import { create } from 'zustand';

const useSearchContext = create((set) => ({
  category: '',
  close: () => set({ openMenu: undefined }),
  open: (menu) => set({ openMenu: menu }),
  toggle: (menu) =>
    set((state) => ({
      openMenu: state.openMenu === menu ? undefined : menu,
    })),
  setSearchCategory: (category) => set({ category }),
}));

export default useSearchContext;
