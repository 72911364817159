import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, PRODUCTS } from 'services/constants';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import ClientSideTable from 'components/agility-pageModules/pageComponents/ClientSideTable';
import { emailRegex, parseRawEmails } from 'services/stringUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AddEditSchoolSubsriptionDialog = (props) => {
  const [productList, setProductList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [fetching, setFetching] = useState(false);

  const [dialogState, setDialogState] = useState({
    numSeats: 1,
    product: '',
    crmId: '',
    isSeatBased: false,
    seatList: [],
    startDate: DateTime.now().toFormat('yyyy-LL-dd'),
    endDate: DateTime.now().plus({ years: 1 }).plus({ days: -1 }).toFormat('yyyy-LL-dd'),
  });

  const [addSeatsDialog, setAddSeatsDialog] = useState(false);
  const [rawEmails, setRawEmails] = useState('');
  const [rawEmailError, setRawEmailError] = useState(false);
  const [seatErrorMessage, setSeatErrorMessage] = useState(null);
  const [seatAlertSeverity, setSeatAlertSeverity] = useState('error');
  const [alertSeverty, setAlertSeverity] = useState('error');

  useEffect(() => {
    const init = async () => {
      let products = [];
      PRODUCTS.forEach((r) => {
        if (r.isActive) {
          products.push(
            <MenuItem value={r.id} key={r.id}>
              {r.name}
            </MenuItem>
          );
        }
      });
      setProductList(products);

      if (props.editSchool && props.editSubId) {
        let sub = props.editSchool.subscriptions?.find((s) => s.id === props.editSubId);
        if (sub) {
          let tempState = {
            numSeats: sub.num_seats,
            product: sub.product,
            crmId: sub.crm_id,
            seatList: sub.seat_list,
            isSeatBased: sub.is_seat_based,
            startDate: DateTime.fromMillis(sub.start_date).toFormat('yyyy-LL-dd'),
            endDate: DateTime.fromMillis(sub.end_date).toFormat('yyyy-LL-dd'),
          };
          setDialogState(tempState);
        }
      } else {
        setErrorMessage(null);
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open]);

  useEffect(() => {
    if (!props.editDistrict?.subscriptions) {
      return;
    }
    if (errorMessage) {
      return;
    }
    const overlapSubs = props.editDistrict.subscriptions.filter((s) => {
      if (
        s.product === dialogState.product &&
        s.start_date <= DateTime.fromFormat(dialogState.endDate, 'yyyy-LL-dd').toJSDate().getTime() &&
        s.end_date >= DateTime.fromFormat(dialogState.startDate, 'yyyy-LL-dd').toJSDate().getTime()
      ) {
        return true;
      }
      return false;
    });

    if (overlapSubs && overlapSubs.length > 0) {
      setWarningMessage(`The district has a subscrition for "${PRODUCTS.find((p) => p.id === dialogState.product)?.name}" with overlapping dates!`);
    } else {
      setWarningMessage(null);
    }
  }, [props.editDistrict, dialogState]);

  const closeDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSave()) {
      return;
    }

    try {
      setFetching(true);

      let sub = {
        school_id: props.editSchool.id,
        district_id: props.editSchool.district_id,
        num_seats: parseInt(dialogState.numSeats, 10),
        product: dialogState.product,
        crm_id: dialogState.crmId,
        start_date: DateTime.fromFormat(dialogState.startDate, 'yyyy-LL-dd').toJSDate().getTime(),
        end_date: DateTime.fromFormat(dialogState.endDate, 'yyyy-LL-dd').toJSDate().getTime(),
        is_seat_based: dialogState.isSeatBased,
        seat_list: dialogState.seatList,
      };
      if (props.editSubId) {
        sub.sub_id = props.editSubId;
      }
      const tempSchool = await callApi('/api/db/school-subscription', props.action === 'Add' ? 'POST' : 'PUT', sub);
      resetState();
      props.onClose(doSave, tempSchool);
      setFetching(false);
    } catch (error) {
      console.log(error);
      logClientException(error);
      setFetching(false);
      setAlertSeverity('error');
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const canSave = () => {
    let hasError = false;
    const sub = dialogState;
    if (!sub.numSeats || isNaN(sub.numSeats) || sub.numSeats < 1) {
      hasError = true;
      sub.numSeatsError = true;
    }

    if (!sub.product) {
      hasError = true;
      sub.productError = true;
    }

    if (!sub.crmId) {
      hasError = true;
      sub.crmIdError = true;
    }

    if (hasError) {
      setDialogState({ ...sub, canSave: false });
    }
    return !hasError;
  };

  const dialogStateOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;

    if (field === 'product' || field === 'startDate' || field === 'endDate' || field === 'crmId') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    if (field === 'numSeats') {
      if (isNaN(value) || value < 1) {
        canSave = false;
        fieldError = true;
      }
    }

    // check for dup subs
    if (props.editSchool?.subscriptions) {
      const product = field === 'product' ? value : dialogState.product;
      const startDate = field === 'startDate' ? value : dialogState.startDate;
      const endDate = field === 'endDate' ? value : dialogState.endDate;
      const overlapSubs = props.editSchool.subscriptions.filter((s) => {
        if (
          s.product === product &&
          s.id !== props.editSubId &&
          s.start_date <= DateTime.fromFormat(endDate, 'yyyy-LL-dd').toJSDate().getTime() &&
          s.end_date >= DateTime.fromFormat(startDate, 'yyyy-LL-dd').toJSDate().getTime()
        ) {
          return true;
        }
        return false;
      });

      if (overlapSubs && overlapSubs.length > 0) {
        setAlertSeverity('error');
        setErrorMessage(`There is already a subscription for "${PRODUCTS.find((p) => p.id === product)?.name}" with overlapping dates!`);
        canSave = false;
      } else {
        setErrorMessage(null);
      }
    }

    // check endDate > startDate
    const testStartDate = field === 'startDate' ? value : dialogState.startDate;
    const testEndDate = field === 'endDate' ? value : dialogState.endDate;
    if (testStartDate >= testEndDate) {
      fieldError = true;
      canSave = false;
    } else {
      dialogState.startDateError = false;
      dialogState.endDateError = false;
    }

    setDialogState({ ...dialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const resetState = () => {
    setDialogState({
      numSeats: 1,
      product: '',
      isSeatBased: false,
      seatList: [],
      startDate: DateTime.now().toFormat('yyyy-LL-dd'),
      endDate: DateTime.now().plus({ years: 1 }).plus({ days: -1 }).toFormat('yyyy-LL-dd'),
    });
  };

  const seatLicenseHeaderColumns = () => {
    return [
      { text: 'User', key: 'email', field: 0, isSortable: true },
      { text: '', key: 'delete_button', isSortable: false },
    ];
  };

  const seatLicenseRecords = () => {
    let records = null;
    records = dialogState.seatList?.map((s) => {
      return [
        s,
        <div key={s}>
          <Button
            color='info'
            size='small'
            variant='text'
            onClick={() => {
              const newSeatList = dialogState.seatList?.slice() ?? [];
              newSeatList.splice(newSeatList.indexOf(s), 1);
              setDialogState({ ...dialogState, seatList: newSeatList, canSave: true });
            }}
            title='Remove Seat'
          >
            Remove Seat
          </Button>
        </div>,
      ];
    });
    return records;
  };

  const showAddSeatsDialog = () => {
    setAddSeatsDialog(true);
    setRawEmails('');
  };

  const addSeats = () => {
    let fieldError = false;
    const items = parseRawEmails(rawEmails);
    for (let i = 0; i < items.length; i++) {
      if (!emailRegex.test(items[i])) {
        fieldError = true;
        i = items.length + 1;
      }
      if (props.editDistrict.email_domain && props.editDistrict.email_domain.length > 0) {
        if (!items[i].endsWith(props.editDistrict.email_domain)) {
          fieldError = true;
          i = items.length + 1;
        }
      }
    }
    if (fieldError) {
      setSeatErrorMessage('One or more email addresses are invalid!');
      setSeatAlertSeverity('error');
      setRawEmailError(true);
      return;
    }
    // get distinct list of items
    const distinctItems = [...new Set(items)];
    const newSeatList = dialogState.seatList?.slice() ?? [];
    distinctItems.forEach((i) => {
      if (!newSeatList.includes(i)) {
        newSeatList.push(i);
      }
    });
    if (newSeatList.length > dialogState.numSeats) {
      newSeatList.splice(dialogState.numSeats);
      setErrorMessage(`Only ${dialogState.numSeats} seats were added!`);
      setAlertSeverity('warning');
    }
    setDialogState({ ...dialogState, seatList: newSeatList, canSave: true });
    setAddSeatsDialog(false);
  };

  const handleSeatAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSeatErrorMessage(null);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <>
      <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeDialog(false)}>
        <DialogTitle>
          {props.action} School Subscription ({props.editSchool?.name})
        </DialogTitle>

        <DialogContent>
          <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleAlertClose} severity={alertSeverty} sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
          <Snackbar open={warningMessage} onClose={handleWarningClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleWarningClose} severity='warning' sx={{ width: '100%' }}>
              {warningMessage}
            </Alert>
          </Snackbar>

          <FormControl fullWidth sx={{ marginTop: '7px' }}>
            <InputLabel required error={dialogState.productError} id='product-select'>
              Product
            </InputLabel>
            <Select
              labelId='product-select'
              id='product-select-value'
              value={dialogState.product}
              label='Product'
              onChange={(e) => {
                dialogStateOnChange('product', e.target.value);
              }}
            >
              {productList}
            </Select>
          </FormControl>

          <TextField
            value={dialogState.numSeats}
            error={dialogState.numSeatsError}
            required
            margin='dense'
            id='num-seats'
            label='Number of Seats'
            type='number'
            fullWidth
            variant='outlined'
            onChange={(e) => dialogStateOnChange('numSeats', e.target.value)}
            inputProps={{ max: 99999, min: 1 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            value={dialogState.crmId}
            error={dialogState.crmIdError}
            required
            margin='dense'
            id='num-seats'
            label='CRM Subscription ID'
            fullWidth
            variant='outlined'
            onChange={(e) => dialogStateOnChange('crmId', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            value={dialogState.startDate}
            error={dialogState.startDateError}
            required
            margin='dense'
            id='start-date'
            label='Start Date'
            type='date'
            fullWidth
            variant='outlined'
            onChange={(e) => dialogStateOnChange('startDate', e.target.value)}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            value={dialogState.endDate}
            error={dialogState.endDateError}
            required
            margin='dense'
            id='end-date'
            label='end Date'
            type='date'
            fullWidth
            variant='outlined'
            onChange={(e) => dialogStateOnChange('endDate', e.target.value)}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
          />

          <FormControlLabel
            control={<Checkbox checked={dialogState.isSeatBased} onChange={(e) => dialogStateOnChange('isSeatBased', e.target.checked)} />}
            label='Use Seat Based Licensing'
          />

          {dialogState.isSeatBased && (
            <>
              <ClientSideTable
                headerCols={seatLicenseHeaderColumns()}
                records={seatLicenseRecords()}
                preTableContent={
                  <KitGridContainer direction='row' align='right'>
                    <KitGridItem xs={12}>
                      <KitButton size='sm' round color='primary' onClick={showAddSeatsDialog}>
                        <AddCircleIcon /> Add Seats
                      </KitButton>
                    </KitGridItem>
                  </KitGridContainer>
                }
              />
            </>
          )}

          <PleaseWait isLoading={fetching} />
        </DialogContent>
        <DialogActions>
          <KitButton onClick={() => closeDialog(false)} round size='sm'>
            Cancel
          </KitButton>
          <KitButton onClick={() => closeDialog(true)} round size='sm' color='success' disabled={!dialogState.canSave}>
            {props.action}
          </KitButton>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth scroll='paper' open={addSeatsDialog} onClose={() => setAddSeatsDialog(false)}>
        <DialogTitle>Add Seats</DialogTitle>

        <DialogContent>
          <Snackbar open={seatErrorMessage} onClose={handleSeatAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleSeatAlertClose} severity={seatAlertSeverity} sx={{ width: '100%' }}>
              {seatErrorMessage}
            </Alert>
          </Snackbar>

          <TextField
            value={rawEmails}
            error={rawEmailError}
            autoFocus
            required
            multiline
            rows={5}
            margin='dense'
            id='email-address'
            label='Email Address List'
            type='text'
            title='Enter a comma separated list or one email per line'
            fullWidth
            variant='outlined'
            onChange={(e) => setRawEmails(e.target.value)}
            inputProps={{ maxLength: 2000 }}
          />
        </DialogContent>
        <DialogActions>
          <KitButton onClick={() => setAddSeatsDialog(false)} round size='sm'>
            Cancel
          </KitButton>
          <KitButton onClick={() => addSeats()} round size='sm' color='success'>
            Add
          </KitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddEditSchoolSubsriptionDialog.propTypes = {
  action: PropTypes.oneOf(['Add', 'Update']).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  editSchool: PropTypes.object,
  editSubId: PropTypes.string,
  onClose: PropTypes.func,
  editDistrict: PropTypes.object,
};

export default AddEditSchoolSubsriptionDialog;
