// package imports
import { useTheme } from '@emotion/react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, IconButton, Typography } from '@mui/material';
import { CheckmarkIcon, EditCircleIcon, ExclamationIconCircle, HandIcon, HeartIcon, NoteIcon } from 'components/common/subcomponents/SvgIcons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useState } from 'react';

// local imports
import OverflowTip from 'components/common/subcomponents/OverflowTip';
import hex2rgba from 'services/colorUtils/hex2rgba';
import { useObservationStore } from '../../stores/observationStore';
import { TimelineBadge } from '../StyledComponents';
import ObservationTimelineEditDialog from './ObservationTimelineEditDialog';
import OffTaskEditDialog from './OffTaskEditDialog';
import SessionNoteEditDialog from './SessionNoteEditDialog';

const ObservationTimelineFull = ({ canEdit }) => {
  const theme = useTheme();
  const [editTimelineDialogOpen, setEditTimelineDialogOpen] = useState(false);
  const [editOffTaskDialogOpen, setEditOffTaskDialogOpen] = useState(false);
  const [editSessionNoteDialogOpen, setEditSessionNoteDialogOpen] = useState(false);

  const { observation, setEditTimelineItem, setEditSessionNote, setEditOffTaskItem } = useObservationStore();

  const getFormattedTime = (timestamp) => {
    try {
      return DateTime.fromMillis(timestamp).toFormat('h:mm a');
    } catch (error) {
      console.log(error);
    }
    return 'N/A';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginLeft: '2rem', marginRight: '2rem' }}>
      {observation.observations?.map((timelineItem, i) => {
        return (
          <Box key={timelineItem.timestamp} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxHeight: '45px' }}>
            {canEdit &&
            (timelineItem.type === 'Praise' ||
              timelineItem.type === 'Correction' ||
              timelineItem.type === 'Prevention' ||
              timelineItem.type === 'Off Task' ||
              timelineItem.type === 'Session Note') ? (
              <IconButton
                sx={{ marginRight: '1rem' }}
                onClick={() => {
                  if (['Praise', 'Correction', 'Prevention'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditTimelineDialogOpen(true);
                  } else if (['Off Task'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditOffTaskDialogOpen(true);
                  } else if (['Session Note'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditSessionNoteDialogOpen(true);
                  }
                }}
              >
                <EditCircleIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ marginRight: '1rem' }}
                onClick={() => {
                  if (['Praise', 'Correction', 'Prevention'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditTimelineDialogOpen(true);
                  } else if (['Off Task'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditOffTaskDialogOpen(true);
                  } else if (['Session Note'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditSessionNoteDialogOpen(true);
                  }
                }}
              >
                <CircleIcon style={{ color: 'transparent' }} />
              </IconButton>
            )}

            <Box
              sx={{
                backgroundColor: hex2rgba(theme.palette[`obs${timelineItem.type}`].dark, 0.3),
                height: '56px',
                width: '56px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '1rem',
              }}
            >
              {timelineItem.type === 'Prevention' && <HandIcon fillColor={theme.palette.obsPrevention.dark} />}
              {timelineItem.type === 'Praise' && <HeartIcon fillColor={theme.palette.obsPraise.dark} />}
              {timelineItem.type === 'Correction' && <CheckmarkIcon fillColor={theme.palette.obsCorrection.dark} />}
              {timelineItem.type === 'Session Note' && <NoteIcon fillColor={theme.palette['obsSession Note'].main} />}
              {timelineItem.type === 'Timer' && <AccessTimeIcon fillColor={theme.palette.obsTimer.dark} />}
              {timelineItem.type === 'Off Task' && (
                <ExclamationIconCircle style={{ height: '27px', width: '27px' }} fillColor={theme.palette['obsOff Task'].main} />
              )}
            </Box>

            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '19px', color: theme.palette.text.mediumDark, marginRight: '.75rem' }}>
              {timelineItem.type}
            </Typography>
            <Box>
              {timelineItem.type !== 'Session Note' &&
                timelineItem.tags.map((tag) => {
                  return <TimelineBadge key={tag}>{tag}</TimelineBadge>;
                })}
            </Box>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                color: theme.palette.text.light,
                marginRight: '1rem',
                flex: '1 0 55px',
                textAlign: 'right',
              }}
            >
              {getFormattedTime(timelineItem.timestamp)}
            </Typography>

            <OverflowTip maxWidth='100%' style={{ marginLeft: 'auto', minWidth: '35%', maxWidth: '35%' }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: theme.palette.common.black,
                }}
              >
                {timelineItem.note && '"'}
                {timelineItem.note}
                {timelineItem.note && '"'}
              </Typography>
            </OverflowTip>
          </Box>
        );
      })}

      <ObservationTimelineEditDialog
        open={editTimelineDialogOpen}
        onClose={() => {
          setEditTimelineItem(null);
          setEditTimelineDialogOpen(false);
        }}
      />
      <OffTaskEditDialog
        open={editOffTaskDialogOpen}
        onClose={() => {
          setEditTimelineItem(null);
          setEditOffTaskDialogOpen(false);
        }}
      />
      <SessionNoteEditDialog
        open={editSessionNoteDialogOpen}
        onClose={() => {
          setEditTimelineItem(null);
          setEditSessionNoteDialogOpen(false);
        }}
      />
    </Box>
  );
};

ObservationTimelineFull.defaultProps = {
  canEdit: true,
};

ObservationTimelineFull.propTypes = {
  canEdit: PropTypes.bool,
};

export default ObservationTimelineFull;
