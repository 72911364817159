// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, FormControl, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// local imports
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles } from '../StyledComponents';

const ObservationOffTaskEdit = ({ onComplete, onDirty }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { updateTimelineObservation, removeTimelineObservation, editTimelineItem, setEditTimelineItem } = useObservationStore();

  const [showOffTask, setShowOffTask] = useState(false);
  const [offTaskNumber, setOffTaskNumber] = useState(0);
  const [numStudents, setNumStudents] = useState(0);
  const [offTaskNumberError, setOffTaskNumberError] = useState(null);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (editTimelineItem?.type === 'Off Task') {
      setOffTaskNumber(Number.parseInt(editTimelineItem.count, 10));
      setNumStudents(Number.parseInt(editTimelineItem.number_of_students, 10));
    } else {
      resetState();
    }
  }, [editTimelineItem]);

  const resetState = () => {
    setOffTaskNumber(0);
    setOffTaskNumberError(null);
    setConfirmDeleteOpen(false);
  };

  const addToTimeline = (isClosed = false) => {
    const timelineNote = {
      timestamp: editTimelineItem.timestamp,
      type: 'Off Task',
      tags: [isClosed ? 'X' : offTaskNumber.toString()],
      count: isClosed ? -1 : Number.parseInt(offTaskNumber, 10),
      number_of_students: editTimelineItem.number_of_students,
    };
    updateTimelineObservation(timelineNote);
    setOffTaskNumber(0);
    setOffTaskNumberError(false);
    setShowOffTask(false);
    onComplete();
  };

  const deleteTimelineObservation = () => {
    removeTimelineObservation(editTimelineItem);
    resetState();
    onComplete();
  };

  const setOffTaskStudentCount = (num) => {
    const updatedNumber = parseInt(offTaskNumber, 10) + num;
    if (typeof updatedNumber !== 'number' || isNaN(updatedNumber) || updatedNumber < 0) {
      setOffTaskNumber(0);
      setOffTaskNumberError(true);
    } else {
      setOffTaskNumber(updatedNumber);
      setOffTaskNumberError(false);
    }
  };

  return (
    <>
      <Box style={{ width: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <label style={{ fontSize: '1.05rem' }}>Number of Off-Task Students</label>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1rem',
            minWidth: '230px',
          }}
        >
          <IconButton onClick={() => setOffTaskStudentCount(-1)} style={{ marginTop: 10 }}>
            <ArrowBackIcon style={{ color: theme.palette.obsBlue.dark }} />
          </IconButton>
          <FormControl fullWidth size='small'>
            <TextField
              placeholder='Enter a number'
              InputLabelProps={{ shrink: true }}
              sx={{ ...styles.select, marginTop: '.75rem' }}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ min: 0, max: numStudents, type: 'number' }}
              label=''
              id='off-task-students'
              error={offTaskNumberError}
              size='small'
              value={offTaskNumber}
              onChange={(e) => {
                setOffTaskNumber(e.target.value);
                if (isNaN(e.target.value) || e.target.value < 0) {
                  setOffTaskNumberError(true);
                } else {
                  setOffTaskNumberError(false);
                }
              }}
            />
          </FormControl>
          <IconButton onClick={() => setOffTaskStudentCount(1)} style={{ marginTop: 10 }}>
            <ArrowForwardIcon style={{ color: theme.palette.obsBlue.dark }} />
          </IconButton>
        </Box>
        <Box sx={{ marginTop: '.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '60%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <KitButton
              onClick={(e) => addToTimeline(false)}
              size='sm'
              style={{
                backgroundColor: theme.palette.gold.main,
                marginTop: '1rem',
              }}
              disabled={offTaskNumber < 0 || offTaskNumber > numStudents}
            >
              <span>
                <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
              </span>
            </KitButton>
            <KitButton
              onClick={() => setConfirmDeleteOpen(true)}
              size='sm'
              style={{
                backgroundColor: theme.palette['obsOff Task'].main,
                marginTop: '1rem',
              }}
            >
              <span>
                <DeleteForeverIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />
                Delete
              </span>
            </KitButton>
          </Box>
        </Box>
      </Box>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        confirmMessage='Are you sure you want to delete this Off-Task count?'
        handleClose={() => setConfirmDeleteOpen(false)}
        handleYes={deleteTimelineObservation}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </>
  );
};

export default ObservationOffTaskEdit;
