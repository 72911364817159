import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COMMON_ROUTES } from 'services/constants';
import { useTheme } from '@mui/material/styles';

const LearnMore = ({ module, customData }) => {
  const { fields } = module;
  const theme = useTheme();

  const [numberOfStaff, setNumberOfStaff] = useState(0);
  const [lengthOfSubscription, setLengthOfSubscription] = useState(0);
  const [costOfPlan, setCostOfPlan] = useState(0);
  const [showTotal, setShowTotal] = useState(false);

  const handleNumberOfStaff = (val) => {
    setShowTotal(false);
    setNumberOfStaff(val);
  };

  const handleLengthOfSubscription = (val) => {
    setShowTotal(false);
    setLengthOfSubscription(val);
  };

  const calculateCost = () => {
    let discount = 1;
    switch (lengthOfSubscription) {
      case '2':
        discount = 0.8;
        break;
      case '3':
        discount = 0.8;
        break;
      default:
        discount = 1;
        break;
    }
    setCostOfPlan(Math.round(((numberOfStaff * lengthOfSubscription * discount) / 10) * 10).toLocaleString('en-US'));
    setShowTotal(true);
  };

  if (fields && (fields.length <= 0 || !fields.learnMorePage)) {
    return <div></div>;
  }

  const Title = styled.div`
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px dashed #000;
    text-align: center;
    @media screen and (max-width: 899px) {
      font-size: 2.225rem;
      line-height: 2.55rem;
      max-width: 95%;
      padding: 40px 5px 10px 5px;
      margin-bottom: 15px;
    }
    @media screen and (min-width: 900px) {
      font-size: 2.625rem;
      line-height: 2.625rem;
      padding: 70px 10px 20px 10px;
      margin-bottom: 35px;
      max-width: 80%;
    }
  `;

  const Description = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    @media screen and (max-width: 899px) {
      max-width: 90%;
      padding: 10px 5px 30px 5px;
    }
    @media screen and (min-width: 900px) {
      max-width: 60%;
      padding: 20px 10px 55px 10px;
    }
  `;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: 899px) {
      flex-direction: column;
      padding: 15px 0;
      width: 100%;
    }
    @media screen and (min-width: 900px) {
      flex-direction: row;
      padding: 20px 40px;
      width: 95%;
    }
  `;

  const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    @media screen and (min-width: 900px) {
      border-radius: 2%;
      ${fields?.learnMorePage?.fields?.showImageOrCalculator === 'image' ? 'width: 40%;' : 'display: none;'}
    }
    @media screen and (max-width: 899px) {
      margin: 10px 0 10px 0;
      // ${fields?.learnMorePage?.fields?.showImageOrCalculator === 'image' ? 'width: 4%;' : 'display: none;'};
      width: 100%;
    }
  `;

  const ListIcon = styled.div`
    align-self: flex-start;
    @media screen and (max-width: 899px) {
      ${fields?.learnMorePage?.fields?.showImageOrCalculator !== 'neither' ? 'width: 15%;' : 'width: 5%;'}
    }
    @media screen and (min-width: 900px) {
      ${fields?.learnMorePage?.fields?.showImageOrCalculator !== 'neither' ? 'width: 8%;' : 'width: 5%;'}
    }
  `;

  const CalculatorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    @media screen and (max-width: 899px) {
      margin: 10px 5px 10px 5px;
      ${fields?.learnMorePage?.fields?.showImageOrCalculator === 'calculator' ? 'width: 100%;' : 'display: none;'};
    }
    @media screen and (min-width: 900px) {
      margin: 30px 10px 30px 10px;
      ${fields?.learnMorePage?.fields?.showImageOrCalculator === 'calculator' ? 'width: 40%;' : 'display: none;'};
    }
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    vertical-align: top;
    @media screen and (max-width: 899px) {
      padding: 20px 0 10px 0;
      margin-bottom: 10px;
      width: 100%;
    }
    @media screen and (min-width: 900px) {
      padding: 60px 30px 0 0;
      margin-bottom: 80px;
      ${fields?.learnMorePage?.fields?.showImageOrCalculator === 'neither' ? 'width: 100%' : 'width: 60%;'}
    }
  `;

  const ListSeparator = styled.div`
    border: 0.75px dashed #fff;
    @media screen and (max-width: 899px) {
      margin: 20px 15px;
      width: 90%;
    }
    @media screen and (min-width: 900px) {
      margin: 30px 0 10px 70px;
      width: 90%;
    }
  `;

  return (
    <Container>
      <Title>{fields?.learnMorePage?.fields?.title}</Title>
      <Description>{fields?.learnMorePage?.fields?.description}</Description>
      <HighlightsContainer>
        <ListContainer>
          <ContentContainer>
            <Introduction>{fields?.learnMorePage?.fields?.introduction}</Introduction>
            <OptionDescription>{fields?.learnMorePage?.fields?.optionDescription}</OptionDescription>
            <List>
              {fields &&
                fields.learnMorePage &&
                fields.learnMorePage.fields &&
                fields.learnMorePage.fields.benefits &&
                fields.learnMorePage.fields.benefits
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                    if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                    return 0;
                  })
                  .map((benefit, index) => {
                    return (
                      <BenefitList key={index}>
                        <ListItemButton style={{ padding: '5px', width: '100%' }}>
                          <ListIcon>
                            <img src='/assets/arrow-icon-green.svg' alt='bullet' style={{ width: '15px', marginTop: '8px' }} />
                          </ListIcon>
                          <ListText>{benefit.fields.benefitName}</ListText>
                        </ListItemButton>
                      </BenefitList>
                    );
                  })}
            </List>
            <Disclaimer>{fields?.learnMorePage?.fields?.disclaimer}</Disclaimer>
          </ContentContainer>
          {fields?.learnMorePage?.fields?.showImageOrCalculator === 'image' ? (
            <ImageContainer>
              {fields.learnMorePage.fields.accentImage && (
                <AccentImage
                  style={{
                    backgroundImage:
                      fields &&
                      fields.learnMorePage &&
                      fields.learnMorePage.fields &&
                      fields.learnMorePage.fields.accentImage &&
                      `url(${fields.learnMorePage.fields.accentImage.url}`,
                  }}
                ></AccentImage>
              )}
            </ImageContainer>
          ) : fields?.learnMorePage?.fields?.showImageOrCalculator === 'calculator' ? (
            <CalculatorContainer>
              <Calculator>
                <CalculatorIntro>Find the right plan for your team.</CalculatorIntro>
                <FormControl sx={{ width: '80%', margin: '10px' }}>
                  <Select
                    id='number-of-staff-value'
                    value={numberOfStaff}
                    placeholder='Number of Staff'
                    hiddenLabel
                    onChange={(e) => {
                      handleNumberOfStaff(e.target.value);
                    }}
                  >
                    <MenuItem value='0' disabled>
                      Select Number of Staff
                    </MenuItem>
                    <MenuItem value='8250'>1-30</MenuItem>
                    <MenuItem value='18600'>31-75</MenuItem>
                    <MenuItem value='33000'>76-150</MenuItem>
                    <MenuItem value='X'>150+</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ width: '80%', margin: '10px' }}>
                  <Select
                    id='length-of-subscription-value'
                    value={lengthOfSubscription}
                    placeholder='Length of Subscription'
                    hiddenLabel
                    onChange={(e) => {
                      handleLengthOfSubscription(e.target.value);
                    }}
                  >
                    <MenuItem value='0' disabled>
                      Select a Subscription Length
                    </MenuItem>
                    <MenuItem value='1'>1 year</MenuItem>
                    <MenuItem value='2'>2 years</MenuItem>
                    <MenuItem value='3'>3 years</MenuItem>
                  </Select>
                </FormControl>
                <KitButton
                  onClick={(e) => calculateCost()}
                  round
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    margin: '20px',
                  }}
                >
                  CALCULATE
                </KitButton>
                <CalculatedCost>{showTotal && `The cost for this plan is $${costOfPlan}.`}</CalculatedCost>
                {showTotal && (
                  <Link href='/contact-us' passHref legacyBehavior>
                    <KitButton
                      color='primary'
                      simple
                      style={{ color: '#000', textDecoration: 'underline', margin: '0px 30px 10px 30px', fontSize: '0.90rem' }}
                    >
                      Contact Us
                    </KitButton>
                  </Link>
                )}
              </Calculator>
            </CalculatorContainer>
          ) : (
            <div></div>
          )}
        </ListContainer>
        <ActionArea>
          <KitGridContainer direction='row' style={{ width: '100%', margin: '5px 0' }}>
            <KitGridItem xs={12} align='center' style={{ margin: '10px 0' }}>
              <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
                <KitButton color='success' round>
                  View More Options
                </KitButton>
              </Link>
            </KitGridItem>
          </KitGridContainer>
        </ActionArea>
      </HighlightsContainer>
    </Container>
  );
};

export default LearnMore;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const HighlightsContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
}));

const Introduction = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
    lineHeight: '2.063rem',
    margin: '30px 0 10px 70px',
    maxWidth: '90%',
  },
  [theme.breakpoints.down('md')]: {
    margin: '5px 15px 25px 15px',
    fontSize: '1.45rem',
    lineHeight: '1.675rem',
    maxWidth: '95%',
  },
}));

const Disclaimer = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontStyle: 'italic',
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.275rem',
    margin: '20px 0 10px 70px',
    maxWidth: '80%',
  },
  [theme.breakpoints.down('md')]: {
    margin: '5px 15px',
    fontSize: '1.00rem',
    lineHeight: '1.4 75rem',
    maxWidth: '95%',
  },
}));

const OptionDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.313rem',
    lineHeight: '1.875rem',
    margin: '40px 0 10px 70px',
    maxWidth: '95%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.113rem',
    lineHeight: '1.775rem',
    maxWidth: '95%',
    margin: '10px 15px',
  },
}));

const BenefitList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.313rem',
    lineHeight: '1.875rem',
    margin: '0 0 0 70px',
    maxWidth: '80%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.113rem',
    lineHeight: '1.775rem',
    maxWidth: '90%',
    margin: '5px 10px 5px 15px',
  },
}));

const AccentImage = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '450px',
    height: '500px',
    margin: '175px 20px 20px 30px',
    borderRadius: '6px',
    backgroundSize: 'cover',
    backgroundPosition: 'right 30% bottom 85%',
    backgroundRepeat: 'no-repeat',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '280px',
    margin: '10px 0 10px 0',
    backgroundSize: '180%',
    backgroundPosition: 'right 25% bottom 60%',
    backgroundRepeat: 'no-repeat',
  },
}));

const MoreButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '30px 0 40px 0',
}));

const ListText = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    maxWidth: '85%',
    fontSize: '1.313rem',
    lineHeight: '1.875rem',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '80%',
    fontSize: '1.10rem',
    lineHeight: '1.375rem',
  },
}));

const Calculator = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  fontSize: '1.65rem',
  fontWeight: 600,
  alignSelf: 'center',
  maxWidth: '400px',
  margin: '35px 20px 20px 20px',
}));

const CalculatorIntro = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.50rem',
    lineHeight: '1.875rem',
    margin: '30px 20px 35px 20px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px',
    fontSize: '1.35rem',
    lineHeight: '1.475rem',
  },
}));

const ActionArea = styled('div')(({ theme }) => ({
  margin: '30px 0',
  width: '100%',
}));

const ButtonDivider = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '1.10rem',
  margin: '12px 25px 10px 25px',
}));

const CalculatedCost = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  alignSelf: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.35rem',
    lineHeight: '1.675rem',
    margin: '50px 20px 35px 20px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px',
    fontSize: '1.45rem',
    lineHeight: '1.675rem',
  },
}));
