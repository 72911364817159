import React, { useEffect, useRef, useMemo } from 'react';
import QRCode from 'qrcode';

const defaultImageOptions = {
  type: 'image/png',
  quality: 0.3,
  level: 'M',
  margin: 3,
  scale: 4,
  width: 150,
  color: {
    dark: '#005581FF',
    light: '#F4AA00FF',
  },
}

export const getQRCodeImageDataUrl = async ({text, options = defaultImageOptions}) => {
  return await QRCode.toDataURL(text, options);
}

