import { useTheme } from '@emotion/react';

export function FreeformQuote({ text, caption, alignment }) {
  const theme = useTheme();
  return (
    <blockquote
      style={{
        padding: '1.25rem 1.5rem',
        backgroundColor: theme.palette.background.lightGrey,
        borderRadius: '1.5rem',
        width: '100%',
        margin: '0 auto',
        maxWidth: '48rem',
      }}
    >
      <div aria-hidden style={{ color: theme.palette.primary.main, fontSize: '68px', marginTop: '.75rem', marginBottom: '.75rem' }}>
        “
      </div>

      <p style={{ fontSize: '20px', lineHeight: '28px' }}>{text}</p>

      <footer style={{ marginTop: '1.5rem' }}>{caption}</footer>
    </blockquote>
  );
}
