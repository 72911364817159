import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useRouter } from 'next/router';
import ContactUsForm from 'components/common/subcomponents/ContactUsForm';

const ContactUs = ({ module }) => {
  const { fields } = module;
  const contactIframe = useRef();

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(location.search);

  const router = useRouter();

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 899px) {
      // min-height: 100vh;
      background-size: 400%;
      background-position: right 10% bottom 85%;
    }
    @media screen and (min-width: 900px) {
      // min-height: 100vh;
      background-size: 120%;
      background-position: right 85% bottom 95%;
    }
  `;

  const EmbedContainer = styled.div`
    margin: 0 0 0 30px;
    @media screen and (max-width: 899px) {
      width: 300px;
      min-height: 230px;
    }
    @media screen and (min-width: 900px) {
      width: 450px;
      height: 650px;
    }
  `;

  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: fields.backgroundImage && fields.backgroundImage.url.length > 0 && `url(${fields.backgroundImage.url})`,
          backgroundColor: `rgba(191, 187, 175, .15)`, // #BFB8AF 0.15
        }}
      >
        <Box sx={{ width: '100%', ml: 'auto', mr: 'auto', maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' } }}>
          {((fields.title && fields.title.length > 0) || (fields.introduction && fields.introduction.length > 0)) && (
            <TitleBox>
              <Title>{fields.title}</Title>
              <Introduction>{fields.introduction}</Introduction>
            </TitleBox>
          )}
          <FormatFrame>
            <FormBox>
              <ContactUsForm product={queryParams?.get('product')} method={queryParams?.get('trainingMethod')} />
            </FormBox>

            {/* TODO: HELP Is on hold until we have content. */}
            {/* <LinkBox>
              <FormHeader
                iconUrl={fields.linkFormHeaderIcon?.url}
                iconLabel={fields.linkFormHeaderIcon?.label}
                title={fields.productSupportFormTitle}
              />
            </LinkBox> */}
          </FormatFrame>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ContactUs;

const FormHeader = (props) => {
  return (
    <>
      <Box sx={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'center', mt: '10px', gap: '20px' }}>
        <img src={props.iconUrl} alt={props.iconLabel} height='55px' width='55px' />
        <Typography fontSize='29px' fontWeight={600} width='65%'>
          Have a question? Check out the FAQs.
        </Typography>
      </Box>
      <Box sx={{ borderBottom: '1px dashed #000', width: '90%', margin: '5px auto' }}>&nbsp;</Box>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 0' }}>
        <a href='support' rel='noopener noreferrer'>
          <KitButton style={{ width: '130px' }} size='small' color='success' round variant='outlined'>
            FAQS
          </KitButton>
        </a>
      </div>
    </>
  );
};

const TitleBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  zIndex: 10,
  margin: '50px 0 0 0',
  color: '#fff',
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    width: '95%',
  },
}));

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '3.40rem',
    lineHeight: '3.65rem',
    margin: '15px 0 5px 0',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.50rem',
    lineHeight: '2.65rem',
    margin: '15px 0 5px 0',
  },
}));

const Introduction = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.20rem',
  lineHeight: '32px',
  marginBottom: 10,
  margin: '5px 0 20px 0',
}));

const FormatFrame = styled('div')(({ theme }) => ({
  margin: '40px 0 30px 0',
  display: 'flex',
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    // justifyContent: 'flex-start',
    // flexDirection: 'row',
    // alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const FormBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.up('md')]: {
    width: '700px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const LinkBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.up('md')]: {
    width: '385px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
