// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import { BadRequestError } from 'services/apiWrapper';
import { states } from 'services/stringUtils';
import { useDistrictsByState, useSchoolsByDistrict } from '../hooks/SchoolLookupHooks';
import { ObsStyles } from './StyledComponents';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const SelectSchoolDialog = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const isFetchingCount = useIsFetching();

  const [errorMessage, setErrorMessage] = useState(null);
  const [dialogState, setDialogState] = useState({
    state: null,
    district: null,
    school: null,
  });

  const districtsQuery = useDistrictsByState(dialogState.state?.value);
  const schoolQuery = useSchoolsByDistrict(dialogState.district?.value?.id);

  useEffect(() => {
    if (props.open && !props.school && !props.district) {
      setDialogState({
        state: null,
        district: null,
        school: null,
      });
    }
  }, [props.open]);

  useEffect(() => {
    if (props.district && props.school) {
      setDialogState({
        state: states.find((s) => s.value === props.district.state),
        district: districtsQuery.data?.find((d) => d.value.id === props.district.id),
        school: schoolQuery.data?.find((s) => s.value === props.school),
      });
    }
    if (props.district && !props.school) {
      setDialogState({
        state: states.find((s) => s.value === props.district.state),
        district: districtsQuery.data?.find((d) => d.value.id === props.district.id),
        school: null,
      });
    }
  }, [props.district, props.school, districtsQuery.data, schoolQuery.data]);

  useEffect(() => {
    let error = null;
    if (districtsQuery.error) {
      error = districtsQuery.error;
    } else if (schoolQuery.error) {
      error = schoolQuery.error;
    }
    if (error) {
      logClientException(error);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [districtsQuery.error, schoolQuery.error]);

  const resetState = () => {
    setErrorMessage(null);
    setDialogState({
      state: null,
      district: null,
      school: null,
    });
  };

  const closeDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave, null, null);
      resetState();
      return;
    }
    props.onClose(doSave, dialogState.district?.value, dialogState.school?.value);
    resetState();
  };

  const formOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;

    setDialogState({ ...dialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      fullWidth={true}
      open={props.open}
      onClose={() => closeDialog(false)}
      PaperComponent={DraggablePaperComponent}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.obsAqua.main, color: theme.palette.white.main, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Select Agency/School
          <IconButton onClick={() => closeDialog(false)} className='need-interaction'>
            <CancelIcon color='white' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem' }}>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} className='need-interaction'>
            {errorMessage}
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem' }}>
          <Autocomplete
            options={states}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            value={dialogState.state}
            onChange={async (e, v) => {
              if (v) {
                setDialogState({ ...dialogState, state: v, district: null, school: null, canSave: true });
              }
            }}
            renderInput={(params) => (
              <TextField
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                required
                {...params}
                label='State'
                size='small'
                error={dialogState.stateError}
                InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
              />
            )}
          />

          <Autocomplete
            options={districtsQuery.data || []}
            fullWidth
            isOptionEqualToValue={(o, v) => o.value.id === v?.value?.id}
            disabled={!dialogState.state}
            value={dialogState.district}
            onChange={async (e, v) => {
              if (v) {
                setDialogState({ ...dialogState, district: v, school: null, canSave: true });
              }
            }}
            renderInput={(params) => (
              <TextField
                sx={styles.select}
                disabled={!dialogState.state}
                required
                error={dialogState.districtError}
                {...params}
                label='School district'
                size='small'
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <Autocomplete
            options={schoolQuery.isPending ? [] : schoolQuery.data || []}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            disabled={!dialogState.district || schoolQuery.data?.length === 0}
            value={dialogState.school}
            onChange={(e, v) => formOnChange('school', v)}
            renderInput={(params) => (
              <TextField
                sx={styles.select}
                error={dialogState.schoolError}
                {...params}
                label='Agency/School'
                size='small'
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>

        <PleaseWait isLoading={isFetchingCount > 0} />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <KitButton className='need-interaction' onClick={() => closeDialog(true)} size='sm' color='secondary' disabled={!dialogState.district}>
          Save
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

SelectSchoolDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  district: PropTypes.object,
  school: PropTypes.object,
};
export default SelectSchoolDialog;
