import { logClientException } from 'appinsights/clientAppInsights';
import { callApi } from './apiWrapper';

/**
 @typedef agencyData
 @type {Object}
 @property {string} city The city.
 @property {string} state The state.
 @property {string} agency The agency.
 */
/**
 *
 * @param {*} user
 * @param {*} loadingSetter
 * @returns {agencyData} Data describing the agency the user is associated with.
 */
export const getAgencyData = async (user, loadingSetter) => {
  const agencyData = {
    city: '',
    state: '',
    agency: '',
  };
  if (!user) {
    return agencyData;
  }

  try {
    if (user.state) {
      if (loadingSetter) {
        loadingSetter(true);
      }
      if (user.school_id && user.school_name && user.district_id) {
        const school = await callApi(`/api/db/school?sid=${user?.school_id}&did=${user?.district_id}`);
        agencyData.agency = user.school_name;
        agencyData.city = school?.location?.city ?? '';
        agencyData.state = school?.location?.state ?? user.state;
      } else if (user.district_id && user.district_name) {
        const district = await callApi(`/api/db/district?did=${user?.district_id}`);
        agencyData.agency = user.district_name;
        agencyData.city = district?.location?.city ?? '';
        agencyData.state = district?.location?.state ?? user.state;
      }
      if (agencyData.agency === 'Other') {
        agencyData.agency = '';
      }
    }
  } catch (error) {
    logClientException(error);
  } finally {
    if (loadingSetter) {
      loadingSetter(false);
    }
  }

  return agencyData;
};
