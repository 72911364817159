// package imports
import { create } from 'zustand';
import { produce } from 'immer';
import { persist, createJSONStorage } from 'zustand/middleware';
import { DateTime } from 'luxon';

type EventSearchState = {
  eventDate: DateTime | null | string;
  school: object | null;
  district: object | null;
  observer: string;
  allDates: boolean;
};

type State = {
  searchState: EventSearchState;
};

type Actions = {
  setSearchState: (state: EventSearchState) => void;
  setEventDate: (date: DateTime | null) => void;
  setSchool: (school: object | null) => void;
  setDistrict: (district: object | null) => void;
  setObserver: (observer: string) => void;
  setAllDates: (allDates: boolean) => void;
};

/**
 * Custom hook for managing the observation event list search store.
 * @returns An object containing state and actions for managing the search store.
 */
export const useObservationEventListSearchStore = create<State & Actions>()(
  persist(
    (set) => ({
      searchState: { eventDate: null, school: null, district: null, observer: '', allDates: false },
      setSearchState: (state: EventSearchState) =>
        set(
          produce((draft) => {
            draft.searchState = state;
          })
        ),
      setEventDate: (date: DateTime | null) =>
        set(
          produce((draft) => {
            draft.searchState.eventDate = date;
          })
        ),
      setSchool: (school: object | null) =>
        set(
          produce((draft) => {
            draft.searchState.school = school;
          })
        ),
      setDistrict: (district: object | null) =>
        set(
          produce((draft) => {
            draft.searchState.district = district;
          })
        ),
      setObserver: (observer: string) =>
        set(
          produce((draft) => {
            draft.searchState.observer = observer;
          })
        ),
      setAllDates: (allDates: boolean) =>
        set(
          produce((draft) => {
            draft.searchState.allDates = allDates;
          })
        ),
    }),
    {
      name: 'obs-evt-lst-srch', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used

      onRehydrateStorage: (state) => {
        return (state, error) => {
          if (error) {
            console.log('an error happened during hydration', error);
          } else {
            if (typeof state?.searchState?.eventDate === 'string') {
              // turn the eventDate string into a DateTime object
              state.searchState.eventDate = DateTime.fromISO(state.searchState.eventDate);
            }
          }
        };
      },
    }
  )
);
