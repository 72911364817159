import styled from '@emotion/styled';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';
import CustomText from 'components/common/subcomponents/Typography/CustomText';

const NewsLetterComponent = ({ cTAText1, cTAText2, unsubscribeText, cTAlink, cTALinkColor = 'light', backgroundColor = 'gold', accentImage }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        width: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container bgColor={backgroundColor}>
        <SubHeaderBox>
          <CustomText color='#000' component='h5' fontSize='29px' fontWeight='600' lineHeight='36.45px'>
            {cTAText1}
          </CustomText>
        </SubHeaderBox>
        <MobileSubHeaderBox>
          <CustomText color='#000' component='h5' fontSize='18px' fontWeight='600' lineHeight='22.63px'>
            {cTAText1}
          </CustomText>
        </MobileSubHeaderBox>
        <HeaderBox>
          <CustomText color='#FFF' component='h5' fontSize='32px' fontWeight='700' lineHeight='40.22px'>
            {cTAText2}
          </CustomText>
        </HeaderBox>
        <MobileHeaderBox>
          <CustomText color='#FFF' component='h5' fontSize='21px' fontWeight='700' lineHeight='26.4px'>
            {cTAText2}
          </CustomText>
        </MobileHeaderBox>
        <ButtonContainer>
          <Link href={cTAlink.href} passHref legacyBehavior>
            <KitButton
              round
              size='lg'
              style={{
                backgroundColor:
                  cTALinkColor === 'light' ? theme.palette.primary.light : theme.palette['cTALinkColor']?.main || theme.palette.primary.main,
                width: '200px',
              }}
            >
              {cTAlink.text}
            </KitButton>
          </Link>
        </ButtonContainer>
        <UnsubscribeBox>
          <CustomText color='#000' component='h6' fontSize='1.00rem' fontWeight='400' lineHeight='1.10rem'>
            <div dangerouslySetInnerHTML={{ __html: unsubscribeText }}></div>
          </CustomText>
        </UnsubscribeBox>
        <ImageContainer>{accentImage && <AdImage src={accentImage.url} alt={accentImage.text} />}</ImageContainer>
      </Container>
    </Box>
  );
};

const Container = styled('div')(({ theme, bgColor }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: theme.palette[bgColor].main,
  borderRadius: '6px',
  [theme.breakpoints.up('md')]: {
    minHeight: '300px',
    padding: '0 50px 0 50px',
    marginTop: '100px',
    marginBottom: '50px',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '356px',
    padding: '0 15px 0 15px',
    marginTop: '20px',
    marginBottom: '20px',
    overflow: 'hidden',
  },
}));

const SubHeaderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 1200px) {
    max-width: 55%;
    top: 25px;
    left: 50px;
    margin: 0 15px 0 15px;
  }
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

const MobileSubHeaderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    max-width: 95%;
    top: 20px;
    left: 15px;
    margin: 0 5px 0 5px;
  }
`;

const HeaderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 1200px) {
    max-width: 55%;
    top: 105px;
    left: 50px;
    margin: 0 15px 15px 15px;
  }
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

const MobileHeaderBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    max-width: 95%;
    top: 100px;
    left: 15px;
    margin: 0 5px 15px 5px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 1200px) {
    max-width: 50%;
    top: 175px;
    left: 50px;
    margin: 20px 0 0 15px;
  }
  @media screen and (max-width: 1199px) {
    max-width: 95%;
    top: 170px;
    left: 15px;
    margin: 20px 0 0 5px;
  }
`;

const UnsubscribeBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 1200px) {
    max-width: 50%;
    top: 240px;
    left: 50px;
    margin: 20px 0 0 15px;
  }
  @media screen and (max-width: 1199px) {
    max-width: 95%;
    top: 280px;
    left: 15px;
    margin: 20px 50% 0 15px;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  @media screen and (min-width: 1200px) {
    right: 40px;
    top: -33px;
    height: 100%;
  }
  @media screen and (max-width: 1199px) {
    right: -35px;
    top: 175px;
    height: 75%;
  }
`;

const AdImage = styled.img`
  @media screen and (min-width: 1200px) {
    height: 125%;
  }
  @media screen and (max-width: 1199px) {
    height: 80%;
  }
`;

export default NewsLetterComponent;
