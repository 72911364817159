import { useTheme } from '@mui/material/styles';
import { SuggestionLink } from './SearchStyledComponents';

const SearchTerms = ({ searchTerms, newSearch }) => {
  const theme = useTheme();

  return (
    <div style={{ paddingLeft: '3rem', borderLeft: '1px solid rgb(215 215 215)', marginLeft: '3rem' }}>
      <div style={{ position: 'sticky', top: 0, bottom: 0 }}>
        <div style={{ fontWeight: 700, lineHeight: '20px', fontSize: '14px', color: theme.palette.text.active, marginBottom: '1rem' }}>
          Popular Keywords
        </div>
        <div style={{ display: 'grid', gap: '.75rem' }}>
          {searchTerms?.map((term, index) => {
            return (
              <SuggestionLink key={index} onClick={() => newSearch(term)}>
                {term}
              </SuggestionLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchTerms;
