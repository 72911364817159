import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { COMMON_ROUTES } from 'services/constants';
import { useRouter } from 'next/router';
import CloseIcon from '@mui/icons-material/Close';

import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';

const GatedResourceModal = ({ open, toggleOpen }) => {
  const theme = useTheme();

  const handleClose = (e, r) => {
    if (r !== 'backdropClick' && r !== 'escapeKeyDown') {
      toggleOpen(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-desc'>
      <Box
        sx={{
          borderRadius: '8px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100%', sm: '500px' },
          padding: '50px 30px',
          backgroundColor: theme.palette.common.white,
          overflow: 'hidden',
          outline: 'none',
        }}
      >
        <KitGridContainer spacing={0.2}>
          <KitGridItem xs={6}>
            <CtaBox bgColor={theme.palette.warmRed.main} headerText='Create a Free Account' path='/signin?nl=1' id='modal-title' />
          </KitGridItem>
          <KitGridItem xs={6} style={{ alignItems: 'center' }}>
            <SubText id='modal-desc'>To access unlimited free resources.</SubText>
          </KitGridItem>

          <KitGridItem xs={6}>
            <CtaBox bgColor={theme.palette.brandedAqua.main} headerText='View Plans' path={COMMON_ROUTES.PricingPage} />
          </KitGridItem>
          <KitGridItem xs={6} style={{ alignItems: 'center' }}>
            <SubText>Sign up for online, virtual or in-person training.</SubText>
          </KitGridItem>
        </KitGridContainer>

        <AccentImage
          src='https://content.liftwithboystown.org/designer-images/free-reseources/signup-girls-background.jpg'
          alt='children background image'
        />

        <CloseIcon
          sx={{
            color: theme.palette.common.black,
            opacity: 0.38,
            position: 'absolute',
            top: '5px',
            right: '5px',
            cursor: 'pointer',
            '&:hover': { color: theme.palette.primary.light, opacity: 1, fontSize: '26px' },
          }}
          onClick={() => toggleOpen(false)}
        />
      </Box>
    </Modal>
  );
};

const CtaBox = ({ bgColor, headerText, path }) => {
  const router = useRouter();

  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        width: { xs: '120px', sm: '210px' },
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#fff',
        cursor: 'pointer',
        fontSize: { xs: '18px', sm: '26px' },
        fontWeight: 700,
        textAlign: 'center',
        margin: '10px',
      }}
      onClick={() => {
        router.push(path);
      }}
    >
      {headerText}
    </Box>
  );
};

const SubText = styled('div')(({ theme }) => ({
  fontWeight: 400,
  height: '100%',
  color: theme.palette.common.black,
  textAlign: 'center',
  lineHeight: '26.4px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: '21px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
  },
}));

const AccentImage = styled.img`
  position: absolute;
  left: 45%;
  top: 20px;
  height: 95%;
  opacity: 0.2;
  mix-blend-mode: multiply;
  // @media screen and (max-width: 991px) {
  //   display: none;
  // }
`;

export default GatedResourceModal;
