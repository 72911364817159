import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';

const RenewalCallout = ({ module }) => {
  const { fields } = module;
  const router = useRouter();
  const theme = useTheme();
  const { data: session, status } = useSession();

  const [isLoading, setIsLoading] = useState(false);
  const [showCallout, setShowCallout] = useState(false);
  const [productExpirationDate, setProductExpirationDate] = useState(null);

  useEffect(() => {
    if (session) {
      if (session.user) {
        if (session.user.subscriptions) {
          let shouldShowCallout = false;
          let exp = null;
          session.user.subscriptions.forEach((s) => {
            if (s.end_date < DateTime.now().plus({ days: 45 }).toJSDate().getTime() && s.product === fields.product) {
              shouldShowCallout = true;
              exp = s.end_date;
            }
          });
          // is there already a renewed sub?
          session.user.subscriptions.forEach((s) => {
            if (shouldShowCallout && s.start_date >= exp) {
              shouldShowCallout = false;
              exp = null;
            }
          });

          setShowCallout(shouldShowCallout);
          setProductExpirationDate(exp);
        }
      }
    }
  }, [session]);

  const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      background-color: theme.palette.primary.main;
      background-image: url('${fields.backgroundImage.url}');
      background-repeat: no-repeat;
      height: 300px;
    }
  `;

  const MobileContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 899px) {
      height: 330px;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const MobileImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    padding: 0 5px 0 140px;
    @media screen and (max-width: 899px) {
      background-color: theme.palette.primary.main;
      background-image: url('${fields.backgroundImage.url}');
      background-repeat: no-repeat;
      background-size: 200px;
      background-position: left -5%;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const MobileCalloutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    padding: 15px 10px 0 20px;
    @media screen and (max-width: 899px) {
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  return (
    <React.Fragment>
      <PleaseWait isLoading={isLoading} />
      {session && showCallout && (
        <Frame>
          <ContentContainer>
            <CalloutContainer>
              <Callout>{fields.calloutText}</Callout>
            </CalloutContainer>
            <Summary>
              <Paragraph>{fields.description}</Paragraph>
            </Summary>

            <ActionArea>
              {fields.actionButtonUrl && fields.actionButtonUrl.text && fields.actionButtonUrl.text.length > 0 && (
                <KitButton
                  round
                  size='lg'
                  color='transparent'
                  style={{
                    width: '160px',
                    color: theme.palette.common.white,
                    border: `1px solid ${theme.palette.common.white}`,
                  }}
                  onClick={() =>
                    router.push(
                      fields.actionButtonUrl.href +
                        '?product=' +
                        fields.product +
                        '&plan=' +
                        fields.planName +
                        '&price=' +
                        fields.planPrice +
                        '&renewalDate=' +
                        productExpirationDate
                    )
                  }
                >
                  {fields.actionButtonUrl.text}
                </KitButton>
              )}
            </ActionArea>
          </ContentContainer>
          <MobileContentContainer>
            <MobileCalloutContainer>
              <Callout>{fields.calloutText}</Callout>
            </MobileCalloutContainer>
            <Summary>
              <Paragraph>{fields.description}</Paragraph>
            </Summary>

            <ActionArea>
              {fields.actionButtonUrl && fields.actionButtonUrl.text && fields.actionButtonUrl.text.length > 0 && (
                <KitButton
                  round
                  size='lg'
                  color='transparent'
                  style={{
                    width: '160px',
                    color: theme.palette.common.white,
                    border: `1px solid ${theme.palette.common.white}`,
                  }}
                  onClick={() =>
                    router.push(
                      fields.actionButtonUrl.href +
                        '?product=' +
                        fields.product +
                        '&plan=' +
                        fields.planName +
                        '&price=' +
                        fields.planPrice +
                        '&renewalDate=' +
                        productExpirationDate
                    )
                  }
                >
                  {fields.actionButtonUrl.text}
                </KitButton>
              )}
            </ActionArea>
          </MobileContentContainer>
        </Frame>
      )}
    </React.Fragment>
  );
};

export default RenewalCallout;

const Frame = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const CalloutContainer = styled('div')(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.down('md')]: {
    padding: '5px 0 0 50px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0 10px 475px',
  },
}));

const Callout = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#F2A900',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.65rem',
    lineHeight: '1.825rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.65rem',
    lineHeight: '1.825rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.15rem',
    lineHeight: '2.375rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.625rem',
    lineHeight: '2.95rem',
  },
}));

const Summary = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: '10px 30px 0 0',
    padding: '0 0 0 20px',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 20px 0 0',
    padding: '10px 0 0 475px',
  },
}));

const Paragraph = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.10rem',
    lineHeight: '1.425rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.250rem',
    lineHeight: '1.525rem',
  },
}));

const ActionArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    margin: '25px 0 20px 0',
    padding: '0 0 0 20px',
  },
  [theme.breakpoints.up('md')]: {
    margin: '10px 0 15px 0',
    padding: '0 0 0 475px',
  },
}));
