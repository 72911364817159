export const themeColor = (theme, colorString) => {
  // #000000 style colorString
  if (colorString?.startsWith('#')) {
    return colorString;
  }
  // primary.main style colorString
  if (colorString?.indexOf('.') > -1) {
    const [palette, shade] = colorString.split('.');
    return theme.palette[palette][shade] ? theme.palette[palette][shade] : theme.palette.common.black;
  }
  // primary style colorString
  return theme.palette.hasOwnProperty(colorString) ? theme.palette[colorString].main : theme.palette.common.black;
};
