// package imports
import { openDB } from 'idb';
import { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Badge, Box, ClickAwayListener } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.black,
  },
}));

const OffLineQueueTrack = () => {
  const theme = useTheme();
  const [queueCount, setQueueCount] = useState(0);
  const [open, setOpen] = useState(false);

  const BACKGROUND_SYNC_DB_VERSION = 3;
  const BACKGROUND_SYNC_DB_NAME = 'serwist-background-sync';
  const REQUEST_OBJECT_STORE_NAME = 'requests';

  let dbExists = true;
  const dbUpgradeNeeded = (db, oldVersion, newVersion, transaction) => {
    // if the db doesn't exist, abort the transaction
    // this is so the serwist library an create the db
    // as needed with its correct setup
    dbExists = false;
    transaction.abort();
  };

  const getAllItemsFromStore = async () => {
    try {
      const db = await openDB(BACKGROUND_SYNC_DB_NAME, BACKGROUND_SYNC_DB_VERSION, { upgrade: dbUpgradeNeeded });

      if (!dbExists) {
        return;
      }
      // Get all values from the designated object store:
      const allValues = await db.getAll(REQUEST_OBJECT_STORE_NAME);

      if (!allValues || allValues.length === 0) {
        setQueueCount(0);
      } else {
        setQueueCount(
          allValues.filter((item) => item.requestData?.method === 'POST' && item.requestData?.url.indexOf('/api/db/observation/instance') > -1).length
        );
      }

      const reg = await navigator.serviceWorker.getRegistration();
      if ('sync' in reg) {
        // background sync is supported
      } else if (allValues.length > 0) {
        // background sync is not supported, replay all items
        reg.active?.postMessage({ type: 'TRY_SYNC' });
      }
    } catch (error) {
      // if we abort we'll get an error here, most likely means db doesn't exist
      //console.log('Error getting all items from store, db might not exist', error);
    }
  };

  useEffect(() => {
    getAllItemsFromStore();
    const interval = setInterval(getAllItemsFromStore, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: queueCount > 0 ? 'flex' : 'none' }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Badge badgeContent={queueCount} color='secondary' overlap='circular'>
          <LightTooltip title={`${queueCount} Observations are Pending Syncronization`} placement='left' open={open}>
            <CloudUploadIcon sx={{ color: theme.palette.common.white }} fontSize='large' onClick={handleOpen} />
          </LightTooltip>
        </Badge>
      </ClickAwayListener>
    </Box>
  );
};

export default OffLineQueueTrack;
