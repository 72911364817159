import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';

const BannerContainer = styled('div')((props) => ({
  width: 'auto',
  flexGrow: 1,
  flexShrink: 0,
  padding: '1.5rem 0',
  margin: '15px 25px 10px 10px',
  [props.theme.breakpoints.up('md')]: {
    margin: '15px 25px 5px 30px',
    backgroundRepeat: props.repeat ? 'repeat' : 'no-repeat',
    backgroundPosition: props.repeat ? 'top left' : 'center top',
    backgroundAttachment: 'fixed',
    backgroundColor: 'transparent',
    backgroundImage: `url(${props.bgurl})`,
  },
}));

const BannerContentContainer = styled.div`
  @media screen and (min-width: 1408px) {
    max-width: 1344px;
  }
  @media screen and (min-width: 1216px) {
    max-width: 1152px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 960px;
  }
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
`;

const BannerContent = styled.div`
  width: auto;
  align-items: center;
`;

const BannerMain = styled.div`
  @media screen and (min-width: 769px), print {
    flex: none;
    width: 100%;
    isplay: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-top: 0.75rem;
  }
`;

const BannerLinks = styled.div`
  @media screen and (min-width: 769px), print {
    flex: none;
    width: 58.33333%;
  }
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
`;

const Banner = (props) => {
  const { title, subTitle, backgroundImage, showLinks } = props.module.fields;

  return (
    <BannerContainer bgurl={backgroundImage?.url} repeat={backgroundImage && backgroundImage?.width < 600}>
      <KitGridContainer direction='row' align='center'>
        <KitGridItem xs={12} sm={10} md={9} lg={8} align='left'>
          <BannerContentContainer>
            <BannerContent>
              <BannerMain>
                <Text>
                  <Title>{title}</Title>
                </Text>
                {subTitle && <SubTitle secondary>{subTitle}</SubTitle>}
              </BannerMain>
              <BannerLinks>{showLinks && <></>}</BannerLinks>
            </BannerContent>
          </BannerContentContainer>
        </KitGridItem>
      </KitGridContainer>
    </BannerContainer>
  );
};

export default Banner;

const Text = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  // margin: '15px 25px 10px 40px',
  fontSize: '28px',
  lineHeight: '32px',
  width: '100%',
}));

const SubTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.light,
  backgroundColor: 'transparent',
  marginTop: '5px',
  // margin: '15px 25px 10px 40px',
  fontSize: '18px',
  lineHeight: '20px',
  width: '100%',
}));
