// package imports
import React from 'react';
import Script from 'next/script';

// local imports
import Section from 'components/common/subcomponents/Section';

const Embed = ({ module: { fields } }) => {
  console.log('Embed fields:', fields);
  return (
    <Section styleProps={fields.sectionStyle ?? {}} sectionId={fields.sectionId}>
      <Script id={`content-${fields.contentID}`} strategy='afterInteractive'>
        {fields.code}
      </Script>

      {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
    </Section>
  );
};

export default Embed;
