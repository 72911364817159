import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import QuestionForm from 'components/common/subcomponents/QuestionForm';

const AskTheTrainer = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();
  const { data: session, status } = useSession();

  const Frame = styled.div`
    background: rgba(217, 217, 217, 0.3);
    @media screen and (min-width: 1300px) {
      padding: 25px 0 50px 0;
    }
    @media screen and (max-width: 1299px) {
      padding: 25px 0 40px 0;
    }
    @media screen and (max-width: 999px) {
      padding: 20px 0 30px 0;
    }
    @media screen and (max-width: 699px) {
      padding: 20px 0 20px 0;
    }
    @media screen and (max-width: 450px) {
      padding: 15px 0 10px 0;
    }
  `;

  const Container = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1300px) {
      margin: 25px auto;
      flex-direction: row;
      height: ${session?.user ? '550px' : '620px'};
      padding-bottom: ${session?.user ? '25px' : '80px'};
    }
    @media screen and (max-width: 1299px) {
      padding: 0;
      margin: 20px auto;
      flex-direction: column;
      height: 1250px;
    }
    @media screen and (max-width: 999px) {
      padding: 0;
      margin: 1px auto;
      flex-direction: column;
      min-height: 1150px;
      justify-content: normal;
    }
    @media screen and (max-width: 699px) {
      padding: 0;
      margin: 5px auto;
      flex-direction: column;
      min-height: 1000px;
      justify-content: normal;
    }
    @media screen and (max-width: 450px) {
      padding: 0;
      margin: 5px auto;
      flex-direction: column;
      height: 300px;
      justify-content: normal;
    }
  `;

  const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1300px) {
      margin: 20px auto;
      width: 400px;
    }
    @media screen and (max-width: 1299px) {
      margin: 5px auto;
      width: 320px;
    }
  `;

  const VideoContainer = styled.div`
    position: relative;
    .video {
      aspect-ratio: 16 / 9;
    }
    &:after {
      border-top: solid 17px transparent;
      border-right: solid 17px transparent;
      border-left: solid 17px ${theme.palette.primary.main};
      transform: translateX(-50%);
      position: absolute;
      z-index: 5;
      content: '';
      top: 79.15%;
      left: 100.3%;
      height: 0;
      width: 0;
    }
    @media screen and (min-width: 1300px) {
      width: 630px;
      height: 386px;
    }
    @media screen and (max-width: 1299px) {
      display: none;
    }
  `;

  const MobileVideoContainer = styled.div`
    position: relative;
    .video {
      aspect-ratio: 16 / 9;
    }
    justify-content: center;
    @media screen and (min-width: 1300px) {
      display: none;
    }
    @media screen and (max-width: 1299px) {
      margin: 3% 0 10px 0;
      width: 70%;
      height: auto;
    }
    @media screen and (max-width: 999px) {
      margin: 5% 0 10px 0;
      width: 100%;
      height: auto;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (min-width: 1300px) {
      margin: 0 0 50px 0;
      width: 95%;
    }
    @media screen and (max-width: 1299px) {
      width: 110%;
    }
  `;

  const FormContainer = styled.div`
    position: relative;
    top: ${session?.user ? '30px' : '95px'};
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1300px) {
      width: 45%;
      min-height: 525px;
      padding: 0 0 15px 0;
    }
    @media screen and (max-width: 1299px) {
      width: 100%;
      min-height: 530px;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
      height: 500px;
    }
  `;

  return (
    <Frame>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <TitleContainer>
          <Heading3 color='#000000' underline={true} uppercase={true}>
            Have A Question?
            <br />
            Ask The Trainer
          </Heading3>
        </TitleContainer>
        <Container>
          <VideoContainer>
            {fields.videoEmbed && <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoEmbed)} />}
            <Link href='ask-the-trainer' passHref legacyBehavior>
              <KitButton
                style={{
                  backgroundColor: theme.palette.brandedAqua.main,
                  padding: '10px 40px',
                  fontSize: '1.1875rem',
                  fontWeight: 300,
                  position: 'absolute',
                  left: '319.75px',
                  top: '318px',
                  borderRadius: 0,
                  margin: '4.5px 0 0 -1px',
                  flexDirection: 'row',
                }}
              >
                View All Q&A Videos <PlayArrowIcon style={{ margin: '0 0 3px 10px' }} />
              </KitButton>
            </Link>
          </VideoContainer>
          <MobileVideoContainer>
            {fields.videoEmbed && <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoEmbed)} />}
            <KitButton
              round
              style={{
                backgroundColor: theme.palette.brandedAqua.main,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '10px 40px',
                fontSize: '1.1875rem',
                fontWeight: 300,
                margin: '5px auto',
                flexDirection: 'column',
              }}
            >
              <Link href='ask-the-trainer' passHref legacyBehavior>
                <a href='ask-the-trainer' rel='noopener noreferrer'>
                  View All Q&A Videos <PlayArrowIcon style={{ margin: '0 0 3px 10px' }} />
                </a>
              </Link>
            </KitButton>
          </MobileVideoContainer>
          <FormContainer>
            <QuestionForm buttonColor={theme.palette.primary.light} />
          </FormContainer>
        </Container>
      </Box>
    </Frame>
  );
};

export default AskTheTrainer;
