import { KitCard } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import hex2rgba from 'services/colorUtils/hex2rgba';
import { logResourceAccess } from 'services/gatedResourceUtils';

const LessonPlanCard = (props) => {
  const fields = props.item.fields;
  const behaviorTagList = props.behaviorTagList;
  const theme = useTheme();
  const behaviorTags = behaviorTagList.filter((b) => fields?.behaviorTags_ValueField?.split(',')?.indexOf(b.contentID?.toString()) > -1) ?? [];
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const logLessonPlanDownload = async () => {
    await logResourceAccess('Lesson Plan', fields?.title, fields?.downloadLink?.href, 'lesson_plan_download');
  };

  const getTagList = () => {
    let tagList = behaviorTags.map((b) => {
      return (
        <span style={{ marginRight: '10px' }} key={b.fields?.textValue}>
          {b.fields?.textValue}
        </span>
      );
    });
    if (isSmallScreen) {
      return tagList;
    }
    if (!isSmallScreen) {
      const textList = behaviorTags.map((r) => r.fields?.textValue);

      // determine number of tags which can fit on a line
      let maxTags = textList.length;
      for (let i = 1; i <= textList.length; i++) {
        let temp = textList.slice(0, i).join(', ');
        if (temp.length > 50) {
          maxTags = i;
          i = textList.length + 1;
        } else if (i < textList.length) {
          temp = textList.slice(0, i + 1).join(', ');
          if (temp.length > 50) {
            maxTags = i;
            i = textList.length + 1;
          }
        }
      }

      if (tagList.length <= maxTags) {
        return tagList;
      }

      const tooltipTags = textList.slice(maxTags).join(', ');
      tagList = tagList.slice(0, maxTags);
      tagList.push(
        <Tooltip title={tooltipTags} arrow>
          <MoreHorizIcon />
        </Tooltip>
      );
      return tagList;

      // // only show 2 tags on desktop
      // if (tagList.length <= 2) {
      //   return tagList;
      // }
      // const tooltipTags = behaviorTags
      //   .slice(2)
      //   .map((r) => r.fields?.textValue)
      //   .join(', ');
      // tagList = tagList.slice(0, 2);
      // tagList.push(
      //   <Tooltip title={tooltipTags} arrow>
      //     <MoreHorizIcon />
      //   </Tooltip>
      // );
      // return tagList;
    }
  };

  return (
    <>
      <KitCard className='lessonPlanCard' style={{ width: '95%', borderRadius: '2px' }}>
        <div
          style={{
            borderTopLeftRadius: '2px',
            borderTopRightRadius: '2px',
            backgroundColor: hex2rgba(theme.palette.tertiary.main, 0.1),
            height: '60px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip title={fields?.title} arrow>
            <Typography noWrap={isSmallScreen ? false : true} sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '21px', marginLeft: '45px' }}>
              {fields?.title}
            </Typography>
          </Tooltip>
          <Box
            sx={{
              // TODO - should this svg come from Agility?
              backgroundImage: "url('/assets/rectangle.svg')",
              backgroundRepeat: 'no-repeat',
              height: '36px',
              width: '41px',
              position: 'absolute',
              top: 9,
              left: -9,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            {fields?.lessonPlanNumber}
          </Box>
        </div>

        <Box sx={{ margin: '15px 30px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ textTransform: 'uppercase', fontSize: '13px', fontWeight: 400 }}>
              <a
                onClick={() => logLessonPlanDownload()}
                href={fields?.downloadLink?.href}
                target='_blank'
                rel='noreferrer'
                title={fields?.downloadLink?.text}
              >
                <PictureAsPdfOutlinedIcon style={{ color: theme.palette.warmRed.main }} /> {'  '} Download
              </a>
            </span>
            <span style={{ textTransform: 'uppercase', fontSize: '16px', lineHeight: '22px', fontWeight: 600, color: theme.palette.primary.light }}>
              <a href={`/premium-resources/lesson-plan/${fields?.slug}`} title='View Lesson'>
                View Lesson
              </a>
            </span>
          </Box>
        </Box>
        <hr style={{ borderTop: `1px dashed ${theme.palette.grey['A400']}`, width: '100%' }} />
        <Box sx={{ margin: '15px 30px', display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              fontSize: '13px',
              lineHeight: '22px',
              fontWeight: 400,
              color: theme.palette.text.light,
              textTransform: 'capitalize',
            }}
          >
            {getTagList()}
          </div>
          <div style={{ fontSize: '13px', lineHeight: '22px', fontWeight: 400, color: theme.palette.text.light, textTransform: 'capitalize' }}>
            {fields?.difficultyLevel}
          </div>
        </Box>
      </KitCard>
    </>
  );
};

export default LessonPlanCard;
