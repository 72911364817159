import styled from '@emotion/styled';

export function FreeformDelimiter() {
  return <StyledHr />;
}

const StyledHr = styled('hr')(({ theme }) => ({
  border: 'none',
  height: '1px',
  backgroundColor: theme.palette.grey.A400,
  margin: '0 1rem',
  [theme.breakpoints.down('lg')]: {
    margin: '0 .75rem',
  },
}));
