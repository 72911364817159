import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useTheme } from '@emotion/react';
import { parseBlocks } from 'services/parseUtils';
import { Freeform } from 'components/common/Freeform';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import { FreeformImage } from 'components/common/Freeform/FreeformImage';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import Section from 'components/common/subcomponents/Section';
import { headingColor, headingStyle } from 'services/styleUtils/headingStyleUtils';
import { getContentStyle } from 'services/styleUtils/contentStyleUtils';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import { VideoBase } from 'components/common/subcomponents/VideoBase';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const HighlightedVideo = (props) => {
  const {
    module: { fields },
  } = props;

  const [isActive, setActive] = useState(false);
  const nodeRef = useRef(null);

  const Container = styled.div`
    width: 100%;
  `;

  const TranscriptContainer = styled.div`
    width: 100%;
    margin-top: 10px;
  `;

  const ButtonContainer = styled.div`
    align-items: center;
    gap: 10px 5px;
    width: 100%;
  `;

  const callAction = async (url, target) => {
    const win = window.open(url, target || '_self');
    win.focus();
  };

  function toggle() {
    setActive((p) => !p);
  }

  const Icon = isActive ? RemoveIcon : AddIcon;

  const theme = useTheme();

  const contentBlocks = parseBlocks(fields.contentText);
  const transcriptBlocks = parseBlocks(fields.transcript);
  const contentStyle = getContentStyle(theme, fields.contentStyle?.fields);

  const src = fields?.videoUrl || fields?.videoFile?.url;
  const autoPlay = fields?.autoplay === 'true' || fields?.autoplay === true;
  const loop = fields?.loop === 'true' || fields?.loop === true;

  return (
    <Section styleProps={fields.sectionStyle} sectionId={fields.sectionId}>
      <div className='max-w-5xl mx-auto'>
        <Container>
          <FreeformHeader
            level={fields?.headingLevel || 'h2'}
            text={fields?.headingText}
            color={headingColor(theme, fields.headingStyle?.fields?.color)}
            style={headingStyle(fields.headingStyle?.fields?.alignment)}
          />
          <VideoContainer>
            <VideoBase
              className='w-full h-full'
              url={fields?.videoUrl}
              file={fields?.videoFile}
              autoPlay={autoPlay}
              loop={loop}
              frameborder={0}
              allow='autoplay'
            />
          </VideoContainer>

          {fields?.transcript && fields?.transcript.length > 13 && (
            <TranscriptContainer>
              <div className='flex'>
                <button
                  type='button'
                  className='flex-auto flex items-center text-left text-sm text-blue font-bold uppercase decoration-2 decoration-light-blue underline-offset-4 hover:underline'
                  onClick={toggle}
                >
                  <span className='flex-auto'>{isActive ? 'Close Video Transcript' : 'Read Video Transcript'}</span>
                  <Icon className='flex-none w-6 h-6 ml-4' />
                </button>
              </div>

              <div style={{ marginLeft: '5px', padding: '0 10px' }}>
                {isActive && <Freeform blocks={transcriptBlocks}></Freeform>}
                <br />
              </div>
            </TranscriptContainer>
          )}

          <div
            style={{
              color: contentStyle.textColor,
              textAlign: contentStyle.alignment,
              fontSize: contentStyle.fontSize,
              lineHeight: contentStyle.lineHeight,
            }}
          >
            <Freeform blocks={contentBlocks}></Freeform>
          </div>

          <ButtonContainer>
            {fields?.callsToAction?.sort().map(({ contentID, fields: { label, style, link } }) => (
              <KitButton
                key={contentID}
                round
                size='sm'
                style={{
                  backgroundColor: buttonColor(theme, style?.fields?.color),
                  marginRight: '1rem',
                }}
                onClick={(e) => callAction(link.href, link.target)}
              >
                {label}
              </KitButton>
            ))}
          </ButtonContainer>
        </Container>
      </div>
    </Section>
  );
};

export default HighlightedVideo;

const VideoContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  '& iframe': {
    aspectRatio: '16/9',
    width: '100%',
    height: '100%',
  },
}));
