// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// local imports
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { HandIcon } from 'components/common/subcomponents/SvgIcons';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles, PromptContainer, PromptRadio } from '../StyledComponents';

const PreventionEdit = ({ onComplete, onDirty }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { updateTimelineObservation, removeTimelineObservation, editTimelineItem, setEditTimelineItem } = useObservationStore();

  const [preventionPrompt, setPreventionPrompt] = useState(''); // ['pp', 'bt', 'pt']
  const [sPrompt, setSPrompt] = useState(''); // s'
  const [slPrompt, setSLPrompt] = useState(''); // sl'
  const [moPrompt, setMoPrompt] = useState(''); // mo'
  const [note, setNote] = useState('');
  const [hasPreventionPrompt, setHasPreventionPrompt] = useState(false);
  const [hasMoPrompt, setHasMoPrompt] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (editTimelineItem?.type === 'Prevention') {
      const tags = editTimelineItem.tags;
      if (tags.includes('PP')) {
        setPreventionPrompt('pp');
        setHasPreventionPrompt(true);
      } else if (tags.includes('BT')) {
        setPreventionPrompt('bt');
        setHasPreventionPrompt(true);
      } else if (tags.includes('PT')) {
        setPreventionPrompt('pt');
        setHasPreventionPrompt(true);
      }
      if (tags.includes('S')) {
        setSPrompt('prevention-s');
      }
      if (tags.includes('SL')) {
        setSLPrompt('prevention-sl');
      }
      if (tags.includes('MO')) {
        setMoPrompt('prevention-mo');
        setHasMoPrompt(true);
      }
      setNote(editTimelineItem.note);
    } else {
      resetState();
    }
  }, [editTimelineItem]);

  const resetState = () => {
    setPreventionPrompt('');
    setHasPreventionPrompt(false);
    setSPrompt('');
    setSLPrompt('');
    setMoPrompt('');
    setHasMoPrompt(false);
    setNote('');
    setConfirmDeleteOpen(false);
  };

  const radioOnChange = (e) => {
    onDirty();
    if (editTimelineItem?.type !== 'Prevention') {
      setEditTimelineItem({
        timestamp: editTimelineItem.timestamp,
        type: 'Prevention',
        tags: [],
        note: note,
      });
    }
    const { name, value, checked } = e.target;
    if (name === 'prevention-1') {
      setPreventionPrompt(value);
      setHasPreventionPrompt(true);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'prevention-s-1') {
      setSPrompt(checked && sPrompt === 'prevention-s' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'prevention-sl-1') {
      setSLPrompt(checked && slPrompt === 'prevention-sl' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'prevention-mo') {
      setPreventionPrompt('');
      setHasPreventionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt(checked && moPrompt === 'prevention-mo' ? '' : value);
      setHasMoPrompt(true);
    }
  };

  const addToTimeline = () => {
    const timelineObservation = {
      timestamp: editTimelineItem.timestamp,
      type: 'Prevention',
      tags: [],
      note: note,
    };

    if (preventionPrompt?.length > 0) {
      setHasPreventionPrompt(true);
      timelineObservation.tags.push(preventionPrompt.toUpperCase());
    }
    if (sPrompt?.length > 0) {
      timelineObservation.tags.push('S');
    }
    if (slPrompt?.length > 0) {
      timelineObservation.tags.push('SL');
    }
    if (moPrompt?.length > 0) {
      setHasMoPrompt(true);
      timelineObservation.tags.push('MO');
    }
    if (hasPreventionPrompt || hasMoPrompt) {
      updateTimelineObservation(timelineObservation);
      setPreventionPrompt('');
      setHasPreventionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
      onComplete();
    }
  };

  const deleteTimelineObservation = () => {
    removeTimelineObservation(editTimelineItem);
    resetState();
    onComplete();
  };

  return (
    <PromptContainer promptType='Prevention'>
      <div className='prompt-title-box'>
        <HandIcon fillColor={theme.palette.obsPrevention.dark} />
        <Typography sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '18.86px', color: '#000', marginLeft: '1rem' }}>Prevention</Typography>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
        <PromptRadio promptType='Prevention' style={{ marginTop: '1.5rem' }}>
          <input type='radio' id='pp-edit' name='prevention-1' value='pp' checked={preventionPrompt === 'pp'} onClick={radioOnChange} />
          <label htmlFor='pp-edit'>PP</label>
        </PromptRadio>
        <PromptRadio promptType='Prevention' style={{ marginTop: '1.5rem' }}>
          <input type='radio' id='bt-edit' name='prevention-1' value='bt' checked={preventionPrompt === 'bt'} onClick={radioOnChange} />
          <label htmlFor='bt-edit'>BT</label>
        </PromptRadio>
        <PromptRadio promptType='Prevention' style={{ marginTop: '1.5rem' }}>
          <input type='radio' id='pt-edit' name='prevention-1' value='pt' checked={preventionPrompt === 'pt'} onClick={radioOnChange} />
          <label htmlFor='pt-edit'>PT</label>
        </PromptRadio>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '1.5rem', width: '85%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.obsPrevention.xLight,
            borderRadius: '12px',
            padding: '12px',
            width: '66.67%',
          }}
        >
          <PromptRadio promptType='Prevention' round={true}>
            <input
              type='radio'
              id='prevention-s-edit'
              name='prevention-s-1'
              value='prevention-s'
              checked={sPrompt === 'prevention-s'}
              onClick={radioOnChange}
            />
            <label htmlFor='prevention-s-edit'>S</label>
          </PromptRadio>
          <PromptRadio promptType='Prevention' round={true}>
            <input
              type='radio'
              id='prevention-sl-edit'
              name='prevention-sl-1'
              value='prevention-sl'
              checked={slPrompt === 'prevention-sl'}
              onClick={radioOnChange}
            />
            <label htmlFor='prevention-sl-edit'>SL</label>
          </PromptRadio>
        </Box>
        <Box sx={{ padding: '12px' }}>
          <PromptRadio promptType='Prevention' round={true}>
            <input
              type='radio'
              id='prevention-mo-edit'
              name='prevention-mo'
              value='prevention-mo'
              checked={moPrompt === 'prevention-mo'}
              onClick={radioOnChange}
            />
            <label htmlFor='prevention-mo-edit'>MO</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
        <FormControl fullWidth size='small'>
          <TextField
            placeholder='Type note'
            multiline={true}
            rows={2}
            InputLabelProps={{ shrink: true }}
            sx={{ ...styles.select }}
            InputProps={{ sx: { ...styles.select } }}
            inputProps={{ maxLength: 200 }}
            label=''
            id='prevention-note-edit'
            size='small'
            value={note}
            onChange={(e) => {
              onDirty();
              setNote(e.target.value);
            }}
          />
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <KitButton
            onClick={addToTimeline}
            size='sm'
            style={{
              backgroundColor: theme.palette.gold.main,
              marginTop: '1rem',
            }}
            disabled={!hasPreventionPrompt && !hasMoPrompt}
          >
            <span>
              <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
            </span>
          </KitButton>
          <KitButton
            onClick={() => setConfirmDeleteOpen(true)}
            size='sm'
            style={{
              backgroundColor: theme.palette['obsOff Task'].main,
              marginTop: '1rem',
            }}
            disabled={editTimelineItem?.type === 'Prevention' ? false : true}
          >
            <span>
              <DeleteForeverIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />
              Delete
            </span>
          </KitButton>
        </Box>
      </Box>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        confirmMessage='Are you sure you want to delete this Prevention?'
        handleClose={() => setConfirmDeleteOpen(false)}
        handleYes={deleteTimelineObservation}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </PromptContainer>
  );
};

export default PreventionEdit;
