import React from 'react';
import Head from 'next/head';
import parse from 'html-react-parser';

const SEO = ({ title, description, keywords, ogImage, metaHTML, additionalHeaderMarkup, path, noRobots }) => {
  // setup and parse additional header markup
  let addNoRobots = noRobots;
  let additionalMetaHtml = null;
  if (metaHTML) {
    additionalMetaHtml = parse(metaHTML);
    if (metaHTML.indexOf('robots') > -1 && metaHTML.indexOf('noindex') > -1) {
      // no robots already present
      addNoRobots = false;
    }
  }
  let additionalHeaderMarkupParsed = null;
  if (additionalHeaderMarkup) {
    additionalHeaderMarkupParsed = parse(additionalHeaderMarkup);
  }
  const canonicalHost = `https://${process.env.NEXT_PUBLIC_CANONICAL_HOST || 'liftwithboystown.org'}`;
  let canonicalUrl = null;
  if (path) {
    if (path === '/ask-the-trainer' && typeof window !== 'undefined') {
      const url = new URL(window.location.href);
      const video = url.searchParams.get('video');
      if (video) {
        path = `${path}?video=${video}`;
      }
    }
    canonicalUrl = `${canonicalHost}${path}`;
  }
  return (
    <Head>
      <title>{`${title} | Boys Town National Training`}</title>
      {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}
      {addNoRobots && <meta name='robots' content='noindex' />}
      <meta name='generator' content='Agility CMS' />
      <meta name='agility_timestamp' content={new Date().toLocaleString()} />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link href='https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap' rel='stylesheet' />
      {ogImage && <meta property='og:image' content={ogImage} />}
      {additionalMetaHtml}
      {additionalHeaderMarkupParsed}
    </Head>
  );
};

export default SEO;
