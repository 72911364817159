import { useQuery } from '@tanstack/react-query';
import { callApi } from 'services/apiWrapper';

export const teacherLookupKeys = {
  teacherList: (schoolId, districtId) => ['teacherList', schoolId, districtId],
};

export const fetchTeachers = async (schoolId, districtId) => {
  if (!schoolId || !districtId) return [];
  const res = await callApi(`/api/db/observation/teacher-list?school=${schoolId}&district=${districtId}`);
  return res.map((t) => t.teacher);
};

export const useFetchTeachers = (schoolId, districtId) => {
  return useQuery({
    queryKey: teacherLookupKeys.teacherList(schoolId, districtId),
    queryFn: () => fetchTeachers(schoolId, districtId),
    networkMode: 'offlineFirst',
    retry: false,
  });
};
