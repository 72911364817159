import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Heading6 from 'components/common/subcomponents/Typography/Heading6';

export default function DialogWithX(props) {
  const { size, content, ariaLabel, showValue, showMethod, title, titleBarColor, titleTextColor, closeXColor } = props;

  const ButtonContainer = styled.div`
    padding: 5px;
    width: 10%;
  `;

  return (
    <Dialog
      open={showValue}
      onClose={(e) => showMethod(false)}
      maxWidth={size}
      fullWidth={true}
      aria-labelledby={ariaLabel || 'dialog'}
      PaperProps={{
        style: {
          backgroundColor: props.titleColor || 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <React.Fragment>
        <div
          style={{
            backgroundColor: props.titleBarColor || 'transparent',
            color: props.titleTextColor || '#fff',
            display: 'flex',
            justifyContent: props.title ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {props.title && props.title.length > 0 && (
            <Heading6 color={props.titleTextColor} style={{ justifyContent: 'flex-start', width: '85%', display: 'flex', margin: '10px 5px' }}>
              {props.title}
            </Heading6>
          )}
          <ButtonContainer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <button
              style={{
                color: props.closeXColor || '#000',
                backgroundColor: props.titleBarColor || 'transparent',
              }}
              onClick={(e) => showMethod(false)}
            >
              <CloseIcon style={{ fontSize: '1.725rem', fontWeight: 700, color: props.closeXColor || '#FFF' }} />
            </button>
          </ButtonContainer>
        </div>
        <DialogContent style={{ overflowX: 'hidden', background: '#fff' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: 'fit-content', marginBottom: '35px' }}>{content}</Box>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  );
}

DialogWithX.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']).isRequired,
  content: PropTypes.object.isRequired,
  ariaLabel: PropTypes.string,
  showValue: PropTypes.bool.isRequired,
  showMethod: PropTypes.func.isRequired,
};
