import { useTheme } from '@emotion/react';
import { renderHTML } from '@agility/nextjs';
import { Box, TextField, Snackbar, FormControl, InputLabel, Select, MenuItem, Grow } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { KitButton } from '@boystownorg/bi-cms-component-lib';
import React, { useEffect, useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { emailRegex, isGuid } from 'services/stringUtils';

import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, LEAD_TITLES, OTHER_DISTRICT } from 'services/constants';
import SchoolSelector from 'components/common/subcomponents/SchoolSelector';
import { BackgroundImageStep, EventName, LabelText, SignedInText, ToSText, WelcomeText } from './EventRegistrationComponents';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const STEP_3_FOUND = 'Verify Your Information';
const STEP_3_NOTFOUND = 'Please Provide Your Information';

const EventRegistration = ({ module: { fields } }) => {
  const theme = useTheme();
  const [step, setStep] = useState(1);
  const { height, width } = useWindowDimensions();
  const searchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : new URLSearchParams();
  let eventId = searchParams.get('id') || null;
  const eventName = searchParams.get('name') || null;
  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [step3Label, setStep3Label] = useState(STEP_3_NOTFOUND);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [boxHeight, setBoxHeight] = useState(height - 90);
  const [formState, setFormState] = useState({
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    state: '',
    disrict_id: '',
    school_id: '',
    agency: '',
    city: '',
  });

  if (!isGuid(eventId)) {
    // must be a GUID
    eventId = null;
  }

  useEffect(() => {
    setInitialized(true);
    setBoxHeight(height - 90);

    if (formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id) {
      setBoxHeight(height + 100 - 90);
    }
  }, [height, formState.schoolInfo?.district]);

  const handleCheckEmail = async () => {
    setCheckedEmail(true);
    try {
      if (!formState.email || formState.email.length === 0) {
        return;
      } else if (!emailRegex.test(formState.email)) {
        return;
      }
      //  save/lookup email with eventId
      const body = { email: formState.email, event_id: eventId };
      setFetching(true);
      const res = await callApi('/api/db/event-registration', 'POST', body);
      if (res.exist) {
        setStep3Label(STEP_3_FOUND);
        setFormState({
          ...formState,
          email: res.id,
          event_id: res.event_id,
          first_name: res.bystwn_firstname,
          last_name: res.bystwn_lastname,
          title: res.bystwn_jobtitle,
          state: res.bystwn_state,
          district_id: res.district_id,
          school_id: res.school_id,
          school_name: res.school_name,
          other_agency: res.bystwn_otherinstitutionname || '',
          other_city: res.bystwn_city || '',
        });
      } else {
        setStep3Label(STEP_3_NOTFOUND);
      }
      setFetching(false);
      setStep(3);
    } catch (error) {
      setFetching(false);
      logClientException(error);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const handleUpdateData = async () => {
    setCheckedData(true);
    if (!canUpdateData()) {
      return;
    }

    try {
      //  save/lookup email with eventId
      const body = {
        email: formState.email,
        event_id: eventId,
        first_name: formState.first_name,
        last_name: formState.last_name,
        job_title: formState.title,
        state: formState.schoolInfo?.state?.value,
        district_id: formState.schoolInfo?.district?.value?.id,
        school_id: formState.schoolInfo?.school?.value?.id,
        school_name: formState.schoolInfo?.school?.value?.name,
        city: formState.schoolInfo?.other_city,
        agency: formState.schoolInfo?.other_agency,
      };

      setFetching(true);
      const res = await callApi('/api/db/event-registration', 'PUT', body);
      setFetching(false);
      setStep(4);
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setFetching(false);
      logClientException(error);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const canUpdateData = () => {
    let hasError = false;
    if (!formState.first_name || formState.first_name.length === 0) {
      hasError = true;
      formState.first_nameError = true;
    } else {
      formState.first_nameError = false;
    }
    if (!formState.last_name || formState.last_name.length === 0) {
      hasError = true;
      formState.last_nameError = true;
    } else {
      formState.last_nameError = false;
    }
    if (!formState.title || formState.title.length === 0) {
      hasError = true;
      formState.titleError = true;
    } else {
      formState.titleError = false;
    }
    if (!formState.schoolInfo?.state || formState.schoolInfo?.state.length === 0) {
      hasError = true;
      formState.stateError = true;
    } else {
      formState.stateError = false;
    }

    if (!formState.schoolInfo?.district?.value?.id || formState.schoolInfo?.district?.value?.id.length === 0) {
      hasError = true;
      formState.districtError = true;
    } else {
      formState.districtError = false;
    }

    if (
      formState.schoolInfo?.district?.value?.id !== OTHER_DISTRICT.id &&
      LEAD_TITLES.filter((t) => t.school_required).findIndex((x) => x.crm_val === formState.title) > -1 &&
      (!formState.schoolInfo?.school?.value?.id || formState.schoolInfo?.school?.value?.id.length === 0)
    ) {
      hasError = true;
      formState.schoolError = true;
    } else {
      formState.schoolError = false;
    }

    if (
      formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id &&
      (!formState.schoolInfo?.other_city || formState.schoolInfo?.other_city.length === 0)
    ) {
      hasError = true;
      formState.other_cityError = true;
    } else {
      formState.other_cityError = false;
    }

    if (
      formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id &&
      (!formState.schoolInfo?.other_agency || formState.schoolInfo?.other_agency.length === 0)
    ) {
      hasError = true;
      formState.other_agencyError = true;
    } else {
      formState.other_agencyError = false;
    }

    if (hasError) {
      setFormState({ ...formState, canSave: false });
    }
    return !hasError;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box sx={{ height: `${boxHeight}px` }}>
      <BackgroundImageStep
        imgUrl={[1, 4].includes(step) ? fields.backgroundImage.url : null}
        imgOpacity={fields.backgroundImageOpacity}
        accentColor={step === 1 ? fields.signInAccentColor : step === 4 ? fields.thankYouAccentColor : null}
      >
        <PleaseWait isLoading={fetching} />
        <Snackbar open={errorMessage && errorMessage.length > 0} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            width: '100%',
            mt: '1rem',
            ml: 'auto',
            mr: 'auto',
            maxWidth: { xs: '95%', sm: '540px', md: '600px' },
          }}
        >
          {initialized && !eventId && <EventName style={{ paddingTop: '20px' }}>{fields?.invalidLinkMessage}</EventName>}
          {eventId && step === 1 && (
            <Grow in={step === 1} timeout={500}>
              <div>
                <WelcomeText>{fields.welcomeMessage}</WelcomeText>
                {eventName && <EventName>{eventName}</EventName>}
                <KitButton onClick={() => setStep(2)} size='sm' round color='info' style={{ width: '160px', marginTop: '50px', fontSize: '1rem' }}>
                  Sign In
                </KitButton>
              </div>
            </Grow>
          )}
          {eventId && step === 2 && (
            <Grow in={step === 2} timeout={500} style={{ transformOrigin: '0 0 0' }}>
              <Box sx={{ paddingTop: '20%' }}>
                <LabelText>Continue with email</LabelText>
                <TextField
                  name='email'
                  value={formState.email}
                  error={checkedEmail && (formState.email === undefined || formState.email.length === 0 || !emailRegex.test(formState.email))}
                  required
                  margin='dense'
                  id='email'
                  label='Email'
                  type='email'
                  fullWidth
                  variant='standard'
                  onChange={(e) => setFormState({ ...formState, email: e.target.value?.toLowerCase() })}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true, sx: { fontSize: '1.25rem' } }}
                  onKeyUp={(e) => {
                    e.key === 'Enter' && handleCheckEmail();
                  }}
                />
                {fields.privacyToSStatement && <ToSText dangerouslySetInnerHTML={renderHTML(fields.privacyToSStatement)}></ToSText>}
                <KitButton onClick={handleCheckEmail} size='sm' round color='info' style={{ width: '160px', marginTop: '50px' }}>
                  Continue
                </KitButton>
              </Box>
            </Grow>
          )}
          {eventId && step === 3 && (
            <Grow in={step === 3} timeout={500} style={{ transformOrigin: '0 0 0' }}>
              <Box sx={{ paddingTop: '10%', paddingBottom: '10%' }}>
                <LabelText>{step3Label}</LabelText>
                <TextField
                  sx={{ marginBottom: '5px' }}
                  name='first_name'
                  value={formState.first_name}
                  error={checkedData && formState.first_nameError}
                  required
                  margin='dense'
                  id='first_name'
                  label='First Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  onChange={(e) => setFormState({ ...formState, first_name: e.target.value })}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true, sx: { fontSize: '1.25rem' } }}
                />
                <TextField
                  sx={{ marginBottom: '5px' }}
                  name='last_name'
                  value={formState.last_name}
                  error={checkedData && formState.last_nameError}
                  required
                  margin='dense'
                  id='last_name'
                  label='Last Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  onChange={(e) => setFormState({ ...formState, last_name: e.target.value })}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true, sx: { fontSize: '1.25rem' } }}
                />
                <FormControl fullWidth sx={{ marginTop: '12px' }}>
                  <InputLabel
                    style={{ padding: '-10px', marginBottom: '5px', marginLeft: -15, fontSize: '1.25rem' }}
                    shrink={true}
                    required
                    error={checkedData && formState.titleError}
                    id='type-select'
                  >
                    Title
                  </InputLabel>
                  <Select
                    name='title'
                    variant='standard'
                    labelId='title-select-label'
                    id='title-select-value'
                    error={checkedData && formState.titleError}
                    //size='small'
                    value={formState.title}
                    required
                    onChange={(e) => {
                      setFormState({ ...formState, title: e.target.value });
                    }}
                  >
                    <MenuItem value='' disabled>
                      Select Title
                    </MenuItem>
                    {LEAD_TITLES.map((t) => {
                      return (
                        <MenuItem value={t.crm_val} key={t.title}>
                          {t.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <SchoolSelector
                  labelSize='1.25rem'
                  state={formState.state}
                  district_id={formState.district_id}
                  school_id={formState.school_id}
                  school_name={formState.school_name}
                  other_city={formState.other_city}
                  other_agency={formState.other_agency}
                  requireCity={true}
                  stateError={formState.stateError}
                  districtError={formState.districtError}
                  schoolError={formState.schoolError}
                  other_cityError={formState.other_cityError}
                  other_agencyError={formState.other_agencyError}
                  requireSchool={
                    formState.schoolInfo?.district?.value.id !== OTHER_DISTRICT.id &&
                    LEAD_TITLES.filter((t) => !t.school_required).findIndex((x) => x.crm_val === formState.title) > -1
                      ? false
                      : true
                  }
                  onDataChange={(e) => {
                    if (e.district?.value?.id === OTHER_DISTRICT.id) {
                      if (typeof window !== 'undefined') {
                        window.dispatchEvent(new Event('resize'));
                      }
                    }
                    setFormState({ ...formState, schoolInfo: e });
                  }}
                />
                <KitButton onClick={handleUpdateData} size='sm' round color='info' style={{ width: '160px', marginTop: '50px' }}>
                  Sign In
                </KitButton>
              </Box>
            </Grow>
          )}
          {eventId && step === 4 && (
            <Grow in={step === 4} timeout={500} style={{ transformOrigin: '0 0 0' }}>
              <div>
                <WelcomeText isThankYou={true}>{fields.thankYouMessage}</WelcomeText>
                <SignedInText>{fields.signedInMessage}</SignedInText>
                {fields.otherClassesLink && (
                  <a href={fields.otherClassesLink.href}>
                    {fields.otherClassesLink.text} <ArrowForwardIcon style={{ color: theme.palette.warmRed.main }} />
                  </a>
                )}
              </div>
            </Grow>
          )}
        </Box>
      </BackgroundImageStep>
    </Box>
  );
};

export default EventRegistration;
