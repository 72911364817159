// package imports
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// local imports
import { callApi } from 'services/apiWrapper';
import { ObservationEvent, ObservationEventInstanceList } from 'types/ObservationTypes';

export const observationEventKeys = {
  observationEvents: () => ['observationEvents'],
  observationEventList: (date, district_id, school_id, observer_email, allDates) => [
    'observationEventList',
    date,
    district_id,
    school_id,
    observer_email,
    allDates,
  ],
  observationEventInstanceList: (id) => ['observationEventInstanceList', id],
};

export const fetchObservationEventInstanceList = async (id: string): Promise<ObservationEventInstanceList> => {
  if (id && id.length === 0) {
    return { event: null, instances: [] };
  }
  return await callApi(`/api/db/observation/event-observation-list?id=${id}`, 'GET');
};

export const useObservationEventInstanceList = (id: string) => {
  return useQuery({
    queryKey: observationEventKeys.observationEventInstanceList(id),
    queryFn: () => fetchObservationEventInstanceList(id),
    networkMode: 'offlineFirst',
  });
};

export const fetchObservationEvents = async (): Promise<Array<ObservationEvent>> => {
  const eventList = await callApi('/api/db/observation-event', 'GET');
  return eventList;
};

export const useObservationEvents = () => {
  return useQuery({
    queryKey: observationEventKeys.observationEvents(),
    queryFn: () => fetchObservationEvents(),
    networkMode: 'offlineFirst', // will attempt query one, then pause retries
    retry: false,
  });
};

export const fetchObservationEventList = async (
  date: string,
  district_id?: string,
  school_id?: string,
  observer_email?: string,
  allDates: boolean = false
): Promise<Array<ObservationEvent>> => {
  if (allDates && !district_id && !school_id) {
    return [];
  }
  let url = `/api/db/observation/event-list-summary?date=${date || ''}`;
  if (district_id) {
    url += `&district_id=${district_id}`;
  }
  if (school_id) {
    url += `&school_id=${school_id}`;
  }
  if (observer_email) {
    url += `&observer_email=${observer_email}`;
  }
  if (allDates) {
    url += `&allDates=${allDates}`;
  }
  const eventList = await callApi(url, 'GET');
  return eventList;
};

export const useObservationEventList = (
  date: string,
  district_id?: string,
  school_id?: string,
  observer_email?: string,
  allDates: boolean = false
) => {
  return useQuery({
    queryKey: observationEventKeys.observationEventList(date, district_id, school_id, observer_email, allDates),
    queryFn: () => fetchObservationEventList(date, district_id, school_id, observer_email, allDates),
    networkMode: 'offlineFirst', // will attempt query one, then pause retries
    retry: false,
  });
};

const addObservationEvent = async (event: ObservationEvent): Promise<ObservationEvent> => {
  return await callApi('/api/db/observation-event', 'POST', event);
};

const updateObservationEvent = async (event: ObservationEvent): Promise<ObservationEvent> => {
  return await callApi('/api/db/observation-event', 'PUT', event);
};

export const useAddObservationEvent = (event: ObservationEvent) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: addObservationEvent,
    onSuccess: (data) => {
      client.invalidateQueries({
        queryKey: ['observationEventList', observationEventKeys.observationEvents()],
      });
    },
    networkMode: 'offlineFirst', // will attempt query one, then pause retries
    retry: false,
  });
};

export const useUpdateObservationEvent = (event: ObservationEvent) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: updateObservationEvent,
    onSuccess: (data) => {
      client.invalidateQueries({
        queryKey: ['observationEventList', observationEventKeys.observationEvents(), observationEventKeys.observationEventInstanceList(data.id)],
      });
    },
    networkMode: 'offlineFirst', // will attempt query one, then pause retries
    retry: false,
  });
};
