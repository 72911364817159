import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { getQRCodeImageDataUrl } from '../../../../services/qrCodeUtils';
import { getHost } from '../../../../services/stringUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const EmailDomainQRCode = (props) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [url, setUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const getQRCode = async (r) => {
      const url = `https://${getHost()}?did=${r.district_id}&st=${r.state}`;
      const src = await getQRCodeImageDataUrl({ text: url });
      setUrl(url);
      setImgSrc(src);
    };

    getQRCode(props.emailDomain);
  }, [props.emailDomain]);

  const copyImgToClipboard = async () => {
    try {
      const img = await fetch(imgSrc);
      const imgBlob = await img.blob();
      await navigator.clipboard.write([
        // eslint-disable-next-line no-undef
        new ClipboardItem({
          [imgBlob.type]: imgBlob,
        }),
      ]);
      setOpen(true);
      setMessage('QR Code copied to clipboard!');
    } catch (error) {
      console.error(error);
    }
  };

  const copyLink = async () => {
    try {
      const urlBlob = new Blob([url], { type: 'text/plain' });
      await navigator.clipboard.write([
        // eslint-disable-next-line no-undef
        new ClipboardItem({
          [urlBlob.type]: urlBlob,
        }),
      ]);
      setOpen(true);
      setMessage('Link copied to clipboard!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: { sm: '175px', xs: 'auto' } }}>
      <img src={imgSrc} alt='QR Code' />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <KitButton simple color='primary' onClick={copyImgToClipboard}>
          Copy QR Code
        </KitButton>
        <KitButton simple color='primary' onClick={copyLink}>
          Copy Link
        </KitButton>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='success' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

EmailDomainQRCode.propTypes = {
  emailDomain: PropTypes.object.isRequired,
};

export default EmailDomainQRCode;
