import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';

import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import { OTHER_SCHOOL, OTHER_DISTRICT } from 'services/constants';

const BannerAccount = (props) => {
  const { title, titleTextColor, bannerBackgroundColor } = props.module.fields;
  const { data: session, status } = useSession();
  let school_name = session?.user?.school_name;
  if (school_name === OTHER_SCHOOL.name) {
    if (session?.user?.district_name !== OTHER_DISTRICT.name) {
      school_name = session?.user?.district_name;
    } else {
      school_name =
        session?.user?.other_city?.length > 0 && session?.user?.other_agency?.length > 0
          ? `Agency: ${session?.user?.other_agency} (${session?.user?.other_city})`
          : 'Agency: N/A';
      ('Agency: N/A');
    }
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: { md: 'center', xs: 'flex-start' }, margin: { md: '60px 60px 5px 60px', xs: '20px' } }}>
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: bannerBackgroundColor, width: 1180 }}> */}
      <Container backgroundColor={bannerBackgroundColor}>
        <Heading3 color={titleTextColor}>{title}</Heading3>
        <Box sx={{ marginTop: { xs: '10px', md: 0 } }}>
          <Typography color={titleTextColor} fontSize='22px' fontWeight='600' lineHeight='27.65px'>
            {session?.user?.firstName} {session?.user?.lastName}
          </Typography>
          <Typography color={titleTextColor} fontSize='16px' fontWeight='400' lineHeight='20px'>
            {school_name}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default BannerAccount;

const Container = styled('div')(({ theme, backgroundColor }) => ({
  display: 'flex',
  backgroundColor: backgroundColor,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '1180px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
