// package imports
import { useQuery } from '@tanstack/react-query';

// local imports
import { callApi } from 'services/apiWrapper';

export const observerLookupKeys = {
  observerList: () => ['observerList'],
  observerListByDepartment: (department) => ['observerList', department],
  observerListByEvent: (event_id) => ['observerList', event_id],
};

export const fetchObservers = async () => {
  const res = await callApi(`/api/db/observation/observer-list`);
  return res.map((o) => ({ label: `${o.lastName}, ${o.firstName}`, value: o.emailAddress }));
};

export const useFetchObservers = () => {
  return useQuery({
    queryKey: observerLookupKeys.observerList(),
    queryFn: () => fetchObservers(),
    networkMode: 'offlineFirst',
    retry: false,
  });
};

export const fetchObserversByDepartment = async (department: string) => {
  if (!department) {
    return [];
  }
  const res = await callApi(`/api/db/observation/observer-list-dept?department=${department}`);
  return res.map((o) => ({ label: `${o.lastName}, ${o.firstName}`, value: o.emailAddress }));
};

export const useFetchObserversByDepartment = (department: string) => {
  return useQuery({
    queryKey: observerLookupKeys.observerListByDepartment(department),
    queryFn: () => fetchObserversByDepartment(department),
    networkMode: 'offlineFirst',
    retry: false,
  });
};

export const fetchObserversByEvent = async (event_id: string) => {
  if (!event_id) {
    return [];
  }
  const res = await callApi(`/api/db/observation/observer-list-dept?event_id=${event_id}`);
  return res.map((o) => ({ label: `${o.lastName}, ${o.firstName}`, value: o.emailAddress }));
};

export const useFetchObserversByEvent = (event_id: string) => {
  return useQuery({
    queryKey: observerLookupKeys.observerListByEvent(event_id),
    queryFn: () => fetchObserversByEvent(event_id),
    networkMode: 'offlineFirst',
    retry: false,
  });
};
