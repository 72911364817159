import { getVideoSrc } from '/services/videoUtils';

export function Video({ src, autoPlay, loop, children, className, ...props }) {
  const { type, url } = getVideoSrc(src, {
    autoPlay,
    loop,
  });

  let videoElement = null;

  if (type === 'iframe') {
    videoElement = (
      <iframe
        {...props}
        className='w-full h-full'
        src={url}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    );
  } else if (type === 'video') {
    videoElement = (
      <video {...props} className='w-full h-full' src={src} controls allow='autoplay' autoPlay={autoPlay} muted={autoPlay} loop={loop}>
        {children}
      </video>
    );
  }

  return <div className={'bg-black rounded-xl md:rounded-3xl overflow-hidden aspect-video'}>{videoElement}</div>;
}
