import { useEffect, useRef } from 'react';
import AddSearchClient from 'addsearch-js-client';
import { useQuery } from '@tanstack/react-query';
import usePrevious from 'hooks/usePrevious';

export const LIFT_CATEGORY = '0xliftwithboystown.org';
export const PER_PAGE = 10;
export const SEARCH_CATEGORIES = [
  { text: 'All', value: '' },
  { text: 'Blogs', value: '1xblog' },
];

export function useAddSearch(setup) {
  const clientRef = useRef(null);

  if (!clientRef.current && typeof window !== 'undefined') {
    const client = new AddSearchClient(process.env.NEXT_PUBLIC_ADDSEARCH_SITEKEY);

    setup?.(client);

    clientRef.current = client;
  }

  return clientRef.current;
}

export function useAddSearchQuery(client, variables, options) {
  const keyword = variables.query || '*';
  const prevKeyword = usePrevious(keyword);

  const result = useQuery({
    queryKey: ['search', variables, options?.perPage],
    queryFn: async () => {
      const { category, tag, page } = variables ?? {};

      const results = await new Promise((resolve) => {
        client.setPaging(page, options?.perPage ?? 10, 'relevance', 'desc');

        client.setCategoryFilters(category || undefined);

        if (tag) {
          client.addCustomFieldFilter('tag', tag);
        } else {
          client.removeCustomFieldFilter('tag');
        }

        client.search(keyword, (results) => resolve(results));
      });

      return {
        category,
        keyword,
        page,
        perPage: client.getSettings().paging.pageSize,
        results,
        tag,
      };
    },

    enabled: options?.enabled,
    keepPreviousData: prevKeyword === keyword,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const data = result.data;

  useEffect(() => {
    if (!data) {
      return;
    }

    const { keyword, results } = data;

    const numberOfResults = results.total_hits;

    client.sendStatsEvent('search', keyword, {
      numberOfResults,
    });

    if (typeof window?.dataLayer !== 'undefined') {
      try {
        window.dataLayer.push({
          'event': 'view_search_results',
          'search_term': keyword,
          'num_results': numberOfResults,
          'search_or_click': 'search',
        });
      } catch (err) {
        console.log('error:gtm-search');
      }
    }
  }, [data?.keyword]);

  return result;
}

export function useAddSearchClickTracker(client, data) {
  if (!data) {
    return undefined;
  }

  return (id, position) => () => {
    const { keyword, page = 1, perPage, results } = data;

    const offset = (page - 1) * perPage;

    console.log(page, perPage);

    client.sendStatsEvent('click', keyword, {
      documentId: id,
      position: offset + position,
    });
    if (typeof window?.dataLayer !== 'undefined') {
      try {
        window.dataLayer.push({
          'event': 'view_search_results',
          'search_term': keyword,
          'url': results.hits?.find((r) => r.id === id)?.url,
          'search_or_click': 'click',
        });
      } catch (err) {
        console.log('error:gtm-search-click');
      }
    }
  };
}
