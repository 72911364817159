import DOMPurify from 'isomorphic-dompurify';
import parse, { domToReact } from 'html-react-parser';

const parseHighlightOptions = {
  replace(domNode) {
    if (domNode.tagName === 'em') {
      return <strong>{domToReact(domNode.children, parseHighlightOptions)}</strong>;
    }
  },
};

export const parseHighlight = (highlight) => {
  return parse(DOMPurify.sanitize(highlight, { USE_PROFILES: { html: true } }), parseHighlightOptions);
};
