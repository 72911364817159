import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useSession } from 'next-auth/react';

const ActionImageandButtonWithBackground = ({ module }) => {
  const { fields } = module;

  const router = useRouter();
  const { data: session, status } = useSession();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justifycontent: center;
    align-items: flex-end;
    background-image: url('${fields.backgroundImage.url}');
    background-size: cover;
    background-repeat: no-repeat;
    ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
    padding: 0 30px;
    position: relative;
    @media screen and (max-width: 899px) {
      min-height: 150px;
    }
    @media screen and (min-width: 900px) {
      min-height: 475px;
    }
  `;

  const EmptyContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      padding: 60px 10px;
      ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
      ${fields.imageLocation === 'center' ? 'max-width: 20%;' : 'max-width: 50%;'}
    }
  `;

  const FloatingButton = styled.div`
    vertical-align: top;
    padding: 60px 10px;
    position: absolute;
    top: 60%;
    left: 57%;
    ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
    ${fields.imageLocation === 'center' ? 'max-width: 20%;' : 'max-width: 50%;'}
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileFloatingButton = styled.div`
    vertical-align: top;
    padding: 15px 10px;
    position: absolute;
    top: 66%;
    left: 55%;
    ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
    ${fields.imageLocation === 'center' ? 'max-width: 20%;' : 'max-width: 50%;'}
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const ImageContainer = styled.div`
    margin: 0;
    position: relative;
    @media screen and (min-width: 900px) {
      ${fields.imageLocation === 'center' ? 'max-width: 43%;' : 'max-width: 50%;'}
      margin-left: 9%;
      height: 100%;
    }
    @media screen and (max-width: 899px) {
      ${fields.imageLocation === 'center' ? 'max-width: 75%;' : 'max-width: 50%;'}
      margin-left: 15%;
      height: 135%;
    }
  `;

  return (
    <React.Fragment>
      {!session && (
        <Container>
          <EmptyContainer></EmptyContainer>
          <ImageContainer>
            {fields.heroImage && <img src={fields.heroImage.url} alt={fields.heroImage.text} />}
            <>
              <FloatingButton>
                {fields.buttonUrl && fields.buttonUrl.href && fields.buttonUrl.href.length > 0 && (
                  <a href={fields.buttonUrl.href} target={fields.buttonUrl.target} rel='noopener noreferrer'>
                    <KitButton color={fields.buttonColor} round>
                      {fields.buttonText}
                    </KitButton>
                  </a>
                )}
              </FloatingButton>
              <MobileFloatingButton>
                {fields.buttonUrl && fields.buttonUrl.href && fields.buttonUrl.href.length > 0 && (
                  <Link href={fields.buttonUrl.href} passHref legacyBehavior>
                    <KitButton color={fields.buttonColor} round>
                      <a href={fields.buttonUrl.href} target={fields.buttonUrl.target} rel='noopener noreferrer'>
                        {fields.buttonText}
                      </a>
                    </KitButton>
                  </Link>
                )}
              </MobileFloatingButton>
            </>
          </ImageContainer>
        </Container>
      )}
    </React.Fragment>
  );
};

export default ActionImageandButtonWithBackground;
