import { Box } from '@mui/material';

const PageContainer = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        mt: '1rem',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { xs: '375px', sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      {children}
    </Box>
  );
};

export default PageContainer;
