import styled from '@emotion/styled';

const CardGrid = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '20px 45px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '0 2.00em',
}));

export default CardGrid;
