// package imports
import { KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// local imports
import { OTHER_SCHOOL } from 'services/constants';
import { lastNameFirst2FirstNameLast } from 'services/stringUtils';
import { displayActivity } from 'services/observationUtils';

const SessionListCard = ({ session, sessionSummaryLink }) => {
  const theme = useTheme();

  const sessionDateDisplay = () => {
    const sessionDate = session?.timer?.length > 0 ? session.timer[0].timer_start : null;
    if (!sessionDate) return 'N/A';
    return DateTime.fromMillis(sessionDate).toFormat('MM/dd/yyyy');
  };

  const sessionTimeDisplay = () => {
    const sessionStart = session?.timer?.length > 0 ? session.timer[0].timer_start : null;
    const sessionEnd = session?.timer?.length > 0 ? session.timer[session.timer.length - 1].timer_end : null;
    return `Session time: ${sessionStart ? DateTime.fromMillis(sessionStart).toFormat('hh:mm a') : 'N/A'} - ${
      sessionEnd ? DateTime.fromMillis(sessionEnd).toFormat('hh:mm a') : 'N/A'
    }`;
  };

  const subject = session.other_subject?.length > 0 ? session.other_subject : session.subject;

  return (
    <Box>
      <KitCard style={{ width: '290px', marginTop: 0, marginBottom: 0, height: '350' }}>
        <Box
          id='header'
          sx={{
            minHeight: '79px',
            color: theme.palette.common.white,
            fontWeight: 600,
            backgroundColor: theme.palette.obsAqua.main,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            padding: '.5rem 1rem .3rem 1rem',
          }}
        >
          <Typography component='div' sx={{ fontSize: '20px', lineHeight: '25px' }}>
            {sessionDateDisplay()}
          </Typography>
          <Typography component='div' sx={{ fontSize: '16px', lineHeight: '20px' }}>
            {sessionTimeDisplay()}
          </Typography>
          <Typography component='div' sx={{ fontSize: '16px', lineHeight: '20px' }}>
            {session.school_year_section || ''}
          </Typography>
        </Box>

        <KitCardBody style={{ padding: '.8rem' }}>
          <Box id='card-body' sx={{ height: '100%' }}>
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 400,
                color: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                gap: '.5rem',
                height: '100%',
              }}
            >
              <div>{session.classroom_type}</div>
              <div>
                {lastNameFirst2FirstNameLast(session.teacher)}{' '}
                {session.room_number && session.room_number.length > 0 ? '(Room ' + session.room_number + ')' : ''}
              </div>
              <div>{session.agency_id === OTHER_SCHOOL.id ? session.district_name : session.agency_name}</div>
              <div>{session.grade?.length > 0 ? session.grade : 'Grade: N/A'}</div>
              <div>{subject?.length > 0 ? subject : 'Subject: N/A'}</div>
              <div>{session && displayActivity(session)}</div>
              <div>Observed by: {lastNameFirst2FirstNameLast(session.observer_name)}</div>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignSelf: 'flex-end', marginTop: 'auto' }}>
                <KitButton
                  size='sm'
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    width: '220px',
                  }}
                  href={`${sessionSummaryLink.href}?id=${session.id}`}
                >
                  <VisibilityIcon />
                  {sessionSummaryLink.text}
                </KitButton>
              </Box>
            </Box>
          </Box>
        </KitCardBody>
      </KitCard>
    </Box>
  );
};

SessionListCard.propTypes = {
  session: PropTypes.object,
  sessionSummaryLink: PropTypes.object,
};

export default SessionListCard;
