import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';

const FeaturedVideo = ({ module }) => {
  const { fields } = module;

  const router = useRouter();

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 899px) {
      min-height: 260px;
    }
    @media screen and (min-width: 900px) {
      min-height: 300px;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    minheight: inherit;
    @media screen and (max-width: 899px) {
      padding: 1% 0;
    }
    @media screen and (min-width: 900px) {
      padding: 10% 0;
    }
  `;

  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.750),rgba(0, 0, 0, 0.5)), url(${fields.backgroundImage.url})`,
        }}
      >
        <ArrowLeftCorner>
          <ArrowText>Free Module</ArrowText>
        </ArrowLeftCorner>
        {/* <CornerLayerContainer> */}
        {/* <CornerContainer>
            <CornerTag aria-label='Free Module' onClick={() => handleClickRegistration(s)}>
              Free Module
            </CornerTag>
          </CornerContainer> */}
        <TextContainer>
          <SeriesName style={{ color: fields.textColor }}>{fields.seriesName}</SeriesName>
          <VideoTitle style={{ color: fields.textColor }}>{fields.title}</VideoTitle>
          <Description style={{ color: fields.textColor }}>{fields.description}</Description>
          <div style={{ marginTop: 30 }}>
            <Link href={fields.videoUrl} passHref legacyBehavior>
              <KitButton
                color={fields.actionButtonColor}
                round
                style={{ margin: '0', padding: '10px 40px', fontSize: '1.1875rem', fontWeight: 300, lineHeight: '23px' }}
              >
                {fields.actionButtonLabel}
              </KitButton>
            </Link>
          </div>
        </TextContainer>
        {/* </CornerLayerContainer> */}
      </Container>
    </React.Fragment>
  );
};

export default FeaturedVideo;

const SeriesName = styled('div')(({ theme }) => ({
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.40rem',
    lineHeight: '2.05rem',
    margin: '5px 60px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const VideoTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    fontSize: '2.50rem',
    lineHeight: '3.00rem',
    margin: '15px 0',
    maxWidth: '65%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.35rem',
    margin: '25px 0',
    maxWidth: '95%',
  },
}));

const Description = styled('div')(({ theme }) => ({
  fontWeight: 100,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.55rem',
    margin: '5px 0',
    maxWidth: '50%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CornerLayerContainer = styled('div')(({ theme }) => ({
  minHeight: 'inherit',
  padding: 0,
}));

const CornerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

const CornerTag = styled('div')(({ theme }) => ({
  margin: '0 0 0 0',
  position: 'relative',
  top: 0,
  right: 0,
  justifyContent: 'center',
  fontSize: '1.10rem',
  fontWeight: 300,
  backgroundColor: '#B34520',
  color: theme.palette.common.white,
  padding: '5px 30px',
  borderRadius: '0 0 0 0',
  zIndex: 10,
  cursor: 'pointer',
}));

const ArrowLeftCorner = styled('div')(({ theme }) => ({
  borderLeft: '100px solid #B34520',
  borderRight: '100px solid transparent',
  borderBottom: '100px solid transparent',
  height: 0,
  width: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 2,
}));

const ArrowText = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '0.95rem',
  fontWeight: 600,
  lineHeight: '1.00rem',
  textTransform: 'uppercase',
  left: '-100px',
  top: '12px',
  position: 'absolute',
  width: '70px',
  transform: 'rotate(315deg)',
  textAlign: 'center',
}));
