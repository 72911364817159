import React, { useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import NewsLetterComponent from './NewsletterComponent';

const Newsletter = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();

  const [showPage, setShowPage] = useState(true);

  // If session, hide, otherwise show.
  useEffect(() => {
    if (session) {
      setShowPage(false);
    } else {
      setShowPage(true);
    }
  }, [session, fields]);

  if (!showPage) {
    return null;
  }

  return (
    <NewsLetterComponent
      cTAText1='Create a free account to access unlimited resources and receive our monthly eNewsletter.'
      cTAText2="Our lesson plans and downloadable activities are resources you won't find anywhere else."
      unsubscribeText='You can unsubscribe any time via email.'
      cTAlink={{ href: '/signin?nl=1', text: 'Sign Up', target: '' }}
      cTALinkColor='light'
      backgroundColor='gold'
      accentImage={fields.newsletterImage}
    />
  );
};

export default Newsletter;
