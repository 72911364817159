import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '@emotion/react';
import { Box, IconButton, Typography } from '@mui/material';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DateTime } from 'luxon';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import { useObservationStore } from '../../stores/observationStore';
import TimerPausedDialog from '../TimerPausedDialog';
import TimerStartDialog from '../TimerStartDialog';

const ObservationTimer = ({ nextUrl }) => {
  const theme = useTheme();
  const router = useRouter();
  const pauseLength = 1800;
  const [timerString, setTimerString] = useState('00:00');
  const [intervalTimer, setIntervalTimer] = useState(null);
  const { observation, startTimer, stopTimer, updateStudentCount, addTimelineObservation } = useObservationStore();
  const [autoPause, setAutoPause] = useState(pauseLength);
  const [showTimerStartDialog, setShowTimerStartDialog] = useState(true);
  const [showTimerPauseDialog, setShowTimerPauseDialog] = useState(false);

  useEffect(() => {
    if (
      observation.timer &&
      observation.timer.length > 0 &&
      observation.timer[0].timer_start &&
      observation.timer[observation.timer.length - 1]?.timer_end === 0
    ) {
      const timer = setInterval(() => {
        let elapsedMS = 0;
        for (let i = 0; i < observation.timer.length; i++) {
          if (observation.timer[i].timer_end === 0) {
            elapsedMS += Date.now() - observation.timer[i].timer_start;
          } else {
            elapsedMS += observation.timer[i].timer_end - observation.timer[i].timer_start;
          }
        }
        const elapsedSeconds = elapsedMS / 1000;
        const minutes = Math.floor(elapsedSeconds / 60);
        const seconds = Math.floor(elapsedSeconds % 60);
        setTimerString(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        setIntervalTimer(timer);
        // If timer reaches time limit, pause it.
        if (elapsedSeconds > autoPause) {
          stopTimer('SYSTEM PAUSED');
          clearInterval(intervalTimer);
          setIntervalTimer(null);
          setShowTimerPauseDialog(true);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [observation.timer]);

  const observationStartTime = () => {
    if (!observation.timer || observation.timer.length === 0 || !observation.timer[0].timer_start) {
      return 'N/A';
    }
    return DateTime.fromMillis(observation.timer[0].timer_start).toFormat('t');
  };

  return (
    <>
      <KitCard style={{ width: '95%', marginTop: 0, marginBottom: 0, height: '200px' }}>
        <KitCardBody>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                if (intervalTimer) {
                  setShowTimerPauseDialog(true);
                  stopTimer('OBSERVER PAUSED');
                  clearInterval(intervalTimer);
                  setIntervalTimer(null);
                } else {
                  setShowTimerPauseDialog(false);
                  startTimer('RESUMED');
                }
              }}
            >
              {intervalTimer && <PauseCircleIcon style={{ fontSize: '54px', color: theme.palette.obsBlue.dark }} />}
              {!intervalTimer && <PlayCircleIcon style={{ fontSize: '54px', color: theme.palette.obsBlue.dark }} />}
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 400, color: theme.palette.text.light }}>Started at {observationStartTime()}</Typography>
              <Typography sx={{ fontSize: '32px', fontWeight: 600, color: theme.palette.obsBlue.dark }}>{timerString}</Typography>
            </Box>
            <KitButton
              size='sm'
              style={{
                backgroundColor: buttonColor(theme, 'obsBlue.dark'),
                height: '45px',
                width: '50px',
              }}
              onClick={() => {
                stopTimer('STOPPED');
                router.push({
                  pathname: nextUrl,
                });
              }}
            >
              End
            </KitButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: `1px solid ${theme.palette.obsBlue.dark}`,
              borderRadius: '24px',
              marginTop: '1rem',
            }}
          >
            <IconButton onClick={() => updateStudentCount(-1)}>
              <ArrowBackIcon style={{ color: theme.palette.obsBlue.dark }} />
            </IconButton>

            <Typography sx={{ fontWeight: 600, fontSize: '16px', color: theme.palette.obsBlue.dark }}>{`${
              observation.number_of_students === undefined ? '' : observation.number_of_students
            } Students`}</Typography>

            <IconButton onClick={() => updateStudentCount(1)}>
              <ArrowForwardIcon style={{ color: theme.palette.obsBlue.dark }} />
            </IconButton>
          </Box>
        </KitCardBody>
      </KitCard>
      <TimerStartDialog
        open={showTimerStartDialog}
        start={() => {
          startTimer('START');
          setShowTimerStartDialog(false);
        }}
        endObservation={() => {
          stopTimer('STOPPED');
          router.push({
            pathname: nextUrl,
            query: { id: observation.id },
          });
        }}
      />
      <TimerPausedDialog
        open={showTimerPauseDialog}
        resume={() => {
          setAutoPause(autoPause + pauseLength);
          startTimer('RESUMED');
          setShowTimerPauseDialog(false);
        }}
        endObservation={() => {
          stopTimer('STOPPED');
          router.push({
            pathname: nextUrl,
            query: { id: observation.id },
          });
        }}
      />
    </>
  );
};

export default ObservationTimer;
