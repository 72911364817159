import React, { useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Carousel from 'react-material-ui-carousel';
import { DateTime } from 'luxon';

const FeaturedTestimonials = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();
  const { data: session } = useSession();

  const [renewalSoon, setRenewalSoon] = useState(false);

  useEffect(() => {
    let product = '';
    if (session) {
      if (session.user) {
        // sorting by product and then date, and using last end_date (renewal)
        if (session.user.subscriptions) {
          session.user.subscriptions
            .sort((a, b) =>
              (a.product || '')
                .toString()
                .toLowerCase()
                .localeCompare(b.product || '')
                .toString()
                .toLowerCase()
            )
            .sort((b, a) => {
              return new Date(b.date) - new Date(a.date);
            })
            .forEach((s) => {
              if (s.end_date < DateTime.now().plus({ days: 45 }).toJSDate().getTime()) {
                product = s.product;
                setRenewalSoon(true);
              } else {
                setRenewalSoon(false);
              }
            });
        }
      }
    }
  }, [session]);

  const CarouselContainer = styled.div`
    margin: 0 0 10px 0;
    @media screen and (min-width: 900px) {
      width: 100%;
    }
    @media screen and (max-width: 899px) {
      width: 100%;
    }
  `;

  const FeaturedDescription = styled.div`
    color: ${theme.palette.common.white};
    background-color: transparent;
    font-weight: 600;
    @media screen and (min-width: 768px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  `;

  const FeaturedAuthorName = styled.div`
    color: ${theme.palette.common.white};
    background-color: transparent;
    font-weight: 300;
    @media screen and (min-width: 768px) {
      font-size: 1.28rem;
      line-height: 1.1rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.257rem;
    }
  `;

  function DisplayTestimonialInCarousel(props) {
    return (
      <FeaturedCardBodyContainer>
        <FeaturedDescriptionContainer>
          <FeaturedDescription>{props.testimonial.fields?.comment}</FeaturedDescription>
        </FeaturedDescriptionContainer>
        <FeaturedAuthorContainer>
          <FeaturedAuthorName>{props.testimonial.fields?.customerName_TextField}</FeaturedAuthorName>
        </FeaturedAuthorContainer>
      </FeaturedCardBodyContainer>
    );
  }

  return (
    <>
      {/* This component is hidden based on the hideComponent value.
            never = component is always displayed.
            session = component is hidden if a session exists.
            renewal = component is hidden if a subscription ends in the next 45 days. */}
      {fields &&
        (fields.hideComponent === '' ||
          fields.hideComponent === 'never' ||
          (fields.hideComponent === 'session' && !session) ||
          (fields.hideComponent === 'renewal' && !renewalSoon)) && (
          <Container
            style={{
              backgroundImage:
                fields.backgroundImage &&
                fields.backgroundImage.url &&
                `linear-gradient(rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)), url(${fields.backgroundImage.url})`,
            }}
          >
            {fields && fields.title && <FeaturedTitle>{fields?.title}</FeaturedTitle>}
            <CarouselContainer>
              <Carousel>
                {fields.testimonialList &&
                  fields.testimonialList.length > 0 &&
                  fields.testimonialList.map((testimonial, index) => {
                    return <DisplayTestimonialInCarousel key={index} testimonial={testimonial} />;
                  })}
              </Carousel>
            </CarouselContainer>
          </Container>
        )}
    </>
  );
};

export default FeaturedTestimonials;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    backgroundSize: '100%',
    backgroundPosition: 'right -10% bottom 60%',
    backgroundRepeat: 'no-repeat',
  },
  [theme.breakpoints.down('md')]: {
    backgroundSize: '450%',
    backgroundPosition: 'right 50% bottom 40%',
    backgroundRepeat: 'no-repeat',
  },
}));

const FeaturedCardBodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0 15px 0',
  height: '280px',
}));

const FeaturedAvatarContainer = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  zIndex: 20,
  [theme.breakpoints.up('md')]: {
    margin: '30px 30px 5px 30px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px 15px 5px 15px',
  },
}));

const FeaturedTitle = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'center',
  maxWidth: '90%',
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    padding: '80px 30px 0 30px',
    fontSize: '1.625rem',
    lineHeight: '2.438rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '50px 5px 0 5px',
    fontSize: '1.625rem',
    lineHeight: '2.438rem',
  },
}));

const FeaturedDescriptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    width: '70%',
    margin: '0 5% 0 5%',
    padding: '45px 0 25px 0',
  },
  [theme.breakpoints.down('md')]: {
    width: '80%',
    margin: '0 5% 0 5%',
    padding: '40px 0 30px 0',
  },
}));

const FeaturedAuthorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    width: '70%',
    margin: '0 5% 0 5%',
    padding: '20px 0 15px 0',
  },
  [theme.breakpoints.down('md')]: {
    width: '80%',
    margin: '0 5% 0 5%',
    padding: '10px 0 15px 0',
  },
}));
