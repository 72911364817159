import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import SummaryTitle from './pageComponents/SummaryTitle';
import SummaryDescriptionText from './pageComponents/SummaryDescriptionText';
import SummaryAdditionalText from './pageComponents/SummaryAdditionalText';

const SummaryWithBackgroundImage = ({ module }) => {
  const { fields } = module;

  const router = useRouter();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const imageExists = fields.summaryImage && fields.summaryImage.url && fields.summaryImage.url.length > 0;

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-end;
    background-image: url('${fields.backgroundImage.url}');
    @media screen and (max-width: 899px) {
      background-image: none;
      background-size: 0;
    }
    @media screen and (min-width: 900px) {
      background-size: 100%;
      background-repeat: no-repeat;
      ${fields.contentAlign === 'right' ? (imageExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
    position: relative;
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (max-width: 899px) {
      margin: 10px;
      padding: 40px 20px 40px 20px;
      max-width: 90%;
    }
    @media screen and (min-width: 900px) {
      padding: 60px 10px;
      ${imageExists ? 'max-width: 40%;' : 'max-width: 90%;'}
  `;

  const ImageContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 50%;
    margin: 0;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      ${!imageExists && 'display: none;'}
      ${imageExists ? 'max-width: 50%;' : 'max-width: 0'}
      ${imageExists ? 'max-height: 95%;' : 'max-height: 0'}
    }
  `;

  return (
    <React.Fragment>
      <Container>
        <ContentContainer>
          <SummaryTitle content={fields.title} textColor={fields.titleColor} />

          <SummaryDescriptionText content={fields.description} textColor={fields.textColor} />

          <SummaryAdditionalText content={fields.additionalText} textColor={fields.additionalTextColor} textSize={fields.additionalTextSize} />

          <div style={{ marginTop: 30 }}>
            {fields.actionButtonUrl && fields.actionButtonUrl.text && fields.actionButtonUrl.text.length > 0 && (
              <Link href={fields.actionButtonUrl.href} passHref legacyBehavior>
                <KitButton color={fields.actionButtonColor} round>
                  <a href={fields.actionButtonUrl.href} target={fields.actionButtonUrl.target} rel='noopener noreferrer'>
                    {fields.actionButtonUrl.text}
                  </a>
                </KitButton>
              </Link>
            )}
          </div>
        </ContentContainer>
        <ImageContainer>{fields.summaryImage && <img src={fields.summaryImage?.url} alt={fields.title} />}</ImageContainer>
      </Container>
    </React.Fragment>
  );
};

export default SummaryWithBackgroundImage;
