import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import PageContainer from 'components/common/subcomponents/PageContainer';
import Heading1 from 'components/common/subcomponents/Typography/Heading1';

const BannerWithImage = ({ module: { fields } }) => {
  const theme = useTheme();
  return (
    <PageContainer>
      <BannerContainer>
        {fields?.rightImage?.url && (
          <img src={`${fields?.rightImage?.url}`} alt={fields?.rightImage?.label} style={{ position: 'absolute', top: 0, right: 0 }} />
        )}
        <div style={{ alignSelf: 'center', position: 'relative' }}>
          <Heading1 homepage={false}>{fields?.heading}</Heading1>
        </div>
      </BannerContainer>
      <FullWidthHR />
    </PageContainer>
  );
};

const FullWidthHR = styled('hr')(({ theme }) => ({
  width: '200%',
  borderTopWidth: '.5px',
  position: 'absolute',
  left: '-200px',
  borderColor: theme.palette.grey.A400,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    borderTopWidth: '.5px',
    position: 'absolute',
    left: 0,
  },
}));

const BannerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  height: '250px',
  '> img': {
    height: '260px',
  },
  [theme.breakpoints.down('md')]: {
    height: '125px',
    '> img': {
      height: '125px',
    },
  },
}));

export default BannerWithImage;
