// package imports
import { useTheme } from '@emotion/react';
import { Backdrop, Box, Button, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import PropTypes from 'prop-types';

const RedirectDialog = ({ open, message, redirectTo, buttonText, buttonColor }) => {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
        marginTop: { xs: '100px', sm: '150px' },
      }}
      open={open}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1.5rem',
          borderRadius: '8px',
        }}
      >
        <Typography variant='h6' sx={{ padding: '20px' }}>
          {message}
        </Typography>
        <Button
          variant='contained'
          href={redirectTo}
          sx={{ width: '110px', backgroundColor: buttonColor ? buttonColor : theme.palette.primary.main }}
        >
          {buttonText}
        </Button>
      </Box>
    </Backdrop>
  );
};

RedirectDialog.defaultProps = {
  open: false,
  message: 'Please sign in to continue.',
  redirectTo: '/signin',
  buttonText: 'Sign In',
};

RedirectDialog.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  redirectTo: PropTypes.string,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
};

export default RedirectDialog;
