import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const Heading4 = (props) => {
  const HandleSmallDevices = styled('Typography')(({ theme }) => ({
    fontWeight: 100,
    [theme.breakpoints.up('md')]: {
      fontSize: '38px',
      lineHeight: '47.77px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
      lineHeight: '44.275px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32.275px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
  }));

  const { color, children, ...rest } = props;
  return (
    <HandleSmallDevices {...rest} component='h4' color={color}>
      {children}
    </HandleSmallDevices>
  );
};

export default Heading4;
