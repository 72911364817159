import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';

const Placeholder = ({ module }) => {
  return (
    <Container>
      <TitleContainer>
        <IntroTitle>Placeholder</IntroTitle>
      </TitleContainer>
      <TextContainer>
        <Description>This is a placeholder component to be used during development to indicate where a CMS module is still needed.</Description>
      </TextContainer>
    </Container>
  );
};

export default Placeholder;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: '#DCDCDC',
  // width: '90%',
  padding: 50,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TitleContainer = styled('div')(({ theme }) => ({
  maxWidth: '600px',
  alignSelf: 'center',
}));

const IntroTitle = styled('div')(({ theme }) => ({
  fontSize: '1.65rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
  fontWeight: 600,
  margin: '8px 20px 8px 0px',
  alignSelf: 'center',
}));

const TextContainer = styled('div')(({ theme }) => ({
  maxWidth: '600px',
  alignSelf: 'center',
}));

const Description = styled('div')(({ theme }) => ({
  fontSize: '0.85rem',
  fontWeight: 300,
  color: theme.palette.common.black,
  margin: 5,
}));
