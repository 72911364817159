// packages
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// local imports
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { OBS_ACTIVITY_LIST } from 'services/constants';
import { getSessionStoreString } from 'services/storageUtils';
import ObservationHeader from './components/ObservationHeader';
import ObservationPageContainer from './components/ObservationPageContainer';
import SessionChecklists from './components/SessionChecklists';
import { ObsStyles } from './components/StyledComponents';
import { useObservationStore } from './stores/observationStore';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';

const ObservationSessionEnd = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const router = useRouter();
  const styles = ObsStyles(theme);

  const { observation, patchObservation } = useObservationStore();

  const [isInitalized, setIsInitalized] = useState(false);
  const [eventState, setEventState] = useState({
    activity: [],
    phys_env_notes: '',
    skills_posters: false,
    classroom_rules: false,
    schoolwide_rules: false,
    classroom_procedures: false,
    schoolwide_procedures: false,
    consequences: false,
    daily_schedule: false,
    rel_bld_notes: '',
    atsmosphere: false,
    solicits: false,
    positive_comments: false,
    quality_components: false,
    cards_on_desk: false,
    students_write_cards: false,
  });

  const checkStatus = (obs) => {
    if (!obs || !obs.status) {
      // no session in progress
      // redirect to the observation landing
      router.push(fields?.observationLanding?.href);
    }

    if (obs?.status === 'Complete') {
      // redirect to the observation complete page
      router.push(fields?.sessionReview?.href);
    }

    // could be coming from the summary page
    const fromSummary = getSessionStoreString('summaryReview') === 'true';
    if (obs?.status === 'Under Review' && !fromSummary) {
      router.push(fields?.sessionSummary?.href);
    }
  };

  useEffect(() => {
    if (isInitalized) {
      checkStatus(observation);
    }
    setEventState({
      ...eventState,
      activity: observation.activity ?? [],
      other_activity: observation.other_activity ?? '',
      phys_env_notes: observation.phys_env_notes ?? '',
      skills_posters: !!observation.skills_posters,
      classroom_rules: !!observation.classroom_rules,
      schoolwide_rules: !!observation.schoolwide_rules,
      classroom_procedures: !!observation.classroom_procedures,
      schoolwide_procedures: !!observation.schoolwide_procedures,
      consequences: !!observation.consequences,
      daily_schedule: !!observation.daily_schedule,
      rel_bld_notes: observation.rel_bld_notes ?? '',
      atsmosphere: !!observation.atsmosphere,
      solicits: !!observation.solicits,
      positive_comments: !!observation.positive_comments,
      quality_components: !!observation.quality_components,
      cards_on_desk: !!observation.cards_on_desk,
      students_write_cards: !!observation.students_write_cards,
    });
    setIsInitalized(true);
  }, [observation]);

  const eventStateOnChange = (key, value) => {
    console.log('eventStateOnChange', key, value);
    let canSave = true;
    let fieldError = false;

    setEventState({ ...eventState, [key]: value, [`${key}Error`]: fieldError, canSave: canSave });
  };

  const saveAndContinue = (nextUrl) => {
    if (eventState.activity.indexOf('Other') > -1 && (!eventState.other_activity || eventState.other_activity.length === 0)) {
      setEventState({ ...eventState, other_activityError: true });
      return;
    }

    // save the observation
    patchObservation([
      { name: 'activity', value: eventState.activity },
      { name: 'other_activity', value: eventState.other_activity },
      { name: 'phys_env_notes', value: eventState.phys_env_notes },
      { name: 'skills_posters', value: eventState.skills_posters },
      { name: 'classroom_rules', value: eventState.classroom_rules },
      { name: 'schoolwide_rules', value: eventState.schoolwide_rules },
      { name: 'classroom_procedures', value: eventState.classroom_procedures },
      { name: 'schoolwide_procedures', value: eventState.schoolwide_procedures },
      { name: 'consequences', value: eventState.consequences },
      { name: 'daily_schedule', value: eventState.daily_schedule },
      { name: 'rel_bld_notes', value: eventState.rel_bld_notes },
      { name: 'atsmosphere', value: eventState.atsmosphere },
      { name: 'solicits', value: eventState.solicits },
      { name: 'positive_comments', value: eventState.positive_comments },
      { name: 'quality_components', value: eventState.quality_components },
      { name: 'status', value: 'Under Review' },
      { name: 'cards_on_desk', value: eventState.cards_on_desk },
      { name: 'students_write_cards', value: eventState.students_write_cards },
    ]);

    // navigate to next url
    router.push(nextUrl);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleActivityChange = (event) => {
    const {
      target: { value },
    } = event;
    // eventStateOnChange('activity', e.target.value);
    eventStateOnChange(
      'activity',
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '2rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} />
      <ObservationPageContainer>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />
        <Box
          sx={{
            mt: '2rem',
            backgroundColor: theme.palette.obsBlue.light,
            pb: '.5rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormControl fullWidth size='small' style={{ marginRight: 6 }}>
            <InputLabel id='activity-select-label'>Activity </InputLabel>
            <Select
              id='activity-select'
              labelId='activity-select-label'
              multiple
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              label='Activity'
              size='small'
              value={eventState.activity}
              onChange={(e) => {
                eventStateOnChange('activity', e.target.value);
              }}
              error={eventState.activityError}
              required
              input={<OutlinedInput label='Activity' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {OBS_ACTIVITY_LIST.map((e) => {
                return (
                  <MenuItem value={e.value} key={e.value}>
                    <Checkbox checked={eventState.activity.indexOf(e.value) > -1} />
                    <ListItemText primary={e.name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            size='small'
            sx={{ display: eventState.activity.indexOf('Other') > -1 ? 'inline-flex' : 'none' }}
            style={{ marginLeft: 3 }}
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ maxLength: 200 }}
              label='Other Activity'
              id='other-activity'
              error={eventState.other_activityError}
              size='small'
              value={eventState.other_activity}
              required
              onChange={(e) => {
                eventStateOnChange('other_activity', e.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1rem' }}>
          <SessionChecklists eventState={eventState} eventStateOnChange={eventStateOnChange} />

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
            <KitButton
              size='sm'
              onClick={() => saveAndContinue(fields.sessionSummary?.href)}
              style={{
                backgroundColor: theme.palette.obsBlue.dark,
                width: '200px',
              }}
            >
              <span>
                <ArrowRightIcon sx={{ margin: '-7px', width: '32px !important', height: '32px !important' }} /> {fields.sessionSummary?.text}
              </span>
            </KitButton>
          </Box>
        </Box>
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationSessionEnd;
