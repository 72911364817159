// package imports
import { useState } from 'react';
import { KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

// local imports
import { OTHER_SCHOOL } from 'services/constants';
import AddEventDialog from './AddEventDialog';

const EventListCard = ({ eventSummary, sessionListLink, eventSummaryLink, allowEdit, user, onUpdated }) => {
  const theme = useTheme();
  const [showEditEvent, setShowEditEvent] = useState(false);

  const eventDateDisplay = () => {
    const startDate = DateTime.fromISO(eventSummary.start_date).startOf('day');
    const endDate = DateTime.fromISO(eventSummary.end_date).startOf('day');
    if (startDate.equals(endDate)) {
      return `Date: ${startDate.toFormat('MM/dd/yyyy')}`;
    }
    return `Date: ${startDate.toFormat('MM/dd/yyyy')} - ${endDate.toFormat('MM/dd/yyyy')}`;
  };

  const observersDisplay = () => {
    const observers = eventSummary.observers?.slice()?.sort();
    const returnList = [];
    observers?.sort();
    observers?.forEach((o) => {
      const temp = o.split(',');
      o = temp[1]?.trim() + ' ' + temp[0]?.trim();
      returnList.push(o);
    });
    return returnList?.join(', ');
  };

  return (
    <Box>
      <KitCard style={{ width: '290px', marginTop: 0, marginBottom: 0, height: '290px' }}>
        <Box
          id='header'
          sx={{
            minHeight: '79px',
            color: theme.palette.common.white,
            fontWeight: 600,
            backgroundColor: theme.palette.obsAqua.main,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            padding: '.5rem 1rem .3rem 1rem',
          }}
        >
          <Typography component='div' sx={{ fontSize: '20px', lineHeight: '25px' }}>
            {eventDateDisplay()}
            {allowEdit && (
              <IconButton style={{ float: 'right' }} onClick={() => setShowEditEvent(true)}>
                <EditIcon sx={{ color: theme.palette.common.white }} />
              </IconButton>
            )}
          </Typography>
          <Typography component='div' sx={{ fontSize: '16px', lineHeight: '20px' }}>
            {eventSummary.agency_id === OTHER_SCHOOL.id ? eventSummary.district_name : eventSummary.agency_name}
          </Typography>
          <Typography component='div' sx={{ fontSize: '16px', lineHeight: '20px' }}>
            {eventSummary.school_year_section || ''}
          </Typography>
        </Box>

        <KitCardBody style={{ padding: '.8rem' }}>
          <Box id='card-body' sx={{ height: '100%' }}>
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 400,
                color: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                gap: '.5rem',
                height: '100%',
              }}
            >
              <div>Observed by: {observersDisplay()}</div>
              <div># of observation sessions: {eventSummary.num_observations}</div>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignSelf: 'flex-end', marginTop: 'auto' }}>
                <KitButton
                  size='sm'
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    width: '220px',
                  }}
                  disabled={eventSummary.num_observations === 0}
                  href={`${eventSummaryLink.href}?id=${eventSummary.id}`}
                >
                  <VisibilityIcon />
                  {eventSummaryLink.text}
                </KitButton>
                <KitButton
                  size='sm'
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    width: '220px',
                  }}
                  disabled={eventSummary.num_observations === 0}
                  href={`${sessionListLink.href}?id=${eventSummary.id}`}
                >
                  <VisibilityIcon />
                  {sessionListLink.text}
                </KitButton>
              </Box>
            </Box>
          </Box>
        </KitCardBody>
      </KitCard>

      <AddEventDialog
        user={user}
        open={showEditEvent}
        event={eventSummary}
        onClose={(save, event) => {
          setShowEditEvent(false);
          if (save && onUpdated) {
            onUpdated(event);
          }
        }}
      />
    </Box>
  );
};

EventListCard.propTypes = {
  eventSummary: PropTypes.object,
  allowEdit: PropTypes.bool,
  user: PropTypes.object,
  onUpdated: PropTypes.func,
};

export default EventListCard;
