// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// local imports
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { HeartIcon } from 'components/common/subcomponents/SvgIcons';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles, PromptContainer, PromptRadio } from '../StyledComponents';

const PraiseEdit = ({ onComplete, onDirty }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { updateTimelineObservation, removeTimelineObservation, editTimelineItem, setEditTimelineItem } = useObservationStore();

  const [praisePrompt, setPraisePrompt] = useState(''); // ['gp-a', 'gp-b', 'sp-a', 'sp-b', 'ep-a', 'ep-b']
  const [sPrompt, setSPrompt] = useState(''); // s'
  const [slPrompt, setSLPrompt] = useState(''); // sl'
  const [moPrompt, setMoPrompt] = useState(''); // mo'
  const [note, setNote] = useState('');
  const [hasPraisePrompt, setHasPraisePrompt] = useState(false);
  const [hasMoPrompt, setHasMoPrompt] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (editTimelineItem?.type === 'Praise') {
      const tags = editTimelineItem.tags;
      if (tags.includes('GP-A')) {
        setPraisePrompt('gp-a');
        setHasPraisePrompt(true);
      } else if (tags.includes('GP-B')) {
        setPraisePrompt('gp-b');
        setHasPraisePrompt(true);
      } else if (tags.includes('SP-A')) {
        setPraisePrompt('sp-a');
        setHasPraisePrompt(true);
      } else if (tags.includes('SP-B')) {
        setPraisePrompt('sp-b');
        setHasPraisePrompt(true);
      } else if (tags.includes('EP-A')) {
        setPraisePrompt('ep-a');
        setHasPraisePrompt(true);
      } else if (tags.includes('EP-B')) {
        setPraisePrompt('ep-b');
        setHasPraisePrompt(true);
      }
      if (tags.includes('S')) {
        setSPrompt('praise-s');
      }
      if (tags.includes('SL')) {
        setSLPrompt('praise-sl');
      }
      if (tags.includes('MO')) {
        setMoPrompt('praise-mo');
        setHasMoPrompt(true);
      }
      setNote(editTimelineItem.note);
    } else {
      resetState();
    }
  }, [editTimelineItem]);

  const resetState = () => {
    setPraisePrompt('');
    setHasPraisePrompt(false);
    setSPrompt('');
    setSLPrompt('');
    setMoPrompt('');
    setHasMoPrompt(false);
    setNote('');
    setConfirmDeleteOpen(false);
  };

  const radioOnChange = (e) => {
    onDirty();
    if (editTimelineItem?.type !== 'Praise') {
      setEditTimelineItem({
        timestamp: editTimelineItem.timestamp,
        type: 'Praise',
        tags: [],
        note: note,
      });
    }
    const { name, value, checked } = e.target;
    if (name === 'praise-1') {
      setPraisePrompt(value);
      setHasPraisePrompt(true);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'praise-s-1') {
      setSPrompt(checked && sPrompt === 'praise-s' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'praise-sl-1') {
      setSLPrompt(checked && slPrompt === 'praise-sl' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'praise-mo') {
      setPraisePrompt('');
      setHasPraisePrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt(checked && moPrompt === 'praise-mo' ? '' : value);
      setHasMoPrompt(true);
    }
  };

  const addToTimeline = () => {
    const timelineObservation = {
      timestamp: editTimelineItem.timestamp,
      type: 'Praise',
      tags: [],
      note: note,
    };

    if (praisePrompt?.length > 0) {
      setHasPraisePrompt(true);
      timelineObservation.tags.push(praisePrompt.toUpperCase());
    }
    if (sPrompt?.length > 0) {
      timelineObservation.tags.push('S');
    }
    if (slPrompt?.length > 0) {
      timelineObservation.tags.push('SL');
    }
    if (moPrompt?.length > 0) {
      setHasMoPrompt(true);
      timelineObservation.tags.push('MO');
    }
    if (hasPraisePrompt || hasMoPrompt) {
      updateTimelineObservation(timelineObservation);
      setPraisePrompt('');
      setHasPraisePrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
      onComplete();
    }
  };

  const deleteTimelineObservation = () => {
    removeTimelineObservation(editTimelineItem);
    resetState();
    onComplete();
  };

  return (
    <PromptContainer promptType='Praise'>
      <div className='prompt-title-box'>
        <HeartIcon fillColor={theme.palette.obsPraise.dark} />
        <Typography sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '18.86px', color: '#000', marginLeft: '1rem' }}>Praise</Typography>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', flexWrap: 'nowrap', width: '85%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '.02rem' }}>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='gp-a-edit' name='praise-1' value='gp-a' checked={praisePrompt === 'gp-a'} onClick={radioOnChange} />
            <label htmlFor='gp-a-edit'>GP - A</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='gp-b-edit' name='praise-1' value='gp-b' checked={praisePrompt === 'gp-b'} onClick={radioOnChange} />
            <label htmlFor='gp-b-edit'>GP - B</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '.02rem' }}>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='sp-a-edit' name='praise-1' value='sp-a' checked={praisePrompt === 'sp-a'} onClick={radioOnChange} />
            <label htmlFor='sp-a-edit'>SP - A</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='sp-b-edit' name='praise-1' value='sp-b' checked={praisePrompt === 'sp-b'} onClick={radioOnChange} />
            <label htmlFor='sp-b-edit'>SP - B</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '.02rem' }}>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='ep-a-edit' name='praise-1' value='ep-a' checked={praisePrompt === 'ep-a'} onClick={radioOnChange} />
            <label htmlFor='ep-a-edit'>EP - A</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='ep-b-edit' name='praise-1' value='ep-b' checked={praisePrompt === 'ep-b'} onClick={radioOnChange} />
            <label htmlFor='ep-b-edit'>EP - B</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '1.5rem', width: '85%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.obsPraise.xLight,
            borderRadius: '12px',
            padding: '12px',
            width: '66.67%',
          }}
        >
          <PromptRadio promptType='Praise' round={true}>
            <input type='radio' id='praise-s-edit' name='praise-s-1' value='praise-s' checked={sPrompt === 'praise-s'} onClick={radioOnChange} />
            <label htmlFor='praise-s-edit'>S</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' round={true}>
            <input type='radio' id='praise-sl-edit' name='praise-sl-1' value='praise-sl' checked={slPrompt === 'praise-sl'} onClick={radioOnChange} />
            <label htmlFor='praise-sl-edit'>SL</label>
          </PromptRadio>
        </Box>
        <Box sx={{ padding: '12px' }}>
          <PromptRadio promptType='Praise' round={true}>
            <input type='radio' id='praise-mo-edit' name='praise-mo' value='praise-mo' checked={moPrompt === 'praise-mo'} onClick={radioOnChange} />
            <label htmlFor='praise-mo-edit'>MO</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
        <FormControl fullWidth size='small'>
          <TextField
            placeholder='Type note'
            multiline={true}
            rows={2}
            InputLabelProps={{ shrink: true }}
            sx={{ ...styles.select }}
            InputProps={{ sx: { ...styles.select } }}
            inputProps={{ maxLength: 200 }}
            label=''
            id='praise-note-edit'
            size='small'
            value={note}
            onChange={(e) => {
              onDirty();
              setNote(e.target.value);
            }}
          />
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <KitButton
            onClick={addToTimeline}
            size='sm'
            style={{
              backgroundColor: theme.palette.gold.main,
              marginTop: '1rem',
            }}
            disabled={!hasPraisePrompt && !hasMoPrompt}
          >
            <span>
              <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
            </span>
          </KitButton>
          <KitButton
            onClick={() => setConfirmDeleteOpen(true)}
            size='sm'
            style={{
              backgroundColor: theme.palette['obsOff Task'].main,
              marginTop: '1rem',
            }}
            disabled={editTimelineItem?.type === 'Praise' ? false : true}
          >
            <span>
              <DeleteForeverIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />
              Delete
            </span>
          </KitButton>
        </Box>
      </Box>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        confirmMessage='Are you sure you want to delete this Praise?'
        handleClose={() => setConfirmDeleteOpen(false)}
        handleYes={deleteTimelineObservation}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </PromptContainer>
  );
};

export default PraiseEdit;
