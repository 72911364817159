import React from 'react';
import Draggable from 'react-draggable';
import { Paper } from '@mui/material';

const DraggablePaperComponent = (props) => (
  <Draggable handle={props.handle} cancel={'[class*="MuiDialogContent-root"], .need-interaction'}>
    <Paper {...props} />
  </Draggable>
);

export default DraggablePaperComponent;
