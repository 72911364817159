import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';

const HeroBackgroundAsImage = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();

  const router = useRouter();
  const theme = useTheme();

  const Container = styled.div`
    ${fields.hideComponent === 'session'
      ? session
        ? 'display: none;'
        : 'display: flex;'
      : fields.hideComponent === 'nosession'
      ? !session
        ? 'display: none;'
        : 'display: flex;'
      : 'display: flex;'}
    position: relative;
    flex-direction: row;
    background-repeat: no-repeat;
    @media screen and (min-width: 992px) {
      justify-content: flex-start;
      align-items: center;
      background-size: 100%;
      background-position: right 15% bottom 25%;
      padding: 100px 250px;
      height: 431px;
    }
    @media screen and (max-width: 991px) {
      justify-content: space-between;
      align-items: flex-end;
      background-size: 210%;
      background-position: right 35% bottom 35%;
      ${fields.title && fields.title.length > 60 ? 'padding: 100px 40px 50px 40px;' : 'padding: 100px 40px;'}
      height: 330px;
    }
    @media screen and (max-width: 400px) {
      justify-content: space-between;
      align-items: flex-end;
      background-size: 280%;
      background-position: right 25% bottom 45%;
      ${fields.title && fields.title.length > 60 ? 'padding: 100px 40px 50px 40px;' : 'padding: 100px 40px;'}
      height: 300px;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    minheight: inherit;
    padding-right: 10px;
    @media screen and (min-width: 900px) {
      ${fields.title && fields.title.length > 60 ? 'width: 730px;' : 'width: 582px;'}
      height: 280px;
    }
    @media screen and (max-width: 899px) {
      ${fields.title && fields.title.length > 60 ? 'width: 95%;' : 'width: 80%;'}
    }
  `;

  const HeroTitle = styled.div`
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0 4px 4px);
    @media screen and (min-width: 900px) {
      font-size: 3.125rem;
      line-height: 3.375rem;
    }
    @media screen and (max-width: 899px) {
      font-size: 2.55rem;
      line-height: 2.725rem;
    }
  `;

  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${fields.backgroundImage.url})`,
        }}
      >
        <TextContainer>
          <HeroTitle>{fields.title}</HeroTitle>
        </TextContainer>
      </Container>
    </React.Fragment>
  );
};

export default HeroBackgroundAsImage;

const HeroSeparator = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 200,
  fontSize: '1.75rem',
  lineHeight: '1.85rem',
  margin: '0 30px',
}));
