import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import EmailDomainQRCode from './EmailDomainQRCode';

const EmailDomainCard = (props) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <KitCard style={{ width: '80%', color: `${theme.palette.primary.main}` }}>
        <KitCardBody>
          <Typography variant='h6' sx={{ textAlign: 'center' }}>
            {props.emailDomain.district_name}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <Typography variant='subtitle2' sx={{ textAlign: 'center' }} component='span'>
              {props.emailDomain.email_domain}
            </Typography>
            <FormControlLabel control={<Checkbox checked={props.emailDomain.is_active} disabled />} label='Active' />
          </Box>
          <EmailDomainQRCode emailDomain={props.emailDomain} />
          <KitButton style={{ float: 'right' }} color='info' round size='sm' onClick={() => props.editFunc()} title='Edit Domain'>
            <EditIcon /> Edit
          </KitButton>
        </KitCardBody>
      </KitCard>
    </Box>
  );
};

EmailDomainCard.propTypes = {
  emailDomain: PropTypes.object.isRequired,
  editFunc: PropTypes.func.isRequired,
};

export default EmailDomainCard;
