import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { KitButton } from '@boystownorg/bi-cms-component-lib';

const YesNoDialog = (props) => {
  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      fullWidth={true}
      open={props.open}
      sx={{
        '& .MuiDialog-paper ': {
          overflow: 'visible',
        },
      }}
    >
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <KitButton onClick={() => props.noClose()} round color='info'>
          {props.noText}
        </KitButton>
        <KitButton onClick={() => props.yesClose()} round color='success'>
          {props.yesText}
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

YesNoDialog.defaultProps = {
  yesText: 'Yes',
  noText: 'No',
};
YesNoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  yesText: PropTypes.string,
  noText: PropTypes.string,
  yesClose: PropTypes.func,
  noClose: PropTypes.func,
};
export default YesNoDialog;
