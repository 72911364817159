export const canAccessObservations = (session, status): boolean => {
  if (status === 'loading') {
    // don't stop while loading
    return true;
  }
  // not logged in, no access
  if (!session?.user) {
    return false;
  }
  if (!session.user?.observation_permission || session.user?.observation_permission === 'None') {
    return false;
  }
  return true;
};

export const observationRedirectMessage = (session, status): string => {
  if (!session?.user) {
    return 'Please log in to access observations.';
  }
  if (!canAccessObservations(session, status)) {
    return 'You do not have permission to access observations.';
  }
  return 'You do not have access to this page.';
};

export const observationRedirectPage = (session, status): string => {
  if (!session?.user) {
    let redirect = 'observation/landing';
    if (typeof window !== 'undefined') {
      redirect = window.location.pathname;
    }

    return `/signin?redirect=${redirect}`;
  } else if (!canAccessObservations(session, status)) {
    return '/';
  }
  return '/';
};
