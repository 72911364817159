import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { hasModuleAccess } from '../../services/subscriptionUtils';

const TrainingBenefitsAndFeatures = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();

  const router = useRouter();

  const [showPage, setShowPage] = useState(true);

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  // If session, check if has module details access.
  useEffect(() => {
    if (session) {
      if (hasModuleAccess(session, fields.parentPage)) {
        setShowPage(false);
      } else {
        setShowPage(true);
      }
    }
  }, [session, fields]);

  const Container = styled.div`
    position: relative;
    height: 540px;
    width: 100%;
    background-color: #003a5d;
    bottom: 0;
    color: #fff;
    margin-top: 20px;
    background-size: cover;
    background-position: center;
    display: block;
    @media only screen and (min-width: 993px) {
      margin-top: inherit;
    }
    @media only screen and (max-width: 992px) {
    }
  `;

  const ImageContainer = styled.div`
    @media only screen and (min-width: 768px) {
    }
    @media only screen and (min-width: 993px) {
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  `;

  const FrontImage = styled.img`
    position: absolute;
    right: 50%;
    bottom: 0;
    width: auto;
    z-index: 10;
    @media only screen and (min-width: 993px) {
      height: 600px;
    }
    @media only screen and (max-width: 992px) {
      height: 490px;
    }
  `;

  const BenefitParent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @media only screen and (min-width: 993px) {
      width: 475px;
      height: 90%;
      position: absolute;
      left: 52%;
      top: 25px;
    }
    @media only screen and (max-width: 992px) {
      width: 400px;
      transform: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px auto 10px auto;
    }
  `;

  const BenefitTitle = styled.div`
    color: #fff;
    text-transform: uppercase;
    width: 400px;
    text-align: center;
    @media screen and (min-width: 993px) {
      font-size: 2.4rem;
      font-weight: bold;
    }
    @media screen and (max-width: 992px) {
      font-size: 2rem;
      font-weight: bold;
      padding: 30px 0 0 0;
    }
  `;

  const BenefitList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0;
    width: 400px;
    @media screen and (min-width: 993px) {
    }
    @media screen and (max-width: 992px) {
      margin: 20px auto 5px auto;
    }
  `;

  const BenefitItem = styled.div`
    padding: 10px 5px;
    max-width: 80%;
    color: #fff;
    text-align: center;
  `;

  const ListSeparator = styled.hr`
    margin: 1% 0;
    width: 85%;
    border: 1px dashed #fff;
    &:last-child {
      border: 0px;
    }
  `;

  return (
    <React.Fragment>
      {showPage && (
        <Container
          style={{
            backgroundImage: `linear-gradient(rgba(0, 59, 92, 0.25),rgba(0, 59, 92, 0.25)), url(${fields.backgroundImage.url})`,
          }}
        >
          <ImageContainer>
            <FrontImage src={fields.featuredImage?.url} alt={fields.featuredImage?.text}></FrontImage>
          </ImageContainer>
          <BenefitParent>
            <BenefitTitle>{fields.title}</BenefitTitle>
            <BenefitList>
              {fields.benefitList &&
                fields.benefitList.length > 0 &&
                fields.benefitList.map((benefit, index) => {
                  return (
                    <React.Fragment key={index}>
                      <BenefitItem>{benefit.fields.textValue}</BenefitItem>
                      <ListSeparator />
                    </React.Fragment>
                  );
                })}
            </BenefitList>
          </BenefitParent>
        </Container>
      )}
    </React.Fragment>
  );
};

export default TrainingBenefitsAndFeatures;
