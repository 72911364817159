import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';
import { getColorByAgilityName } from '../../services/agilityTranslations';

const ActionButton = ({ module }) => {
  const { fields } = module;

  return (
    <React.Fragment>
      <ContentContainer style={{ backgroundColor: getColorByAgilityName(fields.backgroundColor) }}>
        <ActionArea>
          <KitGridContainer direction='row' style={{ width: '100%' }}>
            {fields.actionUrl2 && fields.actionUrl2.href && fields.actionUrl2.href.length > 0 ? (
              <>
                <KitGridItem xs={false} sm={1} align='center' />
                <KitGridItem xs={12} sm={4} align='center'>
                  <Link href={fields.actionUrl.href} rel='noopener noreferrer' passHref target={fields.actionUrl.target}>
                    <KitButton
                      color={fields.buttonColor}
                      round
                      style={{
                        color: '#fff',
                      }}
                    >
                      {fields.buttonText}
                    </KitButton>
                  </Link>
                </KitGridItem>
                <KitGridItem xs={false} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <ButtonDivider style={{ color: fields.dividerTextColor ? getColorByAgilityName(fields.dividerTextColor) : '#fff' }}>
                    {fields.dividerText ?? 'OR'}
                  </ButtonDivider>
                </KitGridItem>
                <KitGridItem xs={12} sm={4} align='center'>
                  <Link href={fields.actionUrl2.href} rel='noopener noreferrer' passHref target={fields.actionUrl2.target}>
                    <KitButton
                      color={fields.buttonColor}
                      round
                      style={{
                        color: '#fff',
                      }}
                    >
                      {fields.buttonText2}
                    </KitButton>
                  </Link>
                </KitGridItem>
                <KitGridItem xs={false} sm={1} align='center' />
              </>
            ) : (
              <KitGridItem xs={12} align='center'>
                <Link href={fields.actionUrl.href} rel='noopener noreferrer' passHref target={fields.actionUrl.target}>
                  <KitButton
                    color={fields.buttonColor}
                    round
                    style={{
                      color: '#fff',
                    }}
                  >
                    {fields.buttonText}
                  </KitButton>
                </Link>
              </KitGridItem>
            )}
          </KitGridContainer>
        </ActionArea>
      </ContentContainer>
    </React.Fragment>
  );
};

export default ActionButton;

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    minWidth: '100%',
    maxHeight: '80px',
  },
  // [theme.breakpoints.down('md')]: {
  //   display: 'none',
  // },
}));

const ActionArea = styled('div')(({ theme }) => ({
  flexGrow: 0,
  padding: '10px 8% 10px 8%',
}));

const ButtonDivider = styled('div')(({ theme }) => ({
  fontSize: '1.20rem',
  marginTop: '15px',
  marginLeft: '6px',
  textAlign: 'center',
  width: '100%',
}));
