import React from 'react';
import AlertTitle from '@mui/material/AlertTitle';
import MuiAlert from '@mui/material/Alert';
import Link from 'next/link';

const NotAuthorized = () => {
  return (
    <MuiAlert
      elevation={6}
      severity='warning'
      variant='outlined'
      sx={{ marginBottom: '70px', width: '90%', marginLeft: 'auto', marginRight: 'auto', boxShadow: 'none' }}
    >
      <AlertTitle>Not Authorized.</AlertTitle>
      You are not authorized to view this page. Return to the{' '}
      <Link href='/' legacyBehavior>
        <a style={{ textDecoration: 'underline' }}>home</a>
      </Link>{' '}
      page.
    </MuiAlert>
  );
};

export default NotAuthorized;
