import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, FormControl, IconButton, TextField } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles } from '../StyledComponents';

const ObservationSessionNote = () => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState(false);
  const { addTimelineObservation } = useObservationStore();

  const addToTimeline = () => {
    const timelineNote = {
      timestamp: new Date().getTime(),
      type: 'Session Note',
      tags: [],
      note: note,
    };
    if (note?.length > 0) {
      timelineNote.tags.push(note.toUpperCase());
    }
    if (timelineNote.tags.length > 0) {
      addTimelineObservation(timelineNote);
      setNote('');
    }
  };

  return (
    <KitCard style={{ width: '95%', marginTop: '.5rem', marginBottom: 0 }}>
      <KitCardBody>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl fullWidth size='small'>
            <TextField
              placeholder='Type note'
              multiline={true}
              rows={2}
              InputLabelProps={{ shrink: true }}
              sx={{ ...styles.select, marginTop: '.75rem' }}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ maxLength: 500 }}
              label='New Session Note'
              id='session-note'
              error={noteError}
              size='small'
              value={note}
              onChange={(e) => {
                setNoteError(false);
                setNote(e.target.value);
              }}
            />
          </FormControl>

          <IconButton onClick={addToTimeline} style={{ marginTop: '.5rem' }}>
            <ArrowCircleRightIcon sx={{ color: theme.palette.gold.main }} fontSize='large' />
          </IconButton>
        </Box>
      </KitCardBody>
    </KitCard>
  );
};

export default ObservationSessionNote;
