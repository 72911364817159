import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import Image from 'next/image';

const ObservationCTACard = ({ title, text, ctaLabel, ctaLink, ctaColor, iconUrl }) => {
  const theme = useTheme();
  return (
    <KitCard
      style={{
        width: '80%',
        maxWidth: '380px',
      }}
    >
      <KitCardBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Image src={iconUrl} width={44} height={44} alt={title} style={{ marginRight: '1rem' }} />
            <Typography
              component='div'
              sx={{
                fontSize: { xs: '18px', sm: '24px' },
                fontWeight: 600,
                lineHeight: { xs: '20px', sm: '30px' },
                color: theme.palette.obsBlue.main,
              }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            component='div'
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '20px',
              color: theme.palette.text.medium,
              marginBottom: '20px',
              flexGrow: 1, // make this item take up the remaining space
            }}
          >
            {text}
          </Typography>
          <KitButton
            href={ctaLink}
            size='sm'
            style={{
              backgroundColor: buttonColor(theme, ctaColor),
              width: '100%',
            }}
          >
            {ctaLabel}
          </KitButton>
        </Box>
      </KitCardBody>
    </KitCard>
  );
};

export default ObservationCTACard;
