import { PropsWithChildren, ReactElement, ReactNode, createElement } from 'react';
import * as prod from 'react/jsx-runtime';
import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact, { Options } from 'rehype-react';
import { rehypeFilter, RehypeFilterOptions } from './rehypeFilter';

type ParseHtmlOptions = Pick<Options, 'components'> & RehypeFilterOptions;

// @ts-expect-error: the react types are missing.
const production = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };

export function parseHtml(content: string, options?: ParseHtmlOptions): ReactNode {
  const { result } = unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(rehypeReact, production)
    .use(rehypeFilter, {
      allowElement: options?.allowElement,
      allowedElements: options?.allowedElements,
      disallowedElements: options?.disallowedElements,
      unwrapDisallowed: options?.unwrapDisallowed,
    })
    .processSync(content);

  // unwraps the outer <div> element
  return (result as ReactElement<PropsWithChildren<{}>>).props.children;
}
