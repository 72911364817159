import React from 'react';
import styled from '@emotion/styled';
import Carousel from 'react-material-ui-carousel';

const HomepageCarousel = ({ module }) => {
  const { fields } = module;

  if (fields.carouselImages.media.length <= 0) {
    return <div></div>;
  }

  return (
    <Carousel>
      {fields.carouselImages.media.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

function Item(props) {
  return (
    <CarouselFrame>
      <img src={props.item.url} width='100%' />
    </CarouselFrame>
  );
}

export default HomepageCarousel;

const CarouselFrame = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minHeight: '70vh',
    maxHeight: '60vh',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '30vh',
    maxHeight: '40vh',
  },
}));
