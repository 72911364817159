import { useTheme } from '@emotion/react';
import { Box, IconButton, Typography } from '@mui/material';
import { CheckmarkIcon, EditCircleIcon, ExclamationIconCircle, HandIcon, HeartIcon, NoteIcon } from 'components/common/subcomponents/SvgIcons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateTime } from 'luxon';
import { useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';

// local imports
import hex2rgba from 'services/colorUtils/hex2rgba';
import { useObservationStore } from '../../stores/observationStore';
import { TimelineBadge } from '../StyledComponents';
import ObservationTimelineEditDialog from './ObservationTimelineEditDialog';
import LongNoteDialog from '../LongNoteDialog';
import OffTaskEditDialog from './OffTaskEditDialog';
import SessionNoteEditDialog from './SessionNoteEditDialog';

const ObservationTimeline = () => {
  const theme = useTheme();
  const [editTimelineDialogOpen, setEditTimelineDialogOpen] = useState(false);
  const [editOffTaskDialogOpen, setEditOffTaskDialogOpen] = useState(false);
  const [editSessionNoteDialogOpen, setEditSessionNoteDialogOpen] = useState(false);
  const { observation, setEditTimelineItem, setEditSessionNote, setEditOffTaskItem } = useObservationStore();
  const [longNote, setLongNote] = useState('');
  const [longNoteDialogOpen, setLongNoteDialogOpen] = useState(false);

  const getFormattedTime = (timestamp) => {
    try {
      return DateTime.fromMillis(timestamp).toFormat('h:mm a');
    } catch (error) {
      console.log(error);
    }
    return 'N/A';
  };

  const truncateLongText = (text) => {
    if (text.length > 15) {
      return text.substr(0, 12) + '...';
    }
    return text;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginLeft: '2rem', marginRight: '2rem' }}>
      {observation.observations?.slice(-6)?.map((timelineItem, index) => {
        return (
          <Box key={timelineItem.timestamp} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxHeight: '45px' }}>
            {timelineItem.type === 'Praise' ||
            timelineItem.type === 'Correction' ||
            timelineItem.type === 'Prevention' ||
            timelineItem.type === 'Off Task' ||
            timelineItem.type === 'Session Note' ? (
              <IconButton
                sx={{ marginRight: '1rem' }}
                onClick={() => {
                  if (['Praise', 'Correction', 'Prevention'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditTimelineDialogOpen(true);
                  } else if (['Off Task'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditOffTaskDialogOpen(true);
                  } else if (['Session Note'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditSessionNoteDialogOpen(true);
                  }
                }}
              >
                <EditCircleIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ marginRight: '1rem' }}
                onClick={() => {
                  if (['Praise', 'Correction', 'Prevention'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditTimelineDialogOpen(true);
                  } else if (['Off Task'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditOffTaskDialogOpen(true);
                  } else if (['Session Note'].includes(timelineItem.type)) {
                    setEditTimelineItem(timelineItem);
                    setEditSessionNoteDialogOpen(true);
                  }
                }}
              >
                <CircleIcon style={{ color: 'transparent' }} />
              </IconButton>
            )}
            <Box
              sx={{
                backgroundColor: hex2rgba(theme.palette[`obs${timelineItem.type}`].dark, 0.3),
                height: '56px',
                width: '56px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '1rem',
              }}
            >
              {timelineItem.type === 'Prevention' && <HandIcon fillColor={theme.palette.obsPrevention.dark} />}
              {timelineItem.type === 'Praise' && <HeartIcon fillColor={theme.palette.obsPraise.dark} />}
              {timelineItem.type === 'Correction' && <CheckmarkIcon fillColor={theme.palette.obsCorrection.dark} />}
              {timelineItem.type === 'Session Note' && <NoteIcon fillColor={theme.palette['obsSession Note'].main} />}
              {timelineItem.type === 'Timer' && <AccessTimeIcon fillColor={theme.palette.obsTimer.dark} />}
              {timelineItem.type === 'Off Task' && (
                <ExclamationIconCircle style={{ height: '27px', width: '27px' }} fillColor={theme.palette['obsOff Task'].main} />
              )}
            </Box>
            <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '19px', color: theme.palette.text.mediumDark, marginRight: '.75rem' }}>
              {timelineItem.type}
            </Typography>
            <Box>
              {timelineItem.tags.map((tag) => {
                return timelineItem.type === 'Session Note' ? (
                  <TimelineBadge
                    key={tag}
                    onClick={(e) => {
                      setLongNote(tag);
                      setLongNoteDialogOpen(true);
                    }}
                  >
                    {truncateLongText(tag)}
                  </TimelineBadge>
                ) : (
                  <TimelineBadge key={tag}>{tag}</TimelineBadge>
                );
              })}
            </Box>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '18px', color: theme.palette.text.light, marginLeft: 'auto' }}>
              {getFormattedTime(timelineItem.timestamp)}
            </Typography>
          </Box>
        );
      })}
      <ObservationTimelineEditDialog
        open={editTimelineDialogOpen}
        onClose={() => {
          setEditTimelineItem(null);
          setEditTimelineDialogOpen(false);
        }}
      />
      <OffTaskEditDialog
        open={editOffTaskDialogOpen}
        onClose={() => {
          setEditTimelineItem(null);
          setEditOffTaskDialogOpen(false);
        }}
      />
      <SessionNoteEditDialog
        open={editSessionNoteDialogOpen}
        onClose={() => {
          setEditTimelineItem(null);
          setEditSessionNoteDialogOpen(false);
        }}
      />
      <LongNoteDialog
        open={longNoteDialogOpen}
        onClose={() => {
          setLongNoteDialogOpen(false);
        }}
        note={longNote}
      />
    </Box>
  );
};

export default ObservationTimeline;
