/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
'use client';

import { PropsWithChildren, RefObject, createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';

type AccordionStoreState = {
  activeIndexes: Set<number>;
  refs: Map<number, RefObject<Element>>;
  toggle(index: number): void;
  setRef(index: number, ref: RefObject<Element> | undefined): void;
};

type AccordionOptions = {
  initialActiveIndexes?: number[];
  atLeastOne?: boolean;
  multiple?: boolean;
};

function createAccordionStore({ initialActiveIndexes, atLeastOne = false, multiple = false }: AccordionOptions = {}) {
  return createStore<AccordionStoreState>((set, get) => {
    let activeIndexes = initialActiveIndexes ?? [];

    if (!multiple && activeIndexes.length > 1) {
      activeIndexes = [activeIndexes[0]];
    } else if (atLeastOne && activeIndexes.length === 0) {
      activeIndexes = [0];
    }

    return {
      activeIndexes: new Set(activeIndexes),
      refs: new Map(),
      toggle(index) {
        let activeIndexes = get().activeIndexes;

        if (activeIndexes.has(index)) {
          if (!atLeastOne || activeIndexes.size > 1) {
            activeIndexes.delete(index);
            activeIndexes = new Set(activeIndexes);
          }
        } else {
          activeIndexes = !multiple ? new Set([index]) : new Set(activeIndexes.add(index));
        }

        set({
          activeIndexes,
        });
      },
      setRef(index, ref) {
        set((state) => {
          const refs = new Map(state.refs);

          if (ref) {
            refs.set(index, ref);
          } else {
            refs.delete(index);
          }

          return {
            refs,
          };
        });
      },
    };
  });
}

const AccordionContext = createContext<StoreApi<AccordionStoreState>>({} as StoreApi<AccordionStoreState>);

export function useAccordion<T>(selector?: (state: AccordionStoreState) => T, equals?: (a: T, b: T) => boolean) {
  const context = useContext(AccordionContext);
  if (!selector) {
    throw new Error('useAccordion: selector is required');
  }
  return useStore(context, selector, equals);
}

export function useAccordionItem(index: number) {
  if (typeof index === 'undefined') {
    throw new Error('useAccordionItem: no index provided');
  }

  const ref = useRef<Element>(null);
  const activeIndexes = useAccordion((s) => s.activeIndexes);
  const toggle = useAccordion((state) => state.toggle);
  const setRef = useAccordion((state) => state.setRef);

  useEffect(() => {
    setRef(index, ref);

    return () => {
      setRef(index, undefined);
    };
  }, [index, ref]);

  return useMemo(
    () => ({
      isActive: activeIndexes.has(index),
      ref,
      toggle: () => toggle(index),
    }),
    [activeIndexes, index]
  );
}

export function AccordionProvider({ children, ...options }: PropsWithChildren<AccordionOptions>) {
  const storeRef = useRef<StoreApi<AccordionStoreState> | null>(null);

  if (!storeRef.current) {
    storeRef.current = createAccordionStore(options);
  }

  return <AccordionContext.Provider value={storeRef.current}>{children}</AccordionContext.Provider>;
}
