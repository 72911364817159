import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, TextField, Fade, Skeleton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import {
  LIFT_CATEGORY,
  PER_PAGE,
  SEARCH_CATEGORIES,
  useAddSearch,
  useAddSearchClickTracker,
  useAddSearchQuery,
} from 'components/common/SearchComponents/useAddSearch';
import { NoResults, SearchGridWrapper, SearchPagination } from 'components/common/SearchComponents/SearchStyledComponents';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import SearchTerms from 'components/common/SearchComponents/SearchTerms';
import SearchResultItem from 'components/common/SearchComponents/SearchResultItem';
import SearchCategory from 'components/common/SearchComponents/SearchCategory';

const SearchResults = ({ globalData }) => {
  const searchTerms = globalData?.header?.searchTerms;
  const theme = useTheme();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : new URLSearchParams();
  const query = searchParams.get('q') || undefined;
  const category = searchParams.get('c') || '';
  const page = Number(searchParams.get('p')) || 1;
  const [searchVal, setSearchVal] = useState(query);
  const [searchCategory, setSearchCategory] = useState(category);

  useEffect(() => {
    setSearchVal(query);
  }, [query]);

  const update = (val, cat) => {
    const nextSearchParams = new URLSearchParams(searchParams);

    nextSearchParams.set('q', val);
    if (cat && cat.length > 0) {
      nextSearchParams.set('c', cat);
    } else {
      nextSearchParams.delete('c');
    }
    nextSearchParams.delete('p');

    router.push(`${pathname}?${nextSearchParams}`);
  };

  const handlePageChange = (page) => {
    const nextSearchParams = new URLSearchParams(searchParams);

    nextSearchParams.set('p', page.toString());

    router.push(`${pathname}?${nextSearchParams}`);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    update(searchVal, searchCategory);
  };

  const newSearch = (val) => {
    update(val, searchCategory);
  };

  const client = useAddSearch((client) => {
    client.setPaging(page, PER_PAGE, 'relevance', 'desc');
    client.setSearchOperator('and');
    client.setCollectAnalytics(false);
  });

  const { data, isLoading, isFetching } = useAddSearchQuery(
    client,
    {
      query: query,
      category: searchCategory !== '' ? `${LIFT_CATEGORY}/${searchCategory}` : LIFT_CATEGORY,
      page: page,
    },
    {
      //enabled: query.length > 2,
    }
  );

  const click = useAddSearchClickTracker(client, data);

  const totalCount = data?.results.total_hits ?? 0;
  const results = data?.results?.hits;
  const hasResults = !!results?.length;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '2rem',
        marginBottom: '2rem',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <div
            style={{
              color: '#fff',
              display: 'inline-flex',
              justifyContent: 'flex-start',
              width: '80%',
              paddingLeft: '20px',
              paddingTop: '1rem',
            }}
          >
            {SEARCH_CATEGORIES.map((category) => {
              return (
                <SearchCategory
                  key={category.text}
                  text={category.text}
                  isActive={searchCategory === category.value}
                  click={() => {
                    setSearchCategory(category.value);
                    update(searchVal, category.value);
                  }}
                  color={theme.palette.primary.main}
                />
              );
            })}
          </div>

          <form onSubmit={handleSubmit} style={{ width: '80%', paddingTop: '1rem', paddingBottom: '2rem' }}>
            <TextField
              placeholder='Search'
              color='primary'
              sx={{ width: '90%' }}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              InputProps={{
                sx: { borderRadius: '999px', mt: '1rem' },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon color='primary' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: searchVal?.length > 0 ? 'flex' : 'none' }}>
                    <IconButton aria-label='clear search' onClick={() => setSearchVal('')}>
                      <CloseIcon color='primary' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant='outlined'
            />
          </form>
        </div>
      </div>

      <SearchGridWrapper id='searchGridWrapper'>
        <StyledSearchGrid id='searchGrid'>
          <Fade in={isLoading || isFetching || hasResults || !hasResults} timeout={500}>
            <div id='searchResults'>
              {isLoading && <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />}
              {isFetching && (
                <>
                  <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
                  <Skeleton variant='text' sx={{ width: '100%', fontSize: '18px' }} />
                  <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
                  <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
                  <Skeleton variant='text' sx={{ width: '100%', fontSize: '18px' }} />
                  <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
                </>
              )}

              {!isLoading && !hasResults && query?.length > 2 && <NoResults term={searchVal} />}

              {!isLoading && hasResults && query?.length > 2 && (
                <div>
                  <StyledResultHeading>
                    <div style={{ fontSize: '24px', lineHeight: '32px', fontWeight: 500 }}>
                      {`${totalCount} result${totalCount > 1 ? 's' : ''} for `}
                      <span style={{ color: theme.palette.primary.main }}>{query}</span>
                    </div>
                    <div>
                      <SearchPagination totalCount={totalCount} page={page} setPage={handlePageChange} perPage={PER_PAGE} />
                    </div>
                  </StyledResultHeading>
                  <div>
                    {results.map(({ id, ...item }, index) => (
                      <SearchResultItem id={id} item={item} key={id} click={click} />
                    ))}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <div>
                      <SearchPagination totalCount={totalCount} page={page} setPage={handlePageChange} perPage={PER_PAGE} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Fade>
          <SearchTerms searchTerms={searchTerms} newSearch={newSearch} />
        </StyledSearchGrid>
      </SearchGridWrapper>
    </div>
  );
};

export default SearchResults;

const StyledSearchGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '75% auto',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '100% auto',
  },
}));

const StyledResultHeading = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      marginBottom: '1rem',
    },
  },
}));
