import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const PageBannerWithTwoOptions = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const Container = styled.div`
    position: relative;
    width: 100%;
    background-color: ${fields.backgroundColor};
    height: 265px;
    margin: -25px 0 50px 0;
  `;

  const Title = styled.div`
    position: absolute;
    left: 20%;
    top: 10%;
    width: 260px;
    font-size: 2.625rem;
    line-height: 2.875rem;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
  `;

  const SubTitle = styled.div`
    position: absolute;
    left: 20%;
    top: 65%;
    width: 260px;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #000;
  `;

  const CenterArea = styled.div`
    position: absolute;
    left: 45%;
    top: 0%;
    background-color: ${theme.palette.primary.light};
    height: 281px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1080px) {
      width: 564px;
      &:after {
        border-bottom: solid 16px transparent;
        border-right: solid 16px transparent;
        border-left: solid 16px ${theme.palette.primary.main};
        transform: translateX(-50%);
        position: absolute;
        z-index: 10;
        content: '';
        top: 94.3%;
        left: 102.8%;
        height: 0;
        width: 0;
      }
    }
    @media screen and (max-width: 1079px) {
      display: none;
    }
  `;

  const Option = styled.div`
    text-align: left;
    max-width: 95%;
    padding: 0 50px;
    margin: 0;
  `;

  const OptionImg = styled.img`
    color: #fff;
  `;

  const OptionText = styled.div`
    color: #fff;
    font-size: 1.813rem;
    line-height: 2.278rem;
    font-weight: 600;
  `;

  const WhiteCircle = styled.div`
    background-color: #fff;
    border-radius: 50%;
    width: 53px;
    height: 53px;
    margin: 0 10px;
    padding: 11px;
    font-size: 1.5rem;
    line-height: 1.886rem;
    color: ${theme.palette.primary.light};
  `;

  return (
    <Container>
      <Title>{fields.title}</Title>
      <SubTitle>{fields.subTitle}</SubTitle>
      <CenterArea>
        <Option>
          {fields && fields.option1Icon && (
            <>
              {fields.option1Icon && <OptionImg src={fields.option1Icon.url} alt={fields.option1Icon.text} />}
              <OptionText>{fields.option1Text}</OptionText>
            </>
          )}
        </Option>
        <WhiteCircle>OR</WhiteCircle>
        <Option>
          {fields && fields.option2Icon && (
            <>
              {fields.option2Icon && <OptionImg src={fields.option2Icon.url} alt={fields.option2Icon.text} />}
              <OptionText>{fields.option2Text}</OptionText>
            </>
          )}
        </Option>
      </CenterArea>
    </Container>
  );
};

export default PageBannerWithTwoOptions;
