import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import Carousel from 'react-material-ui-carousel';
import { fetchProductRecordFromList } from 'services/productUtils';
import { useTheme } from '@mui/material/styles';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const ProfessionalLearningSeries = ({ module }) => {
  const { fields } = module;

  const theme = useTheme();

  const [seriesSelected, setSeriesSelected] = useState(null);

  // This useEffect will evaluate if a product page has recently been viewed, and, if so, use that to select the
  // product to highlight. If not, it will default to the first one (WMS).
  useEffect(() => {
    if (window.localStorage.getItem('product-name') && window.localStorage.getItem('product-name').length > 0) {
      const productFromList = fetchProductRecordFromList(fields.seriesList, window.localStorage.getItem('product-name'));
      setSeriesSelected(productFromList);
    } else {
      setSeriesSelected(fields.seriesList[0]);
      window.localStorage && window.localStorage.setItem('product', JSON.stringify(fields.seriesList[0]));
    }
  }, [fields]);

  const [textAlign, setTextAlign] = useState('right');

  const SelectSeries = (series) => {
    setSeriesSelected(series);
    window && window !== 'undefined' && window.localStorage && window.localStorage.setItem('product-name', series.fields.seriesName);
  };

  const MobileContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (min-width: 1001px) {
      display: none;
    }
    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  `;

  const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (min-width: 1001px) {
      width: 100%;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  `;

  const SeriesContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 20px 0;
    @media screen and (min-width: 1001px) {
      flex-flow: row;
      width: 90%;
    }
    @media screen and (max-width: 1000px) {
      flex-flow: column;
      width: 90%;
    }
  `;

  const SeriesRow = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: 20px 0;
  `;

  const SeriesBlock = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    cursor: pointer;
    &:active {
      background-color: rgba(63, 164, 240, 0.08);
    }
    &:hover {
      background-color: rgba(63, 164, 240, 0.08);
    }
    @media screen and (min-width: 1001px) {
      height: 160px;
    }
    @media screen and (min-width: 1200px) {
      height: 140px;
    }
    @media screen and (min-width: 1400px) {
      height: 120px;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  `;

  const MobileSeriesBlock = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (min-width: 1001px) {
      display: none;
    }
  `;

  const SeriesTitle = styled.div`
    position: absolute;
    top: 75px;
    color: #000;
    width: 90%;
    text-align: center;
    @media screen and (min-width: 1001px) {
      font-size: 0.9rem;
      line-height: 1.225rem;
      font-weight: 600;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  `;

  const SeriesImg = styled.img`
    position: absolute;
    top: 15px;
    // You cannot CSS style an SVG image you're loading using the <img> tag.
    // &:hover {
    //   line {
    //     stroke: #cf451f;
    //   }
    // }
  `;

  const MobileSeriesTitle = styled.div`
    color: #cf451f;
    width: 100%;
    margin-top: 15px;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    @media screen and (min-width: 1001px) {
      display: none;
    }
  `;

  const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (min-width: 1001px) {
      margin: 35px 0;
      width: 100%;
    }
    @media screen and (max-width: 1000px) {
      margin: 10px 0;
      width: 100%;
    }
  `;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
    }
    @media screen and (min-width: 1001px) {
      width: 100%;
      ${fields.textAlign === 'left' ? (seriesSelected ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
  `;

  const CoursesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (min-width: 1001px) {
      width: 100%;
      background-size: cover;
      background-position: center;
      height: 415px;
      margin: 25px 0 25px 0;
    }
    @media screen and (max-width: 1000px) {
      width: 130%;
      background-size: cover;
      background-position: right 35% bottom 75%;
      height: 415px;
      margin: 0 -25px 0 -35px;
    }
  `;

  const CourseList = styled.ul`
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    align-items: left;
    position: relative;
    @media screen and (min-width: 1001px) {
      list-style-type: disc;
      list-style-position: outside;
      margin-top: 16px;
      margin-left: 56%;
      width: 450px;
      height: 431px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &:after {
        border-bottom: solid 16px transparent;
        border-right: solid 16px transparent;
        border-left: solid 16px #58c1b3;
        transform: translateX(-50%);
        position: absolute;
        z-index: 10;
        content: '';
        top: 96.3%;
        left: 103.6%;
        height: 0;
        width: 0;
      }
    }
    @media screen and (max-width: 1000px) {
      list-style-type: disc;
      list-style-position: outside;
      margin-top: 10px;
      width: 360px;
      height: 425px;
      padding: 15px;
      display: flex;
      margin-left: -5%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &:after {
        border-bottom: solid 10px transparent;
        border-right: solid 10px transparent;
        border-left: solid 10px #58c1b3;
        transform: translateX(-50%);
        position: absolute;
        z-index: 10;
        content: '';
        top: 97.65%;
        left: 102.8%;
        height: 0;
        width: 0;
      }
    }
  `;

  const CourseHeader = styled.div`
    color: #fff;
    text-align: left;
    text-transform: uppercase;
    @media screen and (min-width: 1001px) {
      max-width: 95%;
      padding: 10px 5px 10px 16px;
      font-size: 1.625rem;
      line-height: 1.78rem;
      font-weight: 600;
    }
    @media screen and (max-width: 1000px) {
      max-width: 95%;
      padding: 10px 0 10px 4px;
      font-size: 1.625rem;
      font-size: 1rem;
      line-height: 1.425rem;
    }
  `;

  const OnDemandContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const OnDemandHeader = styled.div`
    color: #fff;
    background-color: #037ac4;
    opacity: 1;
    text-align: center;
    max-width: 100%;
    @media screen and (min-width: 1001px) {
      padding: 8px 23px;
      margin: 5px 5px 10px 16px;
      border-radius: 4px;
      font-size: 0.875rem;
      line-height: 1.225rem;
      font-weight: 600;
    }
    @media screen and (max-width: 1000px) {
      padding: 8px 16px;
      margin: 5px 0 10px 0;
      border-radius: 4px;
      font-size: 0.775rem;
      line-height: 1.125rem;
      font-weight: 600;
    }
  `;

  const CourseItemContainer = styled.div`
    @media screen and (min-width: 1001px) {
      padding-left: 16px;
    }
    @media screen and (max-width: 1000px) {
      padding-left: 0;
    }
  `;

  const Course = styled.li`
    color: #fff;
    text-align: left;
    @media screen and (min-width: 1001px) {
      max-width: 95%;
      padding: 2px 5px;
      font-size: 1rem;
      line-height: 1.538rem;
      font-weight: 600;
      margin: 0 0 0 20px;
    }
    @media screen and (max-width: 1000px) {
      max-width: 95%;
      padding: 2px 5px;
      font-size: 0.875rem;
      line-height: 1.475rem;
      font-weight: 600;
      margin: 0 0 0 20px;
    }
  `;

  const CarouselContainer = styled.div`
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin: 10px 30px 30px 30px;
      width: 25rem;
    }
    @media screen and (min-width: 1001px) {
      display: none;
    }
  `;

  function DisplayTrainingInCarousel(props) {
    return (
      <a>
        <KitCard
          style={{
            width: '25rem',
            height: '875px',
            padding: '5px 0 20px 0',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <React.Fragment key={props.series.contentID}>
            <KitCardBody style={{ margin: 0 }}>
              <MobileSeriesBlock>
                <img src={props.series.fields.seriesIcon && props.series.fields.seriesIcon.url} alt={props.series.fields.seriesName} />
                <MobileSeriesTitle>{props.series.fields.seriesName}</MobileSeriesTitle>
              </MobileSeriesBlock>
              <SeriesContainer>
                {props.series && props.series.fields && <MobileSeriesDescription>{props.series.fields.introduction}</MobileSeriesDescription>}
              </SeriesContainer>
              <CoursesContainer
                style={{
                  backgroundImage: `url(${props.series.fields.backgroundImage.url})`,
                }}
              >
                {props.series && props.series.fields && props.series.fields.courseList && props.series.fields.courseList.length > 0 && (
                  <CourseList>
                    {props.series && props.series.fields && <CourseHeader>INCLUDED IN {props.series.fields.seriesName}</CourseHeader>}
                    <OnDemandContainer>
                      {props.series && props.series.fields.showOnDemandText && (
                        <OnDemandHeader>Well-Managed Schools On-Demand with select services</OnDemandHeader>
                      )}
                    </OnDemandContainer>
                    <CourseItemContainer>
                      {props.series.fields.courseList &&
                        props.series.fields.courseList.length > 0 &&
                        props.series.fields.courseList
                          .sort((a, b) => {
                            if (parseInt(a.properties.itemOrder, 10) > parseInt(b.properties.itemOrder, 10)) return 1;
                            if (parseInt(a.properties.itemOrder, 10) < parseInt(b.properties.itemOrder, 10)) return -1;
                            return 0;
                          })
                          .map((course, index) => {
                            return <Course key={index}>{course.fields.courseName}</Course>;
                          })}
                    </CourseItemContainer>
                    {seriesSelected && seriesSelected.fields.showActionButton === 'true' && (
                      <Link href={seriesSelected.fields.buttonUrl.href} passHref legacyBehavior>
                        <KitButton
                          size='sm'
                          round
                          style={{ backgroundColor: theme.palette.primary.light, margin: '25px 5px 5px 4px', width: '130px' }}
                          aria-label={'Learn More'}
                          color='info'
                        >
                          Learn More
                        </KitButton>
                      </Link>
                    )}
                  </CourseList>
                )}
              </CoursesContainer>
            </KitCardBody>
          </React.Fragment>
        </KitCard>
      </a>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container>
        <Heading3 color='#000000' underline={true} uppercase={true}>
          {fields.title}
        </Heading3>
        <ListContainer>
          <ContentContainer>
            <SeriesRow>
              {fields.seriesList &&
                fields.seriesList.length > 0 &&
                fields.seriesList
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((series, index) => {
                    return (
                      <SeriesBlock
                        key={index}
                        onClick={(e) => SelectSeries(series)}
                        style={{
                          backgroundColor:
                            (seriesSelected && seriesSelected.fields.seriesName.toLowerCase()) ===
                              (series && series.fields.seriesName.toLowerCase()) && 'rgba(63, 164, 240, 0.08)',
                        }}
                      >
                        <SeriesImg src={series.fields.seriesIcon && series.fields.seriesIcon.url} alt={series.fields.seriesName} />
                        <SeriesTitle style={{ color: seriesSelected === series ? '#cf451f' : '#000' }}>{series.fields.seriesName}</SeriesTitle>
                      </SeriesBlock>
                    );
                  })}
            </SeriesRow>
            <SeriesContainer>
              {seriesSelected && seriesSelected.fields && <SeriesDescription>{seriesSelected.fields.introduction}</SeriesDescription>}
            </SeriesContainer>

            {seriesSelected && seriesSelected.fields && seriesSelected.fields.courseList && seriesSelected.fields.courseList.length > 0 && (
              <CoursesContainer
                style={{
                  backgroundImage: `url(${seriesSelected.fields.backgroundImage.url})`,
                  borderRadius: '6px',
                }}
              >
                <CourseList>
                  {seriesSelected && seriesSelected.fields && <CourseHeader>INCLUDED IN {seriesSelected.fields.seriesName}</CourseHeader>}
                  <OnDemandContainer>
                    {seriesSelected && seriesSelected.fields.showOnDemandText && (
                      <OnDemandHeader>Well-Managed Schools On-Demand with select services</OnDemandHeader>
                    )}
                  </OnDemandContainer>
                  <CourseItemContainer>
                    {seriesSelected.fields.courseList &&
                      seriesSelected.fields.courseList.length > 0 &&
                      seriesSelected.fields.courseList
                        .sort((a, b) => {
                          if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                          if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                          return 0;
                        })
                        .map((course, index) => {
                          return <Course key={index}>{course.fields.courseName}</Course>;
                        })}
                  </CourseItemContainer>
                  {seriesSelected && seriesSelected.fields.showActionButton === 'true' && (
                    <Link href={seriesSelected.fields.buttonUrl.href} passHref legacyBehavior>
                      <KitButton
                        size='sm'
                        round
                        style={{ backgroundColor: theme.palette.primary.light, margin: '25px 5px 5px 16px', width: '130px' }}
                        aria-label={'Learn More'}
                      >
                        {seriesSelected.fields.buttonUrl &&
                          seriesSelected.fields.buttonUrl.href &&
                          seriesSelected.fields.buttonUrl.href.length > 0 && (
                            <a href={seriesSelected.fields.buttonUrl.href} rel='noopener noreferrer'>
                              Learn More
                            </a>
                          )}
                      </KitButton>
                    </Link>
                  )}
                </CourseList>
              </CoursesContainer>
            )}
          </ContentContainer>
        </ListContainer>
      </Container>
      <MobileContainer>
        <Heading3 color='#000000' underline={true} uppercase={true}>
          {fields.title}
        </Heading3>
        <CarouselContainer>
          <Carousel>
            {fields.seriesList
              .sort((a, b) => {
                if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                return 0;
              })
              .map((series, index) => (
                <DisplayTrainingInCarousel key={index} series={series} />
              ))}
          </Carousel>
        </CarouselContainer>
      </MobileContainer>
    </Box>
  );
};

export default ProfessionalLearningSeries;

const ResourceImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  minHeight: '75%',
}));

const ResourceIcon = styled('div')(({ theme }) => ({
  maxWidth: '80px',
  [theme.breakpoints.up('md')]: {
    padding: '5px 40px 10px 40px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ResourceTitle = styled('div')(({ theme }) => ({
  margin: '-10px 10px',
}));

const SeriesDescription = styled('div')(({ theme }) => ({
  margin: '5px 10px 15px 0',
  fontSize: '1.00rem',
  lineHeight: '1.625rem',
  minHeight: '105px',
  [theme.breakpoints.up('md')]: {
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileSeriesDescription = styled('div')(({ theme }) => ({
  margin: '5px 0 15px 0',
  fontSize: '1.00rem',
  lineHeight: '1.325rem',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    textAlign: 'left',
    padding: 0,
  },
}));

const ListText = styled('div')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  lineHeight: '1.10rem',
}));

const HandoutText = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '0.90rem',
  lineHeight: '1.00rem',
}));

const Description = styled('div')(({ theme }) => ({
  fontWeight: 300,
  margin: '5px 10px 15px 0',
  fontSize: '0.85rem',
  lineHeight: '0.95rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 25px',
  },
}));
