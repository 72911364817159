/* eslint-disable no-unused-vars */
// from: https://github.com/remarkjs/react-markdown/blob/main/lib/rehype-filter.js
import { visit } from 'unist-util-visit';
import type { Root } from 'mdast';

export type RehypeFilterOptions = {
  allowedElements?: string[];
  disallowedElements?: string[];
  allowElement?: (element: Element, index: number, parent: Element | Root) => boolean | undefined;
  unwrapDisallowed?: boolean;
};

export function rehypeFilter(options: RehypeFilterOptions) {
  if (options.allowedElements && options.disallowedElements) {
    throw new TypeError('Only one of `allowedElements` and `disallowedElements` should be defined');
  }

  if (options.allowedElements || options.disallowedElements || options.allowElement) {
    return (tree) => {
      visit(tree, 'element', (node, index, parent_) => {
        const parent = /** @type {Element|Root} */ parent_;
        let remove: boolean = false;

        if (options.allowedElements) {
          remove = !options.allowedElements.includes(node.tagName);
        } else if (options.disallowedElements) {
          remove = options.disallowedElements.includes(node.tagName);
        }

        if (!remove && options.allowElement && typeof index === 'number') {
          remove = !options.allowElement(node, index, parent);
        }

        if (remove && typeof index === 'number') {
          if (options.unwrapDisallowed && node.children) {
            parent.children.splice(index, 1, ...node.children);
          } else {
            parent.children.splice(index, 1);
          }

          return index;
        }

        return undefined;
      });
    };
  }
}
