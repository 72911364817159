import { createElement, useEffect, useRef } from 'react';

const DangerouslySetHtmlContent = ({ html, ...rest }) => {
  const divRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null");
    if (!isFirstRender.current) return;
    isFirstRender.current = false;

    const addChildren = () => {
      const slotHtml = document.createRange().createContextualFragment(html);

      divRef.current.innerHTML = ''; // Clear the container

      const appendChild = (child) => {
        if (child.tagName !== 'SCRIPT') {
          divRef.current.appendChild(child);
          return;
        }

        if (!child.src) {
          divRef.current.appendChild(child);
          return;
        }

        const scriptElement = document.createElement('script');
        scriptElement.src = child.src;
        scriptElement.type = child.type;
        scriptElement.text = child.text;
        scriptElement.crossorigin = child.crossorigin;
        scriptElement.integrity = child.integrity;
        scriptElement.nomodule = child.nomodule;
        scriptElement.nonce = child.nonce;
        scriptElement.referrerpolicy = child.referrerpolicy;
        //scriptElement.onload = () => alert('script loaded');
        divRef.current.appendChild(scriptElement);
      };

      for (const child of [...slotHtml.children]) {
        appendChild(child);
      }
    };

    addChildren();
  }, [html, divRef]);

  return <div {...rest} ref={divRef} />;
};

export default DangerouslySetHtmlContent;
