import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSession } from 'next-auth/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import AccountProfile from 'components/common/subcomponents/AccountInfo/AccountProfile';
import BillingHistory from 'components/common/subcomponents/AccountInfo/BillingHistory';
import Subscriptions from 'components/common/subcomponents/AccountInfo/Subscriptions';
import PleaseWait from '../common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, OTHER_DISTRICT, OTHER_SCHOOL } from 'services/constants';

function TabPanel(props) {
  const { children, value, index, tabName, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`${tabName}-panel`} aria-labelledby={tabName} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AccountInfo = (module) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { data: session, status } = useSession();
  const initialized = useRef(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userRecord, setUserRecord] = useState(null);
  const [schoolRecord, setSchoolRecord] = useState(null);
  const [districtRecord, setDistrictRecord] = useState(null);

  useEffect(() => {
    const init = async () => {
      setErrorMessage(null);

      try {
        setIsLoading(true);
        // get user record
        const res = await callApi(`/api/db/user?email=${session?.user?.email}`);
        if (res.district_id === OTHER_DISTRICT.id) {
          res.district_name =
            res.other_city?.length > 0 && res.other_agency?.length > 0 ? `Agency: ${res.other_agency} (${res.other_city})` : 'Agency: N/A';
          res.school_name = '';
        } else if (res.school_id === OTHER_SCHOOL.id) {
          res.school_name = '';
        }
        setUserRecord(res);
        // get school record
        if (session?.user?.school_id && session?.user?.district_id) {
          const res2 = await callApi(`/api/db/school?sid=${session?.user?.school_id}&did=${session?.user?.district_id}`);
          res2.subscriptions = res2.subscriptions?.filter((s) => {
            if (s.is_seat_based) {
              return s.seat_list?.includes(session?.user?.email);
            } else {
              return true;
            }
          });
          setSchoolRecord(res2);
        }
        // get district record
        if (session?.user?.district_id) {
          const res3 = await callApi(`/api/db/district?did=${session?.user?.district_id}`);
          res3.subscriptions = res3.subscriptions?.filter((s) => {
            if (s.is_seat_based) {
              return s.seat_list?.includes(session?.user?.email);
            } else {
              return true;
            }
          });

          setDistrictRecord(res3);
        }
        setIsLoading(false);
        initialized.current = true;
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(ERROR_MESSAGES.Default);
        logClientException(error);
      }
    };

    if (session?.user && !initialized.current) {
      init();
    }
  }, [session?.user]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        width: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        // backgroundColor: 'rgba(217, 217, 217, 0.3)',
        width: '100%',
        mt: '20px',
      }}
    >
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <PleaseWait isLoading={isLoading} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          mt: '10px',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={(e, nv) => setTabIndex(nv)}
          aria-label='Account Tab Selection'
          sx={{ backgroundColor: 'transparent', '& .MuiTabs-indicator': { display: 'none', transition: 'none' } }}
        >
          <Tab
            label='Profile'
            id='profile-tab'
            aria-controls='profile-tabpanel'
            style={{ backgroundColor: tabIndex === 0 ? 'rgba(63, 164, 240, 0.08)' : 'transparent', color: tabIndex === 0 ? 'red' : '#000' }}
          />
          <Tab
            label='Subscriptions'
            id='subscriptions-tab'
            aria-controls='subscriptions-tabpanel'
            style={{ backgroundColor: tabIndex === 1 ? 'rgba(63, 164, 240, 0.08)' : 'transparent', color: tabIndex === 1 ? 'red' : '#000' }}
          />
          <Tab
            label='Billing'
            id='billing-tab'
            aria-controls='billing-tabpanel'
            style={{ backgroundColor: tabIndex === 2 ? 'rgba(63, 164, 240, 0.08)' : 'transparent', color: tabIndex === 2 ? 'red' : '#000' }}
          />
        </Tabs>
        <TabPanel value={tabIndex} index={0} tabName='profile-tabpanel'>
          <AccountProfile userRecord={userRecord} schoolRecord={schoolRecord} onUserRecordUpdate={(u) => setUserRecord(u)} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} tabName='subscriptions-tabpanel'>
          {/* Passing session and callout fields to Subscriptions component: */}
          <Subscriptions userRecord={userRecord} schoolRecord={schoolRecord} districtRecord={districtRecord} session={session} callout={module} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2} tabName='billing-tabpanel'>
          <BillingHistory userRecord={userRecord} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default AccountInfo;
