import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { callApi } from 'services/apiWrapper';
import { ObservationInstance, ObservationInstanceBase } from 'types/ObservationTypes';
import { observationEventKeys } from './ObservationEventHooks';

export const observationInstanceKeys = {
  observationInstance: (id) => ['observationInstance', id],
  addObservationInstance: (id) => ['addObservationInstance', id],
  deleteObservationInstance: (id) => ['deleteObservationInstance', id],
  updateObservationInstance: (id) => ['updateObservationInstance', id],
};

const addObservationInstance = async (instance: ObservationInstance): Promise<ObservationInstance> => {
  const saved = await callApi('/api/db/observation/instance', 'POST', instance);
  return saved;
};

export const useAddObservationInstance = (instance: ObservationInstance, success: Function, onError: Function) => {
  return useMutation({
    mutationFn: () => addObservationInstance(instance),
    mutationKey: observationInstanceKeys.addObservationInstance(instance.id),
    onSuccess: (data) => {
      if (success) {
        success(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
    // https://tanstack.com/query/latest/docs/framework/react/guides/network-mode#network-mode-offlinefirst
    networkMode: 'offlineFirst',
    retry: false,
  });
};

const deleteObservationInstance = async (id: string): Promise<void> => {
  await callApi('/api/db/observation/instance', 'DELETE', { id });
};

export const useDeleteObservationInstance = (id: string, eventId: string, success: Function) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: () => deleteObservationInstance(id),
    mutationKey: observationInstanceKeys.deleteObservationInstance(id),
    onSuccess: (data) => {
      //client.invalidateQueries({ queryKey: observationInstanceKeys.observationInstance(id) });
      client.invalidateQueries({ queryKey: observationEventKeys.observationEventInstanceList(eventId) });
      if (success) {
        success(data);
      }
    },
    networkMode: 'offlineFirst', // will attempt query one, then pause retries
    retry: false,
  });
};

const fetchObservationInstance = async (id) => {
  if (!id) return null;
  const res = await callApi(`/api/db/observation/instance?id=${id}`);
  return res;
};

export const useObservationInstanceById = (id: string): UseQueryResult<ObservationInstanceBase, Error> => {
  return useQuery({
    queryKey: observationInstanceKeys.observationInstance(id),
    queryFn: () => fetchObservationInstance(id),
    networkMode: 'offlineFirst', // will attempt query one, then pause retries
    retry: false,
  });
};

const updatedObservationInstance = async (instance: ObservationInstance): Promise<ObservationInstance> => {
  const saved = await callApi('/api/db/observation/instance', 'PUT', instance);
  return saved;
};

export const useUpdateObservationInstance = (instance: ObservationInstance, success: Function, onError: Function) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: () => updatedObservationInstance(instance),
    mutationKey: observationInstanceKeys.updateObservationInstance(instance?.id),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: observationInstanceKeys.observationInstance(instance.id) });
      if (success) {
        success(data);
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
    // https://tanstack.com/query/latest/docs/framework/react/guides/network-mode#network-mode-offlinefirst
    networkMode: 'offlineFirst',
    retry: false,
  });
};
