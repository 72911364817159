// Package imports
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';

const EventSummaryMetricCard = ({ title, subTitle, metric }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: { xs: '48%', sm: '24%' } }}>
      <KitCard style={{ padding: '.75rem 1rem', margin: '2px', minHeight: '117px' }}>
        <KitCardBody style={{ padding: 0, display: 'flex' }}>
          <Box id='outer-box' sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <Box>
              <Box sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: theme.palette.text.mediumDark }}>{title}</Box>
              <Box sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px', minHeight: '18px', color: theme.palette.obsBlue.main }}>
                {subTitle}
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: '28px',
                fontWeight: 600,
                lineHeight: '35px',
                color: theme.palette.obsBlue.main,
                marginTop: 'auto',
              }}
            >
              {metric}
            </Box>
          </Box>
        </KitCardBody>
      </KitCard>
    </Box>
  );
};

export default EventSummaryMetricCard;
