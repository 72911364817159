import { ObservationInstance } from '../types/ObservationTypes';

const displayActivity = (obs: ObservationInstance) => {
  if (obs.activity.indexOf('Other') > -1) {
    if (obs?.other_activity?.length ?? 0 > 0) {
      return obs.activity.join(', ') + ' (' + obs.other_activity + ')';
    } else {
      return obs.activity.join(', ');
    }
  } else {
    return obs.activity.toString();
  }
};

export { displayActivity };
