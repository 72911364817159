import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';

const CardLabel = ({ label }) => {
  return (
    <Typography variant='subtitle2' color='text.secondary' component='span' marginRight='5px' display='inline-block'>
      {label}
    </Typography>
  );
};

const CardValue = ({ value }) => {
  return (
    <Typography
      variant='subtitle2'
      component='span'
      textOverflow='ellipsis'
      whiteSpace='nowrap'
      display='inline-block'
      overflow='hidden'
      sx={{ maxWidth: '300px', verticalAlign: 'middle' }}
      title={value}
    >
      {value}
    </Typography>
  );
};

const FeatureSwitchCard = (props) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <KitCard style={{ width: '80%', color: `${theme.palette.primary.main}` }}>
        <KitCardBody>
          <Typography variant='h6' sx={{ textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {props.featureSwitch?.switch_name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div>
                <CardLabel label='Switch Type' />
                <CardValue value={props.featureSwitch?.switch_type} />
              </div>
              <div>
                <CardLabel label='Enabled' />
                <CardValue value={<Checkbox checked={props.featureSwitch.enabled} disabled />} />
              </div>
              <div>
                <CardLabel label='Value' />
                <CardValue value={props.featureSwitch?.value} />
              </div>
            </Box>
          </Box>
          <KitButton style={{ float: 'right' }} color='info' round size='sm' onClick={() => props.editFunc()} title='Edit'>
            <EditIcon /> Edit
          </KitButton>
        </KitCardBody>
      </KitCard>
    </Box>
  );
};

FeatureSwitchCard.propTypes = {
  featureSwitch: PropTypes.object.isRequired,
  editFunc: PropTypes.func.isRequired,
};

export default FeatureSwitchCard;
