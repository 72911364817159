import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Checkbox, FormControlLabel, useMediaQuery, Pagination, IconButton, Drawer, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSession } from 'next-auth/react';
import { logServerException } from 'appinsights/clientAppInsights';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PageContainer from 'components/common/subcomponents/PageContainer';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';
import { LESSON_PLAN_DIFFICULTY_LEVEL } from 'services/constants';
import { useRef, useState } from 'react';
import LessonPlanCard from './LessonPlanLandingComponents';
import { FilterIcon } from 'components/common/subcomponents/SvgIcons';
import NewsLetterComponent from './NewsletterComponent';
import { isCustomer } from 'services/subscriptionUtils';

const PER_PAGE = 14;

const LessonPlanLanding = ({ module: { fields }, customData }) => {
  const theme = useTheme();
  const { data: session, status } = useSession();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const behaviorTagList = customData?.behaviorTagList?.items ?? [];
  const lessonPlanList = customData?.lessonPlans?.items ?? [];
  const [behaviorTagFilter, setBehaviorTagFilter] = useState([]);
  const [difficultyLevelFilter, setDifficultyLevelFilter] = useState([]);
  const [filteredLessonPlanList, setFilteredLessonPlanList] = useState(lessonPlanList);
  const [page, setPage] = useState(1);
  const [filterOpen, setFilterOpen] = useState(false);
  const scrollRef = useRef(null);

  const clearFilter = () => {
    setBehaviorTagFilter([]);
    setDifficultyLevelFilter([]);
    filterLessonPlans([], []);
    setPage(1);
  };

  const filterLessonPlans = (behaviorTags, levelTags) => {
    if (behaviorTags.length === 0 && levelTags.length === 0) {
      setPage(1);
      setFilteredLessonPlanList(lessonPlanList);
      scrollToStart();
      return;
    }
    const temp = lessonPlanList.filter((l) => {
      let hasBehaviorTag = false;
      const thisPlanTags = behaviorTagList.filter((b) => l?.fields?.behaviorTags_ValueField?.split(',')?.indexOf(b.contentID?.toString()) > -1) ?? [];
      thisPlanTags.forEach((tag) => {
        if (behaviorTags.indexOf(tag.fields.textValue) > -1) {
          hasBehaviorTag = true;
        }
      });
      const hasLevelTag = levelTags.indexOf(l?.fields?.difficultyLevel) > -1;
      return hasBehaviorTag || hasLevelTag;
    });
    setPage(1);
    setFilteredLessonPlanList(temp);
    scrollToStart();
  };

  const updatePage = (p) => {
    setPage(p);
  };

  const getPageOfData = () => {
    const start = PER_PAGE * (page - 1);
    return filteredLessonPlanList.slice(start, start + PER_PAGE);
  };

  const filterChange = (type, e) => {
    if (type === 'behaviorTag') {
      const temp = behaviorTagFilter.slice();
      if (e.target.checked && temp.indexOf(e.target.value) === -1) {
        temp.push(e.target.value);
      } else if (!e.target.checked) {
        const i = temp.indexOf(e.target.value);
        if (i > -1) {
          temp.splice(i, 1);
        }
      }
      setBehaviorTagFilter(temp);
      filterLessonPlans(temp, difficultyLevelFilter);
    }
    if (type === 'difficultyLevel') {
      const temp = difficultyLevelFilter.slice();
      if (e.target.checked && temp.indexOf(e.target.value) === -1) {
        temp.push(e.target.value);
      } else if (!e.target.checked) {
        const i = temp.indexOf(e.target.value);
        if (i > -1) {
          temp.splice(i, 1);
        }
      }
      setDifficultyLevelFilter(temp);
      filterLessonPlans(behaviorTagFilter, temp);
    }
  };

  const getFilterItems = () => {
    return (
      <>
        <Box sx={{ display: isSmallScreen ? 'flex' : 'none', justifyContent: 'flex-end', width: '100%' }}>
          <IconButton onClick={() => setFilterOpen(false)} sx={{ color: theme.palette.text.dark }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <KitButton color='info' onClick={clearFilter} round style={{ marginBottom: '20px' }}>
          Clear All Selection
        </KitButton>
        <FilterHeading>Behavior Based</FilterHeading>
        <div style={{ marginBottom: '.75rem' }}>
          {behaviorTagList.map((b) => {
            return (
              <FormControlLabel
                sx={{ width: '100%', fontSize: '13px', fontWeight: 400, lineHeight: '22px', color: theme.palette.text.medium }}
                key={b.fields.textValue}
                control={
                  <Checkbox
                    size='small'
                    checked={behaviorTagFilter.indexOf(b.fields.textValue) > -1}
                    value={b.fields.textValue}
                    onChange={(e) => filterChange('behaviorTag', e)}
                  />
                }
                label={b.fields?.textValue}
              />
            );
          })}
        </div>
        <FilterHeading>Difficulty Level</FilterHeading>
        <div>
          {LESSON_PLAN_DIFFICULTY_LEVEL.map((l, i) => {
            return (
              <FormControlLabel
                sx={{ width: '100%', fontSize: '13px', fontWeight: 400, lineHeight: '22px', color: theme.palette.text.medium }}
                key={l.name}
                control={
                  <Checkbox
                    size='small'
                    checked={difficultyLevelFilter.indexOf(l.value) > -1}
                    value={l.value}
                    onChange={(e) => filterChange('difficultyLevel', e)}
                  />
                }
                label={l.name}
              />
            );
          })}
        </div>
      </>
    );
  };

  const scrollToStart = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof window !== 'undefined') {
        window.setTimeout(() => {
          scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 250);
      }
    }
  };

  return (
    <PageContainer>
      {(status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
            marginTop: { xs: '100px', sm: '150px' },
          }}
          open={true}
        >
          <div style={{ width: '100%' }}>
            <NewsLetterComponent
              cTAText1={fields.cTAText1}
              cTAText2={fields.cTAText2}
              unsubscribeText={fields.unsubscribeText}
              cTAlink={fields.cTAlink}
              cTALinkColor={fields.cTALinkColor}
              backgroundColor={fields.backgroundColor}
              accentImage={fields.accentImage}
            />
          </div>
        </Backdrop>
      )}

      <Heading4>{fields?.howToUseLabel}</Heading4>
      <HowToUseList>
        {fields.howToUseList.map((v, i) => {
          return (
            <div style={{ display: 'flex' }} key={i}>
              <div className='num-heading'>{i + 1}</div>
              <div className='content'>{v.fields.textValue}</div>
            </div>
          );
        })}
      </HowToUseList>
      <Drawer
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        anchor='left'
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box id='filterWrapper' sx={{ width: 250, padding: '.5rem', height: '100%' }} role='presentation'>
          {getFilterItems()}
        </Box>
      </Drawer>
      <Box sx={{ display: isSmallScreen ? 'flex' : 'none' }}>
        <IconButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.scrollTo(0, 0);
              setFilterOpen(true);
              window.setTimeout(() => {
                window.scrollTo(0, 0);
              }, 250);
            }
          }}
        >
          <FilterIcon fillColor={theme.palette.primary.main} height='24' width='24' />
          <span style={{ fontSize: '18px', lineHeight: '23.4px', weight: 400, color: theme.palette.text.dark, marginLeft: '.5rem' }}> Filter</span>
        </IconButton>
      </Box>
      <Box
        ref={scrollRef}
        sx={{
          display: { md: 'grid', xs: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
          gridTemplateColumns: { xs: 'auto', md: 'auto', lg: 'auto 40% 40%' },
          marginTop: { xs: 0, md: '1.5rem' },
        }}
      >
        <div style={{ display: isSmallScreen ? 'none' : 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>{getFilterItems()}</div>

        <LessonPlanColumn isSmallScreen={isSmallScreen}>
          {getPageOfData().length === 0 && <div>No Lessons Found. Try to adjust the filters.</div>}
          {getPageOfData().map((l, i) => {
            if (!isSmallScreen && i % 2 === 0) {
              return <LessonPlanCard item={l} key={i} behaviorTagList={behaviorTagList} />;
            } else if (isSmallScreen) {
              return <LessonPlanCard item={l} key={i} behaviorTagList={behaviorTagList} />;
            }
          })}
        </LessonPlanColumn>
        <LessonPlanColumn number={2} isSmallScreen={isSmallScreen} id='planColumn2'>
          {getPageOfData().map((l, i) => {
            if (!isSmallScreen && i % 2 === 1) {
              return <LessonPlanCard item={l} key={i} behaviorTagList={behaviorTagList} />;
            }
          })}
        </LessonPlanColumn>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem', marginBottom: '1.5rem' }}>
        <Pagination
          color='primary'
          size='large'
          shape='circular'
          count={Math.ceil(filteredLessonPlanList.length / PER_PAGE)}
          page={page}
          onChange={(ev, val) => {
            updatePage(val);
            scrollToStart();
          }}
        />
      </Box>
    </PageContainer>
  );
};

const LessonPlanColumn = styled('div')(({ theme, number, isSmallScreen }) => ({
  display: isSmallScreen && number === 2 ? 'none' : 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.lessonPlanCard': {
    marginTop: 0,
    marginBottom: '1.25rem',
  },
  marginTop: isSmallScreen ? '1rem' : 0,
}));

const HowToUseList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '1rem',
  '.content': {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.text.light,
    maxWidth: '270px',
  },
  '.num-heading': {
    fontSize: '58px',
    lineHeight: '50px',
    color: theme.palette.grey[300],
    marginRight: '.5rem',
    fontWeight: 700,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '.content': {
      maxWidth: '100%',
      marginBottom: '1rem',
    },
    '.num-heading': {
      fontSize: '50px',
    },
  },
}));

const FilterHeading = styled('div')(({ theme }) => ({
  fontSize: '19px',
  fontWeight: 600,
  lineHeight: '21px',
}));

// This is called during server-side rendering (not part of NextJS).
LessonPlanLanding.getCustomInitialProps = async ({ agility, languageCode }) => {
  const api = agility;

  try {
    const behaviorTagList = await api.getContentList({
      referenceName: 'lessonplanbehaviortags',
      languageCode,
      take: 250,
      contentLinkDepth: 5,
      expandAllContentLinks: true,
      skip: 0,
      sort: 'fields.textValue',
      direction: api.types.SortDirections.ASC,
    });

    const lessonPlans = await api.getContentList({
      referenceName: 'lessonplans',
      languageCode,
      take: 250,
      contentLinkDepth: 5,
      expandAllContentLinks: true,
    });

    return {
      behaviorTagList,
      lessonPlans,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default LessonPlanLanding;
