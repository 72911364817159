import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import { getIcon } from '../../services/agilityTranslations';

const TextWithIcon = ({ module }) => {
  const { fields } = module;

  return (
    <Container>
      <a id={fields.anchor}></a>
      <TitleContainer>
        <IntroTitle>{fields.title}</IntroTitle>
      </TitleContainer>
      {fields.iconName && <IconContainer>{getIcon(fields.iconName, fields.iconColor, '3.00rem')}</IconContainer>}
      <TextContainer>
        <Description>{fields.description}</Description>
      </TextContainer>
    </Container>
  );
};

export default TextWithIcon;

const Container = styled('div')(({ theme }) => ({
  padding: '40px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TitleContainer = styled('div')(({ theme }) => ({
  maxWidth: '800px',
  alignSelf: 'center',
}));

const IntroTitle = styled('div')(({ theme }) => ({
  fontSize: '1.65rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
  fontWeight: 600,
  margin: '8px 20px 8px 0px',
  alignSelf: 'center',
}));

const IconContainer = styled('div')(({ theme }) => ({
  fontSize: '1.30rem',
  maxWidth: '70px',
  alignSelf: 'center',
}));

const Icon = styled('div')(({ theme }) => ({
  maxWidth: '50px',
  margin: '13px 20px 8px 0px',
  alignSelf: 'center',
}));

const TextContainer = styled('div')(({ theme }) => ({
  maxWidth: '800px',
  alignSelf: 'center',
}));

const Description = styled('div')(({ theme }) => ({
  fontSize: '1.00rem',
  fontWeight: 300,
  color: theme.palette.common.black,
  margin: 5,
}));
