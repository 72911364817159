import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const TabPanel = (props) => {
  const { children, value, index, name, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`${name}-tab-panel`} aria-labelledby={`${name}-tab`} {...other}>
      {value === index && <Box sx={{ paddingTop: '.75rem' }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default TabPanel;
