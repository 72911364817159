import React from 'react';
import { useTheme } from '@emotion/react';
import { logServerException } from 'appinsights/clientAppInsights';
import { useSession } from 'next-auth/react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import Section from 'components/common/subcomponents/Section';
import { weekOfYear } from 'services/dateUtils';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import { headingStyle } from 'services/styleUtils/headingStyleUtils';
import { TEXT_SIZE } from 'services/styleUtils/contentStyleUtils';
import styled from '@emotion/styled';
import Link from 'next/link';
import { COMMON_ROUTES } from 'services/constants';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import { isCustomer } from 'services/subscriptionUtils';

const LessonPlanoftheWeek = (props) => {
  const {
    customData,
    module: { fields },
  } = props;
  const lessonPlan = customData?.lessonPlan;
  const theme = useTheme();
  const { data: session, status } = useSession();

  if (status === 'loading' || status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) {
    return <div></div>;
  }

  return (
    <Section styleProps={{ fields: { margin: 'md' } }}>
      <FreeformHeader level={'h4'} text={fields?.heading} style={headingStyle()} />
      <div
        style={{
          color: theme.palette.text.light,
          fontSize: TEXT_SIZE.get('lg').size,
          lineHeight: TEXT_SIZE.get('lg').lineHeight,
          marginTop: '.75rem',
        }}
      >
        {fields?.introParagraph}
      </div>
      <CalloutWrapper>
        <div className='leftColumn'>
          <div className='banner'>
            <span style={{ zIndex: 5, position: 'relative' }}>{fields?.skillBannerLabel}</span>
            <img
              src={fields?.skillBannerBackgroundImage.url}
              alt='background image'
              style={{ position: 'absolute', top: 0, left: '-1.75rem', zIndex: 0 }}
            />
          </div>
          <div className='planTitle'>{lessonPlan?.fields?.title}</div>
          <div className='paragraph'>{lessonPlan?.fields?.summary}</div>
          <div>
            {fields?.callsToAction?.map((cta, i) => {
              return (
                <Link
                  key={i}
                  href={
                    cta.fields?.link?.text?.toLowerCase().startsWith('view lesson')
                      ? `${COMMON_ROUTES.LessonPlan}/${lessonPlan?.fields?.slug}`
                      : cta.fields?.link?.href
                  }
                  passHref
                  legacyBehavior
                >
                  <KitButton
                    round
                    size='sm'
                    style={{
                      backgroundColor: buttonColor(theme, cta.fields?.style?.fields?.color),
                      marginRight: '1rem',
                    }}
                  >
                    {cta.fields?.link?.text}
                  </KitButton>
                </Link>
              );
            })}
          </div>
        </div>
        <div className='rightColumn'>
          <img src={fields?.image?.url} alt={fields?.image?.label} />
        </div>
      </CalloutWrapper>
    </Section>
  );
};

const CalloutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '.75rem',
  backgroundColor: theme.palette.background.lightGrey,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  '.leftColumn': {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '1.75rem',
    marginBottom: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      '.banner > img': {
        width: '300px',
      },
      '.banner': {
        paddingTop: '0 !important',
        marginBottom: '0 !important',
      },
      '.banner > span': {
        fontSize: '21px',
        lineHeight: '30px',
        fontWeight: 400,
        top: '-12px',
      },
      '.planTitle': {
        fontSize: '21px',
        lineHeight: '36px',
      },
      '.paragraph': {
        fontSize: '13px',
        lineHeight: '18px',
      },
    },
    '.banner': {
      paddingTop: '5px',
      fontSize: '40px',
      lineHeight: '57px',
      fontWeight: 400,
      textTransform: 'uppercase',
      position: 'relative',
      color: theme.palette.common.white,
      marginBottom: '1.75rem',
    },
    '.planTitle': {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: 700,
    },
    '.paragraph': {
      height: '90px',
      overflow: 'hidden',
      marginRight: '1.75rem',
    },
  },
  '.rightColumn': {
    position: 'relative',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

// This is called during server-side rendering (not part of NextJS).
LessonPlanoftheWeek.getCustomInitialProps = async ({ agility, languageCode }) => {
  const api = agility;

  let lessonPlan = null;

  try {
    //get lesson plans
    let raw = await api.getContentList({
      referenceName: 'lessonplans',
      languageCode,
      take: 250,
      contentLinkDepth: 5,
      expandAllContentLinks: true,
    });

    if (raw) {
      const weekNum = weekOfYear() - 1;
      const planCount = raw.items?.length;
      if (planCount > weekNum) {
        lessonPlan = raw.items[weekNum];
      } else {
        const rem = weekNum % planCount;
        lessonPlan = raw.items[rem];
      }
    }

    return {
      lessonPlan,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default LessonPlanoftheWeek;
