import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Grid from '@mui/material/Grid';
import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';

const FeaturedPost = ({ module, customData }) => {
  const { fields } = module;

  if (fields.length <= 0 || !fields.post) {
    return <div></div>;
  }

  // format date
  const dateStr = new Date(fields?.post.fields.date).toLocaleDateString();

  return (
    <Grid container direction='row' style={{ marginTop: 30 }}>
      <Grid item xs={11} align='left'>
        <KitCard
          style={{
            margin: 50,
          }}
        >
          <KitCardBody>
            <PostTitle>{fields?.post.fields?.title}</PostTitle>
            <PostDateAndAuthor>
              {dateStr} {fields?.post.fields?.authorName && 'by'} {fields?.post.fields?.authorName}
            </PostDateAndAuthor>
            <PostImage>
              {fields?.post.fields.image && (
                <img src={fields?.post.fields?.image.url} alt={fields?.post.fields?.image.label || fields?.post.fields?.title} />
              )}
            </PostImage>
            <PostContent className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields?.post.fields?.content)}></PostContent>
          </KitCardBody>
        </KitCard>
      </Grid>
    </Grid>
  );
};

export default FeaturedPost;

const CardGrid = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '20px 45px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '3.00em',
}));

const PostCategory = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.10rem',
  lineHeight: '1.2rem',
  fontWeight: 500,
}));

const PostTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '10px 0 20px 0',
  fontSize: '1.95rem',
  lineHeight: '2.20rem',
  fontWeight: 600,
  minWidth: '60%',
}));

const ReadMoreLink = styled('div')(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  margin: '5px 0 30px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));

const MoreButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '30px 0 40px 0',
}));

const PostImage = styled('div')(({ theme }) => ({
  fontSize: '1.65rem',
  color: theme.palette.primary.main,
  fontWeight: 600,
  alignSelf: 'flex-start',
  maxWidth: '300px',
  margin: '13px 20px 8px 0px',
  float: 'left',
  //shapeOutside: 'polygon(0 0, 35% 35%, 35% 35%, 35% 35%)',
}));

const PostDateAndAuthor = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: 'transparent',
  margin: '10px 0 10px 0',
  fontSize: '1.20rem',
  lineHeight: '1.1rem',
  paddingBottom: 10,
}));

const PostContent = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 10px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));
