import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { getLocalStoreString, setLocalStoreString, removeLocalStoreItem } from 'services/storageUtils';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, PRODUCTS } from 'services/constants';
import ClientSideTable from '../../pageComponents/ClientSideTable';
import AddEditDistrictSubsriptionDialog from './AddEditDistrictSubscription';
import YesNoDialog from 'components/common/subcomponents/YesNoDialog';
import { parseBool, stringHasValue } from 'services/stringUtils';
import SeatListToolTip from './SeatListToolTip';
import { Checkbox } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const DistrictManager = (props) => {
  const router = useRouter();
  const [districtList, setDistrictList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [fetching, setFetching] = useState(false);
  const [ssoProviderError, setSsoProviderError] = useState(false);
  const [cleverDistrictIdError, setCleverDistrictIdError] = useState(false);
  const [otherLmsError, setOtherLmsError] = useState(false);
  const [emailDomainError, setEmailDomainError] = useState(false);
  const [useAllowListError, setUseAllowListError] = useState(false);
  const [limitByDomainError, setLimitByDomainError] = useState(false);
  const [districtAdminInfo, setDistrictAdminInfo] = useState({
    district_id: null,
    email_domain: '',
    sso_provider: null,
    other_lms: null,
    clever_district_id: null,
    use_allow_list: null,
    limit_by_email_domain: null,
  });
  const [addEditDistrictSubState, setAddEditDistrictSubState] = useState({
    open: false,
    action: 'Add',
    editDistrict: null,
    editDistrictSub: null,
  });
  const [deleteSub, setDeleteSub] = useState({ open: false, subId: null });
  const [pageState, setPageState] = useState({
    state: null,
    district: null,
  });

  const [savedState, setSavedState] = useState(() => {
    return getLocalStoreString('dm-st');
  });
  const [savedDistrictId, setSavedDistrictId] = useState(() => {
    return getLocalStoreString('dm-did');
  });

  useEffect(() => {
    const init = async () => {
      const tempState = props.stateList.find((s) => s.value === savedState);
      setPageState({ ...pageState, state: tempState });
      await fetchDistrictsForState(tempState);
      setErrorMessage(null);
    };

    if (savedState) {
      init();
    }
  }, [savedState]);

  const fetchDistrictsForState = async (state) => {
    try {
      setFetching(true);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      const districtList = res.results.map((r) => ({ label: r.name, value: r }));
      setDistrictList(districtList);
      let tempDistrict = null;
      if (savedDistrictId) {
        tempDistrict = districtList.find((d) => d?.value.id === savedDistrictId);
      }
      setFetching(false);
      setPageState({ ...pageState, state: state, district: tempDistrict });
      setDistrictAdminInfo({
        district_id: tempDistrict?.value?.id,
        email_domain: tempDistrict?.value?.email_domain,
        sso_provider: tempDistrict?.value?.sso_provider,
        other_lms: tempDistrict?.value?.other_lms,
        clever_district_id: tempDistrict?.value?.clever_district_id,
        use_allow_list: tempDistrict?.value?.use_allow_list,
        limit_by_email_domain: tempDistrict?.value?.limit_by_email_domain,
      });
      resetErrorState();
    } catch (error) {
      console.log(error);
      setFetching(false);
      setErrorMessage(ERROR_MESSAGES.Default);
      setAlertSeverity('error');
      logClientException(error);
    }
  };

  const saveDistrict = async () => {
    try {
      if (!canSave()) {
        return;
      }
      setFetching(true);

      let putObject = {
        district_id: districtAdminInfo.district_id,
        sso_provider: districtAdminInfo.sso_provider,
        email_domain: districtAdminInfo.email_domain || '',
        use_allow_list: parseBool(districtAdminInfo.use_allow_list) || false,
        limit_by_email_domain: parseBool(districtAdminInfo.limit_by_email_domain) || false,
      };

      if (districtAdminInfo.sso_provider === 'Clever') {
        putObject.clever_district_id = districtAdminInfo.clever_district_id;
        putObject.limit_by_email_domain = false;
      } else {
        putObject.clever_district_id = null;
      }
      if (districtAdminInfo.sso_provider === 'Other') {
        putObject.other_lms = districtAdminInfo.other_lms;
      } else {
        putObject.other_lms = null;
      }

      const updatedDistrict = await callApi('/api/db/district-admin-info', 'PUT', putObject);
      pageState.district.value = updatedDistrict;
      let updated2 = districtList.find((d) => d?.value.id === updatedDistrict.id);
      updated2.value = updatedDistrict;

      setFetching(false);
    } catch (error) {
      console.log(error);
      logClientException(error);
      setFetching(false);
      setAlertSeverity('error');
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const canSave = () => {
    let hasError = false;
    if (!stringHasValue(districtAdminInfo?.sso_provider)) {
      setSsoProviderError(true);
      hasError = true;
    } else {
      setSsoProviderError(false);
    }

    if (districtAdminInfo?.sso_provider === 'Other' && (!districtAdminInfo?.other_lms || districtAdminInfo?.other_lms?.length === 0)) {
      setOtherLmsError(true);
      hasError = true;
    } else {
      setOtherLmsError(false);
    }

    if (
      ['None', 'Other'].indexOf(districtAdminInfo?.sso_provider) > -1 &&
      (districtAdminInfo?.use_allow_list === null || districtAdminInfo?.use_allow_list === undefined)
    ) {
      setUseAllowListError(true);
      hasError = true;
    } else {
      setUseAllowListError(false);
    }

    // not using allow list, require limit_by_email_domain
    if (
      ['None', 'Other'].indexOf(districtAdminInfo?.sso_provider) > -1 &&
      districtAdminInfo?.use_allow_list === false &&
      (districtAdminInfo?.limit_by_email_domain === null || districtAdminInfo?.limit_by_email_domain === undefined)
    ) {
      setLimitByDomainError(true);
      hasError = true;
    } else {
      setLimitByDomainError(false);
    }

    if (
      // email domain required for google
      districtAdminInfo?.sso_provider === 'Google' &&
      (!districtAdminInfo?.email_domain || districtAdminInfo?.email_domain.length === 0)
    ) {
      setEmailDomainError(true);
      hasError = true;
    } else if (
      // email domain required if limit_by_email_domain is true for None/Other
      districtAdminInfo?.sso_provider !== 'Google' &&
      ['None', 'Other'].indexOf(districtAdminInfo?.sso_provider) > -1 &&
      parseBool(districtAdminInfo?.limit_by_email_domain) &&
      !stringHasValue(districtAdminInfo?.email_domain)
    ) {
      setEmailDomainError(true);
      hasError = true;
    } else {
      setEmailDomainError(false);
    }

    return !hasError;
  };

  const resetErrorState = () => {
    setCleverDistrictIdError(false);
    setOtherLmsError(false);
    setSsoProviderError(false);
    setUseAllowListError(false);
    setLimitByDomainError(false);
    setEmailDomainError(false);
  };

  const showEditDistrictSub = (subId) => {
    setAddEditDistrictSubState({ open: true, action: 'Update', editDistrict: pageState.district.value, editDistrictSub: subId });
  };
  const showAddDistrictSub = () => {
    setAddEditDistrictSubState({ open: true, action: 'Add', editDistrict: pageState.district.value, editDistrictSub: null });
  };
  const handleAddEditDistrictSubClose = async (doSave, updated) => {
    setAddEditDistrictSubState({ ...addEditDistrictSubState, open: false });
    if (doSave) {
      let updatedDistrict = pageState.district;
      updatedDistrict.value = updated;
      setPageState({ ...pageState, district: updatedDistrict });
    }
  };

  const onDeleteSub = async () => {
    try {
      setDeleteSub({ open: false });
      setFetching(true);
      let deleteObject = {
        district_id: pageState.district.value.id,
        sub_id: deleteSub.subId,
      };
      const sch = await callApi('/api/db/district-subscription', 'DELETE', deleteObject);
      let updatedDistrict = pageState.district;
      updatedDistrict.value.subscriptions = sch.subscriptions;
      setPageState({ ...pageState, district: updatedDistrict });
      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      setAlertSeverity('error');
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const districtHeaderColumns = () => {
    return [
      { text: 'Product', key: 'product', field: 0, isSortable: true },
      { text: 'CRM ID', key: 'crm_id', field: 1, isSortable: true },
      { text: 'Seat License', key: 'is_seat_based', field: 2, isSortable: true },
      { text: '# Seats', key: 'num_seats', field: 3, isSortable: true },
      { text: '# Seats Used', key: 'num_seats', field: 1, isSortable: true },
      { text: 'Start', key: 'start_date', field: 4, isSortable: true },
      { text: 'End', key: 'date', field: 5, isSortable: true },
      { text: '', key: 'edit_button', isSortable: false },
    ];
  };

  const districtSubRecords = () => {
    let records = null;
    if (pageState.district?.value?.subscriptions) {
      records = pageState.district?.value?.subscriptions?.map((s) => {
        return [
          PRODUCTS.find((p) => p.id === s.product)?.name,
          s.crm_id,
          s.is_seat_based ? <Checkbox checked /> : 'No',
          s.num_seats,
          s.is_seat_based ? <SeatListToolTip title={s.seat_list?.length ?? 0} content={s.seat_list ? s.seat_list?.join('\n') : ''} /> : 'N/A',
          DateTime.fromMillis(s.start_date).toFormat('LL-dd-yyyy'),
          DateTime.fromMillis(s.end_date).toFormat('LL-dd-yyyy'),
          <div key={s.id}>
            <KitButton
              color='info'
              justIcon
              size='sm'
              onClick={() => setDeleteSub({ open: true, subId: s.id })}
              title='Delete Subscription'
              style={{ marginRight: '25px' }}
            >
              <DeleteIcon />
            </KitButton>
            <KitButton color='info' round justIcon size='sm' onClick={() => showEditDistrictSub(s.id)} title='Edit code'>
              <EditIcon />
            </KitButton>
          </div>,
        ];
      });
    }
    return records;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <div>
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <YesNoDialog
        open={deleteSub.open}
        content='Are you sure you want to delete this subscription?'
        yesClose={onDeleteSub}
        noClose={() => setDeleteSub({ open: false })}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          {/* style={{backgroundColor: '#f2f2f2', padding: '20px'}} */}
          <Autocomplete
            sx={{ marginTop: '7px' }}
            autoFocus
            disablePortal
            options={props.stateList}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            size='small'
            value={pageState.state}
            onChange={async (e, v) => {
              setPageState({ ...pageState, state: v, district: null });
              if (v?.value) {
                await fetchDistrictsForState(v);
                setLocalStoreString('dm-st', v.value);
              } else {
                removeLocalStoreItem('dm-st');
                setDistrictList(null);
              }
              resetErrorState();
            }}
            renderInput={(params) => <TextField {...params} label='State' />}
          />

          <Autocomplete
            sx={{ marginTop: '7px' }}
            disablePortal
            options={districtList}
            fullWidth
            size='small'
            isOptionEqualToValue={(o, v) => o.value?.id === v?.value?.id}
            disabled={!pageState.state}
            value={pageState.district}
            onChange={(e, v) => {
              if (v?.value?.id) {
                setLocalStoreString('dm-did', v?.value?.id);
              } else {
                removeLocalStoreItem('dm-did');
              }
              setPageState({ ...pageState, district: v });
              setDistrictAdminInfo({
                district_id: v?.value?.id,
                email_domain: v?.value?.email_domain,
                sso_provider: v?.value?.sso_provider,
                other_lms: v?.value?.other_lms,
                clever_district_id: v?.value?.clever_district_id,
                use_allow_list: v?.value?.use_allow_list,
                limit_by_email_domain: v?.value?.limit_by_email_domain,
              });
              resetErrorState();
            }}
            renderInput={(params) => <TextField disabled={!pageState.state} {...params} label='School district' />}
          />

          <FormControl fullWidth sx={{ marginTop: '7px' }} size='small'>
            <InputLabel required id='sso-select' error={ssoProviderError}>
              SSO Provider
            </InputLabel>
            <Select
              labelId='sso-select'
              required
              error={ssoProviderError}
              disabled={!pageState.district}
              id='sso-select-value'
              value={districtAdminInfo?.sso_provider || ''}
              label='SSO Provider'
              onChange={(e) => {
                setDistrictAdminInfo({
                  ...districtAdminInfo,
                  sso_provider: e.target.value,
                  limit_by_email_domain: e.target.value === 'Google' ? true : districtAdminInfo.limit_by_email_domain,
                });
              }}
            >
              <MenuItem value={'Clever'} key={'Clever'}>
                Clever
              </MenuItem>
              <MenuItem value={'Google'} key={'Google'}>
                Google
              </MenuItem>
              <MenuItem value={'None'} key={'None'}>
                None
              </MenuItem>
              <MenuItem value={'Other'} key={'Other'}>
                Other
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            required
            error={useAllowListError}
            hidden={['None', 'Other'].indexOf(districtAdminInfo?.sso_provider) === -1}
            disabled={!pageState.district}
          >
            <FormLabel id='use-allow-list-label'>Limit users to allow list?</FormLabel>
            <RadioGroup
              required
              row
              aria-labelledby='use-allow-list-label'
              name='use-allow-list-rad-group'
              value={districtAdminInfo?.use_allow_list}
              onChange={(e) => {
                setDistrictAdminInfo({
                  ...districtAdminInfo,
                  use_allow_list: parseBool(e.target.value),
                  limit_by_email_domain: parseBool(e.target.value) ? false : districtAdminInfo.limit_by_email_domain,
                });
              }}
            >
              <FormControlLabel value='true' control={<Radio />} label='Yes' />
              <FormControlLabel value='false' control={<Radio />} label='No' />
            </RadioGroup>
          </FormControl>

          <FormControl
            fullWidth
            required
            error={limitByDomainError}
            hidden={['None', 'Other'].indexOf(districtAdminInfo?.sso_provider) === -1 || parseBool(districtAdminInfo?.use_allow_list) === true}
            disabled={!pageState.district}
          >
            <FormLabel id='limit-email-domain-label'>Limit users by email domain?</FormLabel>
            <RadioGroup
              required
              row
              aria-labelledby='limit-email-domain-label'
              name='limit-email-domain-rad-group'
              value={districtAdminInfo?.limit_by_email_domain}
              onChange={(e) => {
                // let district = pageState.district;
                // district.value.limit_by_email_domain = e.target.value;
                // setPageState({ ...pageState, district });
                setDistrictAdminInfo({ ...districtAdminInfo, limit_by_email_domain: parseBool(e.target.value) });
                //canSave();
              }}
            >
              <FormControlLabel value='true' control={<Radio />} label='Yes' />
              <FormControlLabel value='false' control={<Radio />} label='No' />
            </RadioGroup>
          </FormControl>

          <Tooltip title='Unique email domain for the district. i.e. for Boys Town you would enter "boystown.org".' arrow>
            <TextField
              required={
                districtAdminInfo?.sso_provider === 'Google' ||
                (['None', 'Other'].indexOf(districtAdminInfo?.sso_provider) > -1 && parseBool(districtAdminInfo?.limit_by_email_domain) === true)
              }
              value={districtAdminInfo?.email_domain ? districtAdminInfo.email_domain : ''}
              margin='dense'
              id='email-domain'
              label='Email Domain'
              error={emailDomainError}
              disabled={!pageState.district}
              type='text'
              fullWidth
              size='small'
              variant='outlined'
              onChange={(e) => {
                setDistrictAdminInfo({ ...districtAdminInfo, email_domain: e.target.value });
              }}
              inputProps={{ maxLength: 100 }}
            />
          </Tooltip>

          <Tooltip title='The Clever District Id is obtained from the Clever Portal apps.clever.com for the district.' arrow>
            <TextField
              value={districtAdminInfo?.clever_district_id || ''}
              error={cleverDistrictIdError}
              margin='dense'
              id='clever-district-id'
              label='Clever District Id'
              sx={{ display: districtAdminInfo?.sso_provider === 'Clever' ? 'block' : 'none' }}
              type='text'
              fullWidth
              size='small'
              variant='outlined'
              onChange={(e) => {
                setDistrictAdminInfo({ ...districtAdminInfo, clever_district_id: e.target.value });
              }}
              inputProps={{ maxLength: 100 }}
            />
          </Tooltip>

          <Tooltip title='Please provide the name of the LMS the customer uses.' arrow>
            <TextField
              value={districtAdminInfo?.other_lms || ''}
              error={otherLmsError}
              required
              margin='dense'
              id='lms-id'
              label='LMS'
              sx={{ display: districtAdminInfo?.sso_provider === 'Other' ? 'block' : 'none' }}
              type='text'
              fullWidth
              size='small'
              variant='outlined'
              onChange={(e) => {
                setDistrictAdminInfo({ ...districtAdminInfo, other_lms: e.target.value });
              }}
              inputProps={{ maxLength: 200 }}
            />
          </Tooltip>

          <KitGridContainer direction='row' align='right'>
            <KitGridItem xs={12}>
              <KitButton onClick={() => saveDistrict()} size='sm' round color='success' disabled={!pageState.district}>
                Update District Info
              </KitButton>
            </KitGridItem>
          </KitGridContainer>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Box sx={{ marginLeft: '15px', marginRight: '15px', marginBottom: '15px' }}>
            <ClientSideTable
              headerCols={districtHeaderColumns()}
              preTableContent={
                <KitGridContainer direction='row' align='right'>
                  <KitGridItem xs={12}>
                    <Tooltip
                      disableHoverListener={pageState.district && districtAdminInfo?.sso_provider}
                      disableFocusListener={pageState.district && districtAdminInfo?.sso_provider}
                      placement='top'
                      title='Select a district and set the SSO Provider before adding subscriptions.'
                    >
                      <span>
                        <KitButton
                          size='sm'
                          round
                          color='primary'
                          onClick={showAddDistrictSub}
                          disabled={!pageState.district || !pageState.district?.value?.sso_provider}
                        >
                          <AddCircleIcon /> Add Subscription
                        </KitButton>
                      </span>
                    </Tooltip>
                  </KitGridItem>
                </KitGridContainer>
              }
              records={districtSubRecords()}
            />
          </Box>
        </Grid>

        <Grid item xs={12} align='right'>
          <KitButton
            color='primary'
            round
            size='sm'
            onClick={() => router.push('/admin/manage-users')}
            title='Manage district and school administrators'
          >
            <ManageAccountsIcon /> Manage Admin Users
          </KitButton>
        </Grid>
      </Grid>
      <AddEditDistrictSubsriptionDialog
        action={addEditDistrictSubState.action}
        editDistrict={addEditDistrictSubState.editDistrict}
        editSubId={addEditDistrictSubState.editDistrictSub}
        open={addEditDistrictSubState.open}
        onClose={handleAddEditDistrictSubClose}
      />
      <PleaseWait isLoading={fetching} />
    </div>
  );
};

DistrictManager.propTypes = {
  user: PropTypes.object.isRequired,
  stateList: PropTypes.array,
};

export default DistrictManager;
