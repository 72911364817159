// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// local imports
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles } from '../StyledComponents';

const ObservationSessionNoteEdit = ({ onComplete, onDirty }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { updateTimelineObservation, removeTimelineObservation, editTimelineItem, setEditTimelineItem } = useObservationStore();

  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (editTimelineItem?.type === 'Session Note') {
      setNote(editTimelineItem.note);
    } else {
      resetState();
    }
  }, [editTimelineItem]);

  const resetState = () => {
    setNote('');
    setNoteError(null);
    setConfirmDeleteOpen(false);
  };

  const addToTimeline = (isClosed = false) => {
    const timelineNote = {
      timestamp: editTimelineItem.timestamp,
      type: 'Session Note',
      tags: [],
      note: note,
    };
    if (note?.length > 0) {
      timelineNote.tags.push(note.toUpperCase());
    }
    if (timelineNote.tags.length > 0) {
      updateTimelineObservation(timelineNote);
      setNote('');
      setNoteError(false);
    }
    onComplete();
  };

  const deleteTimelineObservation = () => {
    removeTimelineObservation(editTimelineItem);
    resetState();
    onComplete();
  };

  return (
    <>
      <Box style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Box style={{ width: '75%' }}>
          <FormControl fullWidth size='small'>
            <TextField
              placeholder='Type note'
              multiline={true}
              rows={2}
              InputLabelProps={{ shrink: true }}
              sx={{ ...styles.select, marginTop: '.75rem' }}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ maxLength: 500 }}
              label='Edit Session Note'
              id='session-note'
              error={noteError}
              size='small'
              value={note}
              onChange={(e) => {
                setNoteError(false);
                setNote(e.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ marginTop: '.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <KitButton
              onClick={(e) => addToTimeline(false)}
              size='sm'
              style={{
                backgroundColor: theme.palette.gold.main,
                marginTop: '1rem',
              }}
              disabled={note.length === 0}
            >
              <span>
                <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
              </span>
            </KitButton>
            <KitButton
              onClick={() => setConfirmDeleteOpen(true)}
              size='sm'
              style={{
                backgroundColor: theme.palette['obsOff Task'].main,
                marginTop: '1rem',
              }}
            >
              <span>
                <DeleteForeverIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />
                Delete
              </span>
            </KitButton>
          </Box>
        </Box>
      </Box>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        confirmMessage='Are you sure you want to delete this session note?'
        handleClose={() => setConfirmDeleteOpen(false)}
        handleYes={deleteTimelineObservation}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </>
  );
};

export default ObservationSessionNoteEdit;
