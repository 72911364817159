import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import Link from 'next/link';
import { useSession } from 'next-auth/react';

const ActionImageWithButton = ({ module }) => {
  const { fields } = module;

  const router = useRouter();
  const { data: session, status } = useSession();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    @media screen and (min-width: 993px) {
      flex-flow: row;
      ${fields.imageLocation === 'left' ? 'justify-content: right;' : 'justify-content: left;'}
      ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
      min-height: 475px;
    }
    @media screen and (max-width: 992px) {
      flex-flow: column;
      min-height: 550px;
    }
  `;

  const ImageContainer = styled.div`
    @media screen and (min-width: 993px) {
      ${fields.imageLocation === 'left' ? 'left: 5%;' : 'right: 5%;'}
      position: absolute;
      top: 35px;
      margin: 0;
      max-width: 45%;
      height: 100%;
    }
    @media screen and (max-width: 992px) {
      height: 90%;
    }
  `;

  const HeroImage = styled.img`
    height: 250;
    width: 250;
    border-radius: 50%;
    object-fit: cover;
  `;

  const TitleContainer = styled.div`
    @media screen and (min-width: 601px) {
      ${fields.imageLocation === 'left' ? 'left: 43%;' : 'right: 43%;'}
      width: 55%;
      margin: 0;
      position: absolute;
      top: 35%;
    }
    @media screen and (max-width: 992px) {
      text-align: center;
      width: 100%;
      padding: 15px 10px;
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (min-width: 993px) {
      font-size: 3.375rem;
      line-height: 3.125rem;
      ${fields.imageLocation === 'left' ? 'text-align: left;' : 'text-align: right;'}
    }
    @media screen and (max-width: 992px) {
      font-size: 2rem;
      line-height: 2.25rem;
      text-align: center;
    }
  `;

  return (
    <Box
      sx={{
        width: '100%',
        mt: '20px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      {/* {!session && ( */}
      <Container>
        <TitleContainer>
          <Title>{fields.title}</Title>
        </TitleContainer>
        <ImageContainer>{fields.heroImage && <HeroImage src={fields.heroImage.url} alt={fields.heroImage.text} />}</ImageContainer>
      </Container>
      {/* )} */}
    </Box>
  );
};

export default ActionImageWithButton;
