export const setLocalStoreString = (key, value) => {
  if (typeof window !== 'undefined') {
    window?.localStorage?.setItem(key, value);
  }
};

export const getLocalStoreString = (key) => {
  if (typeof window !== 'undefined') {
    return window?.localStorage?.getItem(key) || null;
  }
  return null;
};

export const getLocalStoreObject = (key) => {
  if (typeof window !== 'undefined') {
    const storeString = window?.localStorage?.getItem(key) || null;
    try {
      if (storeString) {
        return JSON.parse(storeString);
      }
      return null;
    } catch (error) {
      console.log(`error getLocalStoreObject: ${key}`, error);
      return null;
    }
  }
  return null;
};

export const removeLocalStoreItem = (key) => {
  if (typeof window !== 'undefined') {
    window?.localStorage?.removeItem(key);
  }
};

export const setSessionStoreString = (key, value) => {
  if (typeof window !== 'undefined') {
    window?.sessionStorage?.setItem(key, value);
  }
};

export const getSessionStoreString = (key) => {
  if (typeof window !== 'undefined') {
    return window?.sessionStorage?.getItem(key) || null;
  }
  return null;
};

export const removeSessionStoreItem = (key) => {
  if (typeof window !== 'undefined') {
    window?.sessionStorage?.removeItem(key);
  }
};

export const clearSessionStore = () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.clear();
  }
};
