import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { emailRegex } from 'services/stringUtils';
import { ROLES, ROLE_CONST } from 'services/roleUtils';
import { logClientException } from 'appinsights/clientAppInsights';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AddMultipleDialog = (props) => {
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [addMultipleState, setMultipleState] = useState({
    rawEmails: '',
    role: ROLE_CONST.Learner,
    state: null,
    district: null,
    school: null,
  });

  const fetching = useRef(0);
  const hasError = useRef(false);

  const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

  useEffect(() => {
    const init = async () => {
      let roles = [];
      ROLES.forEach((r) => {
        if (props.user.role <= r.id) {
          roles.push(
            <MenuItem value={r.id} key={r.id}>
              {r.name}
            </MenuItem>
          );
        }
      });
      setRoleList(roles);
      if (props.couponCodes) {
        setCouponCodeList(
          props.couponCodes.map((c) => ({
            label: c.code,
            value: { code: c.code, exp_date: c.exp_date, type: c.type, value: c.value, product: c.product },
          }))
        );
      }

      let district = null;
      let school = null;
      let state = null;
      if (props.user.role !== ROLE_CONST.BoysTownAdmin) {
        district = { label: props.user.district_name, value: props.user.district_id };
        school = { label: props.user.school_name, value: props.user.school_id };
        await fetchDistrictsForState(props.user.state);
        await fetchSchoolsForDistrict(props.user.district_id);
        state = props.stateList.find((s) => s.value === props.user.state);
      }
      setMultipleState({ ...addMultipleState, state, district, school, role: ROLE_CONST.Learner });
    };

    if (props.open) {
      init();
    }
  }, [props.open]);

  const resetState = () => {
    setMultipleState({
      state: props.stateList.find((s) => s.value === props.user.state),
      rawEmails: '',
      role: ROLE_CONST.Learner,
      district: null,
      school: null,
    });
  };

  const fetchDistrictsForState = async (state) => {
    try {
      fetching.current += 1;
      setSchoolList([]);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      setDistrictList(res.results.map((r) => ({ label: r.name, value: r.id })));
      fetching.current -= 1;
    } catch (error) {
      fetching.current -= 1;
      hasError.current = true;
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district) => {
    try {
      fetching.current += 1;
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district}`);
      setSchoolList(res.results.map((r) => ({ label: r.name, value: r.id })));
      fetching.current -= 1;
    } catch (error) {
      fetching.current -= 1;
      hasError.current = true;
      logClientException(error);
    }
  };

  const parseRawEmails = (raw) => {
    let seperator = ',';
    if (addMultipleState.rawEmails.indexOf('\n') > -1) {
      seperator = '\n';
    }
    return raw.split(seperator).map((e) => e.trim());
  };

  const canAddMultiple = () => {
    let hasError = false;

    if (!addMultipleState.canSave) {
      return false;
    }

    let tempState = addMultipleState;

    if (!addMultipleState.rawEmails || addMultipleState.rawEmails.length == 0) {
      hasError = true;
      tempState.rawEmailsError = true;
    }

    const items = parseRawEmails(addMultipleState.rawEmails);
    for (let i = 0; i < items.length; i++) {
      if (!emailRegex.test(items[i])) {
        hasError = true;
        tempState.rawEmailsError = true;
      }
    }
    if (!addMultipleState.district) {
      hasError = true;
      tempState.districtError = true;
    }
    if (!addMultipleState.school) {
      hasError = true;
      tempState.stateError = true;
    }
    if (!addMultipleState.role || String(addMultipleState.role).length === 0) {
      hasError = true;
      tempState.roleError = true;
    }

    tempState.canSave = !hasError;

    setMultipleState(tempState);

    return !hasError;
  };

  const addMultipleOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'rawEmails') {
      const items = parseRawEmails(value);
      for (let i = 0; i < items.length; i++) {
        if (!emailRegex.test(items[i])) {
          canSave = false;
          fieldError = true;
          i = items.length + 1;
        }
      }
    }
    if (field === 'state') {
      if (!value || value?.value === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    if (field === 'state' && !fieldError) {
      await fetchDistrictsForState(value);
      setMultipleState({ ...addMultipleState, [field]: value, [`${field}Error`]: fieldError, canSave, district: null, school: null });
      return;
    }

    if (field === 'district' && value) {
      await fetchSchoolsForDistrict(value.value);
      setMultipleState({ ...addMultipleState, [field]: value, [`${field}Error`]: fieldError, canSave, school: null });
      return;
    }

    setMultipleState({ ...addMultipleState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const closeMultipleDialog = async (doSave) => {
    setDistrictList([]);
    setSchoolList([]);
    hasError.current = false;
    setMultipleState({ ...addMultipleState, hasError: false, errorMessage: defaultErrorMessage });

    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canAddMultiple()) {
      return;
    }

    let reqBody = { userProps: {} };
    if (addMultipleState.district) {
      reqBody.userProps.district_id = addMultipleState.district.value;
      reqBody.userProps.district_name = addMultipleState.district.label;
    }
    if (addMultipleState.school) {
      reqBody.userProps.school_id = addMultipleState.school.value;
      reqBody.userProps.school_name = addMultipleState.school.label;
    }
    if (addMultipleState.state) {
      reqBody.userProps.state = addMultipleState.state.value;
    }
    reqBody.userProps.role = addMultipleState.role;
    reqBody.emailList = [...new Set(parseRawEmails(addMultipleState.rawEmails))]; // use Set to get distinct list

    if (addMultipleState.couponCode) {
      reqBody.coupon_code = addMultipleState.couponCode.value;
    }

    try {
      fetching.current += 1;
      await callApi('/api/db/user-list', 'POST', reqBody);
      fetching.current -= 1;
      props.onClose(doSave);
      resetState();
    } catch (error) {
      console.log(error);
      fetching.current -= 1;
      hasError.current = true;
      if (error instanceof BadRequestError) {
        setMultipleState({ ...addMultipleState, hasError: true, errorMessage: error.message });
        return;
      }
      logClientException(error);
      setMultipleState({ ...addMultipleState, hasError: true, errorMessage: defaultErrorMessage });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    hasError.current = false;
    setMultipleState({ ...addMultipleState, hasError: false });
  };

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeMultipleDialog(false)}>
      <DialogTitle>Add User List</DialogTitle>
      <DialogContent>
        <Snackbar
          open={hasError.current || addMultipleState.hasError}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {addMultipleState.errorMessage}
          </Alert>
        </Snackbar>
        <Alert severity='info' variant='outlined' sx={{ boxShadow: 'none', marginBottom: '10px' }}>
          Enter a list of emails. It can be comma separated list or one per line.
        </Alert>
        <TextField
          value={addMultipleState.rawEmails}
          error={addMultipleState.rawEmailsError}
          autoFocus
          required
          multiline
          rows={5}
          margin='dense'
          id='email-address'
          label='Email Address List'
          type='text'
          title='Enter a comma separated list or one email per line'
          fullWidth
          variant='outlined'
          onChange={(e) => addMultipleOnChange('rawEmails', e.target.value)}
          inputProps={{ maxLength: 2000 }}
        />

        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={props.stateList}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          disabled={props.user.role >= ROLE_CONST.DistrictAdmin}
          value={addMultipleState.state}
          onChange={(e, v) => addMultipleOnChange('state', v)}
          renderInput={(params) => <TextField required error={addMultipleState.stateError} {...params} label='State' />}
        />

        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={districtList}
          fullWidth
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          disabled={props.user.role >= ROLE_CONST.DistrictAdmin || !addMultipleState.state}
          value={addMultipleState.district}
          onChange={(e, v) => addMultipleOnChange('district', v)}
          renderInput={(params) => (
            <TextField disabled={!addMultipleState.state} required error={addMultipleState.districtError} {...params} label='School district' />
          )}
        />
        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={schoolList}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          disabled={props.user.role >= ROLE_CONST.SchoolAdmin || !addMultipleState.district}
          value={addMultipleState.school}
          onChange={(e, v) => addMultipleOnChange('school', v)}
          renderInput={(params) => (
            <TextField required={addMultipleState.role >= ROLE_CONST.SchoolAdmin} error={addMultipleState.schoolError} {...params} label='School' />
          )}
        />
        <FormControl fullWidth sx={{ marginTop: '7px' }}>
          <InputLabel required error={addMultipleOnChange.roleError} id='role-select'>
            Role
          </InputLabel>
          <Select
            labelId='role-select'
            id='role-select-value'
            value={addMultipleState.role}
            label='Role'
            onChange={(e) => {
              addMultipleOnChange('role', e.target.value);
            }}
          >
            {roleList}
          </Select>
        </FormControl>

        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={couponCodeList}
          isOptionEqualToValue={(o, v) => o.value.code === v?.value?.code}
          fullWidth
          value={addMultipleState.couponCode}
          onChange={(e, v) => addMultipleOnChange('couponCode', v)}
          renderInput={(params) => <TextField {...params} label='Coupon Code' />}
        />

        <PleaseWait isLoading={fetching.current > 0} />
      </DialogContent>
      <DialogActions>
        <KitButton onClick={() => closeMultipleDialog(false)} round size='sm'>
          Cancel
        </KitButton>
        <KitButton onClick={() => closeMultipleDialog(true)} round size='sm' color='success' disabled={!addMultipleState.canSave}>
          Add
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddMultipleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  stateList: PropTypes.array,
  onClose: PropTypes.func,
  couponCodes: PropTypes.array,
};
export default AddMultipleDialog;
