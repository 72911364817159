// package imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';

// local imports
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { emailRegex } from 'services/stringUtils';
import { ROLES, ROLE_CONST } from 'services/roleUtils';
import { logClientException } from 'appinsights/clientAppInsights';
import { DEPARTMENT_LIST, OBSERVATION_PERMISSION_LIST } from 'services/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AddEditUserDialog = (props) => {
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const [userDialogState, setUserDialogState] = useState({
    emailAddress: '',
    firstName: '',
    lastName: '',
    state: null,
    district: null,
    school: null,
    role: ROLE_CONST.Learner,
    couponCode: null,
    observation_permission: 'None',
    department: '',
  });

  const fetching = useRef(0);

  useEffect(() => {
    const init = async () => {
      let roles = [];
      ROLES.forEach((r) => {
        if (props.user.role <= r.id) {
          roles.push(
            <MenuItem value={r.id} key={r.id}>
              {r.name}
            </MenuItem>
          );
        }
      });
      setRoleList(roles);
      if (props.couponCodes) {
        setCouponCodeList(
          props.couponCodes.map((c) => ({
            label: c.code,
            value: { code: c.code, exp_date: c.exp_date, type: c.type, value: c.value, product: c.product },
          }))
        );
      }

      if (props.editUser) {
        let editUser = props.editUser;
        editUser.state = props.stateList.find((s) => s.value === editUser.state);
        if (editUser.state) {
          await fetchDistrictsForState(editUser.state);
        }
        if (editUser.district_id) {
          await fetchSchoolsForDistrict(editUser.district_id);
        }
        setUserDialogState({ ...editUser });
      } else {
        let district = null;
        let school = null;
        let state = null;
        if (props.user.role !== ROLE_CONST.BoysTownAdmin) {
          district = { label: props.user.district_name, value: props.user.district_id };
          school = { label: props.user.school_name, value: props.user.school_id };
          state = props.stateList.find((s) => s.value === props.user.state);
          await fetchDistrictsForState(state);
          await fetchSchoolsForDistrict(props.user.district_id);
        }
        setErrorMessage(null);
        setUserDialogState({
          emailAddress: '',
          firstName: '',
          lastName: '',
          state: state,
          district: district,
          school: school,
          role: ROLE_CONST.Learner,
          couponCode: null,
          observation_permission: 'None',
          department: '',
        });
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open]);

  const resetState = () => {
    setErrorMessage(null);
    setUserDialogState({
      district: null,
      school: null,
      state: null,
      firstName: '',
      lastName: '',
      emailAddress: '',
      role: ROLE_CONST.Learner,
      couponCode: null,
      observation_permission: 'None',
      department: '',
    });
  };

  const fetchDistrictsForState = async (state) => {
    try {
      fetching.current += 1;
      setSchoolList([]);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      setDistrictList(res.results.map((r) => ({ label: r.name, value: r.id })));
      setSchoolList([]);
      fetching.current -= 1;
      setUserDialogState({ ...userDialogState, state: state, district: null, school: null });
    } catch (error) {
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district) => {
    try {
      fetching.current += 1;
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district}`);
      setSchoolList(res.results.map((r) => ({ label: r.name, value: r.id })));
      fetching.current -= 1;
    } catch (error) {
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const closeUserDialog = async (doSave) => {
    setDistrictList([]);
    setSchoolList([]);

    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSaveUser()) {
      return;
    }

    let user = JSON.parse(JSON.stringify(userDialogState));
    delete user.hasError;
    delete user.canSave;
    if (user.district) {
      user.district_id = user.district.value;
      user.district_name = user.district.label;
      delete user.district;
    }
    if (user.school) {
      user.school_id = user.school.value;
      user.school_name = user.school.label;
      delete user.school;
    }
    if (user.state) {
      user.state = user.state.value;
    }

    if (user.couponCode) {
      const couponCode = JSON.parse(JSON.stringify(user.couponCode));
      user.coupon_code = couponCode.value;
      console.log(user.coupon_code);
    }
    delete user.couponCode;

    Object.keys(user).forEach((key) => {
      if (key.endsWith('Error') && user.hasOwnProperty(key)) {
        delete user[key];
      }
    });

    try {
      fetching.current += 1;
      await callApi('/api/db/user', props.action === 'Add' ? 'POST' : 'PUT', { user: user });
      resetState();
      props.onClose(doSave);
      fetching.current -= 1;
    } catch (error) {
      logClientException(error);
      fetching.current -= 1;
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSaveUser = () => {
    let hasError = false;
    const user = userDialogState;
    if (!user.emailAddress || user.emailAddress.length === 0 || !emailRegex.test(user.emailAddress)) {
      hasError = true;
      user.emailAddressError = true;
    }
    if (!user.firstName || user.firstName.length === 0) {
      hasError = true;
      user.firstNameError = true;
    }
    if (!user.lastName || user.lastName.length === 0) {
      hasError = true;
      user.lastNameError = true;
    }
    if (!user.state || !user.state.value) {
      hasError = true;
      user.stateError = true;
    }
    if (!user.district || !user.district.value || user.district.value.length === 0) {
      hasError = true;
      user.districtError = true;
    }
    if (user.role && user.role >= ROLE_CONST.SchoolAdmin && (!user.school || !user.school.value || user.school.value.length === 0)) {
      hasError = true;
      user.schoolError = true;
    }
    if (!user.role || String(user.role).length === 0) {
      hasError = true;
      user.roleError = true;
    }
    if (user.observation_permission && user.observation_permission !== 'None' && (!user.department || String(user.department).length === 0)) {
      hasError = true;
      user.departmentError = true;
    }
    if (hasError) {
      setUserDialogState({ ...user, canSave: false });
    }
    return !hasError;
  };

  const userOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'emailAddress') {
      if (!emailRegex.test(value)) {
        canSave = false;
        fieldError = true;
      }
    }
    if (field === 'firstName' || field === 'lastName' || field === 'state') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    setUserDialogState({ ...userDialogState, [field]: value, [`${field}Error`]: fieldError, canSave });

    if (field === 'state' && !fieldError) {
      await fetchDistrictsForState(value);
      setUserDialogState({ ...userDialogState, [field]: value, [`${field}Error`]: fieldError, district: null, school: null, canSave });
      return;
    }
    if (field === 'district' && value) {
      await fetchSchoolsForDistrict(value.value);
      setUserDialogState({ ...userDialogState, [field]: value, [`${field}Error`]: fieldError, school: null, canSave });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeUserDialog(false)}>
      <DialogTitle>{props.action} User</DialogTitle>

      <DialogContent>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <TextField
          size='small'
          InputLabelProps={{ shrink: true }}
          disabled={props.action === 'Update'}
          value={userDialogState.emailAddress}
          error={userDialogState.emailAddressError}
          autoFocus
          required
          margin='dense'
          id='email-address'
          label='Email Address'
          type='email'
          fullWidth
          variant='outlined'
          onChange={(e) => userOnChange('emailAddress', e.target.value)}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          size='small'
          margin='dense'
          InputLabelProps={{ shrink: true }}
          value={userDialogState.firstName}
          error={userDialogState.firstNameError}
          required
          id='first-name'
          label='First Name'
          type='text'
          fullWidth
          variant='outlined'
          onChange={(e) => userOnChange('firstName', e.target.value)}
          inputProps={{ maxLength: 100 }}
        />
        <TextField
          size='small'
          InputLabelProps={{ shrink: true }}
          value={userDialogState.lastName}
          error={userDialogState.lastNameError}
          required
          margin='dense'
          id='last-name'
          label='Last Name'
          type='text'
          fullWidth
          variant='outlined'
          onChange={(e) => userOnChange('lastName', e.target.value)}
          inputProps={{ maxLength: 100 }}
        />

        <Autocomplete
          size='small'
          margin='dense'
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: '7px' }}
          disablePortal
          options={props.stateList}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          disabled={props.user.role >= ROLE_CONST.DistrictAdmin}
          value={userDialogState.state}
          onChange={(e, v) => userOnChange('state', v)}
          renderInput={(params) => <TextField required error={userDialogState.stateError} {...params} label='State' />}
        />

        <Autocomplete
          size='small'
          margin='dense'
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: '7px' }}
          disablePortal
          options={districtList}
          fullWidth
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          disabled={props.user.role >= ROLE_CONST.DistrictAdmin || !userDialogState.state}
          value={userDialogState.district}
          onChange={(e, v) => userOnChange('district', v)}
          renderInput={(params) => (
            <TextField disabled={!userDialogState.state} required error={userDialogState.districtError} {...params} label='School district' />
          )}
        />
        <Autocomplete
          size='small'
          margin='dense'
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: '7px' }}
          disablePortal
          options={schoolList}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          disabled={props.user.role >= ROLE_CONST.SchoolAdmin || !userDialogState.district}
          value={userDialogState.school}
          onChange={(e, v) => userOnChange('school', v)}
          renderInput={(params) => (
            <TextField required={userDialogState.role >= ROLE_CONST.SchoolAdmin} error={userDialogState.schoolError} {...params} label='School' />
          )}
        />
        <FormControl fullWidth sx={{ marginTop: '7px' }} size='small'>
          <InputLabel required error={userDialogState.roleError} id='role-select'>
            Role
          </InputLabel>
          <Select
            margin='dense'
            InputLabelProps={{ shrink: true }}
            labelId='role-select'
            id='role-select-value'
            value={userDialogState.role}
            label='Role'
            onChange={(e) => {
              userOnChange('role', e.target.value);
            }}
          >
            {roleList}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginTop: '7px' }} size='small'>
          <InputLabel error={userDialogState.observation_permissionError} id='observation-permission-select'>
            Classroom Observation Permission
          </InputLabel>
          <Select
            margin='dense'
            InputLabelProps={{ shrink: true }}
            labelId='observation-permission-select'
            id='observation-permission-value'
            value={userDialogState.observation_permission}
            label='Classroom Observation Permission'
            onChange={(e) => {
              userOnChange('observation_permission', e.target.value);
            }}
          >
            {OBSERVATION_PERMISSION_LIST.map((o) => {
              return (
                <MenuItem value={o} key={o}>
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginTop: '7px' }} size='small'>
          <InputLabel
            required={userDialogState.observation_permission && userDialogState.observation_permission !== 'None'}
            error={userDialogState.departmentError}
            id='department-select'
          >
            Department
          </InputLabel>
          <Select
            error={userDialogState.departmentError}
            margin='dense'
            InputLabelProps={{ shrink: true }}
            labelId='department-select'
            id='department-value'
            value={userDialogState.department}
            label='Department'
            onChange={(e) => {
              userOnChange('department', e.target.value);
            }}
          >
            {DEPARTMENT_LIST.map((o) => {
              return (
                <MenuItem value={o} key={o}>
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Autocomplete
          size='small'
          margin='dense'
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: '7px' }}
          disablePortal
          options={couponCodeList}
          isOptionEqualToValue={(o, v) => o.value.code === v?.value?.code}
          fullWidth
          value={userDialogState.couponCode}
          onChange={(e, v) => userOnChange('couponCode', v)}
          renderInput={(params) => <TextField {...params} label='Coupon Code' />}
        />
        <PleaseWait isLoading={fetching.current > 0} />
      </DialogContent>
      <DialogActions>
        <KitButton onClick={() => closeUserDialog(false)} round size='sm'>
          Cancel
        </KitButton>
        <KitButton onClick={() => closeUserDialog(true)} round size='sm' color='success' disabled={!userDialogState.canSave}>
          {props.action}
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddEditUserDialog.propTypes = {
  action: PropTypes.oneOf(['Add', 'Update']).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  editUser: PropTypes.object,
  stateList: PropTypes.array,
  onClose: PropTypes.func,
  couponCodes: PropTypes.array,
};
export default AddEditUserDialog;
