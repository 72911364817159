import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import Typography from '@mui/material/Typography';
import { useSession } from 'next-auth/react';
import { COMMON_ROUTES } from 'services/constants';
import { useTheme } from '@mui/material/styles';
import Heading1 from 'components/common/subcomponents/Typography/Heading1';
import { isCustomer } from 'services/subscriptionUtils';

const HeroImageHomepage = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const router = useRouter();
  const { data: session, status } = useSession();

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-size: 115%;
    background-repeat: no-repeat;
    background-position: right 40% bottom 50%;
    position: relative;
    padding: 10px 20px;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      ${fields.fullScreen === 'true' ? 'height: 90vh;' : 'height: 689px;'}
    }
  `;

  const MobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-size: 300%;
    background-repeat: no-repeat;
    background-position: right 40% bottom 75%;
    position: relative;
    padding: 10px 20px;
    @media screen and (max-width: 899px) {
      min-height: 620px;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    minheight: inherit;
    max-width: 85%;
    @media screen and (max-width: 899px) {
      margin: 0 5%;
    }
    @media screen and (min-width: 900px) {
      margin: 0 15%;
    }
  `;

  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.6)), url(${fields.backgroundImage.url})`,
          backgroundColor: 'black',
        }}
      >
        <TextContainer>
          <Description variant='h6' dangerouslySetInnerHTML={renderHTML(fields.description)}></Description>
          <ButtonContainer>
            {fields.displaySignupButton === 'yes' ||
              (fields.displaySignupButton === 'notregistered' && isCustomer(session?.user) === false && (
                <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
                  <KitButton
                    round
                    size='lg'
                    style={{
                      backgroundColor: theme.palette.brandedAqua.main,
                      margin: '0 60px',
                    }}
                  >
                    {fields.signUpButtonText}
                  </KitButton>
                </Link>
              ))}
            {(fields.displayRedirectButton === 'yes' || (fields.displayRedirectButton === 'registered' && isCustomer(session?.user))) && (
              <Link href={fields.redirectLink.href} passHref legacyBehavior>
                <KitButton
                  round
                  size='lg'
                  style={{
                    backgroundColor: theme.palette.brandedAqua.main,
                    margin: '0 60px',
                  }}
                >
                  {fields.redirectButtonText}
                </KitButton>
              </Link>
            )}
          </ButtonContainer>
        </TextContainer>
      </Container>
      <MobileContainer
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.6)), url(${fields.backgroundImage.url})`,
          backgroundColor: 'black',
        }}
      >
        <TextContainer>
          <MobileDescription>
            <Heading1 color={fields.titleColor} homepage='true'>
              {fields.mobileDescription}
            </Heading1>
          </MobileDescription>
          {(fields.displaySignupButton === 'yes' || (fields.displaySignupButton === 'notregistered' && isCustomer(session?.user) === false)) && (
            <ButtonContainer>
              <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
                <KitButton
                  round
                  style={{
                    backgroundColor: theme.palette.brandedAqua.main,
                  }}
                >
                  {fields.signUpButtonText}
                </KitButton>
              </Link>
            </ButtonContainer>
          )}
          {(fields.displayRedirectButton === 'yes' || (fields.displayRedirectButton === 'registered' && isCustomer(session?.user))) && (
            <ButtonContainer>
              <Link href={fields.redirectLink.href} passHref legacyBehavior>
                <KitButton
                  round
                  style={{
                    backgroundColor: theme.palette.brandedAqua.main,
                  }}
                >
                  {fields.redirectButtonText}
                </KitButton>
              </Link>
            </ButtonContainer>
          )}
        </TextContainer>
      </MobileContainer>
    </React.Fragment>
  );
};

export default HeroImageHomepage;

const Description = styled('Typography')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  margin: '5px 60px',
  width: '642px',
  height: '295px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileDescription = styled('Typography')(({ theme }) => ({
  fontSize: '1.95rem',
  lineHeight: '2.30rem',
  margin: '5% 0 5% 0',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '30px 0 80px 0',
    alignSelf: 'flex-start',
    bottom: '-180px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    bottom: '-30px',
  },
}));
