import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSession } from 'next-auth/react';
import { KitGridContainer, KitGridItem, KitButton, KitTable } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { logClientException } from 'appinsights/clientAppInsights';
import { isAdmin, ROLE_CONST } from 'services/roleUtils';
import { callApi } from 'services/apiWrapper';
import NotAuthorized from 'components/common/NotAuthorized';
import { formatDate } from 'services/stringUtils';
import CustomFilter from '../../../common/subcomponents/CustomFilter';
import SortableTableHeader from '../../../common/subcomponents/SortableTableHeader';
import FeatureSwitchCard from './FeatureSwitchCard';
import AddFeatureSwitchDialog from './AddFeatureSwitchDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AdminFeatureSwitchList = (props) => {
  const { data: session, status } = useSession();
  const [currentFeatureSwitch, setCurrentFeatureSwitch] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [addEditState, setAddEditState] = useState({
    open: false,
    action: 'Add',
    editFeatureSwitch: null,
  });
  const fetching = useRef(0);

  const [pageState, setPageState] = useState({
    allRecords: [],
    tableRecords: [],
    filter: '',
    fetchedRecords: false,
    showAllRecords: false,
    sortDirection: 'asc',
    sortBy: '',
  });
  const [paginationOptions, setPaginationOptions] = useState({
    searching: false,
    page: 0,
    perPage: 10,
    totalRecords: 0,
  });

  const sortOptions = useRef({ field: 'switch_name', direction: 'ASC' });

  const filterOptions = useRef(null);

  useEffect(() => {
    if (isAdmin(session)) {
      init();
    }
  }, [session]);

  const init = async () => {
    await fetchFeatureSwitches();
  };

  if (!isAdmin(session)) {
    return <NotAuthorized />;
  }

  const fetchFeatureSwitches = async (perPage = 10, page = 0, append = false) => {
    try {
      fetching.current += 1;
      setPaginationOptions({ ...paginationOptions, searching: true });
      let path = `/api/db/feature-switch-list?page_size=${perPage}&page_number=${page}`;
      let qs = [];

      if (filterOptions.current) {
        for (const key in filterOptions.current) {
          if (filterOptions.current.hasOwnProperty(key)) {
            qs.push(`filter.${key}=${filterOptions.current[key]}`);
          }
        }
      }
      qs.push(`order_by=${sortOptions.current.field}:${sortOptions.current.direction}`);

      if (qs.length > 0) {
        path += `&${qs.join('&')}`;
      }
      let res = await callApi(path);

      const tableRecords = res.results.map((r) => [
        r.switch_name,
        r.switch_type,
        <Checkbox key={r.switch_name} checked={r.enabled} disabled />,
        <div key={r.switch_name} style={{ maxWidth: '300px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {r.value}
        </div>,
        <KitButton key={r.switch_name} color='info' round justIcon size='sm' onClick={() => showEdit(r)} title='Edit code'>
          <EditIcon />
        </KitButton>,
      ]);
      let allRecords = [...pageState.allRecords];
      if (append) {
        res.results.forEach((r) => {
          allRecords.push(r);
        });
      } else {
        allRecords = res.results;
      }
      fetching.current -= 1;
      setPageState({ ...pageState, allRecords: allRecords, tableRecords: tableRecords });
      setPaginationOptions({ ...paginationOptions, totalRecords: res.totalRecords, perPage, page, searching: false });
    } catch (error) {
      console.log(error);
      logClientException(error);
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
    }
  };

  const showEdit = (code) => {
    setAddEditState({ open: true, action: 'Update', editFeatureSwitch: code });
  };

  const showAdd = () => {
    setAddEditState({ open: true, action: 'Add', editFeatureSwitch: null });
  };

  const handleAddEditClose = async (doSave) => {
    setAddEditState({ ...addEditState, open: false });
    if (doSave) {
      await fetchFeatureSwitches();
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const filterOnChange = async (data) => {
    filterOptions.current = data;
    await fetchFeatureSwitches(paginationOptions.perPage, paginationOptions.page);
  };

  const onSortChange = async (field, direction) => {
    if (sortOptions.current.field === field) {
      sortOptions.current.direction = sortOptions.current.direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortOptions.current.field = field;
      sortOptions.current.direction = direction ? direction : 'ASC';
    }
    await fetchFeatureSwitches(paginationOptions.perPage, paginationOptions.page);
  };

  const getSortDirection = (field) => {
    return sortOptions.current.field === field ? sortOptions.current.direction : null;
  };

  const headerCols = [
    <SortableTableHeader
      key='switch_name'
      field='switch_name'
      text='Switch Name'
      sortDirection={getSortDirection('switch_name')}
      onSortChange={onSortChange}
    />,
    <SortableTableHeader
      key='switch_tpe'
      field='switch_tpe'
      text='Type'
      sortDirection={getSortDirection('switch_tpe')}
      onSortChange={onSortChange}
    />,
    <SortableTableHeader key='enabled' field='enabled' text='Enabled' sortDirection={getSortDirection('enabled')} onSortChange={onSortChange} />,
    <SortableTableHeader key='value' field='value' text='Value' sortDirection={getSortDirection('value')} onSortChange={onSortChange} />,
    '',
  ];

  const customFilterFields = () => {
    let fields = [];
    const user = session.user;
    if (user?.role === ROLE_CONST.BoysTownAdmin) {
      fields.push({ name: 'switch_name', label: 'Switch Name' });
    }
    fields.push({ name: 'switch_type', label: 'Type' });
    return fields;
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={fetching.current > 0} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <CustomFilter fields={customFilterFields()} onChange={filterOnChange} />
        {pageState.allRecords.map((r, i) => (
          <FeatureSwitchCard key={r.id} featureSwitch={r} editFunc={() => showEdit(r)} />
        ))}
        {paginationOptions.totalRecords > (paginationOptions.page + 1) * paginationOptions.perPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <KitButton
              size='sm'
              round
              color='secondary'
              onClick={() => fetchFeatureSwitches(paginationOptions.perPage, paginationOptions.page + 1, true)}
            >
              Load More
            </KitButton>
          </Box>
        )}
      </Box>

      <Box sx={{ display: { sm: 'block', xs: 'none' }, marginLeft: '15px', marginRight: '15px', marginBottom: '15px' }}>
        <CustomFilter fields={customFilterFields()} onChange={filterOnChange} />
        <KitGridContainer direction='row' align='right'>
          <KitGridItem xs={12}>
            <KitButton size='sm' round color='primary' onClick={showAdd}>
              <AddCircleIcon /> Add Feature Switch
            </KitButton>
          </KitGridItem>
        </KitGridContainer>
        <KitGridContainer direction='row' align='center'>
          <KitGridItem xs={12}>
            <TableContainer>
              <KitTable striped hover tableData={pageState.tableRecords} tableHead={headerCols}></KitTable>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={paginationOptions.totalRecords}
                rowsPerPage={paginationOptions.perPage}
                page={paginationOptions.page}
                onPageChange={(ev, val) => {
                  fetchFeatureSwitches(paginationOptions.perPage, val);
                }}
                onRowsPerPageChange={(ev) => {
                  const perPage = parseInt(ev.target.value, 10);
                  fetchFeatureSwitches(perPage, 0);
                }}
                showFirstButton
                showLastButton
              />
            </TableContainer>
          </KitGridItem>
        </KitGridContainer>
      </Box>

      <AddFeatureSwitchDialog
        open={addEditState.open}
        onClose={handleAddEditClose}
        action={addEditState.action}
        user={session.user}
        editFeatureSwitch={addEditState.editFeatureSwitch}
      />
    </React.Fragment>
  );
};

export default AdminFeatureSwitchList;
