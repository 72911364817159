import React from 'react';
import Typography from '@mui/material/Typography';

const Heading5 = (props) => {
  const { color, children, ...rest } = props;
  return (
    <Typography {...rest} component='h5' color={color} fontSize='29px' fontWeight='600' lineHeight='36.45px' textTransform='capitalize'>
      {children}
    </Typography>
  );
};

export default Heading5;
