import React from 'react';
import Typography from '@mui/material/Typography';

const BodyCopyMobile = (props) => {
  const { color, children, ...rest } = props;
  return (
    <Typography {...rest} color={color} fontSize='16px' fontWeight='400' lineHeight='24px'>
      {children}
    </Typography>
  );
};

export default BodyCopyMobile;
