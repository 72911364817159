import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import Checkbox from '@mui/material/Checkbox';
import { useSession } from 'next-auth/react';
import { KitGridContainer, KitGridItem, KitButton, KitTable } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { logClientException } from 'appinsights/clientAppInsights';
import { isAdmin } from 'services/roleUtils';
import { callApi } from 'services/apiWrapper';
import NotAuthorized from 'components/common/NotAuthorized';
import AddEditEmailDomainDialog from './AddEditEmailDomainDialog';
import EmailDomainCard from './EmailDomainCard';
import { states } from '../../../../services/stringUtils';
import CustomFilter from '../../../common/subcomponents/CustomFilter';
import SortableTableHeader from '../../../common/subcomponents/SortableTableHeader';
import EmailDomainQRCode from './EmailDomainQRCode';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AdminEmailDomainAllowList = (props) => {
  const { data: session, status } = useSession();
  const [errorMessage, setErrorMessage] = useState(null);
  const [addEditState, setAddEditState] = useState({
    open: false,
    action: 'Add',
    editDomain: null,
  });
  const fetching = useRef(0);
  const filterOptions = useRef(null);
  const sortOptions = useRef({ field: 'district_name', direction: 'ASC' });

  const [pageState, setPageState] = useState({
    allRecords: [],
    tableRecords: [],
    filter: '',
    fetchedRecords: false,
    showAllRecords: false,
    sortDirection: 'asc',
    sortBy: '',
  });
  const [paginationOptions, setPaginationOptions] = useState({
    searching: false,
    page: 0,
    perPage: 10,
    totalRecords: 0,
  });

  useEffect(() => {
    if (isAdmin(session)) {
      init();
    }
  }, [session]);

  const init = async () => {
    await fetchDomainAllowList();
  };

  if (!isAdmin(session)) {
    return <NotAuthorized />;
  }

  const fetchDomainAllowList = async (perPage = 10, page = 0, append = false) => {
    try {
      fetching.current += 1;
      setPaginationOptions({ ...paginationOptions, searching: true });
      let path = `/api/db/domain-allow-list?page_size=${perPage}&page_number=${page}`;
      let qs = [];
      if (filterOptions.current) {
        for (const key in filterOptions.current) {
          if (filterOptions.current.hasOwnProperty(key)) {
            qs.push(`filter.${key}=${filterOptions.current[key]}`);
          }
        }
      }
      qs.push(`order_by=${sortOptions.current.field}:${sortOptions.current.direction}`);
      if (qs.length > 0) {
        path += `&${qs.join('&')}`;
      }
      let res = await callApi(path);
      const tableRecords = res.results.map((r) => {
        return [
          r.district_name,
          r.email_domain,
          <EmailDomainQRCode emailDomain={r} key={r.email_domain} />,
          <Checkbox disabled checked={r.is_active} key={r.email_domain} />,
          <>
            <KitButton color='info' round justIcon size='sm' onClick={() => showEdit(r)} title='Edit Domain'>
              <EditIcon />
            </KitButton>
          </>,
        ];
      });
      fetching.current -= 1;
      let allRecords = [...pageState.allRecords];
      if (append) {
        res.results.forEach((r) => {
          allRecords.push(r);
        });
      } else {
        allRecords = res.results;
      }
      setPageState({ ...pageState, allRecords, tableRecords: tableRecords });
      setPaginationOptions({ ...paginationOptions, totalRecords: res.totalRecords, perPage, page, searching: false });
    } catch (error) {
      console.log(error);
      logClientException(error);
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
    }
  };

  const showEdit = (domain) => {
    domain.district = domain.district_id && domain.district_name ? { label: domain.district_name, value: domain.district_id } : null;
    //domain.school = domain.school_id && domain.school_name ? {label: domain.school_name, value: domain.school_id} : null;
    setAddEditState({ open: true, action: 'Update', editDomain: domain });
  };

  const showAdd = () => {
    setAddEditState({ open: true, action: 'Add', editDomain: null });
  };

  const handleAddEditClose = async (doSave) => {
    setAddEditState({ ...addEditState, open: false });
    if (doSave) {
      // TODO refetch users or add/update list? - could be issues with sorting/filtering if we add to list.
      await fetchDomainAllowList();
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const filterOnChange = async (data) => {
    filterOptions.current = data;
    await fetchDomainAllowList(paginationOptions.perPage, paginationOptions.page);
  };

  const onSortChange = async (field, direction) => {
    if (sortOptions.current.field === field) {
      sortOptions.current.direction = sortOptions.current.direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortOptions.current.field = field;
      sortOptions.current.direction = direction ? direction : 'ASC';
    }
    await fetchDomainAllowList(paginationOptions.perPage, paginationOptions.page);
  };

  const getSortDirection = (field) => {
    return sortOptions.current.field === field ? sortOptions.current.direction : null;
  };

  const headerCols = [
    <SortableTableHeader
      key='district_name'
      field='district_name'
      text='District'
      sortDirection={getSortDirection('district_name')}
      onSortChange={onSortChange}
    />,
    <SortableTableHeader
      key='email_domain'
      field='email_domain'
      text='Email Domain'
      sortDirection={getSortDirection('email_domain')}
      onSortChange={onSortChange}
    />,
    'QR Code',
    'Active',
    '',
  ];

  return (
    <React.Fragment>
      <PleaseWait isLoading={fetching.current > 0} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <KitGridContainer direction='row' align='right' style={{ marginRight: '15px' }}>
        <KitGridItem xs={12}>
          <KitButton size='sm' round color='primary' onClick={showAdd}>
            <AddCircleIcon /> Add Allowed Domain
          </KitButton>
        </KitGridItem>
      </KitGridContainer>

      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <CustomFilter
          fields={[
            { name: 'district_name', label: 'District' },
            { name: 'email_domain', label: 'Email Domain' },
          ]}
          onChange={filterOnChange}
        />
        {pageState.allRecords.map((r, i) => (
          <EmailDomainCard key={r.id} emailDomain={r} editFunc={() => showEdit(r)} />
        ))}
        {paginationOptions.totalRecords > (paginationOptions.page + 1) * paginationOptions.perPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <KitButton size='sm' color='secondary' onClick={() => fetchDomainAllowList(paginationOptions.perPage, paginationOptions.page + 1, true)}>
              Load More
            </KitButton>
          </Box>
        )}
      </Box>
      <Box sx={{ display: { sm: 'block', xs: 'none' }, margin: '15px' }}>
        <CustomFilter
          fields={[
            { name: 'district_name', label: 'District' },
            { name: 'email_domain', label: 'Email Domain' },
          ]}
          onChange={filterOnChange}
        />
        <KitGridContainer direction='row' align='center'>
          <KitGridItem xs={12}>
            <TableContainer>
              <KitTable striped hover tableData={pageState.tableRecords} tableHead={headerCols}></KitTable>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={paginationOptions.totalRecords}
                rowsPerPage={paginationOptions.perPage}
                page={paginationOptions.page}
                onPageChange={(ev, val) => {
                  fetchDomainAllowList(paginationOptions.perPage, val);
                }}
                onRowsPerPageChange={(ev) => {
                  const perPage = parseInt(ev.target.value, 10);
                  fetchDomainAllowList(perPage, 0);
                }}
                showFirstButton
                showLastButton
              />
            </TableContainer>
          </KitGridItem>
        </KitGridContainer>

        <AddEditEmailDomainDialog
          action={addEditState.action}
          stateList={states}
          user={session.user}
          open={addEditState.open}
          onClose={handleAddEditClose}
          editDomain={addEditState.editDomain}
        />
      </Box>
    </React.Fragment>
  );
};

export default AdminEmailDomainAllowList;
