import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const SearchButton = ({ isActive = false, color = '#fff', ...props }) => {
  return (
    <IconButton aria-label={isActive ? 'Close Search' : 'Open Search'} {...props} sx={{ color: color }}>
      {isActive ? <CloseIcon /> : <SearchIcon />}
    </IconButton>
  );
};

export default SearchButton;
