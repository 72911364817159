import React, { useState } from 'react';
import styled from '@emotion/styled';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { isFeatureSwitchOnClientAsync } from '../../services/featureSwitchClientUtils';
import { COMMON_ROUTES } from 'services/constants';

const CallToAction = ({ module }) => {
  const { fields } = module;
  const router = useRouter();
  const { data: session, status } = useSession();
  const [isLoading, setIsLoading] = useState(false);

  const doSignUp = async () => {
    setIsLoading(true);
    const registrationEnabled = await isFeatureSwitchOnClientAsync('Individual_Subscription');
    if (registrationEnabled) {
      router.push(COMMON_ROUTES.PricingPage);
    } else {
      signIn('azure-ad-b2c', null, { prompt: 'login' });
    }
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={isLoading} />
      {!session && (
        <ContentContainer>
          <TextContainer>
            <ActionText>{fields.actionText}</ActionText>
          </TextContainer>

          <ActionImage>
            <img src={fields.actionLogo.url} alt='arrow' />
          </ActionImage>

          <ActionArea>
            {fields.actionUrl && fields.actionUrl.text && fields.actionUrl.text.length > 0 && !session && (
              <KitButton
                color='white'
                round
                onClick={doSignUp}
                style={{
                  color: '#B34520',
                  fontWeight: 'bold',
                  padding: '10px 50px',
                  fontSize: '1.50rem',
                  lineHeight: '28.8px',
                }}
              >
                {fields.actionUrl.text}
              </KitButton>
            )}
          </ActionArea>
        </ContentContainer>
      )}
    </React.Fragment>
  );
};

export default CallToAction;

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    minWidth: '100%',
    maxHeight: '80px',
    backgroundColor: '#B34520',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  flexGrow: 500,
  margin: 0,
  padding: '20px 20px 20px 50px',
  minWidth: '50vw',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ActionImage = styled('div')(({ theme }) => ({
  flexGrow: 0,
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'transparent',
    width: '15%',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const ActionArea = styled('div')(({ theme }) => ({
  flexGrow: 0,
  padding: '10px 8% 10px 8%',
  minWidth: '30vw',
  maxWidth: '20vw',
}));

const ActionText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  fontSize: '2.00rem',
  lineSpacing: '38.4px',
  textTransform: 'uppercase',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.50rem',
  },
}));
