import React from 'react';
import Typography from '@mui/material/Typography';

const CustomText = (props) => {
  const { color, children, component, fontSize, fontWeight, lineHeight, ...rest } = props;
  return (
    <Typography {...rest} component={component} color={color} fontSize={fontSize} fontWeight={fontWeight} lineHeight={lineHeight}>
      {children}
    </Typography>
  );
};

export default CustomText;
