import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { Checkbox, FormControl, FormControlLabel, TextField, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSession } from 'next-auth/react';

import { logClientException } from 'appinsights/clientAppInsights';
import { emailRegex } from 'services/stringUtils';
import { callApi, BadRequestError } from 'services/apiWrapper';
import PleaseWait from 'components/common/PleaseWait';
import { ERROR_MESSAGES, LEAD_TITLES, OTHER_DISTRICT, OTHER_SCHOOL } from 'services/constants';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { getAgencyData } from 'services/userUtils';
import { states } from 'services/stringUtils';
import SchoolSelector from './SchoolSelector';

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY;
const FORM_NAME = 'Ask Trainer';
const FORM_ID = '2ed576339c9f458f9f7e403064357bc7';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function QuestionForm(props) {
  const { buttonColor } = props;
  const { data: session, status } = useSession();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [form, setForm] = useState({
    question: '',
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    attended: false,
    recaptcha_token: '',
    city: '',
    state: '',
    agency: '',
  });

  useEffect(() => {
    const sessionInit = (user) => {
      if (!user) {
        return;
      }
      setForm({
        ...form,
        state: user.state,
        school_id: user.school_id,
        school_name: user.school_name,
        district_id: user.district_id,
        other_city: user.other_city,
        other_agency: user.other_agency,
        first_name: user?.firstName ?? '',
        last_name: user?.lastName ?? '',
        email: user?.email ?? '',
        role: user?.position ?? '',
      });
    };
    sessionInit(session?.user);
  }, [session?.user]);

  const questionOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'question' || field === 'first_name' || field === 'last_name') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    if (field === 'email') {
      if (!emailRegex.test(value)) {
        canSave = false;
        fieldError = true;
      }
    }
    setForm({ ...form, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const canSubmitQuestion = () => {
    let hasError = false;
    if (!form.email || form.email.length === 0 || !emailRegex.test(form.email)) {
      hasError = true;
      form.emailError = true;
    }
    if (!form.question || form.question.length === 0) {
      hasError = true;
      form.questionError = true;
    }
    if (!form.first_name || form.first_name.length === 0) {
      hasError = true;
      form.first_nameError = true;
    }
    if (!form.last_name || form.last_name.length === 0) {
      hasError = true;
      form.last_nameError = true;
    }
    if (!form.role || form.role.length === 0) {
      hasError = true;
      form.roleError = true;
    }
    if (!form.recaptcha_token || form.recaptcha_token.length === 0) {
      hasError = true;
      form.recaptcha_tokenError = true;
    }
    if (!form.schoolInfo?.state || form.schoolInfo?.state.length === 0) {
      hasError = true;
      form.stateError = true;
    } else {
      form.stateError = false;
    }

    if (!form.schoolInfo?.district?.value?.id || form.schoolInfo?.district?.value?.id.length === 0) {
      hasError = true;
      form.districtError = true;
    } else {
      form.districtError = false;
    }

    if (
      form.schoolInfo?.district?.value?.id !== OTHER_DISTRICT.id &&
      !session?.user && // if user is logged in, we don't need to check for school
      (!form.schoolInfo?.school?.value?.id || form.schoolInfo?.school?.value?.id.length === 0)
    ) {
      hasError = true;
      form.schoolError = true;
    } else {
      form.schoolError = false;
    }

    if (form.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id && (!form.schoolInfo?.other_city || form.schoolInfo?.other_city.length === 0)) {
      hasError = true;
      form.other_cityError = true;
    } else {
      form.other_cityError = false;
    }

    if (
      form.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id &&
      (!form.schoolInfo?.other_agency || form.schoolInfo?.other_agency.length === 0)
    ) {
      hasError = true;
      form.other_agencyError = true;
    } else {
      form.other_agencyError = false;
    }

    if (hasError) {
      setForm({ ...form, canSave: false });
    }
    return !hasError;
  };

  const resetState = () => {
    setErrorMessage(null);
    setForm({
      question: '',
      email: '',
      first_name: '',
      last_name: '',
      role: '',
      attended: false,
      recaptcha_token: '',
      city: '',
      state: '',
      agency: '',
    });
  };

  const submitQuestion = async () => {
    if (!canSubmitQuestion()) {
      return;
    }

    try {
      const body = {
        email: form.email,
        question: form.question,
        attended: form.attended,
        first_name: form.first_name,
        last_name: form.last_name,
        role: form.role,
        decision_maker: LEAD_TITLES.find((t) => t.title === form.role)?.decision_maker,
        recaptcha_token: form.recaptcha_token,
        utm_source: window.sessionStorage?.utm_source,
        utm_medium: window.sessionStorage?.utm_medium,
        utm_campaign: window.sessionStorage?.utm_campaign,
        utm_content: window.sessionStorage?.utm_content,
        state: form.schoolInfo?.state?.value,
      };
      if (form.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id) {
        body.other_agency = form.schoolInfo?.other_agency;
        body.other_city = form.schoolInfo?.other_city;
        body.agencyType = 'OTHER';
      } else if (form.schoolInfo?.school?.value?.id !== OTHER_SCHOOL.id) {
        body.agencyType = 'SCHOOL';
        body.agency_id = form.schoolInfo?.school?.value?.agile_ed_id;
      } else {
        body.agencyType = 'DISTRICT';
        body.agency_id = form.schoolInfo?.district?.value?.agile_ed_id;
      }

      try {
        setIsLoading(true);
        await callApi('/api/ask-the-trainer', 'POST', body);
        addDataLayerEvent({ 'event': 'ask_trainer_form', 'form_name': FORM_NAME, 'form_id': FORM_ID });
        setFormSubmitted(true);
        resetState();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        logClientException(error);
        if (error instanceof BadRequestError) {
          setErrorMessage(error.message);
          return;
        } else {
          setErrorMessage(ERROR_MESSAGES.Default);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      logClientException(error);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (formSubmitted) {
    return (
      <Box sx={{ width: '60%', textAlign: 'center', fontSize: '18px', lineHeight: '28px' }}>
        <p>Thank You For Your Question.</p>
        <p>&nbsp;</p>
        <p>One of our trainers will respond to you via email. If you think of anything else, feel free to ask again.</p>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <KitGridContainer
        direction='row'
        style={{ width: '95%', marginBottom: '30px', backgroundColor: '#fff', padding: '25px', boxShadow: '0px 4px 4px 0px #00000040' }}
      >
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <PleaseWait isLoading={isLoading} />

        <KitGridItem xs={12} sm={12} md={12} lg={12} align='left' style={{ padding: '10px 0 0 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                color='default'
                checked={form.attended}
                onChange={(e) => {
                  questionOnChange('attended', !form.attended);
                }}
              />
            }
            label='I have attended Boys Town Training in the past 12 months.'
          />
        </KitGridItem>

        <KitGridItem xs={12} sm={12} md={12} lg={12} align='left' style={{ padding: '0 0 0px 0' }}>
          <FormControl fullWidth>
            <TextField
              name='question'
              aria-label='question'
              minRows={3}
              maxRows={3}
              error={form.questionError}
              value={form.question}
              placeholder='Receive advice from our experts by typing your question here.'
              label='Question'
              onChange={(e) => {
                questionOnChange('question', e.target.value);
              }}
              multiline={true}
              required
              variant='standard'
              inputProps={{ maxLength: 500 }}
              style={{
                fontSize: '0.938rem',
                fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
              }}
            />
          </FormControl>
        </KitGridItem>
        <KitGridItem xs={6} sm={6} md={6} lg={6} align='left' style={{ padding: '0 0 10px 0' }}>
          <FormControl fullWidth>
            <TextField
              id='first_name'
              size='small'
              label='First Name'
              required
              fullWidth
              error={form.first_nameError}
              variant='standard'
              value={form.first_name}
              onChange={(e) => {
                questionOnChange('first_name', e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              inputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </KitGridItem>
        <KitGridItem xs={6} sm={6} md={6} lg={6} align='left' style={{ padding: '0 0 10px 10px' }}>
          <FormControl fullWidth>
            <TextField
              id='last_name'
              size='small'
              label='Last Name'
              required
              fullWidth
              error={form.last_nameError}
              variant='standard'
              value={form.last_name}
              onChange={(e) => {
                questionOnChange('last_name', e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              inputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </KitGridItem>

        <KitGridItem xs={6} sm={6} md={6} lg={6} align='left' style={{ padding: '0 0 10px 0' }}>
          <FormControl fullWidth>
            <TextField
              id='email'
              size='small'
              label='Your Email'
              required
              fullWidth
              error={form.emailError}
              variant='standard'
              value={form.email}
              onChange={(e) => {
                questionOnChange('email', e.target.value);
              }}
              inputProps={{ maxLength: 100 }}
              inputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </KitGridItem>

        <KitGridItem xs={6} sm={6} md={6} lg={6} align='left' style={{ padding: '0 0 10px 10px' }}>
          <FormControl fullWidth variant='standard'>
            <InputLabel id='role-select-label' required error={form.roleError}>
              Title
            </InputLabel>
            <Select
              name='type'
              variant='standard'
              labelId='role-select-label'
              id='role-select-value'
              size='small'
              value={form.role}
              error={form.roleError}
              required
              onChange={(e) => {
                questionOnChange('role', e.target.value);
              }}
            >
              {LEAD_TITLES.map((t) => {
                return (
                  <MenuItem value={t.title} key={t.title}>
                    {t.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </KitGridItem>

        <KitGridItem xs={12} sm={12} md={12} lg={12} align='left' style={{ display: session?.user ? 'none' : 'block', padding: 0 }}>
          <SchoolSelector
            state={form.state}
            district_id={form.district_id}
            school_id={form.school_id}
            school_name={form.school_name}
            requireCity={true}
            other_agency={form.other_agency}
            other_city={form.other_city}
            stateError={form.stateError}
            districtError={form.districtError}
            schoolError={form.schoolError}
            other_cityError={form.other_cityError}
            other_agencyError={form.other_agencyError}
            onDataChange={(e) => {
              setForm({ ...form, schoolInfo: e });
            }}
          />
        </KitGridItem>

        <KitGridItem xs={6} sm={6} md={6} lg={6} style={{ marginTop: '5px', padding: '10px 0 0 0' }}>
          <ReCAPTCHA sitekey={`${SITE_KEY}`} onChange={(token) => questionOnChange('recaptcha_token', token)} />
        </KitGridItem>

        <KitGridItem xs={12} sm={12} md={12} lg={12} align='left' style={{ padding: '10px 0' }}>
          <KitButton
            round
            size='sm'
            onClick={(e) => submitQuestion()}
            style={{
              backgroundColor: buttonColor,
              margin: 0,
              padding: '7px 40px',
              fontSize: '0.90rem',
              opacity: 1.0,
            }}
          >
            SUBMIT
          </KitButton>
        </KitGridItem>
      </KitGridContainer>
    </React.Fragment>
  );
}

QuestionForm.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};
