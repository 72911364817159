import React, { useState } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ListItemIcon } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';
import GatedResourceDrawer from 'components/common/subcomponents/GatedResources/GatedResourceDrawer';
import GatedResourceModal from 'components/common/subcomponents/GatedResources/GatedResourceModal';

import { gatedResourceCheck, logResourceAccess } from 'services/gatedResourceUtils';
import FreeResourceNewIndicator from 'components/common/FreeResourceNewIndicator';
import { FreeResourceNewCountTag } from '../common/FreeResourceNewIndicator';

const VideoLinks = ({ module }) => {
  const { fields } = module;
  const [gatedDrawerState, setGatedDrawerState] = useState({ open: false, remaining: 0 });
  const [gatedModalOpen, setGatedModalOpen] = useState(false);
  const { data: session, status } = useSession();

  const [videoSelected, setVideoSelected] = useState(null);
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const hideVideoDialog = () => {
    setShowVideoDialog(false);
  };

  let newCount = 0;
  for (let i = 1; i < 4; i++) {
    fields[`column${i}Links`]?.forEach((link) => {
      if (link.fields?.isNew === 'true') {
        newCount++;
      }
    });
  }

  const calculateContainerHeight = (column1Links, column2Links, column3Links, column1LongTitles, column2LongTitles, column3LongTitles) => {
    let links1 = column1Links ? column1Links.length : 0;
    let links2 = column2Links ? column2Links.length : 0;
    let links3 = column3Links ? column3Links.length : 0;
    let height = 0;

    if (links1 > 0) {
      const lineHeight1 = column1LongTitles ? 90 : 55;
      height = links1 * lineHeight1 + 'px';
    }
    if (links2 > height) {
      const lineHeight2 = column2LongTitles ? 90 : 55;
      height = links2 * lineHeight2 + 'px';
    }
    if (links3 > height) {
      const lineHeight3 = column3LongTitles ? 90 : 55;
      height = links3 * lineHeight3 + 'px';
    }
    return height;
  };

  const selectAndShowVideo = async (video) => {
    if (!session?.user) {
      const remaining = gatedResourceCheck(video.fields.webinarUrl.href);
      if (remaining < 0) {
        // show overlay
        setGatedModalOpen(true);
        return;
      } else {
        setGatedDrawerState({ open: true, remaining });
      }
    }

    setVideoSelected(video);
    setShowVideoDialog(true);
    // log resource access
    await logResourceAccess('recorded webinar', video.fields.name, video.fields.webinarUrl.href);
  };

  const showDocumentLink = (url) => {
    return (
      <ListItemIcon style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Link href={url.href} passHref legacyBehavior>
          <a target={url.target} rel='noopener noreferrer'>
            <ListText style={{ width: '100%' }}>
              <ArticleIcon /> {url.text}
            </ListText>
          </a>
        </Link>
      </ListItemIcon>
    );
  };

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
    @media screen and (max-width: 991px) {
      padding: 10px;
    }
    @media screen and (min-width: 992px) {
      padding: 30px;
    }
  `;

  const PageFlow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      width: 90%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  `;

  const TitleBox = styled.div`
    margin: 40px 0 20px 0;
    display: flex;
    justify-content: center;
  `;

  const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
    @media screen and (min-width: 992px) {
      width: 30%;
      height: ${calculateContainerHeight(
        fields.column1Links,
        fields.column2Links,
        fields.column3Links,
        fields.column1LongTitles,
        fields.column2LongTitles,
        fields.column3LongColumns
      )};
      margin: 0 20px;
    }
  `;

  const ContentContainer = styled.div`
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (min-width: 992px) {
      width: 33%;
      margin: 0 20px;
    }
  `;

  const ListText = styled.div`
    cursor: pointer;
    color: theme.palette.common.black;
    font-size: 1.125rem;
    line-height: 1.525rem;
    margin: 15px 0;
  `;

  const ResourceListItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    border-bottom: 2px dashed #037ac4;
    @media screen and (max-width: 991px) {
    }
    @media screen and (min-width: 992px) {
      &:last-child {
        border-bottom: none;
      }
    }
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 35px 0;
    @media screen and (max-width: 899px) {
      .video {
        aspect-ratio: 16 / 9;
        width: 300px;
      }
    }
    @media screen and (min-width: 900px) {
      .video {
        aspect-ratio: 16 / 9;
        width: 650px;
      }
    }
  `;

  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const EmbedContainer = styled.div`
    border: 1px solid #000;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      width: 650px;
    }
  `;

  const YouTubeLink = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 899px) {
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const DocumentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 899px) {
      margin: 10px 0 0 0;
    }
  `;

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container>
        <a id={fields.anchor}>
          <TitleBox>
            <Heading4 color='#000000'>{fields.title}</Heading4>
            {newCount > 0 && <FreeResourceNewCountTag>{newCount}</FreeResourceNewCountTag>}
          </TitleBox>
        </a>
        <PageFlow>
          {fields.column1Icon && fields.column1Icon.url && fields.column1Icon.url.length > 0 ? (
            <ImageContainer>
              <ResourceFrame key='post.contentID'>
                <ResourceImg alt='icon' src={fields.column1Icon.url} width='160px' />
              </ResourceFrame>
            </ImageContainer>
          ) : (
            <ContentContainer>
              {fields.column1Links &&
                fields.column1Links.length > 0 &&
                fields.column1Links
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      item &&
                      item.fields &&
                      item.fields.webinarEmbed &&
                      item.fields.webinarEmbed.includes('https') && (
                        <ResourceListItem key={index} onClick={(e) => selectAndShowVideo(item)}>
                          {item.fields?.isNew === 'true' && <FreeResourceNewIndicator>NEW</FreeResourceNewIndicator>}
                          <ListText>{item.fields.name}</ListText>
                        </ResourceListItem>
                      )
                    );
                  })}
            </ContentContainer>
          )}
          {fields.column2Icon && fields.column2Icon.url && fields.column2Icon.url.length > 0 ? (
            <ImageContainer>
              <ResourceFrame key='post.contentID'>
                <ResourceImg alt='icon' src={fields.column2Icon.url} width='160px' />
              </ResourceFrame>
            </ImageContainer>
          ) : (
            <ContentContainer>
              {fields.column2Links &&
                fields.column2Links.length > 0 &&
                fields.column2Links
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      item &&
                      item.fields &&
                      item.fields.webinarEmbed &&
                      item.fields.webinarEmbed.includes('https') && (
                        <ResourceListItem key={index} onClick={(e) => selectAndShowVideo(item)}>
                          {item.fields?.isNew === 'true' && <FreeResourceNewIndicator>NEW</FreeResourceNewIndicator>}
                          <ListText>{item.fields.name}</ListText>
                        </ResourceListItem>
                      )
                    );
                  })}
            </ContentContainer>
          )}
          {fields.column3Icon && fields.column3Icon.url && fields.column3Icon.url.length > 0 ? (
            <ImageContainer>
              <ResourceFrame key='post.contentID'>
                <ResourceImg alt='icon' src={fields.column3Icon.url} width='160px' />
              </ResourceFrame>
            </ImageContainer>
          ) : (
            <ContentContainer>
              {fields.column3Links &&
                fields.column3Links.length > 0 &&
                fields.column3Links
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      item &&
                      item.fields &&
                      item.fields.webinarEmbed &&
                      item.fields.webinarEmbed.includes('https') && (
                        <ResourceListItem key={index} onClick={(e) => selectAndShowVideo(item)}>
                          {item.fields?.isNew === 'true' && <FreeResourceNewIndicator>NEW</FreeResourceNewIndicator>}
                          <ListText>{item.fields.name}</ListText>
                        </ResourceListItem>
                      )
                    );
                  })}
            </ContentContainer>
          )}
        </PageFlow>
      </Container>
      <Dialog open={showVideoDialog} onClose={hideVideoDialog} scroll='paper' maxWidth='md' fullWidth={false} aria-labelledby='Pop-up Video Viewer'>
        <React.Fragment>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: 'fit-content' }}>
              <KitGridContainer direction='row'>
                <KitGridItem xs={12} align='left'>
                  <VideoContainer style={{ backgroundColor: '#fff' }}>
                    <EmbedContainer
                      className='prose max-w-full mx-auto'
                      dangerouslySetInnerHTML={renderHTML(videoSelected && videoSelected.fields.webinarEmbed)}
                    />
                    <YouTubeLink>
                      <a
                        href={videoSelected && videoSelected.fields.webinarUrl && videoSelected.fields.webinarUrl.href}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <KitButton round size='sm' color='youtube' style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}>
                          <React.Fragment key='.0'>
                            <PlayArrowIcon color='white' /> View video in YouTube
                          </React.Fragment>
                        </KitButton>
                      </a>
                    </YouTubeLink>
                    <DocumentContainer>
                      {videoSelected &&
                        videoSelected.fields &&
                        videoSelected.fields.documentUrl &&
                        showDocumentLink(videoSelected.fields.documentUrl, fields.textColor)}
                    </DocumentContainer>
                  </VideoContainer>
                </KitGridItem>
              </KitGridContainer>
            </Box>
            <ButtonContainer>
              <KitButton
                round
                size='sm'
                color='primary'
                onClick={hideVideoDialog}
                style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}
              >
                Close
              </KitButton>
            </ButtonContainer>
          </DialogContent>
        </React.Fragment>
      </Dialog>
      <GatedResourceDrawer
        open={gatedDrawerState.open}
        remainingCount={gatedDrawerState.remaining}
        toggleDrawer={(e) => setGatedDrawerState({ ...gatedDrawerState, open: e })}
      />
      <GatedResourceModal open={gatedModalOpen} toggleOpen={(e) => setGatedModalOpen(e)} />
    </Box>
  );
};

export default VideoLinks;

const ResourceImg = styled('img')(({ theme }) => ({
  backgroundColor: '#B8C7CF',
  display: 'block',
  height: '100%',
  width: 'auto',
}));

const ResourceFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '50px solid #B8C7CF',
  backgroundColor: '#B8C7CF',
  borderRadius: '6px',
  height: '160px',
  width: '160px',
}));

const ResourceSeparator = styled('div')(({ theme }) => ({
  fontWeight: 100,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
    lineHeight: '0.40rem',
    padding: '0 25px 0 30px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '0.45rem',
    padding: '0 25px 0 10px',
  },
}));

const ResourceDescription = styled('div')(({ theme }) => ({
  border: '1px solid purple',
  fontWeight: 300,
  fontSize: '0.85rem',
  lineHeight: '1.40rem',
  [theme.breakpoints.up('md')]: {
    margin: '5px 10px 15px 0',
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {},
}));
