// package imports
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useSession } from 'next-auth/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTheme } from '@emotion/react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { renderHTML } from '@agility/nextjs';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';

// local imports
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { LEAD_TITLES, OTHER_DISTRICT, OTHER_SCHOOL, PRODUCTS } from 'services/constants';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import { states } from 'services/stringUtils';
import { getSessionStoreString, setSessionStoreString } from 'services/storageUtils';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { hasModuleAccess } from 'services/subscriptionUtils';
import { ROLE_CONST } from 'services/roleUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const SFMC_NEW_PAID_INDIVIDUAL = 'SFMC_NEW_PAID_INDIVIDUAL';
const SFMC_NEW_PAID_SCHOOL = 'SFMC_NEW_PAID_SCHOOL';
const SFMC_NEW_FREE_INDIVIDUAL = 'SFMC_NEW_FREE_INDIVIDUAL';
const SFMC_NEW_PAID_ADMIN = 'SFMC_NEW_PAID_ADMIN';

const CompleteProfile = ({ module: { fields }, globalData }) => {
  const terms = globalData?.header?.terms;
  const theme = useTheme();
  const router = useRouter();
  const { data: session, status } = useSession();
  const initialized = useRef(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [districtDisabled, setDistrictDisabled] = useState(false);
  const [agreeTos, setAgreeTos] = useState(false);
  const [agreeTosTimestamp, setAgreeTosTimestamp] = useState(null);
  const [tosOpen, setTosOpen] = useState(false);

  const getLocalStoreString = (key) => {
    if (typeof window !== 'undefined') {
      return window?.localStorage?.getItem(key) || null;
    }
    return null;
  };
  const [userState, setUserState] = useState(() => {
    return getLocalStoreString('st');
  });
  const [districtId, setDistrictId] = useState(() => {
    return getLocalStoreString('did');
  });
  const [planToPurchase, setPlanToPurchase] = useState(null);

  const [userDialogState, setUserDialogState] = useState({
    state: null,
    district: null,
    school: null,
    position: '',
    ccCouponCode: null,
  });

  useEffect(() => {
    const init = async () => {
      if (!session?.user) {
        return;
      }
      setErrorMessage(null);

      let plan = null;
      try {
        plan = JSON.parse(getLocalStoreString('plan-to-purchase'));
      } catch (error) {
        console.log('failed to get plan', error);
      }
      if (plan) {
        setPlanToPurchase(plan);
      }

      // if the user's subscription has expired, etc, display the message
      if (session?.user?.subscription?.message?.length > 0) {
        setErrorMessage(session?.user?.subscription?.message);
      }

      if (session?.user?.state?.length > 0 && session?.user?.district_id?.length > 0) {
        let state;
        let district = null;
        let school = null;
        let sList = [];
        state = states.find((s) => s.value === session?.user.state);
        if (session?.user.district_id === OTHER_DISTRICT.id) {
          district = { label: OTHER_DISTRICT.name, value: OTHER_DISTRICT };
          school = { label: OTHER_SCHOOL.name, value: OTHER_SCHOOL };
          await fetchDistrictsForState(state);
        } else {
          district = await fetchDistrict(session?.user.district_id);
          sList = await fetchSchoolsForDistrict(district);
          if (session?.user?.school_id && session?.user?.school_name) {
            school = { label: session?.user?.school_name, value: sList.find((s) => s?.value?.id === session?.user?.school_id)?.value };
          }
        }

        setUserDialogState({ ...userDialogState, state, district, school, ccState: state, position: session?.user?.position ?? '' });

        // district was matched at login (or via allow list), don't allow it to be changed
        setDistrictDisabled(true);
      } else {
        let state = null;
        let district = null;
        if (userState) {
          state = states.find((s) => s.value === userState);
        }
        if (districtId) {
          console.log('using ls did');
          district = await fetchDistrict(districtId);
          if (district) {
            setDistrictList([district]);
            await fetchSchoolsForDistrict(district);
          }
        } else if (state) {
          await fetchDistrictsForState(state);
        }

        setUserDialogState({ ...userDialogState, state, district, ccState: state });
      }
      initialized.current = true;
    };

    if (!initialized.current) {
      init();
    }
  }, [districtId, session?.user]);

  const isSchoolRequiredForTitle = (title) => {
    return LEAD_TITLES.find((t) => t.title === title)?.school_required;
  };

  const resetState = () => {
    setAgreeTos(false);
    setAgreeTosTimestamp(null);
    setErrorMessage(null);
    setDistrictList([]);
    setSchoolList([]);
    setUserDialogState({
      district: null,
      school: null,
      state: null,
      position: '',
      ccCouponCode: null,
      ccName: null,
      ccAddress: null,
      ccCity: null,
      ccState: null,
      ccZip: null,
      ccNumber: null,
      ccExpMonth: null,
      ccExpYear: null,
      ccCVV: null,
    });
  };

  const afterProfileComplete = () => {
    clearLocalStore();
    setSessionStoreString('profile_complete', 'true');
    if (planToPurchase) {
      // send user to plan purchase page
      if (typeof window !== 'undefined') {
        window?.sessionStorage.removeItem('signing_in');
      }
      router.push('pricing-pages/plan-purchase');
      return;
    }
    if (hasModuleAccess({ user: session?.user }, 'wms')) {
      router.push(PRODUCTS.find((p) => p.id === 'wms').homePage);
      return;
    } else if (hasModuleAccess({ user: session?.user }, 'ai')) {
      router.push(PRODUCTS.find((p) => p.id === 'ai').homePage);
      return;
    } else {
      router.push('free-resources');
      return;
    }
  };

  const clearLocalStore = () => {
    if (typeof window !== 'undefined') {
      window?.localStorage.removeItem('st');
      window?.localStorage.removeItem('did');
    }
  };

  const fetchDistrict = async (district_id) => {
    try {
      if (district_id === OTHER_DISTRICT.id) {
        return { label: OTHER_DISTRICT.name, value: OTHER_DISTRICT };
      }
      setFetching(true);
      setSchoolList([]);
      let res = await callApi(`/api/db/district-list?did=${district_id}`);
      if (res.results[0]) {
        return { label: res.results[0].name, value: res.results[0] };
      }
      setFetching(false);
      setSchoolList([]);
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchDistrictsForState = async (state) => {
    try {
      setFetching(true);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      let dList = res.results.map((r) => ({ label: r.name, value: r }));
      dList = dList.filter((d) => {
        // filter out districts not eligible to be selected

        // no sso provider, but used allow list or email domain match
        if (
          ['none', 'other'].indexOf(d.value.sso_provider?.toLowerCase()) > -1 &&
          (d.value.use_allow_list || d.value.limit_by_email_domain) &&
          !d.value.is_seat_based
        ) {
          return false;
        }

        // if district is sso, subscription is active, and not seat based, filter out
        if (['google', 'clever'].indexOf(d.value.sso_provider?.toLowerCase()) > -1) {
          //} && d.value.subscriptions && d.value.subscriptions.length > 0) {
          let activeSubs = [];
          d.value.subscriptions?.forEach((s) => {
            if (s.end_date > Date.now() && s.start_date <= Date.now()) {
              activeSubs.push(s);
            }
          });
          if (activeSubs.length > 0 && !activeSubs.find((s) => s.is_seat_based)) {
            return false;
          }
          return true;
        }
        return true;
      });
      dList.push({ label: OTHER_DISTRICT.name, value: OTHER_DISTRICT });
      setDistrictList(dList);
      setSchoolList([]);
      setFetching(false);
      return dList;
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district) => {
    try {
      if (district.value.id === OTHER_DISTRICT.id) {
        setSchoolList([{ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL }]);
        return;
      }
      setFetching(true);
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district.value.id}`);
      setFetching(false);
      const tempSchools = res.results.map((r) => ({ label: r.name, value: r }));
      tempSchools.push({ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL });
      setSchoolList(tempSchools);
      setFetching(false);
      return tempSchools;
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const getUpdatedUser = () => {
    let user = { emailAddress: session?.user.email };
    if (userDialogState.district) {
      user.district_id = userDialogState.district.value?.id;
      user.district_name = userDialogState.district.label;
    }
    if (userDialogState.school) {
      user.school_id = userDialogState.school.value?.id;
      user.school_name = userDialogState.school.label;
    }
    if (userDialogState.state) {
      user.state = userDialogState.state.value;
    }
    user.position = userDialogState.position;
    user.agreedToTermsTimestamp = agreeTosTimestamp;
    if (user.district_id === OTHER_DISTRICT.id) {
      user.other_city = userDialogState.other_city;
      user.other_agency = userDialogState.other_agency;
    }
    if (getSessionStoreString('utm_source')) {
      user.utm_source = getSessionStoreString('utm_source');
    }
    if (getSessionStoreString('utm_medium')) {
      user.utm_medium = getSessionStoreString('utm_medium');
    }
    if (getSessionStoreString('utm_campaign')) {
      user.utm_campaign = getSessionStoreString('utm_campaign');
    }
    if (getSessionStoreString('utm_content')) {
      user.utm_content = getSessionStoreString('utm_content');
    }

    return user;
  };

  const canSaveProfile = () => {
    let hasError = false;
    const user = userDialogState;
    Object.keys(user).forEach((key) => {
      if (key.endsWith('Error') && user.hasOwnProperty(key)) {
        delete user[key];
      }
    });
    if (!user.position || user.position.length === 0) {
      hasError = true;
      user.positionError = true;
    }
    if (!user.state || !user.state.value) {
      hasError = true;
      user.stateError = true;
    }
    if (!user.district || !user.district.value) {
      hasError = true;
      user.districtError = true;
    }
    if (isSchoolRequiredForTitle(user.position)) {
      if (!user.school || !user.school.value || user.school.value.length === 0) {
        hasError = true;
        user.schoolError = true;
      }
    }
    if (user.district?.value?.id === OTHER_DISTRICT.id) {
      if (!user.other_city || user.other_city.length === 0) {
        hasError = true;
        user.other_cityError = true;
      }
      if (!user.other_agency || user.other_agency.length === 0) {
        hasError = true;
        user.other_agencyError = true;
      }
    }

    if (!agreeTos) {
      hasError = true;
      user.agreeTosError = true;
    }

    setUserDialogState({ ...userDialogState, canSave: !hasError });

    return !hasError;
  };

  const saveprofile = async () => {
    if (!canSaveProfile()) {
      return;
    }

    let user = getUpdatedUser();

    try {
      setFetching(true);
      if (session?.user.subscriptions?.find((s) => s.entity_type !== 'user') && session?.user.first_login) {
        // new user with a school or district subscription
        const sub = session?.user.subscriptions?.find((s) => s.entity_type !== 'user'); // just grab first (might be more than one?)
        const product = PRODUCTS.find((p) => p.id === sub.product);
        await callApi('/api/db/user', 'PUT', {
          user: user,
          is_login: true,
          new_user_journey:
            [ROLE_CONST.SchoolAdmin, ROLE_CONST.DistrictAdmin].indexOf(session?.user.role) > -1 ? SFMC_NEW_PAID_ADMIN : SFMC_NEW_PAID_SCHOOL,
          product: product.trans_desc,
        });
      } else if (session?.user.first_login && planToPurchase) {
        // new user purchasing an individual subscription
        await callApi('/api/db/user', 'PUT', {
          user: user,
          is_login: true,
          new_user_journey: SFMC_NEW_FREE_INDIVIDUAL, // add to free journey to start, if they complete the purchase will be added to the paid.
          //product: PRODUCTS.find((p) => p.id == planToPurchase.product).trans_desc,
        });
      } else if (session?.user.first_login && !planToPurchase) {
        // new user not purchasing and individual subscription
        console.log('new user - no paid subscription');
        await callApi('/api/db/user', 'PUT', {
          user: user,
          is_login: true,
          new_user_journey: SFMC_NEW_FREE_INDIVIDUAL,
        });
      } else {
        await callApi('/api/db/user', 'PUT', { user: user, is_login: true });
      }
      let event = {
        'event': 'signup',
        'userId': session?.user.id,
      };
      addDataLayerEvent(event);
      resetState();
      afterProfileComplete();
      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        width: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        width: '100%',
        mt: '20px',
        mb: '3rem',
      }}
    >
      <Box
        sx={{
          margin: '50px 30px',
        }}
      >
        <Heading3 color='#000000' underline={false} uppercase={false}>
          Welcome, {session?.user?.firstName}!
        </Heading3>
      </Box>

      <Grid container sx={{ width: '80%', maxWidth: '1000px' }}>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccountCircleOutlinedIcon sx={{ fontSize: '30px', color: theme.palette.gold.main, marginRight: '1rem' }} />
            <Typography component='h5' fontSize='24px' fontWeight='600' lineHeight='33.94px'>
              Complete Your Profile
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <InputLabel id='role-select-label' required error={userDialogState.positionError} sx={{ marginLeft: '-1rem' }}>
              Title
            </InputLabel>
            <Select
              name='type'
              variant='standard'
              labelId='role-select-label'
              id='role-select-value'
              value={userDialogState.position}
              error={userDialogState.positionError}
              required
              onChange={(e) => {
                setUserDialogState({ ...userDialogState, position: e.target.value });
              }}
            >
              {LEAD_TITLES.map((t) => {
                return (
                  <MenuItem value={t.title} key={t.title}>
                    {t.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Autocomplete
            sx={{ marginTop: '7px', display: userState ? 'none' : 'block' }}
            disabled={districtDisabled}
            disablePortal
            options={states}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            value={userDialogState.state}
            onChange={async (e, v) => {
              if (v) {
                await fetchDistrictsForState(v);
                setUserDialogState({ ...userDialogState, state: v, district: null, school: null });
              }
            }}
            renderInput={(params) => <TextField required error={userDialogState.stateError} variant='standard' {...params} label='State' />}
          />

          <Autocomplete
            sx={{ marginTop: '7px', display: districtId ? 'none' : 'block' }}
            disablePortal
            options={districtList}
            fullWidth
            isOptionEqualToValue={(o, v) => o.value.id === v?.value?.id}
            disabled={(districtDisabled, !userDialogState.state)}
            value={userDialogState.district}
            onChange={async (e, v) => {
              if (v) {
                await fetchSchoolsForDistrict(v);
                if (v?.value?.id === OTHER_DISTRICT.id) {
                  setUserDialogState({ ...userDialogState, district: v, school: { label: OTHER_SCHOOL.name, value: OTHER_SCHOOL } });
                } else {
                  setUserDialogState({ ...userDialogState, district: v, school: null });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                disabled={!userDialogState.state}
                required
                error={userDialogState.districtError}
                variant='standard'
                {...params}
                label='School district'
              />
            )}
          />

          {userDialogState.district?.value?.id !== OTHER_DISTRICT.id && (
            <Autocomplete
              sx={{ marginTop: '7px' }}
              disablePortal
              options={schoolList}
              isOptionEqualToValue={(o, v) => o.value === v?.value}
              fullWidth
              disabled={
                !userDialogState.district ||
                !schoolList ||
                schoolList.length == 0 ||
                userDialogState.school?.value?.subscriptions?.find((s) => s.is_seat_based && s.seat_list?.indexOf(session?.user.email) > -1)
              }
              value={userDialogState.school}
              onChange={(e, v) => setUserDialogState({ ...userDialogState, school: v })}
              renderInput={(params) => (
                <TextField
                  required={isSchoolRequiredForTitle(userDialogState.position)}
                  error={userDialogState.schoolError}
                  variant='standard'
                  {...params}
                  label='School'
                />
              )}
            />
          )}

          {userDialogState.district?.value?.id === OTHER_DISTRICT.id && (
            <div>
              <TextField
                value={userDialogState.other_city}
                error={userDialogState.other_cityError}
                required
                margin='dense'
                id='City'
                label='City'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setUserDialogState({ ...userDialogState, other_city: e.target.value })}
                inputProps={{ maxLength: 100 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                value={userDialogState.other_agency}
                error={userDialogState.other_agencyError}
                required
                margin='dense'
                id='Agency'
                label='Agency'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setUserDialogState({ ...userDialogState, other_agency: e.target.value })}
                inputProps={{ maxLength: 100 }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          )}

          <FormControlLabel
            sx={{ marginTop: '1rem' }}
            fullWidth
            control={
              <Checkbox
                sx={{ color: userDialogState.agreeTosError ? '#d32f2f' : 'inherit' }}
                checked={agreeTos}
                onChange={(e) => {
                  setAgreeTos(e.target.checked);
                  setAgreeTosTimestamp(new Date().getTime());
                }}
              />
            }
            label={
              <span style={{ color: userDialogState.agreeTosError ? '#d32f2f' : 'inherit' }}>
                <span>
                  I have read and understand the{' '}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      setTosOpen(true);
                    }}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    terms and conditions.
                  </span>
                </span>
              </span>
            }
          />

          <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '1.25', mt: '10px' }}>
            As a bonus, you will automatically be signed up for our monthly eNewsletter. Boys Town has been working with kids and families for over a
            century, and we have taken what we&apos;ve learned and developed research-proven training and resources for the classroom that you
            won&apos;t find anywhere else. You can unsubscribe at any time via email.
          </Typography>

          <KitButton onClick={saveprofile} style={{ backgroundColor: theme.palette.brandedAqua.main, marginTop: '1rem' }} size='sm'>
            {planToPurchase ? 'Continue to Purchase' : 'Save Profile'}
          </KitButton>
        </Grid>
      </Grid>

      <Dialog maxWidth='sm' scroll='paper' fullWidth={false} open={tosOpen} onClose={() => setTosOpen(false)}>
        <DialogContent>
          <div className='prose' style={{ marginTop: '-50px' }} dangerouslySetInnerHTML={renderHTML(terms)}></div>
        </DialogContent>
        <DialogActions>
          <KitButton onClick={() => setTosOpen(false)} size='sm' round color='success'>
            OK
          </KitButton>
        </DialogActions>
      </Dialog>

      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <PleaseWait isLoading={fetching} />
    </Box>
  );
};

CompleteProfile.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;
  let terms = null;

  try {
    let page = await api.getPage({
      pageID: 100,
      locale: languageCode,
    });

    if (page?.zones?.MainContentZone?.length > 0) {
      const mod = page?.zones?.MainContentZone.find((m) => m.module === 'RichTextArea');
      if (mod?.item?.fields?.textblob) {
        terms = mod?.item?.fields?.textblob;
      }
    }
  } catch (error) {
    await logServerException(error);
    if (console) console.error('Could not load CompleteProfile item.', error);
    return null;
  }

  // return clean object...
  return {
    terms,
  };
};

export default CompleteProfile;
