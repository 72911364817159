import React, { useState } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton, KitCard, KitCardFooter, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import truncate from 'truncate-html';
import Carousel from 'react-material-ui-carousel';
import { useTheme } from '@mui/material/styles';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const FeaturedPosts = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const displayReadMore = (str, len) => {
    if (str.length > len) {
      return true;
    }
    return false;
  };

  const calcDaysBetween = (dayposted) => {
    const currentDate = new Date();
    const postDate = new Date(dayposted);
    const days = Math.ceil(Math.abs(postDate - currentDate) / (1000 * 60 * 60 * 24));
    return `${days} ${days && days > 1 ? ' days ago' : ' day ago'}`;
  };

  const Background = styled.div`
    background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0.2) 30.21%);
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const Container = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `;

  const PostContainer = styled.div`
    background-color: #fff;
    width: 350px;
    height: 600px;
    margin: 3%;
  `;

  const ActionContainer = styled.div`
    @media screen and (min-width: 993px) {
      margin: 1% 0 5% 0;
    }
    @media screen and (max-width: 992px) {
      margin: 1% 0 10% 0;
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px dashed #000;
    @media screen and (min-width: 993px) {
      margin: 25px 15px 0 15px;
      color: ${fields.titleColor};
      font-size: 2.3rem;
      line-height: 2.425rem;
    }
    @media screen and (max-width: 992px) {
      color: ${fields.titleColor};
      font-size: 2.625rem;
      line-height: 2.838rem;
    }
  `;

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    @media screen and (min-width: 993px) {
      padding: 15px 10px 0 10px;
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  `;

  const MobileImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    @media only screen and (min-width: 993px) {
      display: none;
    }
    @media screen and (max-width: 992px) {
      padding: 15px 0 0 0;
    }
  `;

  const ResourceImg = styled.img`
    border-radius: 3%;
    width: 95%;
    height: auto;
    @media screen and (min-width: 993px) {
      margin: 1% 0 10px 0;
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  const MobileResourceImg = styled.img`
    border-radius: 3%;
    width: 95%;
    height: auto;
  `;

  const PostsList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0 0 0;
    gap: 0rem;
  `;

  const MobilePostsList = styled.ul`
    list-style-type: none;
    display: grid;
    margin: 5px;
    @media screen and (min-width: 993px) {
      display: none;
    }
    @media screen and (max-width: 992px) {
      padding: 0 10px;
      max-width: 100%;
    }
  `;

  const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 380px;
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  const MobileListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (min-width: 993px) {
      display: none;
    }
  `;

  const CarouselContainer = styled.div`
    @media screen and (min-width: 993px) {
      display: none;
    }
    @media screen and (max-width: 992px) {
      width: 380px;
    }
  `;

  function DisplayPostInCarousel(props) {
    return (
      <MobileListContainer>
        <KitCard
          style={{
            width: '380px',
            height: '550px',
          }}
        >
          <MobileImageContainer>
            {props.post.fields.image && <MobileResourceImg alt={props.post.fields.image.label} src={props.post.fields.image.url} />}
          </MobileImageContainer>
          <KitCardBody style={{ padding: '10px 10px 0 10px' }}>
            <PostTags>{props.post.fields.tagNames}</PostTags>
            <PostTitle>{props.post.fields.title}</PostTitle>
            <PostContent>
              {truncate(props.post.fields.content, {
                length: 160,
                decodeEntities: true,
                stripTags: true,
                reserveLastWord: true,
              })}{' '}
              {displayReadMore(props.post.fields.content, 160) && (
                <Link href={`/blog/${props.post.fields.slug}`} passHref legacyBehavior>
                  <ReadMoreLink>Read More</ReadMoreLink>
                </Link>
              )}
            </PostContent>
            <PostDateAndAuthor>
              {props.post.fields.authorName && 'by'} {props.post.fields.authorName}
              {/* {calcDaysBetween(props.post.fields.date)} */}
            </PostDateAndAuthor>
          </KitCardBody>
        </KitCard>
      </MobileListContainer>
    );
  }

  return (
    <Background>
      <Heading3 color='#000000' underline={true} uppercase={true} style={{ marginTop: 20 }}>
        {fields.title}
      </Heading3>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <Container>
          <PostsList>
            {fields.posts &&
              fields.posts.length > 0 &&
              fields.posts.map((post, index) => {
                return (
                  <ListContainer key={post.contentID}>
                    <KitCard
                      style={{
                        width: '350px',
                        height: '575px',
                      }}
                    >
                      <React.Fragment>
                        <ImageContainer>
                          {post.fields.image && <ResourceImg alt={post.fields.image.label} src={post.fields.image.url} />}
                        </ImageContainer>
                        <KitCardBody style={{ padding: '0 20px 0 20px' }}>
                          <PostTags>{post.fields.tagNames}</PostTags>
                          <PostTitle>{post.fields.title}</PostTitle>
                          <PostContent>
                            {truncate(post.fields.content, {
                              length: 160,
                              decodeEntities: true,
                              stripTags: true,
                              reserveLastWord: true,
                            })}{' '}
                            {displayReadMore(post.fields.content, 160) && (
                              <Link href={`/blog/${post.fields.slug}`} passHref legacyBehavior>
                                <ReadMoreLink>Read More</ReadMoreLink>
                              </Link>
                            )}
                          </PostContent>
                          <PostDateAndAuthor>
                            {post.fields.authorName && 'by'} {post.fields.authorName}
                            {/* {calcDaysBetween(post.fields.date)} */}
                          </PostDateAndAuthor>
                        </KitCardBody>
                        <KitCardFooter style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                          <Link href={`/blog/${post.fields.slug}`} passHref legacyBehavior>
                            <KitButton
                              color='success'
                              round
                              size='lg'
                              style={{
                                position: 'absolute',
                                width: '160px',
                                bottom: 15,
                              }}
                            >
                              Read
                            </KitButton>
                          </Link>
                        </KitCardFooter>
                      </React.Fragment>
                    </KitCard>
                  </ListContainer>
                );
              })}
          </PostsList>
          <CarouselContainer>
            <Carousel>
              {fields.posts &&
                fields.posts.length > 0 &&
                fields.posts.map((post, index) => {
                  return <DisplayPostInCarousel key={index} post={post} />;
                })}
            </Carousel>
          </CarouselContainer>
          <ActionContainer>
            <Link href='/blog' passHref legacyBehavior>
              <KitButton
                round
                size='lg'
                style={{
                  width: '160px',
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                MORE
              </KitButton>
            </Link>
          </ActionContainer>
        </Container>
      </Box>
    </Background>
  );
};

export default FeaturedPosts;

const PostTags = styled('div')(({ theme }) => ({
  color: theme.palette.common.gray,
  textTransform: 'uppercase',
  backgroundColor: 'transparent',
  margin: '0 0 15px 0',
  fontSize: '1.00rem',
  lineHeight: '1.425rem',
  fontWeight: 300,
}));

const PostTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  fontSize: '1.15rem',
  lineHeight: '1.525rem',
  fontWeight: 700,
}));

const PostContent = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '5px 0 10px 0',
  fontSize: '1.00rem',
  lineHeight: '1.25rem',
  fontWeight: 400,
}));

const PostDateAndAuthor = styled('div')(({ theme }) => ({
  color: theme.palette.common.gray,
  backgroundColor: 'transparent',
  fontSize: '1.00rem',
  lineHeight: '1.1rem',
  fontWeight: '300',
  fontStyle: 'italic',
}));

const ReadMoreLink = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  margin: '5px 0 5px 0',
  fontSize: '1.00rem',
  fontWeight: 400,
}));
