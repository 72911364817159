import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const BackgroundImageStep = ({ imgUrl, imgOpacity = 1, accentColor = null, children }) => {
  const theme = useTheme();
  const { height, width } = useWindowDimensions();
  const colorPieces = accentColor?.split('.') ?? [];
  accentColor = colorPieces.length > 1 ? theme.palette[colorPieces[0]][colorPieces[1]] : null;

  return (
    <BackgroundImageWrapper imgUrl={imgUrl} opacity={imgOpacity} accentColor={accentColor} height={height}>
      <Box sx={{ position: 'relative' }}>{children}</Box>
    </BackgroundImageWrapper>
  );
};

const BackgroundImageWrapper = styled('div')(({ theme, imgUrl, opacity, accentColor, height }) => ({
  position: 'relative',
  height: '100%',
  ': before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${imgUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 0',
    opacity: opacity,
    zIndex: -1,
  },
  ': after': {
    content: '""',
    width: 0,
    height: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    opacity: 0.6,
    [theme.breakpoints.down('sm')]: {
      borderBottom: `233px solid ${accentColor}`,
      borderLeft: '170px solid transparent',
    },
    [theme.breakpoints.down('md')]: {
      borderBottom: `333px solid ${accentColor}`,
      borderLeft: '220px solid transparent',
    },
    [theme.breakpoints.up('md')]: {
      borderBottom: `${height - 90}px solid ${accentColor}`,
      borderLeft: '370px solid transparent',
    },
  },
}));

export const WelcomeText = styled('div')(({ theme, isThankYou }) => ({
  color: isThankYou ? theme.palette.info.main : theme.palette.common.black,
  fontSize: '42px',
  fontWeight: 700,
  lineHeight: '39px',
  paddingTop: '20%',
  textTransform: 'uppercase',
}));

export const EventName = styled('div')(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '71px',
  fontWeight: 400,
  lineHeight: '70px',
  textTransform: 'capitalize',
}));

export const SignedInText = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '42px',
  fontWeight: 400,
  lineHeight: '39px',
  textTransform: 'uppercase',
  marginBottom: '30px',
}));

export const LabelText = styled('div')(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
  marginBottom: '15px',
}));

export const ToSText = styled('div')(({ theme }) => ({
  color: theme.palette.text.active,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  marginBottom: '15px',
  marginTop: '15px',
  '& a': {
    textDecoration: 'underline',
  },
}));
