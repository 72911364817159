import React from 'react';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';
import { isCustomer } from 'services/subscriptionUtils';

const Paragraphs = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container>
        <IntroText
          className='prose max-w-full mx-auto'
          dangerouslySetInnerHTML={renderHTML(
            isCustomer(session?.user)
              ? fields.paragraphTextwithSession
                ? fields.paragraphTextwithSession
                : fields.paragraphText
              : fields.paragraphText
          )}
        />
      </Container>
    </Box>
  );
};

export default Paragraphs;

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '5px',
    margin: '5px 0 10px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px',
    margin: '5px 2px 5px 2px',
  },
}));

const IntroText = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  margin: 5,
  '& li': {
    '&::before': {
      backgroundColor: '#000 !important',
    },
  },
}));
