import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { getIcon, getColorByAgilityName } from '../../services/agilityTranslations';
import ArticleIcon from '@mui/icons-material/Article';

const AskTheTrainerWithBullets = ({ module }) => {
  const { fields } = module;

  const [videoSelected, setVideoSelected] = useState(null);

  useEffect(() => {
    if (!videoSelected) {
      if (fields && fields.linkList && fields.linkList.length > 0) {
        setVideoSelected(fields.linkList[0]);
      }
    }
  }, [fields]);

  const [textAlign, setTextAlign] = useState('right');

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  `;

  const VideoContainer = styled.div`
    .video {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
    @media screen and (max-width: 992px) {
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
    }
    @media screen and (min-width: 992px) {
      flex-grow: 1;
      flex-shrink: 0;
      margin: 20px 0;
      max-width: 100%;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (max-width: 992px) {
      ${videoSelected ? 'width: 450px; height: 300px;' : 'display: none;'}
    }
    @media screen and (min-width: 992px) {
      ${videoSelected ? 'width: 550px; height: 280px;' : 'display: none;'}
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    margin: 40px 5px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      color: ${fields.titleColor};
      font-size: 1.75rem;
      line-height: 1.95rem;
      padding: 0 25px;
    }
    @media screen and (min-width: 768px) {
      color: ${fields.titleColor};
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  `;

  const ContentContainer = styled.div`
  align-self: flex-start;
  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
  @media screen and (min-width: 992px) {
    margin: 0 10px 0 50px;
    padding: 0 10px;
    max-width: 600px;
`;

  const ListContainer = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: 992px) {
      width: 100%;
      flex-direction: column;
    }
    @media screen and (min-width: 992px) {
      width: 75%;
      flex-direction: row;
    }
  `;

  const ListSeparator = styled.div`
    margin: 1.5% 0 1.5% 18px;
    width: 95%;
    border: 0.75px dashed #808080;
    &:last-child {
      border: 0px;
    }
  `;

  const FormContainer = styled.div`
    background-color: #dcebfa;
    border-radius: 25px;
    @media screen and (max-width: 992px) {
      width: 400px;
      height: 500px;
      margin: 15px 5px;
      align-self: center;
    }
    @media screen and (min-width: 992px) {
      width: 400px;
      height: 500px;
      margin: 16px 0 0 0;
    }
  `;

  return (
    <React.Fragment>
      <Container style={{ backgroundColor: getColorByAgilityName(fields.backgroundColor) }}>
        <ListContainer>
          <ContentContainer>
            <VideoContainer>
              {videoSelected && videoSelected.fields && videoSelected.fields.webinarEmbed && (
                <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(videoSelected.fields.webinarEmbed)} />
              )}
            </VideoContainer>

            <ResourceDescription style={{ color: fields.textColor, margin: 0, padding: '-20px 10px' }}>
              <List>
                {fields.linkList &&
                  fields.linkList.length > 0 &&
                  fields.linkList
                    .sort((a, b) => {
                      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                      return 0;
                    })
                    .map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ListItemButton style={{ width: '100%' }} align='left' onClick={(e) => setVideoSelected(item)}>
                            <ListItemIcon>{getIcon(fields.iconName, fields.iconColor, '1.10rem')}</ListItemIcon>
                            <ListText>{item.fields.webinarUrl.text}</ListText>
                          </ListItemButton>
                          <ListSeparator />
                        </React.Fragment>
                      );
                    })}
              </List>
            </ResourceDescription>
          </ContentContainer>
          <FormContainer></FormContainer>
        </ListContainer>
      </Container>
    </React.Fragment>
  );
};

AskTheTrainerWithBullets.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  try {
    let list = await api.getContentList({
      referenceName: 'videoWithBullets',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (list && list.items && list.items.length > 0) {
      contentItem = list;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load Video with Bullets video List.', error);
    return null;
  }

  return {
    fields: contentItem,
  };
};

export default AskTheTrainerWithBullets;

const ResourceIcon = styled('div')(({ theme }) => ({
  maxWidth: '80px',
  [theme.breakpoints.up('md')]: {
    padding: '5px 40px 10px 40px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ResourceTitle = styled('div')(({ theme }) => ({
  margin: '-10px 10px',
}));

const ResourceDescription = styled('div')(({ theme }) => ({
  fontWeight: 300,
  margin: '5px 10px 15px 0',
  fontSize: '0.85rem',
  lineHeight: '0.95rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 5px',
  },
}));

const ListText = styled('div')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  lineHeight: '1.10rem',
}));

const HandoutText = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '0.90rem',
  lineHeight: '1.00rem',
}));

const Description = styled('div')(({ theme }) => ({
  fontWeight: 300,
  margin: '5px 10px 15px 0',
  fontSize: '0.85rem',
  lineHeight: '0.95rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 25px',
  },
}));
