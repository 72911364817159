import { ParseMarkdownOptions, parseMarkdown } from './parseMarkdown';

export function parseMarkdownSingleLine(
    content: string,
    options?: Omit<ParseMarkdownOptions, 'allowedElements' | 'unwrapDisallowed'>
) {
    const output = parseMarkdown(content, {
        ...options,
        allowedElements: ['br', 'strong', 'sup'],
        unwrapDisallowed: true,
    });

    return output;
}
