import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { COUPON_CODE_TYPES, PRODUCTS } from 'services/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AddCouponCodeDialog = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMaxUsage, setIsMaxUsage] = useState(false);
  const [productList, setProductList] = useState([]);
  const [couponCodeDialogState, setCouponCodeDialogState] = useState({
    code: '',
    exp_date: DateTime.now().plus({ days: 90 }).toFormat('yyyy-LL-dd'),
    type: COUPON_CODE_TYPES[0].code,
    is_active: true,
    value: 0,
    max_usage: 0,
    product: '',
  });

  useEffect(() => {
    const init = () => {
      let products = [];
      PRODUCTS.forEach((r) => {
        if (r.isActive) {
          products.push(
            <MenuItem value={r.id} key={r.id}>
              {r.name}
            </MenuItem>
          );
        }
      });
      setProductList(products);

      if (props.editCode) {
        setCouponCodeDialogState({
          code: props.editCode.code,
          exp_date: DateTime.fromJSDate(new Date(props.editCode.exp_date)).toFormat('yyyy-LL-dd'),
          type: props.editCode.type,
          product: props.editCode.product,
          is_active: props.editCode.is_active,
          value: props.editCode.value ?? 0,
          max_usage: props.editCode.max_usage ?? 0,
        });
        setIsMaxUsage(props.editCode.max_usage > 0);
      } else {
        resetState();
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open, props.editCode]);

  const resetState = () => {
    setErrorMessage(null);
    setCouponCodeDialogState({
      code: '',
      exp_date: DateTime.now().plus({ days: 90 }).toFormat('yyyy-LL-dd'),
      type: COUPON_CODE_TYPES[0].code,
      is_active: true,
      value: 0,
      max_usage: 0,
      product: '',
    });
    setIsMaxUsage(false);
  };

  const closeCouponCodeDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSaveCouponCode()) {
      return;
    }

    let couponCode = JSON.parse(JSON.stringify(couponCodeDialogState));
    delete couponCode.hasError;
    delete couponCode.canSave;
    Object.keys(couponCode).forEach((key) => {
      if (key.endsWith('Error') && couponCode.hasOwnProperty(key)) {
        delete couponCode[key];
      }
    });
    if (!isMaxUsage) {
      couponCode.max_usage = 0;
    }

    couponCode.exp_date = DateTime.fromFormat(couponCode.exp_date, 'yyyy-LL-dd', {}).toJSDate().getTime();

    try {
      setIsLoading(true);
      await callApi('/api/db/coupon-code', props.action === 'Add' ? 'POST' : 'PUT', couponCode);
      resetState();
      props.onClose(doSave);
      setIsLoading(false);
    } catch (error) {
      logClientException(error);
      setIsLoading(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSaveCouponCode = () => {
    let hasError = false;
    const couponCode = couponCodeDialogState;
    if (!couponCode.code || couponCode.code.length === 0) {
      hasError = true;
      couponCode.codeError = true;
    }
    if (!couponCode.product || couponCode.product.length === 0) {
      hasError = true;
      couponCode.productError = true;
    }
    if (!couponCode.exp_date || couponCode.exp_date.length === 0) {
      hasError = true;
      couponCode.exp_dateError = true;
    }
    if (isMaxUsage && couponCode.max_usage <= 0) {
      hasError = true;
      couponCode.max_usageError = true;
    }
    if (!couponCode.value || couponCode.value <= 0) {
      hasError = true;
      couponCode.valueError = true;
    }
    if (couponCode.type === 'percent_off' && couponCode.value > 100) {
      hasError = true;
      couponCode.valueError = true;
    }
    if (hasError) {
      setCouponCodeDialogState({ ...couponCode, canSave: false });
    }
    return !hasError;
  };

  const couponCodeOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'code') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    if (field === 'is_max_usage') {
      setIsMaxUsage(value);
      if (value === false) {
        await couponCodeOnChange('max_usage', 0);
        return;
      }
    }

    setCouponCodeDialogState({ ...couponCodeDialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeCouponCodeDialog(false)}>
      <DialogTitle>{props.action} Coupon Code</DialogTitle>

      <DialogContent>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <TextField
          disabled={props.action === 'Update'}
          value={couponCodeDialogState.code}
          error={couponCodeDialogState.codeError}
          autoFocus
          required
          margin='dense'
          id='coupon-code'
          label='Coupon Code (i.e. 30DayFree2022)'
          type='text'
          fullWidth
          variant='outlined'
          onChange={(e) => couponCodeOnChange('code', e.target.value)}
          inputProps={{ maxLength: 100 }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth sx={{ marginTop: '7px' }}>
          <InputLabel required error={couponCodeDialogState.productError} id='product-select'>
            Product
          </InputLabel>
          <Select
            error={couponCodeDialogState.productError}
            labelId='product-select'
            id='product-select-value'
            value={couponCodeDialogState.product}
            label='Product'
            onChange={(e) => {
              couponCodeOnChange('product', e.target.value);
            }}
          >
            {productList}
          </Select>
        </FormControl>

        <TextField
          sx={{ marginTop: '10px' }}
          value={couponCodeDialogState.exp_date}
          error={couponCodeDialogState.exp_dateError}
          required
          margin='dense'
          id='exp-date'
          label='Expiration Date'
          type='date'
          fullWidth
          variant='outlined'
          onChange={(e) => couponCodeOnChange('exp_date', e.target.value)}
          inputProps={{ maxLength: 100 }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth sx={{ marginTop: '7px' }}>
          <FormLabel id='coupon-code-type-label'>Code Type</FormLabel>
          <RadioGroup
            aria-labelledby='coupon-code-type-label'
            name='coupon-code-type'
            value={couponCodeDialogState.type}
            onChange={(e) => couponCodeOnChange('type', e.target.value)}
          >
            {COUPON_CODE_TYPES.map((c, i) => {
              return <FormControlLabel key={c.code} value={c.code} control={<Radio />} label={c.label} />;
            })}
          </RadioGroup>
        </FormControl>

        <TextField
          value={couponCodeDialogState.value}
          error={couponCodeDialogState.valueError}
          required
          margin='dense'
          id='coupon_value'
          label={COUPON_CODE_TYPES.find((c) => c.code === couponCodeDialogState.type)?.val_label ?? 'Coupon Value'}
          type='number'
          fullWidth
          variant='outlined'
          onChange={(e) => couponCodeOnChange('value', e.target.value)}
          inputProps={{ maxLength: 4 }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControlLabel
          control={<Checkbox checked={couponCodeDialogState.is_active} onChange={(e) => couponCodeOnChange('is_active', e.target.checked)} />}
          label='Active'
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isMaxUsage}
              onChange={(e) => {
                couponCodeOnChange('is_max_usage', e.target.checked);
              }}
            />
          }
          label='Limit Usage'
        />

        <TextField
          hidden={!isMaxUsage}
          value={couponCodeDialogState.max_usage}
          error={couponCodeDialogState.max_usageError}
          autoFocus
          required={isMaxUsage}
          margin='dense'
          id='max_usage'
          label='Max times to be used'
          type='number'
          fullWidth
          variant='outlined'
          onChange={(e) => couponCodeOnChange('max_usage', e.target.value)}
          inputProps={{ maxLength: 4 }}
          InputLabelProps={{ shrink: true }}
        />

        <PleaseWait isLoading={isLoading} />
      </DialogContent>
      <DialogActions>
        <KitButton round onClick={() => closeCouponCodeDialog(false)} size='sm'>
          Cancel
        </KitButton>
        <KitButton round onClick={() => closeCouponCodeDialog(true)} size='sm' color='success' disabled={!couponCodeDialogState.canSave}>
          {props.action}
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddCouponCodeDialog.propTypes = {
  action: PropTypes.oneOf(['Add', 'Update']).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  editCode: PropTypes.object,
  onClose: PropTypes.func,
};
export default AddCouponCodeDialog;
