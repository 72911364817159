import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';

const CardLabel = ({ label }) => {
  return (
    <Typography variant='subtitle2' color='text.secondary' component='span' marginRight='5px' display='inline-block'>
      {label}
    </Typography>
  );
};

const getTitle = (val) => {
  // if (typeof val === 'string') {
  //   return val;
  // }
  return val;
};

const CardValue = ({ value }) => {
  return (
    <Typography
      variant='subtitle2'
      component='span'
      //textOverflow='ellipsis'
      //whiteSpace='nowrap'
      //overflow='hidden'
      display='inline-block'
      sx={{ width: '200px', verticalAlign: 'middle' }}
      title={getTitle(value)}
    >
      {value}
    </Typography>
  );
};

const ManageUserCard = (props) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <KitCard style={{ width: '85%', color: `${theme.palette.primary.main}` }}>
        <KitCardBody>
          <Typography variant='h6' sx={{ textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {props.user.emailAddress}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div>
                <CardLabel label='Name' />
                <CardValue value={props.user.name} />
              </div>
              <div>
                <CardLabel label='District' />
                <CardValue value={props.user.district_name} />
              </div>
              {props.user.school_name && (
                <div>
                  <CardLabel label='School' />
                  <CardValue value={props.user.school_name} />
                </div>
              )}
              {props.user.subscription_type && props.user.subscription_type.length > 0 && (
                <div>
                  <CardLabel label='Sub' />
                  <CardValue value={props.user.subscription_type} />
                </div>
              )}
              {props.user.trial_exp_date && (
                <div>
                  <CardLabel label='Exp Date' />
                  <div style={{ display: 'inline-block' }}>
                    <CardValue value={props.user.trial_exp_date} />
                  </div>
                </div>
              )}
            </Box>
          </Box>
          <KitButton style={{ float: 'right' }} round color='info' size='sm' onClick={() => props.editFunc()} title='Edit'>
            <EditIcon /> Edit
          </KitButton>
          <KitButton style={{ float: 'right' }} round color='info' size='sm' onClick={() => props.resendInviteFunc()} title='Resend Invite'>
            <RestartAltIcon /> Resend Invite
          </KitButton>
        </KitCardBody>
      </KitCard>
    </Box>
  );
};

ManageUserCard.propTypes = {
  user: PropTypes.object.isRequired,
  editFunc: PropTypes.func.isRequired,
  resendInviteFunc: PropTypes.func.isRequired,
  extendTrialFunc: PropTypes.func.isRequired,
};

export default ManageUserCard;
