export const TEXT_SIZE = new Map();
TEXT_SIZE.set('xs', { size: '12px', lineHeight: '20px' });
TEXT_SIZE.set('sm', { size: '14px', lineHeight: '20px' });
TEXT_SIZE.set('md', { size: '16px', lineHeight: '24px' });
TEXT_SIZE.set('lg', { size: '18px', lineHeight: '24px' });
TEXT_SIZE.set('xl', { size: '20px', lineHeight: '28px' });
TEXT_SIZE.set('2xl', { size: '24px', lineHeight: '32px' });
TEXT_SIZE.set('3xl', { size: '28px', lineHeight: '40px' });

export const getContentStyle = (theme, fields) => {
  return {
    textColor: fields?.textColor ? theme.palette.text[fields.textColor] : theme.palette.text.primary,
    alignment: fields?.alignment,
    fontSize: fields?.textSize ? TEXT_SIZE.get(fields?.textSize).size : TEXT_SIZE.get('md').size,
    lineHeight: fields?.textSize ? TEXT_SIZE.get(fields?.textSize).lineHeight : TEXT_SIZE.get('md').lineHeight,
  };
};
