import React from 'react';
import TestimonialList from 'components/common/TestimonialList';

const TestimonialListing = (props) => {
  const { customData, module, page } = props;
  const listProps = {
    list: customData.testimonials,
    page,
  };

  return <TestimonialList {...listProps} />;
};

const resolvePostUrls = function (sitemap, posts) {
  let dynamicUrls = {};
  posts.forEach((post) => {
    Object.keys(sitemap).forEach((path) => {
      if (sitemap[path].contentID === post.contentID) {
        dynamicUrls[post.contentID] = path;
      }
    });
  });
  return dynamicUrls;
};

// This is called during server-side rendering (not part of NextJS).
TestimonialListing.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  const api = agility;

  try {
    //get sitemap first - THIS MUST BE INCLUDED!
    let sitemap = await api.getSitemapFlat({ channelName: channelName, languageCode });

    // get modules
    // TODO - paging...
    let raw = await api.getContentList({
      referenceName: 'testimonials',
      languageCode,
      contentLinkDepth: 2,
      depth: 2,
      sort: 'fields.featured',
      take: 3,
    });

    const dynamicUrls = resolvePostUrls(sitemap, raw.items);

    let testimonials = raw.items.map((event) => {
      const url = dynamicUrls[event.contentID];
      if (url == undefined) {
        console.log('Failed to get url for ' + event.contentID + '  ' + event.fields.eventName);
      }
      return event;
    });

    return {
      testimonials,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default TestimonialListing;
