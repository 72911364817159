import React, { useState, useEffect } from 'react';

const ProductStorage = (props) => {
  const { customData, module } = props;
  const { fields } = module;

  useEffect(() => {
    window.localStorage && window.localStorage.setItem('product-name', fields.product);
  }, [fields]);
  return <></>;
};

export default ProductStorage;
