import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { KitGridContainer, KitGridItem, KitTable } from '@boystownorg/bi-cms-component-lib';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';

import CustomFilter from '../../common/subcomponents/CustomFilter';
import SortableTableHeader from '../../common/subcomponents/SortableTableHeader';
import { compareValues } from 'services/stringUtils';

const ClientSideTable = (props) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [sortOptions, setSortOptions] = useState({ field: 0, direction: 'ASC' });
  const [paginationOptions, setPaginationOptions] = useState({
    searching: false,
    page: 0,
    perPage: 10,
    totalRecords: 0,
  });

  useEffect(() => {
    if (props.records) {
      setFilteredRecords(props.records.slice());
      setPaginationOptions({ ...paginationOptions, totalRecords: props.records.length });
    } else {
      setFilteredRecords([]);
    }
  }, [props.records]);

  const filterOnChange = async (data) => {
    if (Object.keys(data).length === 0) {
      setPaginationOptions({ ...paginationOptions, page: 0, totalRecords: props.records.length });
      setFilteredRecords(props.records);
      return;
    }
    let filterRecs = props.records.slice();
    for (const key in data) {
      if (data[key].length > 0) {
        filterRecs = filterRecs.filter((r) => {
          if (typeof r[key] === 'string') {
            return r[key].toLowerCase().indexOf(data[key].toLowerCase()) > -1;
          } else {
            return r[key] === data[key];
          }
        });
      }
    }
    setPaginationOptions({ ...paginationOptions, page: 0, totalRecords: filterRecs.length });
    setFilteredRecords(filterRecs);
  };

  const onSortChange = async (field, direction) => {
    let options = JSON.parse(JSON.stringify(sortOptions));
    if (options.field === field) {
      options.direction = options.direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      options.field = field;
      options.direction = direction ? direction : 'ASC';
    }

    setSortOptions(options);
    let sortedRecs = [];
    if (filteredRecords.length > 0) {
      sortedRecs = filteredRecords.slice();
      sortedRecs.sort(compareValues(options.field, options.direction));
    }
    setFilteredRecords(sortedRecs);
  };

  const getSortDirection = (field) => {
    return sortOptions.field === field ? sortOptions.direction : null;
  };

  return (
    <React.Fragment>
      {props.customFilterFields && <CustomFilter fields={props.customFilterFields} onChange={filterOnChange} />}

      {props.preTableContent}
      <KitGridContainer direction='row' align='center'>
        <KitGridItem xs={12}>
          <TableContainer>
            <KitTable
              striped
              hover
              tableData={filteredRecords.slice(
                paginationOptions.page * paginationOptions.perPage,
                paginationOptions.page * paginationOptions.perPage + paginationOptions.perPage
              )}
              tableHead={props.headerCols.map((c, i) => {
                if (c.isSortable) {
                  return (
                    <SortableTableHeader
                      key={c.field}
                      text={c.text}
                      field={c.field}
                      sortDirection={getSortDirection(c.field)}
                      onSortChange={(f, d) => onSortChange(f, d)}
                    />
                  );
                } else {
                  return '';
                }
              })}
            ></KitTable>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={paginationOptions.totalRecords}
              rowsPerPage={paginationOptions.perPage}
              page={paginationOptions.page}
              onPageChange={(ev, val) => {
                setPaginationOptions({ ...paginationOptions, page: val });
              }}
              onRowsPerPageChange={(ev) => {
                const perPage = parseInt(ev.target.value, 10);
                setPaginationOptions({ ...paginationOptions, perPage, page: 0 });
              }}
              showFirstButton
              showLastButton
            />
          </TableContainer>
        </KitGridItem>
      </KitGridContainer>
    </React.Fragment>
  );
};

ClientSideTable.propTypes = {
  customFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string || PropTypes.number,
      label: PropTypes.string,
    })
  ),
  preTableContent: PropTypes.node,
  records: PropTypes.array.isRequired,
  headerCols: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      field: PropTypes.string || PropTypes.number,
      isSortable: PropTypes.bool,
    })
  ),
};

export default ClientSideTable;
