import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { PRODUCTS } from 'services/constants';
import Heading6 from '../Typography/Heading6';
import { RightChevron, LeftChevron } from '../SvgIcons';

const BillingHistory = (props) => {
  const { userRecord } = props;
  const theme = useTheme();
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [tableRows, setTableRows] = useState(null);

  useEffect(() => {
    if (userRecord?.subscriptions) {
      let tempRows = [];
      userRecord.subscriptions.forEach((sub) => {
        if (sub.transaction) {
          tempRows.push({
            date: DateTime.fromFormat(sub.transaction.dateTime, 'M/d/yyyy h:mm:ss a').toFormat('MM/dd/yyyy'),
            description: PRODUCTS.find((p) => p.id === sub.product)?.trans_desc ?? sub.product,
            paymentMethod: `${sub.transaction.accountType} ${sub.transaction.accountNumber}`,
            amount: `$${sub.transaction.totalAmount}`,
            sub,
            key: sub.transaction.refId,
          });
        }
      });
      tempRows = tempRows.sort((a, b) => {
        if (
          DateTime.fromFormat(a.sub.transaction.dateTime, 'M/d/yyyy h:mm:ss a') >
          DateTime.fromFormat(b.sub.transaction.dateTime, 'M/d/yyyy h:mm:ss a')
        )
          return -1;
        if (
          DateTime.fromFormat(a.sub.transaction.dateTime, 'M/d/yyyy h:mm:ss a') <
          DateTime.fromFormat(b.sub.transaction.dateTime, 'M/d/yyyy h:mm:ss a')
        )
          return 1;
        return 0;
      });

      setTableRows(tempRows);
      setMaxPage((tempRows.length / pageSize).toFixed(0));
    }
  }, [userRecord]);

  const getTableRows = () => {
    if (tableRows && tableRows.length > 0) {
      let tempRows = [];
      const start = currentPage * pageSize;
      const end = start + pageSize > tableRows.length ? tableRows.length : start + pageSize;
      for (let i = start; i < end; i++) {
        const row = tableRows[i];
        tempRows.push(
          <TableRow key={row.key} sx={{ border: 0 }}>
            <TD sx={{ color: theme.palette.brandedAqua.main }}>{row.date}</TD>
            <TD>{row.description}</TD>
            <TD>{row.paymentMethod}</TD>
            <TD>{row.amount}</TD>
            <TD align='right' sx={{ color: theme.palette.secondary.light }}>
              <a
                href={`/api/sub-invoice?id=${userRecord?.id}&ref=${row.key}`}
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'underline', fontSize: '16px' }}
              >
                VIEW INVOICE
              </a>
            </TD>
          </TableRow>
        );
      }
      return tempRows;
    }
    return null;
  };

  return (
    <>
      <Box sx={{ mb: '20px' }}>
        <Heading6>Billing History</Heading6>
      </Box>

      <DesktopContainer>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='billing history table'>
            <TableHead>
              <TableRow sx={{ border: 0 }}>
                <TH>Date</TH>
                <TH>Description</TH>
                <TH>Payment Method</TH>
                <TH>Amount</TH>
                <TH></TH>
              </TableRow>
            </TableHead>
            <TableBody>{getTableRows()}</TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: '20px' }}>
          <span
            style={{
              color: currentPage === 0 ? theme.palette.text.disabled : theme.palette.text.active,
              cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
            }}
            disabled={currentPage === 0}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <LeftChevron /> Prev&nbsp;
          </span>
          <span
            style={{
              color: currentPage >= maxPage ? theme.palette.text.disabled : theme.palette.text.active,
              cursor: currentPage >= maxPage ? 'not-allowed' : 'pointer',
            }}
            disabled={currentPage >= maxPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next <RightChevron />
          </span>
        </Box>
      </DesktopContainer>
      <MobileContainer>
        <KitGridContainer direction='row' style={{ width: '100%' }}>
          {tableRows &&
            tableRows.map((row, i) => {
              return (
                <React.Fragment key={row.key + i}>
                  <KitGridItem xs={12} style={{ marginBottom: '10px' }} key={row.key + i}>
                    <Box sx={{ color: theme.palette.brandedAqua.main }}>{row.date}</Box>
                  </KitGridItem>
                  <KitGridItem xs={8} align='left' style={{ marginBottom: '10px' }}>
                    <Box sx={{ width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={row.description}>
                      {row.description}
                    </Box>
                  </KitGridItem>
                  <KitGridItem xs={4} align='right' style={{ marginBottom: '10px' }}>
                    <Box>{row.amount}</Box>
                  </KitGridItem>
                  <KitGridItem xs={6} align='left' style={{ marginBottom: '10px' }}>
                    <Box sx={{ color: theme.palette.secondary.light }}>{row.paymentMethod}</Box>
                  </KitGridItem>
                  <KitGridItem xs={6} align='right' style={{ marginBottom: '10px' }}>
                    <Box sx={{ color: theme.palette.secondary.light }}>
                      <a
                        href={`/api/sub-invoice?id=${userRecord?.id}&ref=${row.key}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: 'underline' }}
                      >
                        VIEW INVOICE
                      </a>
                    </Box>
                  </KitGridItem>
                  <KitGridItem xs={12} style={{ borderBottom: '.5px solid #000', margin: '10px 10px 20px 10px' }} />
                </React.Fragment>
              );
            })}
        </KitGridContainer>
      </MobileContainer>
    </>
  );
};

export default BillingHistory;

const DesktopContainer = styled('div')(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '28px',
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileContainer = styled('div')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '100%',
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const TH = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '12px',
  padding: '10px',
  border: 'none',
}));

const TD = styled(TableCell)(({ theme }) => ({
  border: 'none',
  padding: '10px',
}));
