import React from 'react';
import Typography from '@mui/material/Typography';

const Heading6 = (props) => {
  const { color, children, ...rest } = props;
  return (
    <Typography {...rest} component='h6' color={color} fontSize='20px' fontWeight='700' lineHeight='25px'>
      {children}
    </Typography>
  );
};

export default Heading6;
