import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';

const Quote = ({ module }) => {
  const { fields } = module;

  return (
    <Container>
      <KitGridContainer direction='row'>
        <KitGridItem xs={false} sm={false} md={false} lg={2} align='right'>
          <QuoteBraces>{`\{`}</QuoteBraces>
        </KitGridItem>
        <KitGridItem xs={false} sm={12} md={12} lg={8} align='left'>
          <QuoteContainer>
            <QuoteText>{fields.quotation}</QuoteText>
            <QuoteAttributed>{fields.attributed}</QuoteAttributed>
          </QuoteContainer>
        </KitGridItem>
        <KitGridItem xs={false} sm={false} md={false} lg={2} align='left'>
          <QuoteBraces>{`\}`}</QuoteBraces>
        </KitGridItem>
      </KitGridContainer>
    </Container>
  );
};

export default Quote;

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '40px 5% 35px 5%',
  },
  [theme.breakpoints.down('md')]: {
    margin: '5%',
  },
}));

const QuoteContainer = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  margin: '40px 5% 35px 5%',
  [theme.breakpoints.down('md')]: {
    // display: 'none',
  },
}));

const QuoteBraces = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: '8.00rem',
    color: theme.palette.error.main,
    selfAlign: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const QuoteText = styled('div')(({ theme }) => ({
  fontSize: '1.65rem',
  lineHeight: '1.85rem',
  color: theme.palette.primary.light,
  margin: '10px 5px',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
    lineHeight: '1.35rem',
  },
}));

const QuoteAttributed = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  fontSize: '1.00rem',
  lineHeight: '1.15rem',
  fontStyle: 'italic',
  color: theme.palette.primary.light,
  margin: '5px 20px',
  alignSelf: 'flex-end',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.80rem',
    lineHeight: '1.00rem',
  },
}));
