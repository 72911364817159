import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useSession } from 'next-auth/react';
import { useTheme } from '@mui/material/styles';

const ActionVideoWithButton = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const router = useRouter();
  const { data: session, status } = useSession();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 993px) {
      position: relative;
      ${fields.videoLocation === 'left' ? 'justify-content: right;' : 'justify-content: left;'}
      ${fields.videoLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
      min-height: 420px;
    }
    @media screen and (max-width: 992px) {
      padding: 0 30px;
      height: 620px;
    }
    @media screen and (max-width: 602px) {
      padding: 0 10px;
      height: 500px;
    }
  `;

  const EmptyContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (min-width: 993px) {
      padding: 60px 10px;
      ${fields.videoLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
      ${fields.videoLocation === 'center' ? 'max-width: 20%;' : 'max-width: 50%;'}
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  const FloatingButton = styled.div`
    vertical-align: top;
    padding: 60px 10px;
    position: absolute;
    top: 56%;
    ${fields.videoLocation === 'left' ? 'left: 62%;' : 'left: 35%;'}
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  const MobileFloatingButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 330px 10px 0 10px;
    @media screen and (min-width: 992px) {
      display: none;
    }
  `;

  const VideoContainer = styled.div`
    .video {
      aspect-ratio: 16 / 9;
    }
    @media screen and (min-width: 993px) {
      position: absolute;
      width: 550px;
      height: auto;
      top: 15%;
      right: 40%;
      margin-right: 2%;
    }
    @media screen and (max-width: 992px) {
      position: absolute;
      width: 95%;
      height: auto;
      top: 135px;
      right: 3%;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (min-width: 993px) {
      margin: 0 0 50px 0;
      width: 95%;
    }
    @media screen and (max-width: 992px) {
      width: 110%;
    }
  `;

  const TitleContainer = styled.div`
    @media screen and (min-width: 993px) {
      ${fields.videoLocation === 'left' ? 'left: 62.45%;' : 'left: 1%;'}
      position: absolute;
      top: 18%;
      width: 285px;
    }
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 35px;
      left: 1%;
      width: 100%;
      height: 100px;
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (min-width: 993px) {
      ${fields.videoLocation === 'left' ? 'text-align: left;' : 'text-align: right;'}
      font-size: 3.375rem;
      line-height: 2.688rem;
    }
    @media screen and (max-width: 992px) {
      font-size: 2rem;
      line-height: 2.25rem;
      text-align: center;
    }
  `;

  return (
    <React.Fragment>
      <Container>
        <EmptyContainer></EmptyContainer>
        <VideoContainer>
          {fields.videoEmbed && <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoEmbed)} />}
        </VideoContainer>
        <TitleContainer>
          <Title>{fields.title}</Title>
        </TitleContainer>
        {!session && fields.showButton === 'true' && (
          <>
            <FloatingButton>
              <Link href={fields.buttonUrl.href} passHref legacyBehavior>
                <KitButton
                  round
                  size='lg'
                  style={{
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <a href={fields.buttonUrl.href} target={fields.buttonUrl.target} rel='noopener noreferrer'>
                    {fields.buttonText}
                  </a>
                </KitButton>
              </Link>
            </FloatingButton>
            <MobileFloatingButton>
              <Link href={fields.buttonUrl.href} passHref legacyBehavior>
                <KitButton
                  round
                  style={{
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <a href={fields.buttonUrl.href} target={fields.buttonUrl.target} rel='noopener noreferrer'>
                    {fields.buttonText}
                  </a>
                </KitButton>
              </Link>
            </MobileFloatingButton>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

export default ActionVideoWithButton;
