// package imports
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';

// local imports
import { HandIcon, HeartIcon, CheckmarkIcon } from 'components/common/subcomponents/SvgIcons';

const BenchmarkGroup = ({ type, total, children }) => {
  const theme = useTheme();

  const getIcon = (type) => {
    if (type === 'Prevention') {
      return <HandIcon fillColor={theme.palette.common.white} />;
    } else if (type === 'Praise') {
      return <HeartIcon fillColor={theme.palette.common.white} />;
    }
    if (type === 'Correction') {
      return <CheckmarkIcon fillColor={theme.palette.common.white} />;
    }
  };

  return (
    <Box sx={{ backgroundColor: theme.palette[`obs${type}`].light, borderRadius: '8px', marginBottom: '1rem' }}>
      <Box
        sx={{
          backgroundColor: theme.palette[`obs${type}`].main,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '1.7rem',
          paddingRight: '1.7rem',
          height: '60px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getIcon(type)}
          <Typography
            component='span'
            sx={{ marginLeft: '1rem', color: theme.palette.common.white, fontWeight: 600, fontSize: '20px', lineHeight: '25px' }}
          >
            {type}
          </Typography>
        </Box>
        <Typography sx={{ color: theme.palette.common.white, fontWeight: 600, fontSize: '20px', lineHeight: '25px' }}>{total}</Typography>
      </Box>
      <div>{children}</div>
    </Box>
  );
};

export default BenchmarkGroup;
