import React, { useState } from 'react';
import { useSession } from 'next-auth/react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';
import Link from 'next/link';
import FreeResourceNewIndicator from 'components/common/FreeResourceNewIndicator';
import { FreeResourceNewCountTag } from '../common/FreeResourceNewIndicator';

const ResourceList = ({ module }) => {
  const { fields } = module;
  console.log('..........ResourceList fields: ', fields);

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
  `;

  const PageFlow = styled.div`
    width: 100%;
    display: flex;
    @media screen and (max-width: 991px) {
      width: 90%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  `;

  const TitleBox = styled.div`
    margin: 40px 0 20px 0;
    display: flex;
    justify-content: center;
  `;

  const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
    @media screen and (min-width: 992px) {
      width: 33%;
      margin: 0 20px;
    }
  `;

  const ContentContainer = styled.div`
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (min-width: 992px) {
      width: 33%;
      margin: 0 20px;
    }
  `;

  const ListText = styled.div`
    cursor: pointer;
    color: theme.palette.common.black;
    font-size: 1.125rem;
    line-height: 1.525rem;
    margin: 15px 0;
  `;

  const ResourceListItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    border-bottom: 2px dashed #037ac4;
    @media screen and (max-width: 991px) {
    }
    @media screen and (min-width: 992px) {
      &:last-child {
        border-bottom: none;
      }
    }
  `;

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container>
        <a id={fields.anchor}>
          <TitleBox>
            <Heading4 color='#000000'>{fields.title}</Heading4>
          </TitleBox>
        </a>
        <PageFlow>
          {fields.column1Icon && fields.column1Icon.url && fields.column1Icon.url.length > 0 ? (
            <ImageContainer>
              <ResourceFrame key={post.contentID}>
                <ResourceImg alt='icon' src={fields.column1Icon.url} width='160px' />
              </ResourceFrame>
            </ImageContainer>
          ) : (
            <ContentContainer>
              {fields.column1Links &&
                fields.column1Links.length > 0 &&
                fields.column1Links
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                    if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      <Link key={item.contentID} href={`/free-resources/${item.fields.slug}`} passHref legacyBehavior>
                        <ResourceListItem>
                          {item.fields?.isNew === 'true' && <FreeResourceNewIndicator>NEW</FreeResourceNewIndicator>}
                          <ListText>{item.fields.linkName}</ListText>
                        </ResourceListItem>
                      </Link>
                    );
                  })}
            </ContentContainer>
          )}
          {fields.column2Icon && fields.column2Icon.url && fields.column2Icon.url.length > 0 ? (
            <ImageContainer>
              <ResourceFrame key={post.contentID}>
                <ResourceImg alt={fields.column2Icon.label} src={fields.column2Icon.url} width='160px' />
              </ResourceFrame>
            </ImageContainer>
          ) : (
            <ContentContainer>
              {fields.column2Links &&
                fields.column2Links.length > 0 &&
                fields.column2Links
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                    if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      <Link key={item.contentID} href={`/free-resources/${item.fields.slug}`} passHref legacyBehavior>
                        <ResourceListItem>
                          {item.fields?.isNew === 'true' && <FreeResourceNewIndicator>NEW</FreeResourceNewIndicator>}
                          <ListText>{item.fields.linkName}</ListText>
                        </ResourceListItem>
                      </Link>
                    );
                  })}
            </ContentContainer>
          )}
          {fields.column3Icon && fields.column3Icon.url && fields.column3Icon.url.length > 0 ? (
            <ImageContainer>
              <ResourceFrame key={post.contentID}>
                <ResourceImg alt={fields.column3Icon.label} src={fields.column3Icon.url} width='160px' />
              </ResourceFrame>
            </ImageContainer>
          ) : (
            <ContentContainer>
              {fields.column3Links &&
                fields.column3Links.length > 0 &&
                fields.column3Links
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                    if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                      <Link key={item.contentID} href={`/free-resources/${item.fields.slug}`} passHref legacyBehavior>
                        <ResourceListItem>
                          {item.fields?.isNew === 'true' && <FreeResourceNewIndicator>NEW</FreeResourceNewIndicator>}
                          <ListText>{item.fields.linkName}</ListText>
                        </ResourceListItem>
                      </Link>
                    );
                  })}
            </ContentContainer>
          )}
        </PageFlow>
      </Container>
    </Box>
  );
};

export default ResourceList;

const ResourceImg = styled('img')(({ theme }) => ({
  backgroundColor: '#B8C7CF',
  display: 'block',
  height: '100%',
  width: 'auto',
}));

const ResourceFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '50px solid #B8C7CF',
  backgroundColor: '#B8C7CF',
  borderRadius: '6px',
  height: '160px',
  width: '160px',
}));

const ResourceSeparator = styled('div')(({ theme }) => ({
  fontWeight: 100,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
    lineHeight: '0.40rem',
    padding: '0 25px 0 30px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '0.45rem',
    padding: '0 25px 0 10px',
  },
}));
