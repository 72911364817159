import { useTheme } from '@mui/material/styles';
import { parseHighlight } from './SearchUtils';

const SearchResultItem = ({ id, item, index, click }) => {
  const theme = useTheme();

  return (
    <a href={item.url} target='_self' onClick={click(id, index + 1)} key={id} title={item.title}>
      <article style={{ paddingTop: '2rem', paddingBottom: '2rem', borderTop: '1px solid rgb(215 215 215)' }}>
        <div style={{ fontSize: '20px', lineHeight: '28px', fontWeight: 600, color: theme.palette.primary.main }}>{item.title}</div>
        <div style={{ textOverflow: 'ellipsis', color: 'rgb(107 107 107)' }}>{item.url}</div>
        {item.highlight && <div style={{ marginTop: '.25rem' }}>{parseHighlight(item.highlight)}&hellip;</div>}
      </article>
    </a>
  );
};

export default SearchResultItem;
