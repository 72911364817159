import { parseHtml } from 'services/parseUtils';

export function FreeformContent({ children }) {
  const html = parseHtml(children, {
    components: {
      a: ({ href, ...props }) => {
        const isExternal = /^https?:\/\//.test(href);

        return <a href={href} target={isExternal ? '_blank' : undefined} {...props} rel='noreferrer' />;
      },
    },
  });

  return html;
}
