import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const SectionTitle = ({ module }) => {
  const { fields } = module;

  return (
    <Container>
      <Heading3 color='#000' underline={true} uppercase={true}>
        {fields.title}
      </Heading3>
    </Container>
  );
};

export default SectionTitle;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '50px 0 20px 0',
}));
