import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const StackedImagesWithText = ({ module }) => {
  const { fields } = module;

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const Container = styled.div`
    height: inherit;
    width: 100%;
    padding: 10px 0;
    position: relative;
    @media only screen and (min-width: 600px) {
      height: 660px;
    }
    @media only screen and (min-width: 768px) {
      height: 700px;
    }
    @media only screen and (min-width: 992px) {
      height: 760px;
    }
    @media only screen and (min-width: 1200px) {
      height: 860px;
    }
    @media only screen and (min-width: 1536px) {
      height: 860px;
    }
  `;

  const ContentContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    @media only screen and (min-width: 600px) {
      max-width: 640px;
    }
    @media only screen and (min-width: 992px) {
      max-width: 768px;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 1120px;
    }
    @media only screen and (min-width: 1536px) {
      max-width: 1200px;
    }
  `;

  const ContentText = styled.div`
    position: inherit;
    margin: 20px 10px 10px 10px;

    line-height: 30px;
    font-size: 18px;
    color: #333333;

    @media only screen and (min-width: 768px) {
      position: absolute;
      right: 20%;
      top: 100px;
      margin-top: 10px;
    }
    @media only screen and (min-width: 992px) {
      right: 40%;
      margin-top: 80px;
    }
    @media only screen and (min-width: 1200px) {
      right: 50%;
      margin-top: 80px;
    }
    @media only screen and (min-width: 1536px) {
      right: 50%;
    }
  `;

  const ImageContainer = styled.div`
    position: absolute;
    left: 10%;
    z-index: -1;
    width: 100%;
    max-width: 1200px;
    display: none;
    @media only screen and (min-width: 992px) {
      top: 36%;
      display: block;
    }
    @media only screen and (min-width: 768px) {
      left: 45%;
      top: 30px;
    }
    @media only screen and (min-width: 992px) {
      left: 45%;
      top: 30px;
    }
  `;

  const LayeredImage = styled.img`
    width: 700px;
    @media only screen and (min-width: 768px) {
      width: auto;
    }
  `;

  return (
    <React.Fragment>
      <Container>
        <TitleContainer>
          <TitleBox>
            <Heading3 color={fields.textColor} underline={true} uppercase={true}>
              Research on the Boys Town
              <br />
              Education Model®
            </Heading3>
          </TitleBox>
        </TitleContainer>
        <ContentContainer>
          <ContentText>
            <div className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.description)} />
            <Link href={fields.downloadUrl.href} passHref legacyBehavior>
              <a target='_blank' rel='noopener noreferrer'>
                <DownloadLink>{fields.downloadText}</DownloadLink>
              </a>
            </Link>
          </ContentText>
        </ContentContainer>
        <ImageContainer>
          {fields.stackedSingleImage && <LayeredImage src={fields.stackedSingleImage?.url} alt={fields.stackedSingleImage?.text} />}
        </ImageContainer>
      </Container>
    </React.Fragment>
  );
};

export default StackedImagesWithText;

const DownloadLink = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#0375BA',
  width: '100%',
  textDecoration: 'none',
  display: 'block',
  marginTop: '60px',
  transition: 'color 0.2s',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    lineHeight: '1.563rem',
    marginTop: '80px',
    maxWidth: '200px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.16rem',
    marginTop: '40px',
    maxWidth: '380px',
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TitleBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 700,
    margin: '30px 0 10px 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: '25px 0 10px 0',
  },
}));
