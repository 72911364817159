import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';

const ConfirmEndDialog = (props) => {
  const theme = useTheme();

  return (
    <Dialog maxWidth='sm' scroll='body' fullWidth={true} open={props.open} PaperComponent={DraggablePaperComponent}>
      <DialogTitle sx={{ backgroundColor: theme.palette.obsTimer.dark, color: theme.palette.common.black, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Confirm Observation End</Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem' }}>Are you sure you want to end the observation?</Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <KitButton
          size='sm'
          className='need-interaction'
          style={{
            backgroundColor: buttonColor(theme, 'secondary.light'),
            height: '45px',
            width: '90px',
          }}
          onClick={() => props.closeConfirm()}
        >
          No
        </KitButton>
        <KitButton
          size='sm'
          className='need-interaction'
          style={{
            backgroundColor: buttonColor(theme, 'obsBlue.dark'),
            height: '45px',
            width: '90px',
          }}
          onClick={() => props.endObservation()}
        >
          Yes
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmEndDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeConfirm: PropTypes.func,
  endObservation: PropTypes.func,
};
export default ConfirmEndDialog;
