import qs from 'qs';

const YOUTUBEMATCH_URL = /^https?:\/\/(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL =
  /^https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

/**
 * @typedef {object} VideoOptions
 * @prop {boolean} [autoPlay]
 * @prop {string} [locale]
 */

/**
 * @param {string} url
 */
function isYoutube(url) {
  return YOUTUBEMATCH_URL.test(url);
}

/**
 * @param {string} url
 */
function isVimeo(url) {
  return VIMEOMATCH_URL.test(url);
}

/**
 * @param {string} url
 * @param {VideoOptions} [options]
 */
function getYoutubeSrc(url, options) {
  const [, id] = url.match(YOUTUBEMATCH_URL);

  const query = qs.stringify({
    version: 3,
    autoplay: options?.autoPlay ? 1 : 0,
    loop: options?.loop ? 1 : 0,
    mute: options?.autoPlay ? 1 : 0,
    modestbranding: 1,
    hl: options?.locale,
  });

  return {
    type: 'iframe',
    url: `${YOUTUBE_PREFIX}${id}?${query}`,
  };
}

/**
 * @param {string} url
 * @param {VideoOptions} [options]
 */
function getVimeoSrc(url, options) {
  const [, , , id] = url.match(VIMEOMATCH_URL);

  const query = qs.stringify({
    autoplay: options?.autoPlay ? 1 : 0,
    loop: options?.loop ? 1 : 0,
    muted: options?.autoPlay ? 1 : 0,
    byline: 0,
    transparent: 1,
  });

  return {
    type: 'iframe',
    url: `${VIMEO_PREFIX}${id}?${query}`,
  };
}

/**
 * @param {string} url
 * @param {VideoOptions} [options]
 */
export const getVideoSrc = (url, options) => {
  if (isYoutube(url)) {
    return getYoutubeSrc(url, options);
  }

  if (isVimeo(url)) {
    return getVimeoSrc(url, options);
  }

  return {
    type: 'video',
    url,
  };
};
