import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useSession } from 'next-auth/react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { logServerException } from 'appinsights/clientAppInsights';
import GatedModuleCard from 'components/common/GatedModuleCard';
import CardGrid from 'components/common/subcomponents/CardGrid';
import { hasModuleAccess } from '../../services/subscriptionUtils';
import PleaseWait from '../common/PleaseWait';
import { logClientException } from '../../appinsights/clientAppInsights';

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const GatedWMSModuleListing = (props) => {
  const { customData, module, page } = props;

  const theme = useTheme();

  const [canAccessModules, setCanAccessModules] = useState(false);
  const [modules, setModules] = useState();
  const [modulesSet, setModulesSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { data: session, status } = useSession();

  useEffect(() => {
    let moduleArray = [];
    customData.mods &&
      customData.mods.length > 0 &&
      customData.mods.forEach((mod, i) => {
        moduleArray.push(mod);
      });
    setModules(moduleArray);
    setModulesSet(true);
  }, [module, customData]);

  useEffect(() => {
    setCanAccessModules(hasModuleAccess(session, 'wms'));
  }, [session]);

  useEffect(() => {
    if (modulesSet && canAccessModules) {
      fetchUserRegistrations();
    }
  }, [session?.user?.email, modulesSet, canAccessModules]);

  const getFetchOptions = (method, body) => {
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  };

  const fetchUserRegistrations = async (isOnClose = false) => {
    try {
      if (!session?.user?.email) {
        return;
      }
      setIsLoading(true);

      // get tags for all modules, since we need to check completion on all submodules
      const allSubMods = [];
      modules.forEach((mod) => {
        mod?.subMods?.forEach((sub) => {
          allSubMods.push(sub);
        });
      });
      const allTags = [];
      allSubMods.forEach((sub) => {
        sub.fields?.parentModuleTags?.split(',').forEach((tag) => {
          if (tag) {
            allTags.push(tag);
          }
        });
      });

      const body = {
        email: session?.user?.email,
        tags: allTags,
      };

      // fetch all registrations for the user
      let regs = [];
      const res = await fetch('/api/scorm/fetch-registrations', getFetchOptions('POST', body));
      if (res && res.ok && res.status === 200) {
        const bodyJson = await res.json();
        regs = bodyJson.registrations;

        let more = bodyJson.more;

        while (more && more.length > 0) {
          const newBody = {
            email: session?.user?.email,
            more: more,
          };
          const resmore = await fetch('/api/scorm/fetch-registrations', getFetchOptions('POST', newBody));
          if (resmore.ok && resmore.status === 200) {
            const morebodyJson = await resmore.json();
            const moreregs = morebodyJson.registrations;

            moreregs.forEach((morereg, idx) => {
              regs.push(morereg);
            });

            const moreloop = morebodyJson.more;
            more = moreloop;
          }
        }
      }

      // now we have the user's registrations.
      // loop through registrations and mark sub completion status
      regs
        .sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        })
        .forEach((reg) => {
          const sub = allSubMods.find((s) => s.fields?.scormCourse?.id === reg.course.id);
          // Handle multiple records for single sub-module:
          // Only update if the instance is > than what is already there (this handles updates to courses)
          if (sub && (sub.instance < reg.instance || !sub.instance)) {
            sub.instance = reg.instance;
            sub.registrationCompletion = reg.registrationCompletion;
            sub.registrationSuccess = reg.registrationSuccess;
          }
        });

      modules?.forEach((mod) => {
        let allComplete = true;
        let allNotStarted = true;

        mod?.subMods?.forEach((sub) => {
          // Checking registrationCompletion values for all COMPLETED or all UNDEFINED (Not Started)
          switch (sub.registrationCompletion) {
            case 'COMPLETED':
              if (sub.registrationSuccess === 'UNKNOWN' || sub.registrationSuccess === 'PASSED') {
                // passed
              } else {
                allComplete = false;
              }
              allNotStarted = false;
              break;
            case 'INCOMPLETE':
              allComplete = false;
              allNotStarted = false;
              break;
            default:
              allComplete = false;
              break;
          }
        });
        // Evaluate the vars to determine state
        if (allComplete) {
          mod.state = 'Completed';
        } else if (allNotStarted) {
          mod.state = 'Start';
        } else {
          mod.state = 'Continue';
        }
      });

      setIsLoading(false);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      console.log(error);
      logClientException(error);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const Container = styled.div`
    margin-top: 30px;
    background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0.2) 30.21%);
    padding-bottom: 50px;
  `;

  const TitleContainer = styled.div`
    ${module.fields.hideTitleWhenSubscriberExists === 'true' ? 'display: none;' : 'display: flex;'}
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const Title = styled.div`
    margin: 25px 20px 0 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px dashed #000;
    @media screen and (min-width: 992px) {
      color: ${theme.palette.common.black};
      font-size: 2.3rem;
      line-height: 2.525rem;
    }
    @media screen and (max-width: 992px) {
      color: ${theme.palette.common.black};
      font-size: 2.625rem;
      line-height: 2.95rem;
    }
  `;

  return (
    <>
      {session && canAccessModules && (
        <Container>
          <PleaseWait isLoading={isLoading} />
          <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>

          <TitleContainer>
            <Title>{module.fields.title}</Title>
          </TitleContainer>
          <Box
            sx={{
              width: '100%',
              ml: 'auto',
              mr: 'auto',
              maxWidth: { sm: '540px', md: '768px', lg: '1200px', xl: '1200px' },
            }}
          >
            <CardGrid style={{ margin: '-10px 10px' }}>
              {modules.map((m) => (
                <GatedModuleCard key={m.contentID} module={m} hasAccess={canAccessModules} canAccessModules={canAccessModules} />
              ))}
            </CardGrid>
          </Box>
        </Container>
      )}
    </>
  );
};

const resolveSeriesUrls = function (sitemap, posts) {
  let dynamicUrls = {};
  posts.forEach((post) => {
    Object.keys(sitemap).forEach((path) => {
      if (sitemap[path].contentID === post.contentID) {
        if (path.indexOf('well-managed-schools') > 0) {
          dynamicUrls[post.contentID] = path;
        }
      }
    });
  });
  return dynamicUrls;
};

// This is called during server-side rendering (not part of NextJS).
GatedWMSModuleListing.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  const api = agility;

  let modArray = [];
  try {
    //get sitemap in order to get the dynamic urls
    let sitemap = await api.getSitemapFlat({ channelName: channelName, languageCode });

    // get modules
    let raw = await api.getContentList({
      referenceName: 'ntrwmsmodulelist',
      languageCode,
      contentLinkDepth: 2,
      // expandAllContentLinks: true,
      depth: 2,
      sort: 'fields.sortOrder',
      take: 50,
    });

    // get our sub modules
    // fetch all (limit of 250 per fetch)
    let subMods = await api.getContentList({ referenceName: 'ntrwmssubmodulelist', languageCode, sort: 'fields.subModuleName', take: 250 });

    const dynamicUrls = resolveSeriesUrls(sitemap, raw.items);

    let mods = raw.items.map((mod) => {
      const url = dynamicUrls[mod.contentID];
      if (url == undefined) {
        console.log('Failed to get url for ' + mod.contentID + '  ' + mod.fields.moduleName);
      }
      let subs = subMods.items
        .filter((s) => s.fields.parentModule.contentID === mod.contentID)
        .map((s) => {
          try {
            if (s.fields.scormCourse) {
              s.fields.scormCourse = JSON.parse(s.fields.scormCourse);
            }
          } catch (error) {
            console.log(error);
          }
          return s;
        });
      mod.url = url;
      mod.subMods = subs ? subs : null;
      return mod;
    });

    return {
      mods,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default GatedWMSModuleListing;
