import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { emailRegex } from 'services/stringUtils';
import { ROLES, ROLE_CONST } from 'services/roleUtils';
import { logClientException } from 'appinsights/clientAppInsights';
import { FEATURE_SWITCH_TYPES } from 'services/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AddFeatureSwitchDialog = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [featureSwitchDialogState, setFeatureSwitchDialogState] = useState({
    switch_name: '',
    switch_type: 'Basic',
    enabled: true,
    value: '',
  });

  useEffect(() => {
    const init = () => {
      if (props.editFeatureSwitch) {
        setFeatureSwitchDialogState({
          switch_name: props.editFeatureSwitch.switch_name,
          switch_type: props.editFeatureSwitch.switch_type,
          enabled: props.editFeatureSwitch.enabled,
          value: props.editFeatureSwitch.value,
        });
      } else {
        resetState();
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open, props.editFeatureSwitch]);

  const resetState = () => {
    setErrorMessage(null);
    setFeatureSwitchDialogState({
      switch_name: '',
      switch_type: 'Basic',
      enabled: true,
      value: '',
    });
  };

  const closeFeatureSwitchDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSaveFeatureSwitch()) {
      return;
    }

    let featureSwitch = JSON.parse(JSON.stringify(featureSwitchDialogState));
    delete featureSwitch.hasError;
    delete featureSwitch.canSave;
    Object.keys(featureSwitch).forEach((key) => {
      if (key.endsWith('Error') && featureSwitch.hasOwnProperty(key)) {
        delete featureSwitch[key];
      }
    });

    try {
      setIsLoading(true);
      await callApi('/api/db/feature-switch', props.action === 'Add' ? 'POST' : 'PUT', featureSwitch);
      resetState();
      props.onClose(doSave);
      setIsLoading(false);
    } catch (error) {
      logClientException(error);
      setIsLoading(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSaveFeatureSwitch = () => {
    let hasError = false;
    const featureSwitch = featureSwitchDialogState;
    if (!featureSwitch.switch_name || featureSwitch.switch_name.length === 0) {
      hasError = true;
      featureSwitch.switch_nameError = true;
    }
    if (hasError) {
      setFeatureSwitchDialogState({ ...featureSwitch, canSave: false });
    }
    return !hasError;
  };

  const featureSwitchOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'switch_name') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    setFeatureSwitchDialogState({ ...featureSwitchDialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeFeatureSwitchDialog(false)}>
      <DialogTitle>{props.action} Feature Switch</DialogTitle>

      <DialogContent>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <TextField
          disabled={props.action === 'Update'}
          value={featureSwitchDialogState.switch_name}
          error={featureSwitchDialogState.switch_nameError}
          autoFocus
          required
          margin='dense'
          id='switch_name'
          label='Feature Switch name'
          type='text'
          fullWidth
          variant='outlined'
          onChange={(e) => featureSwitchOnChange('switch_name', e.target.value)}
          inputProps={{ maxLength: 100 }}
          InputLabelProps={{ shrink: true }}
        />
        <FormControlLabel
          control={<Checkbox checked={featureSwitchDialogState.enabled} onChange={(e) => featureSwitchOnChange('enabled', e.target.checked)} />}
          label='Enabled'
        />
        <FormControl fullWidth sx={{ marginTop: '7px' }}>
          <FormLabel id='feature-switch-type-label'>Code Type</FormLabel>
          <RadioGroup
            aria-labelledby='feature-switch-type-label'
            name='feature-switch-type'
            value={featureSwitchDialogState.switch_type}
            onChange={(e) => featureSwitchOnChange('switch_type', e.target.value)}
          >
            {FEATURE_SWITCH_TYPES.map((c, i) => {
              return <FormControlLabel key={c.switch_name} value={c.switch_name} control={<Radio />} label={c.label} />;
            })}
          </RadioGroup>
        </FormControl>

        <TextField
          value={featureSwitchDialogState.value}
          error={featureSwitchDialogState.valueError}
          margin='dense'
          id='value'
          label='Value'
          required={featureSwitchDialogState.switch_type === 'Percentage' || featureSwitchDialogState.switch_type === 'User List'}
          type={featureSwitchDialogState.switch_type === 'Percentage' ? 'number' : 'text'}
          fullWidth
          variant='outlined'
          onChange={(e) => featureSwitchOnChange('value', e.target.value)}
          inputProps={{ maxLength: 256 }}
          InputLabelProps={{ shrink: true }}
        />

        <PleaseWait isLoading={isLoading} />
      </DialogContent>
      <DialogActions>
        <KitButton onClick={() => closeFeatureSwitchDialog(false)} round size='sm'>
          Cancel
        </KitButton>
        <KitButton onClick={() => closeFeatureSwitchDialog(true)} round size='sm' color='success' disabled={!featureSwitchDialogState.canSave}>
          {props.action}
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddFeatureSwitchDialog.propTypes = {
  action: PropTypes.oneOf(['Add', 'Update']).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  editFeatureSwitch: PropTypes.object,
  onClose: PropTypes.func,
};
export default AddFeatureSwitchDialog;
