export const addDataLayerEvent = (event) => {
  if (!event) {
    return;
  }
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
    if (process.env.NEXT_PUBLIC_DEBUG) {
      console.log(Date.now(), JSON.stringify(event, null, 2));
    }
  }
};
