const https = require('https');

const getOptions = (method, body) => {
  let options = {
    // agent: isHttp
    //   ? new https.Agent({
    //       rejectUnauthorized: false,
    //     })
    //   : null,
    method: method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return options;
};

export const callApi = async (path, method, body, options = {}) => {
  // timeout needs to be higher than the serwist timeout - which is currently 10 seconds
  const { timeout = 11000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort('timeout'), timeout);

  let url = path;
  let response;
  try {
    response = await fetch(url, { ...getOptions(method, body), signal: controller.signal });
    clearTimeout(id);
    if (response.ok) {
      if (response.status === 204) {
        return;
      }
      return response.json();
    }
    if (response.status === 400) {
      throw new BadRequestError(await response.text());
    }
    let err = new Error(`API Call failed. ${method} to ${path} : Status ${response.status} ${response.statusText}`);
    throw err;
  } catch (error) {
    console.log(JSON.stringify(error, null, 2));
    console.log('callApi error', error);
    throw error;
  }
};

export class BadRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = 'BadRequestError';
  }

  formatMessage() {
    if (this.message.indexOf('ZodError') > -1) {
      const badError = JSON.parse(this.message);
      let msg = '';
      badError.issues.forEach((issue) => {
        msg += `${issue.path?.join('.')}:${issue.message} `;
      });
      return msg;
    } else {
      return this.message;
    }
  }
}
