import { Popover, Typography } from '@mui/material';
import { useState } from 'react';

const SeatListToolTip = ({ title, content }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <span style={{ cursor: 'pointer' }} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {title}
      </span>

      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            maxWidth: 5000,
            padding: '1em',
            whiteSpace: 'pre-wrap',
          }}
        >
          {content}
        </Typography>{' '}
      </Popover>
    </div>
  );
};

export default SeatListToolTip;
