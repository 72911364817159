import styled from '@emotion/styled';
import { Pagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SuggestionLink = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const SearchPagination = ({ totalCount, page, setPage, perPage }) => {
  return (
    <Pagination
      color='primary'
      size='large'
      shape='circular'
      count={Math.ceil(totalCount / perPage)}
      page={page}
      onChange={(ev, val) => {
        setPage(val);
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
      }}
    />
  );
};

export const SearchGridWrapper = styled('div')(({ theme }) => ({
  paddingLeft: '2.5rem',
  paddingRight: '2.5rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth: '1392px',
}));

export const NoResults = ({ term }) => {
  const theme = useTheme();
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'justify-start', marginBottom: '1rem' }}>
        <div style={{ fontSize: '24px', lineHeight: '32px', fontWeight: 500 }}>
          <span>0 results for </span>
          <span style={{ color: theme.palette.primary.main }}>{term}</span>
        </div>
      </div>
    </div>
  );
};
