import Layout from 'components/common/Layout';
import { getAgilityPageProps, getAgilityPaths } from '@agility/nextjs/node';
import { getModule } from 'components/agility-pageModules';
import SiteHeader from 'components/common/SiteHeader';
import SiteFooter from 'components/common/SiteFooter';

// getStaticProps function fetches data for all of your Agility Pages and Next.js will pre-render these pages at build time
export async function getStaticProps({ preview, params, locale, defaultLocale, locales }) {
  // note, for some reason without this console.log, preview is always true
  console.log('getStaticProps', preview);
  // always in preview mode based on env...
  if (process.env.AGILITY_PREVIEW_MODE === 'true') {
    preview = true;
  }

  // place all global here
  const globalComponents = {
    header: SiteHeader,
    footer: SiteFooter,
  };

  const agilityProps = await getAgilityPageProps({
    preview,
    params,
    locale,
    getModule,
    defaultLocale,
    globalComponents,
  });

  if (!agilityProps) {
    // We throw to make sure this fails at build time as this is never expected to happen
    throw new Error(`Page not found.`);
  }

  // hack - undefined doesn't serialize to JSON
  if (agilityProps.dynamicPageItem === undefined) {
    agilityProps.dynamicPageItem = null;
  }

  console.log('slug noRobots', process.env.NO_ROBOTS);
  if (process.env.NO_ROBOTS === 1 || process.env.NO_ROBOTS === '1') {
    agilityProps.noRobots = true;
  }

  return {
    // return all props
    props: agilityProps,

    // Next.js will attempt to re-generate the page when a request comes in, at most once every 10 seconds
    // Read more on Incremental Static Regeneration here: https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration
    revalidate: 10,
  };
}

// Next.js will statically pre-render all the paths from Agility CMS
export async function getStaticPaths({ locales, defaultLocale }) {
  //get the paths configured in agility
  let agilityPaths = await getAgilityPaths({
    preview: process.env.AGILITY_PREVIEW_MODE === 'true', // force preview mode if env is set.
    locales,
    defaultLocale,
  });

  return {
    paths: agilityPaths,
    fallback: true,
  };
}

const AgilityPage = (props) => {
  return <Layout {...props} />;
};

export default AgilityPage;
