// package imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import DeleteIcon from '@mui/icons-material/Delete';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

// local imports
import { default as SavedSnack } from './AlertSnack';
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { useDeleteObservationInstance } from '../hooks/ObservationInstanceHooks';
import { useRouter } from 'next/router';
import { BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';

const defaultToastAutoHide = 2000;
const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const DeleteObservationButton = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackSave, setSnackSave] = useState({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });

  const onDeleteSuccess = () => {
    setSnackSave({ message: 'Session deleted', severity: 'warning', autoHideDuration: defaultToastAutoHide });
  };

  const afterDelete = () => {
    setOpen(false);
    router.push(props.afterRedirect);
    deleteMutation.reset();
  };

  const deleteMutation = useDeleteObservationInstance(props.id, props.eventId, onDeleteSuccess);

  const handleDeleteSession = () => {
    if (!deleteMutation.isPending && !deleteMutation.isSuccess && !deleteMutation.isError) {
      deleteMutation.mutate();
    }
  };

  useEffect(() => {
    if (deleteMutation.isError) {
      logClientException(deleteMutation.error);
      if (deleteMutation.error instanceof BadRequestError) {
        setErrorMessage(deleteMutation.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    } else if (deleteMutation.isSuccess) {
      setErrorMessage(null);
    }
  }, [deleteMutation]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <>
      <KitButton
        size='sm'
        style={{
          width: '180px',
        }}
        onClick={() => setOpen(true)}
        disabled={deleteMutation.isPending || snackSave.message !== null}
      >
        <DeleteIcon />
        Delete Session
      </KitButton>

      <ConfirmDeleteDialog
        open={open}
        confirmMessage='Are you sure you want to delete this session?'
        handleClose={() => setOpen(false)}
        handleYes={() => {
          handleDeleteSession();
        }}
        yesDisabled={deleteMutation.isPending}
        noButtonColor={theme.palette.obsAqua.main}
      />

      <SavedSnack
        message={snackSave.message}
        openSnack={snackSave.message === null ? false : true}
        onClose={() => {
          setSnackSave({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });
          afterDelete();
        }}
        severity={snackSave.severity}
        autoHideDuration={snackSave.autoHideDuration}
      />

      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

DeleteObservationButton.propTypes = {
  afterRedirect: PropTypes.string.isRequired,
};
export default DeleteObservationButton;
