import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const RecordedWebinarDetail = ({ dynamicPageItem }) => {
  //console.log(dynamicPageItem);
  const webinar = dynamicPageItem.fields;

  return (
    <WebinarContainer>
      <WrapContainer>
        <KitGridContainer direction='row'>
          <KitGridItem xs={8} align='left'>
            <EventTitle>{webinar.title}</EventTitle>
          </KitGridItem>
          <KitGridItem xs={4} align='left' style={{ paddingTop: '5px' }}>
            <Link href='/free-resources' legacyBehavior>
              <PageLinkContainer>
                <PageLinkIconContainer>
                  <ArrowUpwardIcon />
                </PageLinkIconContainer>
                <PageLinkTextContainer>
                  <PageLinkActionText>Back to the Resource List</PageLinkActionText>
                </PageLinkTextContainer>
              </PageLinkContainer>
            </Link>
            {webinar.handout && (
              <Link href={webinar.handout.url} passHref legacyBehavior>
                <HandoutLink target='_blank'>{webinar.handout.label}</HandoutLink>
              </Link>
            )}
            {webinar.handout2 && (
              <Link href={webinar.handout2.url} passHref legacyBehavior>
                <HandoutLink target='_blank'>{webinar.handout2.label}</HandoutLink>
              </Link>
            )}
          </KitGridItem>
        </KitGridContainer>

        <Box
          sx={{
            width: '100%',
            position: 'relative',
            height: 0,
            paddingBottom: '56.25%',
            iframe: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            },
          }}
          dangerouslySetInnerHTML={renderHTML(webinar.videoEmbedCode)}
        ></Box>
      </WrapContainer>
    </WebinarContainer>
  );
};

export default RecordedWebinarDetail;

const WebinarContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  padding: '10px 5px 10px 5px',
  margin: '0 5% 35px 5%',
}));

const WrapContainer = styled('div')(({ theme }) => ({
  padding: '10px 5px 10px 5px',
  margin: '60px 10% 5px 10%',
  minWidth: '85%',
}));

const EventTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '10px 0 20px 0',
  fontSize: '1.95rem',
  lineHeight: '2.20rem',
  fontWeight: 600,
  minWidth: '60%',
}));

const PageLinkContainer = styled.div`
  display: flex;
  min-width: 100%;
  maxheight: 60px;
  cursor: pointer;
  justify-content: flex-end;
`;

const PageLinkIconContainer = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '10px 5px 10px 50px',
}));

const PageLinkTextContainer = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '12px 20px 10px 10px',
}));

const PageLinkActionText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  fontSize: '1.10rem',
  fontWeight: 300,
}));

const HandoutLink = styled('a')(({ theme }) => ({
  backgroundColor: 'transparent',
  float: 'right',
  color: theme.palette.success.main,
  fontSize: '.9rem',
  fontWeight: 500,
  padding: '5px 20px 10px 10px',
}));
