import React from 'react';
import styled from '@emotion/styled';

export default function SummaryTitle(props) {
  const { content, textColor } = props;

  const Title = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    @media screen and (max-width: 899px) {
      color: ${textColor};
      font-size: 2.2rem;
      line-height: 2.3rem;
      padding-left: 0;
    }
    @media screen and (min-width: 900px) {
      color: ${textColor};
      font-size: 3.75rem;
      line-height: 5rem;
    }
  `;

  return <React.Fragment>{content && <Title>{content}</Title>}</React.Fragment>;
}
