import { TextField, InputAdornment, Skeleton, Fade, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import {
  useAddSearch,
  useAddSearchQuery,
  LIFT_CATEGORY,
  PER_PAGE,
  SEARCH_CATEGORIES,
  useAddSearchClickTracker,
} from '../SearchComponents/useAddSearch';
import { NoResults, SearchGridWrapper, SearchPagination } from '../SearchComponents/SearchStyledComponents';
import SearchTerms from '../SearchComponents/SearchTerms';
import SearchResultItem from '../SearchComponents/SearchResultItem';
import { SearchBarWrapper, SearchResultsWrapper } from './SiteHeaderStyledComponents';
import SearchCategory from '../SearchComponents/SearchCategory';
import useSearchContext from './SearchContext';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: white;
  }
`;

const SearchBar = ({ isActive, searchTerms }) => {
  const theme = useTheme();
  const searchRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [page, setPage] = useState(1);
  const { setSearchCategory } = useSearchContext();
  const searchCategory = useSearchContext((state) => state.category);

  const newSearch = (val) => {
    setSearchText(val);
    setSearchVal(val);
    setPage(1);
  };

  useEffect(() => {
    if (!isActive) {
      newSearch('');
    } else {
      searchRef?.current?.focus();
    }
  }, [isActive]);

  useDebounce(
    () => {
      setSearchVal(searchText);
      if (searchText.length < 3) {
        setPage(1);
      }
    },
    500,
    [searchText]
  );

  const client = useAddSearch((client) => {
    client.setPaging(page, PER_PAGE, 'relevance', 'desc');
    client.setSearchOperator('and');
    client.setCollectAnalytics(false);
  });

  const { data, isLoading, isFetching } = useAddSearchQuery(
    client,
    {
      query: searchVal,
      //category: LIFT_CATEGORY,
      category: searchCategory !== '' ? `${LIFT_CATEGORY}/1xblog` : LIFT_CATEGORY,
      page: page,
    },
    {
      enabled: searchVal.length > 2,
    }
  );

  const click = useAddSearchClickTracker(client, data);

  const totalCount = data?.results.total_hits ?? 0;
  const results = data?.results?.hits;
  const hasResults = !!results?.length;

  if (!isActive) {
    return null;
  }

  return (
    <Fade in={isActive} timeout={500}>
      <SearchBarWrapper id='searchBarWrapper'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <div
                style={{ color: '#fff', display: 'inline-flex', justifyContent: 'flex-start', width: '80%', paddingLeft: '20px', paddingTop: '1rem' }}
              >
                {SEARCH_CATEGORIES.map((category) => {
                  return (
                    <SearchCategory
                      key={category.text}
                      text={category.text}
                      isActive={searchCategory === category.value}
                      click={() => setSearchCategory(category.value)}
                    />
                  );
                })}
              </div>
              <StyledTextField
                inputRef={searchRef}
                placeholder='Search'
                color='primary'
                sx={{ width: '80%', paddingBottom: '1rem' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  sx: { borderRadius: '999px', mt: '1rem' },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon color='primary' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end' sx={{ display: searchText?.length > 0 ? 'flex' : 'none' }}>
                      <IconButton aria-label='clear search' onClick={() => newSearch('')}>
                        <CloseIcon color='primary' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant='outlined'
              />

              <SearchResultsWrapper id='searchResultsWrapper'>
                <SearchGridWrapper id='searchGridWrapper'>
                  <div id='searchGrid' style={{ display: 'grid', gridTemplateColumns: '75% auto' }}>
                    <Fade in={searchText.length > 2} timeout={500}>
                      <div id='searchResults'>
                        {isLoading && <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />}
                        {isFetching && (
                          <>
                            <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
                            <Skeleton variant='text' sx={{ width: '100%', fontSize: '18px' }} />
                            <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
                          </>
                        )}

                        {!isLoading && !hasResults && searchText === searchVal && searchText?.length > 2 && <NoResults term={searchVal} />}

                        {!isLoading && hasResults && searchText === searchVal && searchText?.length > 2 && (
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                              <div style={{ fontSize: '24px', lineHeight: '32px', fontWeight: 500 }}>
                                {`${totalCount} result${totalCount > 1 ? 's' : ''} for `}
                                <span style={{ color: theme.palette.primary.main }}>{searchText}</span>
                              </div>
                              <div>
                                <SearchPagination totalCount={totalCount} page={page} setPage={setPage} perPage={PER_PAGE} />
                              </div>
                            </div>
                            <div>
                              {results.map(({ id, ...item }, index) => (
                                <SearchResultItem id={id} item={item} key={id} click={click} />
                              ))}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                              <div>
                                <SearchPagination totalCount={totalCount} page={page} setPage={setPage} perPage={PER_PAGE} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Fade>
                    <SearchTerms searchTerms={searchTerms} newSearch={newSearch} />
                  </div>
                </SearchGridWrapper>
              </SearchResultsWrapper>
            </div>
          </div>
        </div>
      </SearchBarWrapper>
    </Fade>
  );
};

export default SearchBar;
