import { callApi } from 'services/apiWrapper';

const isFeatureSwitchOnClientAsync = async (flagName) => {
  let enabled = false;

  try {
    let res = await callApi(`/api/feature-switch?flag=${flagName}`);
    enabled = res.enabled;
  } catch (error) {
    console.log(error);
    throw error;
  }

  return enabled;
};
export { isFeatureSwitchOnClientAsync };
