import React, { useState } from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';
import Heading5 from 'components/common/subcomponents/Typography/Heading5';
import DialogWithX from './pageComponents/DialogWithX';
import LockIcon from '@mui/icons-material/Lock';

const IntroductionWithSideImage = ({ module }) => {
  const { fields } = module;

  const { data: session, status } = useSession();
  const theme = useTheme();

  const [showTranscriptRegistrationDialog, setShowTranscriptRegistrationDialog] = useState(false);

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @media screen and (min-width: 900px) {
      padding: 0 20px;
      width: 70%;
      padding: 30px 5px;
    }
    @media screen and (max-width: 899px) {
      padding: 0 10px 0 10px;
      width: 100%;
    }
  `;

  const AccentImage = styled.img`
    z-index: 10;
    @media only screen and (min-width: 992px) {
      position: absolute;
      bottom: 0;
      ${fields.imagePosition === 'left' ? 'left: -5%;' : 'right: -25%;'}
      height: 450px;
      width: auto;
    }
    @media only screen and (max-width: 991px) {
      height: auto;
      width: 100%;
      margin: 20px auto 30px 10%;
    }
  `;

  const SideBySideContainer = styled.div`
    position: relative;
    display; flex;
    align-items: center;
    width: 100%;
    @media screen and (min-width: 992px) {
      justify-Content: space-between;
      ${fields.imagePosition === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
    }
    @media screen and (max-width: 991px) {
      width: 95%;
      height: auto;
    }
`;

  const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // @media screen and (min-width: 1300px) {
    //   width: 40%;
    //   height: 630px;
    //   padding: 0 0 15px 0;
    // }
    // @media screen and (max-width: 1299px) {
    //   width: 95%;
    //   height: 680px;
    // }
    width: 100%;
    height: 750px;
    padding: 0 0 15px 0;
  `;

  return (
    <Container>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <SideBySideContainer>
          <TextContainer>
            <Heading5 style={{ width: '85%' }}>{fields.title}</Heading5>
            <IntroText
              className='prose max-w-full mx-auto'
              dangerouslySetInnerHTML={renderHTML(
                session
                  ? fields.introductionTextwithSession
                    ? fields.introductionTextwithSession
                    : fields.introductionText
                  : fields.introductionText
              )}
            />
            <ButtonContainer align={fields.buttonAlignment}>
              {fields.showButton === 'true' && (
                <>
                  <KitButton
                    round
                    style={{
                      backgroundColor: theme.palette.primary.light,
                    }}
                    onClick={(e) => setShowTranscriptRegistrationDialog(true)}
                  >
                    <LockIcon color='white' /> {fields.buttonUrl.text}
                  </KitButton>
                </>
              )}
            </ButtonContainer>
          </TextContainer>
          <ImageContainer>
            {fields.accentImage && (
              <AccentImage src={fields.accentImage && fields.accentImage.url} alt={fields.accentImage && fields.accentImage.text} />
            )}
          </ImageContainer>
        </SideBySideContainer>
      </Box>
      <DialogWithX
        size='md'
        content={
          <FormContainer>
            <HTMLContent className='m-5 p-0' dangerouslySetInnerHTML={renderHTML(fields.dialogContent)} />
          </FormContainer>
        }
        ariaLabel='Graduate Credit Resistration Form Dialog'
        showValue={showTranscriptRegistrationDialog}
        showMethod={setShowTranscriptRegistrationDialog}
        title='Official Transcript Registration Form'
        titleBarColor='#FFA500'
        titleTextColor='#fff'
        closeXColor='#000'
      />
    </Container>
  );
};

export default IntroductionWithSideImage;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(191, 187, 175, 0.15)',
  [theme.breakpoints.up('md')]: {
    margin: '30px 0 40px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '20px 0 0 0',
    margin: '15px 10px 20px 10px',
  },
}));

const IntroText = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  margin: 5,
}));

const ButtonContainer = styled('div')(({ theme, align }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    margin: '35px 0 25px 0',
    alignItems: align || 'center',
  },
  [theme.breakpoints.down('md')]: {
    width: '105%',
    margin: '20px 0',
    alignItems: 'center',
  },
}));

const HTMLContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    '> *': {
      '> *': {
        padding: 0,
        margin: 0,
        width: '1200px',
        height: '600px',
      },
    },
  },
  [theme.breakpoints.down('lg')]: {
    '> *': {
      '> *': {
        padding: 0,
        margin: 0,
        width: '300px',
        height: '600px',
      },
    },
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
}));
