import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';

const RichTextArea = ({ module }) => {
  const { fields } = module;

  return (
    <TextContainer>
      <div className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.textblob)} />
    </TextContainer>
  );
};

export default RichTextArea;

const TextContainer = styled('div')((props) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 5px 10px 5px',
  margin: '10px 10% 50px 10%',
}));
