import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { getIcon, getColorByAgilityName } from '../../services/agilityTranslations';
import ArticleIcon from '@mui/icons-material/Article';

const VideoWithBullets = ({ module }) => {
  const { fields } = module;

  const [videoSelected, setVideoSelected] = useState(null);

  const showDocumentLink = (url, iconColor) => {
    return (
      <ListItemIcon>
        <Link href={url.href} passHref legacyBehavior>
          <a target={url.target} rel='noopener noreferrer'>
            <ListText style={{ color: iconColor }}>
              <ArticleIcon /> {url.text}
            </ListText>
            {/* <div title={url.text}>
              <ArticleIcon style={{ width: '20px', color: iconColor }} />
            </div> */}
          </a>
        </Link>
      </ListItemIcon>
    );
  };

  useEffect(() => {
    if (!videoSelected) {
      if (fields && fields.linkList && fields.linkList.length > 0) {
        setVideoSelected(fields.linkList[0]);
      }
    }
  }, [fields]);

  const [textAlign, setTextAlign] = useState('right');

  const MobileContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 899px) {
      max-width: 100%;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      max-width: 100%;
    }
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 35px 0;
    .video {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      max-width: 100%;
    }
  `;

  const MobileVideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    .video {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
    @media screen and (max-width: 899px) {
      max-width: 100%;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (max-width: 899px) {
      ${videoSelected ? 'width: 450px; height: 300px;' : 'display: none;'}
    }
    @media screen and (min-width: 900px) {
      ${videoSelected ? 'width: 450px; height: 300px;' : 'display: none;'}
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    margin: 40px 5px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    @media screen and (max-width: 899px) {
      color: ${fields.titleColor};
      font-size: 1.75rem;
      line-height: 1.95rem;
      padding: 0 25px;
    }
    @media screen and (min-width: 900px) {
      color: ${fields.titleColor};
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  `;

  const ContentContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex: 1;
  vertical-align: top;
  @media screen and (max-width: 899px) {
    margin: 10px 0;
    padding: 0 20px 0 20px;
    max-width: 100%;
  }
  @media screen and (min-width: 900px) {
    margin: 35px 0;
    padding: 0 10px;
    max-width: 450px;
`;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: 899px) {
      width: 100%;
      flex-direction: column;
    }
    @media screen and (min-width: 900px) {
      width: 70%;
      ${fields.textAlign === 'left' ? (videoSelected ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
  `;

  const DocumentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 899px) {
      margin: 10px 10px 0 10px;
    }
  `;

  const ButtonContainer = styled.div`
    margin: 40px 5px;
    @media screen and (max-width: 899px) {
      padding: 0 25px;
    }
  `;

  const ListSeparator = styled.div`
    margin: 1.5% 0 1.5% 18px;
    width: 95%;
    border: 0.75px dashed #808080;
    &:last-child {
      border: 0px;
    }
  `;

  return (
    <React.Fragment>
      <MobileContainer style={{ backgroundColor: getColorByAgilityName(fields.backgroundColor) }}>
        <MobileVideoContainer>
          {videoSelected && videoSelected.fields && videoSelected.fields.webinarEmbed && (
            <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(videoSelected.fields.webinarEmbed)} />
          )}
          <DocumentContainer>
            <DocumentLink>
              {videoSelected &&
                videoSelected.fields &&
                videoSelected.fields.documentUrl &&
                showDocumentLink(videoSelected.fields.documentUrl, fields.textColor)}
            </DocumentLink>
          </DocumentContainer>
        </MobileVideoContainer>
        <ListContainer>
          <ContentContainer>
            <ResourceDescription style={{ color: fields.textColor, margin: 0, padding: '-20px 10px' }}>
              <List>
                {fields.linkList &&
                  fields.linkList.length > 0 &&
                  fields.linkList
                    .sort((a, b) => {
                      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                      return 0;
                    })
                    .map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ListItemButton style={{ width: '100%' }} onClick={(e) => setVideoSelected(item)}>
                            <KitGridContainer direction='row' style={{ width: '100%' }}>
                              <KitGridItem xs={2} sm={1} align='left' style={{ width: '100%', marginTop: 3 }}>
                                <ListItemIcon>{getIcon(fields.iconName, fields.iconColor, '1.10rem')}</ListItemIcon>
                              </KitGridItem>
                              <KitGridItem xs={10} sm={11} align='left' style={{ width: '100%' }}>
                                <ListText>{item.fields.webinarUrl.text}</ListText>
                              </KitGridItem>
                            </KitGridContainer>
                          </ListItemButton>
                          <ListSeparator />
                        </React.Fragment>
                      );
                    })}
              </List>
            </ResourceDescription>
          </ContentContainer>
        </ListContainer>
      </MobileContainer>
      <Container style={{ backgroundColor: getColorByAgilityName(fields.backgroundColor) }}>
        <ListContainer>
          <VideoContainer>
            {videoSelected && videoSelected.fields && videoSelected.fields.webinarEmbed && (
              <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(videoSelected.fields.webinarEmbed)} />
            )}
            <DocumentContainer>
              <DocumentLink>
                {videoSelected &&
                  videoSelected.fields &&
                  videoSelected.fields.documentUrl &&
                  showDocumentLink(videoSelected.fields.documentUrl, fields.textColor)}
              </DocumentLink>
            </DocumentContainer>
          </VideoContainer>
          <ContentContainer>
            <ResourceDescription style={{ color: fields.textColor, margin: 0, padding: '-20px 10px' }}>
              <List>
                {fields.linkList &&
                  fields.linkList.length > 0 &&
                  fields.linkList
                    .sort((a, b) => {
                      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                      return 0;
                    })
                    .map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ListItemButton style={{ width: '100%' }} align='left' onClick={(e) => setVideoSelected(item)}>
                            <ListItemIcon>{getIcon(fields.iconName, fields.iconColor, '1.10rem')}</ListItemIcon>
                            <ListText>{item.fields.webinarUrl.text}</ListText>
                          </ListItemButton>
                          <ListSeparator />
                        </React.Fragment>
                      );
                    })}
              </List>
            </ResourceDescription>
          </ContentContainer>
        </ListContainer>
      </Container>
    </React.Fragment>
  );
};

VideoWithBullets.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  try {
    let list = await api.getContentList({
      referenceName: 'videoWithBullets',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (list && list.items && list.items.length > 0) {
      contentItem = list;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load Video with Bullets video List.', error);
    return null;
  }

  return {
    fields: contentItem,
  };
};

export default VideoWithBullets;

const ResourceImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  minHeight: '75%',
}));

const ResourceIcon = styled('div')(({ theme }) => ({
  maxWidth: '80px',
  [theme.breakpoints.up('md')]: {
    padding: '5px 40px 10px 40px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ResourceTitle = styled('div')(({ theme }) => ({
  margin: '-10px 10px',
}));

const ResourceDescription = styled('div')(({ theme }) => ({
  fontWeight: 300,
  margin: '5px 10px 15px 0',
  fontSize: '0.85rem',
  lineHeight: '0.95rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 5px',
  },
}));

const ListText = styled('div')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  lineHeight: '1.10rem',
}));

const HandoutText = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '0.90rem',
  lineHeight: '1.00rem',
}));

const DocumentLink = styled('div')(({ theme }) => ({
  justifyContent: 'center',
}));

const Description = styled('div')(({ theme }) => ({
  fontWeight: 300,
  margin: '5px 10px 15px 0',
  fontSize: '0.85rem',
  lineHeight: '0.95rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 25px',
  },
}));

// const ListIcon = styled('ListItemIcon')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     width: '45px',
//   },
//   [theme.breakpoints.down('md')]: {
//     width: '55px',
//   },
// }));
