// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Snackbar, Tabs } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { useRouter } from 'next/router';
import { BadRequestError } from 'services/apiWrapper';
import { removeSessionStoreItem, setSessionStoreString } from 'services/storageUtils';
import ObservationHeader from './components/ObservationHeader';
import ObservationPageContainer from './components/ObservationPageContainer';
import TabPanel from './components/TabPanel';
import { TabItem } from './components/Tabs';
import CoorectionBenchmarkGroup from './components/sessionSummary/CorrectionBenchmarkGroup';
import ObservationHeaderDetail from './components/sessionSummary/ObservationHeaderDetail';
import PraiseBenchmarkGroup from './components/sessionSummary/PraiseBenchmarkGroup';
import PreventionBenchmarkGroup from './components/sessionSummary/PreventionBenchmarkGroup';
import { useAddObservationInstance } from './hooks/ObservationInstanceHooks';
import { useObservationStore } from './stores/observationStore';
import ObservationStats from './utils/ObservationStats';
import SummaryMetricCards from './components/sessionSummary/SummaryMetricCards';
import StrengthsGrowthGroup from './components/sessionSummary/StrengthsGrowthGroup';
import ObservationTimelineFull from './components/dataEntry/ObservationTimelineFull';
import { default as SavedSnack } from './components/AlertSnack';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const offlineSaveMessage =
  'Offline observations are queued and retried when online. <br>Be sure to reconnect to the internet to save your observation within 24 hours.';
const defaultToastAutoHide = 2500;

const ObservationSessionSummary = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const router = useRouter();
  const [tabIndex, setTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [snackSave, setSnackSave] = useState({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });

  const { observation, patchObservation } = useObservationStore();

  const afterObservationMutate = (data) => {
    setSnackSave({ message: 'Session Saved', severity: 'success', autoHideDuration: defaultToastAutoHide });
  };

  const afterObservationMutateFail = (error) => {
    logClientException(error);
    if (
      error?.message?.toLocaleLowerCase().includes('failed to fetch') ||
      error?.message?.toLocaleLowerCase().includes('service worker is an error')
    ) {
      // might be offline, should have been added to offline queue to be retried
      setSnackSave({
        message: offlineSaveMessage,
        severity: 'warning',
        autoHideDuration: defaultToastAutoHide,
      });
    } else if (error instanceof BadRequestError) {
      setErrorMessage(error.formatMessage());
    } else {
      setErrorMessage(defaultErrorMessage);
    }
    createObservationMutation.reset();
  };

  const afterSave = () => {
    useObservationStore.persist.clearStorage();
    removeSessionStoreItem('summaryReview');
    router.push({
      pathname: fields.nextPage.href,
    });
  };

  const createObservationMutation = useAddObservationInstance(observation, afterObservationMutate, afterObservationMutateFail);

  const isFetchingCount = useIsFetching();

  const observationMetrics = new ObservationStats(observation);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    } else if (observation) {
      if (observation?.status !== 'Under Review') {
        console.log('redirecting to landing page', JSON.stringify(observation, null, 2));
        useObservationStore.persist.clearStorage();
        removeSessionStoreItem('summaryReview');
        router.push({
          pathname: fields?.nextPage?.href,
        });
      } else {
        // set a flag summary review is in progress
        setSessionStoreString('summaryReview', 'true');
      }
    }
  }, [observation, initialized]);

  useEffect(() => {
    if (session?.user) {
      patchObservation([{ 'name': 'observer_name', 'value': `${session.user.lastName}, ${session.user.firstName}` }]);
    }
  }, [session]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const handleSave = (event) => {
    if (
      !observation.areas_strength ||
      observation.areas_strength.length === 0 ||
      !observation.areas_growth ||
      observation.areas_growth.length === 0
    ) {
      setErrorMessage('You must enter areas of strength and areas of growth before saving.');
    } else {
      createObservationMutation.mutate();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='xlarge'>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <ObservationHeaderDetail observation={observation} observationMetrics={observationMetrics} />
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <KitButton
              size='md'
              style={{
                backgroundColor: theme.palette['obsOff Task'].main,
                marginRight: '1rem',
                width: '160px',
              }}
              disabled={isFetchingCount > 0 || snackSave.message !== null}
              onClick={() => setConfirmDeleteOpen(true)}
            >
              <DeleteForeverIcon />
              Discard Session
            </KitButton>
            <KitButton
              size='md'
              style={{
                width: '160px',
              }}
              disabled={
                isFetchingCount > 0 || createObservationMutation.isLoading || createObservationMutation.isPending || snackSave.message !== null
              }
              onClick={(e) => handleSave(e)}
            >
              <SaveIcon />
              Save Session
            </KitButton>
          </Box>
        </Box>
      </ObservationHeader>
      <ObservationPageContainer>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem', justifyContent: 'center' }}>
          <SummaryMetricCards observationMetrics={observationMetrics} checklistPageHref={fields?.checklistPage?.href} />

          <Box sx={{ width: '100%', marginTop: '1.25rem' }}>
            <Tabs
              value={tabIndex}
              onChange={(e, nv) => setTabIndex(nv)}
              aria-label='Summary Tabs'
              centered
              variant='fullWidth'
              sx={{ borderBottom: `solid 2px ${theme.palette.primary.main}` }}
            >
              <TabItem label='Strengths/Growth' id='strengths-growth-tab' aria-controls='strengths-growth-tabpanel' />
              <TabItem label='Benchmarks' id='benchmarks-tab' aria-controls='benchmarks-tabpanel' />
              <TabItem label='Timeline' id='timeline-tab' aria-controls='timeline-tabpanel' />
            </Tabs>
            <TabPanel value={tabIndex} index={0} id='strengths-growth-tabpanel' name='strengths-growth-tabpanel'>
              <StrengthsGrowthGroup />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} id='benchmarks-tabpanel' name='benchmarks-tabpanel'>
              <PreventionBenchmarkGroup observationMetrics={observationMetrics} />
              <PraiseBenchmarkGroup observationMetrics={observationMetrics} />
              <CoorectionBenchmarkGroup observationMetrics={observationMetrics} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} id='timeline-tabpanel' name='timeline-tabpanel'>
              <ObservationTimelineFull canEdit={true} />
            </TabPanel>
          </Box>
        </Box>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0} />
        <SavedSnack
          message={snackSave.message}
          openSnack={snackSave.message === null ? false : true}
          onClose={() => {
            setSnackSave({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });
            afterSave();
          }}
          severity={snackSave.severity}
          autoHideDuration={snackSave.autoHideDuration}
        />

        <ConfirmDeleteDialog
          open={confirmDeleteOpen}
          confirmMessage='Are you sure you want to discard this session?'
          handleClose={() => setConfirmDeleteOpen(false)}
          handleYes={() => {
            setConfirmDeleteOpen(false);
            setSnackSave({ message: 'Session discarded', severity: 'warning', autoHideDuration: defaultToastAutoHide });
          }}
          noButtonColor={theme.palette.obsAqua.main}
        />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationSessionSummary;
