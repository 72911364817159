import React from 'react';
import { useTheme } from '@emotion/react';
import { parseBlocks } from 'services/parseUtils';
import { Freeform } from 'components/common/Freeform';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import Section from 'components/common/subcomponents/Section';
import { headingColor, headingStyle } from 'services/styleUtils/headingStyleUtils';
import { getContentStyle } from 'services/styleUtils/contentStyleUtils';

const HeadingText = (props) => {
  const {
    module: { fields },
  } = props;

  const theme = useTheme();

  const blocks = parseBlocks(fields.contentText);
  const contentStyle = getContentStyle(theme, fields.contentStyle?.fields);

  return (
    <>
      <Section styleProps={fields.sectionStyle} sectionId={fields.sectionId}>
        <FreeformHeader
          level={fields?.headingLevel || 'h2'}
          text={fields?.headingText}
          color={headingColor(theme, fields.headingStyle?.fields?.color)}
          style={{ marginBottom: '1rem', ...headingStyle(fields.headingStyle?.fields?.alignment) }}
        />
        <div
          style={{
            color: contentStyle.textColor,
            textAlign: contentStyle.alignment,
            fontSize: contentStyle.fontSize,
            lineHeight: contentStyle.lineHeight,
          }}
        >
          <Freeform blocks={blocks}></Freeform>
        </div>
      </Section>
      {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
    </>
  );
};

export default HeadingText;
