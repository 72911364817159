export function TableRow({ children, ...rest }) {
  return <tr {...rest}>{children}</tr>;
}

export function FreeformTable({ withHeadings, content }) {
  const headings = withHeadings ? content[0] : [];
  const body = withHeadings ? content.slice(1) : content;

  return (
    <table style={{ tableLayout: 'auto', maxWidth: '56rem', width: '100%', marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'collapse' }}>
      {headings.length > 0 && (
        <thead>
          <TableRow style={{ textAlign: 'left' }}>
            {headings.map((item, index) => (
              <th
                key={index}
                style={{ borderBottom: `1px solid ${theme.palette.grey[400]}`, padding: '0.5rem 0.75rem', backgroundColor: theme.palette.grey[100] }}
              >
                {item}
              </th>
            ))}
          </TableRow>
        </thead>
      )}

      <tbody>
        {body.map((row, index) => (
          <TableRow key={index}>
            {row.map((item, index) => (
              <td key={index} style={{ borderBottom: `1px solid ${theme.palette.grey[400]}`, padding: '0.5rem 0.75rem' }}>
                {item}
              </td>
            ))}
          </TableRow>
        ))}
      </tbody>
    </table>
  );
}
