import React, { useState, useEffect } from 'react';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { InputLabel, FormControl, Select, TextField, MenuItem, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import PleaseWait from '../../PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, LEAD_TITLES } from 'services/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AccountProfile = (props) => {
  const { userRecord, schoolRecord } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    position: '',
    phone_number: '',
  });

  useEffect(() => {
    if (userRecord) {
      setForm({
        first_name: userRecord.firstName ?? '',
        last_name: userRecord.lastName ?? '',
        position: userRecord.position ?? '',
        phone_number: userRecord.phone_number ?? '',
      });
    }
  }, [userRecord]);

  const saveUser = async () => {
    if (!canSave) {
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);

      let updateRec = {
        firstName: form.first_name,
        lastName: form.last_name,
        position: form.position,
        emailAddress: userRecord.emailAddress,
        id: userRecord.id,
      };
      if (form.phone_number) {
        updateRec.phone_number = form.phone_number;
      }

      const res = await callApi('/api/db/user-profile', 'PUT', updateRec);
      props.onUserRecordUpdate(res);
      // force reload, pick up name changes
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(ERROR_MESSAGES.Default);
      logClientException(error);
    }
  };

  const canSave = () => {
    let hasError = false;
    if (!form.first_name || form.first_name.length === 0) {
      hasError = true;
      form.first_nameError = true;
    }
    if (!form.last_name || form.last_name.length === 0) {
      hasError = true;
      form.last_nameError = true;
    }
    if (!form.position || form.position.length === 0) {
      hasError = true;
      form.positionError = true;
    }
    // todo validate phone if present??
    // if (!form.phone_number || form.phone_number.length === 0) {
    //   hasError = true;
    //   form.last_nameError = true;
    // }

    if (hasError) {
      setForm({ ...form, canSave: false });
    }
    return !hasError;
  };

  const resetForm = () => {
    setForm({
      first_name: userRecord?.firstName ?? '',
      last_name: userRecord?.lastName ?? '',
      position: userRecord?.position ?? '',
      phone_number: userRecord?.phone_number ?? '',
    });
  };

  const formOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'position' || field === 'first_name' || field === 'last_name') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    setForm({ ...form, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <KitGridContainer>
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <PleaseWait isLoading={isLoading} />

      <KitGridItem xs={12} sm={12} md={3} lg={3} align='left' style={{ marginBottom: '20px' }}>
        {userRecord?.district_name}
        <br />
        {userRecord?.school_name}
        <br />
        {schoolRecord?.location && (
          <>
            {schoolRecord?.location?.street1}
            <br />
            {schoolRecord?.location?.city}, {schoolRecord?.location?.state}
            <br />
            {schoolRecord?.location?.zip}
            <br />
          </>
        )}
      </KitGridItem>
      <KitGridItem xs={12} sm={12} md={6} lg={6} align='left'>
        <FormControl fullWidth>
          <TextField
            id='first_name'
            size='small'
            label='First Name'
            required
            fullWidth
            error={form.first_nameError}
            variant='standard'
            value={form.first_name}
            onChange={(e) => {
              formOnChange('first_name', e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: '10px' }}>
          <TextField
            id='last_name'
            size='small'
            label='Last Name'
            required
            fullWidth
            error={form.last_nameError}
            variant='standard'
            value={form.last_name}
            onChange={(e) => {
              formOnChange('last_name', e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>

        <FormControl fullWidth variant='standard' sx={{ mt: '10px' }}>
          <InputLabel id='role-select-label' required>
            Position
          </InputLabel>
          <Select
            name='role'
            variant='standard'
            labelId='role-select-label'
            id='role-select-value'
            size='small'
            value={form.position}
            error={form.postionError}
            required
            onChange={(e) => {
              formOnChange('position', e.target.value);
            }}
          >
            {LEAD_TITLES.map((t) => {
              return (
                <MenuItem value={t.title} key={t.title}>
                  {t.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: '10px' }}>
          <TextField
            id='phone_number'
            size='small'
            label='Phone Number'
            fullWidth
            error={form.phone_numberError}
            variant='standard'
            value={form.phone_number}
            onChange={(e) => {
              formOnChange('phone_number', e.target.value);
            }}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>

        <Box sx={{ display: 'flex', alignItems: 'flex-start', margin: '25px auto' }}>
          <KitButton style={{ width: '130px' }} size='sm' color='success' round onClick={(e) => saveUser()}>
            Save
          </KitButton>
        </Box>
      </KitGridItem>
    </KitGridContainer>
  );
};

export default AccountProfile;
