// package imports
import PropTypes from 'prop-types';
import { Alert, Snackbar } from '@mui/material';
import { alertClasses } from '@mui/material/Alert';
import { useTheme } from '@emotion/react';

const AlertStyles = (theme) => ({
  alert: {
    [`&.${alertClasses.standard}.${alertClasses.colorSuccess}`]: {
      backgroundColor: theme.palette.obsPraise.light,
      border: `1px solid ${theme.palette.obsPraise.dark}`,
    },
    [`&.${alertClasses.standard}.${alertClasses.colorError}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette['obsOff Task'].main,
      border: `1px solid ${theme.palette['obsOff Task'].dark}`,
      '& .MuiAlert-icon': {
        color: theme.palette.common.white,
      },
    },
    [`&.${alertClasses.standard}.${alertClasses.colorWarning}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.gold.main,
      border: `1px solid ${theme.palette.gold.dark}`,
      '& .MuiAlert-icon': {
        color: theme.palette.common.white,
      },
    },
    [`&.${alertClasses.standard}.${alertClasses.colorInfo}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.obsPrevention.main,
      border: `1px solid ${theme.palette.obsPrevention.light}`,
      '& .MuiAlert-icon': {
        color: theme.palette.common.white,
      },
    },
  },
});

const AlertSnack = ({ openSnack, severity, message, onClose, autoHideDuration, horizontal = 'right' }) => {
  const theme = useTheme();
  const styles = AlertStyles(theme);
  return (
    <Snackbar
      open={openSnack}
      anchorOrigin={{ vertical: 'top', horizontal }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      sx={{ zIndex: 9000 }}
    >
      <Alert id='ObsAlert' severity={severity} sx={styles.alert}>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Alert>
    </Snackbar>
  );
};

AlertSnack.defaultProps = {
  severity: 'success',
  autoHideDuration: 5000,
};

AlertSnack.propTypes = {
  openSnack: PropTypes.bool.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
};
export default AlertSnack;
