import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Badge, Box, FormControl, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useObservationStore } from '../../stores/observationStore';
import { CheckmarkIcon } from 'components/common/subcomponents/SvgIcons';
import { PromptButton, ObsStyles, PromptContainer, PromptRadio } from '../StyledComponents';

const Correction = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { observation, addTimelineObservation, setDirtyObject } = useObservationStore();

  const [correctionPrompt, setCorrectionPrompt] = useState(''); // ['cp-bc', 'cp-nc', 'cs-bc', 'cs-nc', 'gsc-bc', 'gsc-nc', 'ct-bc', 'ct-nc']
  const [sPrompt, setSPrompt] = useState(''); // s'
  const [slPrompt, setSLPrompt] = useState(''); // sl'
  const [moPrompt, setMoPrompt] = useState(''); // mo'
  const [note, setNote] = useState('');
  const [hasCorrectionPrompt, setHasCorrectionPrompt] = useState(false);
  const [hasMoPrompt, setHasMoPrompt] = useState(false);

  useEffect(() => {
    if (observation.dirtyObject !== 'correction') {
      setCorrectionPrompt('');
      setHasCorrectionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
    }
  }, [observation]);

  const radioOnChange = (e) => {
    setDirtyObject('correction');
    const { name, value, checked } = e.target;
    if (name === 'correction-1') {
      setCorrectionPrompt(value);
      setHasCorrectionPrompt(true);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'correction-s-1') {
      setSPrompt(checked && sPrompt === 'correction-s' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'correction-sl-1') {
      setSLPrompt(checked && slPrompt === 'correction-sl' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'correction-mo') {
      setCorrectionPrompt('');
      setHasCorrectionPrompt(true);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt(checked && moPrompt === 'correction-mo' ? '' : value);
      setHasMoPrompt(true);
    }
  };

  const addToTimeline = () => {
    const timelineObservation = {
      timestamp: new Date().getTime(),
      type: 'Correction',
      tags: [],
      note: note,
    };

    let hasMo = false;
    if (correctionPrompt?.length > 0) {
      setHasCorrectionPrompt(true);
      timelineObservation.tags.push(correctionPrompt.toUpperCase());
    }
    if (sPrompt?.length > 0) {
      timelineObservation.tags.push('S');
    }
    if (slPrompt?.length > 0) {
      timelineObservation.tags.push('SL');
    }
    if (moPrompt?.length > 0) {
      setHasMoPrompt(true);
      timelineObservation.tags.push('MO');
    }
    if (hasCorrectionPrompt || hasMoPrompt) {
      addTimelineObservation(timelineObservation);
      setCorrectionPrompt('');
      setHasCorrectionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
      props.scrollToTop();
    }
  };

  return (
    <PromptContainer promptType='Correction'>
      <Badge
        color='error'
        badgeContent={
          observation?.observations?.filter((o) => o.type === 'Correction' && o.tags.filter((t) => t === 'MO')?.length === 0)?.length || 0
        }
        className='prompt-title-badge'
      >
        <div className='prompt-title-box'>
          <CheckmarkIcon fillColor={theme.palette.obsCorrection.dark} />
          <Typography sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '18.86px', color: '#000', marginLeft: '1rem' }}>Correction</Typography>
        </div>
      </Badge>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', flexWrap: 'nowrap', width: '85%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.35rem' }}>
            <input type='radio' id='cp-bc' name='correction-1' value='cp-bc' checked={correctionPrompt === 'cp-bc'} onClick={radioOnChange} />
            <label htmlFor='cp-bc'>CP - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.35rem' }}>
            <input type='radio' id='cp-nc' name='correction-1' value='cp-nc' checked={correctionPrompt === 'cp-nc'} onClick={radioOnChange} />
            <label htmlFor='cp-nc'>CP - NC</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='cs-bc' name='correction-1' value='cs-bc' checked={correctionPrompt === 'cs-bc'} onClick={radioOnChange} />
            <label htmlFor='cs-bc'>CS - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='cs-nc' name='correction-1' value='cs-nc' checked={correctionPrompt === 'cs-nc'} onClick={radioOnChange} />
            <label htmlFor='cs-nc'>CS - NC</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='gsc-bc' name='correction-1' value='gsc-bc' checked={correctionPrompt === 'gsc-bc'} onClick={radioOnChange} />
            <label htmlFor='gsc-bc'>GSC - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='gsc-nc' name='correction-1' value='gsc-nc' checked={correctionPrompt === 'gsc-nc'} onClick={radioOnChange} />
            <label htmlFor='gsc-nc'>GSC - NC</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='ct-bc' name='correction-1' value='ct-bc' checked={correctionPrompt === 'ct-bc'} onClick={radioOnChange} />
            <label htmlFor='ct-bc'>CT - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='ct-nc' name='correction-1' value='ct-nc' checked={correctionPrompt === 'ct-nc'} onClick={radioOnChange} />
            <label htmlFor='ct-nc'>CT - NC</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '1rem', width: '85%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.obsCorrection.xLight,
            borderRadius: '12px',
            padding: '12px',
            width: '66.67%',
          }}
        >
          <PromptRadio promptType='Correction' round={true}>
            <input
              type='radio'
              id='correction-s'
              name='correction-s-1'
              value='correction-s'
              checked={sPrompt === 'correction-s'}
              onClick={radioOnChange}
            />
            <label htmlFor='correction-s'>S</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' round={true}>
            <input
              type='radio'
              id='correction-sl'
              name='correction-sl-1'
              value='correction-sl'
              checked={slPrompt === 'correction-sl'}
              onClick={radioOnChange}
            />
            <label htmlFor='correction-sl'>SL</label>
          </PromptRadio>
        </Box>
        <Box sx={{ padding: '12px' }}>
          <Badge
            color='error'
            badgeContent={
              observation?.observations?.filter((o) => o.type === 'Correction' && o.tags.filter((t) => t === 'MO')?.length > 0)?.length || 0
            }
            overlap='circular'
          >
            <PromptRadio promptType='Correction' round={true}>
              <input
                type='radio'
                id='correction-mo'
                name='correction-mo'
                value='correction-mo'
                checked={moPrompt === 'correction-mo'}
                onClick={radioOnChange}
              />
              <label htmlFor='correction-mo'>MO</label>
            </PromptRadio>
          </Badge>
        </Box>
      </Box>
      <Box sx={{ marginTop: '.98rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
        <FormControl fullWidth size='small'>
          <TextField
            placeholder='Type note'
            multiline={true}
            rows={2}
            InputLabelProps={{ shrink: true }}
            sx={{ ...styles.select }}
            InputProps={{ sx: { ...styles.select } }}
            inputProps={{ maxLength: 200 }}
            label=''
            id='correction-note'
            //error={noteError}
            size='small'
            value={note}
            onChange={(e) => {
              //setNoteError(false);
              setDirtyObject('correction');
              setNote(e.target.value);
            }}
          />
        </FormControl>

        <KitButton
          onClick={addToTimeline}
          size='sm'
          style={{
            backgroundColor: theme.palette.gold.main,
            marginTop: '1rem',
          }}
          disabled={!hasCorrectionPrompt && !hasMoPrompt}
        >
          <span>
            <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
          </span>
        </KitButton>
      </Box>
    </PromptContainer>
  );
};

export default Correction;
