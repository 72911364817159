import { z } from 'zod';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { callApi } from 'services/apiWrapper';

const addContactUsCrmLead = async (instance: ContactUsCrmLead): Promise<void> => {
  try {
    return await callApi('/api/contact-us-crm-lead', 'POST', instance);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const useAddContactUsCrmLead = (instance: ContactUsCrmLead) => {
  return useMutation({
    mutationFn: addContactUsCrmLead,
    mutationKey: ['addContactUsCrmLead', instance?.email],
    // https://tanstack.com/query/latest/docs/framework/react/guides/network-mode#network-mode-offlinefirst
    networkMode: 'offlineFirst',
    retry: false,
  });
};

export const ContactUsCrmLeadSchema = z.object({
  isBannerForm: z.boolean().default(false),
  description: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  title: z.string().optional(),
  email: z.string(),
  decision_maker: z.string().optional(),
  phone: z.string().optional(),

  option: z.string(),
  product: z.string(),
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  recaptcha_token: z.string().optional(),
  state: z.string(),
  other_agency: z.string().optional(),
  other_city: z.string().optional(),
  agencyType: z.string(),
  agency_id: z.string(),
});

export type ContactUsCrmLead = z.infer<typeof ContactUsCrmLeadSchema>;
