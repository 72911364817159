import { useQuery } from '@tanstack/react-query';
import { callApi } from 'services/apiWrapper';
import { OTHER_SCHOOL } from 'services/constants';

export const schoolLookupKeys = {
  districtsByState: (state) => ['districtsByState', state],
  schoolsByDistrict: (district) => ['schoolsByDistrict', district],
};

export const fetchDistrictsForState = async (state) => {
  if (!state) return [];
  const res = await callApi(`/api/db/district-list?state=${state}`);
  return res.results.map((r) => ({ label: r.name, value: r }));
};

export const useDistrictsByState = (state) => {
  return useQuery({
    queryKey: schoolLookupKeys.districtsByState(state),
    queryFn: () => fetchDistrictsForState(state),
    networkMode: 'offlineFirst',
  });
};

export const fecthSchoolsForDistrict = async (district) => {
  if (!district) return [];
  const res = await callApi(`/api/db/school-list?district-id=${district}`);
  const tempSchools = res.results.map((r) => ({ label: r.name, value: r }));
  tempSchools.push({ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL });
  return tempSchools;
};

export const useSchoolsByDistrict = (district) => {
  return useQuery({
    queryKey: schoolLookupKeys.schoolsByDistrict(district),
    queryFn: () => fecthSchoolsForDistrict(district),
    networkMode: 'offlineFirst',
  });
};
