import React from 'react';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';

const HTMLList = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container>
        <ListText className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.list)} />
      </Container>
      <MobileContainer>
        <ListText className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.mobileList)} />
      </MobileContainer>
    </Box>
  );
};

export default HTMLList;

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '-75px 20px -35px 20px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    margin: '-50px 10px 0 10px',
  },
}));

const ListText = styled('ul')(({ theme }) => ({
  color: theme.palette.common.black,
  '& li': {
    '&::before': {
      backgroundColor: '#000 !important',
    },
  },
}));
