import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { KitCard } from '@boystownorg/bi-cms-component-lib';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
import PleaseWait from '../common/PleaseWait';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const TeamMembers = ({ module }) => {
  const { fields } = module;

  const [isLoading, setIsLoading] = useState(false);

  if (fields.teamMembers && fields.teamMembers.length <= 0) {
    return (
      <NoTeamContainer>
        <NoTeamTitle>No team currently available.</NoTeamTitle>
      </NoTeamContainer>
    );
  }

  return (
    <Box sx={{ padding: '80px 0' }}>
      <PleaseWait isLoading={isLoading} />
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <Container>
          <TitleBox>
            <Heading3 color={fields.textColor} underline={true} uppercase={true}>
              {fields.title}
            </Heading3>
          </TitleBox>
          <CardGrid>
            {/* <Stack direction='row' spacing={3}> */}
            {fields.teamMembers &&
              fields.teamMembers.length > 0 &&
              fields.teamMembers.map((t, index) => (
                <KitCard plain key={t.fields.contentID} style={{ width: '250px' }}>
                  <DetailsContainer>
                    {t.fields.teamMemberImage && <Avatar alt={t.fields.name} src={t.fields.teamMemberImage.url} sx={{ width: 200, height: 200 }} />}
                    <MemberName>{t.fields.name}</MemberName>
                    <MemberTitle>{t.fields.title}</MemberTitle>
                  </DetailsContainer>
                </KitCard>
              ))}
            {/* </Stack> */}
          </CardGrid>
        </Container>
      </Box>
    </Box>
  );
};

export default TeamMembers;

const NoTeamContainer = styled('div')(({ theme }) => ({
  minHeight: 300,
}));

const NoTeamTitle = styled('div')(({ theme }) => ({
  fontSize: '1.05rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  margin: '0 30px 10px 30px',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TitleBox = styled('div')(({ theme }) => ({
  margin: '0 30px 10px 30px',
}));

const CardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0 0 0',
  flexWrap: 'wrap',
  gap: '0 3.00em',
}));

const ModuleImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  height: 'auto',
}));

const PostCategory = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.10rem',
  lineHeight: '1.2rem',
  fontWeight: 500,
}));

const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px 5px',
}));

const MemberName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '20px 0 0 0',
  fontSize: '1.00rem',
  lineHeight: '1.20rem',
  fontWeight: 600,
}));

const MemberTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '5px 0 10px 0',
  fontSize: '1.00rem',
  lineHeight: '1.20rem',
  fontWeight: 300,
}));
