import React, { useState } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getColorByAgilityName } from '../../services/agilityTranslations';

const BackBar = ({ module }) => {
  const { fields } = module;

  return (
    <Link href={fields.returnUrl.href} legacyBehavior>
      <PageLinkContainer style={{ backgroundColor: getColorByAgilityName() }}>
        <PageLinkIconContainer>
          <ArrowUpwardIcon style={{ color: fields.textandArrowColor }} />
        </PageLinkIconContainer>
        <PageLinkTextContainer>
          <PageLinkActionText>{fields.barText}</PageLinkActionText>
        </PageLinkTextContainer>
      </PageLinkContainer>
    </Link>
  );
};

export default BackBar;

const PageLinkContainer = styled.div`
  display: flex;
  min-width: 100%;
  maxheight: 60px;
  cursor: pointer;
`;

const PageLinkIconContainer = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '10px 5px 10px 50px',
}));

const PageLinkTextContainer = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '12px 0 10px 10px',
}));

const PageLinkActionText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  fontSize: '1.10rem',
  fontWeight: 300,
}));
