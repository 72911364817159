// package imports
import { useTheme } from '@emotion/react';
import { PieChart } from '@mui/x-charts/PieChart';

// local imports
import { Benchmarks } from '../StyledComponents';
import BenchmarkGroup from './BenchmarkGroup';
import { calcPercent } from 'services/numberUtils';

const PraiseBenchmarkGroup = ({ observationMetrics }) => {
  const theme = useTheme();

  return (
    <BenchmarkGroup type='Praise' total={observationMetrics.praises?.length || 0}>
      <Benchmarks>
        <div className='four-column-section' style={{ marginBottom: '1rem' }}>
          <div>General</div>
          <div className='right-align'>{observationMetrics.generalPraises}</div>
          <div className='right-align'>{calcPercent(observationMetrics.generalPraises, observationMetrics.praises.length)}%</div>
          <div className='pie' style={{ gridRow: '1 / 4', gridColumn: '4 / 4' }}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: observationMetrics.generalPraises, label: 'General Praise', color: theme.palette.obsPrevention.main },
                    { id: 1, value: observationMetrics.specificPraises, label: 'Specific Praise', color: theme.palette.obsPraise.main },
                    { id: 2, value: observationMetrics.effectivePraises, label: 'Effective Praise', color: theme.palette.gold.muted },
                  ],
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              slotProps={{ legend: { hidden: true } }}
              width={80}
              height={80}
            />
          </div>
          <div>Specific</div>
          <div className='right-align'>{observationMetrics.specificPraises}</div>
          <div className='right-align'>{calcPercent(observationMetrics.specificPraises, observationMetrics.praises.length)}%</div>
          <div>Effective</div>
          <div className='right-align'>{observationMetrics.effectivePraises}</div>
          <div className='right-align'>{calcPercent(observationMetrics.effectivePraises, observationMetrics.praises.length)}%</div>
        </div>

        <div className='four-column-section'>
          <div>Academic Praise</div>
          <div className='right-align'>{observationMetrics.academicPraises}</div>
          <div className='right-align'>{calcPercent(observationMetrics.academicPraises, observationMetrics.praises.length)}%</div>
          <div className='pie' style={{ gridRow: '1 / 3', gridColumn: '4 / 4' }}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: observationMetrics.academicPraises, label: 'Academic Praise', color: theme.palette.obsPrevention.main },
                    { id: 1, value: observationMetrics.behaviorPraises, label: 'Behavior Praise', color: theme.palette.obsPraise.main },
                  ],
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              slotProps={{ legend: { hidden: true } }}
              width={60}
              height={60}
            />
          </div>
          <div>Behavior Praise</div>
          <div className='right-align'>{observationMetrics.behaviorPraises}</div>
          <div className='right-align'>{calcPercent(observationMetrics.behaviorPraises, observationMetrics.praises.length)}%</div>
        </div>

        <div className='three-plus-one'>
          <div className='three-column-section'>
            <div>Skill Languages</div>
            <div className='right-align'>{observationMetrics.praiseSkillLanguages}</div>
            <div className='right-align'>{calcPercent(observationMetrics.praiseSkillLanguages, observationMetrics.praises.length)}%</div>
            <div>Specificity</div>
            <div className='right-align'>{observationMetrics.praiseSpecificity}</div>
            <div className='right-align'>{calcPercent(observationMetrics.praiseSpecificity, observationMetrics.praises.length)}%</div>
          </div>
          <div className='one-column-section mo'>
            <div className='mo-title'>Missed Opportunities</div>
            <div className='mo-value'>{observationMetrics.praiseMO}</div>
          </div>
        </div>
      </Benchmarks>
    </BenchmarkGroup>
  );
};

export default PraiseBenchmarkGroup;
