import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import ConfirmEndDialog from './ConfirmEndDialog';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';

const TimerStartDialog = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const [showConfirmEnd, setShowConfirmEnd] = useState(false);

  return (
    <>
      <Dialog maxWidth='sm' scroll='body' fullWidth={true} open={props.open} PaperComponent={DraggablePaperComponent}>
        <DialogTitle sx={{ backgroundColor: theme.palette.obsTimer.dark, color: theme.palette.common.black, padding: '8px 16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Observation Collection</Box>
        </DialogTitle>

        <DialogContent sx={{ marginTop: '1rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem' }}>Click to start timer.</Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <KitButton
            className='need-interaction'
            size='sm'
            style={{
              backgroundColor: buttonColor(theme, 'obsBlue.dark'),
              height: '45px',
              width: '165px',
            }}
            onClick={() => props.start()}
          >
            Start Observation Timer
          </KitButton>
          <Tooltip title='Click to cancel observation.' placement='top'>
            <KitButton
              className='need-interaction'
              size='sm'
              style={{
                backgroundColor: buttonColor(theme, 'btLightGrey.maing'),
                height: '45px',
                width: '110px',
              }}
              onClick={() => {
                router.push({
                  pathname: 'observation/landing',
                  query: { id: null },
                });
              }}
            >
              Cancel
            </KitButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <ConfirmEndDialog open={showConfirmEnd} closeConfirm={() => setShowConfirmEnd(false)} endObservation={() => props.endObservation()} />
    </>
  );
};

TimerStartDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  start: PropTypes.func,
  endObservation: PropTypes.func,
};
export default TimerStartDialog;
