// package imports
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
  DialogActions,
  MuiAlert,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSession } from 'next-auth/react';

// local imports
import ConfirmCancelDialog from 'components/common/ConfirmCancelDialog';
import { useObservationStore } from '../stores/observationStore';
import { GRADE_LIST, OBS_ACTIVITY_LIST, OBS_SUBJECT_LIST } from 'services/constants';
import { ObsStyles } from './StyledComponents';
import { useFetchObserversByEvent } from '../hooks/ObserverLookupHooks';
import PleaseWait from 'components/common/PleaseWait';
import { BadRequestError } from 'services/apiWrapper';
import { default as SavedSnack } from './AlertSnack';
import { logClientException } from 'appinsights/clientAppInsights';
import { useUpdateObservationInstance } from '../hooks/ObservationInstanceHooks';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const defaultToastAutoHide = 1500;

const SessionInfoEditDialog = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { data: session, status } = useSession();

  const [errorMessage, setErrorMessage] = useState(null);
  const [snackSave, setSnackSave] = useState({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });
  const [isDirty, setIsDirty] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [observerList, setObserverList] = useState([]);
  const [eventState, setEventState] = useState({
    classroom_type: 'Well-Managed Schools',
    teacher_first: '',
    teacher_last: '',
    grade: '',
    subject: '',
    room_number: '',
    number_of_students: '',
    activity: [],
    other_activity: '',
    other_subject: '',
    observer: '',
  });

  const { observation, setObservation } = useObservationStore();
  const observerQuery = useFetchObserversByEvent(observation?.event_id);
  const afterObservationMutate = (data) => {
    setSnackSave({ message: 'Session Info Updated', severity: 'success', autoHideDuration: defaultToastAutoHide });
  };

  const afterObservationMutateFail = (error) => {
    logClientException(error);
    if (error instanceof BadRequestError) {
      setErrorMessage(error.formatMessage());
    } else {
      setErrorMessage(defaultErrorMessage);
    }
    updateObservationMutation.reset();
  };

  const updateObservationMutation = useUpdateObservationInstance(observation, afterObservationMutate, afterObservationMutateFail);

  useEffect(() => {
    if (props.open) {
      if (observation) {
        const teacherParts = observation.teacher.split(',');
        setEventState({
          classroom_type: 'Well-Managed Schools',
          teacher_first: teacherParts[1]?.trim() ?? '',
          teacher_last: teacherParts[0]?.trim() ?? '',
          grade: observation.grade,
          subject: observation.subject,
          room_number: observation.room_number,
          number_of_students: observation.number_of_students,
          activity: observation.activity ?? [],
          other_activity: observation.other_activity ?? '',
          other_subject: observation.other_subject ?? '',
          observer_name: observation.observer_name ?? session.user ? `${session?.user?.lastName}, ${session?.user?.firstName}` : '',
          observer_email: observation.observer_email ?? session.user.email,
        });
      } else {
        resetEventState();
      }
    } else {
      resetEventState();
    }
  }, [observation, props.open, session]);

  useEffect(() => {
    if (observerQuery.isSuccess && observerQuery.isFetching === false) {
      const list = observerQuery.data || [];
      // sort list by label
      list.sort((a, b) => a.label.localeCompare(b.label));
      setObserverList(list);
    }
    if (observerQuery.isError) {
      logClientException(eventListQuery.error);
      if (observerQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (observerQuery.error instanceof BadRequestError) {
        setErrorMessage(observerQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [observerQuery]);

  const resetEventState = () => {
    setEventState({
      classroom_type: 'Well-Managed Schools',
      teacher_first: '',
      teacher_last: '',
      grade: '',
      subject: '',
      room_number: '',
      number_of_students: '',
      activity: [],
      other_activity: '',
      other_subject: '',
      observer_name: '',
      observer_email: '',
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const eventStateOnChange = (key, value) => {
    let canSave = true;
    let fieldError = false;

    if (key === 'number_of_students') {
      if (isNaN(value) || value < 1) {
        canSave = false;
        fieldError = true;
      }
    }
    if (key === 'observer_email') {
      // set both observer_email and observer_name
      const observer = observerList.find((o) => o.value === value);
      setEventState({ ...eventState, observer_email: value, observer_name: observer.label, [`${key}Error`]: fieldError, canSave: canSave });
    } else {
      setEventState({ ...eventState, [key]: value, [`${key}Error`]: fieldError, canSave: canSave });
    }
    setIsDirty(true);
  };

  const canSave = () => {
    let hasError = false;
    const event = eventState;

    if (!event.classroom_type || event.classroom_type.length === 0) {
      hasError = true;
      event.classroom_typeError = true;
    } else {
      event.classroom_typeError = false;
    }
    if (!event.teacher_first || event.teacher_first.length === 0) {
      hasError = true;
      event.teacher_firstError = true;
    } else {
      event.teacher_firstError = false;
    }
    if (!event.teacher_last || event.teacher_last.length === 0) {
      hasError = true;
      event.teacher_lastError = true;
    } else {
      event.teacher_lastError = false;
    }

    // either grade or subject is required
    if ((!event.grade || event.grade.length === 0) && (!event.subject || event.subject.length === 0)) {
      hasError = true;
      event.gradeError = true;
      event.subjectError = true;
      setErrorMessage('Please specify either a Grade or a Subject.');
    } else {
      event.gradeError = false;
      event.subjectError = false;
    }

    if (event.number_of_students?.length === 0 || isNaN(event.number_of_students) || event.number_of_students < 1) {
      hasError = true;
      event.number_of_studentsError = true;
    } else {
      event.number_of_studentsError = false;
    }
    if (!event.activity || event.activity.length === 0) {
      hasError = true;
      event.activityError = true;
    } else {
      event.activityError = false;
    }
    if (eventState.activity.indexOf('Other') > -1 && (!event.other_activity || event.other_activity.length === 0)) {
      hasError = true;
      event.other_activityError = true;
    } else {
      event.other_activityError = false;
    }

    if (event.subject === 'Other' && (!event.other_subject || event.other_subject.length === 0)) {
      hasError = true;
      event.other_subjectError = true;
    } else {
      event.other_subjectError = false;
    }

    if (!event.observer_email || event.observer_email.length === 0) {
      hasError = true;
      event.observer_emailError = true;
    } else {
      event.observer_emailError = false;
    }

    if (hasError) {
      setEventState({ ...event, canSave: false });
    }
    return !hasError;
  };

  const closeDialog = (needsPrompt) => {
    if (needsPrompt) {
      setConfirmOpen(true);
      return;
    }
    setConfirmOpen(false);
    setIsDirty(false);
    props.onClose();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog maxWidth='lg' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeDialog(isDirty)}>
      <DialogTitle sx={{ backgroundColor: theme.palette.obsAqua.main, color: theme.palette.white.main, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '30px' }}>Edit Session Info</Typography>

          <IconButton onClick={() => closeDialog(isDirty)}>
            <CancelIcon color='white' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '.90rem',
            paddingTop: '1.5rem',
          }}
        >
          <FormControl fullWidth>
            <TextField
              select
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              required={true}
              error={eventState.observer_emailError}
              size='small'
              labelId='observer-label'
              id='observer'
              value={eventState.observer_email}
              label='Observed By'
              onChange={(e) => {
                eventStateOnChange('observer_email', e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Choose Observer
              </MenuItem>

              {observerList?.map((e) => {
                return (
                  <MenuItem value={e.value} key={e.label}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <FormControl fullWidth>
            <RadioGroup
              row={true}
              aria-labelledby='classroom-type-label'
              name='classroom-type'
              value={eventState.classroom_type}
              onChange={(e) => eventStateOnChange('classroom_type', e.target.value)}
              error={eventState.classroom_typeError}
            >
              {['Well-Managed Schools', 'Specialized Classroom Management'].map((c, i) => {
                return (
                  <FormControlLabel
                    key={c}
                    value={c}
                    control={<Radio color='gold' checkedIcon={<CheckCircleIcon />} icon={<CheckCircleOutlineIcon color='gold' />} />}
                    label={c}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth size='small'>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              inputProps={{ maxLength: 100 }}
              label='Teacher First Name'
              id='teacher-first-name'
              size='small'
              value={eventState.teacher_first}
              required
              error={eventState.teacher_firstError}
              onChange={(e) => {
                eventStateOnChange('teacher_first', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth size='small'>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              inputProps={{ maxLength: 100 }}
              label='Teacher Last Name'
              id='teacher-last-name'
              size='small'
              value={eventState.teacher_last}
              required
              error={eventState.teacher_lastError}
              onChange={(e) => {
                eventStateOnChange('teacher_last', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth size='small'>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              inputProps={{ maxLength: 50 }}
              label='Room Number'
              id='room-number'
              size='small'
              value={eventState.room_number}
              onChange={(e) => {
                eventStateOnChange('room_number', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth size='small'>
            <TextField
              select
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              label='Grade'
              id='grade-select'
              error={eventState.gradeError}
              size='small'
              value={eventState.grade}
              onChange={(e) => {
                eventStateOnChange('grade', e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Choose Grade
              </MenuItem>
              {GRADE_LIST.map((e) => {
                return (
                  <MenuItem value={e.value} key={e.value}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <FormControl fullWidth size='small'>
            <TextField
              select
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              label='Subject'
              id='subject-select'
              error={eventState.subjectError}
              size='small'
              value={eventState.subject}
              onChange={(e) => {
                eventStateOnChange('subject', e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Choose Subject
              </MenuItem>
              {OBS_SUBJECT_LIST.map((e) => {
                return (
                  <MenuItem value={e.value} key={e.value}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <FormControl fullWidth size='small' sx={{ display: eventState.subject === 'Other' ? 'inline-flex' : 'none' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ maxLength: 200 }}
              label='Other Subject'
              id='other-subject'
              error={eventState.other_subjectError}
              size='small'
              value={eventState.other_subject}
              required
              onChange={(e) => {
                eventStateOnChange('other_subject', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth size='small'>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ min: 1, max: 1000, type: 'number' }}
              label='Number of Students'
              id='num-students'
              error={eventState.number_of_studentsError}
              size='small'
              value={eventState.number_of_students}
              required
              onChange={(e) => {
                eventStateOnChange('number_of_students', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth size='small'>
            <InputLabel shrink required id='activity-select-label'>
              Activity{' '}
            </InputLabel>
            <Select
              id='activity-select'
              multiple
              sx={styles.select2}
              label='Activity'
              size='small'
              value={eventState.activity}
              onChange={(e) => {
                eventStateOnChange('activity', e.target.value);
              }}
              error={eventState.activityError}
              required
              input={<OutlinedInput notched label='Activity' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {OBS_ACTIVITY_LIST.map((e) => {
                return (
                  <MenuItem value={e.value} key={e.value}>
                    <Checkbox checked={eventState.activity.indexOf(e.value) > -1} />
                    <ListItemText primary={e.name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth size='small' sx={{ display: eventState.activity.indexOf('Other') > -1 ? 'inline-flex' : 'none' }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ maxLength: 200 }}
              label='Other Activity'
              id='other-activity'
              error={eventState.other_activityError}
              size='small'
              value={eventState.other_activity}
              required
              onChange={(e) => {
                eventStateOnChange('other_activity', e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <KitButton className='need-interaction' onClick={() => closeDialog(isDirty)} size='sm' color='primary'>
          Cancel
        </KitButton>
        <KitButton
          className='need-interaction'
          disabled={updateObservationMutation.isPending || !isDirty || snackSave.message !== null}
          onClick={() => {
            if (canSave()) {
              const newObservation = { ...observation };
              newObservation.teacher = `${eventState.teacher_last}, ${eventState.teacher_first}`;
              newObservation.grade = eventState.grade;
              newObservation.subject = eventState.subject;
              newObservation.room_number = eventState.room_number;
              newObservation.number_of_students = eventState.number_of_students;
              newObservation.activity = eventState.activity;
              newObservation.other_activity = eventState.other_activity;
              newObservation.other_subject = eventState.other_subject;
              newObservation.observer_name = eventState.observer_name;
              newObservation.observer_email = eventState.observer_email;
              setObservation(newObservation);
              if (props.doInfoUpdate) {
                updateObservationMutation.mutate();
              } else {
                closeDialog(false);
              }
            }
          }}
          size='sm'
          color='secondary'
        >
          Update
        </KitButton>
      </DialogActions>

      <ConfirmCancelDialog
        confirmMessage={'There are unsaved changes, are you sure you want to close?'}
        open={confirmOpen}
        handleYes={() => closeDialog(false)}
        handleClose={() => setConfirmOpen(false)}
        noButtonColor={theme.palette.obsAqua.main}
      />
      <PleaseWait open={observerQuery.isLoading || updateObservationMutation.isPending} />
      <SavedSnack
        message={snackSave.message}
        openSnack={snackSave.message === null ? false : true}
        onClose={() => {
          setSnackSave({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });
          closeDialog(false);
        }}
        severity={snackSave.severity}
        autoHideDuration={snackSave.autoHideDuration}
      />

      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

SessionInfoEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default SessionInfoEditDialog;
