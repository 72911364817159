// package imports
import { useTheme } from '@emotion/react';
import { PieChart } from '@mui/x-charts/PieChart';

// local imports
import { Benchmarks } from '../StyledComponents';
import BenchmarkGroup from './BenchmarkGroup';
import { calcPercent } from 'services/numberUtils';

const PreventionBenchmarkGroup = ({ observationMetrics }) => {
  const theme = useTheme();

  return (
    <BenchmarkGroup type='Prevention' total={observationMetrics.preventions?.length || 0}>
      <Benchmarks>
        <div className='four-column-section'>
          <div>Preventative Prompts</div>
          <div className='right-align'>{observationMetrics.preventativePrompts}</div>
          <div className='right-align'>{calcPercent(observationMetrics.preventativePrompts, observationMetrics.preventions.length)}%</div>
          <div className='pie' style={{ gridRow: '1 / 4', gridColumn: '4 / 4' }}>
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: observationMetrics.preventativePrompts,
                      label: 'Preventative Prompts',
                      color: theme.palette.obsPrevention.main,
                    },
                    { id: 1, value: observationMetrics.plannedTeachings, label: 'Planned Teachings', color: theme.palette.obsPraise.main },
                    { id: 2, value: observationMetrics.blendedTeachings, label: 'Blended Teachings', color: theme.palette.gold.muted },
                  ],
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              slotProps={{ legend: { hidden: true } }}
              width={80}
              height={80}
            />
          </div>
          <div>Planned Teaching</div>
          <div className='right-align'>{observationMetrics.plannedTeachings}</div>
          <div className='right-align'>{calcPercent(observationMetrics.plannedTeachings, observationMetrics.preventions.length)}%</div>
          <div>Blended Teaching</div>
          <div className='right-align'>{observationMetrics.blendedTeachings}</div>
          <div className='right-align'>{calcPercent(observationMetrics.blendedTeachings, observationMetrics.preventions.length)}%</div>
        </div>
        <div className='three-plus-one'>
          <div className='three-column-section'>
            <div>Skill Languages</div>
            <div className='right-align'>{observationMetrics.preventionSkillLanguages}</div>
            <div className='right-align'>{calcPercent(observationMetrics.preventionSkillLanguages, observationMetrics.preventions.length)}%</div>
            <div>Specificity</div>
            <div className='right-align'>{observationMetrics.preventionSpecificity}</div>
            <div className='right-align'>{calcPercent(observationMetrics.preventionSpecificity, observationMetrics.preventions.length)}%</div>
          </div>
          <div className='one-column-section mo'>
            <div className='mo-title'>Missed Opportunities</div>
            <div className='mo-value'>{observationMetrics.preventionMO}</div>
          </div>
        </div>
      </Benchmarks>
    </BenchmarkGroup>
  );
};

export default PreventionBenchmarkGroup;
