import { KitBadge } from '@boystownorg/bi-cms-component-lib';

const DefaultModule = (props) => {
  const { fields, properties, contentID } = props.module;
  //console.log('DefaultModule', props);
  return (
    <div>
      {process.env.environment === 'development' && (
        <>
          <KitBadge>{properties.definitionName}</KitBadge> is not defined!
        </>
      )}
    </div>
  );
};

export default DefaultModule;
