import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const Heading1 = (props) => {
  const Homepage = styled.span`
    @media screen and (min-width: 992px) {
      ${props.homepage === false ? 'text-transform: uppercase;' : 'text-transform: titlecase;'}
      ${props.homepage === false ? 'font-size: 50px;' : 'font-size: 68px;'}
      ${props.homepage === false ? 'line-height: 54px;' : 'line-height: 68px;'}
      ${props.homepage === false ? 'font-weight: 700;' : 'font-weight: 300;'}
    }
    @media screen and (max-width: 991px) {
      ${props.homepage === false ? 'text-transform: uppercase;' : 'text-transform: titlecase;'}
      ${props.homepage === false ? 'font-size: 34px;' : 'font-size: 42px;'}
      ${props.homepage === false ? 'line-height: 34.5px;' : 'line-height: 46.2px;'}
      ${props.homepage === false ? 'font-weight: 700;' : 'font-weight: 300;'}
    }
  `;

  const { color, children, underline, uppercase, ...rest } = props;
  return (
    <Typography {...rest} component='h1' color={color}>
      <Homepage>{children}</Homepage>
    </Typography>
  );
};

export default Heading1;
