import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { COMMON_ROUTES } from 'services/constants';
import { useRouter } from 'next/router';
import CloseIcon from '@mui/icons-material/Close';

// NOTE: This is no longer a drawer per DXE-701.

const GatedResourceDrawer = ({ open, remainingCount, toggleDrawer }) => {
  const theme = useTheme();

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={open}
      onClose={() => toggleDrawer(false)}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <CloseIcon
          sx={{
            color: theme.palette.common.white,
            opacity: 0.38,
            position: 'absolute',
            top: '-5px',
            right: '21px',
            cursor: 'pointer',
            '&:hover': { color: theme.palette.common.white, opacity: 1, fontSize: '25px' },
          }}
          onClick={() => toggleDrawer(false)}
        />
      </div>
      <DialogContent>
        {/* NOTE: Moved background here to allow the buttons to be on top. If it is set directly on the DialogContent, the buttons don't work. */}
        <div
          style={{
            width: { xs: '325px', sm: '700px', md: '800px', lg: '800px', xl: '800px' },
            zIndex: -1,
            backgroundSize: '30%',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 45% bottom 70%',
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.8)), url("https://content.liftwithboystown.org/designer-images/free-reseources/signup-girls-background.jpg")`,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        >
          <div style={{ zIndex: 10 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Typography
                sx={{
                  fontSize: '28px',
                  fontWeight: 600,
                  color: theme.palette.primary.light,
                  margin: { xs: '30px 0 30px 0', sm: '75px 0 75px 0' },
                  textAlign: 'center',
                }}
              >
                You have <span style={{ color: '#000', textDecoration: 'underline' }}>{remainingCount}</span> Free <br />
                {remainingCount === 1 ? 'Resource' : 'Resources'} Remaining
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
                gap: { xs: 1, sm: 4 },
                margin: '5px 0 0 0',
              }}
            >
              <CtaBox bgColor={theme.palette.warmRed.main} headerText='Create a Free Account' path='/signin?nl=1' />
              <CtaBox bgColor={theme.palette.brandedAqua.main} headerText='View Plans' path={COMMON_ROUTES.PricingPage} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
                gap: { xs: 1, sm: 4 },
              }}
            >
              <CtaDesc desc='To access free unlimited resources.' />
              <CtaDesc desc='Sign up for online, virtual or in-person training.' />
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const CtaBox = ({ bgColor, headerText, path }) => {
  const router = useRouter();

  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        width: { xs: '140px', sm: '230px' },
        height: { xs: '130px', sm: '90px' },
        margin: { xs: 0, sm: '5px 0', sm: 0, sm: '5px 0' },
        padding: { xs: '5px 15px 25px 15px', sm: '5px 20px 25px 20px' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: '#fff',
        cursor: 'pointer',
        textTransform: 'uppercase',
      }}
      onClick={() => {
        router.push(path);
      }}
    >
      <Typography component='div' sx={{ fontSize: { xs: '18px', sm: '20px' }, fontWeight: 700, marginTop: '22px', textAlign: 'center' }}>
        {headerText}
      </Typography>
    </Box>
  );
};

const CtaDesc = ({ desc }) => {
  const router = useRouter();

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '225px' },
        height: '130px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#000',
      }}
      onClick={() => {
        router.push(path);
      }}
    >
      <Typography component='div' sx={{ fontSize: { xs: '16px', sm: '18px' }, fontWeight: 400, marginTop: '22px', textAlign: 'center' }}>
        {desc}
      </Typography>
    </Box>
  );
};

const AccentImage = styled.img`
  position: absolute;
  left: 0;
  top: -40%;
  height: 190%;
  opacity: 0.3;
  mix-blend-mode: multiply;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export default GatedResourceDrawer;
