// package imports
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';

// local imports
import OffLineQueueTrack from './OfflineQueueTrack';

const ObservationHeader = ({ title, icon, headerAccentImageUrl, size = 'small', children }) => {
  const theme = useTheme();

  const sizeMap = {
    small: {
      height: { xs: '94px', sm: '120px' },
    },
    medium: {
      height: { xs: '140px', sm: '170px' },
    },
    large: {
      height: { xs: '200px', sm: '220px' },
    },
    xlarge: {
      height: { xs: '315px', sm: '315px' },
    },
  };

  const imageSizeMap = {
    small: {
      height: { xs: '125px', sm: '161px', lg: '0px' },
    },
    medium: {
      height: { xs: '155px', sm: '200px', lg: '0px' },
    },
    large: {
      height: { xs: '210px', sm: '250px', lg: '0px' },
    },
    xlarge: {
      height: { xs: '337px', sm: '337px', lg: '0px' },
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: theme.palette.obsAqua.main,
        height: sizeMap[size].height,
        position: 'relative',
        top: { xs: '-17px', sm: 0 },
        marginBottom: '2.5rem',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: imageSizeMap[size].height,
          position: 'relative',
          top: { xs: '7px', md: '15px' },
          backgroundImage: `url("${headerAccentImageUrl}")`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '25px', marginLeft: '2rem', marginRight: '2rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {icon && icon}
            <Typography
              component='h1'
              sx={{
                fontSize: { xs: '30px', sm: '40px' },
                fontWeight: 700,
                lineHeight: { xs: '40px', sm: '50px' },
                color: '#fff',
              }}
            >
              {title}
            </Typography>
            <Box sx={{ marginLeft: 'auto' }}>
              <OffLineQueueTrack />
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default ObservationHeader;
