import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { DateTime } from 'luxon';
import { renderHTML } from '@agility/nextjs';
import CheckIcon from '@mui/icons-material/Check';
import Carousel from 'react-material-ui-carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { logServerException } from 'appinsights/clientAppInsights';
import { KitGridContainer, KitGridItem, KitCard, KitCardHeader, KitCardBody, KitCardFooter, KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from '../common/PleaseWait';
import { hasModuleAccess } from 'services/subscriptionUtils';
import { isFeatureSwitchOnClientAsync } from 'services/featureSwitchClientUtils';
import { fetchProductRecordFromList } from 'services/productUtils';
import PlanOptionLearnMore from './pageComponents/PlanOptionLearnMore';
import DialogWithX from './pageComponents/DialogWithX';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, TZ_MAP } from 'services/constants';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import Heading6 from 'components/common/subcomponents/Typography/Heading6';
import BodyCopy from 'components/common/subcomponents/Typography/BodyCopy';
import BodyCopyMobile from 'components/common/subcomponents/Typography/BodyCopyMobile';
import { formatDate } from 'services/stringUtils';

// todo this will need to be refactored when we have more than just WMS for online plans...
const IND_PLAN_NAME = '12-Month Online Access';

const ARLO_BASE_URL = process.env.ARLO_BASE_URL ?? 'https://boystown.arlo.co/api/2012-02-01/pub/resources/eventsearch/?region=LED';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const PlanOptionList = (props) => {
  const matchesLg = useMediaQuery('screen and (min-width: 992px)');
  const { customData, module, page } = props;
  const { fields } = module;
  const options = customData?.options;

  // console.log(JSON.stringify(module, null, 2));

  const theme = useTheme();
  const router = useRouter();
  const { data: session, status } = useSession();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [indSubEnabled, setIndSubEnabled] = useState(false);
  const [clientType, setClientType] = React.useState('educator');

  const [seriesSelected, setSeriesSelected] = useState(null);
  const [contentOptions, setContentOptions] = useState([]);
  const [virtualSessions, setVirtualSessions] = useState(null);
  const [virtualSessions2, setVirtualSessions2] = useState(null);

  const [product, setProduct] = useState('');

  const [showDialog, setShowDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [planClicked, setPlanClicked] = useState(null);

  const [productFound, setProductFound] = useState(null);
  const [planFound, setPlanFound] = useState(null);
  const [learnMoreAccentImage, setLearnMoreAccentImage] = useState(null);
  const [learnMoreTitle, setLearnMoreTitle] = useState('');
  const [learnMoreDescription, setLearnMoreDescription] = useState('');
  const [learnMoreIntroduction, setLearnMoreIntroduction] = useState('');
  const [learnMoreBenefits, setLearnMoreBenefits] = useState('');
  const [learnMoreDisclaimer, setLearnMoreDisclaimer] = useState('');
  const [learnMoreShowCalculator, setLearnMoreShowCalculator] = useState(false);

  const [subscriptions, setSubscriptions] = useState([]);
  const [renewalSubscription, setRenewalSubscription] = useState(null);
  const [renewalProductChecks, setRenewalProductChecks] = useState(0);

  useEffect(() => {
    const checkIndSubEnabled = async () => {
      setIndSubEnabled(await isFeatureSwitchOnClientAsync('Individual_Subscription'));
    };

    checkIndSubEnabled();

    // Fetch client type from local storage.
    if (
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('client-type') &&
      window.localStorage.getItem('client-type').length > 0
    ) {
      setClientType(window.localStorage.getItem('client-type'));
    }
  }, []);

  useEffect(() => {
    const featuresDictionary = Object.fromEntries(options.map((f) => [f.contentID, f.features]));
    setContentOptions(featuresDictionary);
  }, [options]);

  useEffect(() => {
    if (session) {
      if (session.user) {
        setSubscriptions(session?.user?.subscriptions);
      }
    }
  }, [session]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('client-type') &&
      window.localStorage.getItem('client-type').length > 0
    ) {
      window.localStorage.setItem('client-type', clientType);
    }
  }, [clientType]);

  const subscribe = (plan) => {
    // console.log(plan);
    const purchase = {
      planName: plan.planName,
      product: 'wms',
      planPriceAmount: plan.individualPriceAmount,
      clientType: clientType,
    };
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('plan-to-purchase', JSON.stringify(purchase));
    }

    // if logged in, collect payment in page...
    if (session?.user) {
      router.push('/pricing-pages/plan-purchase');
      return;
    }

    // user is not logged in, send them to signin...
    router.push('/signin?subscribe=1');
  };

  // This useEffect will evaluate if a product page has recently been viewed, and, if so, use that to select the
  // product to highlight. If not, it will default to the first one (WMS).
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('product-name') &&
      window.localStorage.getItem('product-name').length > 0
    ) {
      setProduct(window.localStorage.getItem('product-name'));
      const productFromList = fetchProductRecordFromList(fields.productList, window.localStorage.getItem('product-name'));
      setSeriesSelected(productFromList);
    } else {
      setSeriesSelected(fields.productList[0]);
    }
  }, [fields]);

  const handleClientTypeClick = (type) => {
    if (type !== clientType) {
      setVirtualSessions(null);
      if (typeof window !== 'undefined') {
        window.location.hash = '';
      }
    }
    setClientType(type);
  };

  const learnMore = (product, planName) => {
    setSelectedProduct(product);
    setSelectedPlan(planName);

    // Preparing LearnMore dialog data
    setIsLoading(true);

    const productReq = module.fields.productList && module.fields.productList.find((p) => p.fields.seriesAbbreviation === product);
    setProductFound(productReq);

    const planReq = productReq && productReq.fields.productPlans && productReq.fields.productPlans.find((pp) => pp.fields.planName === planName);
    setPlanFound(planReq);

    if (planReq) {
      setLearnMoreTitle(planReq.fields.learnMoreTitle);
      setLearnMoreDescription(planReq.fields.learnMoreDescription);
      setLearnMoreAccentImage(planReq.fields.learnMoreAccentImage && planReq.fields.learnMoreAccentImage.url);
      setLearnMoreIntroduction(clientType === 'district' ? planReq.fields.learnMoreOptionIntroDistrict : planReq.fields.learnMoreOptionIntro);
      setLearnMoreBenefits(clientType === 'district' ? planReq.fields.learnMoreBenefitsDistrict : planReq.fields.learnMoreBenefits);
      setLearnMoreDisclaimer(planReq.fields.learnMoreDisclaimer);
      setLearnMoreShowCalculator(clientType === 'district' && planReq.fields.learnMoreShowCalculator === 'true');

      setIsLoading(false);
      setShowDialog(true);
    }
  };

  const handleActionButtonClick = async (planName, plan, product, price, expDate, renew) => {
    setPlanClicked(plan);
    if (renew) {
      router.push('/pricing-pages/plan-purchase?product=' + product + '&plan=' + planName + '&price=' + price + '&renewalDate=' + expDate);
    } else {
      if (clientType === 'educator' && planName === IND_PLAN_NAME) {
        subscribe(plan);
        return;
      }
      if (plan.arloCategoryId && plan.arloCategoryId.length > 0) {
        await loadArloSessions(plan);
        return;
      }
      router.push(`/contact-us?product=${seriesSelected.fields.seriesName}&trainingMethod=${planName}`);
    }
  };

  const handleNoSessionsContactUsClick = async (planName) => {
    router.push(`/contact-us?product=${seriesSelected.fields.seriesName}&trainingMethod=${planName}`);
  };

  const loadArloSessions = async (plan) => {
    let url = ARLO_BASE_URL;
    url +=
      '&fields=EventID,Name,AdvertisedOffers,Sessions.StartDateTime,Sessions.Location,Sessions.EndDateTime,Sessions.Summary,Sessions.Name,Sessions.AdvertisedOffers,RegistrationInfo,Categories,Presenters.Profile,TimeZone,Notice,Description';
    url += `&filter=templatecategoryid%3D${plan.arloCategoryId}`;
    url += '&top=200';
    url += '&sort=Sessions.StartDateTime';

    try {
      setIsLoading(true);
      let res = await fetch(url);
      if (res.status === 200) {
        const result = await res.json();
        const sessions = [];
        for (let i = 0; i < result.Items?.length; i++) {
          const item = result.Items[i];
          for (let j = 0; j < item.Sessions?.length; j++) {
            const session = item.Sessions[j];
            const startDT = DateTime.fromISO(session.StartDateTime).setZone(TZ_MAP.get(item.TimeZone));
            const endDT = DateTime.fromISO(session.EndDateTime).setZone(TZ_MAP.get(item.TimeZone));
            sessions.push({
              name: plan.arloWebinarName ? plan.arloWebinarName : seriesSelected?.fields?.seriesName,
              registerUri: item.RegistrationInfo?.RegisterUri,
              registerMessage: item.RegistrationInfo.RegisterMessage,
              time: `${startDT.toFormat('h:mm')} - ${endDT.toFormat('h:mm ZZZZ')}`,
              date:
                startDT.toFormat('LLL d yyyy') === endDT.toFormat('LLL d yyyy')
                  ? startDT.toFormat('LLL d, yyyy')
                  : `${startDT.toFormat('LLL d')} - ${endDT.toFormat('LLL d, yyyy')}`,
              notice: item.Notice,
              sessionName: session.Name,
              price: plan.useArloSession
                ? session.AdvertisedOffers[0]?.OfferAmount?.FormattedAmountTaxExclusive
                : item.AdvertisedOffers[0]?.OfferAmount?.FormattedAmountTaxExclusive,
              closeDate: startDT.minus({ days: 14 }).toFormat('LLL d, yyyy'),
            });
          }
        }
        setVirtualSessions(sessions);
      }
      // if there is a second categoryId, load it as well
      if (plan.arloCategoryId2 && plan.arloCategoryId2.length > 0) {
        url = url.replace(plan.arloCategoryId, plan.arloCategoryId2);
        res = await fetch(url);
        if (res.status === 200) {
          const result = await res.json();
          const sessions = [];
          for (let i = 0; i < result.Items?.length; i++) {
            const item = result.Items[i];
            for (let j = 0; j < item.Sessions?.length; j++) {
              const session = item.Sessions[j];
              const startDT = DateTime.fromISO(session.StartDateTime).setZone(TZ_MAP.get(item.TimeZone));
              const endDT = DateTime.fromISO(session.EndDateTime).setZone(TZ_MAP.get(item.TimeZone));
              sessions.push({
                name: plan.arloWebinarName2,
                registerUri: item.RegistrationInfo?.RegisterUri,
                registerMessage: item.RegistrationInfo.RegisterMessage,
                time: `${startDT.toFormat('h:mm')} - ${endDT.toFormat('h:mm ZZZZ')}`,
                date:
                  startDT.toFormat('LLL d yyyy') === endDT.toFormat('LLL d yyyy')
                    ? startDT.toFormat('LLL d, yyyy')
                    : `${startDT.toFormat('LLL d')} - ${endDT.toFormat('LLL d, yyyy')}`,
                notice: item.Notice,
                sessionName: session.Name,
                price: plan.useArloSession
                  ? session.AdvertisedOffers[0]?.OfferAmount?.FormattedAmountTaxExclusive
                  : item.AdvertisedOffers[0]?.OfferAmount?.FormattedAmountTaxExclusive,
                closeDate: startDT.minus({ days: 14 }).toFormat('LLL d, yyyy'),
              });
            }
          }
          setVirtualSessions2(sessions);
        }
      } else {
        setVirtualSessions2(null);
      }

      if (typeof window !== 'undefined') {
        window.location.hash = '#virtual-training-session-list';
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(ERROR_MESSAGES.Default);
      logClientException(error);
    }
  };

  const formatSessionList = (sessions) => {
    return (
      <Box sx={{ maxWidth: { xs: '100%', lg: '1200px' }, width: '100%' }}>
        {sessions && sessions.length > 0 && (
          <Box
            sx={{
              bgcolor: theme.palette.primary.light,
              color: theme.palette.common.white,
              borderRadius: { sm: 'none', md: '12px 12px 0px 0px' },
              width: '100%',
              mt: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '75px',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '26px',
              padding: '0 5px',
            }}
          >
            {sessions && sessions.length > 0 && sessions[0].name}
          </Box>
        )}
        <KitGridContainer
          style={{
            justifyContent: sessions && sessions.length > 1 ? 'left' : 'space-evenly',
            display: matchesLg ? 'flex' : 'none',
            marginTop: '20px',
            rowGap: '20px',
          }}
        >
          {sessions &&
            sessions.map((s, i) => {
              return (
                <KitGridItem xs={12} md={4} key={i}>
                  <Box sx={{ width: '290px', marginTop: '20px' }}>
                    {s.sessionName && <Heading6>{s.sessionName}</Heading6>}
                    <Heading6 sx={{ marginBottom: '10px' }}>{s.date}</Heading6>
                    <BodyCopy>{s.time}</BodyCopy>
                    <BodyCopy>{s.notice}</BodyCopy>
                    <BodyCopy>Registration closes on {s.closeDate}</BodyCopy>
                    <BodyCopy sx={{ marginBottom: '15px' }}>Cost: {s.price}</BodyCopy>
                    <a href={s.registerUri} target='_blank' rel='noreferrer'>
                      <KitButton color='success' round size='sm'>
                        {s.registerMessage}
                      </KitButton>
                    </a>
                  </Box>
                </KitGridItem>
              );
            })}
          {(!sessions || sessions.length === 0) && (
            <>
              <KitGridItem xs={12} align='center'>
                {fields && fields.noSessionsMessage && fields.noSessionsMessage.length > 0
                  ? fields.noSessionsMessage
                  : 'We didn’t find any upcoming sessions. Please contact us for additional scheduling options.'}
              </KitGridItem>
              <KitGridItem xs={12} align='center'>
                <KitButton
                  color='success'
                  round
                  onClick={() => handleNoSessionsContactUsClick(planClicked.planName)}
                  style={{ marginBottom: '20px' }}
                >
                  Contact Us
                </KitButton>
              </KitGridItem>
            </>
          )}
        </KitGridContainer>
        <Box sx={{ display: matchesLg ? 'none' : 'flex', justifyContent: 'center', marginTop: '20px' }}>
          {(!sessions || sessions.length === 0) && (
            <>
              <KitGridContainer align='center'>
                <KitGridItem xs={12} align='center'>
                  {fields && fields.noSessionsMessage && fields.noSessionsMessage.length > 0
                    ? fields.noSessionsMessage
                    : 'We didn’t find any upcoming sessions. Please contact us for additional scheduling options.'}
                </KitGridItem>
                <KitGridItem xs={12} align='center' style={{ marginTop: 20 }}>
                  <KitButton
                    color='success'
                    round
                    onClick={() => handleNoSessionsContactUsClick(planClicked.planName)}
                    style={{ marginBottom: '20px', maxWidth: '200px' }}
                  >
                    Contact Us
                  </KitButton>
                </KitGridItem>
              </KitGridContainer>
            </>
          )}
          {sessions && sessions.length > 0 && (
            <Carousel
              autoPlay={false}
              cycleNavigation={true}
              navButtonsAlwaysVisible={true}
              navButtonsProps={{
                style: {
                  backgroundColor: 'rgba(0, 59, 92, 0.3)',
                  borderRadius: '25px',
                },
              }}
              sx={{ width: '315px', height: '250px' }}
            >
              {sessions &&
                sessions.map((s, i) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '311px',
                      }}
                    >
                      {s.sessionName && <Heading6>{s.sessionName}</Heading6>}
                      <Heading6 sx={{ marginBottom: '8px' }}>{s.date}</Heading6>
                      <BodyCopyMobile>{s.time}</BodyCopyMobile>
                      <BodyCopyMobile>{s.notice}</BodyCopyMobile>
                      <BodyCopyMobile>Registration closes on {s.closeDate}</BodyCopyMobile>
                      <BodyCopyMobile sx={{ marginBottom: '10px' }}>Cost: {s.price}</BodyCopyMobile>
                      <a href={s.registerUri} target='_blank' rel='noreferrer'>
                        <KitButton color='success' round size='sm'>
                          {s.registerMessage}
                        </KitButton>
                      </a>
                    </Box>
                  );
                })}
            </Carousel>
          )}
        </Box>
      </Box>
    );
  };

  const getVirtualSessionList = () => {
    return (
      <Slide direction='up' in={true} mountOnEnter unmountOnExit>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', alignItems: 'center', mt: '50px' }}>
          <Heading3 color='#000' underline={true} uppercase={true}>
            Virtual Training Sessions
          </Heading3>
          {formatSessionList(virtualSessions)}
          {virtualSessions2 && virtualSessions2.length > 0 && formatSessionList(virtualSessions2)}
        </Box>
      </Slide>
    );
  };

  const getSubscriptionInfo = (p) => {
    if (subscriptions && subscriptions.length > 0 && renewalProductChecks === 0) {
      const product = subscriptions
        .sort((a, b) => {
          return b.end_date - a.end_date;
        })
        .find((s) => s.product === p && s.entity_type === 'user');
      setRenewalSubscription(product);
      setRenewalProductChecks(1);
    }
  };

  const MobileContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0 0 20px 0;
    }
  `;

  const CarouselContainer = styled.div`
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      // width: 100%;
      margin: 0px 2% 5px 3%;
      width: 25rem;
    }
  `;

  const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (min-width: 1001px) {
      width: 100%;
      margin: 0 0 5% 0;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin: 0 0 5% 0;
    }
  `;

  const ProductsTitle = styled.div`
    color: #000;
    text-transform: uppercase;
    border-bottom: 1px dashed #000;
    text-align: center;
    @media screen and (min-width: 1001px) {
      margin: 20px;
      width: 475px;
      font-size: 2.625rem;
      line-height: 2.875rem;
      font-weight: 600;
    }
    @media screen and (max-width: 1000px) {
      margin: 5px;
      width: 365px;
      font-size: 2rem;
      line-height: 2.25rem;
      font-weight: 600;
    }
  `;

  const OptionsTitle = styled.div`
    color: #000;
    text-transform: uppercase;
    border-bottom: 1px dashed #000;
    text-align: center;
    @media screen and (min-width: 1001px) {
      margin: 20px;
      width: 360px;
      font-size: 2.625rem;
      line-height: 2.875rem;
      font-weight: 600;
    }
    @media screen and (max-width: 1000px) {
      margin: 5px;
      width: 270px;
      font-size: 2rem;
      line-height: 2.25rem;
      font-weight: 600;
    }
  `;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @media screen and (min-width: 991px) {
      width: 95%;
      flex-direction: row;
    }
    @media screen and (max-width: 990px) {
      width: 100%;
      flex-direction: column;
    }
  `;

  const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1001px) {
      margin: 35px 0 5px 0;
      width: 100%;
    }
    @media screen and (max-width: 1000px) {
      margin: 20px 0;
      width: 100%;
    }
  `;

  const SeriesRow = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 0.2;
    width: 100%;
  `;

  const SeriesBlock = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:active {
      background-color: rgba(63, 164, 240, 0.08);
    }
    &:hover {
      background-color: rgba(63, 164, 240, 0.08);
    }
    @media screen and (min-width: 1001px) {
      width: 160px;
      height: 160px;
    }
    @media screen and (min-width: 1200px) {
      width: 170px;
      height: 140px;
    }
    @media screen and (min-width: 1400px) {
      width: 180px;
      height: 120px;
    }
    @media screen and (max-width: 1000px) {
      width: 150px;
      height: 140px;
      border: 1px solid #000;
      border-radius: 6px;
      margin: 5px;
    }
  `;

  const SeriesImg = styled.img`
    position: absolute;
    top: 15px;
  `;

  const SeriesTitle = styled.div`
    position: absolute;
    top: 75px;
    color: #000;
    width: 90%;
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.225rem;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.225rem;
    font-weight: 600;
  `;

  const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const PricingCardGrid = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1;
    width: 100%;
    @media screen and (max-width: 991px) {
      display: none;
    }
  `;

  const FeatureRow = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 3px 0 8px 0;
  `;

  const subscribeButton = (p) => {
    getSubscriptionInfo(p.product);
    let label = p.actionButtonLabel;
    let disabled = false;
    let buttonColor = 'success';
    let isRenew = false;
    let renewDate = null;
    if (clientType === 'district') {
      label = p.actionButtonDistrictLabel;
      disabled = p.districtButtonDisabled;
    } else {
      // check individual subscription feature switch
      if (p.planName === IND_PLAN_NAME && !indSubEnabled) {
        disabled = true;
        label = 'Coming Soon';
      } else {
        disabled = p.buttonDisabled;
        if (session?.user && p.planName === IND_PLAN_NAME && hasModuleAccess(session, 'wms')) {
          const type = renewalSubscription && renewalSubscription.entity_type;
          renewDate = renewalSubscription && renewalSubscription.end_date;

          if (p.individualPriceAmount > 0 && p.actionButtonLabel === 'Purchase' && type === 'user') {
            label = <span>Renew Now</span>;
            disabled = false;
            buttonColor = 'success';
            isRenew = true;
          } else {
            label = (
              <span>
                <CheckIcon /> Current Plan
              </span>
            );
            disabled = true;
            buttonColor = 'info';
          }
        }
      }
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <KitButton
          color={buttonColor}
          round
          onClick={() => handleActionButtonClick(p.planName, p, p.product, p.individualPriceAmount, renewDate, isRenew)}
          style={{ marginBottom: '20px', maxWidth: '200px' }}
          disabled={disabled}
        >
          {label}
        </KitButton>
        {isRenew && <aside>Your current subscription expires on {formatDate(renewDate)}.</aside>}
      </div>
    );
  };

  const isPlanButtonEnabled = (clientType, buttonEnabled, buttonEnabledForDistrict) => {
    // ORIGINAL CODE:
    // disabled={clientType === 'district' ? p.fields.districtButtonDisabled : p.fields.buttonDisabled}
    // p.fields.actionButtonEnabledforDistrict === 'true' ? false : true
    // p.fields.actionButtonEnabled === 'true' ? false : true
    if (clientType === 'district') {
      if (buttonEnabledForDistrict === 'true') {
        return false;
      } else {
        return true;
      }
    } else {
      if (buttonEnabled === 'true') {
        return false;
      } else {
        return true;
      }
    }
  };

  const SelectSeries = (series) => {
    setVirtualSessions(null);
    if (typeof window !== 'undefined') {
      window.location.hash = '';
    }
    setSeriesSelected(series);
    if (
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('product-name') &&
      window.localStorage.getItem('product-name').length > 0
    ) {
      window.localStorage.setItem('product-name', series.fields.seriesName);
    }
  };

  function DisplayPricingOptionInCarousel(props) {
    return (
      <KitCard
        key={props.option.fields.contentID}
        plain
        style={{
          width: '27em',
          margin: '0 0 5% 2%',
          borderRadius: '10px',
          background: '#FFF',
          boxShadow: '0px 18px 23px rgba(0,0,0,0.2)',
        }}
      >
        <KitCardBody plain style={{ minHeight: '570px' }}>
          <PlanTitleContainer style={{ borderRadius: '10px 10px 0 0', margin: -5 }}>
            {props.option.fields.displayPlanName && (
              <PlanTitle className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(props.option.fields.displayPlanName)} />
            )}
          </PlanTitleContainer>
          <PlanPrice>{clientType === 'district' ? props.option.fields.districtPrice : props.option.fields.price}</PlanPrice>
          <ul style={{ margin: 0, padding: 0, textAlign: 'left', width: '100%' }}>
            {contentOptions &&
              Object.entries(contentOptions[props.option.contentID.toString()]).map(([k, v]) => {
                return (
                  <PricingListItem key={k}>
                    <FeatureRow>
                      <img src={'/assets/arrow-icon-green.svg'} alt='' style={{ width: '15px', margin: '2px 10px 2px 1px' }} />
                      <Feature>{v.fields.featureName}</Feature>
                    </FeatureRow>
                  </PricingListItem>
                );
              })}
          </ul>
        </KitCardBody>
        <KitCardFooter pricing style={{ justifyContent: 'center' }}>
          <FooterFrame>
            <LearnMoreLinkContainer>
              <LearnMoreLink onClick={(e) => learnMore(props.option.fields.product, props.option.fields.planName)}>LEARN MORE</LearnMoreLink>
            </LearnMoreLinkContainer>{' '}
            {subscribeButton(props.option.fields)}
          </FooterFrame>
        </KitCardFooter>
      </KitCard>
    );
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box sx={{ padding: '80px 0' }}>
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <PleaseWait isLoading={isLoading} />
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          pl: '10px',
          pr: '10px',
          maxWidth: { sm: '600px', md: '900px', lg: '1200px', xl: '1500px' },
        }}
      >
        <Container>
          <ProductsTitle>{fields.title}</ProductsTitle>
          <ProductsIntro>Select a category below to view the pricing plans for the training options.</ProductsIntro>
          <ListContainer>
            <ContentContainer>
              <SeriesRow>
                {fields.productList &&
                  fields.productList.length > 0 &&
                  fields.productList
                    .sort((a, b) => {
                      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                      return 0;
                    })
                    .map((series, index) => {
                      return (
                        <>
                          <SeriesBlock
                            key={index}
                            onClick={(e) => SelectSeries(series)}
                            style={{
                              backgroundColor:
                                (seriesSelected && seriesSelected.fields.seriesName.toLowerCase()) ===
                                  (series && series.fields.seriesName.toLowerCase()) && 'rgba(63, 164, 240, 0.08)',
                            }}
                          >
                            <SeriesImg src={series.fields.seriesIcon && series.fields.seriesIcon.url} alt={series.fields.seriesName} />
                            <SeriesTitle style={{ color: seriesSelected === series ? '#cf451f' : '#000' }}>{series.fields.seriesName}</SeriesTitle>
                          </SeriesBlock>
                        </>
                      );
                    })}
              </SeriesRow>
            </ContentContainer>
          </ListContainer>
          {seriesSelected && seriesSelected.fields && seriesSelected.fields.learnMoreLink && seriesSelected.fields.learnMoreLink.href && (
            <Link href={seriesSelected.fields.learnMoreLink.href} legacyBehavior>
              <KitButton color='info' simple style={{ margin: '5px 30px 30px 30px', fontWeight: 400, padding: '10px 35px', fontSize: '0.90rem' }}>
                <ButtonText>Learn more about {seriesSelected.fields.learnMoreLink.text}</ButtonText>
              </KitButton>
            </Link>
          )}
        </Container>

        <KitGridContainer style={{ margin: '0 0 20px 0' }}>
          <KitGridItem xs={12} sx={{ ml: 'auto', mr: 'auto', textAlign: 'center' }}>
            <Typography variant='h6' sx={{ color: theme.palette.grey[500] }}></Typography>
            <KitButton
              round
              size='lg'
              onClick={(e) => handleClientTypeClick('educator')}
              style={{
                color: clientType === 'educator' ? '#fff' : '#000',
                backgroundColor: clientType === 'educator' ? theme.palette.primary.light : '#fff',
                width: '200px',
              }}
            >
              Educator
            </KitButton>
            <KitButton
              round
              size='lg'
              onClick={(e) => handleClientTypeClick('district')}
              style={{
                color: clientType === 'district' ? '#fff' : '#000',
                backgroundColor: clientType === 'district' ? theme.palette.primary.light : '#fff',
                margin: '15px 30px',
                width: '200px',
              }}
            >
              School/District
            </KitButton>
          </KitGridItem>
        </KitGridContainer>

        <PricingCardGrid>
          {seriesSelected &&
            seriesSelected.fields &&
            seriesSelected?.fields.productPlans
              .sort((a, b) => {
                if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                return 0;
              })
              .map((p, i) => {
                return (
                  <KitCard
                    key={p.contentID}
                    plain
                    style={{
                      width: '27em',
                      margin: 20,
                      minHeight: '768px',
                      borderRadius: '10px',
                      background: '#FFF',
                      boxShadow: '0px 18px 23px rgba(0,0,0,0.2)',
                    }}
                  >
                    <KitCardBody plain style={{ minHeight: '580px' }}>
                      <PlanTitleContainer style={{ borderRadius: '10px 10px 0 0', margin: -5 }}>
                        {p.fields.displayPlanName && (
                          <PlanTitle className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(p.fields.displayPlanName)} />
                        )}
                      </PlanTitleContainer>
                      <PlanPrice>{clientType === 'district' ? p.fields.districtPrice : p.fields.price}</PlanPrice>
                      <ul style={{ margin: 0, padding: 0, textAlign: 'left', width: '100%' }}>
                        {contentOptions &&
                          Object.entries(contentOptions[p.contentID.toString()]).map(([k, v]) => {
                            return (
                              <PricingListItem key={k}>
                                <FeatureRow>
                                  <img src={'/assets/arrow-icon-green.svg'} alt='' style={{ width: '15px', margin: '2px 10px 2px 1px' }} />
                                  <Feature>{v.fields.featureName}</Feature>
                                </FeatureRow>
                              </PricingListItem>
                            );
                          })}
                      </ul>
                    </KitCardBody>
                    <KitCardFooter pricing style={{ justifyContent: 'center' }}>
                      <FooterFrame>
                        <LearnMoreLinkContainer>
                          <LearnMoreLink onClick={(e) => learnMore(p.fields.product, p.fields.planName)}>LEARN MORE</LearnMoreLink>
                        </LearnMoreLinkContainer>{' '}
                        {subscribeButton(p.fields)}
                      </FooterFrame>
                    </KitCardFooter>
                  </KitCard>
                );
              })}
        </PricingCardGrid>
        <MobileContainer>
          <CarouselContainer>
            <Carousel>
              {seriesSelected &&
                seriesSelected.fields &&
                seriesSelected?.fields.productPlans
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((p, i) => <DisplayPricingOptionInCarousel key={i} option={p} />)}
            </Carousel>
          </CarouselContainer>
        </MobileContainer>
        <div id='virtual-training-session-list'>{virtualSessions && getVirtualSessionList()}</div>
      </Box>
      <DialogWithX
        size='lg'
        content={
          <PlanOptionLearnMore
            headerImage={learnMoreAccentImage}
            title={learnMoreTitle}
            description={learnMoreDescription}
            introduction={learnMoreIntroduction}
            benefits={learnMoreBenefits}
            disclaimer={learnMoreDisclaimer}
            showCalculator={learnMoreShowCalculator}
          />
        }
        ariaLabel='Learn More Dialog'
        showValue={showDialog}
        showMethod={setShowDialog}
      />
    </Box>
  );
};

// This is called during server-side rendering (not part of NextJS).
PlanOptionList.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  const api = agility;

  try {
    // get plans
    let raw = await api.getContentList({
      referenceName: 'planoptionlist',
      expandAllContentLinks: true,
      languageCode,
      take: 100,
    });

    let options = raw.items.map((p) => {
      let option = {
        contentID: p.contentID,
        planName: p.fields.planName,
        displayPlanName: p.fields.displayPlanName || null,
        planPrice: p.fields.price,
        planPriceAmount: p.fields.individualPriceAmount === undefined ? 0 : p.fields.individualPriceAmount,
        districtPrice: p.fields.districtPrice,
        sortOrder: p.fields.sortOrder,
        actionButtonLabel: p.fields.actionButtonLabel,
        actionButtonDistrictLabel: p.fields.actionButtonDistrictLabel,
        buttonDisabled: p.fields.actionButtonEnabled === 'true' ? false : true,
        districtButtonDisabled: p.fields.actionButtonEnabledforDistrict === 'true' ? false : true,
        features: p.fields.optionFeatures,
      };
      return option;
    });

    return {
      options,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default PlanOptionList;

const PlanTitleContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.primary.light,
  minHeight: '110px',
  padding: '25px 20px',
  margin: 0,
}));

const PlanTitle = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
}));

const ProductsIntro = styled('div')(({ theme }) => ({
  margin: '15px',
  textAlign: 'center',
  fontSize: '1.00rem',
  lineHeight: '1.424rem',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const PlanPrice = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: '#428095',
  fontWeight: 300,
  fontSize: '1.875rem',
  lineHeight: '2.625rem',
  padding: '35px 0 25px 0',
}));

const LearnMoreLinkContainer = styled('div')(({ theme }) => ({
  margin: '-5px auto 20px auto',
}));

const LearnMoreLink = styled('a')(({ theme }) => ({
  color: theme.palette.common.black,
  display: 'block',
  textDecoration: 'underline',
  position: 'relative',
  bottom: 5,
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    margin: '20px 0 0 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px 0 0 0',
  },
}));

const Feature = styled('span')(({ theme }) => ({
  fontSize: '1.00rem',
  lineHeight: '1.15rem',
  color: theme.palette.grey[700],
  width: '90%',
}));

const LearnMoreTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  alignText: 'center',
}));

const LearnMoreText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
}));

const LearnMoreTextLink = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  color: '#08A38D',
  textDecoration: 'underline',
  fontSize: '1.25rem',
  lineHeight: '2.4rem',
}));

const FooterFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignText: 'center',
  marginTop: '10px',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '10px 5px',
}));

const SeriesDescription = styled('div')(({ theme }) => ({
  margin: '5px 10px 10px 0',
  fontSize: '1.00rem',
  lineHeight: '1.625rem',
  width: '90%',
  [theme.breakpoints.up('sm')]: {
    padding: '20px 25px 0 10px',
    minHeight: '220px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 25px 0 10px',
    minHeight: '120px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 25px 0 10px',
    minHeight: '120px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 5px 0 5px',
    minHeight: '240px',
  },
}));

const PricingListItem = styled.li`
  text-align: left;
  width: 93%;
  @media screen and (min-width: 401px) {
    padding: 8px 0 5px 5px;
    margin: 5px 0 0 15px;
    border-bottom: 1px solid #c3c3c3;
    :last-child {
      border-bottom: none;
    }
  }
  @media screen and (max-width: 400px) {
    padding: 5px;
    margin: 5px 0 5px 10px;
    border-bottom: 1px solid #c3c3c3;
    :last-child {
      border-bottom: none;
    }
  }
`;
const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (min-width: 900px) {
    width: 100%;
    margin: 0 0 5% 0;
  }
  @media screen and (max-width: 899px) {
    display: none;
  }
`;

const Title = styled.div`
  color: #000;
  text-transform: uppercase;
  border-bottom: 1px dashed #000;
  text-align: center;
  @media screen and (min-width: 993px) {
    margin: 20px;
    width: 475px;
    font-size: 2.625rem;
    line-height: 2.875rem;
    font-weight: 600;
  }
  @media screen and (max-width: 992px) {
    margin: 5px;
    width: 365px;
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 600;
  }
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  @media screen and (min-width: 999px) {
    width: 90%;
    flex-direction: row;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
  }
`;

const SeriesRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 95%;
`;

const SeriesImg = styled.img`
  position: absolute;
  top: 15px;
  // You cannot CSS style an SVG image you're loading using the <img> tag.
  // &:hover {
  //   line {
  //     stroke: #cf451f;
  //   }
  // }
`;

const MobileSeriesTitle = styled.div`
  color: #cf451f;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  @media screen and (min-width: 993px) {
    display: none;
  }
`;

const SeriesTitle = styled.div`
  position: absolute;
  top: 75px;
  color: #000;
  width: 90%;
  text-align: center;
  @media screen and (min-width: 993px) {
    font-size: 0.9rem;
    line-height: 1.225rem;
    font-weight: 600;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const SeriesContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 20px 0;
  @media screen and (min-width: 900px) {
    width: 80%;
  }
  @media screen and (max-width: 899px) {
    width: 90%;
  }
`;

const ButtonText = styled.div`
  margin-top: 15px;
  text-transform: none;
  text-decoration: underline;
`;

/*
const mockResp = {
  'StartIndex': 0,
  'Count': 2,
  'Items': [
    {
      'Notice': '30 minute lunch',
      'Categories': [
        {
          'CategoryID': 177,
          'Name': 'Well Managed Schools',
        },
      ],
      'Sessions': [
        {
          'EventID': 1542,
          'StartDateTime': '2023-03-19T09:00:00.0000000-05:00',
          'EndDateTime': '2023-03-20T16:00:00.0000000-05:00',
          'Location': {
            'Name': 'Online',
            'IsOnline': true,
          },
        },
      ],
      'RegistrationInfo': {
        'RegisterUri': 'https://registration.boystown.org/led/register?sgid=11cbefafea8445cebfab18a3f8fef888',
        'RegisterMessage': 'Register',
      },
      'RegistrationIsOpen': false,
      'EventID': 1542,
      'Name': 'WMS National Workshop',
      'Description': {
        'ContentType': 'application/xhtml',
        'Text':
          '<p><span>Through our two-day live virtual training, you will be able to work with an expert trainer and discuss strategies and approaches that align with your unique needs or concerns. This course offers our research-backed Model in a form that is accessible and rewarding with transformational results. The Model is a proactive, whole-child solution to possible classroom challenges.</span></p>',
      },
      'TimeZone': 'CDT',
      'TemplateCode': 'WMSN',
      'IsPrivate': false,
      'IsConfirmed': true,
      'AdvertisedOffers': [
        {
          'OfferID': 0,
          'IsDiscountOffer': false,
          'OfferAmount': {
            'CurrencyCode': 'USD',
            'AmountTaxExclusive': 415.0,
            'AmountTaxInclusive': 415.0,
            'FormattedAmountTaxExclusive': '$415.00',
            'FormattedAmountTaxInclusive': '$415.00',
          },
        },
      ],
    },
    {
      'Notice': '30 minute lunch',
      'Categories': [
        {
          'CategoryID': 177,
          'Name': 'Well Managed Schools',
        },
      ],
      'Sessions': [
        {
          'EventID': 1542,
          'StartDateTime': '2023-02-14T09:00:00.0000000-05:00',
          'EndDateTime': '2023-02-15T16:00:00.0000000-05:00',
          'Location': {
            'Name': 'Online',
            'IsOnline': true,
          },
        },
      ],
      'RegistrationInfo': {
        'RegisterUri': 'https://registration.boystown.org/led/register?sgid=11cbefafea8445cebfab18a3f8fef888',
        'RegisterMessage': 'Register',
      },
      'RegistrationIsOpen': false,
      'EventID': 1542,
      'Name': 'WMS National Workshop',
      'Description': {
        'ContentType': 'application/xhtml',
        'Text':
          '<p><span>Through our two-day live virtual training, you will be able to work with an expert trainer and discuss strategies and approaches that align with your unique needs or concerns. This course offers our research-backed Model in a form that is accessible and rewarding with transformational results. The Model is a proactive, whole-child solution to possible classroom challenges.</span></p>',
      },
      'TimeZone': 'CDT',
      'TemplateCode': 'WMSN',
      'IsPrivate': false,
      'IsConfirmed': true,
      'AdvertisedOffers': [
        {
          'OfferID': 0,
          'IsDiscountOffer': false,
          'OfferAmount': {
            'CurrencyCode': 'USD',
            'AmountTaxExclusive': 415.0,
            'AmountTaxInclusive': 415.0,
            'FormattedAmountTaxExclusive': '$415.00',
            'FormattedAmountTaxInclusive': '$415.00',
          },
        },
      ],
    },
    {
      'Notice': '30 minute lunch',
      'Categories': [
        {
          'CategoryID': 177,
          'Name': 'Well Managed Schools',
        },
      ],
      'Sessions': [
        {
          'EventID': 1542,
          'StartDateTime': '2023-03-17T09:00:00.0000000-05:00',
          'EndDateTime': '2023-03-18T16:00:00.0000000-05:00',
          'Location': {
            'Name': 'Online',
            'IsOnline': true,
          },
        },
      ],
      'RegistrationInfo': {
        'RegisterUri': 'https://registration.boystown.org/led/register?sgid=11cbefafea8445cebfab18a3f8fef888',
        'RegisterMessage': 'Register',
      },
      'RegistrationIsOpen': false,
      'EventID': 1542,
      'Name': 'WMS National Workshop',
      'Description': {
        'ContentType': 'application/xhtml',
        'Text':
          '<p><span>Through our two-day live virtual training, you will be able to work with an expert trainer and discuss strategies and approaches that align with your unique needs or concerns. This course offers our research-backed Model in a form that is accessible and rewarding with transformational results. The Model is a proactive, whole-child solution to possible classroom challenges.</span></p>',
      },
      'TimeZone': 'CDT',
      'TemplateCode': 'WMSN',
      'IsPrivate': false,
      'IsConfirmed': true,
      'AdvertisedOffers': [
        {
          'OfferID': 0,
          'IsDiscountOffer': false,
          'OfferAmount': {
            'CurrencyCode': 'USD',
            'AmountTaxExclusive': 415.0,
            'AmountTaxInclusive': 415.0,
            'FormattedAmountTaxExclusive': '$415.00',
            'FormattedAmountTaxInclusive': '$415.00',
          },
        },
      ],
    },
    {
      'Notice': '30 minute lunch',
      'Categories': [
        {
          'CategoryID': 177,
          'Name': 'Well Managed Schools',
        },
      ],
      'Sessions': [
        {
          'EventID': 1542,
          'StartDateTime': '2023-04-17T09:00:00.0000000-05:00',
          'EndDateTime': '2023-04-18T16:00:00.0000000-05:00',
          'Location': {
            'Name': 'Online',
            'IsOnline': true,
          },
        },
      ],
      'RegistrationInfo': {
        'RegisterUri': 'https://registration.boystown.org/led/register?sgid=11cbefafea8445cebfab18a3f8fef888',
        'RegisterMessage': 'Register',
      },
      'RegistrationIsOpen': false,
      'EventID': 1542,
      'Name': 'WMS National Workshop',
      'Description': {
        'ContentType': 'application/xhtml',
        'Text':
          '<p><span>Through our two-day live virtual training, you will be able to work with an expert trainer and discuss strategies and approaches that align with your unique needs or concerns. This course offers our research-backed Model in a form that is accessible and rewarding with transformational results. The Model is a proactive, whole-child solution to possible classroom challenges.</span></p>',
      },
      'TimeZone': 'CDT',
      'TemplateCode': 'WMSN',
      'IsPrivate': false,
      'IsConfirmed': true,
      'AdvertisedOffers': [
        {
          'OfferID': 0,
          'IsDiscountOffer': false,
          'OfferAmount': {
            'CurrencyCode': 'USD',
            'AmountTaxExclusive': 415.0,
            'AmountTaxInclusive': 415.0,
            'FormattedAmountTaxExclusive': '$415.00',
            'FormattedAmountTaxInclusive': '$415.00',
          },
        },
      ],
    },
  ],
};
*/
