// package imports
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';

// local imports
import { OTHER_SCHOOL } from 'services/constants';

const EventHeaderDetail = ({ event }) => {
  const theme = useTheme();

  if (!event) return <Box></Box>;
  return (
    <Box sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: theme.palette.white.main }}>
      {event.agency_id !== OTHER_SCHOOL.id && <div>{event.agency_name}</div>}
      <div>{`${event.district_name} (${event.state})`}</div>
      <div>{`${DateTime.fromISO(event.start_date)?.toFormat('MM/dd/yyyy')} - ${DateTime.fromISO(event.end_date)?.toFormat('MM/dd/yyyy')}`}</div>
    </Box>
  );
};

export default EventHeaderDetail;
