// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import HomeIcon from '@mui/icons-material/Home';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useSession } from 'next-auth/react';
import React from 'react';

// local imports
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import ObservationCTACard from './components/ObservationCTACard';
import ObservationHeader from './components/ObservationHeader';
import ObservationPageContainer from './components/ObservationPageContainer';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ObservationLandingPage = ({ module: { fields } }) => {
  const theme = useTheme();
  const { data: session, status } = useSession();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '2rem',
      }}
    >
      <ObservationHeader
        title={fields.title}
        icon={<HomeIcon sx={{ color: '#fff', fontSize: '40px', marginRight: '10px' }} />}
        headerAccentImageUrl={fields.headerAccentImage?.url}
      />
      <ObservationPageContainer>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            '@media (max-width: 767px)': {
              flexDirection: 'column',
              alignContent: 'center',
              alignItems: 'center',
              gap: '.5rem',
            },
          }}
        >
          <ObservationCTACard
            title={fields.newObservationTitle}
            text={fields.newObservationText}
            ctaLabel={fields.newObservationCallToAction[0]?.fields?.label}
            ctaLink={fields.newObservationCallToAction[0]?.fields?.link?.href}
            ctaColor={fields.newObservationCallToAction[0]?.fields?.style?.fields?.color}
            iconUrl={fields.newObservationIcon?.url}
          />
          <ObservationCTACard
            title={fields.eventTitle}
            text={fields.eventText}
            ctaLabel={fields.eventCallToAction[0]?.fields?.label}
            ctaLink={fields.eventCallToAction[0]?.fields?.link?.href}
            ctaColor={fields.eventCallToAction[0]?.fields?.style?.fields?.color}
            iconUrl={fields.eventIcon?.url}
          />
          <ObservationCTACard
            title={fields.manageEventsTitle}
            text={fields.manageEventsText}
            ctaLabel={fields.manageEventsCallToAction[0]?.fields?.label}
            ctaLink={fields.manageEventsCallToAction[0]?.fields?.link?.href}
            ctaColor={fields.manageEventsCallToAction[0]?.fields?.style?.fields?.color}
            iconUrl={fields.manageEventsIcon?.url}
          />
        </Box>
        {fields.downloadCTA[0] && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
            <KitButton
              target='_blank'
              rel='noopener noreferrer'
              href={fields.downloadCTA[0]?.fields?.link?.href}
              size='sm'
              style={{
                backgroundColor: buttonColor(theme, fields.downloadCTA[0]?.fields?.style?.fields?.color),
              }}
            >
              <span>
                <SaveAltIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />{' '}
                {fields.downloadCTA[0]?.fields?.label}
              </span>
            </KitButton>
          </Box>
        )}
        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationLandingPage;
