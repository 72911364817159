import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { states } from 'services/stringUtils';
import { OTHER_DISTRICT, OTHER_SCHOOL, LEAD_TITLES, PRODUCTS, NEW_USER_LEAD_TYPES } from 'services/constants';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { hasModuleAccess } from 'services/subscriptionUtils';
import { ROLE_CONST } from 'services/roleUtils';
import { getSessionStoreString } from 'services/storageUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const SFMC_NEW_PAID_INDIVIDUAL = 'SFMC_NEW_PAID_INDIVIDUAL';
const SFMC_NEW_PAID_SCHOOL = 'SFMC_NEW_PAID_SCHOOL';
const SFMC_NEW_FREE_INDIVIDUAL = 'SFMC_NEW_FREE_INDIVIDUAL';
const SFMC_NEW_PAID_ADMIN = 'SFMC_NEW_PAID_ADMIN';

const CompleteProfileDialog = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const [districtList, setDistrictList] = useState([]);
  const initialized = useRef(false);
  const [schoolList, setSchoolList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [districtDisabled, setDistrictDisabled] = useState(false);
  const [agreeTos, setAgreeTos] = useState(false);
  const [agreeTosTimestamp, setAgreeTosTimestamp] = useState(null);
  const [tosOpen, setTosOpen] = useState(false);

  const getLocalStoreString = (key) => {
    if (typeof window !== 'undefined') {
      return window?.localStorage?.getItem(key) || null;
    }
    return null;
  };
  const [userState, setUserState] = useState(() => {
    return getLocalStoreString('st');
  });
  const [districtId, setDistrictId] = useState(() => {
    return getLocalStoreString('did');
  });
  const [planToPurchase, setPlanToPurchase] = useState(null);

  const [userDialogState, setUserDialogState] = useState({
    state: null,
    district: null,
    school: null,
    position: '',
    ccCouponCode: null,
  });

  const backdrop = React.useMemo(
    () =>
      styled(Backdrop, {
        name: 'MuiModal',
        slot: 'Backdrop',
        overridesResolver: (props, styles) => {
          return styles.backdrop;
        },
      })({
        zIndex: -1,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: 'url("/assets/Adobe-hands-raised.jpg")',
        backgroundColor: 'rgb(48,75,102)',
      }),
    []
  );

  useEffect(() => {
    const init = async () => {
      setErrorMessage(null);

      let plan = null;
      try {
        plan = JSON.parse(getLocalStoreString('plan-to-purchase'));
      } catch (error) {
        console.log('failed to get plan', error);
      }
      if (plan) {
        setPlanToPurchase(plan);
      }

      // if the user's subscription has expired, etc, display the message
      if (props.user?.subscription?.message?.length > 0) {
        setErrorMessage(props.user?.subscription?.message);
      }

      if (props.user?.state?.length > 0 && props.user?.district_id?.length > 0) {
        let state;
        let district = null;
        let school = null;
        let sList = [];
        state = states.find((s) => s.value === props.user.state);
        district = await fetchDistrict(props.user.district_id);
        sList = await fetchSchoolsForDistrict(district);
        if (props.user?.school_id && props.user?.school_name) {
          school = { label: props.user?.school_name, value: sList.find((s) => s?.value?.id === props.user?.school_id)?.value };
        }

        setUserDialogState({ ...userDialogState, state, district, school, ccState: state });

        // district was matched at login (or via allow list), don't allow it to be changed
        setDistrictDisabled(true);
      } else {
        let state = null;
        let district = null;
        if (userState) {
          state = states.find((s) => s.value === userState);
        }
        if (districtId) {
          console.log('using ls did');
          district = await fetchDistrict(districtId);
          if (district) {
            setDistrictList([district]);
            await fetchSchoolsForDistrict(district);
          }
        } else if (state) {
          await fetchDistrictsForState(state);
        }

        setUserDialogState({ ...userDialogState, state, district, ccState: state });
      }
      initialized.current = true;
    };

    if (props.open && !initialized.current) {
      init();
    }
  }, [props.open, districtId, props.user]);

  const resetState = () => {
    setAgreeTos(false);
    setAgreeTosTimestamp(null);
    setErrorMessage(null);
    setDistrictList([]);
    setSchoolList([]);
    setUserDialogState({
      district: null,
      school: null,
      state: null,
      position: '',
      ccCouponCode: null,
      ccName: null,
      ccAddress: null,
      ccCity: null,
      ccState: null,
      ccZip: null,
      ccNumber: null,
      ccExpMonth: null,
      ccExpYear: null,
      ccCVV: null,
    });
  };

  const afterProfileComplete = () => {
    clearLocalStore();
    if (planToPurchase) {
      // send user to plan purchase page
      if (typeof window !== 'undefined') {
        window?.sessionStorage.removeItem('signing_in');
      }
      router.push('pricing-pages/plan-purchase');
      return;
    }
    if (hasModuleAccess({ user: props.user }, 'wms')) {
      router.push(PRODUCTS.find((p) => p.id === 'wms').homePage);
      return;
    } else if (hasModuleAccess({ user: props.user }, 'ai')) {
      router.push(PRODUCTS.find((p) => p.id === 'ai').homePage);
      return;
    } else {
      router.push('free-resources');
      return;
    }
    // if (typeof window !== 'undefined') {
    //   window.setTimeout(function () {
    //     console.log('reloading page after profile update');
    //     window.location.reload();
    //   }, 500);
    // }
  };

  const clearLocalStore = () => {
    if (typeof window !== 'undefined') {
      window?.localStorage.removeItem('st');
      window?.localStorage.removeItem('did');
    }
  };

  const fetchDistrict = async (district_id) => {
    try {
      if (district_id === OTHER_DISTRICT.id) {
        return { label: OTHER_DISTRICT.name, value: OTHER_DISTRICT };
      }
      setFetching(true);
      setSchoolList([]);
      let res = await callApi(`/api/db/district-list?did=${district_id}`);
      if (res.results[0]) {
        return { label: res.results[0].name, value: res.results[0] };
      }
      setFetching(false);
      setSchoolList([]);
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchDistrictsForState = async (state) => {
    try {
      setFetching(true);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      let dList = res.results.map((r) => ({ label: r.name, value: r }));
      dList = dList.filter((d) => {
        // filter out districts not eligible to be selected

        // no sso provider, but used allow list or email domain match
        if (
          ['none', 'other'].indexOf(d.value.sso_provider?.toLowerCase()) > -1 &&
          (d.value.use_allow_list || d.value.limit_by_email_domain) &&
          !d.value.is_seat_based
        ) {
          return false;
        }

        // if district is sso, subscription is active, and not seat based, filter out
        if (['google', 'clever'].indexOf(d.value.sso_provider?.toLowerCase()) > -1) {
          //} && d.value.subscriptions && d.value.subscriptions.length > 0) {
          let activeSubs = [];
          d.value.subscriptions?.forEach((s) => {
            if (s.end_date > Date.now() && s.start_date <= Date.now()) {
              activeSubs.push(s);
            }
          });
          if (activeSubs.length > 0 && !activeSubs.find((s) => s.is_seat_based)) {
            return false;
          }
          return true;
        }
        // if (props.user?.login_provider?.toLowerCase() === 'azure-ad-b2c') {
        //   if (['none', 'other'].indexOf(d.value.sso_provider?.toLowerCase()) === 0) {
        //     // has an sso provider
        //     return false;
        //   } else if (d.value.use_allow_list || d.value.limit_by_email_domain) {
        //     // no sso provider, but used allow list or email domain match
        //     return false;
        //   }
        // }
        return true;
      });
      dList.push({ label: OTHER_DISTRICT.name, value: OTHER_DISTRICT });
      setDistrictList(dList);
      setSchoolList([]);
      setFetching(false);
      return dList;
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district) => {
    try {
      if (district.value.id === OTHER_DISTRICT.id) {
        setSchoolList([{ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL }]);
        return;
      }
      setFetching(true);
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district.value.id}`);
      setFetching(false);
      const tempSchools = res.results.map((r) => ({ label: r.name, value: r }));
      tempSchools.push({ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL });
      setSchoolList(tempSchools);
      setFetching(false);
      return tempSchools;
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const getUpdatedUser = () => {
    //let user = JSON.parse(JSON.stringify(props.user));
    let user = { emailAddress: props.user.email };
    if (userDialogState.district) {
      user.district_id = userDialogState.district.value?.id;
      user.district_name = userDialogState.district.label;
    }
    if (userDialogState.school) {
      user.school_id = userDialogState.school.value?.id;
      user.school_name = userDialogState.school.label;
    }
    if (userDialogState.state) {
      user.state = userDialogState.state.value;
    }
    user.position = userDialogState.position;
    user.agreedToTermsTimestamp = agreeTosTimestamp;
    if (user.district_id === OTHER_DISTRICT.id) {
      user.other_city = userDialogState.other_city;
      user.other_agency = userDialogState.other_agency;
    }
    if (getSessionStoreString('utm_source')) {
      user.utm_source = getSessionStoreString('utm_source');
    }
    if (getSessionStoreString('utm_medium')) {
      user.utm_medium = getSessionStoreString('utm_medium');
    }
    if (getSessionStoreString('utm_campaign')) {
      user.utm_campaign = getSessionStoreString('utm_campaign');
    }
    if (getSessionStoreString('utm_content')) {
      user.utm_content = getSessionStoreString('utm_content');
    }

    return user;
  };

  const closeUserDialog = async () => {
    if (!canSaveProfile()) {
      return;
    }

    let user = getUpdatedUser();

    try {
      setFetching(true);
      if (props.user.subscriptions?.find((s) => s.entity_type !== 'user') && props.user.first_login) {
        // new user with a school or district subscription
        const sub = props.user.subscriptions?.find((s) => s.entity_type !== 'user'); // just grab first (might be more than one?)
        const product = PRODUCTS.find((p) => p.id === sub.product);
        await callApi('/api/db/user', 'PUT', {
          user: user,
          is_login: true,
          new_user_journey:
            [ROLE_CONST.SchoolAdmin, ROLE_CONST.DistrictAdmin].indexOf(props.user.role) > -1 ? SFMC_NEW_PAID_ADMIN : SFMC_NEW_PAID_SCHOOL,
          product: product.trans_desc,
        });
      } else if (props.user.first_login && planToPurchase) {
        // new user purchasing an individual subscription
        await callApi('/api/db/user', 'PUT', {
          user: user,
          is_login: true,
          new_user_journey: SFMC_NEW_FREE_INDIVIDUAL, // add to free journey to start, if they complete the purchase will be added to the paid.
          //product: PRODUCTS.find((p) => p.id == planToPurchase.product).trans_desc,
        });
      } else if (props.user.first_login && !planToPurchase) {
        // new user not purchasing and individual subscription
        console.log('new user - no paid subscription');
        await callApi('/api/db/user', 'PUT', {
          user: user,
          is_login: true,
          new_user_journey: SFMC_NEW_FREE_INDIVIDUAL,
        });
      } else {
        await callApi('/api/db/user', 'PUT', { user: user, is_login: true });
      }
      let event = {
        'event': 'signup',
        'userId': props.user.id,
      };
      addDataLayerEvent(event);
      resetState();
      afterProfileComplete();
      props.onClose();
      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSaveProfile = () => {
    let hasError = false;
    const user = userDialogState;
    Object.keys(user).forEach((key) => {
      if (key.endsWith('Error') && user.hasOwnProperty(key)) {
        delete user[key];
      }
    });
    if (!user.position || user.position.length === 0) {
      hasError = true;
      user.positionError = true;
    }
    if (!user.state || !user.state.value) {
      hasError = true;
      user.stateError = true;
    }
    if (!user.district || !user.district.value) {
      hasError = true;
      user.districtError = true;
    }
    if (isSchoolRequiredForTitle(user.position)) {
      if (!user.school || !user.school.value || user.school.value.length === 0) {
        hasError = true;
        user.schoolError = true;
      }
    }
    if (user.district?.value?.id === OTHER_DISTRICT.id) {
      if (!user.other_city || user.other_city.length === 0) {
        hasError = true;
        user.other_cityError = true;
      }
      if (!user.other_agency || user.other_agency.length === 0) {
        hasError = true;
        user.other_agencyError = true;
      }
    }

    if (!agreeTos) {
      hasError = true;
      user.agreeTosError = true;
    }
    // learner launch requires coupon code, this probably won't always be the case!
    // if (!user.ccCouponCode || !user.ccCouponCode.length === 0) {
    //   hasError = true;
    //   user.ccCouponCodeError = true;
    // }
    setUserDialogState({ ...userDialogState, canSave: !hasError });

    return !hasError;
  };

  const isSchoolRequiredForTitle = (title) => {
    return LEAD_TITLES.find((t) => t.title === title)?.school_required;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      fullWidth={true}
      open={props.open}
      sx={{
        '& .MuiDialog-paper ': {
          overflow: 'visible',
        },
      }}
      BackdropComponent={backdrop}
    >
      <DialogTitle
        sx={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.light,
          textAlign: 'center',
          marginTop: '-40px',
          marginLeft: '24px',
          marginRight: '24px',
          marginBottom: '24px',
        }}
      >
        <Box
          sx={{
            padding: '10px',
          }}
        >
          <Typography sx={{ fontSize: '2.25rem', fontWeight: 300 }}>Welcome, {props.user?.firstName}!</Typography>
          Let&apos;s get your profile set-up
        </Box>
      </DialogTitle>

      <DialogContent>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        {/* <Typography sx={{ textAlign: 'center', fontSize: '.9rem', lineHeight: '1.25', mb: '10px' }}>
          As a bonus, you will automatically be signed up for our monthly eNewsletter. Boys Town has been working with kids and families for over a
          century, and we have taken what we've learned and developed research-proven training and resources for the classroom that you won't find
          anywhere else. You can unsubscribe at any time via email.
        </Typography> */}

        {/* <FormControlLabel
          fullWidth
          control={
            <Checkbox
              sx={{ color: userDialogState.agreeTosError ? '#d32f2f' : 'inherit' }}
              checked={agreeTos}
              onChange={(e) => {
                setAgreeTos(e.target.checked);
                setAgreeTosTimestamp(new Date().getTime());
              }}
            />
          }
          label={
            <span style={{ color: userDialogState.agreeTosError ? '#d32f2f' : 'inherit' }}>
              <span>
                I have read and understand the{' '}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setTosOpen(true);
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  terms and conditions.
                </span>
              </span>
            </span>
          }
        />
 */}
        <FormControl fullWidth>
          <InputLabel id='role-select-label' required error={userDialogState.positionError}>
            Title
          </InputLabel>
          <Select
            label='Title'
            name='type'
            labelId='role-select-label'
            id='role-select-value'
            value={userDialogState.position}
            error={userDialogState.positionError}
            required
            onChange={(e) => {
              setUserDialogState({ ...userDialogState, position: e.target.value });
            }}
          >
            {LEAD_TITLES.map((t) => {
              return (
                <MenuItem value={t.title} key={t.title}>
                  {t.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Autocomplete
          sx={{ marginTop: '7px', display: userState ? 'none' : 'block' }}
          disabled={districtDisabled}
          disablePortal
          options={states}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          value={userDialogState.state}
          onChange={async (e, v) => {
            if (v) {
              await fetchDistrictsForState(v);
              setUserDialogState({ ...userDialogState, state: v, district: null, school: null });
            }
          }}
          renderInput={(params) => <TextField required error={userDialogState.stateError} {...params} label='State' />}
        />

        <Autocomplete
          sx={{ marginTop: '7px', display: districtId ? 'none' : 'block' }}
          disablePortal
          options={districtList}
          fullWidth
          isOptionEqualToValue={(o, v) => o.value.id === v?.value?.id}
          disabled={districtDisabled || props.district_id || !userDialogState.state}
          value={userDialogState.district}
          onChange={async (e, v) => {
            if (v) {
              await fetchSchoolsForDistrict(v);
              if (v?.value?.id === OTHER_DISTRICT.id) {
                setUserDialogState({ ...userDialogState, district: v, school: { label: OTHER_SCHOOL.name, value: OTHER_SCHOOL } });
              } else {
                setUserDialogState({ ...userDialogState, district: v, school: null });
              }
            }
          }}
          renderInput={(params) => (
            <TextField disabled={!userDialogState.state} required error={userDialogState.districtError} {...params} label='School district' />
          )}
        />

        {userDialogState.district?.value?.id !== OTHER_DISTRICT.id && (
          <Autocomplete
            sx={{ marginTop: '7px' }}
            disablePortal
            options={schoolList}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            disabled={
              !userDialogState.district ||
              !schoolList ||
              schoolList.length == 0 ||
              userDialogState.school?.value?.subscriptions?.find((s) => s.is_seat_based && s.seat_list?.indexOf(props.user.email) > -1)
            }
            value={userDialogState.school}
            onChange={(e, v) => setUserDialogState({ ...userDialogState, school: v })}
            renderInput={(params) => (
              <TextField
                required={isSchoolRequiredForTitle(userDialogState.position)}
                error={userDialogState.schoolError}
                {...params}
                label='School'
              />
            )}
          />
        )}

        {userDialogState.district?.value?.id === OTHER_DISTRICT.id && (
          <div>
            <TextField
              value={userDialogState.other_city}
              error={userDialogState.other_cityError}
              required
              margin='dense'
              id='City'
              label='City'
              type='text'
              fullWidth
              variant='outlined'
              onChange={(e) => setUserDialogState({ ...userDialogState, other_city: e.target.value })}
              inputProps={{ maxLength: 100 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              value={userDialogState.other_agency}
              error={userDialogState.other_agencyError}
              required
              margin='dense'
              id='Agency'
              label='Agency'
              type='text'
              fullWidth
              variant='outlined'
              onChange={(e) => setUserDialogState({ ...userDialogState, other_agency: e.target.value })}
              inputProps={{ maxLength: 100 }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}

        <FormControlLabel
          fullWidth
          control={
            <Checkbox
              sx={{ color: userDialogState.agreeTosError ? '#d32f2f' : 'inherit' }}
              checked={agreeTos}
              onChange={(e) => {
                setAgreeTos(e.target.checked);
                setAgreeTosTimestamp(new Date().getTime());
              }}
            />
          }
          label={
            <span style={{ color: userDialogState.agreeTosError ? '#d32f2f' : 'inherit' }}>
              <span>
                I have read and understand the{' '}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setTosOpen(true);
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  terms and conditions.
                </span>
              </span>
            </span>
          }
        />

        <Typography sx={{ textAlign: 'center', fontSize: '.9rem', lineHeight: '1.25', mt: '10px' }}>
          As a bonus, you will automatically be signed up for our monthly eNewsletter. Boys Town has been working with kids and families for over a
          century, and we have taken what we&apos;ve learned and developed research-proven training and resources for the classroom that you
          won&apos;t find anywhere else. You can unsubscribe at any time via email.
        </Typography>

        <PleaseWait isLoading={fetching} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mb: '20px' }}>
        <KitButton onClick={() => closeUserDialog()} color='primary'>
          Save Profile
        </KitButton>
      </DialogActions>

      <Dialog maxWidth='sm' scroll='paper' fullWidth={false} open={tosOpen} onClose={() => setTosOpen(false)}>
        <DialogContent>
          <div className='prose' style={{ marginTop: '-50px' }} dangerouslySetInnerHTML={renderHTML(props.terms)}></div>
        </DialogContent>
        <DialogActions>
          <KitButton onClick={() => setTosOpen(false)} size='sm' round color='success'>
            OK
          </KitButton>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

CompleteProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};
export default CompleteProfileDialog;
