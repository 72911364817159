import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import truncate from 'truncate-html';
import Box from '@mui/material/Box';
import { KitButton, KitCard, KitCardBody, KitCardFooter } from '@boystownorg/bi-cms-component-lib';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SearchButton from 'components/common/SiteHeaderComponents/SearchButton';
import useSearchContext from 'components/common/SiteHeaderComponents/SearchContext';
import { useTheme } from '@emotion/react';
import { SEARCH_CATEGORIES } from 'components/common/SearchComponents/useAddSearch';
import { useRouter } from 'next/router';

const PostsListing = ({ module, customData }) => {
  const { posts } = customData;
  const searchInput = useRef(null);
  const [postList, setPostList] = useState([]);
  const [filter, setFilter] = useState('');
  const [showPosts, setShowPosts] = useState(6);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('lg'));
  const router = useRouter();

  const isSearchOpen = useSearchContext((s) => s.openMenu) === 'search';
  const toggleSearchOpen = useSearchContext((s) => s.toggle);
  const { setSearchCategory } = useSearchContext();

  useEffect(() => {
    let list = posts;
    if (filter && filter.length > 0) {
      list = list.filter(
        (l) =>
          (l.tagNames && l.tagNames.toString().toLowerCase().includes(filter.toLowerCase())) ||
          (l.content && l.content.toLowerCase().includes(filter.toLowerCase())) ||
          (l.title && l.title.toLowerCase().includes(filter.toLowerCase()))
      );
    }
    setPostList(list);
  }, [filter]);

  useEffect(() => {
    searchInput?.current?.focus();
  }, [postList]);

  if (posts.length <= 0) {
    return (
      <NoPostsContainer>
        <NoPostTitle>No posts currently available.</NoPostTitle>
      </NoPostsContainer>
    );
  }

  const displayReadMore = (str, len) => {
    if (str.length > len) {
      return true;
    }
    return false;
  };

  const Background = styled.div`
    background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0.2) 30.21%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Background>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <Grid container direction='row' style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} align='right' style={{ margin: '0 20px' }}>
            <SearchButton
              title='Search the Blog Posts'
              color='#000'
              isActive={isSearchOpen}
              onClick={() => {
                if (mobile) {
                  const nextSearchParams = new URLSearchParams();
                  nextSearchParams.set('c', SEARCH_CATEGORIES.find((c) => c.text.toLowerCase() === 'blogs').value);
                  router.push(`/search?${nextSearchParams}`);
                } else {
                  toggleSearchOpen('search');
                  setSearchCategory('1xblog');
                  if (typeof window !== 'undefined') {
                    window.scrollTo(0, 0);
                  }
                }
              }}
            />
            {/* <TextField
              key='filter-text-field'
              inputRef={searchInput}
              id='filter-field'
              type='search'
              hiddenLabel
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton aria-label='search icon'>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder='SEARCH THE BLOG'
              variant='standard'
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              clearable='true'
              style={{ minWidth: 400 }}
            /> */}
          </Grid>
        </Grid>
        <CardGrid style={{ margin: '20px, 10px' }}>
          {postList.map(
            (post, index) =>
              index < showPosts && (
                <Link href={post.url} key={post.url} legacyBehavior>
                  <a>
                    <KitCard
                      style={{
                        width: '23rem',
                        height: '565px',
                      }}
                    >
                      <React.Fragment key={post.contentID}>
                        {post.imageSrc && <ModuleImg alt={post.imageAlt} src={post.imageSrc} />}
                        <KitCardBody style={{ padding: '1rem' }}>
                          <PostTitle>{post.title}</PostTitle>
                          <PostDateAndAuthor>
                            {new Date(post.date).toLocaleDateString('en-us', { month: 'long', day: 'numeric' })} {post.authorName && 'by'}{' '}
                            {post.authorName}
                          </PostDateAndAuthor>
                          <PostContent>
                            {truncate(post.content, {
                              length: 150,
                              decodeEntities: true,
                              stripTags: true,
                              reserveLastWord: true,
                            })}{' '}
                            {displayReadMore(post.content, 150) && <ReadMoreLink>Read More</ReadMoreLink>}
                          </PostContent>
                        </KitCardBody>
                        <KitCardFooter>
                          <Tags>
                            <LocalOfferIcon style={{ marginRight: 8 }} /> {post.tagNames && post.tagNames.replace(',', ', ')}
                          </Tags>
                        </KitCardFooter>
                      </React.Fragment>
                    </KitCard>
                  </a>
                </Link>
              )
          )}
          {showPosts <= postList.length && (
            <MoreButton>
              <KitButton round size='lg' color='success' onClick={(e) => setShowPosts(showPosts + 6)}>
                View More
              </KitButton>
            </MoreButton>
          )}{' '}
        </CardGrid>
      </Box>
    </Background>
  );
};

const resolvePostUrls = function (sitemap, posts) {
  let dynamicUrls = {};
  posts.forEach((post) => {
    Object.keys(sitemap).forEach((path) => {
      if (sitemap[path].contentID === post.contentID) {
        dynamicUrls[post.contentID] = path;
      }
    });
  });
  return dynamicUrls;
};

PostsListing.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  // set up api
  const api = agility;

  try {
    // get sitemap...
    let sitemap = await api.getSitemapFlat({
      channelName: channelName,
      languageCode,
    });

    // get posts...
    let rawPosts = await api.getContentList({
      referenceName: 'posts',
      languageCode,
      contentLinkDepth: 2,
      depth: 2,
      take: 50,
    });

    // resolve dynamic urls
    const dynamicUrls = resolvePostUrls(sitemap, rawPosts.items);

    const posts = rawPosts.items.map((post) => {
      //category
      const category = post.fields.category?.fields.title || 'Uncategorized';

      // date
      const date = new Date(post.fields.date).toLocaleDateString();

      const url = dynamicUrls[post.contentID] || '#';

      // post image src
      let imageSrc = post.fields.image?.url || null;

      // post image alt
      let imageAlt = post.fields.image?.label || post.fields.title;

      // content
      const content = post.fields.content || null;

      // slug
      const slug = post.fields.slug || null;

      //author
      const authorID = post.fields.authorID || null;
      const authorName = post.fields.authorName || null;

      // tags
      const tagIDs = post.fields.tagIDs || null;
      const tagNames = post.fields.tagNames || null;

      return {
        contentID: post.contentID,
        title: post.fields.title,
        date,
        url,
        category,
        imageSrc,
        imageAlt,
        content,
        slug,
        authorID,
        authorName,
        tagIDs,
        tagNames,
      };
    });

    return {
      posts,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default PostsListing;

const NoPostsContainer = styled('div')(({ theme }) => ({
  minHeight: 300,
}));

const NoPostTitle = styled('div')(({ theme }) => ({
  fontSize: '1.05rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  margin: '0 15px 10px 15px',
}));

const CardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0 0 0',
  flexWrap: 'wrap',
  gap: '0.2em 1.25em',
}));

const ModuleImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  height: 'auto',
}));

const PostCategory = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.10rem',
  lineHeight: '1.2rem',
  fontWeight: 500,
}));

const PostDateAndAuthor = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: 'transparent',
  fontSize: '1.00rem',
  lineHeight: '1.1rem',
  paddingBottom: 10,
}));

const PostTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '10px 0 15px 0',
  fontSize: '1.20rem',
  lineHeight: '1.50rem',
  fontWeight: 700,
}));

const PostContent = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 10px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));

const ReadMoreLink = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  margin: '5px 0 5px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));

const Tags = styled('div')(({ theme }) => ({
  color: theme.palette.primary.light,
  backgroundColor: 'transparent',
  fontSize: '1.00rem',
  lineHeight: '1.20rem',
  fontWeight: 300,
  paddingTop: 10,
  borderTop: '0.10rem solid #D6DBDF',
  width: '100%',
}));

const MoreButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '-1% 0 5% 0',
  width: '100%',
}));
