import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Link from 'next/link';
import { headingColor } from 'services/styleUtils/headingStyleUtils';

const CalloutWithBackground = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    cursor: pointer;
    @media screen and (max-width: 1199px) {
      background-color: rgba(25, 118, 210, 1);
      position: relative;
      height: 240px;
      margin: 20px 0 30px 0;
    }
    @media screen and (max-width: 899px) {
      background-color: rgba(25, 118, 210, 1);
      position: relative;
      height: 160px;
      margin: 20px 0 30px 0;
    }
    @media screen and (min-width: 1200px) {
      background-color: ${headingColor(theme, fields.backgroundColor)};
      background-image: url('${fields.backgroundWallpaper.url}');
      position: relative;
      height: 265px;
      margin: 20px 0 50px 0;
    }
  `;

  const ImageContainer = styled.div`
    @media screen and (max-width: 1199px) {
      display: none;
    }
    @media screen and (min-width: 1200px) {
      margin-top: 32.2px;
      height: 235px;
      padding: 0;
    }
  `;

  const OptionImage = styled.div`
    @media screen and (max-width: 1199px) {
      display: none;
    }
    @media screen and (min-width: 1200px) {
      margin: 0;
      height: 235px;
    }
  `;

  // This holds the space in the flex grid for the CenterArea.
  const CenterSpaceHolder = styled.div`
    @media screen and (max-width: 1199px) {
      height: 95%;
      width: 61%;
    }
    @media screen and (max-width: 899px) {
      height: 95%;
      width: 70%;
    }
    @media screen and (min-width: 1200px) {
      position: relative;
      margin: 26px 0 0 -15px;
      background-color: transparent;
      height: 254.9px;
      padding: 25px;
      width: 700px;
    }
  `;

  const CenterArea = styled.div`
    @media screen and (max-width: 1199px) {
      height: 95%;
      width: 95%;
      background-color: rgba(25, 118, 210, 1);
    }
    @media screen and (max-width: 899px) {
      height: 98%;
      width: 95%;
      background-color: rgba(25, 118, 210, 1);
    }
    @media screen and (min-width: 1200px) {
      position: absolute;
      left: 2%;
      top: -10%;
      margin: 26px 0 0 -15px;
      background-color: ${headingColor(theme, fields.calloutPanelColor)};
      height: 254.9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 25px;
      width: 700px;
      &:after {
        border-bottom: solid 16px transparent;
        border-right: solid 16px transparent;
        border-left: solid 16px ${headingColor(theme, fields.calloutTriangleColor)};
        transform: translateX(-50%);
        content: '';
        position: absolute;
        top: 93.45%;
        left: 102.25%;
        height: 0;
        width: 0;
      }
    }
  `;

  const Title = styled.div`
    @media screen and (max-width: 1199px) {
      width: 400px;
      margin: 10px 20px;
      font-size: 1.975rem;
      line-height: 2.225rem;
      font-weight: 600;
      color: ${fields.calloutHeaderFontColor};
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    @media screen and (max-width: 899px) {
      width: 300px;
      margin: 10px 10px 0 10px;
      font-size: 1.425rem;
      line-height: 1.875rem;
      font-weight: 600;
      color: ${fields.calloutHeaderFontColor};
      height: 98%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    @media screen and (min-width: 1200px) {
      width: 600px;
      margin: 10px;
      font-size: 2.625rem;
      line-height: 2.875rem;
      font-weight: 600;
      color: ${fields.calloutHeaderFontColor};
    }
  `;

  const SubTitle = styled.div`
    @media screen and (max-width: 1199px) {
      width: 90%;
      margin: 10px 20px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: ${fields.textFontColor};
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 1200px) {
      width: 600px;
      margin: 10px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: ${fields.textFontColor};
    }
  `;

  const IconContainer = styled.div`
    min-height: 80px;
    @media screen and (max-width: 1199px) {
      margin: 90px 0 0 30px;
    }
    @media screen and (max-width: 899px) {
      margin: 45px 0 0 30px;
    }
    @media screen and (min-width: 1200px) {
      margin: 110px 0 0 30px;
    }
  `;

  const IconImage = styled.div`
    margin: 0;
    margin-bottom: 10px;
    width: 80px;
  `;

  return (
    <Link href={fields.iconUrl.href} target={fields.iconUrl.target} legacyBehavior>
      <Container>
        <ImageContainer>
          <OptionImage>
            <img src={fields.backgroundImage?.url} alt={fields.backgroundImage?.label} />
          </OptionImage>
        </ImageContainer>

        <CenterSpaceHolder>
          <CenterArea>
            <Title>{fields.calloutHeading}</Title>
            <SubTitle>{fields.calloutText}</SubTitle>
          </CenterArea>
        </CenterSpaceHolder>
        <IconContainer>
          <IconImage>
            <img src={fields.backgroundIcon?.url} alt={fields.backgroundIcon?.label} />
          </IconImage>
        </IconContainer>
      </Container>
    </Link>
  );
};

export default CalloutWithBackground;

const BackgroundImg = styled('img')(({ theme }) => ({
  borderRadius: '6px',
  display: 'block',
  width: '20rem',
  height: 'auto',
}));
