import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import ModuleCard from 'components/common/ModuleCard';
import { logServerException } from 'appinsights/clientAppInsights';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import Link from 'next/link';
import Carousel from 'react-material-ui-carousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { hasModuleAccess } from '../../services/subscriptionUtils';
import { useSession } from 'next-auth/react';
import { COMMON_ROUTES } from 'services/constants';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const NTRWMSModuleListing = (props) => {
  const { customData, module, page } = props;

  const theme = useTheme();
  const scrollElement = useRef(null);
  const { data: session, status } = useSession();

  const [scrollBegin, setScrollBegin] = useState(true);
  const [scrollEnd, setScrollEnd] = useState(false);

  const [showPage, setShowPage] = useState(true);

  const cardSize = 320;

  const listProps = {
    list: customData.mods,
    page,
  };

  // If session, check if has module details access.
  useEffect(() => {
    if (session) {
      if (hasModuleAccess(session, 'wms')) {
        setShowPage(false);
      } else {
        setShowPage(true);
      }
    }
  }, [session]);

  const sideScroll = (speed, distance, step) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      scrollElement.current.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);

    if (scrollElement.current.scrollLeft === 0) setScrollBegin(true);
    if (Math.floor(scrollElement.current.scrollWidth - scrollElement.current.scrollLeft) <= scrollElement.current.offsetWidth) {
      setScrollEnd(true);
      setScrollBegin(true);
    } else {
      setScrollBegin(false);
      setScrollBegin(false);
    }
  };

  // This will check slide event and checks for scroll end
  const slideCheck = () => {
    if (scrollElement.current.scrollLeft === 0) setScrollBegin(true);
    if (Math.floor(scrollElement.current.scrollWidth - scrollElement.current.scrollLeft) <= scrollElement.current.offsetWidth - cardSize) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  useEffect(() => {
    if (showPage && scrollElement) {
      if (scrollElement.current.scrollLeft === 0) setScrollBegin(true);
      if (scrollElement.current && scrollElement?.current?.scrollWidth === scrollElement?.current?.offsetWidth) {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
      return () => {};
    }
  }, [showPage, scrollElement?.current?.scrollWidth, scrollElement?.current?.offsetWidth]);

  const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
    }
    @media screen and (max-width: 992px) {
      margin: 0 0 25px 0;
    }
  `;

  const TitleBox = styled.div`
    margin: 75px 20px 0 20px;
  `;

  const CarouselContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      width: 100vw;
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  const MobileCarouselContainer = styled.div`
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
      height: 470px;
      margin-bottom: 55px;
    }
  `;

  const CarouselWindow = styled.div`
    margin: 20px 0;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    @media screen and (min-width: 992px) {
      color: ${theme.palette.common.black};
      font-size: 2.3rem;
      line-height: 2.05rem;
      width: 90vw;
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  const CardCarousel = styled.div`
    background-color: transparent;
    max-height: 650px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0.5em;
    @media screen and (min-width: 992px) {
      max-width: 300vw;
      overflow-y: hidden;
      overflow-x: scroll;
      scroll-behavior: smooth;

      -webkit-overflow-scrolling: touch;

      ::-webkit-scrollbar {
        width: 5px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: #fff;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #fff;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #fff;
      }
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  `;

  function DisplayInCarousel(props) {
    return (
      <KitCard
        key={props.course.url}
        style={{
          opacity: props.course.hasPlayableSub ? 1 : 0.5,
          boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
          borderRadius: '6px',
          width: '360px',
          height: '470px',
          margin: '0 auto 10px auto',
        }}
      >
        <React.Fragment>
          {props.course.fields?.moduleImage && <ModuleImg alt={props.course.fields?.moduleName} src={props.course.fields?.moduleImage?.url} />}
          <KitCardBody style={{ padding: '5px 15px' }}>
            <CardBodyContainer>
              <ModuleName>{props.course.fields?.moduleName}</ModuleName>
              <ModuleDescription>{props.course.fields?.seriesDescription.substr(0, 180)}</ModuleDescription>
            </CardBodyContainer>
          </KitCardBody>
        </React.Fragment>
      </KitCard>
    );
  }

  return (
    <>
      {showPage && (
        <>
          <CenterContainer>
            <TitleBox>
              <Heading3 color='#000000' underline={true} uppercase={true}>
                {module.fields.header}
              </Heading3>
            </TitleBox>
          </CenterContainer>
          <CarouselContainer>
            <ChevronLeftIcon
              style={{ fontSize: '5rem', cursor: 'pointer', color: scrollBegin ? '#dcdcdc' : '#000' }}
              onClick={(e) => sideScroll(25, 100, -cardSize)}
            />
            <CarouselWindow>
              <CardCarousel ref={scrollElement} onScroll={slideCheck}>
                {customData?.mods?.map((m) => (
                  <ModuleCard key={m.url} module={m} style={{ display: 'inline-block' }} />
                ))}
              </CardCarousel>
            </CarouselWindow>
            <ChevronRightIcon
              onClick={(e) => sideScroll(25, 100, cardSize)}
              style={{ fontSize: '5rem', cursor: 'pointer', color: scrollEnd ? '#dcdcdc' : '#000' }}
            />
          </CarouselContainer>
          <MobileCarouselContainer>
            <Carousel
              navButtonsProps={{
                style: {
                  borderRadius: '25px',
                },
              }}
            >
              {customData?.mods?.map((course, index) => {
                return <DisplayInCarousel key={index} course={course} />;
              })}
            </Carousel>
          </MobileCarouselContainer>
          <CenterContainer style={{ marginBottom: '80px' }}>
            <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
              <KitButton
                round
                size='lg'
                style={{
                  backgroundColor: theme.palette.primary.light,
                  margin: '0 60px',
                }}
              >
                Explore Training Plans
              </KitButton>
            </Link>
          </CenterContainer>
        </>
      )}
    </>
  );
};

const resolvePostUrls = function (sitemap, posts) {
  let dynamicUrls = {};
  posts.forEach((post) => {
    Object.keys(sitemap).forEach((path) => {
      if (sitemap[path].contentID === post.contentID) {
        dynamicUrls[post.contentID] = path;
      }
    });
  });
  return dynamicUrls;
};

// This is called during server-side rendering (not part of NextJS).
NTRWMSModuleListing.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  const api = agility;

  try {
    //get sitemap first, need it to find the dynamic urls
    let sitemap = await api.getSitemapFlat({ channelName: channelName, languageCode });

    // get modules
    // TODO - paging...
    let raw = await api.getContentList({
      referenceName: 'ntrwmsmodulelist',
      languageCode,
      contentLinkDepth: 2,
      depth: 2,
      sort: 'fields.sortOrder',
      take: 50,
    });

    // get our sub modules
    // fetch all (limit of 250 per fetch)
    let subMods = await api.getContentList({ referenceName: 'ntrwmssubmodulelist', languageCode, sort: 'fields.subModuleName', take: 250 });

    const dynamicUrls = resolvePostUrls(sitemap, raw.items);

    let mods = raw.items.map((mod) => {
      const url = dynamicUrls[mod.contentID];
      if (url == undefined) {
        console.log('Failed to get url for ' + mod.contentID + '  ' + mod.fields.moduleName);
      }
      let subs = subMods.items.filter((s) => s.fields.parentModule.contentID === mod.contentID);
      const playableSub = subs.find((s) => s.fields?.scormCourse && s.fields.scormCourse.length > 0);
      mod.hasPlayableSub = playableSub ? true : false;
      mod.url = url;
      mod.subMods = subs ? subs : null;
      return mod;
    });

    return {
      mods,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default NTRWMSModuleListing;

const ModuleImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  height: 'auto',
}));

const CardBodyContainer = styled('div')(({ theme }) => ({
  padding: '5px 0 10px 0',
  height: '220px',
}));

const ModuleName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.50rem',
  lineHeight: '1.70rem',
  fontWeight: 600,
}));

const ModuleDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: 0,
  fontSize: '1.00rem',
  lineHeight: '1.40rem',
  fontWeight: 300,
}));
