import React, { useState } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton, KitCard, KitCardBody, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';

const ResourceGroupList = ({ module }) => {
  const { fields } = module;

  const [textAlign, setTextAlign] = useState('right');

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: relative;
  `;

  const Title = styled.div`
    font-weight: 600;
    margin: 40px 5px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    @media screen and (max-width: 899px) {
      color: ${fields.titleColor};
      font-size: 1.75rem;
      line-height: 1.95rem;
      padding: 0 25px;
    }
    @media screen and (min-width: 900px) {
      color: ${fields.titleColor};
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  `;

  const ContentContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex: 1;
  vertical-align: top;
  @media screen and (max-width: 899px) {
    margin: 10px;
    padding: 40px 20px 40px 20px;
    max-width: 90%;
  }
  @media screen and (min-width: 900px) {
    padding: 60px 10px;
    max-width: 60%;
`;

  const ImageContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      max-height: 40%;
    }
  `;

  const ResourceList = styled.ul`
    padding: 0 15px;
    list-style-type: none;
    display: grid;
    margin: 15px 5px 15px 5px;
  `;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  `;

  const ButtonContainer = styled.div`
    margin: 40px 5px;
    @media screen and (max-width: 899px) {
      padding: 0 25px;
    }
  `;

  return (
    <React.Fragment>
      <Title>{fields.title}</Title>
      <Container>
        {fields.resources && (
          <ResourceList>
            {fields.resources &&
              fields.resources.length > 0 &&
              fields.resources.map((resource, index) => {
                return (
                  <li key={index} style={{ width: '95%', textAlign: 'left' }}>
                    <ListContainer style={{ flexDirection: `${resource.fields.textAlign === 'left' ? 'row-reverse' : 'row'}` }}>
                      <ImageContainer>
                        <KitCard
                          style={{
                            width: '22rem',
                          }}
                        >
                          <React.Fragment key='post.contentID'>
                            {resource.fields.resourceImage && (
                              <ResourceImg
                                alt={resource.fields.resourceImage.label || resource.fields.resourceName}
                                src={resource.fields.resourceImage.url}
                              />
                            )}
                            <KitCardBody></KitCardBody>
                          </React.Fragment>
                        </KitCard>
                      </ImageContainer>
                      <ContentContainer>
                        <ResourceTitle>{resource.fields.resourceName}</ResourceTitle>
                        <ResourceDescription>{resource.fields.resourceDescription}</ResourceDescription>
                        <div style={{ marginTop: 30 }}>
                          <Link href={resource.fields.actionButtonLink.href} passHref legacyBehavior>
                            <KitButton color='secondary' round style={{ margin: '0 45px', padding: '7px 30px', fontSize: '0.90rem' }}>
                              {resource.fields.actionButtonLink.text}
                            </KitButton>
                          </Link>
                        </div>
                      </ContentContainer>
                    </ListContainer>
                  </li>
                );
              })}
          </ResourceList>
        )}
      </Container>
    </React.Fragment>
  );
};

ResourceGroupList.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  try {
    let list = await api.getContentList({
      referenceName: 'resourceGroupList',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (list && list.items && list.items.length > 0) {
      contentItem = list;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load Resource Group List.', error);
    return null;
  }

  return {
    fields: contentItem,
  };
};

export default ResourceGroupList;

const ResourceImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  minHeight: '75%',
}));

const ResourceTitle = styled('div')(({ theme }) => ({
  fontWeight: 600,
  margin: '25px 5px',
  color: theme.palette.primary.light,
  [theme.breakpoints.up('md')]: {
    fontSize: '2.25rem',
    lineHeight: '2.50rem',
    padding: '0 40px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.75rem',
    lineHeight: '1.95rem',
    padding: '0 25px',
  },
}));

const ResourceDescription = styled('div')(({ theme }) => ({
  marginTop: '10px',
  fontWeight: 300,
  margin: '30px 5px',
  color: theme.palette.primary.dark,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.35rem',
    padding: '0 40px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.15rem',
    padding: '0 25px',
  },
}));
