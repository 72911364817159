// package imports
import { Backdrop } from '@mui/material';
import { useSession } from 'next-auth/react';

// local imports
import { isCustomer } from 'services/subscriptionUtils';
import NewsLetterComponent from './NewsletterComponent';

const LoginRequired = ({ module: { fields } }) => {
  const { data: session, status } = useSession();

  return (
    <>
      {(status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
            height: '100%',
          }}
          open={true}
        >
          <div style={{ width: '100%' }} id='newsletter-component'>
            <NewsLetterComponent
              cTAText1={fields.cTAText1}
              cTAText2={fields.cTAText2}
              unsubscribeText={fields.unsubscribeText}
              cTAlink={fields.cTAlink}
              cTALinkColor={fields.cTALinkColor}
              backgroundColor={fields.backgroundColor}
              accentImage={fields.accentImage}
            />
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default LoginRequired;
