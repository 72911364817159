import styled from '@emotion/styled';

const FreeResourceNewIndicator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warmRed.main,
  color: theme.palette.common.white,
  marginRight: '.5rem',
  fontSize: '10px',
  borderRadius: '999px',
  minWidth: '30px',
  minHeight: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default FreeResourceNewIndicator;

export const FreeResourceNewCountTag = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.warmRed.main,
  color: theme.palette.common.white,
  fontSize: '10px',
  borderRadius: '999px',
  minWidth: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
