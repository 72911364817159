import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { renderHTML } from '@agility/nextjs';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';

const FreeAccountCallOut = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();

  const [showPage, setShowPage] = useState(true);

  const ColoredBox = styled.div`
    display: flex;
    z-index: -1;
    position: relative;
    margin-bottom: 45px;
    min-width: 100%;
    border-radius: 6px;
    background-color: ${fields.boxColor};
    @media screen and (min-width: 1199px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      max-height: 295px;
    }
    @media screen and (min-width: 899px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 420px;
      margin: 10px;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-height: 550px;
    }
    @media screen and (max-width: 400px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-height: 450px;
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 535px;
      margin: 10px;
    }
  `;

  // If session, hide, otherwise show.
  useEffect(() => {
    if (session) {
      setShowPage(false);
    } else {
      setShowPage(true);
    }
  }, [session, fields]);

  if (!showPage) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        width: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Frame>
        <ColoredBox>
          <ActionImage>
            <img src={fields.actionImage.url} alt='Free Account' width='100%' />
          </ActionImage>

          <ContentContainer>
            <TextContainer>
              <ActionText className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.introductionText)} />
              <MobileActionText className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.mobileIntroductionText)} />
            </TextContainer>

            <ActionArea>
              {fields.actionLink && fields.actionLink.text && fields.actionLink.text.length > 0 && !session && (
                <Link href='/signin?nl=1' passHref>
                  <KitButton
                    color='white'
                    round
                    style={{
                      color: '#fff',
                      fontWeight: 500,
                      padding: '20px 55px',
                      fontSize: '0.938rem',
                      lineHeight: '1.625px',
                    }}
                  >
                    {fields.actionLink.text}
                  </KitButton>
                </Link>
              )}
            </ActionArea>
            <DisclaimerContainer>
              <DisclaimerText>{fields.disclaimerText}</DisclaimerText>
            </DisclaimerContainer>
          </ContentContainer>
        </ColoredBox>
      </Frame>
    </Box>
  );
};

export default FreeAccountCallOut;

const Frame = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 50,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: '417px',
    margin: '10px auto 25px auto',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '485px',
    margin: '20px 15px 35px 15px',
  },
}));

const ActionImage = styled('div')(({ theme }) => ({
  flexGrow: 0,
  backgroundColor: 'transparent',
  [theme.breakpoints.up('lg')]: {
    marginLeft: '4%',
    width: '50%',
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: '-30px',
    width: '70%',
  },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    minWidth: '50%',
    maxHeight: '300px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '40px',
    minWidth: '100%',
    maxHeight: '550px',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  padding: '10px 20px 10px 20px',
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('md')]: {
    width: '200%',
  },
}));

const ActionArea = styled('div')(({ theme }) => ({
  flexGrow: 0,
  width: '85%',
  position: 'relative',
  zIndex: 1000,
  [theme.breakpoints.up('md')]: {
    padding: '10px 10px 10px 20px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '5px 10px 5px 20px',
  },
}));

const ActionText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primaryColor,
  [theme.breakpoints.up('lg')]: {},
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const MobileActionText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primaryColor,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {},
}));

const DisclaimerContainer = styled('div')(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('lg')]: {
    width: '85%',
    padding: '10px 10px 10px 20px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: '10px 10px 20px 20px',
  },
}));

const DisclaimerText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.blackColor,
  fontSize: '0.875rem',
  lineHeight: '1.1rem',
}));
