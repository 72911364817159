import { FreeformContent } from './FreeformContent';

export function FreeformList({ style, items }) {
  const Component = style === 'ordered' ? 'ol' : 'ul';

  return (
    <Component style={{ paddingRight: '2rem', paddingLeft: '2rem', listStyleType: style === 'ordered' ? 'decimal' : 'disc' }}>
      {items.map(({ content, items }, index) => (
        <li key={index} style={{ marginTop: '.5rem', marginBotton: '.5rem' }}>
          <div>
            <FreeformContent>{content}</FreeformContent>
          </div>

          {items.length > 0 && <FreeformList style={style} items={items} />}
        </li>
      ))}
    </Component>
  );
}
