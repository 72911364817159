import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const SortableTableHeader = (props) => {

  return (
    <Box component="span"
      sx={{cursor: 'pointer'}}
      title={`Click to sort by ${props.text}`}
      onClick={() => props.onSortChange(props.field, props.sortDirection)}
    >
      {props.text} 
      {props.sortDirection === 'ASC' && 
        <ArrowDropUpIcon />
      }
      {props.sortDirection === 'DESC' && 
        <ArrowDropDownIcon />
      }
    </Box>
  )
}

SortableTableHeader.propTypes = {
  field: PropTypes.string,
  text: PropTypes.string,
  sortDirection: PropTypes.oneOf(['ASC', 'DESC', null]),
  onSortChange: PropTypes.func
}

export default SortableTableHeader;

