import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Section = (props) => {
  const {
    styleProps: { fields },
    sectionId,
    children,
  } = props;

  return (
    <SectionWrapper styleProps={fields} id={sectionId}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          width: { xs: '95%', sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <StyledSection styleProps={fields}>{children}</StyledSection>
      </Box>
    </SectionWrapper>
  );
};

const StyledSection = styled('div')(({ theme, styleProps }) => ({
  width: '100%',
  margin: styleProps?.margin === 'md' ? '4rem 0' : styleProps?.margin === 'sm' ? '2.5rem 0' : styleProps?.margin === 'lg' ? '6rem 0' : '',
  [theme.breakpoints.down('lg')]: {
    padding: styleProps?.padding === 'md' ? '3rem 0' : styleProps?.padding === 'sm' ? '1.5rem 0' : styleProps?.padding === 'lg' ? '4rem 0' : '',
    margin: styleProps?.margin === 'md' ? '3rem 0' : styleProps?.margin === 'sm' ? '1.5rem 0' : styleProps?.margin === 'lg' ? '4rem 0' : '',
  },
}));

const SectionWrapper = styled('section')(({ theme, styleProps }) => ({
  backgroundColor: styleProps?.color === 'gray' ? theme.palette.background.lightGrey : 'inherit',
}));

export default Section;
