import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';

const FeaturedTestimonial = ({ module }) => {
  const { fields } = module;

  if (fields.length <= 0 || !fields.testimonial) {
    return <div></div>;
  }

  return (
    <Container
      style={{
        backgroundImage:
          fields.backgroundImage &&
          fields.backgroundImage.url &&
          `linear-gradient(rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.7)), url(${fields?.backgroundImage?.url})`,
      }}
    >
      <FeaturedCardBodyContainer>
        <FeaturedTitle>{fields?.title}</FeaturedTitle>
        <FeaturedAvatarContainer>
          {fields?.testimonial?.fields?.customerName?.fields.profileImage?.url &&
            fields?.testimonial?.fields?.customerName?.fields.profileImage?.url.length > 0 && (
              <Avatar
                alt={fields?.testimonial?.fields?.customerName_textField}
                src={fields?.testimonial?.fields?.customerName?.fields.profileImage?.url}
                sx={{ width: 100, height: 100 }}
              />
            )}
        </FeaturedAvatarContainer>
        <FeaturedDescriptionContainer>
          <FeaturedDescription>&quot;{fields?.testimonial?.fields?.comment}&quot;</FeaturedDescription>
        </FeaturedDescriptionContainer>
        <FeaturedAuthorName>{fields?.testimonial?.fields?.customerName_TextField}</FeaturedAuthorName>
      </FeaturedCardBodyContainer>
    </Container>
  );
};

export default FeaturedTestimonial;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  [theme.breakpoints.down('md')]: {
    backgroundSize: '450%',
    backgroundPosition: 'right 10% bottom 85%',
    backgroundRepeat: 'no-repeat',
  },
}));

const FeaturedAvatarContainer = styled('div')(({ theme }) => ({
  margin: '30px',
  alignSelf: 'center',
  zIndex: 20,
}));

const FeaturedCardBodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px 0 45px 0',
}));

const FeaturedTitle = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  maxWidth: '90%',
  padding: '10px 0 25px 0',
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    fontSize: '2.20rem',
    lineHeight: '2.45rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.20rem',
    lineHeight: '2.45rem',
  },
}));

const FeaturedDescriptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '90%',
  padding: '20px 0 45px 0',
}));

const FeaturedDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: 'transparent',
  fontSize: '1.425rem',
  lineHeight: '1.825rem',
  letterSpacing: '5.2',
  fontWeight: 300,
  fontStyle: 'italic',
  [theme.breakpoints.up('md')]: {
    margin: '0 50px 15px 50px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 30px 15px 30px',
  },
}));

const FeaturedAuthorName = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: 'transparent',
  margin: '30px 0 50px 0',
  fontSize: '1.20rem',
  lineHeight: '1.30rem',
  fontWeight: 600,
}));
