import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { renderHTML } from '@agility/nextjs';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { getColorByAgilityName } from '../../services/agilityTranslations';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';

const FreeModule2 = ({ module }) => {
  const { fields } = module;

  const router = useRouter();

  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const Frame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const TitleBox = styled.div`
    margin: 40px 0 20px 0;
  `;

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: 115%;
    background-repeat: no-repeat;
    background-position: right 0% bottom 0%;
    position: relative;
    margin: 30px 0 50px 0;
    @media screen and (max-width: 991px) {
      display: none;
    }
    @media screen and (min-width: 992px) {
      min-height: 450px;
      width: 70%;
    }
  `;

  const MobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90%;
    @media screen and (max-width: 991px) {
      margin: 10px auto 50px auto;
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    minheight: inherit;
    @media screen and (max-width: 991px) {
      padding: 0 5%;
    }
    @media screen and (min-width: 992px) {
      padding: 0 10%;
    }
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 35px 0;
    .video {
      aspect-ratio: 16 / 9;
      width: 600px;
    }
  `;

  const ActionButtonContainer = styled.div`
    margin: 20px 0 27px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 991px) {
      align-items: center;
    }
    @media screen and (min-width: 992px) {
      align-items: flex-start;
    }
  `;

  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const EmbedContainer = styled.div`
    @media screen and (max-width: 991px) {
      width: 269px;
    }
    @media screen and (min-width: 992px) {
      width: 700px;
    }
  `;

  const hideVideoDialog = () => {
    setShowVideoDialog(false);
  };

  return (
    <Frame>
      <TitleBox>
        <Heading4 color='#000000'>Experience A Module</Heading4>
      </TitleBox>
      <MobileContainer>
        <KitGridContainer direction='row' style={{ width: '100%', height: '100%' }}>
          <KitGridItem xs={12} align='left' style={{ backgroundColor: 'rgba(0, 59, 92, 0.9)', height: '55%', width: '100%' }}>
            <TextContainer>
              <SeriesName>{fields.seriesName} </SeriesName>
              <VideoTitle style={{ color: fields.textColor }}>{fields.title}</VideoTitle>
              <Description style={{ color: fields.textColor }}>{fields.description}</Description>
              <ActionButtonContainer>
                <KitButton size='sm' color={fields.actionButtonColor} onClick={(e) => setShowVideoDialog(true)} round style={{ opacity: 1.0 }}>
                  {fields.actionButtonLabel}
                </KitButton>
              </ActionButtonContainer>
            </TextContainer>
          </KitGridItem>
        </KitGridContainer>
      </MobileContainer>
      <Container
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.100),rgba(0, 0, 0, 0.1)), url(${fields.backgroundImage.url})`,
        }}
      >
        <KitGridContainer direction='row' style={{ position: 'absolute', top: '0', left: '50%', width: '51.5%', height: '100%' }}>
          <KitGridItem xs={12} align='left' style={{ background: 'rgba(0, 59, 92, 0.90)', height: '100%', width: '100%' }}>
            <TextContainer>
              <SeriesName>{fields.seriesName}</SeriesName>
              <VideoTitle style={{ color: fields.textColor }}>{fields.title}</VideoTitle>
              <Description style={{ color: fields.textColor }}>{fields.description}</Description>
              <ActionButtonContainer>
                <KitButton
                  color={fields.actionButtonColor}
                  onClick={(e) => setShowVideoDialog(true)}
                  round
                  style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}
                >
                  {fields.actionButtonLabel}
                </KitButton>
              </ActionButtonContainer>
            </TextContainer>
          </KitGridItem>
        </KitGridContainer>
      </Container>
      <Dialog open={showVideoDialog} onClose={hideVideoDialog} scroll='paper' maxWidth='md' fullWidth={true} aria-labelledby='Pop-up Video Viewer'>
        <React.Fragment>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: 'fit-content' }}>
              <KitGridContainer direction='row'>
                <KitGridItem xs={12} align='left'>
                  <VideoContainer style={{ backgroundColor: getColorByAgilityName(fields.backgroundColor) }}>
                    <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.embedVideoScript)} />
                  </VideoContainer>
                </KitGridItem>
              </KitGridContainer>
            </Box>
            <ButtonContainer>
              <KitButton
                round
                size='sm'
                color='primary'
                onClick={hideVideoDialog}
                style={{ margin: 0, padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}
              >
                Close
              </KitButton>
            </ButtonContainer>
          </DialogContent>
        </React.Fragment>
      </Dialog>
    </Frame>
  );
};

export default FreeModule2;

const SeriesName = styled('div')(({ theme }) => ({
  fontWeight: 700,
  color: '#ECCE98',
  textTransform: 'uppercase',
  [theme.breakpoints.up('md')]: {
    margin: '80px 0 0 0',
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '65px 0 0 0',
    fontSize: '1.00rem',
  },
}));

const VideoTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  width: '95%',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.375rem',
    lineHeight: '2.375rem',
    margin: '10px 0',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.65rem',
    lineHeight: '1.80rem',
    margin: '15px 0',
  },
}));

const Description = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.35rem',
    height: '100%',
    margin: '10px 0',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '30px',
    fontSize: '1.00rem',
    lineHeight: '1.50rem',
    height: '100%',
  },
}));
