import React, { useState } from 'react';
import styled from '@emotion/styled';

const BackgroundWithBlackBox = ({ module }) => {
  const { fields } = module;

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 991px) {
      background-size: 250%;
      background-position: right 35% bottom 75%;
      height: 300px;
      margin: 20px 0 10px 0;
      padding: 10px 0 -30px 0;
    }
    @media screen and (min-width: 993px) {
      background-size: 100%;
      background-position: right 10% bottom 60%;
      height: 500px;
      margin: 80px 0 100px 0;
      padding: 50px 0 -80px 0;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #133552;
    align-items: left;
    position: relative;
    @media screen and (max-width: 991px) {
      margin-left: 5px;
      width: 325px;
      height: 325px;
      padding: 5% 3%;
      &:after {
        border-bottom: solid 25px transparent;
        border-right: solid 25px transparent;
        border-left: solid 25px #58c1b3;
        transform: translateX(-50%);
        position: absolute;
        z-index: -1;
        content: '';
        top: 92.4%;
        left: 107.6%;
        height: 0;
        width: 0;
      }
    }
    @media screen and (min-width: 993px) {
      margin-left: -34%;
      width: 500px;
      height: 524px;
      padding: 125px 80px;
      &:after {
        border-bottom: solid 25px transparent;
        border-right: solid 25px transparent;
        border-left: solid 25px #58c1b3;
        transform: translateX(-50%);
        position: absolute;
        z-index: -1;
        content: '';
        top: 95.25%;
        left: 105%;
        height: 0;
        width: 0;
      }
    }
  `;

  return (
    <Container
      style={{
        border: '1px solid ${theme.palette.common.black}',
        backgroundImage: `linear-gradient(rgba(${fields.backgroundColor}),rgba(${fields.backgroundColor})), url(${fields.backgroundImage.url})`,
      }}
    >
      <TextContainer>
        <Description>{fields.text}</Description>
      </TextContainer>
    </Container>
  );
};

export default BackgroundWithBlackBox;

const Description = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.325rem',
    lineHeight: '2.225rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
    lineHeight: '2.025rem',
  },
}));
