import React from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Link from 'next/link';
import { getIcon } from '../../services/agilityTranslations';
import { useSession } from 'next-auth/react';
import { useTheme } from '@emotion/react';

const BreadcrumbTrail = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();
  const theme = useTheme();

  const Frame = styled.div`
    width: 100%;
    margin: 0 0 20px 0;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      ${fields.hideComponent === 'session'
        ? session
          ? 'display: none;'
          : 'display: flex;'
        : fields.hideComponent === 'nosession'
        ? !session
          ? 'display: none;'
          : 'display: flex;'
        : 'display: flex;'}
      ${fields.borderColor === '#000' ? 'border-bottom: 1px solid #000' : `border-bottom: 1px solid ${theme.palette.grey[fields.borderColor]}`}
    }
  `;

  return (
    <Frame>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <Container>
          {fields.breadcrumbList &&
            fields.breadcrumbList.length > 0 &&
            fields.breadcrumbList
              .sort((a, b) => {
                if (parseInt(a.fields.order, 10) > parseInt(b.fields.order, 10)) return 1;
                if (parseInt(a.fields.order, 10) < parseInt(b.fields.order, 10)) return -1;
                return 0;
              })
              .map((breadcrumb, index) => {
                return (
                  <Breadcrumb key={breadcrumb.contentID} color={fields.textColor ?? 'default'}>
                    {breadcrumb.fields.breadcrumbUrl.href && breadcrumb.fields.breadcrumbUrl.href.length > 0 ? (
                      <Link href={breadcrumb.fields.breadcrumbUrl.href} passHref legacyBehavior>
                        <a target={breadcrumb.fields.breadcrumbUrl.target} rel='noopener noreferrer'>
                          {breadcrumb.fields.breadcrumbUrl.text}
                        </a>
                      </Link>
                    ) : (
                      <span>{breadcrumb.fields.breadcrumbUrl.text}</span>
                    )}
                    {breadcrumb.fields.breadcrumbUrl.href && breadcrumb.fields.breadcrumbUrl.href.length > 0 && <span>{' / '}&nbsp;</span>}
                  </Breadcrumb>
                );
              })}
        </Container>
      </Box>
    </Frame>
  );
};

export default BreadcrumbTrail;

const Container = styled('div')(({ theme }) => ({
  display: 'inline',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const BreadCrumbRow = styled('span')(({ theme }) => ({
  display: 'inline',
}));

const Breadcrumb = styled('span')(({ theme, color }) => ({
  display: 'inline',
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  textTransform: 'uppercase',
  color: color === 'default' ? theme.palette.primary.dark : theme.palette.text[color],
}));
