import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import Heading6 from '../Typography/Heading6';

const CourseInformation = (props) => {
  const theme = useTheme();

  const InfoList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    align-items: left;
    position: relative;
    list-style-type: disc;
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-between;
  `;

  return (
    <Container>
      <KitGridContainer direction='row' style={{ width: '100%', margin: '10px 0' }}>
        {props?.data?.courses?.map((c, i) => (
          <Cell xs={12} lg={6} style={{ marginBottom: '20px', padding: 0 }} key={i}>
            <Heading6 style={{ margin: '5px 0 10px 0' }}>{c.fields.courseName}</Heading6>
            <InfoList>
              <li>{c.fields.workshopLength}</li>
              <li>{c.fields.transcriptInformation}</li>
              <li>{c.fields.creditInformation}</li>
            </InfoList>
          </Cell>
        ))}
        <Cell xs={12} lg={6} style={{ marginBottom: '20px', padding: 0 }}></Cell>
      </KitGridContainer>
    </Container>
  );
};

export default CourseInformation;

const Container = styled('div')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '20.8px',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    margin: '40px 50px',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '10px 0',
  },
}));

const Cell = styled('KitGridItem')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    marginBottom: '20px',
    width: '50%',
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: '20px',
    padding: 0,
    width: '100%',
  },
}));
