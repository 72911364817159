import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const Heading2 = (props) => {
  const { color, children, underline, uppercase, ...rest } = props;
  return (
    <Typography {...rest} component='h2' color={color} fontSize='28px' fontWeight='600' lineHeight='32px' textTransform='capitalize'>
      {children}
    </Typography>
  );
};

export default Heading2;
