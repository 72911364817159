import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

export const getIcon = (cmsValue, cmsColor, size) => {
  switch (cmsValue) {
    case 'voicechat':
      return <VoiceChatIcon style={{ color: cmsColor, fontSize: size }} />;
    case 'widgets':
      return <WidgetsIcon style={{ color: cmsColor, fontSize: size }} />;
    case 'receiptlong':
      return <ReceiptLongIcon style={{ color: cmsColor, fontSize: size }} />;
    case 'ondemandvideo':
      return <OndemandVideoIcon style={{ color: cmsColor, fontSize: size }} />;
    default:
      return '';
  }
};

export const getColorByAgilityName = (colorName) => {
  switch (colorName) {
    case 'light':
      return '#4298B5';
    case 'dark':
      return '#00243e';
    case 'gray':
      return '#eeeeee';
    case 'black':
      return '#000';
    case 'white':
      return '#FFF';
    case 'transparent':
      return 'transparent';
    default:
      return '#005581';
  }
};
