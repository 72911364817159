import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import PageContainer from 'components/common/subcomponents/PageContainer';

const TextBlock = ({ module: { fields } }) => {
  const theme = useTheme();
  const colorPieces = fields?.fontColor?.split('.') ?? [];
  const fontColor = colorPieces.length > 1 ? theme.palette[colorPieces[0]][colorPieces[1]] : theme.palette.text.light;

  return (
    <PageContainer>
      <Typography
        component='p'
        textAlign={fields?.centerAligned === 'true' ? 'center' : 'start'}
        sx={{
          fontSize: { lg: '18px', xs: '17px' },
          lineHeight: { lg: '28px', xs: '26px' },
          fontWeight: fields?.fontWeight ?? 400,
          color: fontColor,
          marginBottom: '1rem',
        }}
      >
        {fields?.text}
      </Typography>
    </PageContainer>
  );
};

export default TextBlock;
