import React from 'react';
import styled from '@emotion/styled';
import Tab, { tabClasses } from '@mui/material/Tab';

export const TabItem = styled(Tab)(({ theme }) => ({
  opacity: 1,
  overflow: 'initial',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  marginRight: '.3rem',
  color: theme.palette.text.dark,
  backgroundColor: '#CDD7E1',
  transition: '0.2s',
  zIndex: 2,
  marginTop: theme.spacing(0.5),
  textTransform: 'initial',
  '&:before': {
    transition: '0.2s',
  },
  '&:last-of-type': {
    marginRight: 0,
  },
  [`& + .${tabClasses.selected}::before`]: {
    opacity: 0,
  },
  '&:hover': {
    [`&:not(.${tabClasses.selected})`]: {
      backgroundColor: 'rgba(0 0 0 / 0.2)',
    },
    '&::before': {
      opacity: 0,
    },
    [`& + .${tabClasses.root}::before`]: {
      opacity: 0,
    },
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}`]: {
    zIndex: 1,
  },
  [`&.${tabClasses.selected} + .${tabClasses.root}::before`]: {
    opacity: 0,
  },
}));
