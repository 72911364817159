import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import QuestionForm from '../common/subcomponents/QuestionForm';

const AskTheTrainerForm = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();
  const { data: session, status } = useSession();

  const Frame = styled.div`
    background: ${fields.backgroundColor};
    @media screen and (min-width: 992px) {
      padding: 25px 0 0 0;
    }
    @media screen and (max-width: 991px) {
      margin-top: 20px;
      padding: 20px 0 0 0;
    }
  `;

  const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      margin: 25px auto 0 auto;
      flex-direction: row;
      height: ${session?.user ? '550px' : '640px'};
      padding-bottom: 25px;
    }
    @media screen and (max-width: 991px) {
      justify-content: normal;
      padding: 0;
      margin: 1px auto 0 auto;
      flex-direction: column;
      height: ${session?.user ? '550px' : '820px'};
    }
  `;

  const TitleContainer = styled.div`
    z-index: 100;
    display: flex;
    justify-content: left;
    align-items: center;
    @media screen and (min-width: 1200px) {
      position: absolute;
      right: 14%;
      top: 38%;
      flex-direction: row;
      width: 400px;
    }
    @media screen and (max-width: 1199px) {
      position: absolute;
      right: -34%;
      top: 38%;
      flex-direction: row;
      width: 400px;
    }
    @media screen and (max-width: 991px) {
      position: relative;
      right: 0;
      top: 0;
      flex-direction: column;
      margin: 5px auto;
      width: 85%;
    }
  `;

  const Title = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (min-width: 992px) {
      margin: 10px 15px;
      font-size: 2.3rem;
      line-height: 2.55rem;
    }
    @media screen and (max-width: 991px) {
      justify-content: normal;
      margin: 0;
      font-size: 1.825rem;
      line-height: 2.025rem;
    }
  `;

  const AccentImage = styled.img`
    position: absolute;
    left: 47%;
    bottom: 0;
    width: auto;
    width: 591px;
    opacity: 0.3;
    @media screen and (max-width: 991px) {
      display: none;
    }
  `;

  const FormContainer = styled.div`
    display: flex;
    align-items: center;
    z-index: 30;
    @media screen and (min-width: 992px) {
      padding: 0 80px 0 20px;
      position: absolute;
      left: 2%;
      top: ${session?.user ? '10px' : '40px'};
      justify-content: flex-start;
      width: 675px;
      height: 550px;
    }
    @media screen and (max-width: 991px) {
      padding: 110px 10px 0 10px;
      justify-content: center;
      width: 100%;
      height: 600px;
    }
  `;

  return (
    <Box sx={{ width: '100%', ml: 'auto', mr: 'auto', maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' } }}>
      <Frame>
        <Container>
          <TitleContainer>
            <Title>
              {fields.titleLine1}
              <br />
              {fields.titleLine2}
            </Title>
          </TitleContainer>
          <FormContainer>
            <QuestionForm buttonColor={theme.palette.primary.light} />
          </FormContainer>
          {fields.accentImage && <AccentImage src={fields.accentImage?.url} alt={fields.accentImage?.text}></AccentImage>}
        </Container>
      </Frame>
    </Box>
  );
};

export default AskTheTrainerForm;
