// package imports
import { useTheme } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useState } from 'react';

// local imports
import ConfirmCancelDialog from 'components/common/ConfirmCancelDialog';
import { useObservationStore } from '../../stores/observationStore';
import ObservationOffTaskEdit from './ObservationOffTaskEdit';

const OffTaskEditDialog = (props) => {
  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { editTimelineItem } = useObservationStore();

  const closeDialog = (needsPrompt) => {
    if (needsPrompt) {
      setConfirmOpen(true);
      return;
    }
    setConfirmOpen(false);
    setIsDirty(false);
    props.onClose();
  };

  const getFormattedTime = (timestamp) => {
    return DateTime.fromMillis(timestamp).toFormat('h:mm a');
  };

  return (
    <Dialog maxWidth='lg' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeDialog(isDirty)}>
      <DialogTitle sx={{ backgroundColor: theme.palette.obsAqua.main, color: theme.palette.white.main, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '30px' }}>Edit Mode</Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>{`Time Stamp - ${getFormattedTime(
            editTimelineItem?.timestamp || 0
          )}`}</Typography>

          <IconButton onClick={() => closeDialog(isDirty)}>
            <CancelIcon color='white' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <ObservationOffTaskEdit onComplete={closeDialog} onDirty={() => setIsDirty(true)} />
        </Box>
      </DialogContent>
      <ConfirmCancelDialog
        confirmMessage={'There are unsaved changes, are you sure you want to close?'}
        open={confirmOpen}
        handleYes={() => closeDialog(false)}
        handleClose={() => setConfirmOpen(false)}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </Dialog>
  );
};

OffTaskEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  timelineItem: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default OffTaskEditDialog;
