const SearchCategory = ({ text, isActive, click, color = '#fff' }) => {
  return (
    <button style={{ marginRight: '.5rem', display: 'flex', flexDirection: 'column', color: color }} onClick={click}>
      <span style={{ paddingBottom: '.25rem', paddingLeft: '.5rem', paddingRight: '.5rem' }}>{text}</span>
      <span
        style={{
          backgroundColor: color,
          width: '100%',
          borderRadius: '999px',
          height: '.25rem',
          display: 'flex',
          justifyContent: 'center',
          opacity: isActive ? 1 : 0.3,
        }}
      >
        <svg style={{ width: '.5rem', height: '.5rem', display: isActive ? 'block' : 'none' }} viewBox='0 0 6 6'>
          <rect x='3' width='4' height='4' transform='rotate(45 3 0)' className='fill-current'></rect>
        </svg>
      </span>
    </button>
  );
};
export default SearchCategory;
