import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Badge, Box, FormControl, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useObservationStore } from '../../stores/observationStore';
import { HeartIcon } from 'components/common/subcomponents/SvgIcons';
import { PromptButton, ObsStyles, PromptContainer, PromptRadio } from '../StyledComponents';

const Praise = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { observation, addTimelineObservation, setDirtyObject } = useObservationStore();

  const [praisePrompt, setPraisePrompt] = useState(''); // ['gp-a', 'gp-b', 'sp-a', 'sp-b', 'ep-a', 'ep-b']
  const [sPrompt, setSPrompt] = useState(''); // s'
  const [slPrompt, setSLPrompt] = useState(''); // sl'
  const [moPrompt, setMoPrompt] = useState(''); // mo'
  const [note, setNote] = useState('');
  const [hasPraisePrompt, setHasPraisePrompt] = useState(false);
  const [hasMoPrompt, setHasMoPrompt] = useState(false);

  useEffect(() => {
    if (observation.dirtyObject !== 'praise') {
      setPraisePrompt('');
      setHasPraisePrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
    }
  }, [observation]);

  const radioOnChange = (e) => {
    setDirtyObject('praise');
    const { name, value, checked } = e.target;
    if (name === 'praise-1') {
      setPraisePrompt(value);
      setHasPraisePrompt(true);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'praise-s-1') {
      setSPrompt(checked && sPrompt === 'praise-s' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'praise-sl-1') {
      setSLPrompt(checked && slPrompt === 'praise-sl' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'praise-mo') {
      setPraisePrompt('');
      setHasPraisePrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt(checked && moPrompt === 'praise-mo' ? '' : value);
      setHasMoPrompt(true);
    }
  };

  const addToTimeline = () => {
    const timelineObservation = {
      timestamp: new Date().getTime(),
      type: 'Praise',
      tags: [],
      note: note,
    };

    if (praisePrompt?.length > 0) {
      setHasPraisePrompt(true);
      timelineObservation.tags.push(praisePrompt.toUpperCase());
    }
    if (sPrompt?.length > 0) {
      timelineObservation.tags.push('S');
    }
    if (slPrompt?.length > 0) {
      timelineObservation.tags.push('SL');
    }
    if (moPrompt?.length > 0) {
      setHasMoPrompt(true);
      timelineObservation.tags.push('MO');
    }
    if (hasPraisePrompt || hasMoPrompt) {
      addTimelineObservation(timelineObservation);
      setPraisePrompt('');
      setHasPraisePrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
      props.scrollToTop();
    }
  };

  return (
    <PromptContainer promptType='Praise'>
      <Badge
        color='error'
        badgeContent={observation?.observations?.filter((o) => o.type === 'Praise' && o.tags.filter((t) => t === 'MO')?.length === 0)?.length || 0}
        className='prompt-title-badge'
      >
        <div className='prompt-title-box'>
          <HeartIcon fillColor={theme.palette.obsPraise.dark} />
          <Typography sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '18.86px', color: '#000', marginLeft: '1rem' }}>Praise</Typography>
        </div>
      </Badge>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', flexWrap: 'nowrap', width: '85%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '.02rem' }}>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='gp-a' name='praise-1' value='gp-a' checked={praisePrompt === 'gp-a'} onClick={radioOnChange} />
            <label htmlFor='gp-a'>GP - A</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='gp-b' name='praise-1' value='gp-b' checked={praisePrompt === 'gp-b'} onClick={radioOnChange} />
            <label htmlFor='gp-b'>GP - B</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '.02rem' }}>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='sp-a' name='praise-1' value='sp-a' checked={praisePrompt === 'sp-a'} onClick={radioOnChange} />
            <label htmlFor='sp-a'>SP - A</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='sp-b' name='praise-1' value='sp-b' checked={praisePrompt === 'sp-b'} onClick={radioOnChange} />
            <label htmlFor='sp-b'>SP - B</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '.02rem' }}>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='ep-a' name='praise-1' value='ep-a' checked={praisePrompt === 'ep-a'} onClick={radioOnChange} />
            <label htmlFor='ep-a'>EP - A</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' style={{ marginTop: '1.5rem' }}>
            <input type='radio' id='ep-b' name='praise-1' value='ep-b' checked={praisePrompt === 'ep-b'} onClick={radioOnChange} />
            <label htmlFor='ep-b'>EP - B</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '1.5rem', width: '85%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.obsPraise.xLight,
            borderRadius: '12px',
            padding: '12px',
            width: '66.67%',
          }}
        >
          <PromptRadio promptType='Praise' round={true}>
            <input type='radio' id='praise-s' name='praise-s-1' value='praise-s' checked={sPrompt === 'praise-s'} onClick={radioOnChange} />
            <label htmlFor='praise-s'>S</label>
          </PromptRadio>
          <PromptRadio promptType='Praise' round={true}>
            <input type='radio' id='praise-sl' name='praise-sl-1' value='praise-sl' checked={slPrompt === 'praise-sl'} onClick={radioOnChange} />
            <label htmlFor='praise-sl'>SL</label>
          </PromptRadio>
        </Box>
        <Box sx={{ padding: '12px' }}>
          <Badge
            color='error'
            badgeContent={observation?.observations?.filter((o) => o.type === 'Praise' && o.tags.filter((t) => t === 'MO')?.length > 0)?.length || 0}
            overlap='circular'
          >
            <PromptRadio promptType='Praise' round={true}>
              <input type='radio' id='praise-mo' name='praise-mo' value='praise-mo' checked={moPrompt === 'praise-mo'} onClick={radioOnChange} />
              <label htmlFor='praise-mo'>MO</label>
            </PromptRadio>
          </Badge>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
        <FormControl fullWidth size='small'>
          <TextField
            placeholder='Type note'
            multiline={true}
            rows={2}
            InputLabelProps={{ shrink: true }}
            sx={{ ...styles.select }}
            InputProps={{ sx: { ...styles.select } }}
            inputProps={{ maxLength: 200 }}
            label=''
            id='praise-note'
            //error={noteError}
            size='small'
            value={note}
            onChange={(e) => {
              //setNoteError(false);
              setDirtyObject('praise');
              setNote(e.target.value);
            }}
          />
        </FormControl>

        <KitButton
          onClick={addToTimeline}
          size='sm'
          style={{
            backgroundColor: theme.palette.gold.main,
            marginTop: '1rem',
          }}
          disabled={!hasPraisePrompt && !hasMoPrompt}
        >
          <span>
            <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
          </span>
        </KitButton>
      </Box>
    </PromptContainer>
  );
};

export default Praise;
