import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useSession } from 'next-auth/react';
import { hasModuleAccess } from '../../services/subscriptionUtils';
import { useTheme } from '@mui/material/styles';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';

const Video = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();
  const theme = useTheme();

  const [showPage, setShowPage] = useState(true);

  // If session, check if has module details access.
  useEffect(() => {
    if (session) {
      if (hasModuleAccess(session, fields.parentPage)) {
        if (fields && fields.showwhenSession === 'true') {
          setShowPage(true);
        } else {
          setShowPage(false);
        }
      } else {
        setShowPage(true);
      }
    }
  }, [session, fields]);

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${fields.grayBackground === 'true' ? 'background-color: rgba(217, 217, 217, 0.3);' : 'background-color: rgba(255, 255, 255, 1);'}
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right 10% bottom 75%;
    position: relative;
    @media screen and (max-width: 899px) {
      min-height: 180px;
    }
    @media screen and (min-width: 900px) {
      min-height: 420px;
    }
  `;

  const DescriptionBox = styled.div`
    margin: 35px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media screen and (min-width: 992px) {
      max-width: 550px;
    }
    @media screen and (max-width: 992px) {
      max-width: 95%;
    }
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 35px 0;
    .video {
      aspect-ratio: 16 / 9;
      width: 600px;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (min-width: 600px) {
      width: 400px;
    }
    @media screen and (min-width: 768px) {
      width: 550px;
    }
    @media screen and (min-width: 992px) {
      width: 700px;
    }
    @media screen and (min-width: 993px) {
      width: 900px;
    }
  `;

  return (
    <React.Fragment>
      {showPage && (
        <Container>
          {fields.description && (
            <DescriptionBox>
              <Heading4 color='#000'>{fields.description}</Heading4>
            </DescriptionBox>
          )}
          <Box
            sx={{
              width: '100%',
              ml: 'auto',
              mr: 'auto',
              maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
            }}
          >
            <VideoContainer>
              <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoEmbed)} />
            </VideoContainer>
          </Box>
          {fields.showButton === 'true' && (
            <>
              <Link href={fields.buttonLink.href} passHref legacyBehavior>
                <KitButton
                  round
                  size='lg'
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    marginBottom: '50px',
                  }}
                >
                  <a href={fields.buttonLink.href} target={fields.buttonLink.target} rel='noopener noreferrer'>
                    {fields.buttonText}
                  </a>
                </KitButton>
              </Link>
            </>
          )}
        </Container>
      )}
    </React.Fragment>
  );
};

export default Video;
