import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton, KitGridContainer, KitGridItem, KitCard, KitCardBody, KitCardFooter } from '@boystownorg/bi-cms-component-lib';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTheme } from '@emotion/react';
import { logClientMessage, logClientException, logServerException } from 'appinsights/clientAppInsights';
import truncate from 'truncate-html';
import { logResourceAccess } from 'services/gatedResourceUtils';

const PageLinkContainer = styled.div`
  display: flex;
  min-width: 100%;
  maxheight: 60px;
  cursor: pointer;
  justify-content: flex-end;
`;

const PostDetails = (props) => {
  const { customData } = props;
  const post = customData?.post.fields;
  const relatedPosts = customData?.selectedPosts;

  const theme = useTheme();

  // format date
  const options = { month: 'long', day: 'numeric' };
  const dateStr = post.date && new Date(post.date).toLocaleDateString('en-us', options);

  const displayReadMore = (str, len) => {
    if (str.length > len) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const init = async () => {
      await logResourceAccess('blog', post.title, typeof window !== 'undefined' ? window.location.href : '');
    };
    init();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <PostContainer>
        <WrapContainer>
          <PostImage>{post.image && <img src={post.image?.url} alt={post.imageAlt || post.title} />}</PostImage>
          <KitGridContainer direction='row'>
            <KitGridItem xs={12} align='left' style={{ padding: 0 }}>
              <PostTitle>{post.title}</PostTitle>
            </KitGridItem>
          </KitGridContainer>
          <PostDateAndAuthor>
            {dateStr} {post.authorName && 'by'} {post.authorName}
          </PostDateAndAuthor>
          <PostContent className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(post.content)}></PostContent>
        </WrapContainer>
      </PostContainer>
      {relatedPosts > 2 && (
        <RelatedBlogSpace>
          <CardGridTitle>
            <RelatedTitle>Related Blogs</RelatedTitle>
          </CardGridTitle>
          <CardGrid>
            <CardGridCards>
              {relatedPosts.map((relatedPost, index) => (
                <Link href={`/blog/${relatedPost.plan.fields.slug}`} key={index} legacyBehavior>
                  <a>
                    <KitCard
                      style={{
                        width: '24rem',
                        height: '530px',
                      }}
                    >
                      <React.Fragment key={relatedPost.plan.contentID}>
                        {relatedPost.plan.fields.image && (
                          <ModuleImg alt={relatedPost.plan.fields.image.label} src={relatedPost.plan.fields.image.url} />
                        )}
                        <KitCardBody>
                          <RelatedPostTitle>{relatedPost.plan.fields.title}</RelatedPostTitle>
                          <RelatedPostDateAndAuthor>
                            {new Date(relatedPost.plan.fields.date).toLocaleDateString('en-us', { month: 'long', day: 'numeric' })}{' '}
                            {relatedPost.plan.fields.authorName && 'by'} {relatedPost.plan.fields.authorName}
                          </RelatedPostDateAndAuthor>
                          <RelatedPostContent>
                            {truncate(relatedPost.plan.fields.content, {
                              length: 150,
                              decodeEntities: true,
                              stripTags: true,
                              reserveLastWord: true,
                            })}{' '}
                            {displayReadMore(relatedPost.plan.fields.content, 150) && <ReadMoreLink>Read More</ReadMoreLink>}
                          </RelatedPostContent>
                        </KitCardBody>
                        <KitCardFooter>
                          <Tags>
                            <LocalOfferIcon style={{ marginRight: 8 }} />{' '}
                            {relatedPost.plan.fields.tagNames && relatedPost.plan.fields.tagNames.replace(',', ', ')}
                          </Tags>
                        </KitCardFooter>
                      </React.Fragment>
                    </KitCard>
                  </a>
                </Link>
              ))}
            </CardGridCards>
            <MoreButton>
              <Link href='/blog' passHref legacyBehavior>
                <KitButton color='success' round>
                  View More
                </KitButton>
              </Link>
            </MoreButton>
          </CardGrid>
        </RelatedBlogSpace>
      )}
    </Box>
  );
};

// This is called during server-side rendering (not part of NextJS).
PostDetails.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem }) => {
  const api = agility;

  const detailPost = dynamicPageItem;

  let relatedPosts = [];

  try {
    // get posts
    let raw = await api.getContentList({
      referenceName: 'posts',
      languageCode,
      take: 50,
    });

    let posts = raw.items.map((p, index) => {
      try {
        if (p.contentID !== detailPost.contentID) {
          let checkTags = detailPost.fields.tags?.forEach((tag) => {
            if (p.fields.tagNames?.includes(tag.fields.title)) {
              let planOption = {
                planName: p.fields.title,
                plan: p,
                number: index,
              };
              relatedPosts.push(planOption);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    });

    // Randomly choose 3 related posts
    // Shuffle the array of posts
    const shuffled = relatedPosts.sort(() => 0.5 - Math.random());
    // Get selected array of first 3 elements from shuffled array
    const selectedPosts = shuffled.slice(0, 3);

    return {
      post: detailPost,
      selectedPosts,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default PostDetails;

const PostContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '0 5px 10px 5px',
    margin: '0 0 35px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    margin: 0,
  },
}));

const WrapContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '10px 5px 10px px',
    margin: '60px 20px 5px 20px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px 5px 10px 5px',
    margin: '20px',
  },
}));

const TitleLinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  margin: '0 20px 0 20px',
  border: '1px solid green',
}));

const PostImage = styled('div')(({ theme }) => ({
  fontSize: '1.65rem',
  color: theme.palette.primary.main,
  fontWeight: 600,

  [theme.breakpoints.up('md')]: {
    alignSelf: 'flex-start',
    maxWidth: '550px',
    margin: '13px 70px 30px 0px',
    boxShadow: '-20px 20px 0 #000',
    float: 'left',
    //shapeOutside: 'polygon(0 0, 35% 35%, 35% 35%, 35% 35%)',
  },
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
    maxWidth: '100%',
    margin: '5px 0 20px 0',
  },
}));

const PostCategory = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.10rem',
  lineHeight: '1.2rem',
  fontWeight: 500,
}));

const PostDateAndAuthor = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: 'transparent',
  margin: '0 0 10px 0',
  fontSize: '1.00rem',
  lineHeight: '1.1rem',
  paddingBottom: 10,
}));

const PostTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '20px 10px',
  fontSize: '1.95rem',
  lineHeight: '2.20rem',
  fontWeight: 600,
}));

const RelatedTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  textTransform: 'uppercase',
  backgroundColor: 'transparent',
  fontSize: '2.00rem',
  lineHeight: '2.20rem',
  fontWeight: 600,
}));

const PostContent = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 10px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));

const ReadMoreLink = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  margin: '5px 0 5px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));

const Tags = styled('div')(({ theme }) => ({
  color: theme.palette.primary.light,
  backgroundColor: 'transparent',
  fontSize: '1.00rem',
  lineHeight: '1.20rem',
  fontWeight: 300,
  paddingTop: 10,
  borderTop: '0.10rem solid #D6DBDF',
  width: '100%',
}));

const PageLinkIconContainer = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '10px 5px 10px 50px',
}));

const PageLinkTextContainer = styled('div')(({ theme }) => ({
  margin: 0,
  padding: '12px 20px 10px 10px',
}));

const PageLinkActionText = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  fontSize: '1.10rem',
  fontWeight: 300,
}));

const CardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirect: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E4E5F2',
  flexWrap: 'wrap',
  gap: '3.00em',
}));

const CardGridTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirect: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const CardGridCards = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirect: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '3.00em',
}));

const RelatedBlogSpace = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E4E5F2',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '3.00em',
  padding: '5% 0',
}));

const ModuleImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  height: '220px',
}));

const RelatedPostTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '10px 0 15px 0',
  fontSize: '1.20rem',
  lineHeight: '1.50rem',
  fontWeight: 700,
}));

const RelatedPostDateAndAuthor = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.light,
  backgroundColor: 'transparent',
  fontSize: '1.00rem',
  lineHeight: '1.1rem',
  paddingBottom: 10,
}));

const RelatedPostContent = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 10px 0',
  fontSize: '0.90rem',
  fontWeight: 400,
}));

const MoreButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));
