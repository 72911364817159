import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { callApi } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';

const AvailabilityTest = ({ module }) => {
  const { fields } = module;

  const [result, setResult] = useState('pending');
  const [progress, setProgress] = useState([`${DateTime.now().toISO()} Initializing`]);

  const checkDb = async () => {
    try {
      setProgress([...progress, `${DateTime.now().toISO()} Checking API`]);
      await callApi('/api/db/availability-test');
      setProgress([...progress, `${DateTime.now().toISO()} API Success`]);
      setResult(fields.successMessage);
    } catch (error) {
      logClientException(error);
      setProgress([...progress, `${DateTime.now().toISO()} API Failed`]);
      setResult(fields.failureMessage);
    }
  };

  useEffect(() => {
    checkDb();
  }, []);

  return (
    <div style={{ margin: '100px' }}>
      <div>
        {progress.map((p, i) => (
          <div key={i}>{p}</div>
        ))}
      </div>
      <div>{result}</div>
    </div>
  );
};

export default AvailabilityTest;
