// package imports
import { useTheme } from '@emotion/react';
import React from 'react';

// local imports
import Section from 'components/common/subcomponents/Section';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import { headingColor, headingStyle } from 'services/styleUtils/headingStyleUtils';
import { AccordionProvider } from './useAccordion';
import { AccordionItem } from './AccordionItem';
import { Freeform } from 'components/common/Freeform';
import { parseBlocks } from 'services/parseUtils';

const Accordion = ({ module: { fields } }) => {
  const theme = useTheme();

  return (
    <Section styleProps={fields.sectionStyle} sectionId={fields.sectionId}>
      <FreeformHeader
        level={fields?.headingLevel || 'h2'}
        text={fields?.headingText}
        style={{
          color: headingColor(theme, fields.headingStyle?.fields?.color),
          ...headingStyle(fields.headingStyle?.fields?.alignment),
          marginBottom: '1.5rem',
        }}
      />
      <AccordionProvider>
        {fields.items?.map(({ contentID, fields: { text, title } }, index) => (
          <AccordionItem key={contentID} title={title} index={index}>
            <Freeform blocks={parseBlocks(text)} />
          </AccordionItem>
        ))}
      </AccordionProvider>
    </Section>
  );
};

export default Accordion;
