import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import SocialMediaLink from './subcomponents/SocialMediaLink';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import GoogleIcon from '@mui/icons-material/Google';
import { TwitterX } from './subcomponents/SvgIcons';

const SiteFooter = ({ globalData }) => {
  const footer = globalData.footer.fields.items[0].fields;
  const sitemaplinks = globalData.footer.sitemaplinks;

  const fetchMobileIconByName = (name) => {
    switch (name) {
      case 'facebook':
        return <FacebookIcon style={{ color: '#fff', fontSize: '2.50rem' }} />;
      case 'twitter':
        return (
          <span style={{ color: '#fff' }}>
            {' '}
            <TwitterX color='inherit' fontSize='inherit' />{' '}
          </span>
        );

      case 'youtube':
        return <YouTubeIcon style={{ color: '#fff', fontSize: '2.50rem' }} />;
      case 'pinterest':
        return <PinterestIcon style={{ color: '#fff', fontSize: '2.50rem' }} />;
      case 'instagram':
        return <InstagramIcon style={{ color: '#fff', fontSize: '2.50rem' }} />;
      case 'google':
        return <GoogleIcon style={{ color: '#fff', fontSize: '2.50rem' }} />;
      default:
        return '';
    }
  };

  const fetchIconByName = (name) => {
    switch (name) {
      case 'facebook':
        return <FacebookIcon style={{ color: '#fff', fontSize: '1.50rem' }} />;
      case 'twitter':
        return (
          <span style={{ color: '#fff' }}>
            {' '}
            <TwitterX color='inherit' fontSize='inherit' />{' '}
          </span>
        );
      case 'youtube':
        return <YouTubeIcon style={{ color: '#fff', fontSize: '1.50rem' }} />;
      case 'pinterest':
        return <PinterestIcon style={{ color: '#fff', fontSize: '1.50rem' }} />;
      case 'instagram':
        return <InstagramIcon style={{ color: '#fff', fontSize: '1.50rem' }} />;
      case 'google':
        return <GoogleIcon style={{ color: '#fff', fontSize: '1.50rem' }} />;
      default:
        return '';
    }
  };

  const ProductList = styled.ul`
    list-style-type: none;
    @media screen and (min-width: 900px) {
      text-align: left;
      padding: 0 10px;
      margin: 10px 0 15px 0;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const LinkList = styled.ul`
    list-style-type: none;
    @media screen and (min-width: 900px) {
      text-align: left;
      padding: 0 10px;
      margin: 10px 0 15px 0;
    }
    @media screen and (max-width: 899px) {
      width: 110%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 5px 0 5px -11px;
    }
  `;

  const WebLinkList = styled.ul`
    list-style-type: none;
    @media screen and (min-width: 900px) {
      text-align: left;
      padding: 0 10px;
      margin: 10px 0 15px 0;
    }
    @media screen and (max-width: 899px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 110%;
      margin: 5px 0 5px -11px;
    }
  `;

  const SiteMapLink = styled.li`
    z-index: 100;
    text-align: left;
    @media screen and (min-width: 900px) {
      padding: 0 0 15px 0;
    }
    @media screen and (max-width: 899px) {
      padding: 3px 0;
    }
  `;

  const MobileLinks = styled.div`
    width: 100%;
    @media screen and (min-width: 900px) {
    }
    @media screen and (max-width: 899px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  `;

  const DesktopSiteMapLink = styled.li`
    z-index: 100;
    text-align: left;
    @media screen and (min-width: 900px) {
      padding: 0 0 15px 0;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileSiteMapLink = styled.li`
    z-index: 100;
    text-align: left;
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      padding: 3px 0;
    }
  `;

  const DesktopBorder = styled.hr`
    width: 100%;
    margin: 15px 0;
    @media screen and (min-width: 900px) {
      display: block;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileBorder = styled.hr`
    width: 100%;
    margin: 15px 0;
    border: 0;
    height: 0.5px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      display: block;
    }
  `;

  const SocialMediaList = styled.ul`
    z-index: 10;
    list-style-type: none;
    display: grid;
    margin: 30px 5px 0 15px;
    display: flex;
    @media screen and (min-width: 900px) {
      padding: 5px 0;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const MobileSocialMediaList = styled.ul`
    list-style-type: none;
    display: grid;
    margin: 5px;
    display: flex;
    @media screen and (min-width: 900px) {
      display: none;
    }
    @media screen and (max-width: 899px) {
      padding: 10px 0;
    }
  `;

  const ContactArea = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 899px) {
      justify-content: center;
      align-items: center;
    }
  `;

  return (
    <FooterContainer
      style={{
        backgroundImage: `linear-gradient(180deg, #15588C 9.4%, #063C66 109.4%)`,
      }}
    >
      <FooterImg
        style={{
          backgroundImage: `url(${footer?.backgroundImage?.url})`,
        }}
      />
      <KitGridContainer direction='row'>
        <KitGridItem xs={12} sm={12} md={4} lg={4} align='left' style={{ paddingLeft: '5%' }}>
          <ContactArea>
            <FooterLogo src={footer?.logo?.url} alt='Logo' />
            <MobileCopyright>{footer.copyright}</MobileCopyright>
            <SocialMediaList>
              {footer?.socialMediaIconLinks &&
                footer?.socialMediaIconLinks.length > 0 &&
                footer?.socialMediaIconLinks
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                    if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                    return 0;
                  })
                  .map((media, index) => {
                    return (
                      <SocialMediaLink
                        key={`socialmedia-${index}`}
                        name={media?.fields?.name}
                        url={media?.fields?.linkUrl?.href}
                        target={media?.fields?.linkUrl?.target}
                        icon={fetchIconByName(media?.fields?.name.toLowerCase())}
                        style={{ zIndex: 10 }}
                      />
                    );
                  })}
            </SocialMediaList>
            <MobileSocialMediaList>
              {footer?.socialMediaIconLinks &&
                footer?.socialMediaIconLinks.length > 0 &&
                footer?.socialMediaIconLinks
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                    if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                    return 0;
                  })
                  .map((media, index) => {
                    return (
                      <SocialMediaLink
                        key={`socialmedia-${index}`}
                        name={media?.fields?.name}
                        url={media?.fields?.linkUrl?.href}
                        target={media?.fields?.linkUrl?.target}
                        icon={fetchMobileIconByName(media?.fields?.name.toLowerCase())}
                      />
                    );
                  })}
            </MobileSocialMediaList>
            <Address>{footer?.address}</Address>
          </ContactArea>
        </KitGridItem>
        <KitGridItem xs={12} sm={12} md={2} lg={2} style={{ width: '100%' }}>
          <LinkList>
            <MobileBorder />
            {footer?.pageLinks &&
              footer?.pageLinks.length > 0 &&
              footer?.pageLinks
                .sort((a, b) => {
                  if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                  if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                  return 0;
                })
                .map((link, index) => {
                  return (
                    <MobileLinks key={`links-${index}`}>
                      <SiteMapLink key={`links-${index}`}>
                        <a href={link.fields?.linkUrl?.href} target={link.fields?.linkUrl?.target} rel='noopener noreferrer'>
                          <SiteMapLinkTitle>{link.fields?.linkName}</SiteMapLinkTitle>
                        </a>
                      </SiteMapLink>
                      <MobileBorder />
                    </MobileLinks>
                  );
                })}
          </LinkList>
        </KitGridItem>
        <KitGridItem xs={12} sm={12} md={3} lg={3} style={{ zIndex: 10, borderLeft: '1px solid #fff' }}>
          <ProductList>
            {footer?.productLinks &&
              footer?.productLinks.length > 0 &&
              footer?.productLinks
                .sort((a, b) => {
                  if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                  if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                  return 0;
                })
                .map((link, index) => {
                  return (
                    <SiteMapLink key={`links-${index}`}>
                      <a href={link.fields?.linkUrl?.href} target={link.fields?.linkUrl?.target} rel='noopener noreferrer'>
                        <SiteMapLinkTitle>{link.fields?.linkName}</SiteMapLinkTitle>
                      </a>
                    </SiteMapLink>
                  );
                })}
          </ProductList>
        </KitGridItem>
        <KitGridItem xs={12} sm={12} md={3} lg={3} style={{ zIndex: 10, borderLeft: '1px solid #fff' }}>
          <WebLinkList>
            {footer?.boysTownFooterLinks &&
              footer?.boysTownFooterLinks.length > 0 &&
              footer?.boysTownFooterLinks
                .sort((a, b) => {
                  if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                  if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                  return 0;
                })
                .map((link, index) => {
                  return (
                    <SiteMapLink key={`links-${index}`}>
                      <a href={link.fields?.linkUrl?.href} target={link.fields?.linkUrl?.target} rel='noopener noreferrer'>
                        <SiteMapLinkTitle>{link.fields?.linkName}</SiteMapLinkTitle>
                      </a>
                    </SiteMapLink>
                  );
                })}
          </WebLinkList>
        </KitGridItem>
        <KitGridContainer direction='row' style={{ width: '88%', margin: '30px 5% 0 5%' }}>
          <KitGridItem xs={12} style={{ zIndex: 100 }}>
            <Copyright>{footer.copyright}</Copyright>
            <DesktopBorder />
            <Disclaimer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(footer.disclaimer)}></Disclaimer>
          </KitGridItem>
        </KitGridContainer>
      </KitGridContainer>
    </FooterContainer>
  );
};

SiteFooter.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  // let links = [];
  let sitemaplinks = [];

  try {
    let footer = await api.getContentList({
      referenceName: 'sitefooter',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (footer && footer.items && footer.items.length > 0) {
      contentItem = footer;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load site footer item.', error);
    return null;
  }

  // grab the sitemap links
  try {
    let sitemap = await api.getSitemapNested({
      channelName: channelName,
      languageCode: languageCode,
    });

    sitemaplinks = sitemap
      .filter((node) => node.visible.menu)
      .map((node) => {
        return {
          title: node.menuText || node.title,
          path: node.path,
        };
      });
  } catch (error) {
    if (console) console.error('Could not load nested sitemap.', error);
  }

  return {
    fields: contentItem,
    sitemaplinks,
  };
};

export default SiteFooter;

const FooterContainer = styled('div')(({ theme }) => ({
  minHeight: '380px',
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    padding: '20px 12%',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
}));

const FooterImg = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  zIndex: 10,
  [theme.breakpoints.up('md')]: {
    padding: '20px 12%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    backgroundPosition: 'center',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const FooterLogo = styled('img')(({ theme }) => ({
  alignSelf: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '0 15px',
    maxHeight: '65px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 10px',
    maxHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const LinkName = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  margin: '5px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.45rem',
    textAlign: 'left',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    textAlign: 'left',
  },
  fontWeight: 300,
}));

const Address = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  zIndex: 100,
  padding: '5px',
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.up('md')]: {
    margin: '5px 10px 15px 15px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '5px 10px 15px 15px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
}));

const Copyright = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.common.white,
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    margin: '10px 10px 15px 2px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileCopyright = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  color: theme.palette.common.white,
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px 10px 15px 10px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
}));

const Disclaimer = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    margin: '15px 10px 20px 10px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '15px 10px 20px 10px',
    fontSize: '0.75rem',
    lineHeight: '1.063rem',
  },
}));

const SiteMapLinkTitle = styled('div')(({ theme }) => ({
  zIndex: 100,
  color: theme.palette.common.white,
  position: 'relative',
  textDecoration: 'none',
  margin: '0px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    fontSize: '0.875rem',
    lineHeight: '1.575rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
}));
