import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';


const LayoutContainer = ({ size, sx, children, ...rest}) => {
  const theme = useTheme();

  return (
    <Box sx={{ 
          maxWidth: theme.columnSize[size], 
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          display: 'flex',
          ...sx }} 
          {...rest}>
      {children}
    </Box>
  )
}

LayoutContainer.propTypes = {
  size: PropTypes.oneOf['xs', 'sm', 'md', 'lg', 'xl']
}

export default LayoutContainer;

