// package imports
import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { Box, FormControl, TextField, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';

// local imports
import { default as SavedSnack } from '../AlertSnack';
import { useObservationStore } from '../../stores/observationStore';
import { useUpdateObservationInstance } from '../../hooks/ObservationInstanceHooks';
import PleaseWait from 'components/common/PleaseWait';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const defaultToastAutoHide = 2500;

const StrengthsGrowthGroup = ({ showUpdate }) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackSave, setSnackSave] = useState({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });

  const { observation, patchObservation } = useObservationStore();
  const [eventState, setEventState] = useState({
    areas_strength: '',
    areas_growth: '',
    development_notes: '',
  });

  const afterObservationMutate = (data) => {
    setSnackSave({ message: 'Strengths/Growths Updated', severity: 'success', autoHideDuration: defaultToastAutoHide });
  };

  const afterObservationMutateFail = (error) => {
    logClientException(error);
    if (error instanceof BadRequestError) {
      setErrorMessage(error.formatMessage());
    } else {
      setErrorMessage(defaultErrorMessage);
    }
    updateObservationMutation.reset();
  };

  const updateObservationMutation = useUpdateObservationInstance(observation, afterObservationMutate, afterObservationMutateFail);

  const isFetchingCount = useIsFetching();

  const saveObservation = () => {
    //if (eventState.areas_strength.length > 0 && eventState.areas_growth.length > 0 && eventState.development_notes.length > 0) {
    patchObservation([
      eventState.areas_strength.length > 0 && { name: 'areas_strength', value: eventState.areas_strength },
      eventState.areas_growth.length > 0 && { name: 'areas_growth', value: eventState.areas_growth },
      { name: 'development_notes', value: eventState.development_notes },
    ]);
    //}
  };

  useEffect(() => {
    setEventState({
      ...eventState,
      areas_strength: observation?.areas_strength ?? '',
      areas_growth: observation?.areas_growth ?? '',
      development_notes: observation?.development_notes ?? '',
    });
  }, [observation]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box sx={{ margin: '1rem 0' }}>
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          paddingBottom: '10px',
          marginLeft: '20px',
        }}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <label style={{ fontSize: '1.3rem', lineHeight: '1.525rem', fontWeight: 700 }}>
              Areas of Strength<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
          <div style={{ margin: '0 0 .5rem 0' }}>
            <FormControl style={{ width: '80%' }}>
              <TextField
                value={eventState.areas_strength}
                required
                multiline
                rows={4}
                margin='dense'
                id='areas_strength'
                type='text'
                placeholder='Enter the areas of strength witnessed during the observation.'
                fullWidth
                variant='outlined'
                onChange={(e) => setEventState({ ...eventState, areas_strength: e.target.value })}
                onBlur={(e) => saveObservation()}
                inputProps={{ maxLength: 3000, sx: { fontSize: '1.40rem' } }}
              />
            </FormControl>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          padding: '15px 0',
          marginLeft: '20px',
        }}
      >
        <>
          <label style={{ fontSize: '1.3rem', lineHeight: '1.525rem', fontWeight: 700, marginTop: '10px' }}>
            Areas of Growth<span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ margin: '0 0 .5rem 0', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <FormControl style={{ width: '80%' }}>
              <TextField
                value={eventState.areas_growth}
                required
                multiline
                rows={4}
                margin='dense'
                id='areas_growth'
                type='text'
                placeholder='Enter the areas of growth witnessed during the observation.'
                fullWidth
                variant='outlined'
                onChange={(e) => setEventState({ ...eventState, areas_growth: e.target.value })}
                onBlur={(e) => saveObservation()}
                inputProps={{ maxLength: 3000, sx: { fontSize: '1.40rem' } }}
              />
            </FormControl>
          </div>
        </>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          paddingTop: '10px',
          marginLeft: '20px',
        }}
      >
        <label style={{ fontSize: '1.3rem', lineHeight: '1.525rem', fontWeight: 700 }}>Development Notes</label>
        <>
          <div style={{ margin: '0 0 .5rem 0', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <FormControl style={{ width: '80%' }}>
              <TextField
                value={eventState.development_notes}
                multiline
                rows={4}
                margin='dense'
                id='development-notes'
                type='text'
                placeholder='Enter the any development notes for the observation.'
                fullWidth
                variant='outlined'
                onChange={(e) => setEventState({ ...eventState, development_notes: e.target.value })}
                onBlur={(e) => saveObservation()}
                inputProps={{ maxLength: 3000, sx: { fontSize: '1.40rem' } }}
              />
            </FormControl>
          </div>
        </>
      </Box>

      <div style={{ marginLeft: '20px' }}>
        <em>
          <span style={{ color: 'red' }}>*</span> Required
        </em>
      </div>

      {showUpdate && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <KitButton
            size='sm'
            style={{
              backgroundColor: theme.palette.gold.main,
            }}
            onClick={() => {
              if (eventState.areas_strength.length === 0 || eventState.areas_growth.length === 0) {
                setErrorMessage('Please fill out the required fields.');
                return;
              }
              updateObservationMutation.mutate();
            }}
          >
            Update
          </KitButton>
        </Box>
      )}

      <SavedSnack
        message={snackSave.message}
        openSnack={snackSave.message === null ? false : true}
        onClose={() => {
          setSnackSave({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });
        }}
        severity={snackSave.severity}
        autoHideDuration={snackSave.autoHideDuration}
      />

      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <PleaseWait isLoading={isFetchingCount > 0} />
    </Box>
  );
};

export default StrengthsGrowthGroup;
