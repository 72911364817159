import React from 'react';
import Typography from '@mui/material/Typography';

const BodyCopy = (props) => {
  const { color, children, ...rest } = props;
  return (
    <Typography {...rest} color={color} fontSize='18px' fontWeight='400' lineHeight='28px'>
      {children}
    </Typography>
  );
};

export default BodyCopy;
