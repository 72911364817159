import React, { useState, useEffect } from 'react';

export type UseWindowDimensionsFnReturn = { width: number | undefined; height: number | undefined };

const useWindowDimensions = (): UseWindowDimensionsFnReturn => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined as number | undefined,
    height: undefined as number | undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
