import React, { useState } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { getIcon } from '../../services/agilityTranslations';

const ResourceWithBullets = ({ module }) => {
  const { fields } = module;

  const [textAlign, setTextAlign] = useState('right');

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 75px;
  `;

  const ResourceList = styled.ul`
    list-style-type: none;
    display: grid;
    @media screen and (max-width: 899px) {
      margin: 5px 0;
      width: 100%;
    }
    @media screen and (max-width: 1399px) {
      padding: 0 10px;
      margin: 10px 0;
      width: 85%;
    }
    @media screen and (min-width: 1400px) {
      padding: 0 10px;
      margin: 15px 0;
      width: 55%;
    }
  `;

  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (max-width: 899px) {
      margin: 10px;
      padding: 5px 0;
      max-width: 90%;
    }
    @media screen and (min-width: 900px) {
      padding: 20px 10px;
      max-width: 60%;
    }
  `;

  const MobileResourceContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: relative;
  `;

  const MobileImageContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    @media screen and (max-width: 899px) {
      max-width: 100%;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const ImageContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      max-height: 100%;
    }
  `;

  const ButtonContainer = styled.div`
    margin: 40px 5px;
    @media screen and (max-width: 899px) {
      padding: 0 25px;
    }
  `;

  const ListSeparator = styled.hr`
    margin: 1.5% 0 1.5% 18px;
    width: 90%;
    border: 0.75px dashed #808080;
    &:last-child {
      border: 0px;
    }
  `;

  const openFreeResource = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <React.Fragment>
      <MobileResourceContainer>
        <MobileImageContainer>
          <React.Fragment key='post.contentID'>
            <MobileResourceFrame>
              {fields.resourceImage && <ResourceImg alt={fields.resourceImage.label} src={fields.resourceImage.url} width='285px' />}
            </MobileResourceFrame>
          </React.Fragment>
        </MobileImageContainer>
      </MobileResourceContainer>
      <Container>
        <ResourceList>
          <ListContainer>
            <ImageContainer>
              <React.Fragment key='post.contentID'>
                <ResourceFrame>
                  {fields.resourceImage && <ResourceImg alt={fields.resourceImage.label} src={fields.resourceImage.url} width='280px' />}
                </ResourceFrame>
              </React.Fragment>
            </ImageContainer>
            <ContentContainer>
              <ResourceDescription style={{ color: fields.textColor, margin: 0, padding: '-20px 10px' }}>
                <List>
                  {fields.contentUrls &&
                    fields.contentUrls.length > 0 &&
                    fields.contentUrls
                      .sort((a, b) => {
                        if (parseInt(a.fields.sortValue, 10) > parseInt(b.fields.sortValue, 10)) return 1;
                        if (parseInt(a.fields.sortValue, 10) < parseInt(b.fields.sortValue, 10)) return -1;
                        return 0;
                      })
                      .map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <ListItemButton style={{ width: '100%' }} onClick={(e) => openFreeResource(item.fields.linkUrl.href)}>
                              <ListIcon>{getIcon(fields.iconName, fields.iconColor, '1.10rem')}</ListIcon>
                              {item.fields.linkUrl.href && item.fields.linkUrl.href.length > 0 ? (
                                <ListText>{item.fields.linkName}</ListText>
                              ) : (
                                <ListText>{item.fields.linkName}</ListText>
                              )}
                            </ListItemButton>
                            <ListSeparator />
                          </React.Fragment>
                        );
                      })}
                </List>
              </ResourceDescription>
            </ContentContainer>
          </ListContainer>
        </ResourceList>
      </Container>
    </React.Fragment>
  );
};

ResourceWithBullets.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  try {
    let list = await api.getContentList({
      referenceName: 'resourceWithBullets',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (list && list.items && list.items.length > 0) {
      contentItem = list;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load Resource Group List.', error);
    return null;
  }

  return {
    fields: contentItem,
  };
};

export default ResourceWithBullets;

const ResourceImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  [theme.breakpoints.up('md')]: {
    height: '270px',
  },
  [theme.breakpoints.down('md')]: {
    height: '220px',
  },
}));

const ResourceIcon = styled('div')(({ theme }) => ({
  maxWidth: '80px',
  [theme.breakpoints.up('md')]: {
    padding: '5px 40px 10px 40px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileResourceFrame = styled('div')(({ theme }) => ({
  border: '30px solid #E4E4E4',
  borderRadius: '8px',
}));

const ResourceFrame = styled('div')(({ theme }) => ({
  border: '35px solid #E4E4E4',
  borderRadius: '8px',
}));

const ResourceSeparator = styled('div')(({ theme }) => ({
  fontWeight: 100,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
    lineHeight: '0.40rem',
    padding: '0 25px 0 30px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    lineHeight: '0.45rem',
    padding: '0 25px 0 10px',
  },
}));

const ResourceDescription = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: '0.85rem',
  lineHeight: '1.40rem',
  [theme.breakpoints.up('md')]: {
    margin: '5px 10px 15px 0',
    padding: '0 25px 0 10px',
  },
  [theme.breakpoints.down('md')]: {
    // padding: '0 25px',
  },
}));

const ListText = styled('div')(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.common.gray,
  fontSize: '0.95rem',
  lineHeight: '1.10rem',
}));

const ListIcon = styled('ListItemIcon')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '13%',
  },
  [theme.breakpoints.down('md')]: {
    width: '13%',
  },
}));
