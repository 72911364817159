export const addHours = (numHours, date = new Date()) => {
  date.setTime(date.getTime() + numHours * 60 * 60 * 1000);
  return date;
};

export const weekOfYear = (date = new Date()) => {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
};
