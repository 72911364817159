import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import { CompletedIcon, ContinueIcon, PlayCircleIcon } from './CustomIcons';
import { KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import { hasModuleAccess } from '../../services/subscriptionUtils';

const GatedModuleCard = (props) => {
  const { module, hasAccess } = props;

  const router = useRouter();
  const theme = useTheme();

  const { data: session, status } = useSession();
  const [subModules, setSubModules] = useState();

  const IconContainer = styled('div')(({ theme }) => ({
    margin: '5px 0 10px 0',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }));

  const StateContainer = styled.div`
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    margin-bottom: 15px;
    cursor: pointer;
  `;

  const StateIcon = styled.div`
    display: inline-block;
    margin: 0 5px 0 0;
  `;

  const StateText = styled.div`
    display: inline-block;
    font-size: 0.813rem;
  `;

  return (
    <KitCard style={{ width: '23rem' }}>
      <React.Fragment>
        <KitCardBody style={{ padding: '1.00rem' }}>
          {module.fields.moduleImage && (
            <ImageContainer
              style={{
                backgroundImage: `url(${module?.fields?.moduleImage?.url})`,
              }}
            ></ImageContainer>
          )}
          <CardBodyContainer>
            <ModuleName>{module.fields?.moduleName}</ModuleName>
          </CardBodyContainer>
          {!module.fields.comingSoon && (
            <StateContainer onClick={() => router.push(props.module.url)}>
              <StateIcon>
                {module.state === 'Start' ? (
                  <PlayCircleIcon
                    onClick={() => router.push(props.module.url)}
                    sx={{ color: theme.palette.tertiary.main, height: '1.15em', width: '1.15em' }}
                  />
                ) : module.state === 'Continue' ? (
                  <ContinueIcon onClick={() => router.push(props.module.url)} sx={{ color: '#F2A900', height: '1.25em', width: '1.25em' }} />
                ) : (
                  <CompletedIcon onClick={() => router.push(props.module.url)} sx={{ color: '#B7BF10', height: '1.25em', width: '1.25em' }} />
                )}
              </StateIcon>
              <StateText>{module.state}</StateText>
            </StateContainer>
          )}
          {module.fields.comingSoon && <div style={{ marginTop: 12 }}></div>}
          <LaunchButtonContainer>
            {!module.fields.comingSoon && props?.module?.subMods?.length > 0 && props?.module?.url && (
              <KitButton size='sm' round color='success' onClick={() => router.push(props.module.url)}>
                {hasAccess ? 'View Modules' : 'See More'}
              </KitButton>
            )}
            {(module.fields.comingSoon || module.subMods.length === 0) && (
              <Typography style={{ marginTop: 9 }} sx={{ color: theme.palette.brandedAqua.main }}>
                Coming Soon
              </Typography>
            )}
          </LaunchButtonContainer>
        </KitCardBody>
      </React.Fragment>
    </KitCard>
  );
};

export default GatedModuleCard;

const RoundIcon = styled('span')(({ theme }) => ({
  borderRadius: '5px',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
}));

const ImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '104px',
  backgroundSize: '100%',
  backgroundPosition: 'right 30% top 35%',
  backgroundRepeat: 'no-repeat',
}));

const ModuleImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  height: '104px',
}));

const CardBodyContainer = styled('div')(({ theme }) => ({
  padding: '10px 0 10px 0',
  minHeight: '50px',
}));

const ModuleName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.063rem',
  lineHeight: '1.313rem',
  fontWeight: 600,
}));

const LaunchButtonContainer = styled('div')(() => {
  const customTheme = useTheme();
  return {
    borderTop: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    '& button': {
      width: '150px',
    },
  };
});

const ModuleDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: 0,
  fontSize: '1.00rem',
  lineSpacing: '1.20rem',
  lineHeight: '1.257rem',
  fontWeight: 300,
}));
