// package imports
import styled from '@emotion/styled';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

const OverflowTip = ({ maxWidth, children, ...restProps }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [open, setOpen] = React.useState(false);

  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box name='overflow-tip' {...restProps}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LightTooltip title={children} open={open}>
            <div
              ref={textElementRef}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: maxWidth,
              }}
            >
              {children}
            </div>
          </LightTooltip>
          <IconButton onClick={handleOpen} size='small' sx={{ display: isOverflowed ? 'flex' : 'none' }} color='primary'>
            <InfoIcon />
          </IconButton>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default OverflowTip;
