import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { emailRegex } from 'services/stringUtils';
import { getLocalStoreString, setLocalStoreString, removeLocalStoreItem } from 'services/storageUtils';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, PRODUCTS } from 'services/constants';
import ClientSideTable from '../../pageComponents/ClientSideTable';
import AddEditSchoolSubscriptionDialog from './AddEditSchoolSubscription';
import YesNoDialog from 'components/common/subcomponents/YesNoDialog';
import SeatListToolTip from './SeatListToolTip';
import { Checkbox } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const SchoolManager = (props) => {
  const router = useRouter();
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [deleteSub, setDeleteSub] = useState({ open: false, subId: null });
  const [addEditSchoolSubState, setAddEditSchoolSubState] = useState({
    open: false,
    action: 'Add',
    editSchool: null,
    editSchoolSub: null,
  });

  const [pageState, setPageState] = useState({
    state: null,
    district: null,
    school: null,
  });

  const [savedState, setSavedState] = useState(() => {
    return getLocalStoreString('dm-st');
  });
  const [savedDistrictId, setSavedDistrictId] = useState(() => {
    return getLocalStoreString('dm-did');
  });

  const [savedSchoolId, setSavedSchoolId] = useState(() => {
    return getLocalStoreString('dm-sch');
  });

  useEffect(() => {
    const init = async () => {
      const tempState = props.stateList.find((s) => s.value === savedState);
      setPageState({ ...pageState, state: tempState });
      await fetchDistrictsForState(tempState);
      setErrorMessage(null);
    };

    if (savedState) {
      init();
    }
  }, [savedState]);

  const fetchDistrictsForState = async (state) => {
    try {
      setFetching(true);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      const districtList = res.results.map((r) => ({ label: r.name, value: r }));
      setDistrictList(districtList);
      let tempDistrict = null;
      let tempSchool = null;
      if (savedDistrictId) {
        tempDistrict = districtList.find((d) => d.value.id === savedDistrictId);
        tempSchool = await fetchSchoolsForDistrict(tempDistrict.value.id);
      }
      setFetching(false);
      setPageState({ ...pageState, state: state, district: tempDistrict, school: tempSchool ? tempSchool : null });
    } catch (error) {
      setFetching(false);
      setErrorMessage(ERROR_MESSAGES.Default);
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district) => {
    try {
      setFetching(true);
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district}`);
      const tempList = res.results.map((r) => ({ label: r.name, value: r }));
      setSchoolList(tempList);
      if (savedSchoolId && savedDistrictId === district) {
        const tempSchool = tempList.find((d) => d.value.id === savedSchoolId);
        return tempSchool;
      }

      setFetching(false);
    } catch (error) {
      setFetching(false);
      setErrorMessage(ERROR_MESSAGES.Default);
      logClientException(error);
    }
  };

  const saveSchool = async () => {
    try {
      if (!canSave()) {
        return;
      }
      setFetching(true);

      let putObject = {
        school_id: pageState.school.value.id,
        district_id: pageState.school.value.district_id,
      };
      await callApi('/api/db/school-admin-info', 'PUT', putObject);
      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const showEditSchoolSub = (subId) => {
    setAddEditSchoolSubState({ open: true, action: 'Update', editSchool: pageState.school.value, editSchoolSub: subId });
  };
  const showAddSchoolSub = () => {
    setAddEditSchoolSubState({ open: true, action: 'Add', editSchool: pageState.school.value, editSchoolSub: null });
  };
  const handleAddEditSchoolSubClose = async (doSave, updated) => {
    setAddEditSchoolSubState({ ...addEditSchoolSubState, open: false });
    if (doSave) {
      let updatedSchool = pageState.school;
      updatedSchool.value = updated;
      setPageState({ ...pageState, school: updatedSchool });
    }
  };

  const onDeleteSub = async () => {
    try {
      setDeleteSub({ open: false });
      setFetching(true);
      let deleteObject = {
        school_id: pageState.school.value.id,
        district_id: pageState.school.value.district_id,
        sub_id: deleteSub.subId,
      };
      const sch = await callApi('/api/db/school-subscription', 'DELETE', deleteObject);
      let updatedSchool = pageState.school;
      updatedSchool.value = sch;
      setPageState({ ...pageState, school: updatedSchool });
      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const schoolHeaderColumns = () => {
    return [
      { text: 'Product', key: 'product', field: 0, isSortable: true },
      { text: 'CRM ID', key: 'crm_id', field: 0, isSortable: true },
      { text: 'Seat License', key: 'is_seat_based', field: 2, isSortable: true },
      { text: '# Seats', key: 'num_seats', field: 1, isSortable: true },
      { text: '# Seats Used', key: 'num_seats', field: 1, isSortable: true },
      { text: 'Start', key: 'start_date', field: 2, isSortable: true },
      { text: 'End', key: 'date', field: 3, isSortable: true },
      { text: '', key: 'edit_button', isSortable: false },
    ];
  };

  const schoolSubRecords = () => {
    let records = null;
    if (pageState.school?.value?.subscriptions) {
      records = pageState.school?.value?.subscriptions?.map((s) => {
        return [
          PRODUCTS.find((p) => p.id === s.product)?.name,
          s.crm_id,
          s.is_seat_based ? <Checkbox checked /> : 'No',
          s.num_seats,
          s.is_seat_based ? <SeatListToolTip title={s.seat_list?.length ?? 0} content={s.seat_list.join('\n')} /> : 'N/A',
          DateTime.fromMillis(s.start_date).toFormat('LL-dd-yyyy'),
          DateTime.fromMillis(s.end_date).toFormat('LL-dd-yyyy'),
          <div key={s.id}>
            <KitButton
              color='info'
              justIcon
              size='sm'
              onClick={() => setDeleteSub({ open: true, subId: s.id })}
              title='Delete Subscription'
              style={{ marginRight: '25px' }}
            >
              <DeleteIcon />
            </KitButton>
            <KitButton color='info' round justIcon size='sm' onClick={() => showEditSchoolSub(s.id)} title='Edit code'>
              <EditIcon />
            </KitButton>
          </div>,
        ];
      });
    }
    return records;
  };

  const canSave = () => {
    let hasError = false;

    return !hasError;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <div>
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <YesNoDialog
        open={deleteSub.open}
        content='Are you sure you want to delete this subscription?'
        yesClose={onDeleteSub}
        noClose={() => setDeleteSub({ open: false })}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            sx={{ marginTop: '7px' }}
            autoFocus
            disablePortal
            options={props.stateList}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            size='small'
            value={pageState.state}
            onChange={async (e, v) => {
              setPageState({ ...pageState, state: v, district: null });
              if (v?.value) {
                await fetchDistrictsForState(v);
                setLocalStoreString('dm-st', v.value);
              } else {
                removeLocalStoreItem('dm-st');
                setDistrictList(null);
              }
            }}
            renderInput={(params) => <TextField {...params} label='State' />}
          />

          <Autocomplete
            sx={{ marginTop: '7px' }}
            disablePortal
            options={districtList}
            fullWidth
            size='small'
            isOptionEqualToValue={(o, v) => o.value?.id === v?.value?.id}
            disabled={!pageState.state}
            value={pageState.district}
            onChange={(e, v) => {
              if (v?.value?.id) {
                setLocalStoreString('dm-did', v?.value?.id);
                fetchSchoolsForDistrict(v?.value?.id);
              } else {
                removeLocalStoreItem('dm-did');
              }
              setPageState({ ...pageState, district: v, school: null });
            }}
            renderInput={(params) => <TextField disabled={!pageState.state} {...params} label='School district' />}
          />

          <Autocomplete
            sx={{ marginTop: '7px' }}
            disablePortal
            options={schoolList}
            fullWidth
            size='small'
            isOptionEqualToValue={(o, v) => o.value?.id === v?.value?.id}
            disabled={!pageState.district}
            value={pageState.school}
            onChange={(e, v) => {
              if (v?.value) {
                setLocalStoreString('dm-sch', v.value.id);
              } else {
                removeLocalStoreItem('dm-sch');
              }
              setPageState({ ...pageState, school: v });
            }}
            renderInput={(params) => <TextField disabled={!pageState.state} {...params} label='School' />}
          />

          {/* <KitGridContainer direction='row' align='right'>
          <KitGridItem xs={12}>
            <KitButton onClick={() => saveSchool()} size='sm' round color='success' disabled={!pageState.school || adminEmailError}>
              Update School Info
            </KitButton>
          </KitGridItem>
        </KitGridContainer> */}
        </Grid>

        <Grid item xs={12} sm={9}>
          <Box sx={{ marginLeft: '15px', marginRight: '15px', marginBottom: '15px' }}>
            <ClientSideTable
              headerCols={schoolHeaderColumns()}
              preTableContent={
                <KitGridContainer direction='row' align='right'>
                  <KitGridItem xs={12}>
                    <Tooltip
                      disableHoverListener={pageState.school && pageState.district?.value?.sso_provider}
                      disableFocusListener={pageState.school && pageState.district?.value?.sso_provider}
                      placement='top'
                      title='Select district/school and set the SSO Provider before adding subscriptions.'
                    >
                      <span>
                        <KitButton
                          size='sm'
                          round
                          color='primary'
                          onClick={showAddSchoolSub}
                          disabled={!pageState.school || !pageState.district?.value?.sso_provider}
                        >
                          <AddCircleIcon /> Add Subscription
                        </KitButton>
                      </span>
                    </Tooltip>
                  </KitGridItem>
                </KitGridContainer>
              }
              records={schoolSubRecords()}
            />
          </Box>
        </Grid>

        <Grid item xs={12} align='right'>
          <KitButton
            color='primary'
            round
            size='sm'
            onClick={() => router.push('/admin/manage-users')}
            title='Manage district and school administrators'
          >
            <ManageAccountsIcon /> Manage Admin Users
          </KitButton>
        </Grid>
      </Grid>

      <AddEditSchoolSubscriptionDialog
        action={addEditSchoolSubState.action}
        editSchool={addEditSchoolSubState.editSchool}
        editSubId={addEditSchoolSubState.editSchoolSub}
        open={addEditSchoolSubState.open}
        onClose={handleAddEditSchoolSubClose}
        editDistrict={pageState.district?.value}
      />
      <PleaseWait isLoading={fetching} />
    </div>
  );
};

SchoolManager.propTypes = {
  user: PropTypes.object.isRequired,
  stateList: PropTypes.array,
};

export default SchoolManager;
