// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// local imports
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { CheckmarkIcon } from 'components/common/subcomponents/SvgIcons';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles, PromptContainer, PromptRadio } from '../StyledComponents';

const CorrectionEdit = ({ onComplete, onDirty }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { updateTimelineObservation, removeTimelineObservation, editTimelineItem, setEditTimelineItem } = useObservationStore();

  const [correctionPrompt, setCorrectionPrompt] = useState(''); // ['cp-bc', 'cp-nc', 'cs-bc', 'cs-nc', 'gsc-bc', 'gsc-nc', 'ct-bc', 'ct-nc']
  const [sPrompt, setSPrompt] = useState(''); // s'
  const [slPrompt, setSLPrompt] = useState(''); // sl'
  const [moPrompt, setMoPrompt] = useState(''); // mo'
  const [note, setNote] = useState('');
  const [hasCorrectionPrompt, setHasCorrectionPrompt] = useState(false);
  const [hasMoPrompt, setHasMoPrompt] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (editTimelineItem?.type === 'Correction') {
      const tags = editTimelineItem.tags;
      if (tags.includes('CP-BC')) {
        setCorrectionPrompt('cp-bc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('CP-NC')) {
        setCorrectionPrompt('cp-nc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('CS-BC')) {
        setCorrectionPrompt('cs-BC');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('CS-NC')) {
        setCorrectionPrompt('cs-nc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('GSC-BC')) {
        setCorrectionPrompt('gsc-bc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('GSC-NC')) {
        setCorrectionPrompt('gsc-nc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('CT-BC')) {
        setCorrectionPrompt('ct-bc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('CT-NC')) {
        setCorrectionPrompt('ct-nc');
        setHasCorrectionPrompt(true);
      } else if (tags.includes('MO')) {
        setMoPrompt('correction-mo');
        setHasMoPrompt(true);
      }

      if (tags.includes('S')) {
        setSPrompt('correction-s');
      }
      if (tags.includes('SL')) {
        setSLPrompt('correction-sl');
      }
      setNote(editTimelineItem.note);
    } else {
      resetState();
    }
  }, [editTimelineItem]);

  const resetState = () => {
    setCorrectionPrompt('');
    setHasCorrectionPrompt(false);
    setSPrompt('');
    setSLPrompt('');
    setMoPrompt('');
    setHasMoPrompt(false);
    setNote('');
    setConfirmDeleteOpen(false);
  };

  const radioOnChange = (e) => {
    onDirty();
    if (editTimelineItem?.type !== 'Correction') {
      setEditTimelineItem({
        timestamp: editTimelineItem.timestamp,
        type: 'Correction',
        tags: [],
        note: note,
      });
    }
    const { name, value, checked } = e.target;
    if (name === 'correction-1') {
      setCorrectionPrompt(value);
      setHasCorrectionPrompt(true);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'correction-s-1') {
      setSPrompt(checked && sPrompt === 'correction-s' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'correction-sl-1') {
      setSLPrompt(checked && slPrompt === 'correction-sl' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'correction-mo') {
      setCorrectionPrompt('');
      setHasCorrectionPrompt(true);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt(checked && moPrompt === 'correction-mo' ? '' : value);
      setHasMoPrompt(true);
    }
  };

  const addToTimeline = () => {
    const timelineObservation = {
      timestamp: editTimelineItem.timestamp,
      type: 'Correction',
      tags: [],
      note: note,
    };

    let hasMo = false;
    if (correctionPrompt?.length > 0) {
      setHasCorrectionPrompt(true);
      timelineObservation.tags.push(correctionPrompt.toUpperCase());
    }
    if (sPrompt?.length > 0) {
      timelineObservation.tags.push('S');
    }
    if (slPrompt?.length > 0) {
      timelineObservation.tags.push('SL');
    }
    if (moPrompt?.length > 0) {
      setHasMoPrompt(true);
      timelineObservation.tags.push('MO');
    }
    if (hasCorrectionPrompt || hasMoPrompt) {
      updateTimelineObservation(timelineObservation);
      setCorrectionPrompt('');
      setHasCorrectionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
      onComplete();
    }
  };

  const deleteTimelineObservation = () => {
    removeTimelineObservation(editTimelineItem);
    resetState();
    onComplete();
  };

  return (
    <PromptContainer promptType='Correction'>
      <div className='prompt-title-box'>
        <CheckmarkIcon fillColor={theme.palette.obsCorrection.dark} />
        <Typography sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '18.86px', color: '#000', marginLeft: '1rem' }}>Correction</Typography>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', flexWrap: 'nowrap', width: '85%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.35rem' }}>
            <input type='radio' id='cp-bc-edit' name='correction-1' value='cp-bc' checked={correctionPrompt === 'cp-bc'} onClick={radioOnChange} />
            <label htmlFor='cp-bc-edit'>CP - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.35rem' }}>
            <input type='radio' id='cp-nc-edit' name='correction-1' value='cp-nc' checked={correctionPrompt === 'cp-nc'} onClick={radioOnChange} />
            <label htmlFor='cp-nc-edit'>CP - NC</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='cs-bc-edit' name='correction-1' value='cs-bc' checked={correctionPrompt === 'cs-bc'} onClick={radioOnChange} />
            <label htmlFor='cs-bc-edit'>CS - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='cs-nc-edit' name='correction-1' value='cs-nc' checked={correctionPrompt === 'cs-nc'} onClick={radioOnChange} />
            <label htmlFor='cs-nc-edit'>CS - NC</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='gsc-bc-edit' name='correction-1' value='gsc-bc' checked={correctionPrompt === 'gsc-bc'} onClick={radioOnChange} />
            <label htmlFor='gsc-bc-edit'>GSC - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='gsc-nc-edit' name='correction-1' value='gsc-nc' checked={correctionPrompt === 'gsc-nc'} onClick={radioOnChange} />
            <label htmlFor='gsc-nc-edit'>GSC - NC</label>
          </PromptRadio>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='ct-bc-edit' name='correction-1' value='ct-bc' checked={correctionPrompt === 'ct-bc'} onClick={radioOnChange} />
            <label htmlFor='ct-bc-edit'>CT - BC</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' style={{ marginTop: '.75rem' }}>
            <input type='radio' id='ct-nc-edit' name='correction-1' value='ct-nc' checked={correctionPrompt === 'ct-nc'} onClick={radioOnChange} />
            <label htmlFor='ct-nc-edit'>CT - NC</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '1rem', width: '85%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.obsCorrection.xLight,
            borderRadius: '12px',
            padding: '12px',
            width: '66.67%',
          }}
        >
          <PromptRadio promptType='Correction' round={true}>
            <input
              type='radio'
              id='correction-s-edit'
              name='correction-s-1'
              value='correction-s'
              checked={sPrompt === 'correction-s'}
              onClick={radioOnChange}
            />
            <label htmlFor='correction-s-edit'>S</label>
          </PromptRadio>
          <PromptRadio promptType='Correction' round={true}>
            <input
              type='radio'
              id='correction-sl-edit'
              name='correction-sl-1'
              value='correction-sl'
              checked={slPrompt === 'correction-sl'}
              onClick={radioOnChange}
            />
            <label htmlFor='correction-sl-edit'>SL</label>
          </PromptRadio>
        </Box>
        <Box sx={{ padding: '12px' }}>
          <PromptRadio promptType='Correction' round={true}>
            <input
              type='radio'
              id='correction-mo-edit'
              name='correction-mo'
              value='correction-mo'
              checked={moPrompt === 'correction-mo'}
              onClick={radioOnChange}
            />
            <label htmlFor='correction-mo-edit'>MO</label>
          </PromptRadio>
        </Box>
      </Box>
      <Box sx={{ marginTop: '.98rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
        <FormControl fullWidth size='small'>
          <TextField
            placeholder='Type note'
            multiline={true}
            rows={2}
            InputLabelProps={{ shrink: true }}
            sx={{ ...styles.select }}
            InputProps={{ sx: { ...styles.select } }}
            inputProps={{ maxLength: 200 }}
            label=''
            id='correction-note-edit'
            size='small'
            value={note}
            onChange={(e) => {
              onDirty();
              setNote(e.target.value);
            }}
          />
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <KitButton
            onClick={addToTimeline}
            size='sm'
            style={{
              backgroundColor: theme.palette.gold.main,
              marginTop: '1rem',
            }}
            disabled={!hasCorrectionPrompt && !hasMoPrompt}
          >
            <span>
              <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
            </span>
          </KitButton>
          <KitButton
            onClick={() => setConfirmDeleteOpen(true)}
            size='sm'
            style={{
              backgroundColor: theme.palette['obsOff Task'].main,
              marginTop: '1rem',
            }}
            disabled={editTimelineItem?.type === 'Correction' ? false : true}
          >
            <span>
              <DeleteForeverIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />
              Delete
            </span>
          </KitButton>
        </Box>
      </Box>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        confirmMessage='Are you sure you want to delete this Correction?'
        handleClose={() => setConfirmDeleteOpen(false)}
        handleYes={deleteTimelineObservation}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </PromptContainer>
  );
};

export default CorrectionEdit;
