export const buttonColor = (theme, colorString) => {
  return colorString?.startsWith('#')
    ? colorString
    : colorString?.includes('.')
    ? theme.palette[colorString?.split('.')[0]][colorString?.split('.')[1]]
    : theme.palette.primary.dark;
};

export const buttonVariant = () => {
  return 'contained';
};
