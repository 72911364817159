import React from 'react';
import styled from '@emotion/styled';

export default function SummaryAdditionalText(props) {
  const { content, textColor, textSize } = props;

  const AdditionalText = styled.div`
    margin-top: 15px;
    font-weight: 600;
    color: ${textColor};
    font-size: ${textSize};
    @media screen and (max-width: 899px) {
      padding-left: 0;
    }
    @media screen and (min-width: 900px) {
    }
  `;

  return <React.Fragment>{content && <AdditionalText>{content}</AdditionalText>}</React.Fragment>;
}
