// Package imports
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';

const SessionSummaryMetricCard = ({ width, title, upOrDown, na, metric, children }) => {
  const theme = useTheme();
  return (
    <KitCard style={{ padding: '.75rem 1rem', width: width, margin: '2px' }}>
      <KitCardBody style={{ padding: 0, height: '100%' }}>
        <Box id='outer-box' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
          <Box
            id='left-column'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'space-between',
              height: '100%',
            }}
          >
            <Box sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: theme.palette.text.mediumDark }}>{title}</Box>
            <Box sx={{ display: 'flex' }}>
              {!na && (
                <span style={{ alignSelf: 'center' }}>{upOrDown ? <ThumbUpAltIcon color='primary' /> : <ThumbDownAltIcon color='primary' />}</span>
              )}
              <Typography
                component='span'
                sx={{ fontSize: '28px', fontWeight: 500, lineHeight: '35px', color: theme.palette.obsBlue.main, marginLeft: '.4rem' }}
              >
                {na ? 'N/A' : metric}
              </Typography>
            </Box>
          </Box>
          <Box id='right-cilumn' sx={{ display: 'flex', alignItems: 'center' }}>
            {children}
          </Box>
        </Box>
      </KitCardBody>
    </KitCard>
  );
};

export default SessionSummaryMetricCard;
