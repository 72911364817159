import React from 'react';
import { useTheme } from '@emotion/react';
import Section from 'components/common/subcomponents/Section';
import { FreeformImage } from 'components/common/Freeform/FreeformImage';

const Image = (props) => {
  const {
    module: { fields },
  } = props;

  const theme = useTheme();

  return (
    <>
      <Section styleProps={fields.sectionStyle} sectionId={fields.sectionId}>
        <FreeformImage
          file={{ url: fields?.image.url, size: { height: fields?.image.height, width: fields?.image.width } }}
          caption={fields?.image.label}
        />
      </Section>
    </>
  );
};

export default Image;
