import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandIcon from '@mui/icons-material/Expand';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSession } from 'next-auth/react';
import { DateTime } from 'luxon';
import { KitGridContainer, KitGridItem, KitButton, KitTable } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { logClientException, logServerException } from 'appinsights/clientAppInsights';
import { isAdmin, isBoysTownAdmin, ROLE_CONST } from 'services/roleUtils';
import { callApi } from 'services/apiWrapper';
import NotAuthorized from 'components/common/NotAuthorized';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import AddMultipleDialog from './AddMultipleDialog';
import AddEditUserDialog from './AddEditUserDialog';
import { states, formatDateTime, formatDate } from 'services/stringUtils';
import CustomFilter from '../../../common/subcomponents/CustomFilter';
import SortableTableHeader from '../../../common/subcomponents/SortableTableHeader';
import ManageUserCard from './ManageUserCard';
import YesNoDialog from '../../../common/subcomponents/YesNoDialog';
import { COUPON_CODE_TYPES, CouponCodeOfferDesc, PRODUCTS } from '../../../../services/constants';
import SendTestEmail from './SentTestEmail';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AdminManageUsers = (props) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const [resendDialogOpen, setResendDialogOpen] = useState(false);
  const [openExtendTrial, setOpenExtendTrial] = useState(false);
  const [openExtendTrialMessage, setOpenExtendTrialMessage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [multipleOpen, setMultipleOpen] = useState(false);
  const [testEmailOpen, setTestEmailOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [addEditState, setAddEditState] = useState({
    open: false,
    action: 'Add',
    editUser: null,
  });
  const [couponCodes, setCouponCodes] = useState(null);
  const fetching = useRef(0);
  const [subDialogState, setSubDialogState] = useState({ open: false, subs: [], email: '' });
  const [districtSubs, setDistrictSubs] = useState(null);
  const [schoolSubs, setSchoolSubs] = useState(null);

  const [pageState, setPageState] = useState({
    allRecords: [],
    tableRecords: [],
    filter: '',
    fetchedRecords: false,
    showAllRecords: false,
    sortDirection: 'asc',
    sortBy: '',
  });
  const [paginationOptions, setPaginationOptions] = useState({
    searching: false,
    page: 0,
    perPage: 10,
    totalRecords: 0,
  });

  const sortOptions = useRef({ field: 'last_login', direction: 'DESC' });

  const filterOptions = useRef(null);

  useEffect(() => {
    const getSubs = async () => {
      try {
        let path = '/api/db/district-subscription';
        let res = await callApi(path);
        setDistrictSubs(res.results ?? []);
        path = '/api/db/school-subscription';
        res = await callApi(path);
        setSchoolSubs(res.results ?? []);
      } catch (error) {
        console.log(error);
        logClientException(error);
        setErrorMessage(defaultErrorMessage);
      }
    };
    if (schoolSubs === null && districtSubs === null) {
      getSubs();
    }
  }, []);

  useEffect(() => {
    if (isAdmin(session) && schoolSubs !== null && districtSubs !== null) {
      init();
    }
  }, [session, schoolSubs, districtSubs]);

  const init = async () => {
    await fetchCouponCodes();
    await fetchUsers();
  };

  if (!isAdmin(session)) {
    //router.push("/");
    return <NotAuthorized />;
  }

  const formatName = (lastName, firstName) => {
    let name = '';
    if (lastName && lastName.length > 0) {
      name = lastName;
    }
    if (firstName && firstName.length > 0) {
      if (name.length > 0) {
        name += ', ';
      }
      name += firstName;
    }
    return name;
  };

  const formatTrialExpDate = (userRecord) => {
    if (!userRecord.subscription || !userRecord.subscription.exp_date) {
      return '';
    }
    let isExpired = false;
    if (userRecord.subscription.exp_date < Date.now()) {
      isExpired = true;
    }
    return isExpired ? (
      <KitButton color='info' round size='sm' onClick={() => extendTrial(userRecord)} title='Extend Trial'>
        {formatDate(userRecord.subscription.exp_date)} <ExpandIcon sx={{ ml: '5px' }} />
      </KitButton>
    ) : (
      formatDate(userRecord.subscription.exp_date)
    );
  };

  const sortSubs = (subs) => {
    if (!subs || !Array.isArray(subs)) {
      return [];
    }
    return subs.sort((a, b) => {
      if (a.end_date < b.end_date) return 1;
      if (a.end_date > b.end_date) return -1;
      return 0;
    });
  };

  const formatSubscription = (userRecord) => {
    console.log('formatSubscription.userRecord', userRecord);
    console.log('formatSubscription.schoolSubs', schoolSubs);
    let activeSubs = [];
    let allSubs = [];
    if (userRecord?.subscriptions || userRecord?.subscription) {
      // look at any original style FreeTrial type of subs
      if (userRecord?.subscription?.type === 'FreeTrial') {
        userRecord.subscriptions = userRecord.subscriptions || [];
        userRecord.subscriptions.push({
          product: 'wms',
          end_date: userRecord.subscription.exp_date,
          start_date: userRecord.created_at,
          transaction: {
            dateTime: DateTime.fromJSDate(new Date(userRecord.created_at)).toFormat('M/d/yyyy h:mm:ss a'),
          },
          coupon_code: userRecord.subscription.coupon_code,
        });
      }

      const userSubs = sortSubs(userRecord.subscriptions);
      for (let i = 0; i < userSubs.length; i++) {
        let sub = userSubs[i];
        sub.is_expired = sub.end_date < new Date().getTime();
        sub.type = '(I) ';
        if (sub.product !== 'free') {
          if (!sub.transaction?.dateTime) {
            sub.transaction = sub.transaction || {};
            sub.transaction.dateTime = DateTime.fromJSDate(new Date(sub.start_date)).toFormat('M/d/yyyy h:mm:ss a');
          }
          allSubs.push(sub);
          if (!sub.is_expired) {
            activeSubs.push(sub);
          }
        }
      }
    }

    // look for district subs
    if (userRecord.district_id && districtSubs?.findIndex((d) => d.id === userRecord.district_id) > -1) {
      const disSubs = sortSubs(districtSubs?.find((d) => d.id === userRecord.district_id)?.subscriptions);
      for (let i = 0; i < disSubs.length; i++) {
        let sub = disSubs[i];
        sub.is_expired = sub.end_date < new Date().getTime();
        sub.type = '(D) ';
        sub.transaction = sub.transaction || {};
        sub.transaction.dateTime = DateTime.fromJSDate(new Date(sub.start_date)).toFormat('M/d/yyyy h:mm:ss a');
        allSubs.push(sub);
        if (sub.is_seat_based && sub.seat_list?.indexOf(userRecord.emailAddress) === -1) {
          sub.not_in_seat_list = true; // not in seat list
        } else {
          sub.not_in_seat_list = false;
        }

        if (!sub.is_expired && !sub.not_in_seat_list) {
          activeSubs.push(sub);
        }
      }
    }
    // look for school subs
    if (userRecord.school_id && schoolSubs?.findIndex((d) => d.id === userRecord.school_id) > -1) {
      const schSubs = sortSubs(schoolSubs?.find((d) => d.id === userRecord.school_id)?.subscriptions);
      for (let i = 0; i < schSubs.length; i++) {
        let schSub = schSubs[i];
        schSub.is_expired = schSub.end_date < new Date().getTime();
        schSub.type = '(S) ';
        schSub.transaction = schSub.transaction || {};
        schSub.transaction.dateTime = DateTime.fromJSDate(new Date(schSub.start_date)).toFormat('M/d/yyyy h:mm:ss a');
        allSubs.push(schSub);
        if (schSub.is_seat_based && schSub.seat_list?.indexOf(userRecord.emailAddress) === -1) {
          schSub.not_in_seat_list = true; // not in seat list
        } else {
          schSub.not_in_seat_list = false;
        }
        if (!schSub.is_expired && !schSub.not_in_seat_list) {
          activeSubs.push(schSub);
        }
      }
    }

    // if there are no active subs, show the expired (if exist)
    for (let i = 0; i < allSubs.length; i++) {
      let sub = allSubs[i];
      if (sub.product !== 'free' && sub.is_expired && activeSubs.findIndex((s) => s.type === sub.type && s.product === sub.product) === -1) {
        if (!sub.transaction?.dateTime) {
          sub.transaction = sub.transaction || {};
          sub.transaction.dateTime = DateTime.fromJSDate(new Date(sub.start_date)).toFormat('M/d/yyyy h:mm:ss a');
        }
        if (!sub.not_in_seat_list) {
          activeSubs.push(sub);
        }
      }
    }

    const formatted = activeSubs.map((s) => {
      return (
        <Box
          key={s.id}
          sx={{ cursor: 'pointer', color: s.is_expired ? theme.palette.warning.main : 'inherit' }}
          onClick={() => setSubDialogState({ open: true, subs: allSubs })}
        >
          {s.type}
          {PRODUCTS.find((p) => p.id === s.product).name} <i>Exp</i>: {formatDate(s.end_date)}
        </Box>
      );
    });
    return formatted;
  };

  const fetchUsers = async (perPage = 10, page = 0, append = false) => {
    try {
      fetching.current += 1;
      setPaginationOptions({ ...paginationOptions, searching: true });

      let path = `/api/db/user-list?page_size=${perPage}&page_number=${page}`;
      let qs = [];

      if (filterOptions.current) {
        for (const key in filterOptions.current) {
          if (filterOptions.current.hasOwnProperty(key)) {
            qs.push(`filter.${key}=${filterOptions.current[key]}`);
          }
        }
      }
      qs.push(`order_by=${sortOptions.current.field}:${sortOptions.current.direction}`);

      if (qs.length > 0) {
        path += `&${qs.join('&')}`;
      }
      let res = await callApi(path);

      const tableRecords = res.results.slice().map((r) => [
        r.emailAddress,
        formatName(r.lastName, r.firstName),
        r.district_name,
        r.school_name,
        formatSubscription(r),
        //formatTrialExpDate(r),
        formatDateTime(r.last_login),
        <Box display='flex' gap='10px' key={r.emailAddress}>
          <KitButton color='info' round justIcon size='sm' onClick={() => showEdit(r)} title='Edit user'>
            <EditIcon />
          </KitButton>
          <KitButton
            color='info'
            justIcon
            round
            size='sm'
            onClick={() => {
              setCurrentUser(r);
              setResendDialogOpen(true);
            }}
            title='Resend Invite'
          >
            <RestartAltIcon />
          </KitButton>
        </Box>,
      ]);
      let allRecords = [...pageState.allRecords];
      const formatRecords = (recs) => {
        return recs.map((r) => {
          return {
            ...r,
            name: formatName(r.lastName, r.firstName),
            last_login: formatDateTime(r.last_login),
            subscription_type: formatSubscription(r),
            trial_exp_date: formatTrialExpDate(r),
          };
        });
      };
      if (append) {
        formatRecords(res.results).forEach((r) => {
          allRecords.push(r);
        });
      } else {
        allRecords = formatRecords(res.results);
      }
      fetching.current -= 1;
      setPageState({ ...pageState, allRecords: allRecords, tableRecords: tableRecords });
      setPaginationOptions({ ...paginationOptions, totalRecords: res.totalRecords, perPage, page, searching: false });
    } catch (error) {
      console.log(error);
      logClientException(error);
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
    }
  };

  const fetchCouponCodes = async () => {
    try {
      let path = '/api/db/coupon-code-list?active_only=true';
      let res = await callApi(path);
      setCouponCodes(res.results);
    } catch (error) {
      console.log(error);
      logClientException(error);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const resendInvite = async (user) => {
    try {
      fetching.current += 1;
      let inviteType = 'Invite';
      if (!user.coupon_code) {
        if (user.role == ROLE_CONST.Learner) {
          const district = await callApi(`/api/db/district?did=${user?.district_id}`);
          if (['Google', 'Clever'].indexOf(district?.sso_provider) === -1) {
            if (district?.subscriptions?.find((s) => s.end_date > new Date().getTime())) {
              // has active subscription
              inviteType = 'InviteTeacherWithoutSSO';
            } else {
              const school = await callApi(`/api/db/school?sid=${user?.school_id}&did=${user?.district_id}`);
              if (school?.subscriptions?.find((s) => s.end_date > new Date().getTime())) {
                // has active subscription
                inviteType = 'InviteTeacherWithoutSSO';
              }
            }
          }
        }
        await callApi('/api/db/queue-email', 'POST', {
          type: inviteType,
          emails: user.emailAddress,
          params: [{ name: '!FIRST_NAME!', value: user.firstName }],
        });
      } else {
        // grab the current code just in case the exp_date has been extended.
        let currentCode = couponCodes.find((c) => c.code === user.coupon_code.code) ?? user.coupon_code;
        let resendBody = {
          type: 'InviteWithCouponCode',
          emails: user.emailAddress,
          params: [
            { name: '!COUPON_CODE!', value: currentCode.code },
            { name: '!EXP_DATE!', value: DateTime.fromJSDate(new Date(currentCode.exp_date)).toFormat('LL-dd-yyyy') },
            { name: '!OFFER!', value: CouponCodeOfferDesc(currentCode) },
            { name: '!FIRST_NAME!', value: user.firstName },
          ],
        };
        await callApi('/api/db/queue-email', 'POST', resendBody);
      }
      fetching.current -= 1;
    } catch (error) {
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const showEdit = (user) => {
    user.district = user.district_id && user.district_name ? { label: user.district_name, value: user.district_id } : null;
    user.school = user.school_id && user.school_name ? { label: user.school_name, value: user.school_id } : null;
    setAddEditState({ open: true, action: 'Update', editUser: user });
  };

  const extendTrial = (user) => {
    setCurrentUser(user);
    setOpenExtendTrial(true);
    setOpenExtendTrialMessage(`Are you sure you want to extend the trial for ${user.emailAddress}?`);
  };

  const doExtendTrial = async () => {
    try {
      let userToUpdate = JSON.parse(JSON.stringify(currentUser));
      userToUpdate.subscription.exp_date = DateTime.now().plus({ days: 30 }).toJSDate().getTime();
      fetching.current += 1;
      await callApi('/api/db/user', 'PUT', { user: userToUpdate });
      fetching.current -= 1;
      setOpenExtendTrial(false);
      await fetchUsers();
    } catch (error) {
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const showAdd = () => {
    setAddEditState({ open: true, action: 'Add', editUser: null });
  };

  const handleAddEditClose = async (doSave) => {
    setAddEditState({ ...addEditState, open: false });
    if (doSave) {
      // TODO refetch users or add/update list? - could be issues with sorting/filtering if we add to list.
      await fetchUsers();
    }
  };

  const handleMultipleClose = async (doSave) => {
    setMultipleOpen(false);
    if (doSave) {
      // TODO refetch users or add/update list? - could be issues with sorting/filtering if we add to list.
      await fetchUsers();
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const closeResendDialog = async (doSend) => {
    setResendDialogOpen(false);
    if (doSend) {
      await resendInvite(currentUser);
      setCurrentUser(null);
    }
  };

  const filterOnChange = async (data) => {
    filterOptions.current = data;
    await fetchUsers(paginationOptions.perPage);
  };

  const onSortChange = async (field, direction) => {
    if (sortOptions.current.field === field) {
      sortOptions.current.direction = sortOptions.current.direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortOptions.current.field = field;
      sortOptions.current.direction = direction ? direction : 'ASC';
    }
    await fetchUsers(paginationOptions.perPage, paginationOptions.page);
  };

  const getSortDirection = (field) => {
    return sortOptions.current.field === field ? sortOptions.current.direction : null;
  };

  const headerCols = [
    <SortableTableHeader
      key='emailAddress'
      field='emailAddress'
      text='Email'
      sortDirection={getSortDirection('emailAddress')}
      onSortChange={onSortChange}
    />,
    <SortableTableHeader key='lastName' field='lastName' text='Name' sortDirection={getSortDirection('lastName')} onSortChange={onSortChange} />,
    <SortableTableHeader
      key='district_name'
      field='district_name'
      text='District'
      sortDirection={getSortDirection('district_name')}
      onSortChange={onSortChange}
    />,
    <SortableTableHeader
      key='school_name'
      field='school_name'
      text='School'
      sortDirection={getSortDirection('school_name')}
      onSortChange={onSortChange}
    />,
    'Subscription(s)',
    <SortableTableHeader
      key='last_login'
      field='last_login'
      text='Last Login'
      sortDirection={getSortDirection('last_login')}
      onSortChange={onSortChange}
    />,
    '',
  ];

  const customFilterFields = () => {
    let fields = [];
    const user = session.user;
    if (user?.role === ROLE_CONST.BoysTownAdmin) {
      fields.push({ name: 'district_name', label: 'District' });
    }
    if (user?.role <= ROLE_CONST.DistrictAdmin) {
      fields.push({ name: 'school_name', label: 'School' });
    }
    fields.push({ name: 'emailAddress', label: 'Email' });
    return fields;
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={fetching.current > 0} />
      <YesNoDialog open={openExtendTrial} content={openExtendTrialMessage} yesClose={doExtendTrial} noClose={() => setOpenExtendTrial(false)} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <CustomFilter fields={customFilterFields()} onChange={filterOnChange} />
        {pageState.allRecords.map((r, i) => (
          <ManageUserCard
            key={r.id}
            user={r}
            editFunc={() => showEdit(r)}
            resendInviteFunc={() => {
              setCurrentUser(r);
              setResendDialogOpen(true);
            }}
            extendTrialFunc={() => extendTrial(r)}
          />
        ))}
        {paginationOptions.totalRecords > (paginationOptions.page + 1) * paginationOptions.perPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <KitButton size='sm' round color='secondary' onClick={() => fetchUsers(paginationOptions.perPage, paginationOptions.page + 1, true)}>
              Load More
            </KitButton>
          </Box>
        )}
      </Box>

      <Box sx={{ display: { sm: 'block', xs: 'none' }, marginLeft: '15px', marginRight: '15px', marginBottom: '15px' }}>
        <CustomFilter fields={customFilterFields()} onChange={filterOnChange} />
        <KitGridContainer direction='row' align='right'>
          <KitGridItem xs={12}>
            <KitButton
              style={{ marginRight: '10px', display: isBoysTownAdmin(session) ? 'inline-flex' : 'none' }}
              size='sm'
              round
              color='info'
              onClick={() => setTestEmailOpen(true)}
            >
              <AddCircleIcon /> Test Email Template
            </KitButton>
            <KitButton size='sm' round color='primary' onClick={showAdd}>
              <AddCircleIcon /> Add User
            </KitButton>
            <KitButton style={{ marginLeft: '10px' }} size='sm' round color='primary' onClick={() => setMultipleOpen(true)}>
              <AddCircleIcon /> Add User List
            </KitButton>
          </KitGridItem>
        </KitGridContainer>
        <KitGridContainer direction='row' align='center'>
          <KitGridItem xs={12}>
            <TableContainer>
              <KitTable striped hover tableData={pageState.tableRecords} tableHead={headerCols}></KitTable>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={paginationOptions.totalRecords}
                rowsPerPage={paginationOptions.perPage}
                page={paginationOptions.page}
                onPageChange={(ev, val) => {
                  fetchUsers(paginationOptions.perPage, val);
                }}
                onRowsPerPageChange={(ev) => {
                  const perPage = parseInt(ev.target.value, 10);
                  fetchUsers(perPage, 0);
                }}
                showFirstButton
                showLastButton
              />
            </TableContainer>
          </KitGridItem>
        </KitGridContainer>
      </Box>

      <AddEditUserDialog
        open={addEditState.open}
        onClose={handleAddEditClose}
        action={addEditState.action}
        user={session.user}
        stateList={states}
        editUser={addEditState.editUser}
        couponCodes={couponCodes}
      />
      <AddMultipleDialog open={multipleOpen} onClose={handleMultipleClose} stateList={states} user={session.user} couponCodes={couponCodes} />
      <SendTestEmail open={testEmailOpen} onClose={() => setTestEmailOpen(false)} couponCodes={couponCodes} />

      <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={resendDialogOpen} onClose={() => closeResendDialog(false)}>
        <DialogTitle>Confirm</DialogTitle>

        <DialogContent>Do you want to resend the invitation?</DialogContent>
        <DialogActions>
          <KitButton onClick={() => closeResendDialog(false)} round size='sm'>
            Cancel
          </KitButton>
          <KitButton onClick={() => closeResendDialog(true)} round size='sm' color='success' disabled={fetching.current > 0}>
            Resend
          </KitButton>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        scroll='paper'
        fullWidth={true}
        open={subDialogState.open}
        onClose={() => setSubDialogState({ open: false, subs: [], email: '' })}
      >
        <DialogTitle>{subDialogState.email} Subscription History</DialogTitle>

        <DialogContent>
          <Table sx={{ minWidth: 650 }} aria-label='billing history table'>
            <TableHead>
              <TableRow sx={{ border: 0 }}>
                <TH>Type</TH>
                <TH>Trans Date</TH>
                <TH>Exp Date</TH>
                <TH>Description</TH>
                <TH>Payment Method</TH>
                <TH>Amount</TH>
                <TH>Coupon Code</TH>
                <TH>Discount</TH>
              </TableRow>
            </TableHead>
            <TableBody>
              {subDialogState.subs &&
                subDialogState.subs.map((sub) => {
                  return (
                    <TableRow
                      key={sub.id}
                      sx={{ border: 0, backgroundColor: sub.end_date < Date.now() ? theme.palette.grayscale['100'] : 'inherit' }}
                    >
                      <TD>{sub.type}</TD>
                      <TD>
                        {sub.transaction?.dateTime ? DateTime.fromFormat(sub.transaction?.dateTime, 'M/d/yyyy h:mm:ss a').toFormat('MM/dd/yyyy') : ''}
                      </TD>
                      <TD>{formatDate(sub.end_date)}</TD>
                      <TD>{PRODUCTS.find((p) => p.id === sub.product)?.name ?? sub.product}</TD>
                      <TD>
                        {sub.transaction && sub.transaction?.accountType
                          ? `${sub.transaction?.accountType} ${sub.transaction?.accountNumber}`
                          : 'N/A'}
                      </TD>
                      <TD>{sub.transaction && sub.transaction?.totalAmount ? `$${sub.transaction?.totalAmount}` : 'N/A'}</TD>
                      <TD>{sub.coupon_code}</TD>
                      <TD>{`${sub.discount ? '$' + Number.parseFloat(sub.discount).toFixed(2) : ''}`}</TD>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <KitButton onClick={() => setSubDialogState({ open: false, subs: [], email: '' })} round color='info' size='sm'>
            Ok
          </KitButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const TH = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '12px',
  padding: '10px',
  border: 'none',
}));

const TD = styled(TableCell)(({ theme }) => ({
  border: 'none',
  padding: '10px',
}));

export default AdminManageUsers;
