import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { KitGridContainer, KitGridItem, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { PRODUCTS, COMMON_ROUTES } from 'services/constants';
import Heading6 from 'components/common/subcomponents/Typography/Heading6';
import { CalendarIcon, CardPurchaseIcon } from 'components/common/subcomponents/SvgIcons';

import { formatDate } from 'services/stringUtils';

import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { DateTime } from 'luxon';

const Subscriptions = (props) => {
  const { userRecord, schoolRecord, districtRecord, session, callout } = props;

  const fields = callout && callout.module.fields;

  const router = useRouter();
  const theme = useTheme();

  // BEGIN Renewal Callout Code
  const [showCallout, setShowCallout] = useState(false);
  const [productExpirationDate, setProductExpirationDate] = useState(null);

  useEffect(() => {
    if (session) {
      if (session.user) {
        if (session.user.subscriptions) {
          let shouldShowCallout = false;
          let exp = null;
          session.user.subscriptions.forEach((s) => {
            if (s.end_date < DateTime.now().plus({ days: 45 }).toJSDate().getTime() && s.product === fields.calloutProduct) {
              shouldShowCallout = true;
              exp = s.end_date;
            }
          });
          // is there already a renewed sub?
          session.user.subscriptions.forEach((s) => {
            if (shouldShowCallout && s.start_date >= exp) {
              shouldShowCallout = false;
              exp = null;
            }
          });

          setShowCallout(shouldShowCallout);
          setProductExpirationDate(exp);
        }
      }
    }
  }, [session]);
  // END Renewal Callout Code

  const sortSubs = (subs) => {
    return subs.sort((a, b) => {
      if (a.end_date < b.end_date) return 1;
      if (a.end_date > b.end_date) return -1;
      return 0;
    });
  };

  const individualSubs = () => {
    let subs = [];

    if (userRecord && userRecord.subscriptions) {
      const userSubs = sortSubs(userRecord.subscriptions);
      for (let i = 0; i < userSubs.length; i++) {
        let sub = userSubs[i];
        sub.is_expired = sub.end_date < new Date().getTime();
        if (sub.product !== 'free') {
          // if sub is expired, do not add it if there are active subs.
          if (sub.is_expired && subs.find((s) => s.product === sub.product && sub.is_expired) === undefined) {
            subs.push(sub);
          }
          if (!sub.is_expired) {
            subs.push(sub);
          }
        }
      }
    }

    return subs.map((s) => <SubCardBody type='Individual' sub={s} key={s.id} />);
  };

  const districtSubs = () => {
    let subs = [];

    if (districtRecord && districtRecord.subscriptions) {
      const disSubs = sortSubs(districtRecord.subscriptions);
      for (let i = 0; i < disSubs.length; i++) {
        let sub = disSubs[i];
        sub.is_expired = sub.end_date < new Date().getTime();
        // if sub is expired, do not add it if there are active subs.
        if (sub.is_expired && subs.find((s) => s.product === sub.product && sub.is_expired) === undefined) {
          subs.push(sub);
        }
        if (!sub.is_expired) {
          subs.push(sub);
        }
      }
    }

    return subs.map((s) => <SubCardBody type='Managed by your District' sub={s} key={s.id} />);
  };

  const schoolSubs = () => {
    let subs = [];

    if (schoolRecord && schoolRecord.subscriptions) {
      const schSubs = sortSubs(schoolRecord.subscriptions);
      for (let i = 0; i < schSubs.length; i++) {
        let sub = schSubs[i];
        sub.is_expired = sub.end_date < new Date().getTime();
        // if sub is expired, do not add it if there are active subs.
        if (sub.is_expired && subs.find((s) => s.product === sub.product && sub.is_expired) === undefined) {
          subs.push(sub);
        }
        if (!sub.is_expired) {
          subs.push(sub);
        }
      }
    }

    return subs.map((s) => <SubCardBody type='Managed by your School' sub={s} key={s.id} />);
  };

  const allSubs = individualSubs().concat(schoolSubs(), districtSubs());

  return (
    <KitGridContainer style={{ justifyContent: 'center' }}>
      {/* If showing renewal call out for individual subscription, display here (before the subscription cards). */}
      {session && showCallout && <RenewalCalloutCardBody fields={fields} router={router} productExpirationDate={productExpirationDate} />}
      {allSubs && allSubs.length > 0 && allSubs}
      {!allSubs ||
        (allSubs.length === 0 && (
          <Box>
            Looks like you do not have any subscriptions. <br />
            <StyledLink href={COMMON_ROUTES.PricingPage}>Checkout the options.</StyledLink>{' '}
          </Box>
        ))}
    </KitGridContainer>
  );
};

export default Subscriptions;

const StyledLink = styled('a')(({ theme }) => ({
  textDecoration: 'underline',
}));

// BEGIN Renewal Callout Styles
const Callout = styled('div')(({ theme }) => ({
  color: '#F2A900',
  fontWeight: 700,
  textTransform: 'uppercase',
  fontSize: '2.625rem',
  lineHeight: '2.625rem',
  marginTop: '5px',
}));

const Paragraph = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
}));

const ActionArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  width: '50%',
  marginTop: '30px',
}));
// END Renewal Callout Styles

const SubCardBody = (props) => {
  const { type, sub } = props;
  const theme = useTheme();
  return (
    <KitGridItem xs={12} lg={4} key={sub.id} style={{ maxWidth: '320px' }}>
      <KitCard style={{ minHeight: '375px', width: '310px' }}>
        <KitCardBody style={{ paddingRight: '22px', paddingLeft: '22px' }}>
          <Heading6>{PRODUCTS.find((p) => p.id === sub.product)?.name ?? sub.product}</Heading6>
          <Box sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>{type}</Box>
          <Box
            sx={{
              backgroundColor: theme.palette.brandedAqua.main,
              color: theme.palette.common.white,
              padding: '10px',
              mt: '20px',
              textTransform: 'uppercase',
            }}
          >
            <KitGridContainer>
              <KitGridItem xs={3}>
                <CalendarIcon fontSize='large' />
              </KitGridItem>
              <KitGridItem xs={9}>
                START DATE: <br />
                {formatDate(sub.start_date)}
                <br />
                EXPIRATION DATE: <br />
                {formatDate(sub.end_date)}
              </KitGridItem>
            </KitGridContainer>
          </Box>
          {type === 'Individual' && (
            <Box
              sx={{
                backgroundColor: theme.palette.brandedAqua.main,
                color: theme.palette.common.white,
                padding: '10px',
                mt: '20px',
                mb: '20px',
                textTransform: 'uppercase',
              }}
            >
              <KitGridContainer>
                <KitGridItem xs={3}>
                  <CardPurchaseIcon fontSize='large' />
                </KitGridItem>
                <KitGridItem xs={9}>
                  Amount Paid: <br />${sub.transaction?.totalAmount ?? '0.00'}
                  <br />
                  Payment Method: <br />
                  {sub.transaction?.accountType ?? ''}&nbsp;{sub.transaction?.accountNumber ?? 'N/A'}
                </KitGridItem>
              </KitGridContainer>
            </Box>
          )}
          {['Managed by your District', 'Managed by your School'].includes(type) && (
            <Box
              sx={{
                backgroundColor: theme.palette.brandedAqua.main,
                color: theme.palette.common.white,
                padding: '10px',
                mt: '20px',
                mb: '20px',
                textTransform: 'uppercase',
              }}
            >
              <KitGridContainer>
                <KitGridItem xs={3}>
                  <CardPurchaseIcon fontSize='large' />
                </KitGridItem>
                <KitGridItem xs={9}>
                  SEATS PURCHASED: <br />
                  {sub.num_seats ?? ''}
                </KitGridItem>
              </KitGridContainer>
            </Box>
          )}
          {/* <Box>Your subscription expires on {formatDate(sub.end_date)}.</Box> */}

          <Box>{sub.end_date < new Date().getTime() && `Your subscription expired on ${formatDate(sub.end_date)}.`}</Box>

          {type === 'Individual' && sub.end_date < new Date().getTime() && (
            <>
              <Box>
                <StyledLink href={`${COMMON_ROUTES.PricingPage}?p=${sub.product}`}>Renew your subscription.</StyledLink>
              </Box>
              {/* <Box>
                <StyledLink href={COMMON_ROUTES.PricingPage}>Make changes to your subscription.</StyledLink>
              </Box> */}
            </>
          )}
          {['Managed by your District', 'Managed by your School'].includes(type) && sub.end_date < new Date().getTime() && (
            <Box>Contact your administrator to renew.</Box>
          )}
        </KitCardBody>
      </KitCard>
    </KitGridItem>
  );
};

// BEGIN Renewal Callout
// Renewal Card to be displayed at the beginning of the subscription cards.
const RenewalCalloutCardBody = (props) => {
  const theme = useTheme();
  return (
    <KitCard
      style={{
        backgroundColor: 'theme.palette.common.white',
        height: '375px',
        width: '310px',
        marginRight: '-5px',
        backgroundImage: props.fields.backgroundImage && props.fields.backgroundImage.url && `url(${props.fields.backgroundImage.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 100%',
        backgroundSize: '30%',
      }}
    >
      <KitCardBody style={{ paddingRight: '22px', paddingLeft: '22px' }}>
        <Callout>{props.fields.calloutHeader}</Callout>
        <Paragraph className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(props.fields.calloutDescription1)} />
        <Paragraph className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(props.fields.calloutDescription2)} />

        <ActionArea>
          {props.fields.actionButtonUrl && props.fields.actionButtonUrl.text && props.fields.actionButtonUrl.text.length > 0 && (
            <KitButton
              round
              size='lg'
              style={{
                backgroundColor: theme.palette.primary.light,
                width: '185px',
              }}
              onClick={() =>
                props.router.push(
                  props.fields.actionButtonUrl.href +
                    '?product=' +
                    props.fields.calloutProduct +
                    '&plan=' +
                    props.fields.calloutPlanName +
                    '&price=' +
                    props.fields.calloutPlanPrice +
                    '&renewalDate=' +
                    props.productExpirationDate
                )
              }
            >
              {props.fields.actionButtonUrl.text}
            </KitButton>
          )}
        </ActionArea>
      </KitCardBody>
    </KitCard>
  );
};
// END Renewal Callout
