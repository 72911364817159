// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import { Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { BadRequestError } from 'services/apiWrapper';
import ObservationHeader from './components/ObservationHeader';
import ObservationPageContainer from './components/ObservationPageContainer';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';
import { useObservationEventInstanceList } from './hooks/ObservationEventHooks';
import EventHeaderDetail from './components/eventSummary/EventHeaderDetail';
import EventSummaryStats from './utils/EventSummaryStats';
import EventSummaryMetricCards from './components/eventSummary/EventSummaryMetricCards';
import { calcPercent } from 'services/numberUtils';
import PreventionBenchmarkGroup from './components/sessionSummary/PreventionBenchmarkGroup';
import PraiseBenchmarkGroup from './components/sessionSummary/PraiseBenchmarkGroup';
import CoorectionBenchmarkGroup from './components/sessionSummary/CorrectionBenchmarkGroup';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const ObservationEventSummary = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [event, setEvent] = useState(null);
  const [instanceList, setInstanceList] = useState([]);
  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);
  const eventInstanceListQuery = useObservationEventInstanceList(eventId);
  const isFetchingCount = useIsFetching();
  const eventStats = new EventSummaryStats(instanceList);

  useEffect(() => {
    if (eventInstanceListQuery.isSuccess && eventInstanceListQuery.isFetching === false) {
      setEvent(eventInstanceListQuery.data.event);
      setInstanceList(eventInstanceListQuery.data.instances);
    }
    if (eventInstanceListQuery.isError) {
      logClientException(eventInstanceListQuery.error);
      if (eventInstanceListQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (eventInstanceListQuery.error instanceof BadRequestError) {
        setErrorMessage(eventInstanceListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [eventInstanceListQuery]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const id = queryParams?.get('id');
      if (id && id?.length > 0) {
        setEventId(id);
      }
    }
  }, [queryParams?.get('id')]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (initialized && (!eventId || !event) && eventInstanceListQuery.isSuccess && eventInstanceListQuery.isFetching === false) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          paddingBottom: '3rem',
        }}
      >
        <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='small' />
        <ObservationPageContainer>
          <div>{fields?.noIdMessage || 'You appear to have reached this page in error.'}</div>
          <KitButton
            size='sm'
            style={{
              width: '100px',
            }}
            href={`${fields?.returnPage?.href}?r=1`}
          >
            <TurnLeftIcon />
            Back
          </KitButton>
        </ObservationPageContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='large'>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <EventHeaderDetail event={event} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <KitButton
              size='sm'
              style={{
                width: '160px',
              }}
              disabled={isFetchingCount > 0}
              href={`${fields?.returnPage?.href}?r=1`}
            >
              <TurnLeftIcon />
              {fields?.returnPage?.text}
            </KitButton>
            <KitButton
              size='sm'
              style={{
                width: '160px',
              }}
              disabled={isFetchingCount > 0 || eventInstanceListQuery.isFetching}
              target='_blank'
              href={`/api/db/observation/event-summary-download?id=${eventId}`}
            >
              <SaveAltIcon />
              Download
            </KitButton>
          </Box>
        </Box>
      </ObservationHeader>
      <ObservationPageContainer>
        <Box sx={{ fontSize: '24px', lineHeight: '30px', fontWeight: 600, color: theme.palette.obsBlue.main, mt: '.5rem', mb: '.5rem' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div style={{ marginRight: 10 }}>{eventStats.NumTeachers} Teachers Observed </div>
            <KitButton
              size='sm'
              style={{
                width: '150px',
              }}
              href={`session-list?id=${eventId}&p=summary`}
            >
              View Event Sessions
            </KitButton>
          </div>
          {/* <div>{calcPercent(eventStats.NumTeachersMeetingAllBenchmarks, eventStats.NumTeachers)}% of Observed Teachers Meeting Benchmarks</div> */}
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem', justifyContent: 'center' }}>
          <EventSummaryMetricCards eventSummaryMetrics={eventStats} />

          <Box sx={{ width: '100%', marginTop: '1.25rem' }}>
            <PreventionBenchmarkGroup observationMetrics={eventStats} />
            <PraiseBenchmarkGroup observationMetrics={eventStats} />
            <CoorectionBenchmarkGroup observationMetrics={eventStats} />
          </Box>
        </Box>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0} />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationEventSummary;
