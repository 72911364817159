import React, { useState, useEffect } from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import IconButton from '@mui/material/IconButton';
import { useSession } from 'next-auth/react';
import { hasModuleAccess } from '../../services/subscriptionUtils';
import { COMMON_ROUTES } from 'services/constants';
import { useTheme } from '@mui/material/styles';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const TrainingMethodSelection = ({ module }) => {
  const { fields } = module;

  const { data: session, status } = useSession();
  const theme = useTheme();

  const [showPage, setShowPage] = useState(true);

  // If session, check if has module details access.
  useEffect(() => {
    if (session && fields) {
      if (hasModuleAccess(session, fields.parentPage)) {
        setShowPage(false);
      } else {
        setShowPage(true);
      }
    }
  }, [session, fields]);

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      min-height: 600px;
      padding: 10px 0 80px 0;
    }
  `;

  const MobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 899px) {
      min-height: 600px;
      padding: 10px 0 25px 0;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    minheight: inherit;
    @media screen and (max-width: 899px) {
      margin: 0 20px 0 20px;
    }
    @media screen and (min-width: 900px) {
      border-bottom: 20px;
    }
  `;

  const OptionList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (min-width: 900px) {
      margin: 20px 0 0 0;
      gap: 0rem;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const OptionParent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content; center;
    align-items: center;

    @media screen and (min-width: 900px) {
      padding: 10px;
      width: 370px;
      height: 475px;
      &:hover {
        background-color: lightcyan;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 899px) {
      width: 95%;
      margin: 0 auto 0 auto;
      min-height: 350px;
      padding: 30px 15px;
    }
  `;

  const Option = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 325px;
  `;

  const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 140px;
  `;

  const OptionImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @media screen and (max-width: 899px) {
      width: 325px;
    }
    @media screen and (min-width: 900px) {
      width: 250px;
    }
  `;

  const OnDemandContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const OnDemandHeader = styled.div`
    color: #000000;
    border-top: 1px solid #a6b72f;
    text-align: center;
    max-width: 100%;
    font-weight: 600;
    @media screen and (min-width: 1001px) {
      padding: 8px 23px;
      margin: 5px 5px 10px 16px;
      font-size: 0.875rem;
      line-height: 1.225rem;
    }
    @media screen and (max-width: 1000px) {
      padding: 8px 16px;
      margin: 5px 0 10px 0;
      font-size: 0.775rem;
      line-height: 1.125rem;
      font-weight: 600;
    }
  `;

  function DisplayMobileList(props) {
    return (
      <OptionParent>
        <OptionImage>
          <img src={props.method.fields.trainingMethodIcon?.url} alt={props.method.fields.trainingMethodIcon?.text} style={{ maxWidth: '145px' }} />
        </OptionImage>
        <Option>
          <MethodTitle>{props.method.fields.title}</MethodTitle>
          <MethodDescription>{props.method.fields.description}</MethodDescription>
          <OnDemandContainer>
            {props.method.fields.showOnDemandText === 'true' && <OnDemandHeader>{props.method.fields.onDemandText}</OnDemandHeader>}
          </OnDemandContainer>
        </Option>
      </OptionParent>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      {showPage && (
        <>
          <Container>
            <TextContainer>
              <TitleBox>
                <Heading3 color='#000000' underline={true} uppercase={true}>
                  {fields.title}
                </Heading3>
              </TitleBox>
              {fields.trainingMethodList && (
                <>
                  <OptionList>
                    {fields.trainingMethodList &&
                      fields.trainingMethodList.length > 0 &&
                      fields.trainingMethodList
                        .sort((a, b) => {
                          if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                          if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                          return 0;
                        })
                        .map((method, index) => {
                          return (
                            <Link key={index} href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
                              <OptionParent>
                                <ImageContainer>
                                  <OptionImage>
                                    <img
                                      src={method.fields.trainingMethodIcon?.url}
                                      alt={method.fields.trainingMethodIcon?.text}
                                      style={{ maxWidth: '145px', padding: '25px' }}
                                    />
                                  </OptionImage>
                                </ImageContainer>
                                <Option>
                                  <MethodTitle>{method.fields.title}</MethodTitle>
                                  <MethodDescription>{method.fields.description}</MethodDescription>
                                  <OnDemandContainer>
                                    {method.fields.showOnDemandText === 'true' && <OnDemandHeader>{method.fields.onDemandText}</OnDemandHeader>}
                                  </OnDemandContainer>
                                </Option>
                              </OptionParent>
                            </Link>
                          );
                        })}
                  </OptionList>
                </>
              )}
              <div style={{ marginTop: 30 }}>
                <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
                  <KitButton
                    round
                    size='lg'
                    style={{
                      backgroundColor: theme.palette.primary.light,
                    }}
                  >
                    {fields.actionButtonText}
                  </KitButton>
                </Link>
              </div>
            </TextContainer>
          </Container>
          <MobileContainer>
            <TextContainer>
              <TitleBox>
                <Heading3 color='#000000' underline={true} uppercase={true}>
                  {fields.title}
                </Heading3>
              </TitleBox>
              {fields.trainingMethodList &&
                fields.trainingMethodList.length > 0 &&
                fields.trainingMethodList.map((method, index) => {
                  return <DisplayMobileList key={index} method={method} />;
                })}
              <div style={{ marginTop: 30 }}>
                <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
                  <KitButton
                    round
                    size='lg'
                    style={{
                      backgroundColor: theme.palette.primary.light,
                    }}
                  >
                    {fields.actionButtonText}
                  </KitButton>
                </Link>
              </div>
            </TextContainer>
          </MobileContainer>
        </>
      )}
    </Box>
  );
};

TrainingMethodSelection.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  const api = agility;

  let contentItem = null;

  try {
    let selection = await api.getContentList({
      referenceName: 'trainingMethodSelection',
      languageCode: languageCode,
      expandAllContentLinks: true,
      take: 1,
    });

    if (selection && selection.items && selection.items.length > 0) {
      contentItem = selection;
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not load Training Method Selection item.', error);
    return null;
  }

  return {
    fields: contentItem,
  };
};

export default TrainingMethodSelection;

const TitleBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '50px 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: '20px 0',
  },
}));

const MethodTitle = styled('div')(({ theme }) => ({
  fontSize: '1.65rem',
  color: theme.palette.common.black,
  fontWeight: 600,
  width: '100%',
  padding: '0 20%',
  margin: '15px 20px 10px 20px',
}));

const MethodDescription = styled('div')(({ theme }) => ({
  fontSize: '1.05rem',
  color: theme.palette.common.black,
  fontWeight: 300,
  alignSelf: 'flex-start',
  margin: '15px 10px 10px 10px',
}));
