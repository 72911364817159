import { useTheme } from '@emotion/react';
import hex2rgba from 'services/colorUtils/hex2rgba';

export function FreeformWarning({ title, message }) {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: '1.25rem 1.5rem',
        border: `1px bold ${theme.palette.gold.main}`,
        backgroundColor: hex2rgba(theme.palette.gold.main, 0.3),
        borderRadius: '.5rem',
        width: '100%',
        margin: '0 auto',
        maxWidth: '48rem',
      }}
    >
      <div style={{ fontWeight: 700, fontSize: '20px', lineHeight: '28px', color: theme.palette.grey[800] }}>{title}</div>
      <p>{message}</p>
    </div>
  );
}
