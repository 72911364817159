import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const Heading3 = (props) => {
  const TitleUnderline = styled.span`
    @media screen and (min-width: 992px) {
      display: inline-block;
      ${props.underline === false ? `border-bottom: none;` : `border-bottom: 2px dashed ${props.color || '#000'};`}
      ${props.uppercase === false ? 'text-transform: none;' : 'text-transform: uppercase;'}
      font-size: 42px;
      line-height: 55px;
      font-weight: 700;
    }
    @media screen and (max-width: 991px) {
      display: inline-block;
      ${props.underline === false ? `border-bottom: none;` : `border-bottom: 2px dashed ${props.color || '#000'};`}
      ${props.uppercase === false ? 'text-transform: none;' : 'text-transform: uppercase;'}
      ${props.underline === false ? `padding-bottom: 0;` : `padding-bottom: 10px;`}
      font-size: 29px;
      line-height: 36.45px;
      font-weight: 600;
    }
    @media screen and (max-width: 400px) {
      display: inline-block;
      ${props.underline === false ? `border-bottom: none;` : `border-bottom: 2px dashed ${props.color || '#000'};`}
      ${props.uppercase === false ? 'text-transform: none;' : 'text-transform: uppercase;'}
      ${props.underline === false ? `padding-bottom: 0;` : `padding-bottom: 10px;`}
      font-size: 29px;
      line-height: 34.5px;
      font-weight: 600;
    }
  `;

  const { color, children, underline, uppercase, ...rest } = props;
  return (
    <Typography {...rest} component='h3' color={color} textAlign='center'>
      <TitleUnderline>{children}</TitleUnderline>
    </Typography>
  );
};

export default Heading3;
