import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';

const LongNoteDialog = (props) => {
  const theme = useTheme();

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClick={() => props.onClose()}>
      <DialogContent>{props.note}</DialogContent>
    </Dialog>
  );
};

LongNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  note: PropTypes.string.isRequired,
};
export default LongNoteDialog;
