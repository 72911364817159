import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const BannerSimple = (props) => {
  const { title, titleTextColor, bannerBackgroundColor } = props.module.fields;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '60px 10px 40px 10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', backgroundColor: bannerBackgroundColor, width: 1180 }}>
        <Box sx={{}}>
          <Typography color={titleTextColor} fontSize='50px' fontWeight='700' lineHeight='54px'>
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerSimple;
