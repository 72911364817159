import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import truncate from 'truncate-html';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import Carousel from 'react-material-ui-carousel';
import { useTheme } from '@mui/material/styles';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const FreeResources = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const router = useRouter();

  if (fields.resourceList.length <= 0) {
    return <NoResourcesContainer></NoResourcesContainer>;
  }

  const Container = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  `;

  const MobileContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      width: 95%;
      padding: 0 0 20px 0;
    }
  `;

  const CarouselContainer = styled.div`
    cursor: pointer;
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      margin: 10px 30px 30px 30px;
      width: 22rem;
    }
  `;

  function DisplayResourceInCarousel(props) {
    return (
      <Link href={props.resource.fields.actionButtonLink.href} key={props.key} legacyBehavior>
        <a>
          <KitCard
            style={{
              width: '22rem',
              height: '650px',
              margin: '0px 0 30px 0',
            }}
          >
            <React.Fragment key={props.resource.contentID}>
              <ResourceFrame>
                {props.resource.fields.resourceImage && (
                  <ResourceImg alt={props.resource.fields.resourceImage.label} src={props.resource.fields.resourceImage.url} />
                )}
              </ResourceFrame>
              <KitCardBody style={{ margin: 0, position: 'relative' }}>
                <ResourceType>{props.resource.fields.resourceType}</ResourceType>
                <ResourceName>{props.resource.fields.resourceName}</ResourceName>
                <Description>
                  {truncate(props.resource.fields.resourceDescription, {
                    length: 160,
                    decodeEntities: true,
                    stripTags: true,
                    reserveLastWord: true,
                  })}
                </Description>
                <ActionButtonContainer>
                  {props.resource.fields.actionButtonLink && props.resource.fields.actionButtonLink.href.length > 0 && (
                    <Link href={props.resource.fields.actionButtonLink.href} target={props.resource.fields.target} passHref legacyBehavior>
                      <KitButton color={props.resource.fields.actionButtonColor} round style={{ opacity: 1.0 }}>
                        <a rel='noopener noreferrer'>{props.resource.fields.actionButtonLink.text}</a>
                      </KitButton>
                    </Link>
                  )}
                </ActionButtonContainer>
              </KitCardBody>
            </React.Fragment>
          </KitCard>
        </a>
      </Link>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
      }}
    >
      <Frame>
        <TitleSpacing>
          <Heading3 color={fields.textColor} underline={true} uppercase={true}>
            {fields.title}
          </Heading3>
        </TitleSpacing>
        <Introduction>{fields.description}</Introduction>
        <>
          <Container>
            <CardGrid>
              {fields.resourceList
                .sort((a, b) => {
                  if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                  if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                  return 0;
                })
                .map((resource, index) => (
                  <Link href={resource.fields.actionButtonLink.href} key={index} legacyBehavior>
                    <a>
                      <KitCard
                        style={{
                          width: '22rem',
                          height: '700px',
                          borderRadius: 0,
                        }}
                      >
                        <React.Fragment>
                          <ResourceFrame>
                            {resource.fields.resourceImage && (
                              <ResourceImg alt={resource.fields.resourceImage.label} src={resource.fields.resourceImage.url} />
                            )}
                          </ResourceFrame>
                          <KitCardBody style={{ margin: 0, position: 'relative' }}>
                            <ResourceType>{resource.fields.resourceType}</ResourceType>
                            <ResourceName>{resource.fields.resourceName}</ResourceName>
                            <Description>
                              {truncate(resource.fields.resourceDescription, {
                                length: 200,
                                decodeEntities: true,
                                stripTags: true,
                                reserveLastWord: true,
                              })}
                            </Description>
                            <ActionButtonContainer>
                              {resource.fields.actionButtonLink && resource.fields.actionButtonLink.href.length > 0 && (
                                <Link href={resource.fields.actionButtonLink.href} target={resource.fields.target} passHref legacyBehavior>
                                  <KitButton color={resource.fields.actionButtonColor} round style={{ opacity: 1.0 }}>
                                    {resource.fields.actionButtonLink.text}
                                  </KitButton>
                                </Link>
                              )}
                            </ActionButtonContainer>
                          </KitCardBody>
                        </React.Fragment>
                      </KitCard>
                    </a>
                  </Link>
                ))}
            </CardGrid>
            <Link href='/free-resources' passHref legacyBehavior>
              <KitButton color='primary' round style={{ backgroundColor: theme.palette.primary.light, opacity: 1.0, marginBottom: '50px' }}>
                More Free Resources
              </KitButton>
            </Link>
          </Container>
          <MobileContainer>
            <CarouselContainer>
              <Carousel>
                {fields.resourceList
                  .sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((resource, index) => (
                    <DisplayResourceInCarousel key={index} resource={resource} />
                  ))}
              </Carousel>
            </CarouselContainer>
            <Link href='/free-resources' passHref legacyBehavior>
              <KitButton
                round
                style={{
                  backgroundColor: theme.palette.primary.light,
                  opacity: 1.0,
                  marginBottom: '50px',
                }}
              >
                More Free Resources
              </KitButton>
            </Link>
          </MobileContainer>
        </>
      </Frame>
    </Box>
  );
};

export default FreeResources;

const NoResourcesContainer = styled('div')(({ theme }) => ({
  minHeight: 50,
}));

const Frame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TitleSpacing = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '25px 15px',
    padding: '20px 0 5px 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
    padding: '40px 0 2px 0',
  },
}));

const Introduction = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.00rem',
  lineHeight: '1.30rem',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    paddingTop: '20px',
  },
}));

const CardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '2.00em',
  [theme.breakpoints.up('md')]: {
    margin: '10px 0 0 0',
    padding: '10px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ResourceFrame = styled('div')(({ theme }) => ({
  border: '20px solid #FFF',
  borderRadius: '8px',
}));

const ResourceImg = styled('img')(({ theme }) => ({
  borderRadius: '6px',
  display: 'block',
  width: '100%',
  height: '100%',
}));

const ResourceType = styled('div')(({ theme }) => ({
  color: theme.palette.primary.light,
  fontWeight: 600,
  textTransform: 'uppercase',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.10rem',
    lineHeight: '1.25rem',
    maxWidth: '80%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.00rem',
    maxWidth: '95%',
  },
}));

const ResourceName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 600,
  textTransform: 'uppercase',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
    lineHeight: '1.50rem',
    margin: '10px 0',
    maxWidth: '80%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.10rem',
    lineHeight: '1.30rem',
    margin: '15px 0',
    maxWidth: '95%',
  },
}));

const Description = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 300,
  fontSize: '1.00rem',
  lineHeight: '1.35rem',
  height: '40%',
}));

const ActionButtonContainer = styled('div')(({ theme }) => ({
  margin: '5% -1.875rem',
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));
