import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import ConfirmEndDialog from './ConfirmEndDialog';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';

const TimerPausedDialog = (props) => {
  const theme = useTheme();
  const [showConfirmEnd, setShowConfirmEnd] = useState(false);

  return (
    <>
      <Dialog maxWidth='sm' scroll='body' fullWidth={true} open={props.open} PaperComponent={DraggablePaperComponent}>
        <DialogTitle sx={{ backgroundColor: theme.palette.obsTimer.dark, color: theme.palette.common.black, padding: '8px 16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Timer Paused</Box>
        </DialogTitle>

        <DialogContent sx={{ marginTop: '1rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem' }}>The timer has been paused.</Box>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Tooltip title='Click to resume timer.' placement='top'>
            <PlayCircleIcon
              className='need-interaction'
              onClick={() => props.resume()}
              style={{ fontSize: '54px', color: theme.palette.obsBlue.dark }}
            />
          </Tooltip>
          <Tooltip title='Click to end observation.' placement='top'>
            <KitButton
              className='need-interaction'
              size='sm'
              style={{
                backgroundColor: buttonColor(theme, 'obsBlue.dark'),
                height: '45px',
                width: '50px',
              }}
              onClick={() => {
                setShowConfirmEnd(true);
              }}
            >
              End
            </KitButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <ConfirmEndDialog open={showConfirmEnd} closeConfirm={() => setShowConfirmEnd(false)} endObservation={() => props.endObservation()} />
    </>
  );
};

TimerPausedDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  resume: PropTypes.func,
  endObservation: PropTypes.func,
};
export default TimerPausedDialog;
