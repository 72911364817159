import { ObservationInstance, timelineObservation } from 'types/ObservationTypes';

class ObservationStats {
  private observation: ObservationInstance;

  public observationMinutes: number = 0;
  public preventions: Array<timelineObservation> = [];
  public praises: Array<timelineObservation> = [];
  public corrections: Array<timelineObservation> = [];

  // prevention stats
  public preventativePrompts: number = 0;
  public plannedTeachings: number = 0;
  public blendedTeachings: number = 0;
  public preventionSkillLanguages: number = 0;
  public preventionSpecificity: number = 0;
  public preventionMO: number = 0;

  // praise stats
  public generalPraises: number = 0;
  public specificPraises: number = 0;
  public effectivePraises: number = 0;
  public academicPraises: number = 0;
  public behaviorPraises: number = 0;
  public praiseSkillLanguages: number = 0;
  public praiseSpecificity: number = 0;
  public praiseMO: number = 0;

  // correction stats
  public correctivePrompts: number = 0;
  public correctiveStrategies: number = 0;
  public correctiveGuidedSelfCorrections: number = 0;
  public correctiveTeaching: number = 0;
  public correctiveCorrected: number = 0;
  public correctiveNotCorrected: number = 0;
  public correctiveSkillLanguages: number = 0;
  public correctiveSpecificity: number = 0;
  public correctiveMO: number = 0;

  public onTask: string | 100 = '';
  public cooperationToCorrection: string | 0 = '';
  public praiseStatementsPerMin: string | 0 = '';
  public relationShipBuildCount: number = 0;
  public preventativeStrat: string | 0 = '';
  public praiseToCorrection: number[] = [0, 0];
  public physEnvCountCount: number = 0;
  public totalPhysEnvCount: number = 0;

  public onTaskBenchmarkMet: boolean = false;
  public cooperationToCoorectionBenchmarkMet: boolean = false;
  public praiseStatementsPerMinBenchmarkMet: boolean = false;
  public relationShipBuildCountBenchmarkMet: boolean = false;
  public preventativeStratBenchmarkMet: boolean = false;
  public praiseToCorrectionBenchmarkMet: boolean = false;
  public physEnvCountCountBenchmarkMet: boolean = false;

  constructor(observation: ObservationInstance) {
    if (observation) {
      this.observation = observation;

      this.totalPhysEnvCount = observation.classroom_type === 'Well-Managed Schools' ? 7 : 9;

      this.observationMinutes = this.observationMinutesCalc();
      this.preventions = observation.observations?.filter((item) => item.type === 'Prevention' && !item.tags.find((t) => t === 'MO')) || [];
      this.praises = observation.observations?.filter((item) => item.type === 'Praise' && !item.tags.find((t) => t === 'MO')) || [];
      this.corrections = observation.observations?.filter((item) => item.type === 'Correction' && !item.tags.find((t) => t === 'MO')) || [];

      this.preventativePrompts = this.preventions.filter((item) => item.tags.indexOf('PP') > -1)?.length || 0;
      this.plannedTeachings = this.preventions.filter((item) => item.tags.indexOf('PT') > -1)?.length || 0;
      this.blendedTeachings = this.preventions.filter((item) => item.tags.indexOf('BT') > -1)?.length || 0;
      this.preventionSkillLanguages = this.preventions.filter((item) => item.tags.indexOf('SL') > -1)?.length || 0;
      this.preventionSpecificity = this.preventions.filter((item) => item.tags.indexOf('S') > -1)?.length || 0;
      this.preventionMO = observation.observations?.filter((item) => item.type === 'Prevention' && item.tags.find((t) => t === 'MO'))?.length || 0;

      this.generalPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.startsWith('GP'))?.length > 0)?.length || 0;
      this.specificPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.startsWith('SP'))?.length > 0)?.length || 0;
      this.effectivePraises = this.praises.filter((item) => item.tags.filter((tag) => tag.startsWith('EP'))?.length > 0)?.length || 0;
      this.academicPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.endsWith('-A'))?.length > 0)?.length || 0;
      this.behaviorPraises = this.praises.filter((item) => item.tags.filter((tag) => tag.endsWith('-B'))?.length > 0)?.length || 0;
      this.praiseSkillLanguages = this.praises.filter((item) => item.tags.indexOf('SL') > -1)?.length || 0;
      this.praiseSpecificity = this.praises.filter((item) => item.tags.indexOf('S') > -1)?.length || 0;
      this.praiseMO = observation.observations?.filter((item) => item.type === 'Praise' && item.tags.find((t) => t === 'MO'))?.length || 0;

      this.correctivePrompts = this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('CP'))?.length > 0)?.length || 0;
      this.correctiveStrategies = this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('CS'))?.length > 0)?.length || 0;
      this.correctiveGuidedSelfCorrections =
        this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('GSC') || tag.startsWith('GCS'))?.length > 0)?.length || 0;
      this.correctiveTeaching = this.corrections.filter((item) => item.tags.filter((tag) => tag.startsWith('CT'))?.length > 0)?.length || 0;
      this.correctiveCorrected = this.corrections.filter((item) => item.tags.filter((tag) => tag.endsWith('-BC'))?.length > 0)?.length || 0;
      this.correctiveNotCorrected = this.corrections.filter((item) => item.tags.filter((tag) => tag.endsWith('-NC'))?.length > 0)?.length || 0;
      this.correctiveSkillLanguages = this.corrections.filter((item) => item.tags.indexOf('SL') > -1)?.length || 0;
      this.correctiveSpecificity = this.corrections.filter((item) => item.tags.indexOf('S') > -1)?.length || 0;
      this.correctiveMO = observation.observations?.filter((item) => item.type === 'Correction' && item.tags.find((t) => t === 'MO'))?.length || 0;

      this.onTask = this.calcOnTask();
      this.cooperationToCorrection = this.calcCooperationToCorrection();
      this.praiseStatementsPerMin = this.calcPraiseStatements();
      this.relationShipBuildCount = this.relationShipBuildCountCalc();
      this.physEnvCountCount = this.physEnvCountCalc();
      this.preventativeStrat = this.calcPreventativeStrat();
      this.praiseToCorrection = this.calcPraiseToCorrection();

      this.cooperationToCoorectionBenchmarkMet = +this.cooperationToCorrection >= 75;
      this.praiseStatementsPerMinBenchmarkMet = +this.praiseStatementsPerMin >= 1;
      this.relationShipBuildCountBenchmarkMet = this.relationShipBuildCount >= 3;
      this.preventativeStratBenchmarkMet = this.preventions.length >= this.calcPreventativeStratBenchmarkThreshold();
      this.praiseToCorrectionBenchmarkMet = this.praiseToCorrectionBenchmarkMetCalc();
      this.physEnvCountCountBenchmarkMet = this.physEnvCountCount >= 5;
      this.onTaskBenchmarkMet = +this.onTask > 90.5;
    }
  }

  observationMinutesCalc = () => {
    let elapsedMS = 0;
    if (this.observation.timer) {
      for (let i = 0; i < this.observation.timer.length; i++) {
        if (this.observation.timer[i].timer_end === 0) {
          elapsedMS += Date.now() - this.observation.timer[i].timer_start;
        } else {
          elapsedMS += this.observation.timer[i].timer_end - this.observation.timer[i].timer_start;
        }
      }
    }
    const elapsedSeconds = elapsedMS / 1000;
    // return a decimal number of minutes
    return elapsedSeconds / 60;
  };

  calcOnTask = () => {
    const offTask =
      this.observation.observations?.filter(
        (item) => item.type === 'Off Task' && item.count !== null && item.count !== undefined && item.count > -1
      ) || [];
    const offTaskCount = offTask.reduce((acc, item) => acc + (item?.count ?? 0), 0);
    const onTaskTotalStudents = offTask.reduce((acc, item) => acc + (item?.number_of_students ? +item.number_of_students : 0), 0);
    if (onTaskTotalStudents === 0) return 100;
    return (((onTaskTotalStudents - offTaskCount) / onTaskTotalStudents) * 100).toFixed(0);
  };

  calcCooperationToCorrection = () => {
    const bcEntries = this.corrections.filter((item) => item.tags.find((t) => t.indexOf('BC') > -1));
    if (this.corrections.length === 0) return 0;
    return ((bcEntries.length / this.corrections.length) * 100).toFixed(1);
  };

  calcPraiseStatements = () => {
    const minutes = Math.round(this.observationMinutes);
    if (minutes === 0) return 0;
    return (this.praises.length / minutes).toFixed(1);
  };

  relationShipBuildCountCalc = () => {
    let count = 0;
    if (this.observation.atsmosphere) count++;
    if (this.observation.solicits) count++;
    if (this.observation.positive_comments) count++;
    if (this.observation.quality_components) count++;
    return count;
  };

  physEnvCountCalc = () => {
    let count = 0;
    if (this.observation.skills_posters) count++;
    if (this.observation.classroom_rules) count++;
    if (this.observation.schoolwide_rules) count++;
    if (this.observation.classroom_procedures) count++;
    if (this.observation.schoolwide_procedures) count++;
    if (this.observation.consequences) count++;
    if (this.observation.daily_schedule) count++;
    if (this.observation.cards_on_desk) count++;
    if (this.observation.students_write_cards) count++;
    return count;
  };

  calcPreventativeStratBenchmarkThreshold = () => {
    // 1. Round down the minute in the observation length (e.g. 10.8 minutes would still count as 10 minutes).
    const minutes = Math.floor(this.observationMinutes);
    if (minutes === 0) return 1; // minimum of 1
    // 2. To get the benchmark, divide #1 by 2.5 and round to the nearest integer (e.g.: 19 minutes would have a benchmark of 8)
    const temp = Math.round(minutes / 2.5);
    return temp === 0 ? 1 : temp; // minimum of 1
  };

  calcPreventativeStrat = () => {
    return (this.preventions.length / this.calcPreventativeStratBenchmarkThreshold()).toFixed(2);
  };

  static reduceFraction = (numerator, denominator) => {
    const gcd = (a, b) => (b ? gcd(b, a % b) : a);
    const commonDivisor = gcd(numerator, denominator);
    return [numerator / commonDivisor, denominator / commonDivisor];
  };

  calcPraiseToCorrection = () => {
    if (this.corrections.length === 0) return [this.praises.length, 0];
    return ObservationStats.reduceFraction(this.praises.length, this.corrections.length);
  };

  praiseToCorrectionBenchmarkMetCalc = () => {
    if (this.corrections.length === 0 && this.praises.length >= 4) {
      return true;
    } else if (this.corrections.length === 0 && this.praises.length < 4) {
      return false;
    }

    return this.praiseToCorrection[0] / this.praiseToCorrection[1] >= 4;
  };
}

export default ObservationStats;
