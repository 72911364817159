import { logClientException } from 'appinsights/clientAppInsights';
import { callApi } from './apiWrapper';

/**
 * Tracks number of free resources used/remaining
 * @param {string} url - the url of the resource
 * @returns {number} - number of free resources remaining
 */
export const gatedResourceCheck = (url) => {
  const MAX_FREE = 3;

  if (typeof window === 'undefined') {
    return;
  }

  let frList = JSON.parse(window.localStorage.getItem('frl'));
  if (!frList || !Array.isArray(frList)) {
    frList = [url];
    window.localStorage.setItem('frl', JSON.stringify(frList));
    return MAX_FREE - frList.length;
  } else if (frList.findIndex((f) => f === url) > -1) {
    // already has this url
    return MAX_FREE - frList.length;
  } else {
    frList.push(url);
    window.localStorage.setItem('frl', JSON.stringify(frList));
    return MAX_FREE - frList.length;
  }
};

/**
 * Log resource access
 * @param {string} resource_type
 * @param {string} resource_name
 * @param {string} resource_addr
 */
export const logResourceAccess = async (resource_type, resource_name, resource_addr, event_name = 'free_resource') => {
  try {
    await callApi('/api/db/report-event', 'POST', {
      resource_type,
      resource_name,
      resource_addr,
      event_name,
    });
  } catch (error) {
    logClientException(error);
  }
};
