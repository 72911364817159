import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTheme } from '@mui/material/styles';
import { renderHTML } from '@agility/nextjs';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CloseIcon from '@mui/icons-material/Close';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import { AppBar, Toolbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useSession, signIn } from 'next-auth/react';
import CheckIcon from '@mui/icons-material/Check';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { KitButton, KitCard, KitCardBody, KitCardFooter, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import { logClientMessage, logClientException, logServerException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import { hasModuleAccess } from '../../services/subscriptionUtils';
import { CircleCheckIcon, CircleMoreIcon, CircleOutlineIcon } from '../common/CustomIcons';
import LayoutContainer from '../common/LayoutContainer';
import { maxHeight } from '@mui/system';
import { COMMON_ROUTES } from 'services/constants';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { getSessionStoreString } from 'services/storageUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const NTRWMSModuleDetail = (props) => {
  const { customData } = props;

  const theme = useTheme();
  const router = useRouter();

  const [canAccessModules, setCanAccessModules] = useState(false);
  const [subModules, setSubModules] = useState();
  const [parent, setParent] = useState();
  const [subModulesSet, setSubModulesSet] = useState(false);
  const [viewCourseOpen, setViewCourseOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const currentCourseRef = useRef(null);
  const [seriesComplete, setSeriesComplete] = useState(0);
  const { data: session, status } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // workaround hash issue https://github.com/vercel/next.js/issues/11109
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  }, []);

  useEffect(() => {
    setSubModules(customData?.subModules);
    setParent(customData?.parentSeries);
    setSubModulesSet(true);
    //console.log('parentSeries: ', customData?.parentSeries);
    if (typeof document !== 'undefined') {
      const docVisChange = () => {
        console.log('visibilityState', document.visibilityState);
        if (document.visibilityState === 'hidden') {
          sendXapiQueueItem();
        }
      };
      document.addEventListener('visibilitychange', docVisChange);
      return () => {
        document.removeEventListener('visibilitychange', docVisChange);
      };
    }
  }, []);

  useEffect(() => {
    setCanAccessModules(hasModuleAccess(session, 'wms'));
  }, [session]);

  useEffect(() => {
    if (subModulesSet && canAccessModules) {
      fetchUserRegistrations();
    }
  }, [session?.user?.email, subModulesSet, canAccessModules]);

  const getFetchOptions = (method, body) => {
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  };

  const fetchUserRegistrations = async (isOnClose = false) => {
    try {
      if (!session?.user?.email) {
        return;
      }
      setIsLoading(true);
      const body = {
        email: session?.user?.email,
        tags: subModules[0]?.fields?.parentModuleTags?.split(','),
      };
      const res = await fetch('/api/scorm/fetch-registrations', getFetchOptions('POST', body));
      if (res.ok && res.status === 200) {
        const bodyJson = await res.json();
        const regs = bodyJson.registrations;
        let more = bodyJson.more;

        while (more && more.length > 0) {
          const newBody = {
            email: session?.user?.email,
            more: more,
          };
          const resmore = await fetch('/api/scorm/fetch-registrations', getFetchOptions('POST', newBody));
          if (resmore.ok && resmore.status === 200) {
            const morebodyJson = await resmore.json();
            const moreregs = morebodyJson.registrations;

            moreregs.forEach((morereg, idx) => {
              regs.push(morereg);
            });

            const moreloop = morebodyJson.more;
            more = moreloop;
          }
        }

        const newItems = subModules?.map((sub) => {
          let regList = regs.filter((r) => r.course.id === sub.fields?.scormCourse?.id) || [];
          regList = regList.sort((a, b) => {
            // sort desc by instance so we can get registration - logic to match GatedWMSModuleListing
            if (!a.instance) {
              a.instance = 0;
            }
            if (!b.instance) {
              b.instance = 0;
            }
            if (a.instance > b.instance) return -1;
            if (a.instance < b.instance) return 1;
            return 0;
          });
          const reg = regList[0];
          if (reg) {
            if (isOnClose && reg.registrationCompletion === 'COMPLETED' && sub.registrationCompletion !== reg.registrationCompletion) {
              // just completed sub, send event
              addDataLayerEvent({
                'event': 'module_complete',
                'course_name': parent.fields.moduleName,
                'course_id': parent.contentID,
                'module_number': sub.fields.moduleNumber,
                'module_name': sub.fields.subModuleName,
                'module_id': sub.contentID,
              });
            }
            sub.registrationId = reg.id;
            sub.registrationCompletion = reg.registrationCompletion;
            sub.registrationSuccess = reg.registrationSuccess;
            sub.totalSecondsTracked = reg.totalSecondsTracked;
            sub.registrationCompletionAmount = reg.registrationCompletionAmount;
            sub.firstAccessDate = reg.firstAccessDate;
            sub.lastAccessDate = reg.lastAccessDate;
            sub.completionDate = reg.conmpletionDate;
            sub.score = reg.score;
            sub.activityDetails = reg.activityDetails;
          }
          return sub;
        });

        // Calculate Course Completion
        let totalCourses = 0;
        let totalComplete = 0;
        newItems.forEach((s, i) => {
          totalCourses += 1;
          if (s.registrationCompletion === 'COMPLETED' && (s.registrationSuccess === 'PASSED' || s.registrationSuccess === 'UNKNOWN')) {
            totalComplete += 1;
          }
        });
        if (isOnClose && totalComplete === totalCourses) {
          addDataLayerEvent({
            'event': 'course_complete',
            'course_name': parent.fields.moduleName,
            'course_id': parent.contentID,
          });
        }
        setSubModules(newItems);
        setSeriesComplete(((totalComplete / totalCourses) * 100).toFixed(0));
      } else {
        throw new Error(`fetUserRegistrations failed ${res.status}`);
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      console.log(error);
      logClientException(error);
    }
  };

  const handlePlaySubmodule = async (submod) => {
    try {
      setIsLoading(true);
      const body = {
        courseId: submod.fields.scormCourse.id,
        registrationId: submod.registrationId,
        title: submod.fields.scormCourse.title,
        product: 'wms',
        tags: submod.fields.parentModuleTags?.split(','),
      };
      if (getSessionStoreString('utm_source')) {
        body.utm_source = getSessionStoreString('utm_source');
      }
      if (getSessionStoreString('utm_medium')) {
        body.utm_medium = getSessionStoreString('utm_medium');
      }
      if (getSessionStoreString('utm_campaign')) {
        body.utm_campaign = getSessionStoreString('utm_campaign');
      }
      if (getSessionStoreString('utm_content')) {
        body.utm_content = getSessionStoreString('utm_content');
      }
      const res = await fetch('/api/scorm/register-build-link', getFetchOptions('POST', body));
      if (res.ok && res.status === 200) {
        const bodyJson = await res.json();
        const launchLink = bodyJson.launchLink;
        const registrationId = bodyJson.registrationId;
        const newItems = subModules.map((sub) => {
          if (sub.contentID === submod.contentID) {
            sub.launchLink = launchLink;
            sub.registrationId = registrationId;
          }
          return sub;
        });
        // if starting first module in series, send course start event
        let firstMod = subModules.find((s) => s.hasOwnProperty('registrationCompletion')) === undefined;
        if (firstMod) {
          addDataLayerEvent({
            'event': 'course_start',
            'course_name': parent.fields.moduleName,
            'course_id': parent.contentID,
          });
        }
        // module start event
        addDataLayerEvent({
          'event': 'module_start',
          'course_name': parent.fields.moduleName,
          'course_id': parent.contentID,
          'module_number': submod.fields.moduleNumber,
          'module_name': submod.fields.subModuleName,
          'module_id': submod.contentID,
        });
        setSubModules(newItems);
        setCurrentCourse({ launchLink: launchLink, name: submod.fields?.subModuleName });
        currentCourseRef.current = submod;
        setViewCourseOpen(true);
      } else {
        throw new Error(`registerLearner failed ${res.status}`);
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      console.log(error);
      logClientException(error);
    }
  };

  const handleCloseCourse = async () => {
    sendXapiQueueItem();
    await fetchUserRegistrations(true);
    setViewCourseOpen(false);
    setCurrentCourse(null);
    currentCourseRef.current = null;
  };

  const sendXapiQueueItem = () => {
    if (currentCourseRef.current) {
      const submod = currentCourseRef.current;
      if (submod) {
        let url = `/api/scorm/xapi-queue?rid=${submod.registrationId}&cid=${submod.fields.scormCourse.id}&sid=${session?.user?.school_id}&sn=${session?.user?.school_name}&did=${session?.user?.district_id}&dn=${session?.user?.district_name}`;
        navigator.sendBeacon(url);
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const getModuleLabel = (specialLabel, mod) => {
    if (specialLabel && specialLabel.length > 0) {
      return <span id={specialLabel}>{specialLabel}</span>;
    } else {
      return <span id={'mod_' + (mod + 1)}>{'Module ' + (mod + 1)}</span>;
    }
  };

  const getModuleCard = (s, i, totalSubs) => {
    let courseState = !canAccessModules ? 'Register to view' : 'Watch';
    if (s.registrationCompletion === 'COMPLETED' && (s.registrationSuccess === 'PASSED' || s.registrationSuccess === 'UNKNOWN')) {
      courseState = 'Watch Again';
    } else if (s.registrationCompletion === 'COMPLETED' && s.registrationSuccess === 'FAILED') {
      courseState = 'Continue';
    } else if (s.registrationCompletion === 'INCOMPLETE') {
      courseState = 'Continue';
    }

    let secondaryAction = <></>;
    let headerIcon = <span></span>;
    if (courseState === 'Register to view') {
      const text = !session?.user?.email ? 'Sign in to view modules' : 'View Plans';
      secondaryAction = (
        <KitButton
          aria-label={courseState}
          color='success'
          round
          size='sm'
          style={{ width: text === 'View Plans' ? '140px' : '200px' }}
          onClick={() => {
            // if logged in
            if (text === 'View Plans') {
              router.push(COMMON_ROUTES.PricingPage);
            } else {
              // redirect to sign in with redirect flag
              router.push(`${COMMON_ROUTES.SignIn}?redirect=${encodeURIComponent(router.asPath)}`);
            }
          }}
          title={text}
        >
          {text}
        </KitButton>
      );
    } else if (s?.fields?.scormCourse?.id) {
      secondaryAction = (
        <KitButton
          aria-label={courseState}
          round
          size='sm'
          onClick={() => handlePlaySubmodule(s)}
          title='Play'
          style={{ backgroundColor: theme.palette.brandedAqua.main }}
        >
          <PlayCircleIcon /> {courseState}
        </KitButton>
      );
      if (courseState === 'Watch Again') {
        headerIcon = <CircleCheckIcon sx={{ color: '#B7BF10', height: '1.25em', width: '1.25em' }} />;
      } else if (courseState === 'Continue') {
        headerIcon = <CircleMoreIcon sx={{ color: '#F2A900', height: '1.25em', width: '1.25em' }} />;
      } else if (courseState === 'Watch') {
        headerIcon = <CircleOutlineIcon sx={{ color: theme.palette.tertiary.main, height: '1.15em', width: '1.15em' }} />;
      }
    } else {
      secondaryAction = <ComingSoon>COMING SOON</ComingSoon>;
    }

    return (
      <KitCard
        key={s.contentID}
        style={{
          width: '90%',
          minHeight: '170px',
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <React.Fragment key={s.contentID}>
          <KitCardBody style={{ padding: '1.00rem, 1.00rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '24px', marginBottom: '5px' }}>
                {getModuleLabel(s.fields.specializedModuleLabel, i)}
              </Typography>
            </Box>
            <ModuleBox>
              <ModuleName>{s.fields?.subModuleName}</ModuleName>
              <IconContainer>{headerIcon}</IconContainer>
            </ModuleBox>
            <CardBodyContainer>
              <Typography
                component='p'
                sx={{
                  color: theme.palette.grey[800],
                  fontSize: '1.00rem',
                  lineHeight: '1.25rem',
                  fontWeight: 300,
                  display: '-webkit-box',
                  ['-webkit-line-clamp']: '5',
                  ['-webkit-box-orient']: 'vertical',
                  overflow: 'hidden',
                  mb: 2,
                }}
              >
                {s.fields?.whatYouWillLearn}
              </Typography>
              <ActionContainerFrame>
                <ModuleActionContainer>{secondaryAction}</ModuleActionContainer>
              </ActionContainerFrame>
            </CardBodyContainer>
            {/* This hack prevents the divider from being added after the last submod. */}
            {i + 1 < totalSubs && <DashedHr />}
          </KitCardBody>
          <KitCardFooter></KitCardFooter>
        </React.Fragment>
      </KitCard>
    );
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={isLoading} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {parent && parent.fields && parent.fields.breadcrumbList && (
        <BreadcrumbFrame>
          <Box
            sx={{
              width: '100%',
              ml: 'auto',
              mr: 'auto',
              maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
            }}
          >
            <BreadcrumbContainer>
              {parent.fields.breadcrumbList &&
                parent.fields.breadcrumbList.length > 0 &&
                parent.fields.breadcrumbList
                  .sort((a, b) => {
                    if (parseInt(a.fields.order, 10) > parseInt(b.fields.order, 10)) return 1;
                    if (parseInt(a.fields.order, 10) < parseInt(b.fields.order, 10)) return -1;
                    return 0;
                  })
                  .map((breadcrumb, index) => {
                    return (
                      <Breadcrumb key={breadcrumb.contentID}>
                        {breadcrumb.fields.breadcrumbUrl.href && breadcrumb.fields.breadcrumbUrl.href.length > 0 ? (
                          <Link href={breadcrumb.fields.breadcrumbUrl.href} passHref legacyBehavior>
                            <a target={breadcrumb.fields.breadcrumbUrl.target} rel='noopener noreferrer'>
                              {breadcrumb.fields.breadcrumbUrl.text}
                            </a>
                          </Link>
                        ) : (
                          <span>{breadcrumb.fields.breadcrumbUrl.text}</span>
                        )}
                        {breadcrumb.fields.breadcrumbUrl.href && breadcrumb.fields.breadcrumbUrl.href.length > 0 && <span>{' / '}&nbsp;</span>}
                      </Breadcrumb>
                    );
                  })}
            </BreadcrumbContainer>
          </Box>
        </BreadcrumbFrame>
      )}

      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        {parent && parent.fields && parent.fields.introduction && <Introduction>{parent.fields.introduction}</Introduction>}
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CardGrid>
          <LayoutContainer
            size='md'
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: { md: '0 0.50rem', sm: '0 1.00rem' },
            }}
          >
            {subModules &&
              subModules.map((s, i) => {
                return getModuleCard(s, i, subModules.length);
              })}
          </LayoutContainer>
          {parent && parent.fields && parent.fields.parentPage && (
            <ReturnButton>
              <a href={parent.fields.parentPage.href} rel='noopener noreferrer'>
                <KitButton aria-label='Return to Series' color='primary' simple>
                  Return to Series
                </KitButton>
              </a>
            </ReturnButton>
          )}
        </CardGrid>
      </Box>

      <Dialog fullScreen open={viewCourseOpen} onClose={handleCloseCourse}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleCloseCourse} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {currentCourse?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {currentCourse?.launchLink && <iframe src={currentCourse.launchLink} height='100%' width='100%' />}
      </Dialog>
    </React.Fragment>
  );
};

NTRWMSModuleDetail.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem }) => {
  const api = agility;

  // fetch raw SubModule List for the Parent Module
  try {
    let submods = await api.getContentList({
      referenceName: 'ntrwmssubmodulelist',
      languageCode,
      expandAllContentLinks: true,
      filters: [
        { property: 'fields.parentModule_ValueField', operator: api.types.FilterOperators.EQUAL_TO, value: `"${dynamicPageItem.contentID}"` },
      ],
      sort: 'fields.moduleNumber',
      take: 25,
    });
    // parse the scormCourse object...
    submods = submods.items.map((s) => {
      try {
        if (s.fields.scormCourse) {
          s.fields.scormCourse = JSON.parse(s.fields.scormCourse);
        }
      } catch (error) {
        console.log(error);
      }
      return s;
    });

    // fetch the Parent Module (introduction)
    let parentSeries = await api.getContentItem({ contentID: dynamicPageItem.contentID, languageCode, expandAllContentLinks: true });

    return {
      subModules: submods,
      parentSeries,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default NTRWMSModuleDetail;

const DashedHr = styled('hr')(({ theme }) => ({
  borderTop: `1px dashed ${theme.palette.info.main}`,
  margin: '20px 0 -25px 0',
}));

const CardGrid = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '69%',
  },
  [theme.breakpoints.down('md')]: {
    width: '95%',
  },
}));

const CardBodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ModuleBox = styled('Box')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ModuleName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.5rem',
  lineHeight: '1.75rem',
  [theme.breakpoints.up('md')]: {
    width: '68%',
    margin: '0 0 15px 0',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 0 10px 0',
  },
}));

const IconContainer = styled('div')(({ theme }) => ({
  margin: '5px 0 10px 0',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ModuleActionContainer = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  marginTop: '-5px',
  button: {
    height: '35px',
    width: '140px',
    fontSize: '1em',
    fontWeight: 400,
  },
}));

const ActionButton = styled('h5')(({ theme }) => ({
  alignSelf: 'flex-start',
  button: {
    height: '35px',
    width: '140px',
    fontSize: '1em',
    fontWeight: 400,
  },
}));

const Introduction = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: theme.palette.common.black,
  margin: '15px 0',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: '20px 15px 20px 15px',
    margin: '30px 0 40px 0',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '15px 5px 15px 5px',
    margin: '15px 10px 20px 10px',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
}));

const ComingSoon = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '7px 0',
  width: '100%',
  fontSize: '1.70rem',
  fontWeight: 700,
  textTransform: 'uppercase',
}));

const ReturnButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '5px 0 35px 0',
}));

const BreadcrumbFrame = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '0 0 20px 0',
  [theme.breakpoints.up('md')]: {
    borderBottom: '1px solid #000',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const BreadcrumbContainer = styled('div')(({ theme }) => ({
  display: 'inline',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const BreadCrumbRow = styled('span')(({ theme }) => ({
  display: 'inline',
}));

const Breadcrumb = styled('span')(({ theme }) => ({
  display: 'inline',
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
}));

const ActionContainerFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));
