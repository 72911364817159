import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Snackbar, MuiAlert, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { ObsStyles } from './StyledComponents';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';

const AddTeacherDialog = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const [dialogState, setDialogState] = useState({
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    const init = () => {
      resetState();
    };

    if (props.open) {
      init();
    }
  }, [props.open]);

  const resetState = () => {
    setDialogState({
      first_name: '',
      last_name: '',
    });
  };

  const closeDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSave()) {
      return;
    }

    props.onClose(doSave, `${dialogState.last_name}, ${dialogState.first_name}`);
    resetState();
  };

  const canSave = () => {
    let hasError = false;
    const event = dialogState;
    if (!event.first_name || event.first_name.trim().length === 0) {
      hasError = true;
      event.first_nameError = true;
    }
    if (!event.last_name || event.last_name.trim().length === 0) {
      hasError = true;
      event.last_nameError = true;
    }
    if (hasError) {
      setDialogState({ ...event, canSave: false });
    }
    return !hasError;
  };

  const formOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'last_name' || field === 'last_name') {
      if (!value || value.trim().length === 0) {
        canSave = false;
        fieldError = true;
      }
    }

    setDialogState({ ...dialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='body'
      fullWidth={true}
      open={props.open}
      onClose={() => closeDialog(false)}
      PaperComponent={DraggablePaperComponent}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.obsAqua.main, color: theme.palette.white.main, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          New Teacher
          <IconButton className='need-interaction' onClick={() => closeDialog(false)}>
            <CancelIcon color='white' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem' }}>
          <FormControl fullWidth>
            <TextField
              required
              inputProps={{ maxLength: 100 }}
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              notched={true}
              size='small'
              id='first_name-value'
              value={dialogState.first_name}
              label='First Name'
              onChange={(e) => {
                formOnChange('first_name', e.target.value);
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              required
              inputProps={{ maxLength: 100 }}
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              notched={true}
              size='small'
              id='last_name-value'
              value={dialogState.last_name}
              label='Last Name'
              onChange={(e) => {
                formOnChange('last_name', e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <KitButton className='need-interaction' onClick={() => closeDialog(true)} size='sm' color='secondary' disabled={!dialogState.canSave}>
          Save
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddTeacherDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};
export default AddTeacherDialog;
