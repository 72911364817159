// package imports
import React, { useState } from 'react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

// local imports
import SessionSummaryMetricCard from './SessionSummaryMetricCard';
import SessionChecklists from '../SessionChecklists';
import { useObservationStore } from '../../stores/observationStore';
import { useUpdateObservationInstance } from '../../hooks/ObservationInstanceHooks';
import { default as SavedSnack } from '../AlertSnack';
import { logClientException } from 'appinsights/clientAppInsights';
import { BadRequestError } from 'services/apiWrapper';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const defaultToastAutoHide = 2500;

const SummaryMetricCards = ({ observationMetrics, checklistPageHref }) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackSave, setSnackSave] = useState({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });

  const { observation, patchObservation } = useObservationStore();

  const afterObservationMutate = (data) => {
    setSnackSave({ message: 'Checklists Updated', severity: 'success', autoHideDuration: defaultToastAutoHide });
  };

  const afterObservationMutateFail = (error) => {
    logClientException(error);
    if (error instanceof BadRequestError) {
      setErrorMessage(error.formatMessage());
    } else {
      setErrorMessage(defaultErrorMessage);
    }
    updateObservationMutation.reset();
  };

  const updateObservationMutation = useUpdateObservationInstance(observation, afterObservationMutate, afterObservationMutateFail);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <>
      <SessionSummaryMetricCard
        title='On Task'
        width='150px'
        upOrDown={observationMetrics.onTaskBenchmarkMet}
        metric={`${observationMetrics.onTask}%`}
      />
      <SessionSummaryMetricCard
        title='Cooperation to Correction'
        width='150px'
        upOrDown={observationMetrics.cooperationToCoorectionBenchmarkMet}
        metric={`${observationMetrics.cooperationToCorrection}%`}
        na={observationMetrics.cooperationToCorrection === 0}
      />
      <SessionSummaryMetricCard
        title='Praise Statements'
        width='200px'
        upOrDown={observationMetrics.praiseStatementsPerMinBenchmarkMet}
        metric={`${observationMetrics.praiseStatementsPerMin} per min`}
      />
      <SessionSummaryMetricCard
        title='Relationship-Building Components'
        width='370px'
        upOrDown={observationMetrics.relationShipBuildCountBenchmarkMet}
        metric={`${observationMetrics.relationShipBuildCount} of 4 indicators`}
      >
        <KitButton
          size='sm'
          href={checklistPageHref}
          style={{
            backgroundColor: theme.palette.gold.main,
            padding: '6px 8px',
          }}
          onClick={() => {
            if (!checklistPageHref) {
              setDialogOpen(true);
            }
          }}
        >
          <VisibilityIcon />
          View All
        </KitButton>
      </SessionSummaryMetricCard>
      <SessionSummaryMetricCard
        title='Preventative Strat'
        width='230px'
        upOrDown={observationMetrics.preventativeStratBenchmarkMet}
        metric={`${observationMetrics.preventativeStrat} per 2.5 m`}
      />
      <SessionSummaryMetricCard
        title='Praise to Correction'
        width='220px'
        upOrDown={observationMetrics.praiseToCorrectionBenchmarkMet}
        metric={`${observationMetrics.praiseToCorrection[0]}:${observationMetrics.praiseToCorrection[1]} Ratio`}
      />

      <SessionSummaryMetricCard
        title='Physical Environment Indicators'
        width='370px'
        upOrDown={observationMetrics.physEnvCountCountBenchmarkMet}
        metric={`${observationMetrics.physEnvCountCount} of ${observationMetrics.totalPhysEnvCount} indicators`}
      >
        <KitButton
          size='sm'
          href={checklistPageHref}
          style={{
            backgroundColor: theme.palette.gold.main,
            padding: '6px 8px',
          }}
          onClick={() => {
            if (!checklistPageHref) {
              setDialogOpen(true);
            }
          }}
        >
          <VisibilityIcon />
          View All
        </KitButton>
      </SessionSummaryMetricCard>

      <Dialog maxWidth='md' scroll='paper' fullWidth={true} open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle sx={{ backgroundColor: theme.palette.obsAqua.main, color: theme.palette.white.main, padding: '8px 16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Checklists
            <IconButton onClick={() => setDialogOpen(false)}>
              <CancelIcon color='white' />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ marginTop: '1rem' }}>
          <SessionChecklists
            eventState={observation}
            eventStateOnChange={(n, v) => {
              patchObservation([{ name: n, value: v }]);
            }}
          />
        </DialogContent>
        <DialogActions>
          <KitButton size='sm' onClick={() => setDialogOpen(false)}>
            Cancel
          </KitButton>
          <KitButton
            size='sm'
            style={{
              backgroundColor: theme.palette.gold.main,
            }}
            onClick={() => {
              updateObservationMutation.mutate();
              setDialogOpen(false);
            }}
          >
            Update
          </KitButton>
        </DialogActions>
      </Dialog>

      <SavedSnack
        message={snackSave.message}
        openSnack={snackSave.message === null ? false : true}
        onClose={() => {
          setSnackSave({ message: null, severity: 'success', autoHideDuration: defaultToastAutoHide });
        }}
        severity={snackSave.severity}
        autoHideDuration={snackSave.autoHideDuration}
      />

      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

SummaryMetricCards.propTypes = {
  observationMetrics: PropTypes.object.isRequired,
  checklistPageHref: PropTypes.string, // if not supplied, checklists are shown in dialog
};

export default SummaryMetricCards;
