import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

const ObservationPageContainer = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: '1rem',
        ml: '1rem',
        mr: '1rem',
      }}
    >
      {children}
    </Box>
  );
};

export default ObservationPageContainer;
