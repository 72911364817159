// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Snackbar,
  TextField,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { BadRequestError } from 'services/apiWrapper';
import AddEventDialog from './components/AddEventDialog';
import ObservationHeader from './components/ObservationHeader';
import { useObservationEventList } from './hooks/ObservationEventHooks';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';
import ObservationPageContainer from './components/ObservationPageContainer';
import EventListCard from './components/EventListCard';
import { useFetchObservers } from './hooks/ObserverLookupHooks';
import SelectSchoolDialog from './components/SelectSchoolDialog';
import { OTHER_SCHOOL } from 'services/constants';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import { useObservationEventListSearchStore } from './stores/observationEventListSearchStore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const pageSize = 30;

const ObservationEventList = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const [showNum, setShowNum] = useState(pageSize);
  const [pageTitle, setPageTitle] = useState(fields.title);
  const [manageEvents, setManageEvents] = useState(false);
  const [showNewEvent, setShowNewEvent] = useState(false);
  const [showSchoolLookup, setShowSchoolLookup] = useState(false);
  const [observers, setObservers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const [hasSearched, setHasSearched] = useState(false);
  const [eventList, setEventList] = useState([]);

  const { searchState, setSearchState, setEventDate, setSchool, setDistrict, setObserver, setAllDates } = useObservationEventListSearchStore();

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);

  const eventListQuery = useObservationEventList(
    searchState.eventDate,
    searchState.district?.id,
    searchState.school?.id === OTHER_SCHOOL.id ? null : searchState.school?.id,
    searchState.observer,
    searchState.allDates
  );
  const observerListQuery = useFetchObservers();
  const isFetchingCount = useIsFetching();

  useEffect(() => {
    if (eventListQuery.isSuccess && eventListQuery.isFetching === false) {
      setEventList(eventListQuery.data || []);
      setHasSearched(true);
    }
    if (eventListQuery.isError) {
      logClientException(eventListQuery.error);
      if (eventListQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (eventListQuery.error instanceof BadRequestError) {
        setErrorMessage(eventListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [eventListQuery]);

  useEffect(() => {
    if (observerListQuery.isSuccess && observerListQuery.isFetching === false) {
      const list = observerListQuery.data || [];
      // sort list by label
      list.sort((a, b) => a.label.localeCompare(b.label));
      setObservers(list);
    }
    if (observerListQuery.isError) {
      logClientException(eventListQuery.error);
      if (observerListQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (observerListQuery.error instanceof BadRequestError) {
        setErrorMessage(observerListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [observerListQuery]);

  useEffect(() => {
    if (queryParams && queryParams.get('r') !== '1' && !initialized) {
      // reset search state if not returning from another page
      setSearchState({ eventDate: null, school: null, district: null, observer: '', allDates: false });
    }
    if (queryParams && queryParams.get('m') === '1' && !initialized) {
      setPageTitle(fields.manageEventsTitle);
      setManageEvents(true);
    }
    setInitialized(true);
  }, [queryParams?.get('r'), queryParams?.get('m')]);

  const updateSelectedEvent = (event) => {
    eventListQuery.refetch();
  };

  const eventFilterDisplay = () => {
    const defaultDaySpan = 7;
    const items = [];
    items.push('Showing Events ');
    if (searchState.allDates) {
      items.push('Date: All dates');
    } else {
      const startDate = DateTime.now().startOf('day');
      const endDate = DateTime.now().startOf('day');
      if (searchState.eventDate == null) {
        items.push(
          `From: ${startDate.plus({ 'days': defaultDaySpan * -1 }).toFormat('MM/dd/yyyy')} to ${endDate
            .plus({ 'days': defaultDaySpan })
            .toFormat('MM/dd/yyyy')}`
        );
      } else {
        items.push(`On: ${searchState.eventDate.toFormat('MM/dd/yyyy')}`);
      }
    }
    if (searchState.observer?.length > 0) {
      items.push(`Observed by: ${observers.find((o) => o.value === searchState.observer)?.label}`);
    }
    if (searchState.school && searchState.school.id !== OTHER_SCHOOL.id) {
      items.push(`Agency: ${searchState.school.name}`);
    }
    if (searchState.school && searchState.school.id == OTHER_SCHOOL.id && searchState.district) {
      items.push(`District: ${searchState.district.name}`);
    }
    return items;
  };

  const schoolDisplay = () => {
    if (!searchState.school && !searchState.district) {
      return '';
    }
    if (searchState.school && searchState.school.id !== OTHER_SCHOOL.id) {
      return searchState.school.name;
    } else if (searchState.district) {
      return searchState.district.name;
    }
    return '';
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={pageTitle} headerAccentImageUrl={fields.headerAccentImage?.url} size='medium'></ObservationHeader>
      <Box>
        <IconButton aria-label='back' href={fields.observationHomeLink.href} title={fields.observationHomeLink.text} color='primary'>
          <KeyboardBackspaceIcon /> <HomeIcon />
        </IconButton>
      </Box>
      <ObservationPageContainer>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box
            id='filter box'
            sx={{
              width: '100%',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              rowGap: '1rem',
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.btLightGrey.dark,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignContent: 'center',
              flexWrap: 'wrap',
              padding: '1rem',
            }}
          >
            <Box id='clear-button-container' sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '-15px' }}>
              <IconButton sx={{ marginTop: '-15px', marginRight: '-15px' }}>
                <HighlightOffIcon
                  color='primary'
                  onClick={() => {
                    setSearchState({ eventDate: null, school: null, district: null, observer: '' });
                  }}
                />
              </IconButton>
            </Box>

            <Box sx={{ width: { xs: '95%', sm: '31%' }, display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '.5rem' }}>
              <DatePicker
                fullWidth
                label='Search Date'
                slotProps={{
                  field: { clearable: true, onClear: () => setEventDate(null) },
                  textField: {
                    fullWidth: true,
                    sx: { '.MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white } },
                    size: 'small',
                  },
                }}
                value={typeof searchState.eventDate === 'string' ? null : searchState.eventDate}
                //maxDate={DateTime.now()}
                onChange={(e) => {
                  setEventDate(e);
                }}
              />

              <FormControlLabel
                sx={{ fontSize: '13px', fontWeight: 400, lineHeight: '22px', color: theme.palette.primary.main }}
                control={
                  <Checkbox
                    size='small'
                    checked={searchState.allDates}
                    onChange={(e) => {
                      setAllDates(e.target.checked);
                      if (e.target.checked) {
                        setEventDate(null);
                      }
                    }}
                    color='primary'
                  />
                }
                label='Search All Dates'
              />
            </Box>

            <Box sx={{ width: { xs: '95%', sm: '31%' } }}>
              <FormControl variant='outlined' sx={{ '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white } }} fullWidth>
                <InputLabel htmlFor='school-lookup' size='small' required={searchState.allDates}>
                  Agency
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id='school-lookup'
                  type='text'
                  size='small'
                  readOnly={true}
                  value={schoolDisplay()}
                  onClick={() => setShowSchoolLookup(true)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ visibility: schoolDisplay()?.length > 0 ? 'visible' : 'hidden' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSchool(null);
                          setDistrict(null);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        aria-label='lookup school'
                        onClick={() => setShowSchoolLookup(true)}
                        onMouseDown={() => setShowSchoolLookup(true)}
                        edge='end'
                      >
                        <SchoolIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Agency'
                />
              </FormControl>
            </Box>

            <Box sx={{ width: { xs: '95%', sm: '31%', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '.5rem' } }}>
              <FormControl size='small' fullWidth>
                <TextField
                  select
                  fullWidth
                  sx={{ '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white } }}
                  label='Observer'
                  id='observer-select'
                  size='small'
                  value={searchState.observer}
                  onChange={(e) => {
                    setObserver(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton sx={{ visibility: searchState.observer?.length > 0 ? 'visible' : 'hidden' }} onClick={() => setObserver('')}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                >
                  {observers.map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <KitButton
                size='sm'
                style={{
                  backgroundColor: theme.palette.primary.main,
                  marginRight: '1rem',
                  width: '160px',
                }}
                onClick={() => setShowNewEvent(true)}
              >
                <AddIcon />
                New Event
              </KitButton>
            </Box>
          </Box>
          <Box
            sx={{
              mt: '.5rem',
              display: searchState.allDates && !searchState.district && !searchState.school ? 'flex' : 'none',
              justifyContent: 'center',
            }}
          >
            <FormLabel
              sx={{
                fontStyle: 'italic',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '22px',
                color: theme.palette.error.dark,
              }}
            >
              Agency is required when searching for all dates
            </FormLabel>
          </Box>

          <Box sx={{ mt: '.5rem', color: theme.palette.primary.main, fontStyle: 'italic' }}>
            {eventFilterDisplay().map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1.5rem', flexWrap: 'wrap', marginTop: '1.5rem' }}>
            {eventList?.map((event, index) => {
              if (index < showNum) {
                return (
                  <EventListCard
                    key={event.id}
                    eventSummary={event}
                    sessionListLink={fields.sessionListLink}
                    eventSummaryLink={fields.eventSummaryLink}
                    allowEdit={manageEvents}
                    user={session?.user}
                    onUpdated={(event) => eventListQuery.refetch()}
                  />
                );
              }
            })}
            {hasSearched && eventList?.length === 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '1.5rem' }}>
                <Heading3 underline={false}>No events found</Heading3>
              </Box>
            )}
          </Box>
          {showNum < eventList.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '1.5rem' }}>
              <KitButton size='sm' color='secondary' onClick={(e) => setShowNum(showNum + pageSize)}>
                View More
              </KitButton>
            </Box>
          )}
        </Box>

        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0 || eventListQuery.isFetching} />

        <AddEventDialog
          user={session?.user}
          open={showNewEvent}
          onClose={(save, event) => {
            if (save) {
              updateSelectedEvent(event);
            }
            setShowNewEvent(false);
          }}
        />

        <SelectSchoolDialog
          open={showSchoolLookup}
          district={searchState.district}
          school={searchState.school}
          onClose={(save, district, school) => {
            if (save) {
              setSchool(school);
              setDistrict(district);
            }
            setShowSchoolLookup(false);
          }}
        />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationEventList;
