import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { KitButton } from '@boystownorg/bi-cms-component-lib';

const CustomFilter = (props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(props.data || {});
  const theme = useTheme();

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const fields = props.fields.map((f, i) => {
    if (f.type === 'checkbox') {
      return (
        <FormControlLabel
          key={i}
          control={<Checkbox checked={data ? data[f.name] : undefined} onChange={(e) => handleChange(f.name, e.target.checked)} />}
          label={f.label}
        />
      );
    } else {
      return (
        <TextField
          key={f.name}
          sx={{ marginRight: '7px' }}
          size='small'
          value={data ? data[f.name] : undefined}
          margin='dense'
          label={f.label}
          type='text'
          variant='outlined'
          onChange={(e) => handleChange(f.name, e.target.value)}
          inputProps={{ maxLength: 100 }}
          InputLabelProps={{ shrink: true }}
        />
      );
    }
  });

  const handleClear = () => {
    let data = {};
    props.fields.forEach((f) => {
      data[f.name] = '';
    });
    setData(data);
    props.onChange({});
  };

  return (
    <Grid
      container
      sx={{
        border: open ? `1px solid ${theme.palette.primary.light}` : 'none',
        borderRadius: '10px',
        padding: '10px',
        margin: { xs: '10px', sm: 0 },
        width: { xs: '90%', sm: 'auto' },
      }}
    >
      <IconButton aria-label='filter' color='primary' onClick={() => setOpen(!open)}>
        <FilterAltIcon />
      </IconButton>
      {open && (
        <>
          <Grid item>{fields}</Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '3px' }}>
            <KitButton simple size='sm' color='danger' onClick={handleClear}>
              Clear
            </KitButton>
            <KitButton simple size='sm' color='success' onClick={() => props.onChange(data)}>
              Apply
            </KitButton>
          </Box>
        </>
      )}
    </Grid>
  );
};

CustomFilter.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default CustomFilter;
