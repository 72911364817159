import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { emailRegex } from 'services/stringUtils';
import { ROLES, ROLE_CONST } from 'services/roleUtils';
import { logClientException } from 'appinsights/clientAppInsights';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AddEditEmailDomainDialog = (props) => {
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const [domainState, setDomainState] = useState({
    email_domain: '',
    state: null,
    district: null,
    is_active: true,
  });

  const fetching = useRef(0);

  useEffect(() => {
    const init = async () => {
      if (props.editDomain) {
        let editDomain = props.editDomain;
        editDomain.state = props.stateList.find((s) => s.value === editDomain.state);
        // if (editDomain.state) {
        //   await fetchDistrictsForState(editDomain.state);
        // }
        // if (editDomain.district_id) {
        //   await fetchSchoolsForDistrict(editDomain.district_id);
        // }
        setDomainState({ ...editDomain });
      } else {
        const district = { label: props.user.district_name, value: props.user.district_id };
        //const school = {label: props.user.school_name, value: props.user.school_id}
        const state = props.stateList.find((s) => s.value === props.user.state);
        await fetchDistrictsForState(state);
        //await fetchSchoolsForDistrict(props.user.district_id);
        setErrorMessage(null);
        setDomainState({
          email_domain: '',
          state: state,
          district: district,
          is_active: true,
        });
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open]);

  const resetState = () => {
    setErrorMessage(null);
    setDomainState({
      email_domain: '',
      state: null,
      district: null,
      is_active: true,
    });
  };

  const fetchDistrictsForState = async (state) => {
    try {
      fetching.current += 1;
      setSchoolList([]);
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      setDistrictList(res.results.map((r) => ({ label: r.name, value: r.id })));
      setSchoolList([]);
      fetching.current -= 1;
      setDomainState({ ...domainState, state: state, district: null, school: null });
    } catch (error) {
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district) => {
    try {
      fetching.current += 1;
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district}`);
      setSchoolList(res.results.map((r) => ({ label: r.name, value: r.id })));
      fetching.current -= 1;
    } catch (error) {
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const closeDomainDialog = async (doSave) => {
    setDistrictList([]);
    setSchoolList([]);

    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSaveDomain()) {
      return;
    }

    let domain = {};
    if (domainState.district) {
      domain.district_id = domainState.district.value;
      domain.district_name = domainState.district.label;
    }
    // if (domain.school) {
    //   domain.school_id = domain.school.value;
    //   domain.school_name = domain.school.label;
    //   delete domain.school;
    // }
    if (domainState.state) {
      domain.state = domainState.state.value;
    }
    domain.email_domain = domainState.email_domain;
    domain.is_active = domainState.is_active;

    try {
      fetching.current += 1;
      await callApi('/api/db/domain-allow', props.action === 'Add' ? 'POST' : 'PUT', domain);
      resetState();
      props.onClose(doSave);
      fetching.current -= 1;
    } catch (error) {
      logClientException(error);
      fetching.current -= 1;
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSaveDomain = () => {
    let hasError = false;
    const domain = domainState;
    if (!domain.email_domain || domain.email_domain.length === 0) {
      hasError = true;
      domain.email_domain = true;
    }
    if (!domain.state || !domain.state.value) {
      hasError = true;
      domain.stateError = true;
    }
    if (!domain.district || !domain.district.value || domain.district.value.length === 0) {
      hasError = true;
      domain.districtError = true;
    }
    // if (!domain.school || !domain.school.value || domain.school.value.length === 0) {
    //   hasError = true;
    //   domain.schoolError = true;
    // }
    if (hasError) {
      setDomainState({ ...domain, canSave: false });
    }
    return !hasError;
  };

  const domainStateOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;

    if (field === 'emailDomain' || field === 'state') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    setDomainState({ ...domainState, [field]: value, [`${field}Error`]: fieldError, canSave });

    if (field === 'state' && !fieldError) {
      await fetchDistrictsForState(value);
      setDomainState({ ...domainState, [field]: value, [`${field}Error`]: fieldError, district: null, school: null, canSave });
      return;
    }
    // if (field === 'district' && value) {
    //   await fetchSchoolsForDistrict(value.value);
    //   setDomainState({ ...domainState, [field]: value, [`${field}Error`]: fieldError, school: null , canSave})
    // }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeDomainDialog(false)}>
      <DialogTitle>{props.action} Email Domain</DialogTitle>

      <DialogContent>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={props.stateList}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          disabled={props.action === 'Update'}
          value={domainState.state}
          onChange={(e, v) => domainStateOnChange('state', v)}
          renderInput={(params) => <TextField required error={domainState.stateError} {...params} label='State' />}
        />

        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={districtList}
          fullWidth
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          disabled={props.action === 'Update' || !domainState.state}
          value={domainState.district}
          onChange={(e, v) => domainStateOnChange('district', v)}
          renderInput={(params) => (
            <TextField disabled={!domainState.state} required error={domainState.districtError} {...params} label='School district' />
          )}
        />

        <TextField
          value={domainState.email_domain}
          error={domainState.email_domainError}
          autoFocus
          required
          margin='dense'
          id='email-domain'
          label='Email Domain'
          type='text'
          fullWidth
          variant='outlined'
          onChange={(e) => domainStateOnChange('email_domain', e.target.value?.toLowerCase())}
          inputProps={{ maxLength: 100 }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl sx={{ marginTop: '7px' }}>
          <FormControlLabel
            id='role-select'
            label='Is Active'
            control={<Switch checked={domainState.is_active} onChange={(e) => domainStateOnChange('is_active', e.target.checked)} />}
          />
        </FormControl>
        <PleaseWait isLoading={fetching.current > 0} />
      </DialogContent>
      <DialogActions>
        <KitButton onClick={() => closeDomainDialog(false)} size='sm' round>
          Cancel
        </KitButton>
        <KitButton onClick={() => closeDomainDialog(true)} size='sm' round color='success' disabled={!domainState.canSave}>
          {props.action}
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddEditEmailDomainDialog.propTypes = {
  action: PropTypes.oneOf(['Add', 'Update']).isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  editDomain: PropTypes.object,
  stateList: PropTypes.array,
  onClose: PropTypes.func,
};
export default AddEditEmailDomainDialog;
