import React, { useState } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';
import CourseInformation from 'components/common/subcomponents/GraduateInformation/CourseInformation';
import DialogWithX from './pageComponents/DialogWithX';
import { logServerException } from 'appinsights/clientAppInsights';

const GraduateOptionCards = ({ module, customData }) => {
  const theme = useTheme();

  const [showDialog, setShowDialog] = useState(false);

  const Container = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const Title = styled.div`
    text-transform: uppercase;
    width: 95%;
    text-align: center;
    color: ${theme.palette.common.white};
    @media screen and (min-width: 992px) {
      font-size: 1.625rem;
      line-height: 1.5rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.625rem;
      line-height: 1.5rem;
    }
  `;
  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
      }}
    >
      <Frame>
        <Heading4 color='rgba(0, 0, 0, 0.87)' style={{ width: '85%' }}>
          {module.fields.title}
        </Heading4>
        <>
          <Container>
            <CardGrid>
              {module.fields.cardList
                .sort((a, b) => {
                  if (parseInt(a.properties.itemOrder, 10) > parseInt(b.properties.itemOrder, 10)) return 1;
                  if (parseInt(a.properties.itemOrder, 10) < parseInt(b.properties.itemOrder, 10)) return -1;
                  return 0;
                })
                .map((card, index) => (
                  <KitCard
                    key={card.contentID}
                    style={{
                      width: '23rem',
                      height: '265px',
                      borderRadius: '6px',
                      backgroundColor: 'fff',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <React.Fragment>
                      <HeaderFrame>{card.fields.cardHeader && <Title>{card.fields.cardHeader}</Title>}</HeaderFrame>
                      <KitCardBody style={{ margin: 0 }}>
                        <BodyFrame>
                          <Description>{card.fields.cardText && card.fields.cardText}</Description>
                        </BodyFrame>
                      </KitCardBody>
                    </React.Fragment>
                  </KitCard>
                ))}
            </CardGrid>
            <KitButton round style={{ backgroundColor: theme.palette.primary.light, marginBottom: '50px' }} onClick={(e) => setShowDialog(true)}>
              {module.fields.buttonText}
            </KitButton>
          </Container>
        </>
      </Frame>
      <DialogWithX
        size='lg'
        content={<CourseInformation data={customData} />}
        ariaLabel='Course Information Dialog'
        showValue={showDialog}
        showMethod={setShowDialog}
      />
    </Box>
  );
};

GraduateOptionCards.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem, channelName }) => {
  const api = agility;

  try {
    let courses = await api.getContentList({
      referenceName: 'coursesummary',
      expandAllContentLinks: true,
      languageCode,
      take: 100,
      sort: 'fields.sortOrder',
    });

    return {
      courses: courses?.items ?? null,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default GraduateOptionCards;

const Frame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    margin: '25px 0 0 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: '45px 0 0 0',
  },
}));

const CardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    margin: '10px 0 0 0',
    gap: '2.50em',
    padding: '10px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('md')]: {
    margin: '10px 0 0 0',
    gap: '1.00em',
    padding: '10px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const HeaderFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  height: '100px',
  borderRadius: '6px 6px 0px 0px',
}));

const BodyFrame = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '130px',
}));

const Description = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 300,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
  },
}));
