import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';

const SummaryWithBackgroundAndVideo = ({ module }) => {
  const { fields } = module;

  const router = useRouter();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const videoExists = fields.videoScript && fields.videoScript.length > 0;

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 899px) {
    }
    @media screen and (min-width: 900px) {
      ${fields.contentAlign === 'right' ? (videoExists ? 'flex-direction: row-reverse;' : 'flex-direction: row;') : 'flex-direction: row;'}
    }
    position: relative;
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (min-width: 900px) {
      margin: 5px 50px;
      padding: 2% 5%;
      ${videoExists ? 'max-width: 50%;' : 'max-width: 90%;'}
    }
    @media screen and (min-width: 1200px) {
      margin: 5px 30px;
      padding: 4% 8%;
      ${videoExists ? 'max-width: 50%;' : 'max-width: 90%;'}
    }
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 50%;
    margin: 0;
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      ${!videoExists && 'display: none;'}
      ${videoExists ? 'max-width: 50%;' : 'max-width: 0'}
      ${videoExists ? 'max-height: 95%`;' : 'max-height: 0'}
    }
  `;

  const Title = styled.div`
    font-weight: 600;
    margin: 25px 5px;
    @media screen and (max-width: 899px) {
      color: ${fields.titleColor};
      font-size: 1.75rem;
      line-height: 1.95rem;
      padding: 0 25px;
    }
    @media screen and (min-width: 900px) {
      color: ${fields.titleColor};
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  `;

  const PostContent = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    margin: '5px 0 10px 0',
    fontSize: '0.90rem',
    fontWeight: 400,
  }));

  const SubTitle = styled.div`
    margin: 0 5px;
    @media screen and (max-width: 899px) {
      display: none;
    }
  `;

  const SummaryDescription = styled.div`
    margin-top: 10px;
    font-weight: 300;
    margin: 30px 5px;
    @media screen and (max-width: 899px) {
      color: ${fields.descriptionColor};
      font-size: 1rem;
      line-height: 1.3rem;
      padding: 0 25px;
    }
    @media screen and (min-width: 900px) {
      color: ${fields.descriptionColor};
      font-size: 1rem;
      line-height: 1.3rem;
    }
  `;

  const ButtonContainer = styled.div`
    margin: 40px 5px;
    @media screen and (max-width: 899px) {
      padding: 0 25px;
    }
  `;

  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: `url(${fields.backgroundImage.url})`,
        }}
      >
        <ContentContainer>
          <Title>{fields.title}</Title>
          <SubTitle className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.subTitle)}></SubTitle>
          <SummaryDescription>{fields.description}</SummaryDescription>
        </ContentContainer>
        <VideoContainer>
          <div className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.videoScript)} />
        </VideoContainer>
      </Container>
    </React.Fragment>
  );
};

export default SummaryWithBackgroundAndVideo;
