import React, { useState } from 'react';
import { useSession } from 'next-auth/react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { ListItemIcon } from '@mui/material';
import { KitButton, KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@emotion/react';
import GatedResourceDrawer from 'components/common/subcomponents/GatedResources/GatedResourceDrawer';
import GatedResourceModal from 'components/common/subcomponents/GatedResources/GatedResourceModal';
import { logResourceAccess } from 'services/gatedResourceUtils';
import { gatedResourceCheck } from 'services/gatedResourceUtils';

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    min-height: 200px;
  }
  @media screen and (min-width: 992px) {
    min-height: 300px;
  }
`;

const PlayFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    // display: none;
    margin: 5px 0 5px 0;
  }
  @media screen and (min-width: 992px) {
    margin: -60px 0 5px 0;
  }
`;

const VideoContainer = styled.div`
  background-color: theme.palette.common.white;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 35px 0;
  @media screen and (max-width: 899px) {
    .video {
      aspect-ratio: 16 / 9;
      width: 300px;
    }
  }
  @media screen and (min-width: 900px) {
    .video {
      aspect-ratio: 16 / 9;
      width: 650px;
    }
  }
`;

const EmbedContainer = styled.div`
  border: 1px solid #000;
  @media screen and (max-width: 899px) {
    display: none;
  }
  @media screen and (min-width: 900px) {
    width: 650px;
  }
`;

const YouTubeLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 899px) {
    margin: 0 0 20px 0;
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
`;

const DocumentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

const WebinarDocument = styled.div`
  cursor: pointer;
`;

const ListText = styled.div`
  cursor: pointer;
  color: theme.palette.common.black;
  @media screen and (max-width: 899px) {
    margin: 25px 0 10px 0;
    font-size: 1rem;
    line-height: 1.225rem;
  }
  @media screen and (min-width: 900px) {
    margin: 15px 0;
    font-size: 1.125rem;
    line-height: 1.525rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ResourceDetails = (props) => {
  const { customData } = props;
  const theme = useTheme();

  const [gatedDrawerState, setGatedDrawerState] = useState({ open: false, remaining: 0 });
  const [gatedModalOpen, setGatedModalOpen] = useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const { data: session, status } = useSession();

  let newCount = 0;
  for (let i = 1; i < 4; i++) {
    props.dynamicPageItem?.fields[`column${i}Links`]?.forEach((link) => {
      if (link.fields?.isNew === 'true') {
        newCount++;
      }
    });
  }

  const hideVideoDialog = () => {
    setShowVideoDialog(false);
  };

  const showDocumentLink = (url) => {
    return (
      <WebinarDocument onClick={() => openHandout(url.href)}>
        <ListItemIcon style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ListText style={{ width: '100%' }}>
            <ArticleIcon /> {url.text}
          </ListText>
        </ListItemIcon>
      </WebinarDocument>
    );
  };

  const openFreeResource = async (url, name, type) => {
    if (!session?.user) {
      const remaining = gatedResourceCheck(url);
      if (remaining < 0) {
        // show overlay
        setGatedModalOpen(true);
        return;
      } else {
        setGatedDrawerState({ open: true, remaining });
      }
    }

    // If webinar, open dialog, otherwise open new tab in browser.
    if (type === 'webinar') {
      setShowVideoDialog(true);
    } else {
      const win = window.open(url, '_blank');
      win.focus();
    }

    // log resource access
    await logResourceAccess(type, name, url);
  };

  // Handouts are already gated behind the webinar/video, so no need to gate again.
  const openHandout = async (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const VideoImageContainer = styled.div`
    background-color: theme.palette.common.white;
    border: 5px solid ${theme.palette.brandedAqua.main};
    flex-grow: 0;
    flex-shrink: 1;
    height: 235px;
    @media screen and (max-width: 899px) {
      .video {
        aspect-ratio: 16 / 9;
        width: 300px;
      }
    }
    @media screen and (min-width: 900px) {
      .video {
        aspect-ratio: 16 / 9;
        width: 650px;
      }
    }
  `;

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <ResourceContainer>
        <ResourceName>{props?.dynamicPageItem?.fields?.linkName}</ResourceName>
        <Paragraph>{props?.dynamicPageItem?.fields?.description}</Paragraph>
      </ResourceContainer>
      <WrapContainer>
        <DownloadContainer>
          {props?.dynamicPageItem?.fields?.type === 'lesson' && (
            <KitButton
              aria-label='Open Free Resource'
              round
              size='sm'
              onClick={() =>
                openFreeResource(
                  props?.dynamicPageItem?.fields?.linkUrl.href,
                  props?.dynamicPageItem?.fields?.linkName,
                  props?.dynamicPageItem?.fields?.type
                )
              }
              title='Download'
              style={{ backgroundColor: theme.palette.brandedAqua.main }}
            >
              Download This Lesson Plan
            </KitButton>
          )}
          {props?.dynamicPageItem?.fields?.type === 'webinar' && (
            <ImageContainer>
              <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: 'fit-content' }}>
                <KitGridContainer direction='row'>
                  <KitGridItem xs={12} align='left'>
                    <VideoImageContainer>
                      {props &&
                        props.dynamicPageItem &&
                        props.dynamicPageItem.fields &&
                        props.dynamicPageItem.fields.previewImage &&
                        props.dynamicPageItem.fields.previewImage.url.length > 0 && (
                          <img src={props?.dynamicPageItem?.fields?.previewImage.url} alt='Play Video' width='400px' />
                        )}
                      <PlayFrame>
                        <KitButton
                          aria-label='Open Free Resource'
                          round
                          size='sm'
                          onClick={() =>
                            openFreeResource(
                              props?.dynamicPageItem?.fields?.linkUrl.href,
                              props?.dynamicPageItem?.fields?.linkName,
                              props?.dynamicPageItem?.fields?.type
                            )
                          }
                          title='Download'
                          style={{ backgroundColor: theme.palette.brandedAqua.main }}
                        >
                          Watch <PlayArrowIcon />
                        </KitButton>
                      </PlayFrame>
                    </VideoImageContainer>
                  </KitGridItem>
                </KitGridContainer>
              </Box>
            </ImageContainer>
          )}
          {props?.dynamicPageItem?.fields?.type === 'activity' && (
            <KitButton
              aria-label='Open Free Resource'
              round
              size='sm'
              onClick={() =>
                openFreeResource(
                  props?.dynamicPageItem?.fields?.linkUrl.href,
                  props?.dynamicPageItem?.fields?.linkName,
                  props?.dynamicPageItem?.fields?.type
                )
              }
              title='Download'
              style={{ backgroundColor: theme.palette.brandedAqua.main }}
            >
              Download This Activity
            </KitButton>
          )}
        </DownloadContainer>
      </WrapContainer>
      <Dialog open={showVideoDialog} onClose={hideVideoDialog} scroll='paper' maxWidth='md' fullWidth={false} aria-labelledby='Video Viewer'>
        <React.Fragment>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: 'fit-content' }}>
              <KitGridContainer direction='row' style={{ marginBottom: '-20px' }}>
                <KitGridItem xs={12} align='left'>
                  <VideoContainer>
                    <EmbedContainer
                      className='prose max-w-full mx-auto'
                      dangerouslySetInnerHTML={renderHTML(props?.dynamicPageItem?.fields?.videoEmbedCode)}
                    />
                  </VideoContainer>
                  <YouTubeLink>
                    <a href={props?.dynamicPageItem?.fields?.linkUrl.href} target='_blank' rel='noreferrer'>
                      <KitButton round size='sm' color='youtube' style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}>
                        <React.Fragment key='.0'>
                          <PlayArrowIcon color='white' /> Open Video Player
                        </React.Fragment>
                      </KitButton>
                    </a>
                  </YouTubeLink>
                </KitGridItem>
              </KitGridContainer>
              <DocumentContainer>
                {props &&
                  props.dynamicPageItem &&
                  props.dynamicPageItem.fields &&
                  props.dynamicPageItem.fields.documentUrl &&
                  showDocumentLink(props.dynamicPageItem.fields.documentUrl)}
              </DocumentContainer>
            </Box>
            <ButtonContainer>
              <KitButton
                round
                size='sm'
                color='primary'
                onClick={hideVideoDialog}
                style={{ margin: '0', padding: '7px 40px', fontSize: '0.90rem', opacity: 1.0 }}
              >
                Close
              </KitButton>
            </ButtonContainer>
          </DialogContent>
        </React.Fragment>
      </Dialog>
      <GatedResourceDrawer
        open={gatedDrawerState.open}
        remainingCount={gatedDrawerState.remaining}
        toggleDrawer={(e) => setGatedDrawerState({ ...gatedDrawerState, open: e })}
      />
      <GatedResourceModal open={gatedModalOpen} toggleOpen={(e) => setGatedModalOpen(e)} />
    </Box>
  );
};

export default ResourceDetails;

const ResourceContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '0 5px 10px 5px',
    margin: '0 0 5px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    margin: 0,
  },
}));

const WrapContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '10px 5px 10px px',
    margin: '0 20px 5px 20px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px 5px 10px 5px',
    margin: '10px',
  },
}));

const ResourceName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  [theme.breakpoints.up('md')]: {
    padding: '10px 5px 10px px',
    margin: '60px 20px 5px 20px',
    fontSize: '2.25rem',
    lineHeight: '2.55rem',
    fontWeight: 700,
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px 5px 10px 5px',
    margin: '15px 5px',
    fontSize: '1.65rem',
    lineHeight: '1.95rem',
    fontWeight: 600,
  },
}));

const Paragraph = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  [theme.breakpoints.up('md')]: {
    padding: '10px 5px 10px px',
    margin: '20px 20px 5px 20px',
    fontSize: '1.00rem',
    lineHeight: '1.8rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px 5px 10px 5px',
    margin: '15px 5px',
    fontSize: '1.00rem',
    lineHeight: '1.5rem',
  },
}));

const DownloadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0 60px 0',
}));
