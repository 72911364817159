import styled from '@emotion/styled';

export const SharedHeaderDesktopWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const SharedHeaderDesktop = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderBottom: `4px solid ${theme.palette.gold.main}`,
  margin: '0 0',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const SharedHeaderNav = styled('ul')(({ theme, iconUrl }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    border: 'none',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    maxWidth: '1392px',
    margin: '0 auto',
    '& > li': {
      backgroundColor: 'transparent',
      color: '#f1f1f1',
      borderRight: 'thin solid rgba(255, 255, 255, 0.2)',
      '& > a': {
        textDecoration: 'none',
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: '600',
        padding: '12px 0.75rem',
        border: 'none',
        display: 'block',
      },
    },
    '& > li:last-child': {
      backgroundColor: 'transparent',
      border: 'none',
      marginLeft: 'auto',
    },
  },

  '& > li a.current-link': {
    backgroundColor: theme.palette.gold.main,
  },

  '& > li:first-child a': {
    display: 'flex',
    alignItems: 'center',
  },
  '& > li:first-child a:before': {
    content: '""',
    backgroundImage: `url(${iconUrl})`,
    width: '20px',
    height: '20px',
    backgroundSize: 'cover',
    marginRight: '5px',
    filter: 'brightness(0) invert(1)',
  },
}));

export const SharedHeaderNavMobileTertiary = styled('div')(({ theme }) => ({
  '& > #mobileHelpBtn': {
    backgroundColor: theme.palette.violet.main,
    textAlign: 'center',
    margin: '0 1rem 1rem 1rem',
    '& > a': {
      textDecoration: 'none',
      fontFamily: '"Source Sans 3", sans-serif',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      fontWeight: '600',
      padding: '1rem 1.5rem',
      display: 'block',
    },
  },
}));

export const SharedHeaderNavMobile = styled('ul')(({ theme, iconUrl }) => ({
  margin: '1rem 1rem 1rem 1rem',
  listStyle: 'none',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: '1px',
  alignItems: 'center',
  paddingLeft: 0,
  paddingRight: 0,

  '& > li a.current-link': {
    backgroundColor: theme.palette.gold.main,
  },

  '& > li:first-child a:before': {
    content: '""',
    backgroundImage: `url(${iconUrl})`,
    width: '20px',
    height: '20px',
    backgroundSize: 'cover',
    marginRight: '5px',
    display: 'inline-block',
  },

  '& > li': {
    backgroundColor: '#f1f1f1',
    '& > a': {
      display: 'flex',
      textDecoration: 'none',
      fontSize: '11px',
      lineHeight: '20px',
      color: 'rgb(19 61 96)',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontWeight: '600',
      padding: '12px 0.5rem',
      border: 'none',
    },
  },
}));

export const SearchResultsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingTop: '2.5rem',
  paddingBottom: '2.5rem',
  overflow: 'auto',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.grey.A100,
  borderBottom: `1px solid ${theme.palette.grey.A400}`,
}));

export const SearchBarWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  transform: 'translate(0, 48px)',
  zIndex: 400,
}));
