import Heading1 from '../subcomponents/Typography/Heading1';
import Heading2 from '../subcomponents/Typography/Heading2';
import Heading3 from '../subcomponents/Typography/Heading3';
import Heading4 from '../subcomponents/Typography/Heading4';
import Heading5 from '../subcomponents/Typography/Heading5';
import Heading6 from '../subcomponents/Typography/Heading6';

export function FreeformHeader({ level, text, color, ...rest }) {
  if (level === 1 || level === 'h1') {
    return (
      <Heading1 color={color} {...rest}>
        {text}
      </Heading1>
    );
  } else if (level === 2 || level === 'h2') {
    return (
      <Heading2 color={color} {...rest}>
        {text}
      </Heading2>
    );
  } else if (level === 3 || level === 'h3') {
    return (
      <Heading3 color={color} {...rest}>
        {text}
      </Heading3>
    );
  } else if (level === 4 || level === 'h4') {
    return (
      <Heading4 color={color} {...rest}>
        {text}
      </Heading4>
    );
  } else if (level === 5 || level === 'h5') {
    return (
      <Heading5 color={color} {...rest}>
        {text}
      </Heading5>
    );
  } else if (level === 6 || level === 'h6') {
    return (
      <Heading6 color={color} {...rest}>
        {text}
      </Heading6>
    );
  } else {
    return (
      <Heading1 color={color} {...rest}>
        {text}
      </Heading1>
    );
  }
}
