// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import HomeIcon from '@mui/icons-material/Home';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Autocomplete, Box, IconButton, Snackbar, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { BadRequestError } from 'services/apiWrapper';
import ObservationHeader from './components/ObservationHeader';
import { useObservationEventInstanceList } from './hooks/ObservationEventHooks';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';
import ObservationPageContainer from './components/ObservationPageContainer';
import SessionListHeader from './components/SessionListHeader';
import SessionListCard from './components/SessionListCard';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import { lastNameFirst2FirstNameLast } from 'services/stringUtils';
import { GRADE_LIST, OBS_SUBJECT_LIST } from 'services/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const ObservationSessionList = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const [eventId, setEventId] = useState(null);
  const [event, setEvent] = useState(null);
  const [prev, setPrev] = useState(null);
  const [instanceList, setInstanceList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const [teacherList, setTeacherList] = useState([]);
  const [observerList, setObserverList] = useState([]);
  const [subjectList, setSubjectList] = useState(OBS_SUBJECT_LIST.map((s) => s.name));
  const [gradeList, setGradeList] = useState(GRADE_LIST.map((g) => g.name));
  const [filters, setFilters] = useState({ teacher: '', observer: '', subject: '', grade: '' });
  const [filteredList, setFilteredList] = useState([]);

  const eventInstanceListQuery = useObservationEventInstanceList(eventId);

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);

  const isFetchingCount = useIsFetching();

  useEffect(() => {
    if (eventInstanceListQuery.isSuccess && eventInstanceListQuery.isFetching === false) {
      setEvent(eventInstanceListQuery.data.event);
      setInstanceList(eventInstanceListQuery.data.instances);
      applyFilterList(filters);

      let teachers = [...new Set(eventInstanceListQuery.data.instances.map((instance) => lastNameFirst2FirstNameLast(instance.teacher)))];
      setTeacherList(teachers.sort());

      let observers = [...new Set(eventInstanceListQuery.data.instances.map((instance) => lastNameFirst2FirstNameLast(instance.observer_name)))];
      setObserverList(observers.sort());
    }
    if (eventInstanceListQuery.isError) {
      logClientException(eventInstanceListQuery.error);
      if (eventInstanceListQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (eventInstanceListQuery.error instanceof BadRequestError) {
        setErrorMessage(eventInstanceListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [eventInstanceListQuery]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const id = queryParams?.get('id');
      if (id && id?.length > 0) {
        setEventId(id);
      }
      const p = queryParams?.get('p');
      if (p && p?.length > 0) {
        setPrev(p);
      }
    }
  }, [queryParams?.get('id')]);

  const handleFilterChange = (v, filter) => {
    let newFilters = { ...filters, [filter]: v === null ? '' : v };
    setFilters(newFilters);
    applyFilterList(newFilters);
  };

  const applyFilterList = (filterObj) => {
    let filtered = instanceList.filter((instance) => {
      return (
        (filterObj.teacher === '' || lastNameFirst2FirstNameLast(instance.teacher) === filterObj.teacher) &&
        (filterObj.observer === '' || lastNameFirst2FirstNameLast(instance.observer_name) === filterObj.observer) &&
        (filterObj.subject === '' || instance.subject === filterObj.subject) &&
        (filterObj.grade === '' || instance.grade === filterObj.grade)
      );
    });
    setFilteredList(filtered);
  };

  const handleAlertClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (initialized && (!eventId || !event) && eventInstanceListQuery.isSuccess && eventInstanceListQuery.isFetching === false) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          paddingBottom: '3rem',
        }}
      >
        <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='small' />
        <ObservationPageContainer>
          <div>{fields?.noIdMessage || 'You appear to have reached this page in error.'}</div>
          <KitButton
            size='sm'
            style={{
              width: '100px',
            }}
            href={`${fields?.eventListLink?.href}?r=1`}
          >
            <TurnLeftIcon />
            Back
          </KitButton>
        </ObservationPageContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='large'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <SessionListHeader event={event} sessionList={instanceList} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <KitButton
              size='sm'
              style={{
                width: prev === 'summary' ? '195px' : '160px',
              }}
              disabled={isFetchingCount > 0}
              href={prev === 'summary' ? `event-summary?id=${eventId}&p=${prev}` : `${fields?.eventListLink?.href}?r=1`}
            >
              <TurnLeftIcon />
              {prev === 'summary' ? 'RETURN TO EVENT SUMMARY' : 'RETURN TO EVENT LIST'}
            </KitButton>
          </Box>
        </Box>
      </ObservationHeader>
      <Box>
        <IconButton aria-label='back' href={fields.observationHomeLink.href} title={fields.observationHomeLink.text} color='primary'>
          <KeyboardBackspaceIcon /> <HomeIcon />
        </IconButton>
      </Box>
      <ObservationPageContainer>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box
            id='filter box'
            sx={{
              width: '100%',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              rowGap: '1rem',
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.btLightGrey.dark,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignContent: 'center',
              flexWrap: 'wrap',
              padding: '1rem',
            }}
          >
            <Box id='clear-button-container' sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '-15px' }}>
              <IconButton sx={{ marginTop: '-15px', marginRight: '-15px' }}>
                <HighlightOffIcon
                  color='primary'
                  onClick={() => {
                    setFilters({ teacher: '', observer: '', subject: '', grade: '' });
                    setFilteredList(instanceList);
                  }}
                />
              </IconButton>
            </Box>

            <Box sx={{ width: { xs: '95%', sm: '24%' } }}>
              <Autocomplete
                sx={{
                  '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white },
                }}
                size='small'
                fullWidth
                label='Teacher'
                disablePortal
                options={teacherList}
                value={filters.teacher}
                onChange={(_, v) => {
                  handleFilterChange(v, 'teacher');
                }}
                renderInput={(params) => <TextField {...params} label='Teacher' />}
              />
            </Box>
            <Box sx={{ width: { xs: '95%', sm: '24%' } }}>
              <Autocomplete
                sx={{
                  '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white },
                }}
                size='small'
                fullWidth
                label='Observer'
                options={observerList}
                value={filters.observer}
                onChange={(_, v) => {
                  handleFilterChange(v, 'observer');
                }}
                renderInput={(params) => <TextField {...params} label='Observer' />}
              />
            </Box>
            <Box sx={{ width: { xs: '95%', sm: '24%' } }}>
              <Autocomplete
                sx={{
                  '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white },
                }}
                options={subjectList}
                size='small'
                fullWidth
                label='Subject'
                value={filters.subject}
                onChange={(_, v) => {
                  handleFilterChange(v, 'subject');
                }}
                renderInput={(params) => <TextField {...params} label='Subject' />}
              />
            </Box>
            <Box sx={{ width: { xs: '95%', sm: '24%' } }}>
              <Autocomplete
                sx={{
                  '& .MuiOutlinedInput-root': { backgroundColor: theme.palette.common.white },
                }}
                options={gradeList}
                size='small'
                fullWidth
                label='Grade'
                value={filters.grade}
                onChange={(_, v) => {
                  handleFilterChange(v, 'grade');
                }}
                renderInput={(params) => <TextField {...params} label='Grade' />}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1.5rem', flexWrap: 'wrap', marginTop: '1.5rem' }}>
          {filteredList?.length === 0 && <Heading3 underline={false}>No sessions found</Heading3>}

          {filteredList &&
            filteredList.length > 0 &&
            filteredList.map((session, index) => (
              <SessionListCard key={index} event={event} session={session} sessionSummaryLink={fields.sessionSummaryLink} />
            ))}
        </Box>

        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0 || eventInstanceListQuery.isFetching} />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationSessionList;
