// package imports
import { useTheme } from '@emotion/react';
import { PieChart } from '@mui/x-charts/PieChart';

// local imports
import { Benchmarks } from '../StyledComponents';
import BenchmarkGroup from './BenchmarkGroup';
import { calcPercent } from 'services/numberUtils';

const CoorectionBenchmarkGroup = ({ observationMetrics }) => {
  const theme = useTheme();

  return (
    <BenchmarkGroup type='Correction' total={observationMetrics.corrections?.length || 0}>
      <Benchmarks>
        <div className='four-column-section' style={{ marginBottom: '1rem' }}>
          <div>Corrective Prompts</div>
          <div className='right-align'>{observationMetrics.correctivePrompts}</div>
          <div className='right-align'>{calcPercent(observationMetrics.correctivePrompts, observationMetrics.corrections.length)}%</div>
          <div className='pie' style={{ gridRow: '1 / 5', gridColumn: '4 / 4' }}>
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: observationMetrics.correctivePrompts,
                      label: 'Corrective Prompts',
                      color: theme.palette.obsCorrection.main,
                    },
                    {
                      id: 1,
                      value: observationMetrics.correctiveStrategies,
                      label: 'Corrective Strategies',
                      color: theme.palette.obsPraise.main,
                    },
                    {
                      id: 2,
                      value: observationMetrics.correctiveGuidedSelfCorrections,
                      label: 'Guided Self Correction',
                      color: theme.palette.gold.muted,
                    },
                    {
                      id: 3,
                      value: observationMetrics.correctiveTeaching,
                      label: 'Corrective Teaching',
                      color: theme.palette.obsPrevention.main,
                    },
                  ],
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              slotProps={{ legend: { hidden: true } }}
              width={80}
              height={80}
            />
          </div>
          <div>Corrective Strategies</div>
          <div className='right-align'>{observationMetrics.correctiveStrategies}</div>
          <div className='right-align'>{calcPercent(observationMetrics.correctiveStrategies, observationMetrics.corrections.length)}%</div>
          <div>Guided Self Correction</div>
          <div className='right-align'>{observationMetrics.correctiveGuidedSelfCorrections}</div>
          <div className='right-align'>{calcPercent(observationMetrics.correctiveGuidedSelfCorrections, observationMetrics.corrections.length)}%</div>
          <div>Corrective Teaching</div>
          <div className='right-align'>{observationMetrics.correctiveTeaching}</div>
          <div className='right-align'>{calcPercent(observationMetrics.correctiveTeaching, observationMetrics.corrections.length)}%</div>
        </div>

        <div className='four-column-section'>
          <div>Behaviors Corrected</div>
          <div className='right-align'>{observationMetrics.correctiveCorrected}</div>
          <div className='right-align'>{calcPercent(observationMetrics.correctiveCorrected, observationMetrics.corrections.length)}%</div>
          <div className='pie' style={{ gridRow: '1 / 3', gridColumn: '4 / 4' }}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: observationMetrics.correctiveCorrected, label: 'Behaviors Corrected', color: theme.palette.gold.muted },
                    {
                      id: 1,
                      value: observationMetrics.correctiveNotCorrected,
                      label: 'Behaviors Not Corrected',
                      color: theme.palette.obsPraise.light,
                    },
                  ],
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              slotProps={{ legend: { hidden: true } }}
              width={60}
              height={60}
            />
          </div>
          <div>Behaviors Not Corrected</div>
          <div className='right-align'>{observationMetrics.correctiveNotCorrected}</div>
          <div className='right-align'>{calcPercent(observationMetrics.correctiveNotCorrected, observationMetrics.corrections.length)}%</div>
        </div>

        <div className='three-plus-one'>
          <div className='three-column-section'>
            <div>Skill Languages</div>
            <div className='right-align'>{observationMetrics.correctiveSkillLanguages}</div>
            <div className='right-align'>{calcPercent(observationMetrics.correctiveSkillLanguages, observationMetrics.corrections.length)}%</div>
            <div>Specificity</div>
            <div className='right-align'>{observationMetrics.correctiveSpecificity}</div>
            <div className='right-align'>{calcPercent(observationMetrics.correctiveSpecificity, observationMetrics.corrections.length)}%</div>
          </div>
          <div className='one-column-section mo'>
            <div className='mo-title'>Missed Opportunities</div>
            <div className='mo-value'>{observationMetrics.correctiveMO}</div>
          </div>
        </div>
      </Benchmarks>
    </BenchmarkGroup>
  );
};

export default CoorectionBenchmarkGroup;
