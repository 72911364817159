import React, { useEffect, useState } from 'react';
import { renderHTML } from '@agility/nextjs';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import { logClientException, logServerException } from 'appinsights/clientAppInsights';
import { useSession } from 'next-auth/react';
import { Backdrop } from '@mui/material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  BannerContainer,
  Breadcrumb,
  BulletSection,
  FullWidthHR,
  HeadingAndList,
  SectionSeperator,
  SubHeadingContainer,
  SubHeadingSeperator,
  SuggestedMaterials,
  TitledHtmlListArea,
  TitledHtmlListContainer,
} from './LessonPlanDetailComponents';
import Heading4 from '../common/subcomponents/Typography/Heading4';
import SectionFullWidthGrey from '../common/SectionFullWidthGrey';
import PleaseWait from '../common/PleaseWait';
import NewsLetterComponent from './NewsletterComponent';
import { logResourceAccess } from 'services/gatedResourceUtils';
import BreadcrumbTrail from './BreadcrumbTrail';
import { isCustomer } from 'services/subscriptionUtils';
import Link from 'next/link';

const LessonPlanDetails = (props) => {
  const {
    customData,
    module: { fields },
  } = props;
  const lessonPlan = customData.lessonPlan;
  const theme = useTheme();
  const { data: session, status } = useSession();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        await logResourceAccess('Lesson Plan', lessonPlan?.fields?.title, lessonPlan.fields.slug, 'lesson_plan_detail_access');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        logClientException(error);
      }
    };

    if (status === 'authenticated' && isCustomer(session?.user)) {
      init();
    } else if ((status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && typeof window !== 'undefined') {
      window.onscroll = () => {
        let y = window.scrollY;
        if (y > 500) {
          window.scrollTo(0, 500);
        }
      };
      return () => {
        window.onscroll = null;
      };
    }
  }, [status]);

  const breakCrumbArgs = () => {
    const args = { fields: { 'borderColor': 'A400', hideComponent: 'never', textColor: 'medium', breadcrumbList: [] } };
    args.fields.breadcrumbList.push({ contentID: 0, fields: { breadcrumbUrl: { href: '/', text: 'Home' }, 'name': 'Home', order: 0 } });
    args.fields.breadcrumbList.push({
      contentID: 1,
      fields: {
        breadcrumbUrl: { href: '/premium-resources/lesson-plan', text: 'Premium Lesson Plans' },
        'name': 'Premium Lesson Plans',
        order: 1,
      },
    });
    args.fields.breadcrumbList.push({
      contentID: 2,
      fields: { breadcrumbUrl: { href: '', text: 'Plan Details' }, 'name': 'Plan Details', order: 2 },
    });
    return args;
  };

  const logLessonPlanDownload = async () => {
    await logResourceAccess('Lesson Plan', lessonPlan?.fields?.title, lessonPlan.fields?.downloadLink?.href, 'lesson_plan_download');
  };

  if (status === 'loading') {
    return <PleaseWait isLoading={true} />;
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mt: '1rem',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { xs: status === 'unauthenticated' ? '540px' : '375px', sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        {(status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && (
          <Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
              marginTop: { xs: '100px', sm: '150px' },
            }}
            open={true}
          >
            <div style={{ width: '100%' }}>
              <NewsLetterComponent
                cTAText1={fields.cTAText1}
                cTAText2={fields.cTAText2}
                unsubscribeText={fields.unsubscribeText}
                cTAlink={fields.cTAlink}
                cTALinkColor={fields.cTALinkColor}
                backgroundColor={fields.backgroundColor}
                accentImage={fields.accentImage}
              />
            </div>
          </Backdrop>
        )}
        <PleaseWait isLoading={loading} />
        <BannerContainer>
          {lessonPlan?.fields?.image?.url && (
            <img
              src={`${lessonPlan?.fields?.image?.url}`}
              alt={lessonPlan?.fields?.image?.label}
              style={{ opacity: lessonPlan?.fields?.imageOpacity, position: 'absolute', top: 0, right: 0 }}
            />
          )}
          <div style={{ alignSelf: 'center', position: 'relative' }}>
            <div className='lessonText'>Lesson Plan - {lessonPlan?.fields?.lessonPlanNumber}</div>
            <div className='title'>{lessonPlan?.fields?.title}</div>
          </div>
        </BannerContainer>

        <FullWidthHR />
      </Box>
      <BreadcrumbTrail module={breakCrumbArgs()} />

      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { xs: status === 'unauthenticated' ? '540px' : '375px', sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <SubHeadingContainer>
          <Breadcrumb>
            <Link href='/premium-resources/lesson-plan' passHref legacyBehavior>
              <a rel='noopener noreferrer' title='back to lesson plans'>
                <ArrowBackIcon />
                Premium Lesson Plans
              </a>
            </Link>
          </Breadcrumb>

          <span style={{ textTransform: 'uppercase' }}>
            <a
              onClick={() => logLessonPlanDownload()}
              href={lessonPlan?.fields?.downloadLink?.href}
              target='_blank'
              rel='noreferrer'
              title={lessonPlan?.fields?.downloadLink?.text}
            >
              <PictureAsPdfOutlinedIcon sx={{ color: theme.palette.warmRed.main }} /> {'  '}
              {lessonPlan?.fields?.downloadThisLessonLabel}
            </a>
          </span>
        </SubHeadingContainer>

        {/* <SubHeadingSeperator /> */}

        <HeadingAndList>
          <div className='title'>Suggested Materials: </div>
          <SuggestedMaterials dangerouslySetInnerHTML={renderHTML(lessonPlan?.fields?.suggestedMaterials)}></SuggestedMaterials>
          <SectionSeperator />
          <div className='title'>Lesson Objectives: </div>
          <BulletSection dangerouslySetInnerHTML={renderHTML(lessonPlan?.fields?.lessonObjectives)}></BulletSection>
          <SectionSeperator />
          <div className='title'>Essential Questions: </div>
          <BulletSection dangerouslySetInnerHTML={renderHTML(lessonPlan?.fields?.essentialQuestions)}></BulletSection>
          <SectionSeperator />
          <div className='title'>Lesson Activities and Assessments: </div>
          <BulletSection dangerouslySetInnerHTML={renderHTML(lessonPlan?.fields?.activitiesandAssessments)}></BulletSection>
        </HeadingAndList>

        <SectionFullWidthGrey>
          <TitledHtmlListContainer>
            <TitledHtmlListArea title={'Skill Steps'} htmlList={lessonPlan?.fields?.skillSteps} />

            {lessonPlan?.fields?.skillStepReasonSeperator && (
              <img
                src={lessonPlan?.fields?.skillStepReasonSeperator?.url}
                alt={lessonPlan?.fields?.skillStepReasonSeperator?.label}
                style={{ margin: '0 1rem' }}
              />
            )}

            <TitledHtmlListArea title={'Reasons'} htmlList={lessonPlan?.fields?.reasons} />
          </TitledHtmlListContainer>

          {lessonPlan?.fields?.skillExtensionLabel && lessonPlan?.fields?.skillExtensionValue && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '2rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  border: `2px dashed ${theme.palette.common.black}`,
                  borderRadius: '4px',
                  width: '400px',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    fontSize: '22px',
                    fontWeight: 700,
                    lineHeight: '46px',
                    color: theme.palette.text.medium,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {lessonPlan?.fields?.skillExtensionLabel}
                </div>
                <hr style={{ width: '100%', borderTopWidth: '.5px', borderColor: theme.palette.grey.A400 }} />
                <div
                  style={{
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '46px',
                    color: theme.palette.text.medium,
                    fontStyle: 'italic',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {lessonPlan?.fields?.skillExtensionValue}
                </div>
              </div>
            </div>
          )}
        </SectionFullWidthGrey>

        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '1rem 0' }}>
          <Heading4>{lessonPlan?.fields?.activitiesLabel}</Heading4>
        </div>
        <HeadingAndList>
          {lessonPlan?.fields?.activitiesList?.items?.map((activity, index) => {
            return (
              <>
                <div className='title'>{activity?.fields?.title} </div>
                <BulletSection dangerouslySetInnerHTML={renderHTML(activity?.fields?.richTextValue)}></BulletSection>
                <SectionSeperator />
              </>
            );
          })}
        </HeadingAndList>

        <SubHeadingSeperator />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: '2rem',
            marginBottom: '2rem',
            textTransform: 'uppercase',
          }}
        >
          <a
            onClick={() => logLessonPlanDownload()}
            href={lessonPlan?.fields?.downloadLink?.href}
            target='_blank'
            rel='noreferrer'
            title={lessonPlan?.fields?.downloadLink?.text}
          >
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <PictureAsPdfOutlinedIcon style={{ color: theme.palette.warmRed.main }} fontSize='large' /> {'  '}
              <div>{lessonPlan?.fields?.downloadandPrintText}</div>
            </div>
          </a>
        </div>

        {/* <pre>{JSON.stringify(customData, null, 2)}</pre> */}
      </Box>
    </>
  );
};

// This is called during server-side rendering (not part of NextJS).
LessonPlanDetails.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem }) => {
  const api = agility;

  const lessonPlan = dynamicPageItem;

  try {
    //get activities list
    if (lessonPlan?.fields?.activitiesList?.referencename) {
      let raw = await api.getContentList({
        referenceName: lessonPlan?.fields?.activitiesList.referencename,
        languageCode,
        take: 50,
        contentLinkDepth: 5,
        expandAllContentLinks: true,
      });

      if (raw) {
        lessonPlan.fields.activitiesList = raw;
      }
    }

    return {
      lessonPlan,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default LessonPlanDetails;
