import React from 'react';

export default function SocialMediaLink(props) {
  const { name, url, target, icon } = props;

  return (
    <React.Fragment>
      {url && url.length && (
        <li style={{ padding: '0 5px' }}>
          <a href={url} title='Follow Boys Town on ${name}' target={target}>
            {icon}
          </a>
        </li>
      )}
    </React.Fragment>
  );
}
