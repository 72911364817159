import styled from '@emotion/styled';

const SectionFullWidthGrey = styled('div')(({ theme }) => ({
  width: '100vw',
  position: 'relative',
  left: 'calc(-50vw + 50%)',
  backgroundColor: theme.palette.background.lightGrey,
  padding: '2rem',
  marginTop: '2rem',
}));

export default SectionFullWidthGrey;
