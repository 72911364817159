import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const CircleCheckIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M0,10C0,4.48,4.48,0,10,0s10,4.48,10,10-4.48,10-10,10S0,15.52,0,10Zm14.52-1.73c.43-.43,.43-1.12,0-1.55-.43-.43-1.12-.43-1.55,0l-4.23,4.23-1.73-1.73c-.43-.43-1.12-.43-1.55,0-.43,.43-.43,1.12,0,1.55l2.5,2.5c.43,.43,1.12,.43,1.55,0l5-5Z' />
    </SvgIcon>
  );
};

export const CircleMoreIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox='24 1 24 22'>
      <circle cx='34.12' cy='10' r='10'></circle>
      <path
        fill='#fff'
        d='M28.29,9.28h-1.48c.07-.81,.28-1.56,.62-2.28l1.28,.72c-.21,.48-.35,1-.41,1.55Zm.41,3l-1.28,.72c-.35-.71-.55-1.47-.62-2.28h1.48c.07,.55,.21,1.07,.41,1.55Zm.72-5.83l-1.28-.72c.46-.64,1.02-1.21,1.69-1.69l.72,1.28c-.44,.35-.82,.72-1.14,1.14Zm1.14,8.25l-.72,1.28c-.64-.48-1.21-1.05-1.69-1.69l1.28-.72c.35,.44,.72,.82,1.14,1.14Zm1.28-10.11l-.72-1.28c.71-.35,1.47-.55,2.28-.62v1.48c-.55,.07-1.07,.21-1.55,.41Zm1.55,11.25v1.48c-.81-.07-1.56-.28-2.28-.62l.72-1.28c.48,.21,1,.35,1.55,.41Zm1.45-6.14l2.73,2.73-1.04,1.04-3.14-3.14V6.31h1.45v3.38Zm0-5.52v-1.48c.8,.07,1.56,.28,2.28,.62l-.72,1.28c-.48-.21-1-.35-1.55-.41Zm0,11.66v1.48c.8-.07,1.56-.28,2.28-.62l-.72-1.28c-.48,.21-1,.35-1.55,.41Zm2.83-10.52l.72-1.28c.64,.46,1.21,1.01,1.69,1.66l-1.28,.76c-.32-.44-.7-.82-1.14-1.14Zm1.14,8.25l1.28,.72c-.46,.64-1.01,1.21-1.66,1.69l-.76-1.28c.44-.32,.82-.7,1.14-1.14Zm.72-5.83l1.28-.72c.35,.71,.55,1.47,.62,2.28h-1.48c-.07-.55-.21-1.07-.41-1.55Zm.41,3h1.48c-.07,.81-.28,1.56-.62,2.28l-1.28-.72c.21-.48,.35-1,.41-1.55Z'
      ></path>
    </SvgIcon>
  );
};

export const CircleOutlineIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox='47.48 8.212 20 19.999'>
      <g transform='matrix(1, 0, 0, 1, 0.000001, 8.211931)'>
        <g transform='translate(0 -0.001)'>
          <path d='M57.48,1.2c4.86,0,8.8,3.94,8.8,8.8s-3.94,8.8-8.8,8.8-8.8-3.94-8.8-8.8c0-4.86,3.94-8.8,8.8-8.8M57.48,0C51.96,0,47.48,4.48,47.48,10s4.48,10,10,10,10-4.48,10-10S63,0,57.48,0' />
        </g>
      </g>
    </SvgIcon>
  );
};

export const CompletedIcon = (props) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 16C3.61112 16 2.43056 15.5139 1.45832 14.5417C0.486108 13.5694 0 12.3889 0 11V5C0 3.61112 0.486108 2.43056 1.45832 1.45833C2.43056 0.486109 3.61112 0 5 0H11C12.3889 0 13.5694 0.486109 14.5417 1.45833C15.5139 2.43056 16 3.61112 16 5V11C16 12.3889 15.5139 13.5694 14.5417 14.5417C13.5694 15.5139 12.3889 16 11 16H5ZM7 11.3077L12.3077 6L11.6 5.2923L7 9.8923L4.8 7.6923L4.0923 8.4L7 11.3077ZM5 15H11C12.1 15 13.0417 14.6083 13.825 13.825C14.6083 13.0417 15 12.1 15 11V5C15 3.9 14.6083 2.95833 13.825 2.175C13.0417 1.39167 12.1 1 11 1H5C3.9 1 2.95833 1.39167 2.175 2.175C1.39167 2.95833 1 3.9 1 5V11C1 12.1 1.39167 13.0417 2.175 13.825C2.95833 14.6083 3.9 15 5 15Z'
        fill='#1C1B1F'
      />
    </svg>
  );
};

export const ContinueIcon = (props) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.8846 12.0577H6.88463V5.9423H5.8846V12.0577ZM9.11537 12.0577L13.7115 9L9.11537 5.9423V12.0577ZM9.00335 18C7.7588 18 6.58872 17.7638 5.4931 17.2915C4.39748 16.8192 3.44444 16.1782 2.63397 15.3685C1.82352 14.5588 1.18192 13.6066 0.70915 12.512C0.236383 11.4174 0 10.2479 0 9.00335C0 7.7588 0.236158 6.58872 0.708475 5.4931C1.18081 4.39748 1.82183 3.44444 2.63153 2.63398C3.44123 1.82353 4.39337 1.18192 5.48795 0.709151C6.58255 0.236384 7.75212 0 8.99665 0C10.2412 0 11.4113 0.236158 12.5069 0.708475C13.6025 1.18081 14.5556 1.82182 15.366 2.63152C16.1765 3.44122 16.8181 4.39337 17.2908 5.48795C17.7636 6.58255 18 7.75212 18 8.99665C18 10.2412 17.7638 11.4113 17.2915 12.5069C16.8192 13.6025 16.1782 14.5556 15.3685 15.366C14.5588 16.1765 13.6066 16.8181 12.512 17.2909C11.4174 17.7636 10.2479 18 9.00335 18ZM9 17C11.2333 17 13.125 16.225 14.675 14.675C16.225 13.125 17 11.2333 17 9C17 6.76667 16.225 4.875 14.675 3.325C13.125 1.775 11.2333 1 9 1C6.76667 1 4.875 1.775 3.325 3.325C1.775 4.875 1 6.76667 1 9C1 11.2333 1.775 13.125 3.325 14.675C4.875 16.225 6.76667 17 9 17Z'
        fill='#1C1B1F'
      />
    </svg>
  );
};

export const PlayCircleIcon = (props) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 12.5769L12.5769 9L7 5.42307V12.5769ZM9.00335 18C7.7588 18 6.58872 17.7638 5.4931 17.2915C4.39748 16.8192 3.44444 16.1782 2.63397 15.3685C1.82352 14.5588 1.18192 13.6066 0.70915 12.512C0.236383 11.4174 0 10.2479 0 9.00335C0 7.7588 0.236158 6.58872 0.708475 5.4931C1.18081 4.39748 1.82183 3.44444 2.63153 2.63398C3.44123 1.82353 4.39337 1.18192 5.48795 0.709151C6.58255 0.236384 7.75212 0 8.99665 0C10.2412 0 11.4113 0.236158 12.5069 0.708475C13.6025 1.18081 14.5556 1.82182 15.366 2.63152C16.1765 3.44122 16.8181 4.39337 17.2908 5.48795C17.7636 6.58255 18 7.75212 18 8.99665C18 10.2412 17.7638 11.4113 17.2915 12.5069C16.8192 13.6025 16.1782 14.5556 15.3685 15.366C14.5588 16.1765 13.6066 16.8181 12.512 17.2909C11.4174 17.7636 10.2479 18 9.00335 18ZM9 17C11.2333 17 13.125 16.225 14.675 14.675C16.225 13.125 17 11.2333 17 9C17 6.76667 16.225 4.875 14.675 3.325C13.125 1.775 11.2333 1 9 1C6.76667 1 4.875 1.775 3.325 3.325C1.775 4.875 1 6.76667 1 9C1 11.2333 1.775 13.125 3.325 14.675C4.875 16.225 6.76667 17 9 17Z'
        fill='#1C1B1F'
      />
    </svg>
  );
};
