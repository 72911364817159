import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import { KitButton } from '@boystownorg/bi-cms-component-lib';

const OffTaskDialog = (props, styles) => {
  const theme = useTheme();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      closeConfirm();
    }
    return;
  };

  const setOffTaskStudentCount = (num) => {
    let updatedNumber = 0;
    if (props.offTaskNumber === '' || props.offTaskNumber < 0) {
      updatedNumber = 0;
    } else {
      updatedNumber = parseInt(props.offTaskNumber, 10) + num;
    }
    if (typeof updatedNumber !== 'number' || isNaN(updatedNumber) || updatedNumber < 0) {
      props.setOffTaskNumber(0);
      props.setOffTaskNumberError(true);
    } else {
      props.setOffTaskNumber(updatedNumber);
      props.setOffTaskNumberError(false);
    }
  };

  const updateOffTaskCount = (val) => {
    const updatedNumber = parseInt(val, 10);
    if (typeof updatedNumber !== 'number' || isNaN(updatedNumber) || updatedNumber < 0) {
      props.setOffTaskNumber('');
      props.setOffTaskNumberError(true);
    } else {
      props.setOffTaskNumber(updatedNumber);
      props.setOffTaskNumberError(false);
    }
  };

  return (
    <Dialog scroll='body' fullwidth onClose={handleClose} aria-labelledby='off-task-dialog' open={props.open}>
      <DialogTitle sx={{ backgroundColor: theme.palette['obsOff Task'].main, color: theme.palette.common.white, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            <PriorityHighIcon
              style={{ marginLeft: '10px', marginTop: '-3px', fontSize: '1.75rem', fontWeight: 600, color: theme.palette.common.white }}
            />{' '}
            Off Task Students
          </span>
          <IconButton
            onClick={() => {
              props.addToTimeline(true);
            }}
            sx={{ marginLeft: '3px' }}
            className='need-interaction'
          >
            <CloseIcon style={{ height: '30px', width: '30px', color: theme.palette.common.white }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <>
          <Box style={{ width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <label style={{ fontSize: '1.05rem' }}>Number of Off-Task Students</label>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '1rem',
                minWidth: '230px',
              }}
            >
              <IconButton onClick={() => setOffTaskStudentCount(-1)} style={{ marginTop: 10 }}>
                <ArrowBackIcon style={{ color: theme.palette.obsBlue.dark }} />
              </IconButton>
              <FormControl fullWidth size='small'>
                <TextField
                  placeholder='Enter a number'
                  InputLabelProps={{ shrink: true }}
                  sx={{ ...styles.select, marginTop: '.75rem' }}
                  InputProps={{ sx: { ...styles.select } }}
                  inputProps={{ min: 0, max: props.studentCount, type: 'number' }}
                  label=''
                  id='off-task-students'
                  error={props.offTaskNumberError}
                  size='small'
                  value={props.offTaskNumber}
                  onChange={(e) => {
                    // props.setOffTaskNumber(e.target.value);
                    updateOffTaskCount(e.target.value);
                    // if (isNaN(e.target.value) || e.target.value < 0) {
                    //   props.setOffTaskNumberError(true);
                    // } else {
                    //   props.setOffTaskNumberError(false);
                    // }
                  }}
                />
              </FormControl>
              <IconButton onClick={() => setOffTaskStudentCount(1)} style={{ marginTop: 10 }}>
                <ArrowForwardIcon style={{ color: theme.palette.obsBlue.dark }} />
              </IconButton>
            </Box>
            <Box sx={{ marginTop: '.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <KitButton
                  onClick={(e) => props.addToTimeline(false)}
                  size='sm'
                  style={{
                    width: '110px',
                    backgroundColor: theme.palette.gold.main,
                    marginTop: '1rem',
                  }}
                  disabled={props.offTaskNumber === '' || props.offTaskNumber < 0 || props.offTaskNumber > props.studentCount}
                >
                  <span>
                    <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
                  </span>
                </KitButton>
              </Box>
            </Box>
          </Box>
        </>
      </DialogContent>
      {/* <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}></DialogActions> */}
    </Dialog>
  );
};

OffTaskDialog.propTypes = {
  styles: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeConfirm: PropTypes.func.isRequired,
  observation: PropTypes.object.isRequired,
  offTaskNumber: PropTypes.string.isRequired,
  setOffTaskNumber: PropTypes.func.isRequired,
  offTaskNumberError: PropTypes.object.isRequired,
  setOffTaskNumberError: PropTypes.func.isRequired,
  addToTimeline: PropTypes.func.isRequired,
  studentCount: PropTypes.number.isRequired,
};

export default OffTaskDialog;

// export default function CustomizedDialogs(props) {
//   const { isOpen, onClose } = props;
//   const [open, setOpen] = React.useState(isOpen);

//   React.useEffect(() => {
//     setOpen(isOpen);
//   }, [isOpen]);

//   const handleClose = (event, reason) => {
//     if (reason !== 'backdropClick') {
//       setOpen(false);
//       onClose(false);
//     }
//     return;
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   return (
//     <React.Fragment>
//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby='customized-dialog-title'
//         open={open}
//         PaperComponent={DraggablePaperComponent}
//         hideBackdrop={true}
//         PaperProps={{ style: { pointerEvents: 'auto' } }}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
//           Modal title
//         </DialogTitle>
//         <IconButton
//           aria-label='close'
//           onClick={handleClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
//             consectetur ac, vestibulum at eros.
//           </Typography>
//           <Typography gutterBottom>
//             Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
//           </Typography>
//           <Typography gutterBottom>
//             Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui.
//             Donec ullamcorper nulla non metus auctor fringilla.
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleClose}>
//             Save changes
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </React.Fragment>
//   );
// }
