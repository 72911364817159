import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { DateTime } from 'luxon';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { renderHTML } from '@agility/nextjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@emotion/react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { generateUUID, generateUniqueId } from 'services/stringUtils';
import { PRODUCTS, COMMON_ROUTES } from 'services/constants';
import AcceptHostedCC from 'components/common/AcceptHostedCC';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import { Button, Grid, InputLabel, Typography, useMediaQuery } from '@mui/material';
import { getSessionStoreString } from 'services/storageUtils';
import { formatDate } from 'services/stringUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const PlanPurchase = ({ module, customData }) => {
  const { terms } = customData;
  const theme = useTheme();
  const router = useRouter();
  const { data: session, status } = useSession();
  const [currentUser, setCurrentUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [refId, setRefId] = useState(generateUniqueId(20));
  const appliedCouponCode = useRef(null);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [planToPurchase, setPlanToPurchase] = useState(null);
  const [agreeTos, setAgreeTos] = useState(false);
  const [agreeTosTimstamp, setAgreeTosTimestamp] = useState(null);
  const [tosOpen, setTosOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0); // 0 = not agreed to ToS,  1 = agree to ToS, 2 = collect payment
  const getLocalStoreString = (key) => {
    if (typeof window !== 'undefined') {
      return window?.localStorage?.getItem(key) || null;
    }
    return null;
  };
  const [clientType, setClientType] = React.useState('');
  const [renewalDate, setRenewalDate] = React.useState(null);
  const [newExpDate, setNewExpDate] = React.useState(null);

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);

  useEffect(() => {
    // Fetch client type from local storage, if available, otherwise 'educator'
    if (typeof window !== 'undefined' && window.localStorage && getLocalStoreString('client-type')) {
      setClientType(getLocalStoreString('client-type'));
    } else {
      setClientType('educator');
    }
    if (queryParams.get('renewalDate') && queryParams.get('renewalDate').length > 0) {
      // check query param before overwriting plan-to-purchase
      setRenewalDate(queryParams.get('renewalDate'));
      const purchase = {
        planName: queryParams.get('plan'),
        product: queryParams.get('product'),
        planPriceAmount: queryParams.get('price'),
        clientType: clientType,
      };
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('plan-to-purchase', JSON.stringify(purchase));
      }
    }
  }, [queryParams]);

  useEffect(() => {
    if (clientType && clientType.length > 0) {
      window.localStorage.setItem('client-type', clientType);
    }
  }, [clientType]);

  useEffect(() => {
    if (renewalDate && renewalDate.length > 0) {
      const renewParamLuxon = DateTime.fromMillis(+renewalDate);
      setNewExpDate(renewParamLuxon.plus({ years: 1 }).toJSDate().getTime());
    }
  }, [renewalDate]);

  useEffect(() => {
    const init = async () => {
      setErrorMessage(null);
      let plan = null;
      try {
        plan = JSON.parse(getLocalStoreString('plan-to-purchase'));
      } catch (error) {
        console.log('failed to get plan', error);
      }
      if (plan) {
        setPlanToPurchase(plan);
        const codeDiscount = getCouponCodeDiscount(appliedCouponCode.current);
        const price = parseFloat(plan?.planPriceAmount).toFixed(2) - codeDiscount.discount;
        setPurchaseAmount(parseFloat(price > 0 ? price : 0).toFixed(2));
      }
    };

    if (getLocalStoreString('plan-to-purchase')) {
      init();
    }
  }, [getLocalStoreString('plan-to-purchase')]);

  useEffect(() => {
    const init = async () => {
      try {
        setErrorMessage(null);
        setFetching(true);
        const res = await callApi(`/api/db/user?email=${session?.user?.email}`);
        setCurrentUser(res);
        setFetching(false);
      } catch (error) {
        logClientException(error);
        setErrorMessage(defaultErrorMessage);
        setFetching(false);
      }
    };
    if (status === 'authenticated') {
      init();
    }
  }, [status]);

  const resetState = () => {
    setErrorMessage(null);
    appliedCouponCode.current = null;
    clearLocalStore();
    setCouponCode('');
  };

  const clearLocalStore = () => {
    if (typeof window !== 'undefined') {
      window?.localStorage.removeItem('plan-to-purchase');
    }
  };

  const verifyCouponCode = async () => {
    try {
      setFetching(true);
      setErrorMessage(null);
      if (currentUser?.subscriptions?.find((s) => s.product === planToPurchase.product && s.coupon_code === couponCode) !== undefined) {
        setFetching(false);
        setErrorMessage('Previously Redeemed!');
        return;
      }
      // verify coupon code
      const res = await callApi(`/api/db/coupon-code-list?filter.code=${couponCode}`, 'GET');
      const code = res.results?.find((c) => c.code === couponCode && c.is_active);
      if (!code || code.product !== planToPurchase.product) {
        setFetching(false);
        setErrorMessage('Invalid Coupon Code!');
        return;
      }

      if (code.exp_date < Date.now()) {
        setFetching(false);
        setErrorMessage('Expired Coupon Code!');
        return;
      }

      if (code.max_usage > 0 && code.total_usage && code.total_usage >= code.max_usage) {
        setFetching(false);
        setErrorMessage('Sorry, this coupon code has already been used up!');
        return;
      }

      appliedCouponCode.current = code;

      if (appliedCouponCode.current.type === 'free_trial') {
        setCurrentStep(1);
        setPurchaseAmount(0);
        setFetching(false);
        return;
      }

      let tempAmount = purchaseAmount;
      const codeDiscount = getCouponCodeDiscount(appliedCouponCode.current, tempAmount);
      if (codeDiscount.success) {
        tempAmount = purchaseAmount - codeDiscount.discount;
        appliedCouponCode.current.discountAmount = codeDiscount.discount;
      } else {
        setFetching(false);
        setErrorMessage('Invalid Coupon Code Type!');
        return;
      }

      setPurchaseAmount(parseFloat(tempAmount > 0 ? tempAmount : 0).toFixed(2));

      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const applyCouponCode = async () => {
    if (!appliedCouponCode.current || !agreeTos) {
      return;
    }
    try {
      setFetching(true);

      if (appliedCouponCode.current.type === 'free_trial') {
        const renewParamLuxon = DateTime.fromMillis(+renewalDate);
        // add subscription to user record, etc.
        let body = {
          emailAddress: session?.user?.email,
          product: planToPurchase.product,
          status: 'active',
          end_date:
            renewalDate && renewalDate.length > 0
              ? renewParamLuxon.plus({ years: 1 }).plus({ days: appliedCouponCode.current.value }).getTime()
              : DateTime.now().plus({ days: appliedCouponCode.current.value }).toJSDate().getTime(),
          id: generateUUID(),
          coupon_code: appliedCouponCode.current.code,
          is_free_trial: true,
          transaction: {
            accountType: 'N/A',
            accountNumber: 'N/A',
            orderDescription: PRODUCTS.find((p) => p.id == planToPurchase.product)?.trans_desc,
            totalAmount: '0.00',
            dateTime: renewalDate ? renewalDate.toFormat('M/d/yyyy h:mm:ss a') : DateTime.now().toFormat('M/d/yyyy h:mm:ss a'),
            refId: refId,
          },
        };
        if (renewalDate && renewalDate.length > 0) {
          body.start_date = DateTime.fromMillis(+renewalDate).plus({ days: 1 }).toJSDate().getTime();
          body.end_date = DateTime.fromMillis(+renewalDate).plus({ days: 1 }).plus({ days: appliedCouponCode.current.value }).toJSDate().getTime();
        }

        if (getSessionStoreString('utm_source')) {
          body.utm_source = getSessionStoreString('utm_source');
        }
        if (getSessionStoreString('utm_medium')) {
          body.utm_medium = getSessionStoreString('utm_medium');
        }
        if (getSessionStoreString('utm_campaign')) {
          body.utm_campaign = getSessionStoreString('utm_campaign');
        }
        if (getSessionStoreString('utm_content')) {
          body.utm_content = getSessionStoreString('utm_content');
        }

        await callApi('/api/db/user-subscription', 'POST', body);
        if (appliedCouponCode.current.max_usage > 0) {
          // increment usage of code
          await callApi('/api/db/coupon-code-used', 'PUT', { code: appliedCouponCode.current.code });
        }
        addPurchaseEvent(body.id, 0, body.coupon_code, 0, PRODUCTS.find((p) => p.id === planToPurchase.product).trans_desc);
        setFetching(false);
        redirectAfterPurchase();
        return;
      }

      if (purchaseAmount <= 0) {
        // add subscription without payment....
        let body = {
          emailAddress: session?.user?.email,
          product: planToPurchase.product,
          status: 'active',
          // end_date: DateTime.now().plus({ years: 1 }).plus({ days: -1 }).toJSDate().getTime(),
          end_date: newExpDate && newExpDate.length > 0 ? newExpDate : DateTime.now().plus({ years: 1 }).plus({ days: -1 }).toJSDate().getTime(),
          id: generateUUID(),
          coupon_code: appliedCouponCode?.current?.code,
          discount: appliedCouponCode.current.discountAmount,
          transaction: {
            accountType: 'N/A',
            accountNumber: 'N/A',
            orderDescription: PRODUCTS.find((p) => p.id == planToPurchase.product)?.trans_desc,
            totalAmount: '0.00',
            dateTime: DateTime.now().toFormat('M/d/yyyy h:mm:ss a'),
            refId: refId,
          },
        };
        if (renewalDate && renewalDate.length > 0) {
          body.start_date = DateTime.fromMillis(+renewalDate).plus({ days: 1 }).toJSDate().getTime();
          body.end_date = DateTime.fromMillis(+renewalDate).plus({ years: 1 }).toJSDate().getTime();
        }
        if (getSessionStoreString('utm_source')) {
          body.utm_source = getSessionStoreString('utm_source');
        }
        if (getSessionStoreString('utm_medium')) {
          body.utm_medium = getSessionStoreString('utm_medium');
        }
        if (getSessionStoreString('utm_campaign')) {
          body.utm_campaign = getSessionStoreString('utm_campaign');
        }
        if (getSessionStoreString('utm_content')) {
          body.utm_content = getSessionStoreString('utm_content');
        }

        await callApi('/api/db/user-subscription', 'POST', body);
        if (appliedCouponCode?.current && appliedCouponCode?.current?.max_usage > 0) {
          // increment usage of code
          await callApi('/api/db/coupon-code-used', 'PUT', { code: appliedCouponCode?.current?.code });
        }
        addPurchaseEvent(body.id, body.transaction.totalAmount, body.coupon_code, body.discount, body.transaction.orderDescription);
      }
      redirectAfterPurchase();
      setFetching(false);
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const getCouponCodeDiscount = (code, purchaseAmount) => {
    let discount = 0;
    if (!code) {
      // no discount
    } else if (code.type === 'discount') {
      discount = code.value;
    } else if (code.type === 'percent_off') {
      discount = purchaseAmount * (code.value / 100);
    } else {
      return { success: false, discount: discount.toFixed(2) };
    }
    return { success: true, discount };
  };

  const onAcceptHostedPaymentComplete = async (success, result) => {
    console.log(success, result);
    try {
      if (success) {
        setFetching(true);
        setErrorMessage(null);
        // add subscription to user record, etc.
        let body = {
          emailAddress: session?.user?.email,
          product: planToPurchase.product,
          status: 'active',
          end_date: newExpDate && newExpDate.length > 0 ? newExpDate : DateTime.now().plus({ years: 1 }).plus({ days: -1 }).toJSDate().getTime(),
          id: generateUUID(),
          coupon_code: appliedCouponCode?.current?.code,
          transaction: result,
          agreedToTermsTimestamp: agreeTosTimstamp,
        };

        if (renewalDate && renewalDate.length > 0) {
          body.start_date = DateTime.fromMillis(+renewalDate).plus({ days: 1 }).toJSDate().getTime();
          body.end_date = DateTime.fromMillis(+renewalDate).plus({ years: 1 }).toJSDate().getTime();
        }

        const confirmEndDate = formatDate(body.end_date);

        if (getSessionStoreString('utm_source')) {
          body.utm_source = getSessionStoreString('utm_source');
        }
        if (getSessionStoreString('utm_medium')) {
          body.utm_medium = getSessionStoreString('utm_medium');
        }
        if (getSessionStoreString('utm_campaign')) {
          body.utm_campaign = getSessionStoreString('utm_campaign');
        }
        if (getSessionStoreString('utm_content')) {
          body.utm_content = getSessionStoreString('utm_content');
        }

        if (appliedCouponCode?.current?.discountAmount) {
          body.discount = appliedCouponCode?.current?.discountAmount;
        }
        await callApi('/api/db/user-subscription', 'POST', body);
        if (appliedCouponCode?.current && appliedCouponCode?.current?.max_usage > 0) {
          // increment usage of code
          await callApi('/api/db/coupon-code-used', 'PUT', { code: appliedCouponCode?.current?.code });
        }
        addPurchaseEvent(body.id, body.transaction.totalAmount, body.coupon_code, body.discount, body.transaction.orderDescription);
        const confirmMsg = 'Your subscription to ' + body.transaction.orderDescription + ' is now active until ' + confirmEndDate.toString() + '.';
        setConfirmMessage(confirmMsg);
        setTimeout(() => {
          redirectAfterPurchase();
        }, 5000);
      }
    } catch (error) {
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const redirectAfterPurchase = () => {
    resetState();
    // redirect to ....
    const path = PRODUCTS.find((p) => p.id === planToPurchase.product)?.homePage ?? '/';
    if (typeof window !== 'undefined') {
      // reload the page so it picks up the sub
      window.location.href = `${window.location.origin}${path}`;
    } else {
      router.push(PRODUCTS.find((p) => p.id === planToPurchase.product)?.homePage ?? '/');
    }
  };

  const addPurchaseEvent = (transId, amount, couponCode, discount, productName) => {
    addDataLayerEvent({ 'ecommerce': null });
    addDataLayerEvent({
      'event': 'purchase',
      ecommerce: {
        transaction_id: transId,
        value: amount,
        currency: 'USD',
        coupon: couponCode,
        items: [
          {
            item_id: productName,
            item_name: productName,
            coupon: couponCode,
            discount: discount,
            index: 0,
            price: amount,
            quantity: 1,
          },
        ],
      },
    });
  };

  const onAcceptHostedCancel = () => {
    resetState();
    router.push(COMMON_ROUTES.PricingPage);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const handleConfirmClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmMessage(null);
  };

  if (!session?.user) {
    if (typeof window !== 'undefined') {
      router.push('/signin');
    }
  }

  if (!planToPurchase) {
    return (
      <Frame>
        <Box
          sx={{
            mt: '50px',
            mb: '100px',
            fontSize: '1.3rem',
          }}
        >
          Sorry. Nothing found to purchase. Check out the{' '}
          <Link href={COMMON_ROUTES.PricingPage} passHref legacyBehavior>
            <StyledLink>plan options</StyledLink>
          </Link>
          !
        </Box>
      </Frame>
    );
  }

  return (
    <>
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={confirmMessage} onClose={handleConfirmClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleConfirmClose} severity='success' sx={{ width: '100%' }}>
          {confirmMessage}
        </Alert>
      </Snackbar>

      <Frame>
        <Box
          sx={{
            margin: '50px 30px',
          }}
        >
          <Heading3 color='#000000' underline={false} uppercase={true}>
            Complete Your Purchase
          </Heading3>
        </Box>

        <Grid container sx={{ width: '80%', maxWidth: '1000px' }}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex' }}>
              <img
                style={{ height: '37px', marginRight: '10px' }}
                alt='purchase icon'
                src='https://content.liftwithboystown.org/icons/purchase-order.svg'
              />
              <Typography component='h5' fontSize='27px' fontWeight='600' lineHeight='33.94px'>
                Review Your Order
              </Typography>
            </Box>
          </Grid>
          <Row1CellAWrapper container item xs={12} md={8} sx={{ marginTop: { xs: '20px', lg: 'inherit' } }}>
            <Row1CellA>
              <Grid item xs={12} align='center'>
                <Box
                  sx={{
                    backgroundColor: theme.palette.tertiary.mainLight,
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '21px',
                    fontWeight: 400,
                    lineHeight: '26.4px',
                    color: theme.palette.primary.main,
                    padding: '7px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <span>
                    {PRODUCTS.find((p) => p.id === planToPurchase?.product)?.name} {planToPurchase?.planName}
                  </span>
                  <span style={{ fontWeight: 700 }}>
                    ${purchaseAmount}
                    <sup style={{ display: appliedCouponCode.current ? '' : 'none' }}>*</sup>
                  </span>
                </Box>
                <span
                  style={{
                    float: 'right',
                    fontSize: '13px',
                    lineHeight: '16.34px',
                    fontWeight: 400,
                    display: appliedCouponCode.current ? 'inline' : 'none',
                  }}
                >
                  <sup>*</sup> Promo Code Applied
                </span>
              </Grid>

              <Grid item xs={12} sx={{ mt: '10px' }}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <InputLabel shrink={false} htmlFor={'cc-coupon-code'} sx={{ marginLeft: '12px', marginTop: '18px', display: 'inline-block' }}>
                      <Typography component='span' sx={{ color: theme.palette.primary.main, fontSize: '21px', fontWeight: 600 }}>
                        Have a promo code?
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                      size='small'
                      value={couponCode}
                      fullWidth
                      margin='normal'
                      id='cc-coupon-code'
                      type='text'
                      variant='standard'
                      onChange={(e) => setCouponCode(e.target.value)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      sx={{
                        borderRadius: '4px',
                        width: '100px',
                        height: '30px',
                        color: '#fff',
                        marginLeft: '8px',
                      }}
                      variant='contained'
                      size='small'
                      onClick={() => verifyCouponCode()}
                      color='secondary'
                      disabled={!couponCode || couponCode.length === 0 || appliedCouponCode.current}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: '10px' }}>
                <FormControlLabel
                  //sx={{ mb: agreeTos ? '10px' : '75px', paddingLeft: '12px' }}
                  sx={{ paddingLeft: '12px' }}
                  control={
                    <Checkbox
                      color='default'
                      checked={agreeTos}
                      onChange={(e) => {
                        setAgreeTos(e.target.checked);
                        setAgreeTosTimestamp(new Date().getTime());
                        setCurrentStep(e.target.checked ? 1 : 0);
                      }}
                    />
                  }
                  label={
                    <ToSLabel>
                      I have read and understand the{' '}
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          setTosOpen(true);
                        }}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        terms and conditions.
                      </span>
                    </ToSLabel>
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '35px', marginTop: '20px' }}>
                <Button
                  sx={{
                    borderRadius: '4px',
                    backgroundColor: theme.palette.primary.light,
                    color: '#fff',
                    width: '177px',
                    height: '50px',
                  }}
                  variant='contained'
                  size='small'
                  disabled={!agreeTos || currentStep === 2}
                  onClick={() => {
                    if (purchaseAmount <= 0 && agreeTos) {
                      applyCouponCode();
                    } else {
                      setCurrentStep(2);
                    }
                  }}
                >
                  {purchaseAmount <= 0 && agreeTos ? 'Continue' : 'Next'}
                </Button>
              </Grid>
            </Row1CellA>
          </Row1CellAWrapper>

          {planToPurchase && session?.user && purchaseAmount > 0 && agreeTos && currentStep === 2 && (
            <>
              <Grid item xs={12} sx={{ marginBottom: '35px' }}>
                <hr style={{ border: `1px dashed ${theme.palette.primary.main}` }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex' }}>
                  <img
                    style={{ height: '37px', marginRight: '10px' }}
                    alt='purchase-payment icon'
                    src='https://content.liftwithboystown.org/icons/purchase-payment.svg'
                  />
                  <Typography component='h5' fontSize='27px' fontWeight='600' lineHeight='33.94px'>
                    Payment
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <AcceptHostedCC
                  email={session?.user?.email}
                  amount={purchaseAmount}
                  product={planToPurchase.product}
                  refId={refId}
                  orderDescription={PRODUCTS.find((p) => p.id === planToPurchase?.product)?.trans_desc}
                  onComplete={onAcceptHostedPaymentComplete}
                  onCancel={onAcceptHostedCancel}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Frame>
      <PleaseWait isLoading={fetching} />
      <Dialog maxWidth='sm' scroll='paper' fullWidth={false} open={tosOpen} onClose={() => setTosOpen(false)}>
        <DialogContent>
          <div className='prose' style={{ marginTop: '-50px' }} dangerouslySetInnerHTML={renderHTML(terms)}></div>
        </DialogContent>
        <DialogActions>
          <KitButton onClick={() => setTosOpen(false)} size='sm' round color='success'>
            OK
          </KitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Frame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Row1CellAWrapper = styled(Grid)`
  justify-content: center;
  @media screen and (min-width: 1400px) {
    justify-content: center;
  }
`;

const Row1CellA = styled.div`
  width: 297px;
  margin-left: 55px;
  @media screen and (min-width: 450px) {
    width: 595px;
  }
  @media screen and (min-width: 992px) {
    width: 616px;
  }
  @media screen and (min-width: 1200) {
    width: 610px;
  }
`;

const ToSLabel = styled.span`
  font-size: 16px;
  line-height: 20.11px;
  font-weight: 400;
  @media screen and (max-width: 400px) {
    font-size: 14px;
    line-height: 27.6px;
  }
`;

const StyledLink = styled('a')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
}));

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  fontSize: '28px',
  lineHeight: '32px',
  width: '100%',
}));

PlanPurchase.getCustomInitialProps = async ({ agility, languageCode, locale }) => {
  const api = agility;

  let terms = null;

  try {
    // fetch terms-of-use page and grab the content from the first RichTextArea
    let page = await api.getPage({
      pageID: 100,
      locale: languageCode,
    });

    if (page?.zones?.MainContentZone?.length > 0) {
      const mod = page?.zones?.MainContentZone.find((m) => m.module === 'RichTextArea');
      if (mod?.item?.fields?.textblob) {
        terms = mod?.item?.fields?.textblob;
      }
    } else {
      return null;
    }
  } catch (error) {
    if (console) console.error('Could not fetch terms page.', error);
    return null;
  }

  return {
    terms,
  };
};
export default PlanPurchase;
