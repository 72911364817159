import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  Snackbar,
  InputLabel,
  Select,
} from '@mui/material';
import { states } from 'services/stringUtils';
import { ObsStyles } from './StyledComponents';
import { useTheme } from '@emotion/react';
import { useAddObservationEvent, useUpdateObservationEvent } from '../hooks/ObservationEventHooks';
import { useIsFetching } from '@tanstack/react-query';
import { useDistrictsByState, useSchoolsByDistrict } from '../hooks/SchoolLookupHooks';
import { DEPARTMENT_LIST } from 'services/constants';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';

const SCHOOL_YEAR_SECTION = [
  { value: '1st Quarter', label: '1st Quarter' },
  { value: '2nd Quarter', label: '2nd Quarter' },
  { value: '3rd Quarter', label: '3rd Quarter' },
  { value: '4th Quarter', label: '4th Quarter' },
  { value: '1st Trimester', label: '1st Trimester' },
  { value: '2nd Trimester', label: '2nd Trimester' },
  { value: '3rd Trimester', label: '3rd Trimester' },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const AddEventDialog = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const isFetchingCount = useIsFetching();
  const addEvent = useAddObservationEvent();
  const updateEvent = useUpdateObservationEvent();

  const [errorMessage, setErrorMessage] = useState(null);
  const [dialogState, setDialogState] = useState({
    start_date: DateTime.now(),
    end_date: DateTime.now(),
    school_year_section: '',
    state: null,
    district: null,
    school: null,
    department: '',
  });

  const districtsQuery = useDistrictsByState(dialogState.state?.value);
  const schoolQuery = useSchoolsByDistrict(dialogState.district?.value?.id);

  useEffect(() => {
    const init = () => {
      if (props.event) {
        setDialogState({
          start_date: DateTime.fromISO(props.event.start_date),
          end_date: DateTime.fromISO(props.event.end_date),
          school_year_section: props.event.school_year_section || '',
          state: states.find((s) => s.value === props.event.state),
          district: districtsQuery.data?.find((d) => d.value.id === props.event.district_id),
          school: schoolQuery.data?.find((s) => s.value.id === props.event.agency_id),
          department: props.event.department[0],
        });
      } else {
        resetState();
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open, props.event]);

  // TODO - would be nice to abstract this....
  useEffect(() => {
    let error = null;
    if (addEvent.error) {
      error = addEvent.error;
    } else if (updateEvent.error) {
      error = updateEvent.error;
    } else if (districtsQuery.error) {
      error = districtsQuery.error;
    } else if (schoolQuery.error) {
      error = schoolQuery.error;
    }
    if (error) {
      logClientException(error);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [addEvent.error, districtsQuery.error, schoolQuery.error, updateEvent.error]);

  useEffect(() => {
    if (districtsQuery.isSuccess && districtsQuery.data?.length > 0 && props.event) {
      setDialogState({ ...dialogState, district: districtsQuery.data?.find((d) => d.value.id === props.event.district_id) });
    }
  }, [districtsQuery.data, districtsQuery.isSuccess, props.event]);

  useEffect(() => {
    if (schoolQuery.isSuccess && schoolQuery.data?.length > 0 && props.event) {
      setDialogState({ ...dialogState, school: schoolQuery.data?.find((d) => d.value.id === props.event.agency_id) });
    }
  }, [schoolQuery.data, schoolQuery.isSuccess, props.event]);

  const getDepartmentList = () => {
    if (props.user?.observation_permission === 'Admin') {
      return DEPARTMENT_LIST.map((o) => {
        return (
          <MenuItem value={o} key={o}>
            {o}
          </MenuItem>
        );
      });
    } else if (props.user?.observation_permission === 'Observer') {
      const department = DEPARTMENT_LIST.filter((o) => o === props.user.department);
      if (department.length > 0) {
        return (
          <MenuItem value={department[0]} key={department[0]}>
            {department[0]}
          </MenuItem>
        );
      }
    }
  };

  const resetState = () => {
    setErrorMessage(null);
    setDialogState({
      start_date: DateTime.now(),
      end_date: DateTime.now(),
      school_year_section: '',
      state: null,
      district: null,
      school: null,
      department: props.user?.observation_permission === 'Observer' ? DEPARTMENT_LIST.filter((o) => o === props.user.department)[0] || '' : '',
    });
  };

  const closeDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSave()) {
      return;
    }

    try {
      const event = {
        start_date: dialogState.start_date.startOf('day').toISO(),
        end_date: dialogState.end_date.endOf('day').toISO(),
        school_year_section: dialogState.school_year_section ? dialogState.school_year_section : undefined,
        state: dialogState.state.value,
        district_id: dialogState.district.value.id,
        district_name: dialogState.district.value.name,
        agency_id: dialogState.school.value.id,
        agency_name: dialogState.school.value.name,
        department: [dialogState.department],
      };
      let mutatedEvent;
      if (props.event) {
        event.id = props.event.id;
        mutatedEvent = await updateEvent.mutateAsync(event, event);
      } else {
        mutatedEvent = await addEvent.mutateAsync(event, event);
      }

      resetState();
      props.onClose(doSave, mutatedEvent);
    } catch (error) {
      logClientException(error);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSave = () => {
    const currentDate = DateTime.now();
    let hasError = false;
    const event = dialogState;
    if (!event.start_date || !event.start_date.isValid) {
      hasError = true;
      event.start_dateError = true;
    }
    if (!event.end_date || !event.end_date.isValid) {
      hasError = true;
      event.end_dateError = true;
    } else if (event.end_date < event.start_date) {
      hasError = true;
      event.end_dateError = true;
    }
    if (!event.state) {
      hasError = true;
      event.stateError = true;
    }
    if (!event.district) {
      hasError = true;
      event.districtError = true;
    }
    if (!event.school) {
      hasError = true;
      event.schoolError = true;
    }
    if (!event.department || event.department.trim() === '') {
      hasError = true;
      event.departmentError = true;
    }
    // if (!hasError) {
    //   // check that event date range includes current date
    //   event.start_date = event.start_date.startOf('day');
    //   event.end_date = event.end_date.endOf('day');
    //   if (currentDate <= event.end_date && currentDate >= event.start_date) {
    //     // ok
    //   } else {
    //     hasError = true;
    //     event.end_dateError = true;
    //     event.start_dateError = true;
    //     setErrorMessage('Event date range must include current date.');
    //   }
    // }
    if (hasError) {
      setDialogState({ ...event, canSave: false });
    }
    return !hasError;
  };

  const formOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'start_date' || field === 'end_date') {
      if (!value || !value.isValid) {
        canSave = false;
        fieldError = true;
      } else if (field === 'end_date') {
        if (value < dialogState.start_date) {
          canSave = false;
          fieldError = true;
        }
      }
    }

    setDialogState({ ...dialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='body'
      fullWidth={true}
      open={props.open}
      onClose={() => closeDialog(false)}
      PaperComponent={DraggablePaperComponent}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.obsAqua.main, color: theme.palette.white.main, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {props.event ? 'Edit Event' : 'New Event'}
          <IconButton className='need-interaction' onClick={() => closeDialog(false)}>
            <CancelIcon color='white' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem' }}>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} className='need-interaction'>
            {errorMessage}
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem' }}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <DatePicker
              label='Start Date'
              value={dialogState.start_date}
              //minDate={DateTime.now()}
              //maxDate={DateTime.now()}
              required
              onChange={(e) => {
                formOnChange('start_date', e);
              }}
              slotProps={{
                textField: {
                  sx: { ...styles.select, width: '50%', marginRight: '5px' },
                  required: true,
                  size: 'small',
                  error: dialogState.start_dateError,
                },
              }}
            />

            <DatePicker
              label='End Date'
              value={dialogState.end_date}
              minDate={dialogState.start_date ? dialogState.start_date : null}
              required
              onChange={(e) => {
                formOnChange('end_date', e);
              }}
              slotProps={{
                textField: {
                  sx: { ...styles.select, width: '50%', marginRight: '0px' },
                  required: true,
                  size: 'small',
                  error: dialogState.end_dateError,
                },
              }}
            />
          </Box>

          <FormControl fullWidth>
            <TextField
              select
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              notched={true}
              size='small'
              labelId='school_year_section-select-label'
              id='school_year_section-select-value'
              value={dialogState.school_year_section}
              label='Time of Year'
              onChange={(e) => {
                formOnChange('school_year_section', e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Choose Time of Year
              </MenuItem>
              {SCHOOL_YEAR_SECTION.map((x) => (
                <MenuItem key={x.value} value={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <Autocomplete
            disablePortal
            options={states}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            value={dialogState.state}
            onChange={async (e, v) => {
              if (v) {
                setDialogState({ ...dialogState, state: v, district: null, school: null, canSave: true });
              }
            }}
            renderInput={(params) => (
              <TextField
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                required
                {...params}
                label='State'
                size='small'
                error={dialogState.stateError}
                InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
              />
            )}
          />

          <Autocomplete
            disablePortal
            options={districtsQuery.data || []}
            fullWidth
            isOptionEqualToValue={(o, v) => o.value.id === v?.value?.id}
            disabled={!dialogState.state}
            value={dialogState.district}
            onChange={async (e, v) => {
              if (v) {
                setDialogState({ ...dialogState, district: v, school: null, canSave: true });
              }
            }}
            renderInput={(params) => (
              <TextField
                sx={styles.select}
                disabled={!dialogState.state}
                required
                error={dialogState.districtError}
                {...params}
                label='School district'
                size='small'
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <Autocomplete
            disablePortal
            options={schoolQuery.isPending ? [] : schoolQuery.data || []}
            isOptionEqualToValue={(o, v) => o.value === v?.value}
            fullWidth
            disabled={!dialogState.district || schoolQuery.data?.length === 0}
            value={dialogState.school}
            onChange={(e, v) => formOnChange('school', v)}
            renderInput={(params) => (
              <TextField
                sx={styles.select}
                required={true}
                error={dialogState.schoolError}
                {...params}
                label='Agency/School'
                size='small'
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <FormControl fullWidth size='small'>
            <TextField
              select
              required
              InputLabelProps={{ shrink: true }}
              sx={styles.select}
              InputProps={{ sx: { ...styles.select } }}
              label='Department'
              id='department-select'
              error={dialogState.departmentError}
              size='small'
              value={dialogState.department}
              onChange={(e) => {
                formOnChange('department', e.target.value);
              }}
            >
              <MenuItem value='' disabled>
                Choose Department
              </MenuItem>
              {getDepartmentList()}
            </TextField>
          </FormControl>
        </Box>

        <PleaseWait isLoading={isFetchingCount > 0} />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <KitButton className='need-interaction' onClick={() => closeDialog(true)} size='sm' color='secondary' disabled={!dialogState.canSave}>
          {props.event ? 'Update' : 'Save'}
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

AddEventDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  user: PropTypes.object,
};
export default AddEventDialog;
