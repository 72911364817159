import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Badge, Box, FormControl, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useObservationStore } from '../../stores/observationStore';
import { HandIcon } from 'components/common/subcomponents/SvgIcons';
import { PromptButton, ObsStyles, PromptContainer, PromptRadio } from '../StyledComponents';

const Prevention = (props) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { observation, addTimelineObservation, setDirtyObject } = useObservationStore();

  const [preventionPrompt, setPreventionPrompt] = useState(''); // ['pp', 'bt', 'pt']
  const [sPrompt, setSPrompt] = useState(''); // s'
  const [slPrompt, setSLPrompt] = useState(''); // sl'
  const [moPrompt, setMoPrompt] = useState(''); // mo'
  const [note, setNote] = useState('');
  const [hasPreventionPrompt, setHasPreventionPrompt] = useState(false);
  const [hasMoPrompt, setHasMoPrompt] = useState(false);

  useEffect(() => {
    if (observation.dirtyObject !== 'prevention') {
      setPreventionPrompt('');
      setHasPreventionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
    }
  }, [observation]);

  const radioOnChange = (e) => {
    setDirtyObject('prevention');
    const { name, value, checked } = e.target;
    if (name === 'prevention-1') {
      setPreventionPrompt(value);
      setHasPreventionPrompt(true);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'prevention-s-1') {
      setSPrompt(checked && sPrompt === 'prevention-s' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'prevention-sl-1') {
      setSLPrompt(checked && slPrompt === 'prevention-sl' ? '' : value);
      setMoPrompt('');
      setHasMoPrompt(false);
    } else if (name === 'prevention-mo') {
      setPreventionPrompt('');
      setHasPreventionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt(checked && moPrompt === 'prevention-mo' ? '' : value);
      setHasMoPrompt(true);
    }
  };

  const addToTimeline = () => {
    const timelineObservation = {
      timestamp: new Date().getTime(),
      type: 'Prevention',
      tags: [],
      note: note,
    };

    if (preventionPrompt?.length > 0) {
      setHasPreventionPrompt(true);
      timelineObservation.tags.push(preventionPrompt.toUpperCase());
    }
    if (sPrompt?.length > 0) {
      timelineObservation.tags.push('S');
    }
    if (slPrompt?.length > 0) {
      timelineObservation.tags.push('SL');
    }
    if (moPrompt?.length > 0) {
      setHasMoPrompt(true);
      timelineObservation.tags.push('MO');
    }
    if (hasPreventionPrompt || hasMoPrompt) {
      addTimelineObservation(timelineObservation);
      setPreventionPrompt('');
      setHasPreventionPrompt(false);
      setSPrompt('');
      setSLPrompt('');
      setMoPrompt('');
      setHasMoPrompt(false);
      setNote('');
      props.scrollToTop();
    }
  };

  return (
    <PromptContainer promptType='Prevention'>
      <Badge
        color='error'
        badgeContent={
          observation?.observations?.filter((o) => o.type === 'Prevention' && o.tags.filter((t) => t === 'MO')?.length === 0)?.length || 0
        }
        className='prompt-title-badge'
      >
        <div className='prompt-title-box'>
          <HandIcon fillColor={theme.palette.obsPrevention.dark} />
          <Typography sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '18.86px', color: '#000', marginLeft: '1rem' }}>Prevention</Typography>
        </div>
      </Badge>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
        <PromptRadio promptType='Prevention' style={{ marginTop: '1.5rem' }}>
          <input type='radio' id='pp' name='prevention-1' value='pp' checked={preventionPrompt === 'pp'} onClick={radioOnChange} />
          <label htmlFor='pp'>PP</label>
        </PromptRadio>
        <PromptRadio promptType='Prevention' style={{ marginTop: '1.5rem' }}>
          <input type='radio' id='bt' name='prevention-1' value='bt' checked={preventionPrompt === 'bt'} onClick={radioOnChange} />
          <label htmlFor='bt'>BT</label>
        </PromptRadio>
        <PromptRadio promptType='Prevention' style={{ marginTop: '1.5rem' }}>
          <input type='radio' id='pt' name='prevention-1' value='pt' checked={preventionPrompt === 'pt'} onClick={radioOnChange} />
          <label htmlFor='pt'>PT</label>
        </PromptRadio>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '1.5rem', width: '85%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.obsPrevention.xLight,
            borderRadius: '12px',
            padding: '12px',
            width: '66.67%',
          }}
        >
          <PromptRadio promptType='Prevention' round={true}>
            <input
              type='radio'
              id='prevention-s'
              name='prevention-s-1'
              value='prevention-s'
              checked={sPrompt === 'prevention-s'}
              onClick={radioOnChange}
            />
            <label htmlFor='prevention-s'>S</label>
          </PromptRadio>
          <PromptRadio promptType='Prevention' round={true}>
            <input
              type='radio'
              id='prevention-sl'
              name='prevention-sl-1'
              value='prevention-sl'
              checked={slPrompt === 'prevention-sl'}
              onClick={radioOnChange}
            />
            <label htmlFor='prevention-sl'>SL</label>
          </PromptRadio>
        </Box>
        <Box sx={{ padding: '12px' }}>
          <Badge
            color='error'
            badgeContent={
              observation?.observations?.filter((o) => o.type === 'Prevention' && o.tags.filter((t) => t === 'MO')?.length > 0)?.length || 0
            }
            overlap='circular'
          >
            <PromptRadio promptType='Prevention' round={true}>
              <input
                type='radio'
                id='prevention-mo'
                name='prevention-mo'
                value='prevention-mo'
                checked={moPrompt === 'prevention-mo'}
                onClick={radioOnChange}
              />
              <label htmlFor='prevention-mo'>MO</label>
            </PromptRadio>
          </Badge>
        </Box>
      </Box>
      <Box sx={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
        <FormControl fullWidth size='small'>
          <TextField
            placeholder='Type note'
            multiline={true}
            rows={2}
            InputLabelProps={{ shrink: true }}
            sx={{ ...styles.select }}
            InputProps={{ sx: { ...styles.select } }}
            inputProps={{ maxLength: 200 }}
            label=''
            id='prevention-note'
            //error={noteError}
            size='small'
            value={note}
            onChange={(e) => {
              //setNoteError(false);
              setDirtyObject('prevention');
              setNote(e.target.value);
            }}
          />
        </FormControl>

        <KitButton
          onClick={addToTimeline}
          size='sm'
          style={{
            backgroundColor: theme.palette.gold.main,
            marginTop: '1rem',
          }}
          disabled={!hasPreventionPrompt && !hasMoPrompt}
        >
          <span>
            <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
          </span>
        </KitButton>
      </Box>
    </PromptContainer>
  );
};

export default Prevention;
