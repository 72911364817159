export const headingColor = (theme, colorString) => {
  // #000000 style colorString
  if (colorString?.startsWith('#')) {
    return colorString;
  }
  // primary.main style colorString
  if (colorString?.indexOf('.') > -1) {
    const [palette, shade] = colorString.split('.');
    return theme.palette[palette][shade] ? theme.palette[palette][shade] : theme.palette.common.black;
  }
  // primary style colorString
  return theme.palette[colorString] ? theme.palette[colorString].main : theme.palette.common.black;
};

export const headingStyle = (alignment) => {
  return alignment === 'center' ? { display: 'flex', justifyContent: 'center' } : null;
};
