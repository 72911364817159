import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import { isCustomer } from 'services/subscriptionUtils';

const SubscriptionTitleWithIcon = ({ module }) => {
  const { fields } = module;
  const { data: session, status } = useSession();

  const router = useRouter();
  const theme = useTheme();

  const Container = styled.div`
    ${isCustomer(session?.user) ? 'display: flex;' : 'display: none;'}
    position: relative;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    @media screen and (min-width: 992px) {
      justify-content: flex-start;
      align-items: space-between;
      padding: 0 13%;
    }
    @media screen and (max-width: 991px) {
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 3%;
    }
    @media screen and (max-width: 400px) {
      align-items: flex-start;
    }
  `;

  const ImageContainer = styled.div`
    @media screen and (min-width: 992px) {
      width: 50%;
      background-image: url('${fields.titleIcon.url}');
      background-repeat: no-repeat;
      background-size: 335px;
      background-position: right 0 top 0%;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
    @media screen and (max-width: 400px) {
      display: none;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    minheight: inherit;
    @media screen and (min-width: 900px) {
      width: 90%;
      height: 220px;
      padding-right: 10px;
    }
    @media screen and (max-width: 899px) {
      width: 100%;
    }
  `;

  const HeroTitle = styled.div`
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    @media screen and (min-width: 900px) {
      font-size: 2.5rem;
      line-height: 3.375rem;
    }
    @media screen and (max-width: 899px) {
      font-size: 2rem;
      line-height: 2.725rem;
    }
  `;

  const BrandText = styled.div`
    color: #fff;
    background-color: ${fields.brandColor};
    font-weight: 600;
    padding: 2px 20px;
    @media screen and (min-width: 900px) {
      font-size: 1rem;
      line-height: 1.75rem;
    }
    @media screen and (max-width: 899px) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  `;
  return (
    <React.Fragment>
      <Container>
        <TextContainer>
          <BrandText>{fields.brandText}</BrandText>
          <HeroTitle>{fields.title}</HeroTitle>
        </TextContainer>
        <ImageContainer></ImageContainer>
      </Container>
    </React.Fragment>
  );
};

export default SubscriptionTitleWithIcon;

const HeroSeparator = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 200,
  fontSize: '1.75rem',
  lineHeight: '1.85rem',
  margin: '0 30px',
}));
