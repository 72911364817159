// package imports
import { useTheme } from '@emotion/react';
import { Box, IconButton, MenuItem, Snackbar, TextField, useMediaQuery } from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import EditIcon from '@mui/icons-material/Edit';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import MuiAlert from '@mui/material/Alert';

// local imports
import { themeColor } from 'services/styleUtils/colorUtils';
import SchoolSelector from 'components/common/subcomponents/SchoolSelector';
import Heading4 from 'components/common/subcomponents/Typography/Heading4';
import { stripChars, emailRegex } from 'services/stringUtils';
import { LEAD_TITLES, OTHER_DISTRICT, OTHER_SCHOOL } from 'services/constants';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { useIsFetching } from '@tanstack/react-query';
import { logClientException } from 'appinsights/clientAppInsights';
import { BadRequestError } from 'services/apiWrapper';
import PleaseWait from 'components/common/PleaseWait';
import { useAddContactUsCrmLead } from 'hooks/contactUsCrmLeadHooks';
import { Hours, ThankYou } from 'components/common/subcomponents/ContactUsForm';
import Heading5 from 'components/common/subcomponents/Typography/Heading5';
import { getContentStyle } from 'services/styleUtils/contentStyleUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';
const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY;
const FORM_NAME = 'Contact Us Banner';
const FORM_ID = '9ac7027116204544b188a076ec98d3b0';
const CONTACT_SALES_OPTION = '924790011';

const ContactUsBanner = ({ module: { fields } }) => {
  const theme = useTheme();
  const { data: session, status } = useSession();
  const isSmallScreen = useMediaQuery('screen and (max-width: 767px)');
  const [formState, setFormState] = useState({
    step1IsValid: true,
    step2IsValid: true,
    type: 'sales',
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    agency: '',
    phone: '',
    description: '',
    previous_ticket: 'No',
    ticket_number: '',
    screenshot: null,
    recaptcha_token: '',
    city: '',
    state: '',
  });

  const path = typeof window !== 'undefined' ? window.location.pathname : '';

  const [errorMessage, setErrorMessage] = useState(null);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [step, setStep] = useState(1);
  const [showMobileForm, setShowMobileForm] = useState(false);

  const isFetchingCount = useIsFetching();
  const addLead = useAddContactUsCrmLead();

  const contentStyle = getContentStyle(theme, fields.infoTextStyle?.fields);

  useEffect(() => {
    let event = {
      'event': 'contact_form_view',
      'form_name': FORM_NAME,
      'form_id': FORM_ID,
      'page': path,
    };
    addDataLayerEvent(event);
  }, []);

  useEffect(() => {
    if (status === 'authenticated' && session?.user) {
      setFormState({
        ...formState,
        authenticated: true,
        first_name: session.user.firstName,
        last_name: session.user.lastName,
        email: session.user.email,
        title: session.user.position,
        state: session.user.state,
        district_id: session.user.district_id,
        school_id: session.user.school_id,
        school_name: session.user.school_name,
        schoolInfo: {
          district: {
            value: {
              id: session.user.district_id,
              agile_ed_id: session.user.district_agile_ed_id,
              name: session.user.district_name,
            },
            label: session.user.district_name,
          },
          school: {
            value: {
              id: session.user.school_id,
              agile_ed_id: session.user.school_agile_ed_id,
              name: session.user.school_name,
            },
            label: session.user.school_name,
          },
          state: {
            value: session.user.state,
          },
        },
        districtDisplay: session?.user?.district_id === OTHER_DISTRICT.id ? '' : session.user.district_name,
        schoolDisplay: session.user.school_id === OTHER_SCHOOL.id ? '' : session.user.school_name,
      });
      setStep(3);
    }
  }, [session?.user, status]);

  useEffect(() => {
    let error = null;
    if (addLead.error) {
      error = addLead.error;
    }
    if (error) {
      logClientException(error);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [addLead.error]);

  const validateStep1 = () => {
    let isValid = true;
    if (formState.first_name === undefined || formState.first_name.length === 0) {
      isValid = false;
    }
    if (formState.last_name === undefined || formState.last_name.length === 0) {
      isValid = false;
    }
    if (formState.email === undefined || formState.email.length === 0 || !emailRegex.test(formState.email)) {
      isValid = false;
    }
    if (!formState.title || formState.title.length === 0) {
      isValid = false;
    }
    setFormState({ ...formState, step1IsValid: isValid });
    if (isValid) {
      setStep(2);
    }
    return isValid;
  };

  const validateStep2 = () => {
    let agency = null;
    if (formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id) {
      agency = formState.schoolInfo?.other_agency;
    } else if (formState.schoolInfo?.school && formState.schoolInfo?.school?.value?.id !== OTHER_SCHOOL.id) {
      agency = formState.schoolInfo?.school?.value.name;
    } else {
      agency = formState.schoolInfo?.district?.value.name;
    }
    let other_cityError = false;
    let stateError = false;
    let districtError = false;
    let schoolError = false;

    if (formState.type === 'sales') {
      if (
        formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id &&
        (!formState.schoolInfo?.other_city || formState.schoolInfo?.other_city.length === 0)
      ) {
        other_cityError = true;
      } else if (!formState.schoolInfo?.state) {
        stateError = true;
      } else if (!formState.schoolInfo?.district) {
        districtError = true;
      } else if (
        formState.schoolInfo?.district &&
        formState.schoolInfo?.district.value.id !== OTHER_DISTRICT.id &&
        !session?.user && // if user is logged in, we don't need to check for school
        !formState.schoolInfo?.school
      ) {
        schoolError = true;
      }
    }

    if (!agency || agency.length === 0 || other_cityError || stateError || districtError || schoolError) {
      setFormState({
        ...formState,
        stateError: stateError,
        districtError: districtError,
        schoolError: schoolError,
        other_cityError: other_cityError,
        other_agencyError: formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id ? true : false,
      });
      return false;
    } else {
      setFormState({
        ...formState,
        stateError: false,
        districtError: false,
        schoolError: false,
        other_agencyError: false,
        step2IsValid: true,
        agency: agency,
      });
      setStep(3);
      return true;
    }
  };

  const submitForm = async () => {
    setAttemptedSubmit(true);
    if (!formState.description || formState.description.length === 0) {
      return;
    }
    if (!formState.authenticated && !formState.recaptcha_token) {
      return;
    }
    if (!validateStep1() || !validateStep2()) {
      return;
    }

    const body = {
      isBannerForm: true,
      description: formState.description,
      first_name: formState.first_name,
      last_name: formState.last_name,
      title: formState.title,
      email: formState.email,
      decision_maker: LEAD_TITLES.find((t) => t.title === formState.title)?.decision_maker,
      phone: formState.phone,
      option: CONTACT_SALES_OPTION,
      product: '',
      utm_source: window.sessionStorage?.utm_source,
      utm_medium: window.sessionStorage?.utm_medium,
      utm_campaign: window.sessionStorage?.utm_campaign,
      utm_content: window.sessionStorage?.utm_content,
      recaptcha_token: formState.recaptcha_token,
      state: formState.schoolInfo?.state?.value,
    };
    if (formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id) {
      body.other_agency = formState.schoolInfo?.other_agency;
      body.other_city = formState.schoolInfo?.other_city;
      body.agencyType = 'OTHER';
    } else if (formState.schoolInfo?.school && formState.schoolInfo?.school?.value?.id !== OTHER_SCHOOL.id) {
      body.agencyType = 'SCHOOL';
      body.agency_id = formState.schoolInfo?.school?.value?.agile_ed_id;
    } else {
      body.agencyType = 'DISTRICT';
      body.agency_id = formState.schoolInfo?.district?.value?.agile_ed_id;
    }

    await addLead.mutateAsync(body, body);

    setStep('thank-you');

    // add contact_form_submit event
    let event = {
      'event': 'contact_form_submit',
      'form_name': FORM_NAME,
      'form_id': FORM_ID,
      'form_reason': formState.type,
      'page': path,
    };
    addDataLayerEvent(event);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (isSmallScreen) {
    return (
      <Box sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
        <PleaseWait isLoading={isFetchingCount > 0 || addLead.isPending} />
        <Snackbar open={errorMessage && errorMessage.length > 0} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <Box
          sx={{
            padding: '1rem',
            height: '150px',
            width: '100%',
            backgroundColor: fields?.mobileCalloutBackgroundColor?.fields?.color
              ? themeColor(theme, fields.mobileCalloutBackgroundColor.fields.color)
              : theme.palette.primary.light2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading5 style={{ color: themeColor(theme, fields?.headingTextColor?.fields?.color) }}>{fields?.headingText}</Heading5>
          <IconButton onClick={() => setShowMobileForm(!showMobileForm)}>
            <ArrowCircleDownIcon
              fontSize='large'
              sx={{ transform: showMobileForm ? '' : 'rotate(-90deg)', color: themeColor(theme, fields?.headingTextColor?.fields?.color) }}
            />
          </IconButton>
        </Box>

        <Box
          id='mobile-form-container'
          sx={{
            backgroundColor: fields?.backgroundColor?.fields?.color ? themeColor(theme, fields.backgroundColor.fields.color) : '',
            padding: '1rem',
            display: showMobileForm ? 'block' : 'none',
            width: '100%',
          }}
        >
          <Box id='mobile-form' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.common.white, padding: '1.5rem' }}>
            <Box sx={{ display: step !== 'thank-you' ? 'flex' : 'none', flexDirection: 'column', gap: '.5rem' }}>
              <TextField
                name='first_name'
                value={formState.first_name}
                error={attemptedSubmit && (formState.first_name === undefined || formState.first_name.length === 0)}
                required
                margin='dense'
                id='first_name'
                label='First Name'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setFormState({ ...formState, first_name: stripChars(e.target.value) })}
                inputProps={{ maxLength: 40 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='last_name'
                value={formState.last_name}
                error={attemptedSubmit && (formState.last_name === undefined || formState.last_name.length === 0)}
                required
                margin='dense'
                id='last_name'
                label='Last Name'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setFormState({ ...formState, last_name: stripChars(e.target.value) })}
                inputProps={{ maxLength: 40 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='email'
                value={formState.email}
                error={attemptedSubmit && (formState.email === undefined || formState.email.length === 0 || !emailRegex.test(formState.email))}
                required
                margin='dense'
                id='email'
                label='Email'
                type='email'
                fullWidth
                variant='standard'
                onChange={(e) => setFormState({ ...formState, email: stripChars(e.target.value?.toLowerCase()) })}
                inputProps={{ maxLength: 40 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                name='title'
                variant='standard'
                label='Title'
                InputLabelProps={{ shrink: true }}
                error={attemptedSubmit && (formState.title === undefined || formState.title.length === 0)}
                select
                id='title-select-value'
                value={formState.title}
                required
                onChange={(e) => {
                  setFormState({ ...formState, title: e.target.value });
                }}
              >
                <MenuItem value='' disabled>
                  Select Title
                </MenuItem>
                {LEAD_TITLES.map((t) => {
                  return (
                    <MenuItem value={t.title} key={t.title}>
                      {t.title}
                    </MenuItem>
                  );
                })}
              </TextField>
              <SchoolSelector
                state={formState.state}
                district_id={formState.district_id}
                school_id={formState.school_id}
                school_name={formState.school_name}
                other_city={formState.other_city}
                other_agency={formState.other_agency}
                requireCity={formState.type === 'sales'}
                stateError={formState.stateError}
                districtError={formState.districtError}
                schoolError={formState.schoolError}
                other_cityError={formState.other_cityError}
                other_agencyError={formState.other_agencyError}
                onDataChange={(e) => {
                  setFormState({
                    ...formState,
                    districtDisplay: e?.district?.value?.id === OTHER_DISTRICT.id ? e.other_city : e?.district?.value?.name,
                    schoolDisplay:
                      e?.district?.value?.id === OTHER_DISTRICT.id
                        ? e.other_agency
                        : e?.school?.value?.id === OTHER_SCHOOL.id
                        ? ''
                        : e?.school?.value?.name,

                    schoolInfo: e,
                  });
                }}
              />
              <TextField
                name='description'
                value={formState.description}
                error={attemptedSubmit && (!formState.description || formState.description.length === 0)}
                multiline={true}
                rows={3}
                required
                margin='dense'
                id='description'
                label='Question'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setFormState({ ...formState, description: stripChars(e.target.value, true) })}
                inputProps={{ maxLength: 4000 }}
                InputLabelProps={{ shrink: true }}
              />{' '}
              <div style={{ display: session?.user ? 'none' : 'flex', justifyContent: 'center' }}>
                <ReCAPTCHA sitekey={`${SITE_KEY}`} onChange={(token) => setFormState({ ...formState, recaptcha_token: token })} />
              </div>
              <KitButton
                style={{ backgroundColor: theme.palette.primary.light2 }}
                round
                size='sm'
                onClick={() => {
                  validateStep2();
                  submitForm();
                }}
              >
                Submit
              </KitButton>
            </Box>

            <Box id='thank-you' sx={{ display: step === 'thank-you' ? 'flex' : 'none', flexDirection: 'column', gap: '1rem' }}>
              <ThankYou>Thank you for your request. A member of our team will get back to you within the next business day.</ThankYou>
              <Hours>Our hours of operation are M-F 8am to 5pm CT.</Hours>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  if (!isSmallScreen) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '360px',
          width: '100%',
          backgroundColor: fields?.backgroundColor?.fields?.color ? themeColor(theme, fields.backgroundColor.fields.color) : '',
          bottom: 0,
          marginTop: '30px',
          marginBottom: '30px',

          '&::before': {
            content: '" "',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: fields?.backgroundImageOpactiy ? fields.backgroundImageOpactiy : 1,
            backgroundImage: fields?.backgroundImage?.url ? `url(${fields.backgroundImage.url})` : '',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 0',
            backgroundSize: 'cover',
          },
        }}
      >
        <PleaseWait isLoading={isFetchingCount > 0 || addLead.isPending} />
        <Snackbar open={errorMessage && errorMessage.length > 0} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <Box id='content-wrapper-outer' sx={{ position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '1.5rem' }}>
          <Box
            id='calloutWrapper'
            sx={{
              position: 'relative',
              height: '350px',
              width: '478px',
              backgroundColor: fields?.calloutBackgroundColor?.fields?.color ? themeColor(theme, fields.calloutBackgroundColor.fields.color) : '',
              marginTop: '25px',
            }}
          >
            <Box
              id='callout'
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                padding: '1.5rem 2rem',
                '&::after': {
                  content: '" "',
                  position: 'absolute',
                  right: '-48px',
                  bottom: 0,
                  borderRight: '16px solid transparent',
                  borderBottom: '16px solid transparent',
                  borderLeft: `16px solid ${
                    fields?.calloutBackgroundColor?.fields?.color ? themeColor(theme, fields.backgroundColor.fields.color) : ''
                  }`,
                  transform: 'translateX(-50%)',
                },
              }}
            >
              <Heading4 style={{ color: themeColor(theme, fields?.headingTextColor?.fields?.color) }}>{fields?.headingText}</Heading4>
              <Box
                sx={{
                  marginTop: '1rem',
                  color: contentStyle.textColor,
                  textAlign: contentStyle.alignment,
                  fontSize: contentStyle.fontSize,
                  lineHeight: contentStyle.lineHeight,
                }}
              >
                {fields?.infoText}
              </Box>
              {/* <BodyCopy style={{ marginTop: '1rem' }} color={themeColor(theme, fields?.infoTextColor?.fields?.color)}>
                {fields?.infoText}
              </BodyCopy> */}
            </Box>
          </Box>
          <Box
            id='form-container'
            sx={{
              height: '310px',
              width: '617px',
              marginTop: '25px',
              backgroundColor: theme.palette.common.white,
              padding: '1.5rem 2rem',
            }}
          >
            <Box id='step-1' sx={{ display: step === 1 ? 'flex' : 'none', flexDirection: 'column', gap: '1rem' }}>
              <Box id='name-box' sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <TextField
                  name='first_name'
                  value={formState.first_name}
                  error={!formState.step1IsValid && (formState.first_name === undefined || formState.first_name.length === 0)}
                  required
                  margin='dense'
                  id='first_name'
                  label='First Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  onChange={(e) => setFormState({ ...formState, first_name: stripChars(e.target.value) })}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name='last_name'
                  value={formState.last_name}
                  error={!formState.step1IsValid && (formState.last_name === undefined || formState.last_name.length === 0)}
                  required
                  margin='dense'
                  id='last_name'
                  label='Last Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  onChange={(e) => setFormState({ ...formState, last_name: stripChars(e.target.value) })}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <TextField
                name='email'
                value={formState.email}
                error={
                  !formState.step1IsValid && (formState.email === undefined || formState.email.length === 0 || !emailRegex.test(formState.email))
                }
                required
                margin='dense'
                id='email'
                label='Email'
                type='email'
                fullWidth
                variant='standard'
                onChange={(e) => setFormState({ ...formState, email: stripChars(e.target.value?.toLowerCase()) })}
                inputProps={{ maxLength: 40 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='title'
                variant='standard'
                id='title-select-value'
                label='Title'
                InputLabelProps={{ shrink: true }}
                select
                error={!formState.step1IsValid && (formState.title === undefined || formState.title.length === 0)}
                value={formState.title}
                required
                onChange={(e) => {
                  setFormState({ ...formState, title: e.target.value });
                }}
              >
                <MenuItem value='' disabled>
                  Select Title
                </MenuItem>
                {LEAD_TITLES.map((t) => {
                  return (
                    <MenuItem value={t.title} key={t.title}>
                      {t.title}
                    </MenuItem>
                  );
                })}
              </TextField>

              <KitButton
                style={{ width: '130px', backgroundColor: theme.palette.primary.light2 }}
                round
                size='sm'
                //variant='outlined'
                onClick={validateStep1}
              >
                Next
              </KitButton>
            </Box>

            <Box id='step-2' sx={{ display: step === 2 ? 'flex' : 'none', flexDirection: 'column', gap: '1rem' }}>
              <div>
                <SchoolSelector
                  state={formState.state}
                  district_id={formState.district_id}
                  school_id={formState.school_id}
                  school_name={formState.school_name}
                  other_city={formState.other_city}
                  other_agency={formState.other_agency}
                  requireCity={formState.type === 'sales'}
                  stateError={formState.stateError}
                  districtError={formState.districtError}
                  schoolError={formState.schoolError}
                  other_cityError={formState.other_cityError}
                  other_agencyError={formState.other_agencyError}
                  onDataChange={(e) => {
                    setFormState({
                      ...formState,
                      districtDisplay: e?.district?.value?.id === OTHER_DISTRICT.id ? e.other_city : e?.district?.value?.name,
                      schoolDisplay:
                        e?.district?.value?.id === OTHER_DISTRICT.id
                          ? e.other_agency
                          : e?.school?.value?.id === OTHER_SCHOOL.id
                          ? ''
                          : e?.school?.value?.name,

                      schoolInfo: e,
                    });
                  }}
                  compactCityAndAgency={true}
                />
              </div>
              <Box id='step-2-buttons' sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <KitButton
                  style={{ width: '130px', backgroundColor: theme.palette.grey[300], color: theme.palette.common.black }}
                  round
                  size='sm'
                  //variant='outlined'
                  onClick={(e) => setStep(1)}
                >
                  Back
                </KitButton>

                <KitButton
                  style={{ width: '130px', backgroundColor: theme.palette.primary.light2 }}
                  round
                  size='sm'
                  //variant='outlined'
                  onClick={validateStep2}
                >
                  Next
                </KitButton>
              </Box>
            </Box>

            <Box id='step-3' sx={{ display: step === 3 ? 'flex' : 'none', flexDirection: 'column', gap: '1rem' }}>
              <Box sx={{ fontSize: '16px', lineHeight: '18px', color: '#000', fontWeight: 400, display: formState.authenticated ? 'block' : 'none' }}>
                <div style={{ fontWeight: 700 }}>
                  {formState.first_name} {formState.last_name} - {formState.title}
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <span>{formState.email}</span>
                  <IconButton
                    sx={{ paddingBottom: 0, marginTop: '-4px' }}
                    onClick={(e) => {
                      setStep(1);
                    }}
                  >
                    <EditIcon color='primary' sx={{ fontSize: '18px', marginTop: '-4px', paddingBottom: 0 }} />
                  </IconButton>{' '}
                </Box>
                <div>{formState.districtDisplay}</div>
                <div>{formState.schoolDisplay}</div>
              </Box>
              <TextField
                name='description'
                value={formState.description}
                error={attemptedSubmit && (!formState.description || formState.description.length === 0)}
                multiline={true}
                rows={3}
                required
                margin='dense'
                id='description'
                label='Question'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setFormState({ ...formState, description: stripChars(e.target.value, true) })}
                inputProps={{ maxLength: 4000 }}
                InputLabelProps={{ shrink: true }}
              />{' '}
              <div style={{ display: session?.user ? 'none' : 'block' }}>
                <ReCAPTCHA sitekey={`${SITE_KEY}`} onChange={(token) => setFormState({ ...formState, recaptcha_token: token })} />
              </div>
              <Box id='step-3-buttons' sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <KitButton
                  style={{
                    width: '130px',
                    backgroundColor: theme.palette.grey[300],
                    color: theme.palette.common.black,
                    display: formState.authenticated ? 'none' : 'block',
                  }}
                  round
                  size='sm'
                  onClick={(e) => setStep(2)}
                >
                  Back
                </KitButton>

                <KitButton style={{ width: '130px', backgroundColor: theme.palette.primary.light2 }} round size='sm' onClick={submitForm}>
                  Submit
                </KitButton>
              </Box>
            </Box>

            <Box id='thank-you' sx={{ display: step === 'thank-you' ? 'flex' : 'none', flexDirection: 'column', gap: '1rem' }}>
              <ThankYou>Thank you for your request. A member of our team will get back to you within the next business day.</ThankYou>
              <Hours>Our hours of operation are M-F 8am to 5pm CT.</Hours>
            </Box>
          </Box>
        </Box>
        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </Box>
    );
  }
};

export default ContactUsBanner;
