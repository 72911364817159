import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';

export default function VideoDialogWithX(props) {
  const { size, content, ariaLabel, showValue, showMethod } = props;

  const narrow = useMediaQuery('(max-width:767px)');
  const short = useMediaQuery('(max-height:730px)');

  const fullScreen = narrow || short;

  const ButtonContainer = styled.div`
    padding: 5px;
    width: 10%;
  `;

  return (
    <Dialog
      open={showValue}
      onClose={(e) => showMethod(false)}
      maxWidth={size}
      fullWidth={true}
      fullScreen={fullScreen}
      aria-labelledby={ariaLabel || 'dialog'}
      PaperProps={{
        style: {
          backgroundColor: props.titleColor || 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <React.Fragment>
        <div
          style={{
            backgroundColor: 'transparent',
            color: '#fff',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ButtonContainer style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <button
              style={{
                color: props.closeXColor || '#000',
                backgroundColor: props.titleBarColor || 'transparent',
              }}
              onClick={(e) => showMethod(false)}
            >
              <CloseIcon style={{ fontSize: '1.725rem', fontWeight: 700, color: props.closeXColor || '#FFF' }} />
            </button>
          </ButtonContainer>
        </div>
        <DialogContent style={{ overflowX: 'clip', background: 'lightgray', padding: 0 }}>
          <Box id='dialog-content-box' sx={{ display: 'flex', flexDirection: 'column' }}>
            {content}
          </Box>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  );
}

VideoDialogWithX.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']).isRequired,
  content: PropTypes.object.isRequired,
  ariaLabel: PropTypes.string,
  showValue: PropTypes.bool.isRequired,
  showMethod: PropTypes.func.isRequired,
};
