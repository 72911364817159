import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { DateTime } from 'luxon';
import truncate from 'truncate-html';
import Link from 'next/link';
import Box from '@mui/material/Box';
import PleaseWait from '../common/PleaseWait';
import { KitGridContainer, KitGridItem, KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import useMediaQuery from '@mui/material/useMediaQuery';
import Carousel from 'react-material-ui-carousel';
import { useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, TZ_MAP } from 'services/constants';
import DialogWithX from './pageComponents/DialogWithX';
import PlanOptionLearnMore from './pageComponents/PlanOptionLearnMore';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Heading6 from 'components/common/subcomponents/Typography/Heading6';
import BodyCopy from 'components/common/subcomponents/Typography/BodyCopy';
import BodyCopyMobile from 'components/common/subcomponents/Typography/BodyCopyMobile';

const ARLO_BASE_URL = process.env.ARLO_BASE_URL ?? 'https://boystown.arlo.co/api/2012-02-01/pub/resources/eventsearch/?region=LED';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CoachingPlanCards = ({ module }) => {
  const { fields } = module;

  console.log(JSON.stringify(fields, null, 2));

  const theme = useTheme();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const matchesLg = useMediaQuery('screen and (min-width: 992px)');

  const [showDialog, setShowDialog] = useState(false);
  const [productImage, setProductImage] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [benefits, setBenefits] = useState('');
  const [disclaimer, setDisclaimer] = useState('');
  const [virtualSessions, setVirtualSessions] = useState(null);
  const [seriesSelected, setSeriesSelected] = useState(null);
  const [holdDesc, setHoldDesc] = useState('');

  if (fields?.trainingPlans?.length <= 0) {
    return <NoResourcesContainer></NoResourcesContainer>;
  }

  const handleActionButtonClick = async (series, id, desc) => {
    setHoldDesc(desc);
    setSeriesSelected(series);
    await loadArloSessions(id);
    return;
  };

  const handleNoSessionsContactUsClick = async () => {
    router.push(`/contact-us?product=${holdDesc}&trainingMethod=instructor-led virtual`);
  };

  const loadArloSessions = async (id) => {
    let url = ARLO_BASE_URL;
    url +=
      '&fields=EventID,Name,AdvertisedOffers,Sessions.StartDateTime,Sessions.Location,Sessions.EndDateTime,Sessions.Summary,RegistrationInfo,Categories,Presenters.Profile,TimeZone,Notice,Description';
    url += `&filter=templatecategoryid%3D${id}`;
    //TESTING TLR url += `&filter=templatecategoryid%3D${1}`;
    url += '&top=200';
    url += '&sort=Sessions.StartDateTime';

    try {
      setIsLoading(true);
      const res = await fetch(url);
      if (res.status === 200) {
        const result = await res.json();
        //const result = mockResp;
        //const result = { Items: [] };
        let sessions = [];
        for (let i = 0; i < result.Items?.length; i++) {
          const item = result.Items[i];
          item.Sessions.forEach((session, idx) => {
            const startDT = DateTime.fromISO(session.StartDateTime).setZone(TZ_MAP.get(item.TimeZone));
            const endDT = DateTime.fromISO(session.EndDateTime).setZone(TZ_MAP.get(item.TimeZone));
            sessions.push({
              registerUri: item.RegistrationInfo?.RegisterUri,
              registerMessage: item.RegistrationInfo?.RegisterMessage,
              time: `${startDT.toFormat('h:mm')} - ${endDT.toFormat('h:mm ZZZZ')}`,
              date: `${startDT.toFormat('LLL d')} - ${endDT.toFormat('LLL d yyyy')}`,
              notice: item.Notice,
              price: item.AdvertisedOffers[0]?.OfferAmount?.FormattedAmountTaxExclusive,
              closeDate: startDT.minus({ days: 14 }).toFormat('LLL d, yyyy'),
            });
          });
        }
        setVirtualSessions(sessions);
        if (typeof window !== 'undefined') {
          window.location.hash = '#virtual-training-session-list';
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(ERROR_MESSAGES.Default);
      logClientException(error);
    }
  };

  const getVirtualSessionList = () => {
    return (
      <Slide direction='up' in={true} mountOnEnter unmountOnExit>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', alignItems: 'center', mt: '50px' }}>
          <Box sx={{ maxWidth: { xs: '100%', lg: '1200px' }, width: '100%' }}>
            {virtualSessions && virtualSessions.length > 0 && (
              <Box
                sx={{
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.common.white,
                  borderRadius: { sm: 'none', md: '12px 12px 0px 0px' },
                  width: '100%',
                  mt: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '75px',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '26px',
                }}
              >
                {seriesSelected}
              </Box>
            )}
            <KitGridContainer style={{ justifyContent: 'space-evenly', display: matchesLg ? 'flex' : 'none', marginTop: '20px', rowGap: '20px' }}>
              {virtualSessions &&
                virtualSessions.map((s, i) => {
                  return (
                    <KitGridItem xs={12} md={4} key={i}>
                      <Box sx={{ width: '290px' }}>
                        <Heading6 sx={{ marginBottom: '10px' }}>{s.date}</Heading6>
                        <BodyCopy>{s.time}</BodyCopy>
                        <BodyCopy>{s.notice}</BodyCopy>
                        <BodyCopy>Registration closes on {s.closeDate}</BodyCopy>
                        <BodyCopy sx={{ marginBottom: '15px' }}>Cost: {s.price}</BodyCopy>
                        <a href={s.registerUri} target='_blank' rel='noreferrer'>
                          <KitButton color='success' round size='sm'>
                            {s.registerMessage}
                          </KitButton>
                        </a>
                      </Box>
                    </KitGridItem>
                  );
                })}
              {/* {(!virtualSessions || virtualSessions.length === 0) && <BodyCopy>No sessions are currently scheduled. Check back later.</BodyCopy>} */}
              {(!virtualSessions || virtualSessions.length === 0) && (
                <>
                  <KitGridItem xs={12} align='center'>
                    {fields && fields.noSessionsMessage && fields.noSessionsMessage.length > 0
                      ? fields.noSessionsMessage
                      : 'We didn’t find any upcoming sessions. Please contact us for additional scheduling options.'}
                  </KitGridItem>
                  <KitGridItem xs={12} align='center'>
                    <KitButton
                      color='success'
                      round
                      onClick={() => handleNoSessionsContactUsClick(fields?.description)}
                      style={{ marginBottom: '20px' }}
                    >
                      Contact Us
                    </KitButton>
                  </KitGridItem>
                </>
              )}
            </KitGridContainer>
            <Box sx={{ display: matchesLg ? 'none' : 'flex', justifyContent: 'center', marginTop: '20px' }}>
              {/* {(!virtualSessions || virtualSessions.length === 0) && <BodyCopy>No sessions are currently scheduled. Check back later.</BodyCopy>} */}
              {(!virtualSessions || virtualSessions.length === 0) && (
                <KitGridContainer align='center' style={{ marginBottom: 15 }}>
                  <KitGridItem xs={12} align='center'>
                    {fields && fields.noSessionsMessage && fields.noSessionsMessage.length > 0
                      ? fields.noSessionsMessage
                      : 'We didn’t find any upcoming sessions. Please contact us for additional scheduling options.'}
                  </KitGridItem>
                  <KitGridItem xs={12} align='center' style={{ marginTop: 20 }}>
                    <KitButton color='success' round onClick={() => handleNoSessionsContactUsClick()} style={{ marginBottom: '20px' }}>
                      Contact Us
                    </KitButton>
                  </KitGridItem>
                </KitGridContainer>
              )}
              {virtualSessions && virtualSessions.length > 0 && (
                <Carousel
                  autoPlay={false}
                  cycleNavigation={true}
                  navButtonsAlwaysVisible={true}
                  navButtonsProps={{
                    style: {
                      backgroundColor: 'rgba(0, 59, 92, 0.3)',
                      borderRadius: '25px',
                    },
                  }}
                  sx={{ width: '315px', height: '250px' }}
                >
                  {virtualSessions &&
                    virtualSessions.map((s, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '311px',
                          }}
                        >
                          <Heading6 sx={{ marginBottom: '8px' }}>{s.date}</Heading6>
                          <BodyCopyMobile>{s.time}</BodyCopyMobile>
                          <BodyCopyMobile>{s.notice}</BodyCopyMobile>
                          <BodyCopyMobile>Registration closes on {s.closeDate}</BodyCopyMobile>
                          <BodyCopyMobile sx={{ marginBottom: '15px' }}>Cost: {s.price}</BodyCopyMobile>
                          <a href={s.registerUri} target='_blank' rel='noreferrer'>
                            <KitButton color='success' round size='sm'>
                              {s.registerMessage}
                            </KitButton>
                          </a>
                        </Box>
                      );
                    })}
                </Carousel>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>
    );
  };

  const Container = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      margin: 30px 0;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  `;

  const MobileContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      // width: 95%;
      width: 100%;
      padding: 0 0 20px 0;
    }
  `;

  const CarouselContainer = styled.div`
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      // width: 100%;
      // height: 510px;
      // margin: 0 0 20px 5px;
      margin: 0px 2% 5px 3%;
      width: 25rem;
    }
  `;

  const KitCardWrapper = styled(KitCard)`
    border-radius: 6px;
    @media screen and (max-width: 991px) {
      // width: 100% !important;
    }
    @media screen and (min-width: 992px) {
      // width: 31% !important;
    }
  `;

  const Callout = styled.div`
    display: block;
    width: 21.5rem;
    font-size: 0.938rem;
    line-height: 1.5rem;
    font-weight: 600;
    position: absolute;
    top: -28px;
    background-color: #cf4520;
    color: white;
    z-index: 101;
    padding: 10px 10px;
    border-radius: 4px 4px;
  `;

  const learnMore = (contentID) => {
    const plan = fields.trainingPlans && fields.trainingPlans.find((p) => p.contentID === contentID);
    if (plan) {
      setProductImage(plan.fields.learnMoreHeaderImage && plan.fields.learnMoreHeaderImage.url);
      setTitle(plan.fields.learnMoreTitle);
      setDescription(plan.fields.learnMoreDescription);
      setIntroduction(plan.fields.learnMoreIntroduction);
      setBenefits(plan.fields.learnMoreBenefits);
      setDisclaimer(plan.fields.learnMoreDisclaimer);
      setShowDialog(true);
    }
  };

  function DisplayCardInCarousel({ contentID, fields, mobile }) {
    return (
      <KitCardWrapper
        style={{
          width: '23rem',
          height: '450px',
        }}
      >
        <CardHeader>
          {fields && fields.callout && <Callout>{fields.callout}</Callout>}
          <ResourceType>{fields?.title}</ResourceType>
          <ResourceName>{fields?.subtitle}</ResourceName>
        </CardHeader>
        <KitCardBody style={{ margin: 0, position: 'relative' }}>
          <Description>
            {truncate(fields?.description, {
              length: mobile ? 120 : 240,
              decodeEntities: true,
              stripTags: true,
              reserveLastWord: true,
            })}
          </Description>
          <Price>{fields?.price}</Price>
          <LearnMore>
            {fields?.learnMore?.text && (
              <a id='learnmore' onClick={(e) => learnMore(contentID)} style={{ cursor: 'pointer' }}>
                {fields?.learnMore.text}
              </a>
            )}
          </LearnMore>
          <ActionButtonContainer>
            {fields.buttonURL && fields.buttonURL.href.length > 0 ? (
              <Link
                href={`${fields.buttonURL.href}?product=${fields.dynamicsCampaignName ?? fields.description}&trainingMethod=${fields.title}`}
                target='_self'
                passHref
                legacyBehavior
              >
                <KitButton color='success' size='lg' round style={{ opacity: 1.0 }}>
                  {fields.buttonURL.text}
                </KitButton>
              </Link>
            ) : (
              <KitButton
                color='success'
                size='lg'
                round
                style={{ opacity: 1.0 }}
                onClick={(e) => handleActionButtonClick(fields.name, fields.aRLOCategoryId, fields?.description)}
              >
                {fields.buttonURL && fields.buttonURL.text && fields.buttonURL.text.length > 0 && (
                  <a rel='noopener noreferrer'>{fields.buttonURL.text}</a>
                )}
              </KitButton>
            )}
          </ActionButtonContainer>
        </KitCardBody>
      </KitCardWrapper>
    );
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <>
      <Snackbar open={errorMessage && errorMessage.length > 0} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <PleaseWait isLoading={isLoading} />
      <GrayContainer>
        <Box
          id='coaching-plan-cards'
          sx={{
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
          }}
        >
          <Frame>
            <a id='coaching-plan-cards'></a>
            <Container>
              <CardGrid>
                {fields?.trainingPlans
                  ?.sort((a, b) => {
                    if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                    if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                    return 0;
                  })
                  .map((resource, index) => (
                    <React.Fragment key={resource.contentID}>
                      <DisplayCardInCarousel contentID={resource.contentID} fields={resource.fields} mobile={true} />
                    </React.Fragment>
                  ))}
              </CardGrid>
            </Container>
            <MobileContainer>
              <CarouselContainer>
                <Carousel
                  indicatorContainerProps={{
                    style: {
                      zIndex: 1,
                      marginTop: '40px',
                      position: 'relative',
                    },
                  }}
                >
                  {fields.trainingPlans
                    ?.sort((a, b) => {
                      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                      return 0;
                    })
                    .map((resource, index) => (
                      <React.Fragment key={resource.contentID}>
                        <DisplayCardInCarousel contentID={resource.contentID} fields={resource.fields} mobile={true} />
                      </React.Fragment>
                    ))}
                </Carousel>
              </CarouselContainer>
            </MobileContainer>
          </Frame>
          <DialogWithX
            size='lg'
            content={
              <PlanOptionLearnMore
                headerImage={productImage}
                title={title}
                description={description}
                introduction={introduction}
                benefits={benefits}
                disclaimer={disclaimer}
                showCalculator={false}
              />
            }
            ariaLabel='Learn More Dialog'
            showValue={showDialog}
            showMethod={setShowDialog}
          />
        </Box>
      </GrayContainer>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          pl: '10px',
          pr: '10px',
          mb: '50px',
          maxWidth: { sm: '600px', md: '900px', lg: '1200px', xl: '1500px' },
        }}
      >
        <div id='virtual-training-session-list'>{virtualSessions && getVirtualSessionList()}</div>
      </Box>
    </>
  );
};

export default CoachingPlanCards;

const GrayContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(217, 217, 217, 0.3)',
  [theme.breakpoints.up('md')]: {
    margin: '50px 0',
  },
  [theme.breakpoints.down('md')]: {
    margin: '15px 10px 20px 10px',
    padding: '30px 0 5px 0',
  },
}));

const NoResourcesContainer = styled('div')(({ theme }) => ({
  minHeight: 50,
}));

const Frame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CardGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '2.00rem',
  [theme.breakpoints.up('md')]: {
    margin: '10px 0 0 0',
    padding: '10px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const CardHeader = styled('div')(({ theme }) => ({
  borderRadius: '4px 4px 0px 0px',
  width: '100%',
  height: '117px',
  padding: '0.5rem 1.875rem',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
}));

const ResourceType = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 600,
  textTransform: 'uppercase',
  textAlign: 'center',
  marginTop: 10,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.10rem',
  },
}));

const ResourceName = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.35rem',
    lineHeight: '1.60rem',
    margin: '10px 0',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.10rem',
    lineHeight: '1.30rem',
    margin: '15px 0',
  },
}));

const Description = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 300,
  fontSize: '1.10rem',
  lineHeight: '1.5rem',
  marginTop: '15px',
  textAlign: 'center',
  height: '30%',
}));

const Price = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 300,
  fontSize: '2.00rem',
  lineHeight: '2.5rem',
  marginTop: '15px',
  textAlign: 'center',
}));

const LearnMore = styled('div')(({ theme }) => ({
  // color: theme.palette.common.black,
  textTransform: 'uppercase',
  textDecoration: 'underline',
  fontWeight: 300,
  fontSize: '0.875rem',
  marginTop: '40px',
  textAlign: 'center',
  '& > a': {
    color: '#555',
  },
  '& > a:hover': {
    color: '#000',
  },
}));

const ActionButtonContainer = styled('div')(({ theme }) => ({
  margin: '20px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '10px 5px',
}));
