import React from 'react';
import styled from '@emotion/styled';

export default function SummaryDescriptionText(props) {
  const { content, textColor } = props;

  const SummaryDescription = styled.div`
    margin-top: 10px;
    font-weight: 300;
    @media screen and (max-width: 899px) {
      color: theme.palette.common.black;
      font-size: 1rem;
      line-height: 1.2rem;
      padding-left: 0;
    }
    @media screen and (min-width: 900px) {
      color: ${textColor};
      font-size: 1.125rem;
      line-height: 1.6rem;
    }
  `;

  return <React.Fragment>{content && <SummaryDescription>{content}</SummaryDescription>}</React.Fragment>;
}
