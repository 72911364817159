import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { KitCard, KitCardBody, KitCardFooter } from '@boystownorg/bi-cms-component-lib';
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const TestimonialList = (props) => {
  const { list } = props;

  return (
    <React.Fragment>
      <Container>
        <PageTitle>Our Clients Love Us</PageTitle>
        <PageDescription>
          Lorem ipsum dolor sit amet, coneceteur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </PageDescription>
        <CardGrid>
          {list &&
            list?.map((comment, i) => {
              return (
                comment &&
                !comment.fields?.featured && (
                  <KitCard key={comment.contentID} style={{ backgroundColor: '#fff', width: '25em' }}>
                    <React.Fragment key={comment?.contentID}>
                      <KitCardBody>
                        <CardBodyContainer>
                          <FormatQuoteIcon color='primary' style={{ fontSize: '2.50rem', margin: '0 0 25px 0' }} />
                          <DescriptionContainer>
                            <Description>&quot;{comment.fields?.comment}&quot;</Description>
                          </DescriptionContainer>
                          <AuthorName>{comment.fields?.customerName_TextField}</AuthorName>
                        </CardBodyContainer>
                      </KitCardBody>
                      <AvatarContainer>
                        {comment.fields?.customerName?.fields.profileImage?.url &&
                          comment.fields?.customerName?.fields.profileImage?.url.length > 0 && (
                            <Avatar
                              alt={comment.fields?.customerName?.customerName_textField}
                              src={comment.fields?.customerName?.fields.profileImage?.url}
                              sx={{ width: 100, height: 100 }}
                            />
                          )}
                      </AvatarContainer>
                      <KitCardFooter></KitCardFooter>
                    </React.Fragment>
                  </KitCard>
                )
              );
            })}
        </CardGrid>
      </Container>
    </React.Fragment>
  );
};

export default TestimonialList;

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
}));

const PageTitle = styled('div')(({ theme }) => ({
  margin: '50px 30px 10px 30px',
  fontSize: '1.65rem',
  lineHeight: '1.90rem',
  color: theme.palette.common.white,
  fontWeight: 600,
  textTransform: 'uppercase',
}));

const PageDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: 'transparent',
  margin: '20px 0 -35px 0',
  fontSize: '1.125rem',
  lineHeight: '1.40rem',
  fontWeight: 400,
  maxWidth: '60%',
}));

const CardGrid = styled('div')(({ theme }) => ({
  margin: '100px 45px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '3.00em',
}));

const CardBodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 0 0 0',
}));

const DescriptionContainer = styled('div')(({ theme }) => ({}));

const Description = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '0 0 15px 0',
  fontSize: '1.125rem',
  lineHeight: '1.40rem',
  letterSpacing: '5.2',
  fontWeight: 300,
  fontStyle: 'italic',
}));

const AuthorName = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '30px 0 -40px 0',
  fontSize: '1.20rem',
  lineHeight: '1.30rem',
  fontWeight: 600,
}));

const AvatarContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  top: 55,
  alignSelf: 'center',
  zIndex: 20,
}));

const AuthorAvatar = styled('div')(({ theme }) => ({}));
