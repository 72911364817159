import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { callApi } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES } from '../../services/constants';
import PleaseWait from 'components/common/PleaseWait';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AcceptHostedCC = ({ email, firstName, lastName, amount, state, refId, orderDescription, onComplete, onCancel }) => {
  const theme = useTheme();
  const [acceptToken, setAcceptToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const formRef = useRef(null);
  const tokenRef = useRef(null);
  const frameRef = useRef(null);
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (typeof window !== undefined) {
      const loadForm = async () => {
        try {
          setLoading(true);
          let path = '/api/cc/accept-token';
          let body = {
            email: email,
            amount: amount,
            refid: refId,
            order_description: orderDescription,
            iframe_host_url: `${window.location.origin}/IFrameCommunicator.html`,
          };
          let res = await callApi(path, 'POST', body);
          setAcceptToken(res.token);
          setLoading(false);
          if (tokenRef.current && formRef.current) {
            tokenRef.current.value = res.token;
            formRef.current.submit();
            console.log(`submitted form to ${process.env.NEXT_PUBLIC_AUTHORIZE_POST_URL}`);
          } else {
            console.log('no refs');
          }
        } catch (error) {
          console.log(error);
          logClientException(error);
          setErrorMessage(ERROR_MESSAGES.Default);
          setLoading(false);
        }
      };

      loadForm();
    }
  }, [amount]);

  useEffect(() => {
    if (typeof window !== undefined) {
      const parseQueryString = (str) => {
        var vars = [];
        var arr = str.split('&');
        var pair;
        for (var i = 0; i < arr.length; i++) {
          pair = arr[i].split('=');
          vars[pair[0]] = decodeURIComponent(pair[1]);
        }
        return vars;
      };
      window.CommunicationHandler = {};
      window.CommunicationHandler.onReceiveCommunication = (argument) => {
        let params = parseQueryString(argument.qstr);
        let parentFrame = argument.parent.split('/')[4];
        if (params?.action === 'transactResponse' && params?.response) {
          let transResponse = JSON.parse(params.response);
          if (transResponse?.responseCode === '1') {
            // successful transaction
            onComplete(true, transResponse);
          } else {
            onComplete(false, transResponse);
          }
        } else if (params.action === 'cancel') {
          onCancel();
        }
      };
    }
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  return (
    <>
      <PleaseWait isLoading={loading} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <form ref={formRef} id='send_hptoken' action={`${process.env.NEXT_PUBLIC_AUTHORIZE_POST_URL}`} method='post' target='load_payment'>
        <input ref={tokenRef} type='hidden' name='token' />
      </form>
      <iframe
        allow="fullscreen 'none'"
        referrerPolicy='no-referrer'
        sandbox='allow-forms allow-same-origin allow-scripts allow-orientation-lock'
        ref={frameRef}
        id='load_payment'
        name='load_payment'
        width='100%'
        height={matchesMd ? '650px' : '800px'}
        frameBorder='0'
        scrolling='no'
        hidden={acceptToken === null}
      ></iframe>
    </>
  );
};
AcceptHostedCC.propTypes = {
  email: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  refId: PropTypes.string,
  orderDescription: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default AcceptHostedCC;
