import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { emailRegex } from 'services/stringUtils';
import { ROLES, ROLE_CONST } from 'services/roleUtils';
import { logClientException } from 'appinsights/clientAppInsights';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const SendTestEmail = (props) => {
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const [userDialogState, setUserDialogState] = useState({
    emailAddress: '',
    firstName: '',
    emailType: '',
    couponCode: null,
  });

  const fetching = useRef(0);

  useEffect(() => {
    const init = async () => {
      if (props.couponCodes) {
        setCouponCodeList(
          props.couponCodes.map((c) => ({
            label: c.code,
            value: { code: c.code, exp_date: c.exp_date, type: c.type, value: c.value, product: c.product },
          }))
        );
        setUserDialogState({
          emailAddress: '',
          firstName: '',
          emailType: '',
          couponCode: null,
        });
      }
    };

    if (props.open) {
      init();
    }
  }, [props.open]);

  const resetState = () => {
    setErrorMessage(null);
    setUserDialogState({
      emailAddress: '',
      firstName: '',
      emailType: '',
      couponCode: null,
    });
  };

  const closeUserDialog = async (doSave) => {
    if (!doSave) {
      props.onClose(doSave);
      resetState();
      return;
    }

    if (!canSendEmail()) {
      return;
    }

    let user = JSON.parse(JSON.stringify(userDialogState));

    if (user.couponCode) {
      const couponCode = JSON.parse(JSON.stringify(user.couponCode));
      user.coupon_code = couponCode.value;
      delete user.couponCode;
      console.log(user.coupon_code);
    }

    Object.keys(user).forEach((key) => {
      if (key.endsWith('Error') && user.hasOwnProperty(key)) {
        delete user[key];
      }
    });

    try {
      fetching.current += 1;
      await callApi('/api/test-email', 'POST', { user: user });
      resetState();
      props.onClose(doSave);
      fetching.current -= 1;
    } catch (error) {
      logClientException(error);
      fetching.current -= 1;
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const canSendEmail = () => {
    let hasError = false;
    const user = userDialogState;
    if (!user.emailAddress || user.emailAddress.length === 0 || !emailRegex.test(user.emailAddress)) {
      hasError = true;
      user.emailAddressError = true;
    }
    if (!user.firstName || user.firstName.length === 0) {
      hasError = true;
      user.firstNameError = true;
    }
    if (!user.emailType || user.emailType.length === 0) {
      hasError = true;
      user.emailTypeError = true;
    }
    if (hasError) {
      setUserDialogState({ ...user, canSave: false });
    }
    return !hasError;
  };

  const userOnChange = async (field, value) => {
    let canSave = true;
    let fieldError = false;
    if (field === 'emailAddress') {
      if (!emailRegex.test(value)) {
        canSave = false;
        fieldError = true;
      }
    }
    if (field === 'firstName') {
      if (!value || value.length === 0) {
        canSave = false;
        fieldError = true;
      }
    }
    setUserDialogState({ ...userDialogState, [field]: value, [`${field}Error`]: fieldError, canSave });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Dialog maxWidth='sm' scroll='paper' fullWidth={true} open={props.open} onClose={() => closeUserDialog(false)}>
      <DialogTitle>Send Test Email</DialogTitle>

      <DialogContent>
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <TextField
          disabled={props.action === 'Update'}
          value={userDialogState.emailAddress}
          error={userDialogState.emailAddressError}
          autoFocus
          required
          margin='dense'
          id='email-address'
          label='Email Address'
          type='email'
          fullWidth
          variant='outlined'
          onChange={(e) => userOnChange('emailAddress', e.target.value)}
          inputProps={{ maxLength: 100 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          value={userDialogState.firstName}
          error={userDialogState.firstNameError}
          required
          margin='dense'
          id='first-name'
          label='First Name'
          type='text'
          fullWidth
          variant='outlined'
          onChange={(e) => userOnChange('firstName', e.target.value)}
          inputProps={{ maxLength: 100 }}
        />

        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={couponCodeList}
          isOptionEqualToValue={(o, v) => o.value.code === v?.value?.code}
          fullWidth
          value={userDialogState.couponCode}
          onChange={(e, v) => userOnChange('couponCode', v)}
          renderInput={(params) => <TextField {...params} label='Coupon Code' />}
        />

        <FormControl fullWidth sx={{ marginTop: '7px' }}>
          <InputLabel required error={userDialogState.emailTypeError} id='type-select'>
            Email Type
          </InputLabel>
          <Select
            required
            variant='outlined'
            fullWidth
            labelId='type-select'
            id='type-select-value'
            value={userDialogState.emailType}
            label='Email Type'
            onChange={(e) => {
              userOnChange('emailType', e.target.value);
            }}
          >
            <MenuItem value='Invite' key='Invite'>
              Invite
            </MenuItem>
            <MenuItem value='InviteWithCouponCode' key='Invite - With Promo Code'>
              Invite - With Promo Code
            </MenuItem>
            <MenuItem value='InviteTeacherWithoutSSO' key='Invite - Teacher w/o SSO'>
              Invite - Teacher w/o SSO
            </MenuItem>
          </Select>
        </FormControl>

        <PleaseWait isLoading={fetching.current > 0} />
      </DialogContent>
      <DialogActions>
        <KitButton onClick={() => closeUserDialog(false)} round size='sm'>
          Cancel
        </KitButton>
        <KitButton onClick={() => closeUserDialog(true)} round size='sm' color='success' disabled={!userDialogState.canSave || fetching.current > 0}>
          Send Test Email
        </KitButton>
      </DialogActions>
    </Dialog>
  );
};

SendTestEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  couponCodes: PropTypes.array,
};
export default SendTestEmail;
