// package imports
import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// local imports
import { useObservationStore } from '../stores/observationStore';
import { ObsStyles } from './StyledComponents';

const SessionChecklists = ({ eventState, eventStateOnChange }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const isReadonly = !eventStateOnChange;

  const { observation } = useObservationStore();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '1rem' }}>
      <KitCard>
        <KitCardBody style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '.2rem' }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '30px', color: theme.palette.primary.dark, mb: '1rem' }}>
              Physical Environment Checklist
            </Typography>

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.skills_posters}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('skills_posters', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='BT Skills Posters'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.classroom_rules}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('classroom_rules', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Classroom Rules'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.schoolwide_rules}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('schoolwide_rules', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='School-wide Rules'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.classroom_procedures}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('classroom_procedures', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Classroom Procedures'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.schoolwide_procedures}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('schoolwide_procedures', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='School-wide Procedures'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.consequences}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('consequences', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Consequences'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.daily_schedule}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('daily_schedule', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Daily schedule/agenda'
            />

            {observation && observation.classroom_type === 'Specialized Classroom Management' && (
              <>
                <FormControlLabel
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  control={
                    <Checkbox
                      sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                      checked={eventState.cards_on_desk}
                      onChange={(e) => eventStateOnChange && eventStateOnChange('cards_on_desk', e.target.checked)}
                      color='gold'
                      checkedIcon={<CheckCircleIcon />}
                      icon={<CheckCircleOutlineIcon color='gold' />}
                    />
                  }
                  label='Cards on desk'
                />
                <FormControlLabel
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  control={
                    <Checkbox
                      sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                      checked={eventState.students_write_cards}
                      onChange={(e) => eventStateOnChange && eventStateOnChange('students_write_cards', e.target.checked)}
                      color='gold'
                      checkedIcon={<CheckCircleIcon />}
                      icon={<CheckCircleOutlineIcon color='gold' />}
                    />
                  }
                  label='Students write on their cards'
                />
              </>
            )}

            <FormControl fullWidth size='small'>
              <TextField
                placeholder='Enter notes here...'
                multiline={true}
                rows={4}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 500, readOnly: isReadonly, style: { cursor: isReadonly ? 'default' : 'pointer' } }}
                sx={{ ...styles.select, marginTop: '.75rem' }}
                InputProps={{ sx: { ...styles.select } }}
                label='Physical Environment Notes'
                id='phys-env-notes'
                error={eventState.phys_env_notesError}
                size='small'
                value={eventState.phys_env_notes}
                onChange={(e) => {
                  eventStateOnChange && eventStateOnChange('phys_env_notes', e.target.value);
                }}
              />
            </FormControl>
          </Box>
        </KitCardBody>
      </KitCard>

      <KitCard>
        <KitCardBody style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', rowGap: '.2rem', height: '100%' }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '30px', color: theme.palette.primary.dark, mb: '1rem' }}>
              Relationship Building Checklist
            </Typography>

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.atsmosphere}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('atsmosphere', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Creates a welcoming and engaging atmosphere in the classroom'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.solicits}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('solicits', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Solicits student input, opinions, and feedback'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.positive_comments}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('positive_comments', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Makes positive, encouraging comments to students and recognizes accomplishments'
            />

            <FormControlLabel
              sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
              control={
                <Checkbox
                  sx={{ cursor: isReadonly ? 'default' : 'pointer' }}
                  checked={eventState.quality_components}
                  onChange={(e) => eventStateOnChange && eventStateOnChange('quality_components', e.target.checked)}
                  color='gold'
                  checkedIcon={<CheckCircleIcon />}
                  icon={<CheckCircleOutlineIcon color='gold' />}
                />
              }
              label='Uses positive quality and relational components (ex. voice tone, physical proximity, gestures, facial expressions, use student name/term of endearment, humor)'
            />

            <FormControl fullWidth size='small' sx={{ alignSelf: 'flex-end' }}>
              <TextField
                placeholder='Enter notes here...'
                multiline={true}
                rows={4}
                InputLabelProps={{ shrink: true }}
                sx={{ ...styles.select, marginTop: '.75rem' }}
                InputProps={{ sx: { ...styles.select } }}
                inputProps={{ maxLength: 500, readOnly: isReadonly, style: { cursor: isReadonly ? 'default' : 'pointer' } }}
                label='Relationship Building Notes'
                id='rel-bld-notes'
                error={eventState.rel_bld_notesError}
                size='small'
                value={eventState.rel_bld_notes}
                onChange={(e) => {
                  eventStateOnChange && eventStateOnChange('rel_bld_notes', e.target.value);
                }}
              />
            </FormControl>
          </Box>
        </KitCardBody>
      </KitCard>
    </Box>
  );
};

SessionChecklists.propTypes = {
  eventState: PropTypes.object.isRequired,
  eventStateOnChange: PropTypes.func,
};

export default SessionChecklists;
