import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
  
const PleaseWait = (props) => {
  
    return (
      <div>
        <Backdrop 
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.isLoading || false}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
}

export default PleaseWait;