import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';

const ModuleCard = (props) => {
  const { module } = props;

  const router = useRouter();
  const theme = useTheme();

  return (
    <KitCard
      key={module.url}
      style={{ minWidth: '300px', opacity: module.hasPlayableSub ? 1 : 0.5, boxShadow: '0px 4px 4px rgba(0,0,0,0.25)', borderRadius: '6px' }}
    >
      <React.Fragment>
        {module.fields?.moduleImage && <ModuleImg alt={module.fields?.moduleName} src={module.fields?.moduleImage?.url} />}
        <KitCardBody style={{ padding: '5px 15px' }}>
          <CardBodyContainer>
            <ModuleName>{module.fields?.moduleName}</ModuleName>
            <ModuleDescription>{module.fields?.seriesDescription.substr(0, 180)}</ModuleDescription>
          </CardBodyContainer>
        </KitCardBody>
      </React.Fragment>
    </KitCard>
  );
};

export default ModuleCard;

const ModuleImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  height: 'auto',
}));

const CardBodyContainer = styled('div')(({ theme }) => ({
  padding: '5px 0 10px 0',
  height: '225px',
}));

const ModuleName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: '5px 0 15px 0',
  fontSize: '1.50rem',
  lineHeight: '1.70rem',
  fontWeight: 600,
}));

const ModuleDescription = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: 'transparent',
  margin: 0,
  fontSize: '1.00rem',
  lineHeight: '1.40rem',
  fontWeight: 300,
}));
