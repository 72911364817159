import React from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useSession } from 'next-auth/react';
import { useTheme } from '@mui/material/styles';

const ActionImageWithButton = ({ module }) => {
  const { fields } = module;
  const theme = useTheme();

  const router = useRouter();
  const { data: session, status } = useSession();

  const callAction = (event) => {
    event.preventDefault();
    router.push(fields.actionButtonUrl.href);
  };

  const Container = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    padding: 0 30px;
    position: relative;
    @media screen and (min-width: 601px) {
      ${fields.imageLocation === 'left' ? 'justify-content: right;' : 'justify-content: left;'}
      ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
      min-height: 475px;
    }
    @media screen and (max-width: 600px) {
      flex-direction: row-reverse;
      justify-content: right;
      height: 400px;
    }
  `;

  const EmptyContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    flex: 1;
    vertical-align: top;
    @media screen and (min-width: 601px) {
      padding: 60px 10px;
      ${fields.imageLocation === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
      ${fields.imageLocation === 'center' ? 'max-width: 20%;' : 'max-width: 50%;'}
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  `;

  const FloatingButton = styled.div`
    vertical-align: top;
    padding: 60px 10px;
    position: absolute;
    top: 65%;
    ${fields.imageLocation === 'left' ? 'left: 52%;' : 'left: 35%;'}
    @media screen and (max-width: 600px) {
      display: none;
    }
  `;

  const MobileFloatingButton = styled.div`
    vertical-align: top;
    padding: 15px 10px;
    position: absolute;
    top: 60%;
    left: 55%;
    @media screen and (min-width: 601px) {
      display: none;
    }
  `;

  const ImageContainer = styled.div`
    position: relative;
    @media screen and (min-width: 601px) {
      ${fields.imageLocation === 'left' ? 'margin-left: 10px' : 'margin-right: 20px'}
      margin: 0;
      max-width: 50%;
      height: 100%;
    }
    @media screen and (max-width: 600px) {
      max-width: 85%;
      height: auto;
    }
  `;

  const HeroImage = styled.img`
    @media screen and (min-width: 601px) {
    }
    @media screen and (max-width: 600px) {
      margin-left: -48%;
    }
  `;

  const TitleContainer = styled.div`
    margin: 0;
    position: absolute;
    @media screen and (min-width: 601px) {
      ${fields.imageLocation === 'left' ? 'left: 52.45%;' : 'left: 21%;'}
      top: 45%;
      max-width: 50%;
    }
    @media screen and (max-width: 600px) {
      left: 54%;
      top: 25%;
      max-width: 110%;
    }
  `;

  const Title = styled.div`
    text-transform: uppercase;
    @media screen and (min-width: 601px) {
      font-weight: 700;
      max-width: 410px;
      font-size: 3.375rem;
      line-height: 2.688rem;
      ${fields.imageLocation === 'left' ? 'text-align: left;' : 'text-align: right;'}
    }
    @media screen and (max-width: 600px) {
      font-weight: 600;
      text-align: left;
      margin-left: -12px;
      font-size: 2rem;
      line-height: 2.25rem;
    }
  `;

  return (
    <React.Fragment>
      <Container>
        <EmptyContainer></EmptyContainer>
        <ImageContainer>{fields.heroImage && <HeroImage src={fields.heroImage.url} alt={fields.heroImage.text} />}</ImageContainer>
        {fields.showButton === 'true' && (
          <>
            <FloatingButton>
              <a href={fields.buttonUrl.href} target={fields.buttonUrl.target} rel='noopener noreferrer'>
                <KitButton
                  round
                  size='lg'
                  style={{
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  {fields.buttonText}
                </KitButton>
              </a>
            </FloatingButton>
            <MobileFloatingButton>
              <Link href={fields.buttonUrl.href} passHref legacyBehavior>
                <KitButton
                  round
                  style={{
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <a href={fields.buttonUrl.href} target={fields.buttonUrl.target} rel='noopener noreferrer'>
                    {fields.buttonText}
                  </a>
                </KitButton>
              </Link>
            </MobileFloatingButton>
          </>
        )}
        <TitleContainer>
          <Title>{fields.title}</Title>
        </TitleContainer>
      </Container>
    </React.Fragment>
  );
};

export default ActionImageWithButton;
