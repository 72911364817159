import { Button, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, useMediaQuery } from '@mui/system';
import React from 'react';
import { useTheme } from '@emotion/react';

const Transcript = ({ openLabel = 'Read Video Transcript', closeLabel = 'Close Video Transcript', children }) => {
  const [show, setShow] = React.useState(false);
  const theme = useTheme();

  const fullScreen = useMediaQuery('(max-width:767px)');

  return (
    <Box sx={{ '& p': { color: theme.palette.primary.dark }, maxWidth: '800px' }}>
      <Button
        size='small'
        disableElevation
        disableRipple
        sx={{
          color: theme.palette.primary.dark,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
        onClick={() => setShow(!show)}
        startIcon={show ? <RemoveIcon /> : <AddIcon fontSize='inherit' />}
      >
        {show ? closeLabel : openLabel}
      </Button>
      <Collapse sx={{ borderTop: show ? `1px solid ${theme.palette.grey['600']}` : '' }} in={show}>
        <Box sx={{ maxHeight: fullScreen ? '100%' : '130px', overflowY: 'auto', padding: '.5rem' }}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default Transcript;
