import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import truncate from 'truncate-html';
import { KitButton, KitCard, KitCardBody, KitCardFooter } from '@boystownorg/bi-cms-component-lib';
import { logServerException } from 'appinsights/clientAppInsights';

const ResourceListing = ({ customData }) => {
  const { resources } = customData;

  if (!resources || resources.length <= 0) {
    return (
      <NoResourcesContainer>
        <NoResourcesTitle>No resources currently available.</NoResourcesTitle>
      </NoResourcesContainer>
    );
  }

  const getResourceCard = (item) => {
    if (item?.properties?.referenceName === 'recordedwebinarlist') {
      return <Webinar item={item} />;
    }
    // TODO - other resource types (i.e. Ask the Trainer, downloads, lesson plans...)
  };

  return (
    <React.Fragment>
      <CardGrid>{resources.map((resource) => getResourceCard(resource))}</CardGrid>
    </React.Fragment>
  );
};

const resolveUrls = function (sitemap, items) {
  let dynamicUrls = {};
  items.forEach((item) => {
    Object.keys(sitemap).forEach((path) => {
      if (sitemap[path].contentID === item.contentID) {
        dynamicUrls[item.contentID] = path;
      }
    });
  });
  return dynamicUrls;
};

ResourceListing.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem, channelName }) => {
  const api = agility;

  try {
    // get sitemap...
    let sitemap = await api.getSitemapFlat({
      channelName: channelName,
      languageCode,
    });

    let webinars = await api.getContentList({
      referenceName: 'recordedwebinarlist',
      languageCode,
      expandAllContentLinks: true,
      sort: 'properties.modified',
      direction: api.types.SortDirections.DESC,
      take: 10,
    });

    const dynamicUrls = resolveUrls(sitemap, webinars.items);

    // TODO get other resources: Ask the trainer, Lesson Plans, Downloadables
    let resources = webinars.items.map((item) => {
      const url = dynamicUrls[item.contentID] || '#';
      item.fields.url = url;
      return item;
    });

    return {
      resources,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default ResourceListing;

const Webinar = ({ item }) => {
  return (
    <WebinarImgContainer key={item.contentID}>
      <Link href={item.fields.url} legacyBehavior>
        <a>
          <KitButton justIcon round color='success' small>
            <PlayArrowIcon />
          </KitButton>
          <KitCard
            className='webinarCard'
            style={{
              width: '22rem',
            }}
          >
            <>
              {item.fields.previewImage && <WebinarImg alt={item.fields.previewImage.label} src={item.fields.previewImage.url} />}
              <KitCardBody>
                <WebinarTitle>{item.fields.title}</WebinarTitle>
              </KitCardBody>
              <KitCardFooter></KitCardFooter>
            </>
          </KitCard>
        </a>
      </Link>
    </WebinarImgContainer>
  );
};

const WebinarImgContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  button: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
  },
  '&:hover': {
    '.webinarCard': {
      opacity: 0.6,
    },
    button: {
      opacity: 1,
      zIndex: 99,
    },
  },
}));

const NoResourcesContainer = styled('div')(({ theme }) => ({
  marginTop: 40,
  minHeight: 300,
}));

const NoResourcesTitle = styled('div')(({ theme }) => ({
  fontSize: '1.05rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  margin: '0 30px 10px 30px',
}));

const CardGrid = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '20px 45px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '3.00em',
}));

const WebinarTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '5px 0 5px 0',
  fontSize: '1.35rem',
  lineHeight: '1.40rem',
  fontWeight: 700,
  textAlign: 'center',
}));

const WebinarImg = styled('img')(({ theme }) => ({
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  display: 'block',
  width: '100%',
  maxHeight: '220px',
}));
