import React, { useState } from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import CourseInformation from 'components/common/subcomponents/GraduateInformation/CourseInformation';
import DialogWithX from './pageComponents/DialogWithX';
import { logServerException } from 'appinsights/clientAppInsights';

// NOTE: This is a very specific component to be used ONLY for the Graduate Transcript Page.

const GraduateRichText = ({ module, customData }) => {
  const { fields } = module;
  const theme = useTheme();

  const [showCreditFeesDialog, setShowCreditFeesDialog] = useState(false);
  const [showCreditRegistrationDialog, setShowCreditRegistrationDialog] = useState(false);

  const createLink = (text) => {
    return (
      <GeneratedLink>
        <a href='https://www.bellevue.edu/student-support/registrar/transcripts' rel='noreferrer' target='_blank' style={{ cursor: 'pointer' }}>
          Click HERE to order your official Graduate Credit Transcript. Please wait 10-14 days after being notified of your grade by Boys Town to
          request a transcript.
        </a>
      </GeneratedLink>
    );
  };

  const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1300px) {
      width: 40%;
      min-height: 630px;
      padding: 0 0 15px 0;
    }
    @media screen and (max-width: 1299px) {
      width: 95%;
      min-height: 680px;
    }
  `;

  return (
    <Box
      sx={{
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Container>
        <SummaryText className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.summaryText)} />
        <div>{createLink(fields.linkName)}</div>
        <SummaryText className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(fields.summaryTextPart2)} />
        <ButtonContainer align={fields.buttonAlignment}>
          {fields.showButton === 'true' && (
            <>
              <KitButton
                round
                style={{
                  backgroundColor: theme.palette.primary.light,
                }}
                onClick={(e) => setShowCreditRegistrationDialog(true)}
              >
                {fields.showButtonIcon && <LockIcon color='white' />} {fields.buttonText}
              </KitButton>
            </>
          )}
        </ButtonContainer>
      </Container>
      <DialogWithX
        size='lg'
        content={<CourseInformation data={customData} />}
        ariaLabel='Course Information Dialog'
        showValue={showCreditFeesDialog}
        showMethod={setShowCreditFeesDialog}
      />
    </Box>
  );
};

GraduateRichText.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem, channelName }) => {
  const api = agility;

  try {
    let courses = await api.getContentList({
      referenceName: 'coursesummary',
      expandAllContentLinks: true,
      languageCode,
      take: 100,
      sort: 'fields.sortOrder',
    });

    return {
      courses: courses?.items ?? null,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default GraduateRichText;

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '20px 15px 20px 15px',
    margin: '30px 0 40px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '15px 5px 15px 5px',
    margin: '15px 10px 20px 10px',
  },
}));

const SummaryText = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  margin: 5,
}));

const ButtonContainer = styled('div')(({ theme, align }) => ({
  margin: '35px 0 0 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: align || 'center',
}));

const GeneratedLink = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '18px',
  lineHeight: '28px',
  margin: '20px 0',
  color: theme.palette.primary.light,
}));

const JotFrame = styled('iframe')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: '1000px',
    height: '600px',
    border: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    width: '300px',
    height: '600px',
    border: 'none',
  },
}));
