import { createElement, PropsWithChildren, ReactElement, ReactNode } from 'react';
import * as prod from 'react/jsx-runtime';
import { unified } from 'unified';
import rehypeReact, { Options as RehypeOptions } from 'rehype-react';
import remarkParse from 'remark-parse';
import remarkBreaks from 'remark-breaks';
import remarkRehype from 'remark-rehype';
import rehypeSanitize from 'rehype-sanitize';
import rehypeRaw from 'rehype-raw';
import { RehypeFilterOptions, rehypeFilter } from './rehypeFilter';

export type ParseMarkdownOptions = Pick<RehypeOptions, 'components'> & RehypeFilterOptions;

// @ts-expect-error: the react types are missing.
const production = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };

export function parseMarkdown(content: string, options?: ParseMarkdownOptions): ReactNode {
  const { result } = unified()
    .use(remarkParse)
    .use(remarkBreaks)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(rehypeRaw)
    .use(rehypeSanitize)
    .use(rehypeReact, production)
    .use(rehypeFilter, {
      allowElement: options?.allowElement,
      allowedElements: options?.allowedElements,
      disallowedElements: options?.disallowedElements,
      unwrapDisallowed: options?.unwrapDisallowed,
    })
    .processSync(content);

  // unwraps the outer <div> element
  return (result as ReactElement<PropsWithChildren<{}>>).props.children;
}
